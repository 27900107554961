import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _toConsumableArray from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import crmMixin from '@/mixins/crm';
import LOG_TYPE from '@/maps/enum/contract-log-type';
import ATTACHMENT_TYPE from '@/maps/enum/attachment-type';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import AUDIT from '@/maps/enum/status/audit';
import Contract from '@/components/business/contract/businessAccountingIndex';
import Attachment from '@/components/business/attachment';
import ContractLog from '@/components/business/contract-log';
import ContractGoldWeight from '@/components/business/contract-gold-weight';
import AccountStatement from '@/components/business/account-statement';
import { setExtendAttribute } from '@/libs/crm/crm';
import { formatAmountToNumber } from '@/libs/crm/format';
import { getInfo as _getInfo, auditFinance } from '@api/crm/renew/renew'; // 合同续约

import { listNoBuyMatch } from '@api/crm/bank/accountStatement';
import { list } from '@api/crm/bank/bill';
import { getExpectInfo, bankCardInfoSave } from '@api/crm/customer/bankCard';
export default {
  name: 'renew-finance-audit',
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Contract: Contract,
    Attachment: Attachment,
    ContractLog: ContractLog,
    ContractGoldWeight: ContractGoldWeight,
    AccountStatement: AccountStatement
  },
  mixins: [dialogMixin, crmMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tabActive: 'tabContract',
      //标签框默认选中
      collapseActive: ['1', '2'],
      //信息折叠默认展开
      attachmentType: ATTACHMENT_TYPE.CONTRACT_STAFF,
      //附件类型
      attachmentTypeReceptionBill: ATTACHMENT_TYPE.RECEPTION_BILL,
      //附件类型：回单凭证
      isAttachmentEdit: false,
      //附件是否可编辑
      showLogDialog: false,
      showOriginalDialog: false,
      //显示原合同
      contractLogType: LOG_TYPE.RENEW,
      attachmentOpened: '',
      //附件打开状态
      goldWeightDetail: {},
      //克重明细
      formModel: {
        bankSerialNumber: null,
        financeConfirmDate: null
      },
      //表单
      rules: {
        receiveAmount: [{
          required: true,
          message: '请填写实收金额',
          trigger: 'blur'
        }],
        financeConfirmDate: [{
          required: true,
          message: '请选择收款日期',
          trigger: 'blur'
        }],
        bankSerialNumber: {
          required: false,
          message: '请填写银行流水号',
          trigger: 'blur'
        }
      },
      accountStatementList: [],
      // 记录银行流水号
      innerVisible: false,
      // 选择银行流水
      selectAccountStatementIndex: null,
      isBatch: false,
      // 批量选择
      isUnfoldAccountStatement: false,
      // 是否展开银行流水
      originalContractId: '',
      BankCardInfo: {},
      // 银行卡信息
      isCompile: false
    };
  },
  computed: {
    // 判断续约类型为 是否为 追加资金续约 合并续约
    isRenewMethod: function isRenewMethod() {
      return this.model.renewMethod == '3' || this.model.renewMethod == '4';
    },
    sumDebtorAmount: function sumDebtorAmount() {
      return this.accountStatementList.reduce(function (sum, item) {
        return sum + item.debtor;
      }, 0);
    }
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.getInfo();
        }
      },
      immediate: true
    },
    'formModel.receiveAmount': {
      handler: function handler(val) {
        if (val > 0) {
          this.rules.bankSerialNumber.required = true;
        } else {
          this.rules.bankSerialNumber.required = false;
        }
      }
    }
  },
  created: function created() {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tabActive = 'tabContract'; //默认打开第一个标签页
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.isCompile = false;
      this.isUnfoldAccountStatement = false;
      this.formModel = {
        financeConfirmDate: null
      };
      this.$refs.Jform.resetFields();
    },
    onAttachmentOpen: function onAttachmentOpen() {
      //初始化标签
      this.tabActive = 'tabContract';
      this.attachmentOpened = 'dialog-position';
    },
    clickAuditFail: function clickAuditFail() {
      //审核不通过
      if (this.formModel.advice == null || this.formModel.advice == '') {
        this.$message('请填写审核意见');
      } else {
        this.audit(AUDIT.NO.value);
      }
    },
    clickAudit: function clickAudit() {
      //审核通过
      var componentAttachmentReceptionBillCount = this.$refs.componentAttachmentReceptionBill.tableData.length; //回单凭证附件数

      var amount = formatAmountToNumber(this.model.amount); //合同金额

      if (this.formModel.receiveAmount > 0 && componentAttachmentReceptionBillCount == 0) {
        this.$message('请上传回单凭证');
      } else if (this.formModel.receiveAmount > amount) {
        this.$message('收款金额不能大于合同金额');
      } else {
        this.audit(AUDIT.YES.value);
      }
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      this.$refs.Jform.validate(function (valid) {
        if (valid) {
          var param = {
            contractId: _this.model.contractId,
            audit: result,
            advice: _this.formModel.advice,
            bankSerialNumber: _this.formModel.bankSerialNumber,
            receiveAmount: _this.formModel.receiveAmount,
            confirmDate: _this.formModel.financeConfirmDate,
            billIdList: _this.formModel.billIdList
          }; // 无批量去除银行id

          if (_this.accountStatementList.length === 0) {
            delete param.billIdList;
          }

          _this.loading = true;
          auditFinance(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit('refresh');
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    // TODO 获取详情
    getInfo: function getInfo() {
      var _this2 = this;

      var contractId = this.propsModel.contractId; //this.loading = true;

      _getInfo({
        contractId: contractId
      }, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        //this.loading = false;
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this2.model = data;
        setExtendAttribute(_this2.model); //包装必要展示字段
        //赎回实物联系方式

        if (res.data.contact != null) {
          var contact = res.data.contact;
          _this2.model.contactName = contact.name;
          _this2.model.contactMobile = contact.mobile;
          _this2.model.contactAddress = contact.address;
        }

        if (_this2.model.financeConfirmDate) {
          _this2.formModel.financeConfirmDate = _this2.model.financeConfirmDate; // 日期
        }

        _this2.formModel.bankSerialNumber = _this2.model.bankSerialNumber; // 银行流水
        //初始实收追加金额

        if (_this2.model.receiveAmount != null) {
          _this2.$set(_this2.formModel, 'receiveAmount', formatAmountToNumber(_this2.model.receiveAmount));
        } else {
          _this2.$set(_this2.formModel, 'receiveAmount', formatAmountToNumber(_this2.model.appendAmount));
        } // 非黄金实物产品需识别省市联行号 productCountType 3 固定日到期（非黄金类）


        getExpectInfo({
          accountNo: _this2.model.accountNo,
          contractId: _this2.model.contractId
        }).then(function (obj) {
          // 识别失败
          if (obj.data) {
            _this2.BankCardInfo = obj.data;
          }
        });
      }).catch(function (e) {
        _this2.loading = false;
      });

      var params = {
        query: {
          contractId: contractId
        }
      }; // 查询已匹配的银行流水号

      list(params).then(function (res) {
        _this2.accountStatementList = res.data;

        if (_this2.accountStatementList.length > 0) {
          _this2.getInitData(true); // 复现匹配统计数据

        } else {
          //  查询未认购合同匹配列表
          listNoBuyMatch({
            contractId: contractId
          }).then(function (res) {
            _this2.accountStatementList = res.data;

            if (_this2.accountStatementList.length > 0) {
              _this2.getInitData(true); // 复现匹配统计数据

            }
          });
        }
      });
    },
    showGoldWeight: function showGoldWeight() {
      //显示克重明细
      this.showGoldWeightDialog = true;
    },
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    },
    // 查看原合同
    clickOriginal: function clickOriginal(index) {
      var ids = this.model.originalContractId.split(',');
      this.originalContractId = ids[index];
      this.showOriginalDialog = true;
    },
    // isBatch true批量 false新增一行
    addAccountStatement: function addAccountStatement() {
      var isBatch = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      // 批量选择
      if (isBatch) {
        this.isBatch = isBatch;
        this.innerVisible = true;
      } else {
        this.isUnfoldAccountStatement = true;
        this.accountStatementList.push({
          debtor: null
        });
      }
    },
    // 选择银行流水
    selectAccountStatement: function selectAccountStatement(index) {
      this.selectAccountStatementIndex = index; // 记录当前选择

      this.innerVisible = true;
    },
    // 取消
    accountStatemenCancel: function accountStatemenCancel() {
      this.innerVisible = false;
      this.isBatch = false;
      this.selectAccountStatementIndex = null;
    },
    // 查询确认
    accountStatemenCancelAudit: function accountStatemenCancelAudit(rows) {
      var isBatch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      // 是否选择重复
      var isAlreadyExist = false;
      this.accountStatementList.forEach(function (item) {
        rows.forEach(function (i) {
          if (item.bankId === i.bankId) {
            isAlreadyExist = true;
          }
        });
      });

      if (isAlreadyExist) {
        return this.$message('不能重复选择银行流水');
      }

      if (isBatch) {
        var _this$accountStatemen;

        (_this$accountStatemen = this.accountStatementList).push.apply(_this$accountStatemen, _toConsumableArray(rows));

        this.getInitData();
      } else {
        // 选择单个
        this.$set(this.accountStatementList, this.selectAccountStatementIndex, _objectSpread({}, rows[0]));
        this.getInitData();
      }
    },
    // 更新 审核数据
    getInitData: function getInitData() {
      var isInit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var maxDate = this.accountStatementList[0].tallyDate; // 取第一个时间

      var getBankSerialNumber = [];
      var bankId = [];
      this.accountStatementList.forEach(function (item) {
        var date1 = new Date(maxDate);
        var date2 = new Date(item.tallyDate);
        maxDate = date1 > date2 ? maxDate : item.tallyDate; // 比较时间大小

        if (item.billNumber) {
          getBankSerialNumber.push(item.billNumber); // 获取流水识别号
        }

        bankId.push(item.bankId); // 流水id
      }); // 判断是否是初始化 有值不替换

      if (isInit) {
        if (this.formModel.financeConfirmDate || maxDate) {
          this.formModel.financeConfirmDate = this.formModel.financeConfirmDate ? this.formModel.financeConfirmDate : maxDate;
        }

        this.formModel.bankSerialNumber = this.formModel.bankSerialNumber ? this.formModel.bankSerialNumber : getBankSerialNumber.join(',');
        this.formModel.billIdList = this.formModel.billIdList ? this.formModel.billIdList : bankId;
      } else {
        this.$set(this.formModel, 'financeConfirmDate', maxDate);
        this.formModel.bankSerialNumber = getBankSerialNumber.join(',');
        this.formModel.billIdList = bankId;
        this.accountStatemenCancel(); // 关闭
      }
    },
    // 删除
    selectAccountDelete: function selectAccountDelete(index, rows) {
      var _this3 = this;

      this.$confirm('将移除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        rows.splice(index, 1);

        if (_this3.accountStatementList.length > 0) {
          _this3.getInitData();
        }

        _this3.$message({
          type: 'success',
          message: '移除成功!'
        });
      }).catch(function () {});
    },
    // 可否编辑
    clickCompile: function clickCompile() {
      this.isCompile = !this.isCompile;
    },
    // 暂存
    clickSave: function clickSave() {
      var _this4 = this;

      this.$confirm("\u662F\u5426\u786E\u8BA4\u4FDD\u5B58", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var param = {
          object: {}
        };
        param.object = {
          id: _this4.BankCardInfo.id,
          accountNo: _this4.model.accountNo,
          accountName: _this4.model.accountName,
          idType: _this4.model.idType,
          identification: _this4.model.identification,
          branchName: _this4.model.bankName,
          customerCode: _this4.model.customerCode,
          mobile: _this4.model.mobile,
          province: _this4.BankCardInfo.province,
          city: _this4.BankCardInfo.city,
          bankCode: _this4.BankCardInfo.bankCode,
          isCertify: _this4.BankCardInfo.isCertify
        };
        _this4.loading = true;
        bankCardInfoSave(param).then(function (res) {
          _this4.loading = false;

          _this4.$message({
            message: '信息已保存',
            type: 'success'
          });

          _this4.$emit('refresh');
        }).catch(function (e) {
          _this4.loading = false;
        });
      }).catch(function () {});
    }
  }
};