var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "contract-add-dialog",
      attrs: {
        "custom-class": _vm.attachmentOpened,
        top: "10vh",
        width: "1100px",
        title: _vm.title,
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabActive,
            callback: function($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "合同信息", name: "tabContract" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formModel,
                    rules: _vm.rules,
                    "label-width": "120px",
                    size: "mini"
                  }
                },
                [
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("基本")
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "合同编号", prop: "contractCd" }
                            },
                            [
                              _c("el-input", {
                                on: {
                                  change: function($event) {
                                    return _vm.inputToUpperCase()
                                  }
                                },
                                model: {
                                  value: _vm.formModel.contractCd,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "contractCd", $$v)
                                  },
                                  expression: "formModel.contractCd"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "姓名", prop: "customerName" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { readonly: "" },
                                  model: {
                                    value: _vm.formModel.customerName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "customerName",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.customerName"
                                  }
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "append" },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "right",
                                            width: "780",
                                            trigger: "click"
                                          },
                                          model: {
                                            value: _vm.showSearchCustomer,
                                            callback: function($$v) {
                                              _vm.showSearchCustomer = $$v
                                            },
                                            expression: "showSearchCustomer"
                                          }
                                        },
                                        [
                                          _c("search-customer", {
                                            ref: "searchCustomer",
                                            attrs: {
                                              "sch-word":
                                                _vm.formModel.customerName
                                            },
                                            on: {
                                              onSelectItem:
                                                _vm.popSelectCustomer
                                            }
                                          }),
                                          _c("el-button", {
                                            attrs: {
                                              slot: "reference",
                                              type: "primary",
                                              icon: "el-icon-zoom-in",
                                              plain: ""
                                            },
                                            slot: "reference"
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "证件类型", prop: "idType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                    disabled: ""
                                  },
                                  on: { change: _vm.changeIdType },
                                  model: {
                                    value: _vm.formModel.idType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, "idType", $$v)
                                    },
                                    expression: "formModel.idType"
                                  }
                                },
                                _vm._l(_vm.selectData.idType, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证件号码",
                                prop: "identification"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                on: {
                                  change: function($event) {
                                    _vm.changeIdentification()
                                    _vm.inputToUpperCase()
                                  }
                                },
                                model: {
                                  value: _vm.formModel.identification,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      "identification",
                                      $$v
                                    )
                                  },
                                  expression: "formModel.identification"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系方式", prop: "mobile" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.mobile,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "mobile", $$v)
                                  },
                                  expression: "formModel.mobile"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "顾问", prop: "userName" } },
                            [
                              _c("el-input", {
                                attrs: { readonly: true },
                                model: {
                                  value: _vm.formModel.userName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "userName", $$v)
                                  },
                                  expression: "formModel.userName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "机构", prop: "orgName" } },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: _vm.formModel.orgName,
                                    placement: "top-start"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { readonly: true },
                                    model: {
                                      value: _vm.formModel.orgName,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formModel, "orgName", $$v)
                                      },
                                      expression: "formModel.orgName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户生日", prop: "birthday" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  disabled: "",
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd"
                                },
                                model: {
                                  value: _vm.formModel.birthday,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "birthday", $$v)
                                  },
                                  expression: "formModel.birthday"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "性别", prop: "sex" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    disabled: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.formModel.sex,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, "sex", $$v)
                                    },
                                    expression: "formModel.sex"
                                  }
                                },
                                _vm._l(_vm.selectData.sex, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "紧急联系人", prop: "emName" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.emName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "emName", $$v)
                                  },
                                  expression: "formModel.emName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "紧急联系方式", prop: "emMobile" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.emMobile,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "emMobile", $$v)
                                  },
                                  expression: "formModel.emMobile"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "邮箱", prop: "email" } },
                            [
                              _c("el-input", {
                                attrs: { type: "email" },
                                model: {
                                  value: _vm.formModel.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "email", $$v)
                                  },
                                  expression: "formModel.email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "客户编码", prop: "customerCode" }
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: true },
                                model: {
                                  value: _vm.formModel.customerCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "customerCode", $$v)
                                  },
                                  expression: "formModel.customerCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "住址", prop: "address" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.address,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "address", $$v)
                                  },
                                  expression: "formModel.address"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("产品")
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品", prop: "productId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    filterable: "",
                                    placeholder: "请选择"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.changeProduct()
                                    }
                                  },
                                  model: {
                                    value: _vm.formModel.productId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, "productId", $$v)
                                    },
                                    expression: "formModel.productId"
                                  }
                                },
                                _vm._l(_vm.selectData.productId, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "期限(月)", prop: "timeLimit" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.changeTimeLimit()
                                      _vm.changeEarnings()
                                    }
                                  },
                                  model: {
                                    value: _vm.formModel.timeLimit,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, "timeLimit", $$v)
                                    },
                                    expression: "formModel.timeLimit"
                                  }
                                },
                                _vm._l(_vm.selectData.timeLimit, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "返还周期", prop: "returnPeriod" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.formModel.returnPeriod,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "returnPeriod",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.returnPeriod"
                                  }
                                },
                                _vm._l(_vm.selectData.returnPeriod, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收款方式", prop: "payMethod" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.formModel.payMethod,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, "payMethod", $$v)
                                    },
                                    expression: "formModel.payMethod"
                                  }
                                },
                                _vm._l(_vm.selectData.payMethod, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "开始日期", prop: "startDate" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd"
                                },
                                on: {
                                  change: function($event) {
                                    _vm.changeStartDate()
                                    _vm.changeEarnings()
                                  }
                                },
                                model: {
                                  value: _vm.formModel.startDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "startDate", $$v)
                                  },
                                  expression: "formModel.startDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "到期日期", prop: "endDate" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.changeEarnings()
                                  }
                                },
                                model: {
                                  value: _vm.formModel.endDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "endDate", $$v)
                                  },
                                  expression: "formModel.endDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "合同金额", prop: "amount" } },
                            [
                              _c("el-input", {
                                attrs: { type: "number", title: "" },
                                on: {
                                  change: function($event) {
                                    return _vm.changeEarnings()
                                  }
                                },
                                model: {
                                  value: _vm.formModel.amount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "amount", $$v)
                                  },
                                  expression: "formModel.amount"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("银行")
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "类型", prop: "accountType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.formModel.accountType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "accountType",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.accountType"
                                  }
                                },
                                _vm._l(_vm.selectData.accountType, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "户名", prop: "accountName" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.accountName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "accountName", $$v)
                                  },
                                  expression: "formModel.accountName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 9 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "银行账号", prop: "accountNo" } },
                            [
                              _c("el-input", {
                                staticClass: "inline-input",
                                staticStyle: { width: "270px" },
                                attrs: {
                                  oninput: "value = value.replace(/\\s/g, '')",
                                  placeholder: "请输入银行卡号"
                                },
                                model: {
                                  value: _vm.formModel.accountNo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "accountNo", $$v)
                                  },
                                  expression: "formModel.accountNo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "银行", prop: "bankName" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "银行+分行+支行" },
                                model: {
                                  value: _vm.formModel.bankName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "bankName", $$v)
                                  },
                                  expression: "formModel.bankName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("其他")
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否换人续约",
                                prop: "substitution"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                    disabled: _vm.formModel.isWithdraw === "5"
                                  },
                                  model: {
                                    value: _vm.formModel.substitution,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "substitution",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.substitution"
                                  }
                                },
                                _vm._l(_vm.selectData.originType, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.formModel.substitution === "2"
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "原客户姓名",
                                    prop: "originCustomerName"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { readonly: "" },
                                      model: {
                                        value: _vm.formModel.originCustomerName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formModel,
                                            "originCustomerName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formModel.originCustomerName"
                                      }
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "append" },
                                        [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "right",
                                                width: "780",
                                                trigger: "click"
                                              },
                                              model: {
                                                value:
                                                  _vm.showOriginCustomerName,
                                                callback: function($$v) {
                                                  _vm.showOriginCustomerName = $$v
                                                },
                                                expression:
                                                  "showOriginCustomerName"
                                              }
                                            },
                                            [
                                              _c("search-customer", {
                                                ref: "searchCustomer",
                                                attrs: {
                                                  "sch-word":
                                                    _vm.formModel
                                                      .originCustomerName
                                                },
                                                on: {
                                                  onSelectItem:
                                                    _vm.popSelectOriginCustomerName
                                                }
                                              }),
                                              _c("el-button", {
                                                attrs: {
                                                  slot: "reference",
                                                  type: "primary",
                                                  icon: "el-icon-zoom-in",
                                                  plain: ""
                                                },
                                                slot: "reference"
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否统计业绩",
                                prop: "isPerformance"
                              }
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "1" },
                                  model: {
                                    value: _vm.formModel.isPerformance,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "isPerformance",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.isPerformance"
                                  }
                                },
                                [_vm._v("是")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "0" },
                                  model: {
                                    value: _vm.formModel.isPerformance,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "isPerformance",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.isPerformance"
                                  }
                                },
                                [_vm._v("否")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否出金后续约",
                                prop: "isWithdraw"
                              }
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    label: "5",
                                    disabled: _vm.formModel.substitution === "2"
                                  },
                                  model: {
                                    value: _vm.formModel.isWithdraw,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, "isWithdraw", $$v)
                                    },
                                    expression: "formModel.isWithdraw"
                                  }
                                },
                                [_vm._v("是")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    label: "0",
                                    disabled: _vm.formModel.substitution === "2"
                                  },
                                  model: {
                                    value: _vm.formModel.isWithdraw,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, "isWithdraw", $$v)
                                    },
                                    expression: "formModel.isWithdraw"
                                  }
                                },
                                [_vm._v("否")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.formModel.isWithdraw === "5"
                        ? _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "关联合同",
                                    prop: "originalContractCd"
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: { readonly: "" },
                                      model: {
                                        value: _vm.formModel.originalContractCd,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formModel,
                                            "originalContractCd",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formModel.originalContractCd"
                                      }
                                    },
                                    [
                                      _c(
                                        "template",
                                        { slot: "append" },
                                        [
                                          _c("search-contract", {
                                            ref: "searchContract",
                                            on: {
                                              onSelectItem:
                                                _vm.popSelectContract
                                            },
                                            model: {
                                              value:
                                                _vm.showSearchContractDialog,
                                              callback: function($$v) {
                                                _vm.showSearchContractDialog = $$v
                                              },
                                              expression:
                                                "showSearchContractDialog"
                                            }
                                          }),
                                          _c("el-button", {
                                            attrs: {
                                              slot: "reference",
                                              type: "primary",
                                              icon: "el-icon-zoom-in",
                                              plain: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.showSearchContractDialog = true
                                              }
                                            },
                                            slot: "reference"
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "remark" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.remark,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "remark", $$v)
                                  },
                                  expression: "formModel.remark"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "业绩归属日",
                                prop: "bussinessDate"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd"
                                },
                                model: {
                                  value: _vm.formModel.bussinessDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      "bussinessDate",
                                      $$v
                                    )
                                  },
                                  expression: "formModel.bussinessDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.formModel.contractId != null && _vm.formModel.contractId != ""
            ? _c(
                "el-tab-pane",
                { attrs: { label: "附件", name: "tabAttachment" } },
                [
                  _c("attachment", {
                    ref: "componentAttachment",
                    attrs: {
                      "is-attachment-edit": _vm.isAttachmentCanEdit,
                      "attachment-contract-id": _vm.formModel.contractId,
                      "attachment-type": _vm.attachmentType,
                      "attachment-product-id": _vm.formModel.productId,
                      "id-type": _vm.formModel.idType,
                      "rent-ransom-type": _vm.formModel.rentRansomType
                    },
                    on: { onAttachmentOpen: _vm.onAttachmentOpen }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "contract-log-tip",
              attrs: { plain: "", size: "mini", icon: "el-icon-finished" },
              on: {
                click: function($event) {
                  return _vm.showLog()
                }
              }
            },
            [_vm._v("审核日志")]
          ),
          _vm.isCanRemove
            ? _c(
                "el-button",
                {
                  attrs: { plain: "", type: "danger", size: "mini" },
                  on: { click: _vm.remove }
                },
                [_vm._v("删 除")]
              )
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                plain: "",
                size: "mini",
                loading: _vm.loading,
                disabled: !_vm.isFormChange
              },
              on: { click: _vm.clickSave }
            },
            [_vm._v("暂存")]
          ),
          _vm.isCanSubmit
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    size: "mini",
                    loading: _vm.loading
                  },
                  on: { click: _vm.clickSubmit }
                },
                [_vm._v("提交")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.formModel.contractId != ""
        ? _c("contract-log", {
            attrs: {
              "contract-id": _vm.formModel.contractId,
              active: _vm.contractLogType
            },
            model: {
              value: _vm.showLogDialog,
              callback: function($$v) {
                _vm.showLogDialog = $$v
              },
              expression: "showLogDialog"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }