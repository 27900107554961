import _toConsumableArray from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import ROLE from '@/maps/enum/role';
import { list as listRole, setUserRole, listUserRole } from '@api/rm/role';
import { getEnumList } from '@api/common/common';
import CRMENUM from '@/maps/enum/dingTalkCrm';
export default {
  name: 'dialog-person-role',
  props: {
    // 当前职员
    person: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      tableData: [],
      roleList: [],
      //全部角色列表
      searchKey: {},
      //搜索key
      radio: null,
      //单选
      checkboxRows: [],
      rules: {
        loginName: [{
          required: true,
          message: '请填写客户系统账号',
          trigger: 'input'
        }],
        password: [{
          required: true,
          message: '请填写客户密码',
          trigger: 'input'
        }]
      },
      selectData: {},
      isAdmin: ROLE.ADMIN.value
    };
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    }
  },
  created: function created() {
    var _this = this;

    //收款方式
    getEnumList({
      enumCd: CRMENUM.BELONGSYSTEM
    }).then(function (res) {
      _this.selectData.belongSystem = res;
    });
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.searchKey = {};
      this.checkboxRows = [];
      this.radio = null;
      this.loadData();
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
      this.showDialog = false;
      this.checkboxRows = [];
      this.$refs.multipleTable.clearSelection();
    },
    //加载数据
    loadData: function loadData() {
      var _this2 = this;

      if (this.person.personuuid != null) {
        this.tableData = [];
        this.roleList = [];
        var paramAll = {
          page: {
            pageNumber: 0,
            pageSize: 999
          },
          query: {
            enabled: '1'
          }
        };
        var paramUser = {
          userId: this.person.personuuid
        };
        var promiseArr = []; //多个请求数组

        promiseArr[0] = listRole(paramAll); //全部角色列表

        promiseArr[1] = listUserRole(paramUser); //当前用户的角色

        Promise.all(promiseArr).then(function (res) {
          _this2.loading = false; //列表排除管理员，避免被误选

          res[0].data.forEach(function (n) {
            _this2.roleList.push(n);
          });
          _this2.tableData = _toConsumableArray(_this2.roleList); //当前用户已选择
          //多选

          if (res[1].data != null) {
            _this2.checkboxRows = res[1].data;

            if (_this2.checkboxRows != null && _this2.checkboxRows.length > 0) {
              _this2.$nextTick(function () {
                _this2.checkboxRows.forEach(function (n) {
                  if (n != null) {
                    var row = _this2.tableData.find(function (item) {
                      return item.id == n.id;
                    });

                    _this2.$refs.multipleTable.toggleRowSelection(row, true);
                  }
                });
              });
            }
          } //单选

          /*if(res[1].data!=null && res[1].data.length>0) {
            this.radio = res[1].data[0].id;
          }*/

        });
      }
    },
    // TODO 确定
    clickSave: function clickSave() {
      var _this3 = this;

      //多选
      if (this.checkboxRows.length == 0) {
        this.$message('请选择角色');
      } else {
        var arrayRoleId = [];
        this.checkboxRows.forEach(function (n) {
          if (n != null) {
            arrayRoleId.push(n.id);
          }
        });
        var param = {
          userId: this.person.personuuid,
          arrayRoleId: arrayRoleId
        };
        setUserRole(param).then(function (res) {
          _this3.loading = false;

          _this3.$message({
            message: '设置角色成功',
            type: 'success'
          });

          _this3.dialogClose();
        });
      } //单选

      /*
      if(this.radio==null || this.radio=='') {
        this.$message("请选择角色");
      } else {
          let arrayRoleId = [];
          arrayRoleId[0] = this.radio;          
          let param = {
            userId: this.person.personuuid,
            arrayRoleId: arrayRoleId
          };
          setUserRole(param).then(res=> {
            this.loading = false;            
            this.$message({ message: "设置角色成功", type: "success" });              
            this.dialogClose();
          });           
      }*/

    },
    // 复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      this.checkboxRows = rows;
    },
    selectChange: function selectChange(selection, row) {
      console.log('selection', selection);
    },
    // 健盘输入搜索角色
    keyupRoleSearch: function keyupRoleSearch() {
      var _this$searchKey = this.searchKey,
          searchKeyName = _this$searchKey.searchKeyName,
          belongSystem = _this$searchKey.belongSystem;
      this.tableData = []; // 选择归属系统 人名

      if (searchKeyName && belongSystem) {
        for (var i = 0; i < this.roleList.length; i++) {
          var n = this.roleList[i];

          if (n.name.indexOf(searchKeyName) > -1 && n.belongSystem === belongSystem) {
            this.tableData.push(n);
          }
        }
      } else if (searchKeyName) {
        // 输入 人名
        for (var _i = 0; _i < this.roleList.length; _i++) {
          var _n = this.roleList[_i];

          if (_n.name.indexOf(searchKeyName) > -1) {
            this.tableData.push(_n);
          }
        }
      } else if (belongSystem) {
        // 选择 系统
        for (var _i2 = 0; _i2 < this.roleList.length; _i2++) {
          var _n2 = this.roleList[_i2];

          if (_n2.belongSystem === belongSystem) {
            this.tableData.push(_n2);
          }
        }
      } else {
        this.tableData = this.roleList;
      }
    },
    // 移除选中
    deleteRow: function deleteRow(index, rows) {
      var _this4 = this;

      var row = this.tableData.find(function (item) {
        return item.id == _this4.checkboxRows[index].id;
      });
      this.$refs.multipleTable.toggleRowSelection(row, false);
      rows.splice(index, 1);
    },
    // 保存选中的数据id,row-key就是要指定一个key标识这一行的数据
    getRowKey: function getRowKey(row) {
      return row.id;
    },
    // 禁用 管理员
    checkSelectable: function checkSelectable(row) {
      var checkSelectable = true;

      if (this.isAdmin === row.code) {
        checkSelectable = false;
      }

      return checkSelectable;
    }
  }
};