import { useStore } from '@/store';
import { computed } from '@vue/composition-api';
export default function useUser() {
  var store = useStore();
  /**
   * 设置用户信息
   * @param user 用户信息
   * @returns Promise<user>
   */

  var setUser = function setUser(user) {
    return store.dispatch('d2admin/user/set', user, {
      root: true
    });
  };
  /**
   * 用户信息
   */


  var userInfo = computed(function () {
    return store.getters['d2admin/user/get'];
  });
  return {
    /** 设置用户信息 */
    setUser: setUser,

    /** 用户信息 */
    userInfo: userInfo
  };
}