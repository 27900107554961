









































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/clear/expire/index.vue"
}
