import "core-js/modules/es.json.stringify.js";

/** 解析参数 */
export default function useJSON() {
  /** 解析 json 字符串 */
  var parse = function parse(str, options) {
    var _json;

    var _ref = options || {},
        _ref$defaultValue = _ref.defaultValue,
        defaultValue = _ref$defaultValue === void 0 ? [] : _ref$defaultValue;

    try {
      _json = JSON.parse(str);
    } catch (e) {
      console.error('json parse error');
      _json = defaultValue;
    }

    return _json;
  };
  /** 转为字符串 */


  var stringify = function stringify(source) {
    return JSON.stringify(source);
  };

  return {
    /** 解析 json 字符串 */
    parse: parse,

    /** 转为字符串 */
    stringify: stringify
  };
}