



























































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/activity/evaluation/index.vue"
}
