//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '寄件日期',
  prop: 'sendDate',
  width: VAL_WIDTH
}, {
  label: '快递状态',
  prop: 'expressStatus',
  width: VAL_WIDTH,
  type: 'slot'
}, {
  label: '类型',
  prop: 'expressTypeText',
  width: VAL_WIDTH
}, {
  label: '寄出机构',
  prop: 'sendOrgName',
  width: '210px'
}, {
  label: '寄件人',
  prop: 'sender',
  width: VAL_WIDTH
}, {
  label: '收件机构',
  prop: 'receiveOrgName',
  width: '210px'
}, {
  label: '收件人',
  prop: 'receiver',
  width: VAL_WIDTH
}, {
  label: '单号',
  prop: 'expressNo',
  width: '180px'
}, {
  label: '快递公司',
  prop: 'expressCompanyName',
  width: VAL_WIDTH
}];