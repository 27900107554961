var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          filtersFormatter: _vm.filtersFormatter,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        }
      }),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "新增/编辑任务",
            height: "815px",
            width: "800px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            confirm: _vm.dialogConfirm,
            close: _vm.dialogClose
          }
        },
        [
          _vm.dialogFormData
            ? _c("j-form", {
                ref: "form",
                attrs: {
                  formConfig: _vm.dialogFormConfig,
                  formData: _vm.dialogFormData
                },
                on: {
                  "update:formData": function($event) {
                    _vm.dialogFormData = $event
                  },
                  "update:form-data": function($event) {
                    _vm.dialogFormData = $event
                  },
                  submit: _vm.dialogSubmit
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "rewardScore",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "score" }, [
                            _c(
                              "div",
                              { staticClass: "score-item" },
                              [
                                _c(
                                  "el-checkbox",
                                  { attrs: { value: true, disabled: true } },
                                  [_vm._v("积分")]
                                ),
                                _vm._v(" 赠送 "),
                                _c("el-input", {
                                  staticClass: "input",
                                  model: {
                                    value: _vm.dialogFormData.rewardScore,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.dialogFormData,
                                        "rewardScore",
                                        $$v
                                      )
                                    },
                                    expression: "dialogFormData.rewardScore"
                                  }
                                }),
                                _vm._v(" 积分 ")
                              ],
                              1
                            ),
                            _vm.dialogFormData.type === "3"
                              ? _c(
                                  "div",
                                  { staticClass: "score-item" },
                                  [
                                    _vm._v(" 每邀请 "),
                                    _c("el-input", {
                                      staticClass: "input",
                                      model: {
                                        value: _vm.dialogFormData.inviterCount,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.dialogFormData,
                                            "inviterCount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dialogFormData.inviterCount"
                                      }
                                    }),
                                    _vm._v(" 人 额外赠 "),
                                    _c("el-input", {
                                      staticClass: "input",
                                      model: {
                                        value:
                                          _vm.dialogFormData.inviteRewardScore,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.dialogFormData,
                                            "inviteRewardScore",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dialogFormData.inviteRewardScore"
                                      }
                                    }),
                                    _vm._v(" 积分 ")
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2834182455
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }