export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    //营销活动

    /** 新增 */
    MARKETING_ADD: function MARKETING_ADD(data) {
      return request({
        url: url.marketingCampaign.manage.add,
        data: data
      });
    },

    /** 更新 */
    MARKETING_UPDATE: function MARKETING_UPDATE(data) {
      return request({
        url: url.marketingCampaign.manage.update,
        data: data
      });
    },

    /** 更新状态 */
    MARKETING_UPDATESTATUS: function MARKETING_UPDATESTATUS(data) {
      return request({
        url: url.marketingCampaign.manage.updateStatus,
        data: data
      });
    },

    /** 删除 */
    MARKETING_DELETE: function MARKETING_DELETE(data) {
      return request({
        url: url.marketingCampaign.manage.delete,
        data: data
      });
    },

    /** 获取详情 */
    MARKETING_GETINFOBYID: function MARKETING_GETINFOBYID(data) {
      return request({
        url: url.marketingCampaign.manage.getInfoById,
        data: data
      });
    },

    /** 设置虚拟礼品状态 */
    MARKETING_UPDATEVIRTUALGIFT: function MARKETING_UPDATEVIRTUALGIFT(data) {
      return request({
        url: url.marketingCampaign.manage.updateVirtualGiftStatus,
        data: data
      });
    },

    /** 营销活动列表 */
    MARKETING_GETMARKETINGPAGE: function MARKETING_GETMARKETINGPAGE(data) {
      return request({
        url: url.marketingCampaign.manage.getMarketingActivityPage,
        data: data
      });
    },

    /** 营销活动详情列表 */
    MARKETING_GETMARKETINGDETAILSPAGE: function MARKETING_GETMARKETINGDETAILSPAGE(data) {
      return request({
        url: url.marketingCampaign.manage.getMarketingActivityDetailsPage,
        data: data
      });
    },
    // 虚拟礼品

    /** 新增 */
    MARKETING_VIRTUALGIFTADD: function MARKETING_VIRTUALGIFTADD(data) {
      return request({
        url: url.marketingCampaign.virtualGift.addVirtualGift,
        data: data
      });
    },

    /** 获取列表 */
    MARKETING_VIRTUALGIFTLIST: function MARKETING_VIRTUALGIFTLIST(data) {
      return request({
        url: url.marketingCampaign.virtualGift.getList,
        data: data
      });
    },

    /** 删除 */
    MARKETING_VIRTUALGIFTDELETE: function MARKETING_VIRTUALGIFTDELETE(data) {
      return request({
        url: url.marketingCampaign.virtualGift.delete,
        data: data
      });
    },

    /** 导入券码 */
    MARKETING_VIRTUALGIFTCODE: function MARKETING_VIRTUALGIFTCODE(data) {
      return request({
        url: url.marketingCampaign.virtualGift.importCouponCode,
        data: data,
        responseType: 'arraybuffer'
      });
    }
  };
});