// 弹窗中form表单配置
export var DIALOG_FORM_CONFIG = {
  id: {
    hidden: true
  },
  linkUrl: {
    type: 'uploadVideo',
    label: '视频',
    props: {
      placeholder: '最多上传1个文件，文件大小不超过200MB，支持mp4,qlv,qsv,ogg,flv,avi,wmv,rmvb格式'
    },
    rules: [{
      required: true,
      message: '视频不能为空',
      trigger: 'blur'
    }]
  },
  coverUrl: {
    type: 'uploadImage',
    label: '视频封面',
    props: {
      placeholder: '最多可传1张，尺寸为：600*320，图片大小不超过200KB，支gif, jpeg, jpeg, jpg, png, bmp格式'
    },
    rules: [{
      required: true,
      message: '视频封面不能为空',
      trigger: 'blur'
    }]
  },
  name: {
    type: 'input',
    label: '标题',
    rules: [{
      required: true,
      message: '标题不能为空',
      trigger: 'blur'
    }]
  },
  introduction: {
    type: 'input',
    label: '简介',
    props: {
      type: 'textarea',
      rows: 4,
      maxlength: 300,
      showWordLimit: true
    }
  }
};
export var DIALOG_FORM_DETAIL = {
  id: {
    hidden: true
  },
  linkUrl: {
    type: 'uploadVideo',
    label: '视频',
    props: {
      placeholder: '最多上传1个文件，文件大小不超过200MB，支持mp4,m4v,avi,mov,flv格式',
      disabled: true,
      timeout: 5 * 60 * 1000
    }
  },
  coverUrl: {
    type: 'uploadImage',
    label: '视频封面',
    props: {
      placeholder: '最多可传1张，图片大小不超过200KB，支GIF,JPEG,JPG,PNG,BMP格式',
      disabled: true
    }
  },
  name: {
    type: 'input',
    label: '标题',
    props: {
      readonly: true
    }
  },
  introduction: {
    type: 'input',
    label: '简介',
    props: {
      type: 'textarea',
      rows: 4,
      maxlength: 300,
      showWordLimit: true,
      readonly: true
    }
  }
};
export var DIALOG_FORM_DATA = {
  id: null,
  linkUrl: null,
  coverUrl: null,
  name: '',
  introduction: ''
};