export var TABLE_LABEL = [{
  label: '标签',
  prop: 'name'
}, {
  label: '背景色',
  prop: 'backgroundColor',
  type: 'slot'
}, {
  label: '创建时间',
  prop: 'createTime'
}, {
  label: '创建人',
  prop: 'createUserName'
}, {
  label: '更新时间',
  prop: 'updateTime'
}, {
  label: '更新人',
  prop: 'updateUserName'
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [{
    label: '新增',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'customerLabel-add'
  }, {
    label: '删除',
    type: 'delete',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'customerLabel-delete'
  }]
};