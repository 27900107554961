export default {
  checkProcess: {
    buy: '认购',
    // 认购
    count: '核算',
    // 核算
    apply: '赎回',
    // 赎回
    renew: '续约',
    // 续约
    interest: '付息',
    // 付息
    change: '变更',
    // 变更
    commission: '推介费',
    // 推介费
    gift: '礼品',
    // 礼品
    clear: '手动清算' // 手动清算

  }
};