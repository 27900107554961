import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import OPERATE from "@/maps/enum/operate";
import { listTeam, listOrgPerson, applyLog } from "@api/rm/team";
export default {
  name: "team-apply-remove",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      teamManager: "",
      //团队总监
      teamPerson: "",
      //团队成员
      orgPerson: [],
      //公司全体成员
      selectData: {
        team: [] //团队

      },
      rules: {
        team: [{
          type: "String",
          required: true,
          message: "请选择团队",
          trigger: "change"
        }],
        description: [{
          required: true,
          message: "请填写解散团队原因",
          trigger: "input"
        }]
      }
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadTeam();
          this.loadPerson();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.formModel = {};
      this.teamManager = "";
      this.teamPerson = "";
      this.selectData = {
        team: [] //团队

      };
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    loadTeam: function loadTeam() {
      var _this = this;

      this.loading = true;
      var param = {
        page: {
          pageNumber: 1,
          pageSize: 999
        },
        query: {
          level: "3"
        }
      };
      listTeam(param).then(function (res) {
        _this.loading = false;
        _this.selectData.team = res.data;
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询团队列表出错",
          type: "error"
        });
      });
    },
    // TODO 获取列表
    loadPerson: function loadPerson() {
      var _this2 = this;

      this.loading = true;
      listOrgPerson().then(function (res) {
        _this2.loading = false;
        _this2.orgPerson = res.data;
      }).catch(function (e) {
        _this2.loading = false;

        _this2.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    //选择团队
    changeTeam: function changeTeam() {
      var teamId = this.formModel.team;
      this.filterTeamPerson(teamId);
    },
    //筛选团队成员
    filterTeamPerson: function filterTeamPerson(teamId) {
      //标记和去掉已加入其他团队成员
      this.teamManager = "";
      this.teamPerson = "";

      for (var i = 0; i < this.orgPerson.length; i++) {
        var n = this.orgPerson[i];

        if (n.teamId == teamId) {
          if (n.areaTitle == "1") {
            this.teamManager = n.personName;
          } else {
            this.teamPerson += n.personName + ",";
          }
        }
      }
    },
    // TODO 确定
    confirm: function confirm() {
      var _this3 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this3.formModel.team == null || _this3.formModel.team == "") {
            _this3.$message({
              message: "请选择团队",
              type: "warning"
            });
          } else {
            _this3.$confirm("确定要申请解散该团队?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }).then(function () {
              var actionDesc = "申请解散团队: "; //操作描述

              var team = _this3.selectData.team.find(function (item) {
                return item.id === _this3.formModel.team;
              });

              if (team != null) {
                actionDesc += team.name + "; ";
                actionDesc += "原因：" + _this3.formModel.description + ";";
                var actionParam = {
                  action: OPERATE.REMOVE //动作(add/change/remove)                   

                }; //动作

                var param = {
                  teamId: team.id,
                  applyDesc: actionDesc,
                  applyAction: JSON.stringify(actionParam)
                };
                applyLog({
                  object: param
                }).then(function (res) {
                  _this3.loading = false;
                  _this3.showDialog = false;

                  _this3.$emit("refresh");
                }).catch(function (e) {
                  _this3.loading = false;
                });
              }
            }).catch(function () {});
          }
        } else {
          return false;
        }
      });
    }
  }
};