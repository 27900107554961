//启用
export default {
  MALE: {
    value: '1',
    text: '男'
  },
  FEMALE: {
    value: '0',
    text: '女'
  }
};