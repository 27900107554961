var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        top: "20vh",
        width: "950px",
        title: "划款明细",
        visible: _vm.showDialog
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-tabs",
        { attrs: { value: "tabContract" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "合同信息", name: "tabContract" } },
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.collapseActive,
                    callback: function($$v) {
                      _vm.collapseActive = $$v
                    },
                    expression: "collapseActive"
                  }
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "合同信息", name: "1" } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("合同编号:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.contractCd) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("姓名:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.customerName))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("机构:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.orgName) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("开始日期:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.startDate) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("结束日期:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.endDate) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("期限:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.timeLimit) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("产品:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.productName))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("积存类型:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.saveTypeText) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("划款日:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.cutDate) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("总积存金额:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.totalAmount) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("总积存量:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.totalWeight) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("应划款日期:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.model.planTransferDate) + " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("月积存额:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.monthAmount) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("月积存量:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.monthWeight) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("应划款金额:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.model.planTransferAmount) + " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("划款明细")
      ]),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            "row-style": { height: "20px" },
            "cell-style": { padding: "6px 0" },
            height: "250"
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "50" }
          }),
          _c("el-table-column", {
            attrs: { prop: "planTransferDate", label: "应划日期", width: "100" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "actualTransferDate",
              label: "实划日期",
              width: "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "goldPrice",
              label: "金价",
              width: "80",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "planTransferAmount",
              label: "应划金额",
              width: "100",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "transferAmount",
              label: "实划金额",
              width: "100",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "transferState", label: "状态", width: "80" }
          }),
          _c("el-table-column", {
            attrs: { prop: "transferRemark", label: "备注", width: "220" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: { slot: "reference", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.clickLog(scope.row)
                          }
                        },
                        slot: "reference"
                      },
                      [_vm._v("明细")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          )
        ],
        1
      ),
      _c("dialog-log", {
        attrs: { propsModel: _vm.currentRow },
        model: {
          value: _vm.showLogDialog,
          callback: function($$v) {
            _vm.showLogDialog = $$v
          },
          expression: "showLogDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }