var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        "custom-class": _vm.attachmentOpened,
        top: "10vh",
        width: "800px",
        title: "审核",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabActive,
            callback: function($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "合同信息", name: "tabContract" } },
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.collapseActive,
                    callback: function($$v) {
                      _vm.collapseActive = $$v
                    },
                    expression: "collapseActive"
                  }
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "合同信息", name: "1" } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v(_vm._s(_vm.labelContractCd) + ":")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.contractCd) + " ")
                          ]),
                          _vm.isHaveContractBuyCd
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v(_vm._s(_vm.labelBuyContractCd) + ":")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.buyContractCd) + " "
                                )
                              ])
                            : _vm._e(),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("变更状态:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.model.changeApplyStateText) + " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("姓名:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.customerName))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("证件类型:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.idTypeText) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("证件号码:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.identification) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("联系方式:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.mobile)),
                            _vm.model.mobile != _vm.oldModel.mobile
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s("(原:" + _vm.oldModel.mobile + ")")
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("email:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.email)),
                            _vm.model.email != _vm.oldModel.email
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s("(原:" + _vm.oldModel.email + ")")
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("住址:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.address)),
                            _vm.model.address != _vm.oldModel.address
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s("(原:" + _vm.oldModel.address + ")")
                                  )
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("紧急联系人:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.emName) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("紧急联系方式:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.emMobile) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("机构:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.orgName)),
                            _vm.model.orgName != _vm.oldModel.orgName
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s("(原:" + _vm.oldModel.orgName + ")")
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("顾问:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.userName)),
                            _vm.model.userName != _vm.oldModel.userName
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s("(原:" + _vm.oldModel.userName + ")")
                                  )
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      ),
                      _c("el-divider"),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("产品:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.productName))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("合同金额:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.amount)),
                            _vm.model.amount != _vm.oldModel.amount
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s("(原:" + _vm.oldModel.amount + ")")
                                  )
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("期限(月):")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.timeLimit)),
                            _vm.model.timeLimit != _vm.oldModel.timeLimit
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s(
                                      "(原:" + _vm.oldModel.timeLimit + ")"
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("返还周期(月):")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.returnPeriod)),
                            _vm.model.returnPeriod != _vm.oldModel.returnPeriod
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s(
                                      "(原:" + _vm.oldModel.returnPeriod + ")"
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("收款方式:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.payMethodText)),
                            _vm.model.payMethodText !=
                            _vm.oldModel.payMethodText
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s(
                                      "(原:" + _vm.oldModel.payMethodText + ")"
                                    )
                                  )
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("开始日期:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.startDate)),
                            _vm.model.startDate != _vm.oldModel.startDate
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s(
                                      "(原:" + _vm.oldModel.startDate + ")"
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("到期日期:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.endDate)),
                            _vm.model.endDate != _vm.oldModel.endDate
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s("(原:" + _vm.oldModel.endDate + ")")
                                  )
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _vm.isHaveExpectEarnings
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v(
                                    _vm._s(_vm.model.extLabelExpectEarnings) +
                                      ":"
                                  )
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.extExpectEarnings))
                                ])
                              ])
                            : _vm._e(),
                          _vm.isHaveGoldWeight
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("金价:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.contractGoldPrice) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.isHaveGoldWeight
                            ? _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c("span", { staticClass: "d2-fwb" }, [
                                    _vm._v("克重:")
                                  ]),
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "right",
                                        width: "650",
                                        trigger: "click"
                                      }
                                    },
                                    [
                                      _c("contract-gold-weight", {
                                        attrs: {
                                          propsModel: this.model.contractId
                                        }
                                      }),
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            type: "warning"
                                          },
                                          slot: "reference"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.model.contractGoldWeight)
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.model.rentRansomType != null &&
                      _vm.model.rentRansomType != ""
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("赎回方式:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.rentRansomTypeText)
                                ),
                                _vm.model.rentRansomTypeText !=
                                _vm.oldModel.rentRansomTypeText
                                  ? _c(
                                      "span",
                                      { staticClass: "d2-different" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            "(原:" +
                                              _vm.oldModel.rentRansomTypeText +
                                              ")"
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isHaveContact
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("联系人:")
                                ]),
                                _vm._v(" " + _vm._s(_vm.model.contact.name)),
                                _vm.model.contact.name !=
                                _vm.oldModel.contact.name
                                  ? _c(
                                      "span",
                                      { staticClass: "d2-different" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            "(原:" +
                                              _vm.oldModel.contact.name +
                                              ")"
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("联系电话:")
                                ]),
                                _vm._v(" " + _vm._s(_vm.model.contact.mobile)),
                                _vm.model.contact.mobile !=
                                _vm.oldModel.contact.mobile
                                  ? _c(
                                      "span",
                                      { staticClass: "d2-different" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            "(原:" +
                                              _vm.oldModel.contact.mobile +
                                              ")"
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                              _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("联系地址:")
                                ]),
                                _vm._v(" " + _vm._s(_vm.model.contact.address)),
                                _vm.model.contact.address !=
                                _vm.oldModel.contact.address
                                  ? _c(
                                      "span",
                                      { staticClass: "d2-different" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            "(原:" +
                                              _vm.oldModel.contact.address +
                                              ")"
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-divider"),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("账户类型:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.accountTypeText)),
                            _vm.model.accountTypeText !=
                            _vm.oldModel.accountTypeText
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s(
                                      "(原:" +
                                        _vm.oldModel.accountTypeText +
                                        ")"
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行户名:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.accountName)),
                            _vm.model.accountName != _vm.oldModel.accountName
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s(
                                      "(原:" + _vm.oldModel.accountName + ")"
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行账号:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.accountNo)),
                            _vm.model.accountNo != _vm.oldModel.accountNo
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s(
                                      "(原:" + _vm.oldModel.accountNo + ")"
                                    )
                                  )
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-1" },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.bankName)),
                            _vm.model.bankName != _vm.oldModel.bankName
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s("(原:" + _vm.oldModel.bankName + ")")
                                  )
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-2" },
                        [
                          _c("el-col", { attrs: { span: 14 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("合同备注:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.remark)),
                            _vm.model.remark != _vm.oldModel.remark
                              ? _c("span", { staticClass: "d2-different" }, [
                                  _vm._v(
                                    _vm._s("(原:" + _vm.oldModel.remark + ")")
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("业绩归属日:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.bussinessDate) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-2" },
                        [
                          _c("el-col", { attrs: { span: 14 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("变更内容:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.changeDesc) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("合同转让:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.model.contractSignTypeText) + " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.model.contractId != ""
            ? _c(
                "el-tab-pane",
                { attrs: { label: "附件", name: "tabAttachment" } },
                [
                  _c("attachment", {
                    ref: "componentAttachment",
                    attrs: {
                      "is-attachment-edit": false,
                      "attachment-contract-id": _vm.model.contractId,
                      "attachment-type": _vm.attachmentType
                    },
                    on: { onAttachmentOpen: _vm.onAttachmentOpen }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.model.contractId != ""
            ? _c(
                "el-tab-pane",
                { attrs: { label: "变更资料", name: "tabAttachmentChange" } },
                [
                  _c("attachment", {
                    ref: "componentAttachment",
                    attrs: {
                      "is-attachment-edit": false,
                      "attachment-contract-id": _vm.model.contractId,
                      "attachment-ext-id": _vm.model.changeApplyId,
                      "attachment-type": _vm.attachmentTypeChange
                    },
                    on: { onAttachmentOpen: _vm.onAttachmentOpen }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("审核")
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核意见", prop: "advice" } },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.advice,
                            expression: "formModel.advice"
                          }
                        ],
                        staticClass: "el-textarea__inner",
                        attrs: { placeholder: "请填写审核意见" },
                        domProps: { value: _vm.formModel.advice },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formModel,
                              "advice",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "contract-log-tip",
              attrs: { plain: "", size: "mini", icon: "el-icon-finished" },
              on: {
                click: function($event) {
                  return _vm.showLog()
                }
              }
            },
            [_vm._v("审核日志")]
          ),
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "warning",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  return _vm.clickAuditFail()
                }
              }
            },
            [_vm._v("不同意")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  return _vm.clickAudit()
                }
              }
            },
            [_vm._v("同 意")]
          )
        ],
        1
      ),
      _c("contract-log", {
        attrs: {
          "contract-id": _vm.propsModel.contractId,
          active: _vm.contractLogType
        },
        model: {
          value: _vm.showLogDialog,
          callback: function($$v) {
            _vm.showLogDialog = $$v
          },
          expression: "showLogDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }