import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { TABLE_CONFIG, TABLE_LABEL, TOP_FORM } from './config/list';
import { DIALOG_FORM_CONFIG, DIALOG_FORM_DATA } from './config/add';
import { getHeaders } from '@/api-new/service';
import { cloneDeep } from 'lodash-es';
import api from '@/api-new';
import url from '@/api-new/url';
import { useSuccessTip } from '@/hooks/use-tip';
export default {
  name: 'CustomerList',
  data: function data() {
    return {
      loading: false,
      tableConfig: TABLE_CONFIG,
      tableLabel: TABLE_LABEL,
      httpConfig: {},

      /* 弹窗数据 */
      topForm: cloneDeep(TOP_FORM),
      dialogFormData: cloneDeep(DIALOG_FORM_DATA),
      dialogFormConfig: cloneDeep(DIALOG_FORM_CONFIG),

      /* 弹窗是否显示 */
      dialogVisible: false,

      /* 弹窗标题 */
      dialogTitle: '新增广告位'
    };
  },
  computed: {},
  methods: {
    /** 接口参数过滤函数 */
    filtersFormatter: function filtersFormatter() {},

    /* 弹窗确认 */
    dialogConfirm: function dialogConfirm() {
      var _this = this;

      api.ADD_ADVERTISE_LOCATION(this.dialogFormData).then(function (res) {
        useSuccessTip();
        _this.dialogVisible = false;

        _this.$refs.jPage.getPageData();
      });
    },
    tableRowClick: function tableRowClick(action) {
      var _ref = action || {},
          clickItem = _ref.clickItem,
          row = _ref.row;

      var _ref2 = clickItem || {},
          type = _ref2.type;

      switch (type) {
        // 处理列表审核
        case 'edit':
          this.dialogVisible = true;
          this.dialogTitle = '修改广告位';
          this.getDetail(row.id);
          break;

        case 'delete':
          this.Delete(row.id);
          break;
      }
    },

    /* 处理列表删除 */
    Delete: function Delete(id) {
      var _this2 = this;

      this.$confirm('确定要删除所选广告位吗？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        api.DELETE_ADVERTISE_LOCATION_DETAIL({
          id: id
        }).then(function () {
          useSuccessTip();

          _this2.$refs.jPage.getPageData();
        });
      });
    },
    getDetail: function getDetail(id) {
      var _this3 = this;

      api.GET_ADVERTISE_LOCATION_DETAIL({
        id: id
      }).then(function (res) {
        _this3.dialogFormData = res;
      });
    },
    tableClick: function tableClick(params) {
      console.log('params: ', params);

      var _ref3 = params || {},
          clickItem = _ref3.clickItem;

      var _ref4 = clickItem || {},
          type = _ref4.type;

      if (type === 'add') {
        this.dialogTitle = '新增广告位';
        this.dialogVisible = true;
      }
    }
  },
  created: function created() {
    var host = url.host,
        apiPrev = url.apiPrev;
    var apiUrl = url.advertising;
    this.httpConfig = {
      url: "".concat(host).concat(apiPrev).concat(apiUrl.getAdvertiseLocation),
      headers: getHeaders()
    };
  }
};