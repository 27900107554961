import { useStore } from '@/store';
import { computed } from '@vue/composition-api';
export default function useToken() {
  var store = useStore();
  /**
   * 设置 token
   * @param token token 信息
   * @returns Promise<token>
   */

  var setToken = function setToken(token) {
    return store.dispatch('d2admin/token/set', token, {
      root: true
    });
  };
  /**
   * 获取 token
   * @returns token
   */


  var getToken = function getToken() {
    return store.getters['d2admin/token/get'];
  };
  /** token 值 */


  var token = computed(function () {
    var tokenInfo = getToken();
    return tokenInfo.access_token || '';
  });
  return {
    setToken: setToken,
    getToken: getToken,
    token: token
  };
}