/** 展示相关封装 */
import { Notification } from 'element-ui';
/** 
 * 封装element的通知和进度条框
 * @export
 * @param {*} title 标题
 * @param {*} process 进度条百分比
 * @param {*} posttion 位置，默认左下角
 * @param {*} duration 关闭时间，默认不关闭
 */

export function createNotifyProcess(title, process, posttion, duration) {
  //element 的notify 消息，不支持element控件，只能用原生的html
  var defaultPosition = "bottom-left"; //默认弹出在左下角

  var defaultDuration = 0; //默认不关闭

  if (posttion == null || posttion == "") {
    posttion = defaultPosition;
  }

  if (duration == null) {
    duration = defaultDuration;
  }

  var notifyDomHtml = "<div role='progressbar' aria-valuenow='80' aria-valuemin='0' aria-valuemax='100'" + "class='el-progress el-progress--line is-warning el-progress--text-inside'>" + "<div class='el-progress-bar'>" + "<div class='el-progress-bar__outer' style='height: 15px;'>" + "<div class='el-progress-bar__inner' id='notifyProcessWidth' style='width: " + process + "%;'>" + "<div class='el-progress-bar__innerText' id='notifyProcessText'>" + process + "%" + "</div></div></div></div></div>";
  var notifyProcessDom = Notification({
    title: title,
    position: posttion,
    duration: duration,
    //不自动关闭
    dangerouslyUseHTMLString: true,
    message: notifyDomHtml
  });
  return notifyProcessDom;
}
/** 
 * 更新进度条百分比
 * @export
 * @param {*} notifyProcessDom //通知dom元素
 * @param {*} process //百分比进度
 */

export function changeNotifyProcess(notifyProcessDom, process) {
  var textDom = document.getElementById("notifyProcessWidth");

  if (textDom != null) {
    if (process > 0 && process < 100) {
      document.getElementById("notifyProcessWidth").style = "width:" + process + "%;";
      document.getElementById("notifyProcessText").innerText = process + "%";
    } else {
      if (notifyProcessDom != null) {
        notifyProcessDom.close();
      }
    }
  }
}