//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
export default {
  name: "dialog-remind",
  props: {
    propsModel: {
      type: Array,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      tableData: [],
      //表格
      timer: null //计时器

    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.timer = null;
    },
    dialogClose: function dialogClose() {},
    // TODO 获取列表
    loadData: function loadData() {
      this.tableData = this.propsModel;
    },
    routerChange: function routerChange(url) {
      this.$router.push(url);
    }
  }
};