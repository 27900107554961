// 弹窗中form表单配置
export var DIALOG_FORM_CONFIG = {
  id: {
    hidden: true
  },
  video: {
    type: 'uploadVideo',
    label: '视频',
    props: {
      type: 1,
      limit: 1,
      placeholder: '最多上传1个文件，文件大小不超过200MB，支持mp4,m4v,avi,mov,flv格式'
    },
    rules: [{
      required: true,
      message: '视频不能为空',
      trigger: 'blur'
    }]
  },
  coverImg: {
    type: 'uploadImage',
    label: '视频封面',
    props: {
      type: 1,
      limit: 1,
      placeholder: '最多可传1张，图片大小不超过200KB，支GIF,JPEG,JPG,PNG,BMP格式'
    }
  },
  title: {
    type: 'input',
    label: '标题',
    rules: [{
      required: true,
      message: '标题不能为空',
      trigger: 'blur'
    }]
  },
  describtion: {
    type: 'input',
    label: '简介',
    props: {
      type: 'textarea',
      rows: 4,
      maxlength: 300,
      showWordLimit: true
    }
  }
};
export var DIALOG_FORM_DATA = {
  id: null,
  title: '',
  organizationName: '',
  releaseStatus: 1,
  failReviewReason: ''
};