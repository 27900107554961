import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DynamicForm from "@/components/common/dynamic-form";
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import TreeOrganization from "@/components/business/tree-organization";
import DialogAdd from "./components/dialog-add";
import { list as productList, listType as productTypeList } from "@api/crm/setup/product";
import { list as personList } from "@api/rm/person";
import { listProduct as listProductRight, saveProduct } from "@api/rm/right";
export default {
  name: "right-product",
  components: {
    DynamicForm: DynamicForm,
    TreeOrganization: TreeOrganization,
    DialogAdd: DialogAdd
  },
  data: function data() {
    return {
      loading: false,
      filename: __filename,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      showAddDialog: false,
      personTableData: [],
      treeData: [],
      rightTableData: [],
      query: {
        enabled: "1",
        isSystemUser: "1"
      },
      currentOrg: {},
      //当前机构
      currentPerson: {} //当前用户

    };
  },
  created: function created() {
    this.loadProduct();
  },
  computed: {
    // 树的配置
    treeConfig: function treeConfig() {
      var orgCd = this.query.orgCd;
      return {
        currentNodeKey: orgCd
      };
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.personTableData = [];
      this.treeData = [];
      this.rightTableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.query.orgCd = "01";
      this.loadPerson(this.query);
    },
    // TODO 点击树节点
    treeNodeClick: function treeNodeClick(node) {
      var orgCd = node.orgCd;
      this.query = _objectSpread(_objectSpread({}, this.query), {}, {
        orgCd: orgCd
      });
      this.currentOrg = node;
      this.loadPerson(this.query);
    },
    //职员列表
    loadPerson: function loadPerson(query) {
      var _this = this;

      this.initFilter();
      var param = {
        page: {
          pageSize: 99999
        },
        query: query
      };
      personList(param).then(function (res) {
        if (res.data != null) {
          _this.personTableData = res.data;
        } else {
          _this.personTableData = [];
          _this.rightTableData = [];

          _this.$refs.rightTree.setCheckedKeys(_this.rightTableData);
        }
      });
    },
    //加载产品列表
    loadProduct: function loadProduct() {
      var _this2 = this;

      var promiseArr = []; //多个请求数组

      promiseArr[0] = productTypeList(); //产品列表

      this.query.orgCd = "01";
      var productParam = {
        query: {
          right: "0",
          //查全部产品，不要只查用户有权限的
          orgCd: "01"
        },
        page: {
          pageSize: 99999
        }
      };
      promiseArr[1] = productList(productParam);
      Promise.all(promiseArr).then(function (res) {
        //产品类型列表
        var typeList = res[0].data;
        var productList = res[1].data; //产品类型

        typeList.forEach(function (n) {
          var treeDom = {
            id: n.enumDetailValue,
            label: n.enumDetailLabel,
            children: []
          }; //树节点

          _this2.treeData.push(treeDom);
        }); //产品归类

        for (var i = 0; i < productList.length; i++) {
          var n = productList[i];
          var state = n.enabled == "1" ? "启用" : "关闭";
          var obj = {
            id: n.productId,
            label: n.productName + "__(" + state + ")"
          };

          for (var j = 0; j < _this2.treeData.length; j++) {
            var m = _this2.treeData[j];

            if (n.productType == m.id) {
              m.children.push(obj);
              break;
            }
          }
        }
      });
    },
    //用户行点击，加载用户已分配产品
    clickPersonRow: function clickPersonRow(row) {
      var _this3 = this;

      this.currentPerson = row;
      var param = {
        personuuid: row.personuuid
      };
      listProductRight(param).then(function (res) {
        _this3.rightTableData = [];
        res.data.forEach(function (n) {
          var result = {
            is: false
          };

          _this3.isLastNodeMenu(_this3.treeData, n.productId, result);

          if (result.is) {
            //是最底层菜单，标记选中
            _this3.rightTableData.push(n.productId);
          }
        });

        _this3.$refs.rightTree.setCheckedKeys(_this3.rightTableData);
      });
    },
    //保存
    clickSave: function clickSave() {
      var _this4 = this;

      var arrayChecked = this.$refs.rightTree.getCheckedKeys(); //已勾选产品

      var param = {
        personuuid: this.currentPerson.personuuid,
        arrayId: arrayChecked
      };
      saveProduct(param).then(function (res) {
        _this4.$message({
          message: "已保存",
          type: "success"
        });
      });
    },
    //多人员保存
    clickSaveManyPerson: function clickSaveManyPerson() {
      this.showAddDialog = true;
    },
    //判断是否是最下层菜单
    isLastNodeMenu: function isLastNodeMenu(arr, productId, result) {
      if (arr != null) {
        for (var i = 0; i < arr.length; i++) {
          var n = arr[i];

          if (n.id == productId) {
            if (!n.hasOwnProperty("children") || n.children.length == 0) {
              result.is = true;
            }

            return;
          } else {
            this.isLastNodeMenu(n.children, productId, result);
          }
        }
      }
    }
  }
};