//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import visitMap from "@/maps/url/crm/buy/visit";
import util from "@/libs/util";
export default {
  props: {
    uploadId: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      loading: false,
      name: "visit-upload",
      uploadUrl: process.env.VUE_APP_API + visitMap.uploadVudio,
      uploadHeader: {
        "X-Token": util.cookies.get("token")
      },
      fileList: [],
      uploadFileName: "file",
      //服务器接收文件名参数
      uploadParams: {},
      //服务器额外参数
      uploadFileCount: 0 //上传文件总数

    };
  },
  mixins: [dialogMixin],
  showDialog: {
    handler: function handler(val) {},
    immediate: true
  },
  watch: {
    uploadId: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.uploadParams = {
            applyId: this.uploadId
          };
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.fileList = [];
      this.uploadParams = {};
    },
    dialogClose: function dialogClose() {
      this.init();
    },
    submitUpload: function submitUpload(params) {
      this.$refs.upload.submit(); //原element上传多个文件拆分成多次请求
    },
    handleRemove: function handleRemove(file, fileList) {},
    handlePreview: function handlePreview(file) {},
    handleSuccess: function handleSuccess(response, file, fileList) {
      if (response.code == "1") {
        this.uploadFileCount++;

        if (this.uploadFileCount == fileList.length) {
          //全部上传完成
          this.init();
          this.$emit("refresh-upload", response.body.data);
          this.showDialog = false;
        }
      } else {
        this.init();
        this.$message.error(response.message);
      }
    },
    handleError: function handleError(err, file, fileList) {
      this.init();
      this.$message.error("上传文件出错");
    }
  }
};