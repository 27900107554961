//合同购买预约
export default {
  AUDIT_NO: {
    value: '0',
    text: '未审核'
  },
  AUDIT_RESULT_YES: {
    value: '1',
    text: '已预约'
  },
  AUDIT_RESULT_NO: {
    value: '2',
    text: '未预约'
  },
  AUDIT_RESULT_ALLOW: {
    value: '3',
    text: '临时开放'
  }
};