
































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/info/manage/view/index.vue"
}
