import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
export default {
  /**
   * 获取数据类型
   * @param {*} data
   * @returns 数据类型
   */
  getDataType: function getDataType(data) {
    return Object.prototype.toString.call(data).slice(8, -1).toLowerCase();
  },

  /**
   * 是否为字符串类型
   * @param {*} data
   * @returns boolean
   */
  isString: function isString(data) {
    return this.getDataType(data) === 'string';
  },

  /**
   * 是否为数组类型
   * @param {*} data
   * @returns boolean
   */
  isArray: function isArray(data) {
    return this.getDataType(data) === 'array';
  },

  /**
   * 是否为空对象
   * @param {*} data
   * @returns boolean
   */
  isEmptyObject: function isEmptyObject(data) {
    if (data === null) return true;
    if (this.isArray(data) || this.isString(data)) return data.length === 0;

    for (var key in data) {
      if (data.hasOwnProperty(key)) return false;
    }

    return true;
  }
};