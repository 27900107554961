var messageManagementUrl = {
  messageManagement: {
    /* 待办 */
    waitToBeDone: {
      /* 分页列表 */
      getPage: '/manage/todo/getPage',

      /* 导出 */
      export: '/manage/todo/export',

      /* 待办续约明细列表 */
      getRenewalDetail: '/manage/todo/getRenewalDetail',

      /* 作废 */
      cancel: '/manage/todo/cancel'
    },

    /* 消息 */
    information: {
      /* 分页列表 */
      getPage: '/manage/employeeMessage/getPage',

      /* 导出 */
      export: '/manage/employeeMessage/export',

      /* 消息接收者记录 */
      getRecipientLogt: '/manage/employeeMessage/getRecipientLogt',

      /* 新增 */
      add: '/manage/employeeMessage/add',

      /* 更新 */
      update: '/manage/employeeMessage/update',

      /* 作废 */
      cancel: '/manage/employeeMessage/cancel',

      /* 删除 */
      delete: '/manage/employeeMessage/delete'
    },

    /* 节日祝福 */
    holidayBlessings: {
      /* 分页列表 */
      getPage: '/manage/festivalGreeting/getPage',

      /* 导出 */
      export: '/manage/festivalGreeting/export',

      /* 详情 */
      getInfo: '/manage/festivalGreeting/getInfo',

      /* 新增 */
      add: '/manage/festivalGreeting/add',

      /* 更新 */
      update: '/manage/festivalGreeting/update',

      /* 删除 */
      delete: '/manage/festivalGreeting/delete'
    }
  }
};
export default messageManagementUrl;