import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 150,
  col: 10
};
export var FORM_CONFIG = [{
  title: '基本信息',
  children: [// {
  //   ...PUBLIC_CONFIG,
  //   type: 'input',
  //   label: '流水号',
  //   prop: 'id',
  //   props: {
  //     disabled: true,
  //   },
  // },
  // {
  //   ...PUBLIC_CONFIG,
  //   type: 'cascader',
  //   label: '主办方',
  //   prop: 'orgCd',
  //   props: {
  //     props: {
  //       label: 'orgName',
  //       value: 'orgCd',
  //       children: 'children',
  //       checkStrictly: true,
  //       emitPath: false,
  //     },
  //     multiple: true,
  //   },
  //   rules: [{ required: true, message: '主办方不能为空', trigger: 'blur' }],
  //   options: [],
  // },
  _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '活动标题',
    prop: 'activityTitle',
    rules: [{
      required: true,
      message: '活动标题不能为空',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'radio',
    label: '活动类型',
    prop: 'activityType',
    rules: [{
      required: true,
      message: '活动类型不能为空',
      trigger: 'blur'
    }],
    options: []
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '活动链接',
    prop: 'linkUrl',
    hidden: false
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '小程序appId',
    prop: 'appId',
    hidden: false
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '页面路径',
    prop: 'path',
    hidden: false
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '活动主图',
    prop: 'mainPicture',
    rules: [{
      required: true,
      message: '活动主图不能为空',
      trigger: 'blur'
    }],
    props: {
      placeholder: '最多可传1张，图片大小不超过200KB，支持GIF,JPEG,JPG,PNG,BMP格式'
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '活动分类',
    prop: 'classifyId',
    // rules: [{ required: true, message: '活动分类不能为空', trigger: 'blur' }],
    options: []
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '活动地点',
    prop: 'address',
    hidden: true
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '活动开始时间',
    prop: 'startTime',
    props: {
      type: 'datetime',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    rules: [{
      required: true,
      message: '活动开始时间不能为空',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '活动结束时间',
    prop: 'endTime',
    props: {
      type: 'datetime',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    },
    rules: [{
      required: true,
      message: '活动结束时间不能为空',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'inputNumber',
    label: '人数限制',
    prop: 'limitPerson',
    hidden: true // props: {
    //   type: 'number'
    // },

  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '业绩目标',
    prop: 'targetAmount',
    props: {
      type: 'number',
      tips: '万元'
    },
    hidden: true
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'checkbox',
    label: '活动需求',
    prop: 'activityRequire',
    hidden: true,
    options: [{
      label: '品牌设计',
      value: '1'
    }, {
      label: '邀请嘉宾',
      value: '2'
    }, {
      label: '需要雅御协助',
      value: '3'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '排序值',
    prop: 'sort',
    rules: [{
      required: true,
      message: '请输入排序',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    label: '部门',
    type: 'cascader',
    options: [],
    prop: 'restrictOrgCds',
    rules: [{
      required: true,
      message: '部门不能为空',
      trigger: 'blur'
    }],
    props: {
      placeholder: '请选择部门',
      filterable: true,
      props: {
        checkStrictly: true,
        multiple: true,
        emitPath: false,
        value: 'orgCd',
        label: 'orgName',
        children: 'children'
      }
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '备注',
    prop: 'remark',
    props: {
      type: 'textarea',
      rows: 5
    }
  })]
}, {
  title: '活动预算',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    label: '',
    prop: 'budgetList',
    col: 24
  })],
  hidden: true
}, {
  title: '活动内容',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'editor',
    label: '',
    prop: 'content',
    col: 24
  })]
}];