//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '合同编号',
  prop: 'contractCd',
  width: '150px'
}, {
  label: '客户',
  prop: 'customerName',
  width: '80px'
}, {
  label: '状态',
  prop: 'contractState',
  width: '120px',
  type: 'slot'
}, {
  label: '产品',
  prop: 'productName',
  width: '150px'
}, {
  label: '开始日期',
  prop: 'startDate',
  width: VAL_WIDTH
}, {
  label: '结束日期',
  prop: 'endDate',
  width: VAL_WIDTH
}, {
  label: '期限(月)',
  prop: 'timeLimit',
  width: '80px'
}, {
  label: '返还周期',
  prop: 'returnPeriod',
  width: '80px'
}, {
  label: '合同金额',
  prop: 'amount',
  width: '120px',
  align: 'right'
}, {
  label: '机构',
  prop: 'companyName',
  width: '210px'
}, {
  label: '顾问',
  prop: 'userName',
  width: '120px'
}, {
  label: '类型',
  prop: 'logTypeText',
  width: '60px'
}, {
  label: '结果',
  prop: 'auditResultText',
  width: '80px'
}, {
  label: '次数',
  prop: 'cntAudit',
  width: '80px'
}, {
  label: '审核意见',
  prop: 'auditAdvice',
  width: '120px'
}, {
  label: '审核时间',
  prop: 'createTime',
  width: '160px'
}, {
  label: '审核人',
  prop: 'logAuditer',
  width: '80px'
}];