






























































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/rm/system/system/team/apply/components/dialog-add.vue"
}
