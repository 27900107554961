//赎回
export default {
  WAIT_FINANCE_AUDIT: {
    value: '0',
    text: '待财务复核'
  },
  FINANCE_AUDIT: {
    value: '6',
    text: '待出金打印'
  },
  FINANCE_MANAGER_CONFIRM: {
    value: '7',
    text: '待出金打印'
  },
  PRINT: {
    value: '8',
    text: '待集团资金付款'
  },
  MONEY_AUDIT: {
    value: '9',
    text: '资金已付款'
  },
  WAIT_FOR_GOODS_CONFIRM: {
    value: '10',
    text: '待珠宝展厅出货'
  },
  GOODS_CONFIRM: {
    value: '11',
    text: '珠宝展厅完成出货'
  },
  COMPLETE: {
    value: '12',
    text: '完成'
  },
  FINANCE_AUDIT_FAILURE: {
    value: '16',
    text: '财务审核不通过'
  },
  FINANCE_MANAGER_CONFIRM_FAILURE: {
    value: '17',
    text: '财务复核不通过'
  },
  MONEY_AUDIT_FAILURE: {
    value: '19',
    text: '资金审核不通过'
  },
  GOODS_AUDIT_FAILURE: {
    value: '20',
    text: '珠宝现货审核不通过'
  },
  DELETE: {
    value: '-99',
    text: '已删除'
  }
};