//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { getInfo as getContractInfo } from "@/api/crm/buy/buy";
import { addApplication } from "@api/crm/commission/commissionAgency";
export default {
  name: "commission-agency-close-debt",
  components: {},
  props: {
    propsModel: {
      type: Object,
      default: {}
    }
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      formModel: {},
      //表单
      currentRow: {},
      // 当前点击行
      currentIndex: 0,
      rules: {
        contractCd: [{
          required: true,
          message: "请填写合同编号",
          trigger: "input"
        }],
        orgName: [{
          required: true,
          message: "请填写机构",
          trigger: "input"
        }],
        bonus: [{
          required: true,
          message: "请填写推介费金额",
          trigger: "input"
        }]
      }
    };
  },
  watch: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    confirm: function confirm() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = {
            object: {
              contractId: _this.formModel.contractId,
              remark: _this.formModel.remark
            },
            arrayDetail: [{
              titleId: _this.formModel.orgCd,
              title: _this.formModel.orgName,
              bonusUserId: _this.formModel.orgCd,
              bonus: _this.formModel.bonus,
              deduct: _this.formModel.deduct
            }]
          };
          addApplication(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit("refresh");
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    //合同编号变更，检查合同是否存在
    changeContractCd: function changeContractCd() {
      var _this2 = this;

      if (this.formModel.contractCd != "") {
        var param = {
          contractCd: this.formModel.contractCd
        };
        getContractInfo(param).then(function (res) {
          _this2.formModel.contractId = res.data.contractId;
          _this2.formModel.orgCd = res.data.orgCd;

          _this2.$set(_this2.formModel, "orgName", res.data.orgName);
        });
      }
    }
  }
};