













































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/commissionAgency/dialog-summary.vue"
}
