//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import DialogInfo from "../../dialog-info";
import DialogSummary from "../../dialog-summary";
import { listBatchBySummaryId } from "@api/crm/commission/commissionAgency";
export default {
  name: "commission-agency-search-summary-list",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    DialogInfo: DialogInfo,
    DialogSummary: DialogSummary
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      tableData: [],
      currentRow: {},
      //当前行
      showInfoDialog: false,
      showBatchSummaryDialog: false,
      batchId: this.propsModel.batchId
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    loadData: function loadData() {
      var _this = this;

      var param = {
        summaryId: this.propsModel.summaryId
      };
      listBatchBySummaryId(param).then(function (res) {
        _this.tableData = res.data;
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    //台帐明细
    clickBatchInfo: function clickBatchInfo(row) {
      this.currentRow = row;
      this.showInfoDialog = true;
    },
    //台帐汇总
    clickBatchSummary: function clickBatchSummary(row) {
      this.batchId = row.batchId;
      this.showBatchSummaryDialog = true;
    }
  }
};