// 机构推介费V 3
export default {
  // 机构推介费 导入
  importExcelCommission: '/import/commission/importExcelCommission',
  // 导入Excel推介费
  list: '/import/commission/list',
  // 导入Excel数据列表
  listApplication: '/commission/agency/v3/listApplication',
  // 推介费外部导入数据列表
  exportExcelImportData: '/commission/agency/v3/exportExcelImportData' // 导出Excel外部导入推介费

};