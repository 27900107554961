//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '客户名称',
  prop: 'accountName',
  width: VAL_WIDTH
}, {
  label: '客户编码',
  prop: 'customerCode',
  width: '120px'
}, {
  label: '银行卡号',
  prop: 'accountNo',
  width: '180px'
}, {
  label: '开户行名称',
  prop: 'bankName',
  width: '150px'
}, {
  label: '理顾姓名',
  prop: 'agentUserName',
  width: '120px'
}, {
  label: '部门名称',
  prop: 'orgName',
  width: '210px'
}, {
  label: '省',
  prop: 'province',
  width: '120px'
}, {
  label: '城市',
  prop: 'city',
  width: '120px'
}, {
  label: '联行号',
  prop: 'bankCode',
  width: '160px'
}, {
  label: '创建人',
  prop: 'creatorName',
  width: VAL_WIDTH
}, {
  label: '创建时间',
  prop: 'createTime',
  width: '160px'
}, {
  label: '更新人',
  prop: 'updatorName',
  width: VAL_WIDTH
}, {
  label: '更新时间',
  prop: 'updateTime',
  width: '160px'
}];