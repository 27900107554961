//核算
export default {
  WAIT_CONFIRM_DATE: {
    value: '0',
    text: '待确认结算日'
  },
  SAVE: {
    value: '1',
    text: '待提交'
  },
  SUBMIT: {
    value: '2',
    text: '待客服审核'
  },
  SERVICE_AUDIT: {
    value: '7',
    text: '待结算审核'
  },
  COUNT_AUDIT: {
    value: '3',
    text: '待财务复核'
  },
  FINANCE_AUDIT: {
    value: '4',
    text: '核算完成'
  },
  //财务已审核
  COUNT_AUDIT_FAILURE: {
    value: '5',
    text: '结算审核不通过'
  },
  FINANCE_AUDIT_FAILURE: {
    value: '6',
    text: '财务复核不通过'
  },
  SERVICE_AUDIT_FAILURE: {
    value: '8',
    text: '客服审核不通过'
  },
  FINANCE_MANAGER_AUDIT_FAILURE: {
    value: '9',
    text: '财务复核不通过'
  },
  MONEY_AUDIT_FAILURE: {
    value: '10',
    text: '资金审核不通过'
  },
  DELETE: {
    value: '-99',
    text: '已删除'
  }
};