import _slicedToArray from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.repeat.js";

/**
 * 对数字做加法计算，接受多个值，测试值(0.1 + 0.2)
 * @param arg 传入的参数列表(数组)
 * @returns {number} 计算后的合计值
 */
export var add = function add() {
  for (var _len = arguments.length, arg = new Array(_len), _key = 0; _key < _len; _key++) {
    arg[_key] = arguments[_key];
  }

  if (arg.length === 1) {
    return arg[0];
  } // 获取小数位长度数组


  var decLens = arg.reduce(function (arr, item) {
    arr.push(("".concat(item).split('.')[1] || '').length);
    return arr;
  }, []);
  var m = Math.max.apply(null, decLens); // 小数位最长长度

  var total = arg.reduce(function (total, item, index) {
    // 将数字转为整数，用最长小数位长度减去当前数字的小数位长度乘以去掉小数点的当前数字
    total += Number("".concat(item).replace('.', '')) * Math.pow(10, m - decLens[index]);
    return total;
  }, 0);
  return total / Math.pow(10, m);
};
/**
 * 对数字做减法计算，接受多个值，测试值(1.5 + 1.2)
 * @param minuend 被减数
 * @param arg 减数列表(数组)
 * @returns {number}
 */

export var sub = function sub(minuend) {
  for (var _len2 = arguments.length, arg = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    arg[_key2 - 1] = arguments[_key2];
  }

  var subtraction = add.apply(void 0, arg); // 获取减数的和

  var mLen = ("".concat(minuend).split('.')[1] || '').length; // 被除数小数位长度

  var sLen = ("".concat(subtraction).split('.')[1] || '').length; // 减数小数位长度

  var m = Math.max.call(null, mLen, sLen); // 小数位最长长度

  minuend = Number("".concat(minuend).replace('.', '')) * Math.pow(10, m - mLen);
  subtraction = Number("".concat(subtraction).replace('.', '')) * Math.pow(10, m - sLen);
  return (minuend - subtraction) / Math.pow(10, m);
};
/**
 * 对数字做乘法，接受多个值，测试值(35.41 * 100)
 * @param arg 乘数列表(数组)
 * @returns {number}
 */

export var mul = function mul() {
  for (var _len3 = arguments.length, arg = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
    arg[_key3] = arguments[_key3];
  }

  if (arg.length === 1) {
    return arg[0];
  }

  var m = 0; // 小数位的和

  var total = arg.reduce(function (total, item) {
    m += ("".concat(item).split('.')[1] || '').length;
    return total * parseFloat("".concat(item).replace('.', ''));
  }, 1);
  return total / Math.pow(10, m);
};
/**
 * 对数字做除法，接受多个值，测试值(0.3 / 0.1)
 * @param divisor 被除数
 * @param arg 除数列表
 * @returns {number}
 */

export var div = function div(divisor) {
  for (var _len4 = arguments.length, arg = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
    arg[_key4 - 1] = arguments[_key4];
  }

  var product = mul(arg);
  var dLen = ("".concat(divisor).split('.')[1] || '').length;
  var pLen = ("".concat(product).split('.')[1] || '').length;
  divisor = Number("".concat(divisor).replace('.', ''));
  product = Number("".concat(product).replace('.', ''));
  return divisor / product * Math.pow(10, pLen - dLen);
};
/**
 * 保留小数位
 * @param val 处理值
 * @param {number} length 保留位数，默认2位
 * @param {boolean} isKeep 是否保留小数位长度，即传入0时是否展示0.00
 * @returns {string}
 */

export var toFixed = function toFixed(val) {
  var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var isKeep = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

  if (val === undefined || !/^-?\d*\.?\d*$/.test(val)) {
    console.error('the value can not change to number');
    return '';
  }

  var _$split = "".concat(val).split('.'),
      _$split2 = _slicedToArray(_$split, 2),
      _$split2$ = _$split2[0],
      num = _$split2$ === void 0 ? '' : _$split2$,
      _$split2$2 = _$split2[1],
      dec = _$split2$2 === void 0 ? '' : _$split2$2; // 获取整数值和小数值
  // dec = dec.padEnd(length, '0'); // 将0 补全


  if (dec.length < length || isKeep) {
    dec = "".concat(dec).concat('0'.repeat(length - dec.length)); // 将0 补全
  } // 小数位长度多于要保留的长度


  if (dec.length !== length) {
    dec = "".concat(dec.substr(0, length), ".").concat(dec.substr(length)); // 给要保留的位数后加小数点
    // dec = `${Math.round(dec)}`.padStart(length, '0'); // 往前面补0，处理类似001这种情况

    dec = "".concat(Math.round(parseFloat(dec))); // 四舍五入

    if (dec.length < length) {
      dec = "".concat('0'.repeat(length - dec.length)).concat(dec); // 往前面补0，处理类似001这种情况
    }
  }

  return "".concat(num, ".").concat(dec);
};
export default {
  add: add,
  sub: sub,
  mul: mul,
  div: div,
  toFixed: toFixed
};