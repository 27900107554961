import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
var PUBLIC_CONFIG = {
  labelWidth: 150
};
export var TOP_FORM = {
  searchContent: {
    type: 'input',
    label: '搜索',
    props: {
      placeholder: '请输入'
    }
  }
};
export var TABLE_LABEL = [{
  label: '权益名称',
  prop: 'name'
}, {
  label: '权益类型',
  prop: 'typeValue'
}, {
  label: '状态',
  prop: 'status',
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 0:
        res = '停用';
        break;

      case 1:
        res = '启用';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  label: '最后修改人',
  prop: 'updateUserName'
}, {
  label: '最后修改时间',
  prop: 'updateTime'
}, {
  label: '创建人',
  prop: 'createUserName'
}, {
  label: '创建时间',
  prop: 'createTime'
}, {
  type: 'operation',
  label: '操作',
  // fixed: 'right',
  formatter: function formatter(row) {
    var arr = [];

    if (row.status === 1) {
      arr.push({
        type: 'edit',
        label: '修改',
        permission: 'Entitlement-update'
      });
      arr.push({
        type: 'stop',
        label: '停用',
        permission: 'Entitlement-updateStatus'
      });
    } else {
      arr.push({
        type: 'edit',
        label: '修改',
        permission: 'Entitlement-update'
      });
      arr.push({
        type: 'delete',
        label: '删除',
        permission: 'Entitlement-delete'
      });
      arr.push({
        type: 'start',
        label: '启用',
        permission: 'Entitlement-updateStatus'
      });
    }

    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  rowKey: 'id',
  actions: [{
    label: '新增规则',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'Entitlement-add'
  }]
}; // 弹窗中form表单配置

export var DIALOG_FORM_CONFIG = {
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '权益名称',
    props: {
      maxlength: 12,
      showWordLimit: true
    },
    rules: [{
      required: true,
      message: '权益名称不能为空',
      trigger: 'blur'
    }]
  }),
  subtitle: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '副标题',
    props: {
      maxlength: 12,
      showWordLimit: true
    }
  }),
  icon: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '权益图标',
    rules: [{
      required: true,
      message: '权益图标不能为空',
      trigger: 'blur'
    }],
    props: {
      placeholder: '最多可传1张，图片建议大小不超过1MB，支GIF,JPEG,JPG,PNG,BMP格式',
      fileSize: 1 * 1024,
      fileType: ['GIF', 'JPEG', 'JPG', 'PNG', 'BMP']
    }
  }),
  description: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '权益描述',
    props: {
      type: 'textarea',
      rows: 4,
      maxlength: 200,
      showWordLimit: true
    },
    rules: [{
      required: true,
      message: '权益描述不能为空',
      trigger: 'blur'
    }]
  }),
  clickAction: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '点击动作',
    props: {
      placeholder: '无动作'
    },
    options: [{
      label: '链接小程序',
      value: 1
    }, {
      label: '附件下载',
      value: 2
    }, {
      label: '联系客服',
      value: 3
    }, {
      label: 'Web链接',
      value: 4
    }],
    rules: [{
      required: true,
      message: '请选择',
      trigger: 'blur'
    }]
  }),
  appletAppId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '小程序appId',
    hidden: true
  }),
  appletOriginId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '小程序原始Id',
    hidden: true
  }),
  appletUrl: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '页面路径',
    hidden: true
  }),
  webUrl: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '链接',
    hidden: true
  }),
  attachmentUrl: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    label: '附件下载',
    hidden: true
  }),
  type: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '权益类型',
    options: [{
      label: '异业合同',
      value: 1
    }, {
      label: '积分奖励',
      value: 2
    }, {
      label: '零售权益',
      value: 3
    }, {
      label: '营业部活动',
      value: 4
    }, {
      label: '其他',
      value: 5
    }],
    props: {
      disabled: false
    },
    rules: [{
      required: true,
      message: '请选择',
      trigger: 'blur'
    }]
  }),
  quantity: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '权益数量',
    props: {
      type: 'number'
    }
  }),
  unit: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '权益单位',
    props: {
      maxlength: 8,
      showWordLimit: true
    }
  }),
  validDay: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    label: '有效期'
  }),
  applicableMember: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'radio',
    label: '适用人群',
    props: {
      placeholder: '金承会员：年化30W及以上的用户'
    },
    options: [{
      label: '不限制',
      value: 1
    }, {
      label: '金承会员',
      value: 2
    }, {
      label: '指定客户',
      value: 3
    }]
  }),
  designatedCustomerName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    label: '指定客户',
    hidden: true
  })
}; // 弹窗中form表单配置

export var DIALOG_FORM_DATA = {
  name: null,
  subtitle: null,
  icon: null,
  description: null,
  clickAction: null,
  appletAppId: null,
  appletOriginId: null,
  appletUrl: null,
  webUrl: null,
  type: null,
  quantity: null,
  unit: null,
  applicableMember: 1,
  validDay: null
};