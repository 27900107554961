import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
};
export var TOP_FORM = {};
export var TABLE_LABEL = [{
  label: '合同期限',
  type: 'slot',
  prop: 'limit'
}, {
  label: '合同系数',
  prop: 'coefficient'
}, {
  label: '创建时间',
  prop: 'createTime',
  sortable: 'annualRule',
  sortProp: 'createTime'
}, {
  label: '最后修改时间',
  prop: 'updateTime'
}, {
  label: '最后操作人',
  prop: 'updateUserName'
}, {
  type: 'operation',
  label: '操作',
  // fixed: 'right',
  formatter: function formatter(row) {
    var arr = [{
      type: 'edit',
      label: '修改',
      permission: 'AnnualizationRule-update'
    }];
    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  rowKey: 'id',
  actions: [{
    label: '新增规则',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'AnnualizationRule-add'
  }, {
    label: '删除',
    type: 'delete',
    props: {
      size: 'small',
      type: 'danger'
    },
    permission: 'AnnualizationRule-delete'
  }]
}; // 弹窗中form表单配置

export var DIALOG_FORM_CONFIG = {
  endTimeLimit: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    label: '合同期限',
    rules: [{
      required: true,
      message: '请填写合同期限',
      trigger: 'blur'
    }]
  }),
  coefficient: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '合同系数',
    props: {
      type: 'number'
    },
    rules: [{
      required: true,
      message: '合同系数不能为空',
      trigger: 'blur'
    }]
  })
}; // 弹窗中form表单配置

export var DIALOG_FORM_DATA = {
  beginTimeLimit: null,
  endTimeLimit: null,
  coefficient: null
};