import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import ATTACHMENT_TYPE from "@/maps/enum/attachment-type";
import Attachment from "@/components/business/attachment";
import { receive } from "@api/crm/gift/apply";
export default {
  name: "gift-apply-receive",
  props: {
    propsModel: {
      type: Array,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Attachment: Attachment
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tabActive: "tabContract",
      //标签框默认选中
      collapseActive: ["1"],
      //信息折叠默认展开
      attachmentType: ATTACHMENT_TYPE.CONTRACT_GIFT_CONFIRM,
      //附件类型
      tableData: [],
      firstContractId: null //列表第一份合同Id，用于上传签收表，列表后面合同签收表由后台复制第一份生成

    };
  },
  created: function created() {},
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.tabActive = "tabContract"; //默认打开第一个标签页

          this.loadData();
          this.firstContractId = this.propsModel[0].contractId;
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    // TODO 确定
    clickReceive: function clickReceive(result) {
      var _this = this;

      var attachmentCount = this.$refs.componentAttachment.tableData.length; //附件数

      if (attachmentCount == 0) {
        this.$message("请上传礼品签收单");
      } else {
        var arrayApplyGiftId = [];
        this.propsModel.forEach(function (n) {
          arrayApplyGiftId.push(n.applyGiftId);
        });
        var param = {
          arrayApplyGiftId: arrayApplyGiftId
        };
        this.loading = true;
        receive(param).then(function (data) {
          _this.loading = false;
          _this.showDialog = false;

          _this.$emit("refresh");
        }).catch(function (e) {
          _this.loading = false;
        });
      }
    },
    loadData: function loadData() {
      this.tableData = Object.assign([], this.propsModel); //复制新对象      
    }
  }
};