//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cleanCache, getRemindSwitch, setRemindSwitch, getSmsSwitch, setSmsSwitch } from "@api/rm/tool";
export default {
  name: "tool",
  data: function data() {
    return {
      loading: false,
      remindManualSwitch: false,
      //消息提醒手动开关
      smsSwitch: true //短信手动开关

    };
  },
  created: function created() {
    this.loadRemindSwitch(); //消息提醒：获取当前手工模式是否启动
  },
  computed: {},
  methods: {
    //清理缓存
    clickCleanCache: function clickCleanCache() {
      var _this = this;

      cleanCache().then(function (res) {
        _this.$message({
          message: "已清除",
          type: "success"
        });
      });
    },
    //消息提醒：获取当前手工模式是否启动
    loadRemindSwitch: function loadRemindSwitch() {
      var _this2 = this;

      getRemindSwitch().then(function (res) {
        _this2.remindManualSwitch = res.data;
      });
    },
    //消息提醒手动启用关闭
    clickRemindSwitch: function clickRemindSwitch(val) {
      var _this3 = this;

      var param = {
        start: val
      };
      setRemindSwitch(param).then(function (res) {
        _this3.$message({
          message: "操作成功",
          type: "success"
        });
      });
    },
    //短信：获取当前是否启动
    loadSmsSwitch: function loadSmsSwitch() {
      var _this4 = this;

      getSmsSwitch().then(function (res) {
        _this4.remindManualSwitch = res.data;
      });
    },
    //短信手动启用关闭
    clickSmsSwitch: function clickSmsSwitch(val) {
      var _this5 = this;

      var param = {
        start: val
      };
      setSmsSwitch(param).then(function (res) {
        _this5.$message({
          message: "操作成功",
          type: "success"
        });
      });
    }
  }
};