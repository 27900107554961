var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", { staticClass: "page-table" }, [
    _c(
      "div",
      { staticClass: "rule" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form",
            attrs: { model: _vm.form, rules: _vm.rules, "label-width": "150px" }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "是否开启积分： ", prop: "status" } },
              [
                _c("el-switch", {
                  model: {
                    value: _vm.form.status,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "status" }),
            _c(
              "el-form-item",
              { attrs: { label: "默认名称", prop: "name" } },
              [
                _c("el-input", {
                  staticClass: "input",
                  attrs: { maxlength: "6" },
                  model: {
                    value: _vm.form.name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "name", $$v)
                    },
                    expression: "form.name"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "title" }, [
              _c("div", { staticClass: "text" }, [_vm._v("规则说明")])
            ]),
            _c(
              "div",
              { staticClass: "ruleDesc" },
              [
                _c("j-editor", {
                  model: {
                    value: _vm.form.description,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "description", $$v)
                    },
                    expression: "form.description"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "foot-button" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.cancel } },
          [_vm._v("返回")]
        ),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.confirm } },
          [_vm._v("保存")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }