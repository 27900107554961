var PUBLIC_CONFIG = {
  labelWidth: 100
};
export var TOP_FORM = {
  isSelect: false
};
export var TABLE_LABEL = [{
  label: '项目名称',
  prop: 'name',
  width: 120
}, {
  type: 'select',
  label: '周期',
  prop: 'periodType',
  options: [{
    label: '一次',
    value: 1
  }, {
    label: '每天',
    value: 2
  }, {
    label: '不限',
    value: 3
  }],
  props: {
    disabled: false
  }
}, {
  type: 'input',
  label: '周期内最多奖励次数',
  prop: 'maxRewardCount',
  props: {
    placeholder: '请填写奖励次数'
  }
}, {
  type: 'input',
  label: '奖励积分',
  prop: 'rewardScore',
  props: {
    placeholder: '请填写奖励积分'
  }
}, {
  label: '说明',
  prop: 'remark'
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: []
};