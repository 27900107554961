//客户
export default {
  list: '/customer/list',
  // 列表
  save: '/customer/save',
  // 保存          
  remove: '/customer/remove',
  // 删除
  getInfo: '/customer/getInfo',
  // 详情
  exportExcel: '/customer/exportExcel',
  // 导出excel
  listBirthdayBonus: '/customer/vip/listBirthdayBonus',
  // VIP客户生日礼品列表
  saveBirthdayBonus: '/customer/vip/saveBirthdayBonus',
  // VIP客户生日礼品保存
  removeBirthdayBonus: '/customer/vip/removeBirthdayBonus',
  // VIP客户生日礼品删除
  exportExcelBirthdayBonus: '/customer/vip/exportExcelBirthdayBonus' // VIP客户生日奖励

};