var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", { staticClass: "page-table" }, [
    _c("div", { staticClass: "detail-head" }, [
      _c("div", { staticClass: "detail-head-title" }, [
        _c("i", {
          staticClass: "el-icon-arrow-left",
          on: { click: _vm.goBack }
        }),
        _vm._v(" 客户跟进：" + _vm._s(_vm.businessInfo.code) + " ")
      ]),
      _c("div", { staticClass: "detail-head-desc" }, [
        _c("div", [
          _vm._v(" 客户：" + _vm._s(_vm.businessInfo.customerName) + " ")
        ]),
        _c("div", [
          _vm._v(" 跟进方式：" + _vm._s(_vm.businessInfo.typeValue) + " ")
        ]),
        _c("div", [
          _vm._v("创建人：" + _vm._s(_vm.businessInfo.createUserName))
        ]),
        _c("div", [_vm._v("创建时间：" + _vm._s(_vm.businessInfo.createTime))])
      ])
    ]),
    _c(
      "div",
      [
        _c(
          "el-tabs",
          {
            model: {
              value: _vm.activeName,
              callback: function($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName"
            }
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "基础信息", name: "second" } },
              [
                _c("BasicInfo", {
                  attrs: { info: _vm.info },
                  on: { refresh: _vm.getDetail }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }