





















































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/buy/visit/service/components/dialog-visit.vue"
}
