//变更
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 申请
{
  path: "".concat(baseRoute, "/change/new"),
  name: 'change-new',
  meta: {
    title: '变更>申请',
    auth: true,
    cache: true
  },
  component: _import('crm/change/new')
}, // 客服审核
{
  path: "".concat(baseRoute, "/change/service"),
  name: 'change-service',
  meta: {
    title: '变更>客服审核',
    auth: true,
    cache: true
  },
  component: _import('crm/change/service')
}, // 结算审核
{
  path: "".concat(baseRoute, "/change/count"),
  name: 'change-count',
  meta: {
    title: '变更>结算审核',
    auth: true,
    cache: true
  },
  component: _import('crm/change/count')
}, // 资金审核
{
  path: "".concat(baseRoute, "/change/money"),
  name: 'change-money',
  meta: {
    title: '变更>资金审核',
    auth: true,
    cache: true
  },
  component: _import('crm/change/money')
}, // 变更查询
{
  path: "".concat(baseRoute, "/change/search"),
  name: 'change-search',
  meta: {
    title: '变更>查询',
    auth: true,
    cache: true
  },
  component: _import('crm/change/search')
}];