var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticStyle: { height: "500px", border: "1px solid #eee" } },
    [
      _c(
        "el-aside",
        {
          staticStyle: { "background-color": "#f5f5f5" },
          attrs: { width: "250px" }
        },
        [
          _c("tree-organization", {
            attrs: { config: _vm.treeConfig },
            on: { "node-click": _vm.treeNodeClick }
          })
        ],
        1
      ),
      _c(
        "el-main",
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData,
                "row-style": { height: "20px" },
                "cell-style": { padding: "6px 0" },
                height: "430"
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "姓名", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { prop: "titleText", label: "职务", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { prop: "mobile", label: "联系方式", width: "110" }
              }),
              _c("el-table-column", {
                attrs: { prop: "orgName", label: "所属机构", width: "150" }
              }),
              _c("el-table-column", {
                attrs: { align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "请输入姓名",
                              disabled: _vm.searchUser ? true : false
                            },
                            model: {
                              value: _vm.schWord,
                              callback: function($$v) {
                                _vm.schWord = $$v
                              },
                              expression: "schWord"
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                disabled: _vm.searchUser ? true : false,
                                icon: "el-icon-search"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.clickSearch()
                                }
                              },
                              slot: "append"
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { slot: "reference", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.clickSelectItem(
                                  scope.$index,
                                  scope.row
                                )
                              }
                            },
                            slot: "reference"
                          },
                          [_vm._v("选择")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }