import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { personDimission } from "@api/rm/person";
export default {
  name: "dialog-person-dimission",
  props: {
    // 当前
    person: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      rules: {
        name: [{
          required: true,
          message: "请填写客户姓名",
          trigger: "input"
        }],
        leaveDate: [{
          required: true,
          message: "请填写离职日期",
          trigger: "input"
        }]
      }
    };
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          var name = this.person.name;
          this.formModel = {
            name: name
          };
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 确定
    clickSave: function clickSave() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this.loading = true;
          var param = {
            personuuid: _this.person.personuuid,
            leaveReason: _this.formModel.leaveReason
          };
          personDimission(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit("refresh");
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};