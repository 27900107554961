import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    //礼品分类

    /** 新增礼品分类 */
    GIFTALASSI_ADD: function GIFTALASSI_ADD(data) {
      return request({
        url: url.gift.classify.giftClassiAdd,
        data: data
      });
    },

    /** 更新礼品分类 */
    GIFTALASSI_UPDATE: function GIFTALASSI_UPDATE(data) {
      return request({
        url: url.gift.classify.update,
        data: data
      });
    },

    /** 更新礼品分类排序值 */
    GIFTALASSI_UPDATESORT: function GIFTALASSI_UPDATESORT(data) {
      return request({
        url: url.gift.classify.updateSort,
        data: data
      });
    },

    /** 更新礼品分类状态 */
    GIFTALASSI_UPDATESTATUS: function GIFTALASSI_UPDATESTATUS(data) {
      return request({
        url: url.gift.classify.updateStatus,
        data: data
      });
    },

    /** 获取礼品分类详情 */
    GIFTALASSI_GETINFOBYID: function GIFTALASSI_GETINFOBYID(data) {
      return request({
        url: url.gift.classify.getInfoById,
        data: data
      });
    },

    /** 获取礼品分类分页列表 */
    GIFTALASSI_GETPAGELIST: function GIFTALASSI_GETPAGELIST(data) {
      return request({
        url: url.gift.classify.getPageList,
        data: data
      });
    },

    /** 获取礼品分类下拉列表 */
    GIFTALASSI_GETCHOOSELIST: function GIFTALASSI_GETCHOOSELIST(data) {
      return request({
        url: url.gift.classify.getChooseList,
        data: data
      });
    },
    //礼品管理

    /** 新增礼品 */
    GETGIFT_GIFTADD: function GETGIFT_GIFTADD(data) {
      return request({
        url: url.gift.manage.giftAdd,
        data: data
      });
    },

    /** 更新礼品 */
    GETGIFT_UPDATE: function GETGIFT_UPDATE(data) {
      return request({
        url: url.gift.manage.update,
        data: data
      });
    },

    /** 更新礼品状态 */
    GETGIFT_UPDATESTATUS: function GETGIFT_UPDATESTATUS(data) {
      return request({
        url: url.gift.manage.updateStatus,
        data: data
      });
    },

    /** 更新排序值 */
    GETGIFT_UPDATESORT: function GETGIFT_UPDATESORT(data) {
      return request({
        url: url.gift.manage.updateSort,
        data: data
      });
    },

    /** 获取详情数据 */
    GETGIFT_GETINFOBYID: function GETGIFT_GETINFOBYID(data) {
      return request({
        url: url.gift.manage.getInfoById,
        data: data
      });
    },

    /** 导出数据 */
    GETGIFT_EXPORTLIST: function GETGIFT_EXPORTLIST(data) {
      return request({
        url: url.gift.manage.exportList,
        data: data,
        responseType: 'arraybuffer'
      });
    },

    /** 获取礼品选择分页列表 */
    GETGIFT_CHOOSE_PAGE: function GETGIFT_CHOOSE_PAGE(data) {
      return request({
        url: url.gift.manage.getChoosePageList,
        data: data
      });
    },
    //审批管理

    /** 获取分页数据 */
    APPROVAL_GETPAGE: function APPROVAL_GETPAGE(data) {
      return request({
        url: url.gift.examineAndApprove.getPage,
        data: data
      });
    },

    /** 导出数据 */
    APPROVAL_EXPORTLIST: function APPROVAL_EXPORTLIST(data) {
      return request({
        url: url.gift.examineAndApprove.exportList,
        data: data,
        responseType: 'arraybuffer'
      });
    },

    /** 客服审批 */
    APPROVAL_CUSTOMER_SERVEICE_APPROVAL: function APPROVAL_CUSTOMER_SERVEICE_APPROVAL(data) {
      return request({
        url: url.gift.examineAndApprove.customerServiceApproval,
        data: data
      });
    },

    /** 财务审批 */
    APPROVAL_FINANCE_APPROVAL: function APPROVAL_FINANCE_APPROVAL(data) {
      return request({
        url: url.gift.examineAndApprove.financeApproval,
        data: data
      });
    },

    /** 客服批量审批 */
    APPROVAL_CUSTOMER_SERVICE_BATCH_APPROVAL: function APPROVAL_CUSTOMER_SERVICE_BATCH_APPROVAL(data) {
      return request({
        url: url.gift.examineAndApprove.customerServiceBatchApproval,
        data: data
      });
    },

    /** 财务批量审批 */
    APPROVAL_FINANCE_BATCH_APPROVAL: function APPROVAL_FINANCE_BATCH_APPROVAL(data) {
      return request({
        url: url.gift.examineAndApprove.financeBatchApproval,
        data: data
      });
    },

    /** 取消礼品兑换审批数据 */
    APPROVAL_CANCELLED: function APPROVAL_CANCELLED(data) {
      return request({
        url: url.gift.examineAndApprove.cancelled,
        data: data
      });
    },

    /** 新增礼品兑换 */
    APPROVAL_ADD_EXCHANGGE: function APPROVAL_ADD_EXCHANGGE(data) {
      return request({
        url: url.gift.examineAndApprove.addExchange,
        data: data
      });
    },
    // 发货管理

    /** 导出数据 */
    SHIPMENTS_EXPORTDATA: function SHIPMENTS_EXPORTDATA(data) {
      return request({
        url: url.gift.shipments.exportData,
        data: data,
        responseType: 'arraybuffer'
      });
    },

    /** 发货 */
    SHIPMENTS_SHIP: function SHIPMENTS_SHIP(data) {
      return request({
        url: url.gift.shipments.ship,
        data: data
      });
    },

    /** 确认收货 */
    SHIPMENTS_CONFIRM_RECEIPT: function SHIPMENTS_CONFIRM_RECEIPT(data) {
      return request({
        url: url.gift.shipments.confirmReceipt,
        data: data
      });
    },

    /** 导入批量发货 */
    SHIPMENTS_IMPORT_SHIP: function SHIPMENTS_IMPORT_SHIP(data) {
      return request({
        url: url.gift.shipments.importShip,
        data: data,
        responseType: 'arraybuffer'
      });
    },
    // 年化规则

    /** 获取分页数据 */
    ANNUALRULE_GETPAGE: function ANNUALRULE_GETPAGE(data) {
      return request({
        url: url.gift.annualRule.getPage,
        data: data
      });
    },

    /** 新增 */
    ANNUALRULE_ADD: function ANNUALRULE_ADD(data) {
      return request({
        url: url.gift.annualRule.add,
        data: data
      });
    },

    /** 更新 */
    ANNUALRULE_UPDATE: function ANNUALRULE_UPDATE(data) {
      return request({
        url: url.gift.annualRule.update,
        data: data
      });
    },

    /** 详情 */
    ANNUALRULE_GETINFO: function ANNUALRULE_GETINFO(data) {
      return request({
        url: url.gift.annualRule.getInfo,
        data: data
      });
    },

    /** 删除 */
    ANNUALRULE_DELETE: function ANNUALRULE_DELETE(data) {
      return request({
        url: url.gift.annualRule.delete,
        data: data
      });
    },
    // 会员权益

    /** 获取分页数据 */
    MEMBERINTEREST_GETPAGE: function MEMBERINTEREST_GETPAGE(data) {
      return request({
        url: url.gift.memberInterest.getPage,
        data: data
      });
    },

    /** 新增 */
    MEMBERINTEREST_ADD: function MEMBERINTEREST_ADD(data) {
      return request({
        url: url.gift.memberInterest.add,
        data: data
      });
    },

    /** 更新 */
    MEMBERINTEREST_UPDATE: function MEMBERINTEREST_UPDATE(data) {
      return request({
        url: url.gift.memberInterest.update,
        data: data
      });
    },

    /** 详情 */
    MEMBERINTEREST_GETINFO: function MEMBERINTEREST_GETINFO(data) {
      return request({
        url: url.gift.memberInterest.getInfo,
        data: data
      });
    },

    /** 更新状态 */
    MEMBERINTEREST_UPDATESTATUS: function MEMBERINTEREST_UPDATESTATUS(data) {
      return request({
        url: url.gift.memberInterest.updateStatus,
        data: data
      });
    },

    /** 删除 */
    MEMBERINTEREST_DELETE: function MEMBERINTEREST_DELETE(data) {
      return request({
        url: url.gift.memberInterest.delete,
        data: data
      });
    },

    /** 权益选择数据 */
    MEMBERINTEREST_GETCHIISELIST: function MEMBERINTEREST_GETCHIISELIST(data) {
      return request({
        url: url.gift.memberInterest.getChooseList,
        data: data
      }).then(function (res) {
        var arr = res.map(function (item) {
          return {
            label: item.name,
            value: item.id
          };
        });
        return arr;
      });
    }
  };
});