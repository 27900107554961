// import ENUM from '@/maps/enum/enum'
var VAL_WIDTH = '120px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '年份',
  valueKey: 'fiscalYear',
  value: '',
  type: 'date',
  props: {
    type: 'year',
    valueFormat: 'yyyy',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '工号',
  valueKey: 'personNum',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '姓名',
  valueKey: 'personName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}];