// 客户银行卡信息
export default {
  listCustomerBankCard: '/customer/bankCard/list',
  // 列表
  getCustomerBankCard: '/customer/bankCard/getInfo',
  // 详情
  updateCustomerBankCard: '/customer/bankCard/updateInfo',
  // 第三方接口更新详情
  checkBankCard3c: '/customer/bankCard/checkBankCard3c',
  // 验证3要素
  getCheckBankCardInfo: '/customer/bankCard/getCheckBankCardInfo',
  // 获取银行卡验证信息
  updateBankInfo: '/customer/bankCard/updateBankInfo',
  // 更新银行卡详情
  update: '/customer/bankCard/update',
  // 编辑
  create: '/customer/bankCard/create',
  // 创建
  remove: '/customer/bankCard/remove',
  // 删除
  exportExcel: '/customer/bankCard/exportExcel',
  // 导出
  importExcel: '/import/customer/bankCard/importExcel',
  // 导入
  getExpectInfo: '/customer/bankCard/getExpectInfo',
  // 获取预期银行卡信息
  bankCardInfoSave: '/customer/bankCard/save',
  // 保存
  bankCardList: '/import/customer/bankCard/list' // 导入结果列表

};