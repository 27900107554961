//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '机构',
  prop: 'orgName',
  width: '180px'
}, {
  label: '团队',
  prop: 'teamName',
  width: '200px'
}, {
  label: '申请描述',
  prop: 'applyDesc',
  width: '500px'
}, {
  label: '申请人',
  prop: 'applyerName',
  width: VAL_WIDTH
}, {
  label: '申请时间',
  prop: 'applyTime',
  width: VAL_WIDTH
}, {
  label: '审核状态',
  prop: 'auditStatusText',
  width: VAL_WIDTH
}, {
  label: '审核人',
  prop: 'auditorName',
  width: VAL_WIDTH
}, {
  label: '审核时间',
  prop: 'auditTime',
  width: VAL_WIDTH
}, {
  label: '审核说明',
  prop: 'auditRemark',
  width: '100px'
}];