import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { setUpAccount } from "@api/rm/person";
export default {
  name: "dialog-person-account",
  props: {
    // 当前职员
    person: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      rules: {
        loginName: [{
          required: true,
          message: "请填写客户系统账号",
          trigger: "input"
        }],
        password: [{
          required: true,
          message: "请填写客户密码",
          trigger: "input"
        }]
      }
    };
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          var _this$person = this.person,
              name = _this$person.name,
              personNum = _this$person.personNum,
              code = _this$person.code,
              mobile = _this$person.mobile;
          this.formModel = {
            name: name,
            personNum: personNum,
            loginName: code == null ? mobile : code
          };
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 确定
    clickSave: function clickSave() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this.loading = true;
          var param = {
            personuuid: _this.person.personuuid,
            loginName: _this.formModel.loginName,
            password: _this.formModel.password
          };
          setUpAccount(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit("refresh");
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};