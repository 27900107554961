//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import api from '@/api-new';
export default {
  name: 'dynamic',
  mixins: [],
  props: {},
  data: function data() {
    return {
      dynamicLogData: [],
      directives: {}
    };
  },
  computed: {},
  watch: {},
  methods: {
    getDetail: function getDetail() {
      var _this = this;

      var dynamicType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      var query = this.$route.query;
      var requestData = {
        relationId: query.id,
        dynamicType: dynamicType
      };
      api.GET_CUSTOMER_DYNAMIC_LOG(requestData).then(function (res) {
        _this.dynamicLogData = res;
      });
    }
  },
  created: function created() {
    this.getDetail();
  },
  mounted: function mounted() {},
  activated: function activated() {},
  deactivated: function deactivated() {},
  beforeDestroy: function beforeDestroy() {}
};