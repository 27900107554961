













































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/paper/box/archive/components/dialog-archive.vue"
}
