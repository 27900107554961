











































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/paper/service/index.vue"
}
