import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { formatAmountToString } from "@/libs/crm/format";
import { sumSummary } from "@api/crm/commission/commissionAgency";
export default {
  name: "commission-agency-collect-summary-preview",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      tableData: [],
      spanRows: [] //合并单元格的行号及行数

    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.tableData = [];
      this.spanRows = [];
    },
    loadData: function loadData() {
      var _this = this;

      var param = {
        summaryId: this.propsModel.summaryId
      };
      sumSummary(param).then(function (res) {
        //检查哪些行号需要合并单元格，及单元格长度
        var rowNumber = 0;
        _this.tableData = [];

        if (res.data != null) {
          for (var i = 0; i < res.data.length; i++) {
            _this.spanRows.push({
              rowNum: rowNumber,
              length: res.data[i].length
            });

            rowNumber += res.data[i].length;
          } //各子数组数据合并成大数组列表


          res.data.forEach(function (n) {
            var thisBatchYearAmount = 0;
            n.forEach(function (v) {
              thisBatchYearAmount += parseFloat(v.yearAmount);
              v.commission = formatAmountToString(v.commission); //千分位分隔
            }); //列表增加年化入金项

            n.forEach(function (v) {
              v.totalYearAmount = formatAmountToString(thisBatchYearAmount / 10000); //换成“万元”单位

              _this.tableData.push(v);
            });
          });
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    //合并单元格
    rowSpanMethod: function rowSpanMethod(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;
      var span = null;

      for (var i = 0; i < this.spanRows.length; i++) {
        if (this.spanRows[i].rowNum == rowIndex) {
          span = this.spanRows[i];
          break;
        }
      }

      if (columnIndex == 0 || columnIndex == 3) {
        if (span != null) {
          return {
            rowspan: span.length,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    }
  }
};