import ENUM from '@/maps/enum/enum';
import { listOrgTree } from '@api/rm/org';
var VAL_WIDTH = '200px';
export var FORMS = [{
  label: '编号',
  valueKey: 'orgCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  },
  rules: [{
    required: true,
    trigger: 'blur',
    message: '请填写编号'
  }]
}, {
  label: '名称',
  valueKey: 'orgName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  },
  rules: [{
    required: true,
    trigger: 'blur',
    message: '请填写名称'
  }]
}, {
  label: '简称',
  valueKey: 'orgNameAbbr',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  },
  rules: [{
    required: true,
    trigger: 'blur',
    message: '请填写简称'
  }]
}, {
  label: '类型',
  valueKey: 'orgType',
  value: [],
  type: 'select',
  props: {
    style: {
      width: VAL_WIDTH
    }
  },
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_0113
  },
  rules: [{
    required: true,
    trigger: 'blur',
    message: '请选择类型'
  }]
}, {
  label: '所属机构',
  valueKey: 'parentOrgCd',
  value: '',
  type: 'select-tree',
  props: {
    style: {
      width: VAL_WIDTH
    },
    config: {
      nodeKey: 'orgCd' // 唯一标识

    }
  },
  method: listOrgTree,
  params: {}
}, {
  label: '顺序',
  valueKey: 'orgIndex',
  value: '',
  type: 'input',
  props: {
    type: 'number',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '是否可用',
  valueKey: 'enabled',
  value: '1',
  type: 'radio',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  },
  options: [{
    label: '启用',
    value: '1'
  }, {
    label: '禁用',
    value: '0'
  }]
}];