
























































































































































export default function (Component) {
  Component.options.__source = "src/views/rm/system/tool/index.vue"
}
