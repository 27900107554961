//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '公司名称',
  prop: 'orgName',
  width: '300px'
}, {
  label: '历史总数',
  prop: 'countTotal',
  width: '120px',
  align: 'right'
}, {
  label: '待处理数',
  prop: 'countNot',
  width: '120px',
  align: 'right'
}];