import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import echarts from "echarts";
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import DynamicForm from "@/components/common/dynamic-form";
import { formatDate } from "@/libs/crm/format";
import { countOrgAcceptDay } from "@api/crm/report/report";
export default {
  name: "count-org-acceptDay",
  components: {
    DynamicForm: DynamicForm
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS
    };
  },
  computed: {},
  mounted: function mounted() {
    //设置初始搜索日期，过去12个月
    var date = new Date();
    date.setMonth(date.getMonth() - 12);
    date.setDate(1);
    var beginDate = formatDate(date);
    this.$refs.form.$data.model.dateBegin = beginDate;
    this.loadData();
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.loadData();
    },
    // TODO 获取列表
    loadData: function loadData() {
      var _this = this;

      this.initFilter();
      var query = this.query;
      query.orgCd = "0150"; //直营机构

      query.dayBegin = 1; //逾期天数

      query.dayEnd = 1;
      var query2 = Object.assign({}, query);
      query2.dayBegin = 2; //逾期天数

      query2.dayEnd = 2;
      var param = {
        query: query
      };
      var promiseArr = []; //多个请求数组

      promiseArr[0] = countOrgAcceptDay(param); //查询逾期1天统计

      param = {
        query: query2
      };
      promiseArr[1] = countOrgAcceptDay(param); //查询逾期2天统计
      //图表数据

      Promise.all(promiseArr).then(function (res) {
        if (res[0].data != null && res[1].data != null) {
          var echartsData = [];
          echartsData[0] = ["合同受理逾期", "24小时", "48小时"];
          var data = res[0].data;
          var data2 = res[1].data;

          for (var i = 0; i < data.length; i++) {
            var companyCd = data[i].companyCd;
            var arr = [];
            arr[0] = data[i].companyName;
            arr[1] = data[i].acceptDay;

            for (var j = 0; j < data2.length; j++) {
              if (companyCd == data2[j].companyCd) {
                arr[2] = data2[j].acceptDay;
                break;
              }
            }

            echartsData.push(arr);
          }

          _this.echartsDraw(echartsData);
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.$message({
          message: "查询数据出错",
          type: "error"
        });
      });
    },
    echartsDraw: function echartsDraw(data) {
      //绘图
      var echartDom = document.getElementById("chartsOverTimeDom");
      var myChart = echarts.init(echartDom);
      var option = {
        title: {
          text: "机构合同录入受理逾期统计",
          left: "left",
          subtext: "",
          sublink: ""
        },
        legend: {},
        tooltip: {},
        dataset: {
          source: data
        },
        xAxis: {
          type: "category"
        },
        yAxis: {},
        series: [{
          type: "bar"
        }, {
          type: "bar"
        }]
      };
      option && myChart.setOption(option);
    }
  },
  created: function created() {}
};