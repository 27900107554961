//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import LOG_TYPE from "@/maps/enum/contract-log-type";
import ATTACHMENT_TYPE from "@/maps/enum/attachment-type";
import Attachment from "@/components/business/attachment";
import ContractLog from "@/components/business/contract-log";
export default {
  name: "service-particular-apply",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Attachment: Attachment,
    ContractLog: ContractLog
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值 
      collapseActive: ["1"],
      //信息折叠默认展开
      attachmentParticular: ATTACHMENT_TYPE.CONTRACT_PARTICULAR_APPLY_STAFF,
      //附件类型：特殊申请
      showLogDialog: false,
      contractLogType: LOG_TYPE.BUY
    };
  },
  created: function created() {},
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.getIfo();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tabActive = "tabContract"; //默认打开第一个标签页
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    getIfo: function getIfo() {
      this.model = this.propsModel;
    },
    clickConfirm: function clickConfirm() {
      this.loading = false;
      this.showDialog = false;
      this.$emit("refresh");
    },
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    }
  }
};