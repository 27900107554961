import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import dialogMixin from "@/mixins/dialog";
import { formatAmountToString } from "@/libs/crm/format";
import { listCompanyNoCommission, removeApplication, addBatch, addDetailToBatch } from "@api/crm/commission/commissionAgency";
export default {
  name: "commission-agency-application",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    batchId: {
      type: String,
      default: ""
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tableData: [],
      checkboxRows: [] //复选框

    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    loadData: function loadData() {
      var _this = this;

      var param = {
        orgCd: this.propsModel.orgCd
      };
      listCompanyNoCommission(param, DATA_FORMAT_TYPE.AMOUNT).then(function (res) {
        _this.tableData = [];

        if (res.data != null) {
          res.data.forEach(function (n) {
            var bonus = n.listDetail[0].bonus == null ? 0 : parseFloat(n.listDetail[0].bonus);
            var deduct = n.listDetail[0].deduct == null ? 0 : parseFloat(n.listDetail[0].deduct);
            n.listDetail[0].commission = formatAmountToString(bonus + deduct); //实发金额

            n.listDetail[0].bonus = formatAmountToString(n.listDetail[0].bonus);
            n.listDetail[0].deduct = formatAmountToString(n.listDetail[0].deduct);
          });
          _this.tableData = res.data;
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    //删除申请
    clickRemove: function clickRemove() {
      var _this2 = this;

      if (this.checkboxRows.length == 0) {
        this.$message("请选择合同");
      } else {
        this.$confirm("删除推介费申请后，需要分公司重新录入人员提交，确定要删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          var param = {
            arrayApplicationId: []
          };

          _this2.checkboxRows.forEach(function (item) {
            param.arrayApplicationId.push(item.applicationId);
          });

          removeApplication(param).then(function (res) {
            _this2.loadData();
          }).catch(function (e) {
            _this2.loading = false;
          });
        }).catch(function () {});
      }
    },
    //创建推介费表
    clickCreateBatch: function clickCreateBatch() {
      var _this3 = this;

      if (this.checkboxRows.length == 0) {
        this.$message("请选择合同");
      } else {
        var param = {
          orgCd: this.propsModel.orgCd,
          arrayApplicationId: []
        };
        this.checkboxRows.forEach(function (item) {
          param.arrayApplicationId.push(item.applicationId);
        });

        if (this.batchId != null && this.batchId != "") {
          //追加到已存在台帐
          param.batchId = this.batchId;
          addDetailToBatch(param).then(function (res) {
            _this3.showDialog = false;

            _this3.$emit("refresh");
          });
        } else {
          //创建新台帐 
          addBatch(param).then(function (res) {
            _this3.showDialog = false;

            _this3.$emit("refresh");
          });
        }
      }
    },
    //复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      this.checkboxRows = rows;
    }
  }
};