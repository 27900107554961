import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import TABLE_LIST from "./option/list"; // 表头

import DynamicForm from "@/components/common/dynamic-form";
import DynamicTable from "@/components/common/dynamic-table";
import { listDataCountAttachment } from "@api/crm/service/contract";
import { bale, downloadPack, list } from "@api/crm/attachment/attachment";
export default {
  name: "service-attachment-download",
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {
        auto: false,
        multiple: true
      },
      pagination: {},
      query: {},
      tableData: [],
      currentRow: {},
      //当前点击行
      checkboxRows: [],
      //复选框选中行
      showProcess: false,
      //是否显示进度条
      process: 0,
      //处理进度
      timer: null //计时器

    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();

      if (this.query.schContractCd != "" && this.query.schContractCd.indexOf(",") > 0) {
        //多个合同号分割
        var array = this.query.schContractCd.split(",");
        this.query.arrayContractCd = array;
        this.query.contractCd = "";
      } else {
        //单个合同号
        this.query.contractCd = this.query.schContractCd;
        this.query.arrayContractCd = null;
      }

      var param = {
        page: page,
        query: this.query
      };
      this.loading = true;
      listDataCountAttachment(param, 0).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.tableData = data;
          _this.pagination = {
            total: totalCount
          };
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    //复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      this.checkboxRows = rows;
    },
    //打包下载计时器
    timerDownload: function timerDownload() {
      var _this2 = this;

      var param = {
        arrayContractId: []
      };
      this.checkboxRows.forEach(function (n) {
        param.arrayContractId.push(n.contractId);
      });
      this.showProcess = true;
      bale(param).then(function (res) {
        var msg = res.data;

        if (!isNaN(msg)) {
          //进度数字
          _this2.process = parseInt(msg);
        } else if (msg.indexOf(".zip") > -1) {
          //打包完成
          _this2.process = 100;
          _this2.showProcess = false;
          clearInterval(_this2.timer); //启动下载

          var paramDownload = {
            fileName: msg
          };
          downloadPack(paramDownload).catch(function (e) {
            _this2.$message({
              message: e,
              type: "error"
            });
          });
        }
      }).catch(function (e) {
        clearInterval(_this2.timer);
      });
    },
    //下载附件
    clickDownload: function clickDownload() {
      if (this.checkboxRows.length == 0) {
        this.$message("请选择合同");
      } else {
        this.timer = setInterval(this.timerDownload, 500);
      }
    }
  },
  created: function created() {}
};