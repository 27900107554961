//工具
export default {
  cleanCache: '/tool/cleanCache',
  // 清理缓存  
  getRemindSwitch: '/tool/getRemindSwitch',
  // 消息提醒：获取当前手工模式是否启动
  setRemindSwitch: '/tool/setRemindSwitch',
  // 消息提醒：手动启动
  getSmsSwitch: '/tool/getSmsSwitch',
  // 短信：获取当前是否启动
  setSmsSwitch: '/tool/setSmsSwitch',
  // 短信：手动启动
  syncGoldPrice: '/tool/syncGoldPrice',
  // 同步金价
  syncOrgFromDing: '/tool/syncOrgFromDing',
  // 同步钉钉机构和用户，只同步指定机构的直接下属机构
  reCountBackup: '/tool/reCountBackup',
  // 合同重新核算备份相关数据
  reCount: '/tool/reCount' // 合同重新核算

};