//合同回访
export default {
  listContract: '/visit/listContract',
  // 待回访合同列表 
  add: '/visit/add',
  // 申请回访   
  remove: '/visit/remove',
  // 删除
  uploadVudio: '/visit/uploadVudio',
  // 上传回访录音
  doVisit: '/visit/doVisit',
  // 回访    
  listVisitLog: '/visit/listVisitLog',
  // 回访历史
  listVisit: '/visit/listVisit',
  // 回访申请列表
  appendFile: '/visit/appendFile',
  // 追加录音
  download: '/visit/download',
  // 下载录音
  open: '/visit/open',
  // 打开录音
  exportExcel: '/visit/exportExcel' // 导出Excel

};