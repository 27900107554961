var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [
            { name: "dialogDrag", rawName: "v-dialogDrag" },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            top: "30vh",
            width: "700px",
            title: "选择产品",
            visible: _vm.showDialog,
            closeOnClickModal: false
          },
          on: {
            "update:visible": function($event) {
              _vm.showDialog = $event
            },
            close: _vm.dialogClose
          }
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.productId,
                callback: function($$v) {
                  _vm.productId = $$v
                },
                expression: "productId"
              }
            },
            [
              _vm.product.type03.length > 0
                ? _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("SM")
                  ])
                : _vm._e(),
              _vm._l(_vm.product.type03, function(item) {
                return _c(
                  "el-radio",
                  { key: item.productId, attrs: { label: item.productId } },
                  [_vm._v(_vm._s(item.productName))]
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "mini" },
                  on: { click: _vm.cancel }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    size: "mini",
                    loading: _vm.loading
                  },
                  on: { click: _vm.clickSubmit }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }