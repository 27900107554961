var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        title: "",
        width: "500px",
        modal: false,
        visible: _vm.showDialog,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose,
        open: _vm.openDialog
      }
    },
    [
      _c(
        "el-collapse",
        {
          attrs: { accordion: "" },
          on: { change: _vm.changeCollapse },
          model: {
            value: _vm.collapseActive,
            callback: function($$v) {
              _vm.collapseActive = $$v
            },
            expression: "collapseActive"
          }
        },
        _vm._l(_vm.historyData, function(item, index) {
          return _c(
            "el-collapse-item",
            { key: index, attrs: { title: item.createDate, name: index } },
            [
              _c("div", [_vm._v("变更内容：" + _vm._s(item.changeDesc))]),
              _c("div", [
                _c(
                  "ol",
                  _vm._l(_vm.compareData, function(item, index) {
                    return _c("li", { key: index }, [_vm._v(_vm._s(item))])
                  }),
                  0
                )
              ])
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }