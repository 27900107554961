//划款
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 划款
{
  path: "".concat(baseRoute, "/transfer/transfer"),
  name: 'transfer-new',
  meta: {
    title: '划款>划款',
    auth: true,
    cache: true
  },
  component: _import('crm/transfer/transfer')
}, // 查询
{
  path: "".concat(baseRoute, "/transfer/search"),
  name: 'transfer-service',
  meta: {
    title: '划款>查询',
    auth: true,
    cache: true
  },
  component: _import('crm/transfer/search')
}];