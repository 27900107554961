import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
// 计算转换成整数需乘的值
export function integerOperation(val1, val2) {
  // 获取数值1的小数位的长度
  var val1DecimalIndex = val1.toString().indexOf(".");
  var len1 = val1DecimalIndex === -1 ? 0 : val1.toString().length - val1DecimalIndex - 1; // 获取数值2的小数位的长度

  var val2DecimalIndex = val2.toString().indexOf(".");
  var len2 = val2DecimalIndex === -1 ? 0 : val2.toString().length - val2DecimalIndex - 1; // 获取大的小数位数

  var p = Math.max(len1, len2); // 避免最终求出结果的长度大于最大长度的时候导致精度丢失 开启下面
  // p += p - Math.min(len1, len2); 
  // Math.pow() 计算一个数的指定次幂的方法 10的p次方

  return Math.pow(10, p);
} // 加法运算

export function add(val1, val2) {
  var p = integerOperation(val1, val2);
  return (val1 * p + val2 * p) / p;
} // 减法运算

export function subtract(val1, val2) {
  var p = integerOperation(val1, val2);
  return (val1 * p - val2 * p) / p;
} // 乘法运算

export function multiply(val1, val2) {
  var p = integerOperation(val1, val2);
  return val1 * p * (val2 * p) / (p * p);
} // 除法运算

export function divisionMethod(val1, val2) {
  var p = integerOperation(val1, val2);
  return val1 * p / (val2 * p) / (p * p);
}