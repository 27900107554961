export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    /** 新增任务 */
    ADD_TASK: function ADD_TASK(data) {
      return request({
        url: url.task.addTask,
        data: data
      });
    },

    /** 更新任务 */
    UPDATE_TASK: function UPDATE_TASK(data) {
      return request({
        url: url.task.updateTask,
        data: data
      });
    },

    /** 更新任务状态 */
    UPDATE_TASK_STATUS: function UPDATE_TASK_STATUS(data) {
      return request({
        url: url.task.updateTaskStatus,
        data: data
      });
    },

    /** 执行任务 */
    EXECUTE_TASK: function EXECUTE_TASK(data) {
      return request({
        url: url.task.executeTask,
        data: data
      });
    },

    /** 获取最近10条任务执行日志 */
    TASK_LOG: function TASK_LOG(data) {
      return request({
        url: url.task.getTaskLogList,
        data: data
      }).then(function (res) {
        return res;
      });
    }
  };
});