












































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/interest/finance/components/dialog-audit-batch.vue"
}
