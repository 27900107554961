var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        top: "25vh",
        width: "700px",
        title: "编辑礼品",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-tabs",
        { attrs: { value: "tabContract" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "客户信息", name: "tabContract" } },
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.collapseActive,
                    callback: function($$v) {
                      _vm.collapseActive = $$v
                    },
                    expression: "collapseActive"
                  }
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "合同信息", name: "1" } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "block-col-2" },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("客户姓名:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.formModel.customerName) + " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("所属机构:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.formModel.orgName) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-2" },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("性别:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.formModel.sexText) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("手机号码:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.formModel.mobile) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-2" },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("地址:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.formModel.address) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-2" },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("生日:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.formModel.birthday) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("年龄:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.formModel.age) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-2" },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("庆祝日:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.formModel.celebrateDay) + " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("vip级别:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.formModel.vipText) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("礼品")
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "礼品", prop: "gift" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.gift,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "gift", $$v)
                          },
                          expression: "formModel.gift"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "礼品价值", prop: "giftValue" } },
                    [
                      _c("el-input", {
                        attrs: { type: "number" },
                        model: {
                          value: _vm.formModel.giftValue,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "giftValue", $$v)
                          },
                          expression: "formModel.giftValue"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递公司", prop: "expressCompany" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.expressCompany,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "expressCompany", $$v)
                            },
                            expression: "formModel.expressCompany"
                          }
                        },
                        _vm._l(_vm.selectData.expressCompany, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递日期", prop: "expressDate" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.formModel.expressDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "expressDate", $$v)
                          },
                          expression: "formModel.expressDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递单号", prop: "expressNo" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.expressNo,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "expressNo", $$v)
                          },
                          expression: "formModel.expressNo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "快递状态", prop: "expressStatus" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.expressStatus,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "expressStatus", $$v)
                            },
                            expression: "formModel.expressStatus"
                          }
                        },
                        _vm._l(_vm.selectData.expressStatus, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "评论日期", prop: "reviewDate" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.formModel.reviewDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "reviewDate", $$v)
                          },
                          expression: "formModel.reviewDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "评论状态", prop: "reviewState" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.reviewState,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "reviewState", $$v)
                            },
                            expression: "formModel.reviewState"
                          }
                        },
                        _vm._l(_vm.selectData.reviewState, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remark" } },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.remark,
                            expression: "formModel.remark"
                          }
                        ],
                        staticClass: "el-textarea__inner",
                        attrs: { placeholder: "请填写备注" },
                        domProps: { value: _vm.formModel.remark },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formModel,
                              "remark",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: { click: _vm.confirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }