




































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/commissionAgency/deal/components/dialog-closeDebt.vue"
}
