import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import COMMISSION_STATUS from '@/maps/enum/status/commission-status';
import { commissionAudit } from '@api/crm/commissionV3/commissionV3';
export default {
  name: 'settle-batch',
  props: {
    propsModel: {
      type: Array,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      showLogDialog: false,
      tableData: [],
      formModel: {},
      //表单
      sum: {},
      //统计
      rules: {}
    };
  },
  created: function created() {},
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.sum = {};
      this.formModel = {};
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    clickAuditPlan: function clickAuditPlan() {
      var _this = this;

      if (this.formModel.advice == null || this.formModel.advice == '') {
        return this.$message('请填写审核意见');
      } // 退回计划


      this.$confirm('是否退回到待计划?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this.audit(COMMISSION_STATUS.FINANCE_AUDIT_COUNT_FAIL.value);
      }).catch(function () {});
    },
    clickAuditFail: function clickAuditFail() {
      // 审核不通过
      if (this.formModel.advice == null || this.formModel.advice == '') {
        this.$message('请填写审核意见');
      } else {
        this.audit(COMMISSION_STATUS.FINANCE_AUDIT_FAIL.value);
      }
    },
    clickAudit: function clickAudit() {
      // 审核通过
      this.audit(COMMISSION_STATUS.MONEY_AUDIT.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var arrayApplicationId = [];

          _this2.tableData.forEach(function (n) {
            arrayApplicationId.push(n.applicationId);
          });

          var param = {
            arrayApplicationId: arrayApplicationId,
            status: result,
            advice: _this2.formModel.advice
          };
          _this2.loading = true;
          commissionAudit(param).then(function (res) {
            _this2.loading = false;
            _this2.showDialog = false;

            _this2.$emit('refresh');
          }).catch(function (e) {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    loadData: function loadData() {
      for (var i = 0; i < this.propsModel.length; i++) {
        var rowData = this.propsModel[i];
        var sumBonus = 0; // 行合计

        for (var j = 0; j < rowData.detailList.length; j++) {
          var n = rowData.detailList[j];
          var keyName = n.titleId + 'name';
          var keyBonus = n.titleId + 'bonus';
          rowData[keyName] = n.bonusUserName;
          rowData[keyBonus] = n.bonus;
          sumBonus += parseFloat(n.bonus);
        }

        rowData.sumBonus = Number(sumBonus.toFixed(2));
        this.tableData.push(rowData);
      }
    },
    // 设置表格统计行
    setSummaryRow: function setSummaryRow() {
      var sumI0 = 0;
      var sumI1 = 0;
      var sumI4 = 0;
      var sumI2 = 0;
      var sumI3 = 0;
      var sumI5 = 0;
      var sum = 0;
      this.tableData.forEach(function (n) {
        sumI0 += n.i0bonus;
        sumI1 += n.i1bonus == null ? 0 : n.i1bonus;
        sumI4 += n.i4bonus == null ? 0 : n.i4bonus;
        sumI2 += n.i2bonus == null ? 0 : n.i2bonus;
        sumI3 += n.i3bonus == null ? 0 : n.i3bonus;
        sumI5 += n.i5bonus == null ? 0 : n.i5bonus;
        sum += n.sumBonus;
      });
      var sumRow = [];
      sumRow[0] = '合计';
      sumRow[1] = '共' + this.tableData.length + '笔';
      sumRow[8] = sumI0;
      sumRow[10] = sumI1.toFixed(2);
      sumRow[12] = sumI4.toFixed(2);
      sumRow[14] = sumI2.toFixed(2);
      sumRow[16] = sumI3.toFixed(2);
      sumRow[18] = sumI5.toFixed(2);
      sumRow[19] = sum.toFixed(2);
      return sumRow;
    }
  }
};