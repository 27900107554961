import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import PRODUCT_CATEGORY1 from '@/maps/enum/product-category1';
import PRODUCT_CATEGORY2 from '@/maps/enum/product-category2';
import { list } from "@api/crm/setup/product";
export default {
  name: "dialog-product",
  props: {// productType: {
    //   type: Array,
    //   default() {
    //     return [];
    //   },
    // },
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      //表单值
      arrayProductCategory1: [//PRODUCT_CATEGORY1.HJ,
      //PRODUCT_CATEGORY1.JJS,
      PRODUCT_CATEGORY1.SM //PRODUCT_CATEGORY1.YXHH,
      //PRODUCT_CATEGORY1.JXWY,
      ],
      productId: "",
      product: {
        // type01: [],
        // type02: [],
        type03: [] // type04: [],
        // type05: [],

      },
      currentRow: {},
      // 当前点击行
      rules: {}
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.productId = "";
      this.product = {
        //type01: [],
        //type02: [],
        type03: [] //type04: [],
        //type05: [],

      };
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.showDialog = false;
    },
    cancel: function cancel() {
      //对话框取消按钮
      this.dialogClose();
    },
    //加载数据
    loadData: function loadData() {
      var _this = this;

      var param = {
        query: {
          arrayProductCategory1: this.productCategory1,
          enabled: "1",
          right: "1"
        },
        page: {
          pageSize: 999,
          pageNumber: 1
        }
      };
      list(param).then(function (res) {
        res.data.forEach(function (n) {
          // if (PRODUCT_CATEGORY1.HJ == n.productType) {
          //   this.product["type" + PRODUCT_CATEGORY1.HJ].push(n);
          // } else if (PRODUCT_CATEGORY1.JJS == n.productType) {
          //   this.product["type" + PRODUCT_CATEGORY1.JJS].push(n);
          if (PRODUCT_CATEGORY1.SM == n.productType) {
            _this.product["type" + PRODUCT_CATEGORY1.SM].push(n); // } else if (PRODUCT_CATEGORY1.YXHH == n.productType) {
            //   this.product["type" + PRODUCT_CATEGORY1.YXHH].push(n);
            // } else if (PRODUCT_CATEGORY1.JXWY == n.productType) {
            //   this.product["type" + PRODUCT_CATEGORY1.JXWY].push(n);

          }
        });
      });
    },
    clickSubmit: function clickSubmit() {
      var product = null;

      for (var key in this.product) {
        var arr = this.product[key];

        for (var i = 0; i < arr.length; i++) {
          var n = arr[i];

          if (this.productId == n.productId) {
            product = n;
            break;
          }
        }
      }

      this.dialogClose();
      this.loading = false;
      this.$emit("onSelectItem", product); //调用父组件方法，将结果传回。参数1：父组件方法名，参数2：传回参数
    }
  }
};