// 表头
export default [{
  label: '操作',
  prop: 'operation',
  type: 'slot',
  width: '150px'
}, {
  label: '名称',
  prop: 'orgName',
  width: '400px'
}, {
  label: '编号',
  prop: 'orgCd',
  width: '150px'
}, {
  label: '简称',
  prop: 'orgNameAbbr',
  width: '200px'
}, {
  label: '类型',
  prop: 'orgTypeText',
  width: '100px'
}, {
  label: '顺序',
  prop: 'orgIndex',
  width: '100px'
}, {
  label: '是否可用',
  prop: 'enabled',
  width: '100px',
  type: 'slot'
}, {
  label: '加入上级部门日期_实际',
  prop: 'joinSuperActualDate',
  width: '150px'
}, {
  label: '离开上级部门日期_实际',
  prop: 'leaveSuperActualDate',
  width: '150px'
}, {
  label: '创建时间',
  prop: 'createTime',
  width: '150px'
}, {
  label: '创建人',
  prop: 'creator',
  width: '150px'
}, {
  label: '更新时间',
  prop: 'updateTime',
  width: '150px'
}, {
  label: '更新人',
  prop: 'updater',
  width: '150px'
}]; // 部门负责人

export var OrgLeaderTableList = [{
  label: '部门编号',
  prop: 'orgCd',
  width: '100px'
}, {
  label: '部门名称',
  prop: 'orgName',
  width: '300px'
}, {
  label: '加入上级部门日期',
  prop: 'joinSuperActualDate',
  width: '150px'
}, {
  label: '离开上级部门日期',
  prop: 'leaveSuperActualDate',
  width: '150px'
}, {
  label: '负责人',
  prop: 'headName',
  width: '150px'
}, {
  label: '负责人考核开始日期',
  prop: 'beginTime',
  width: '150px'
}, {
  label: '负责人考核结束日期',
  prop: 'endTime',
  width: '150px'
}, {
  label: '是否当前负责人',
  prop: 'isCurHeadText',
  width: '100px'
}]; // 归属部门列表

export var OrgSuperiorTableList = [{
  label: '部门编号',
  prop: 'orgCd',
  width: '100px'
}, {
  label: '部门名称',
  prop: 'orgName',
  width: '300px'
}, {
  label: '加入上级部门日期',
  prop: 'joinSuperActualDate',
  width: '150px'
}, {
  label: '离开上级部门日期',
  prop: 'leaveSuperActualDate',
  width: '150px'
}, {
  label: '统计类型',
  prop: 'statisticalTypeText',
  width: '150px'
}, {
  label: '归属部门类型',
  prop: 'belongTypeText',
  width: '150px'
}, {
  label: '统计归属部门编号',
  prop: 'superiorOrgCd',
  width: '150px'
}, {
  label: '统计归属部门',
  prop: 'superiorOrgName',
  width: '100px'
}, {
  label: '统计归属开始日期',
  prop: 'beginTime',
  width: '100px'
}, {
  label: '统计归属结束日期',
  prop: 'endTime',
  width: '100px'
}];