import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import ENUM from '@/maps/enum/enum';
import { getEnumList } from '@api/common/common';
import { getInfo as _getInfo, add, edit } from '@api/crm/setup/product';
export default {
  name: 'dialog-add',
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {// DynamicForm
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      selectTimeLimit: [],
      //固定合同期限数组
      selectReturnPeriod: [],
      //固定返还周期数组
      selectData: {
        productType: [],
        //产品类型
        category1: [],
        //目录1
        category2: [],
        //目录2
        category3: [],
        //目录3
        countType: [],
        //结算类型
        timeLimit: [],
        //合同期限
        returnPeriod: [] //返还周期

      },
      tableDataExt: [],
      //扩展列表数组
      rules: {
        /*
        productId: [
          { required: true, message: "请填写产品Id", trigger: "input" },
        ],
        productCode: [
          { required: true, message: "请填写手编号", trigger: "input" },
        ],
        */
        productName: [{
          required: true,
          message: '请填写产品名称',
          trigger: 'input'
        }],
        prefix: [{
          required: true,
          message: '请填写前缀',
          trigger: 'input'
        }],
        productType: [{
          required: true,
          message: '请选择产品类型',
          trigger: 'input'
        }],
        category1: [{
          required: true,
          message: '请选择产品目录1',
          trigger: 'input'
        }],
        category2: [{
          required: true,
          message: '请选择产品目录2',
          trigger: 'input'
        }],
        countType: [{
          required: true,
          message: '请选择结算类型',
          trigger: 'input'
        }],
        enabled: [{
          required: true,
          message: '请选择是否启用',
          trigger: 'input'
        }]
      }
    };
  },
  computed: {
    // 弹窗展示的标题
    title: function title() {
      return this.isEdit ? '修改' : '新增';
    },
    // 是否为编辑
    isEdit: function isEdit() {
      var productId = this.propsModel.productId;
      return !!productId;
    }
  },
  created: function created() {
    var _this = this;

    //合同期限
    getEnumList({
      enumCd: ENUM.ENUM_CD_0201
    }).then(function (res) {
      _this.selectData.timeLimit = res;
    }); //返还周期

    getEnumList({
      enumCd: ENUM.ENUM_CD_0202
    }).then(function (res) {
      _this.selectData.returnPeriod = res;
    }); //产品类型

    getEnumList({
      enumCd: ENUM.ENUM_CD_0247
    }).then(function (res) {
      _this.selectData.productType = res;
    }); //目录1

    getEnumList({
      enumCd: ENUM.ENUM_CD_0250
    }).then(function (res) {
      _this.selectData.category1 = res;
    }); //目录2

    getEnumList({
      enumCd: ENUM.ENUM_CD_0252
    }).then(function (res) {
      _this.selectData.category2 = res;
    }); //目录3

    getEnumList({
      enumCd: ENUM.ENUM_CD_0253
    }).then(function (res) {
      _this.selectData.category3 = res;
    }); //结算类型

    getEnumList({
      enumCd: ENUM.ENUM_CD_PRODUCT_COUNT_TYPE
    }).then(function (res) {
      _this.selectData.countType = res;
    });
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();

          if (this.isEdit) {
            this.getInfo();
          } else {}
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.formModel = {
        isOfficialCd: '0',
        isByDay: '1',
        enabled: '1'
      };
      this.selectTimeLimit = [];
      this.selectReturnPeriod = []; // 初始化表格

      this.tableDataExt = [];
      var tb = [];
      var tbItem = {
        dateBegin: null,
        dateEnd: null,
        contractEndDate: null,
        firstPayDate: null
      };

      for (var i = 0; i < 5; i++) {
        // 复制10条
        var formItem = Object.assign({}, tbItem);
        tb[i] = formItem;
      }

      this.$set(this, 'tableDataExt', tb);
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 获取详情
    getInfo: function getInfo() {
      var _this2 = this;

      var productId = this.propsModel.productId;

      _getInfo({
        productId: productId
      }).then(function (res) {
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this2.formModel = data;

        if (_this2.formModel.timeLimit != null && _this2.formModel.timeLimit !== '') {
          var arr = JSON.parse(_this2.formModel.timeLimit);
          arr.forEach(function (n) {
            _this2.selectTimeLimit.push(n.value);
          });
        }

        if (_this2.formModel.returnPeriod != null && _this2.formModel.returnPeriod != '') {
          var _arr = JSON.parse(_this2.formModel.returnPeriod);

          _arr.forEach(function (n) {
            _this2.selectReturnPeriod.push(n.value);
          });
        } //扩展信息


        if (_this2.formModel.extList != null) {
          for (var i = 0; i < _this2.formModel.extList.length; i++) {
            _this2.tableDataExt[i] = _this2.formModel.extList[i];
          }
        }
      });
    },
    // TODO 确定
    confirm: function confirm() {
      var _this3 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var object = _objectSpread({}, _this3.formModel);

          var timeLimit = []; // 合同期限

          var returnPeriod = []; // 返还周期

          _this3.selectTimeLimit.forEach(function (n) {
            timeLimit.push({
              text: n,
              value: n
            });
          });

          if (timeLimit.length > 0) {
            object.timeLimit = JSON.stringify(timeLimit);
          }

          _this3.selectReturnPeriod.forEach(function (n) {
            returnPeriod.push({
              text: n,
              value: n
            });
          });

          if (timeLimit.length > 0) {
            object.returnPeriod = JSON.stringify(returnPeriod);
          } // 扩展信息


          object.extList = [];

          _this3.tableDataExt.forEach(function (n) {
            if (n.dateBegin != null || n.dateEnd != null) {
              object.extList.push(n);
            }
          }); //保存


          var method = _this3.isEdit ? edit : add;
          var param = {
            object: object
          };
          _this3.loading = true;
          method(param).then(function (res) {
            _this3.loading = false;
            _this3.showDialog = false;

            _this3.$emit('refresh');
          }).catch(function (e) {
            _this3.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};