




























































































export default function (Component) {
  Component.options.__source = "src/components/business/tree-organization/index.vue"
}
