import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
// TODO  获取当天日期
export function getDate() {
  var time = new Date();
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  var day = time.getDate();
  month = month > 9 ? month : '0' + month;
  day = day < 10 ? '0' + day : day;
  var date = year + '-' + month + '-' + day;
  return date;
} // TODO  获取日期时间格式

export function formatDate(date) {
  var day = date.getDate();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();
  return year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
}