import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import { htmlRequest } from '@/plugin/axios';
/**
 * 读取public目录下的静态文件，获取字符串
 * @param {*} filePath 文件路径
 */

export function readPublicFile(filePath) {
  var promise = new Promise(function (resolve, reject) {
    var protocol = window.location.protocol; //浏览器协议

    var host = window.location.host; //主机名

    var url = protocol + "//" + host + filePath;
    htmlRequest.get(url, {}).then(function (res) {
      resolve(res.data);
    }).catch(function (res) {
      reject(res.data);
    });
  });
  return promise;
}
/**
 * 静态文件中的 key，代换成model中对应的值
 * 静态文件中的 key 格式必须 ${key}
 * @param  model 
 */

export function replacePublicFileKeyFromObject(htmlString, model) {
  for (var key in model) {
    var value = model[key] == null ? "" : model[key];
    var reg = new RegExp("\\$\\{" + key + "\\}");
    htmlString = htmlString.replace(reg, value);
  }

  return htmlString;
}