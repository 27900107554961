//审批节点流程
export default {
  PREPARE: {
    value: '0',
    text: '数据准备中'
  },
  NEW: {
    value: '1',
    text: '机构发起'
  },
  SERVICE: {
    value: '2',
    text: '客服审核'
  },
  COUNT: {
    value: '3',
    text: '结算审核'
  },
  FINANCE: {
    value: '4',
    text: '财务审核'
  },
  FINANCE_MANAGER: {
    value: '5',
    text: '财务总监审核'
  },
  MONEY: {
    value: '6',
    text: '资金审核'
  },
  SEARCH: {
    value: '7',
    text: '查询'
  },
  PRINT: {
    value: '8',
    text: '打印'
  },
  DELIVE: {
    value: '9',
    text: '总部发货'
  },
  ORG: {
    value: '10',
    text: '分公司'
  }
};