import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
// 查询条件
var PUBLIC_CONFIG = {
  labelWidth: 80
};
var PUBLIC_CONFIGS = {
  labelWidth: 100
}; // 表格列宽

var PUBLIC_TABLE_LABEL_CONFIG = {
  width: 140
}; // 查询条件

export var TOP_FORM = {
  orderNumber: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '订单号',
    props: {
      placeholder: '请输入订单号'
    }
  }),
  transactionNumber: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '支付单号',
    props: {
      placeholder: '请输入支付单号'
    }
  }),
  channel: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '支付渠道',
    col: 6,
    props: {
      placeholder: '请选择支付渠道'
    },
    options: []
  }),
  channelNumber: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '渠道单号',
    props: {
      placeholder: '请输入渠道单号'
    }
  }),
  paymentType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '支付类型',
    col: 6,
    props: {
      placeholder: '请选择支付类型'
    },
    options: [{
      label: '收款',
      value: 1
    }, {
      label: '付款',
      value: 2
    }]
  }),
  payer: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '付款方',
    props: {
      placeholder: '请输入付款方'
    }
  }),
  payee: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '收款方',
    props: {
      placeholder: '请输入收款方'
    }
  }),
  gatewayTransactionNumber: {
    labelWidth: 105,
    type: 'input',
    label: '网关支付单号',
    props: {
      placeholder: '请输入网关支付单号'
    }
  },
  middlewareTransactionNumber: {
    labelWidth: 105,
    type: 'input',
    label: '支付中台单号',
    props: {
      placeholder: '请输入支付中台单号'
    }
  },
  operator: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '操作人',
    props: {
      placeholder: '请输入操作人'
    }
  }),
  createTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '创建时间',
    col: 6,
    propGroup: ['beginCreateTime', 'endCreateTime'],
    props: {
      type: 'daterange'
    }
  })
}; // 列表

export var TABLE_LABEL = [_objectSpread({
  label: '订单号',
  prop: 'orderNumber'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '渠道',
  prop: 'channelValue'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '支付类型值',
  prop: 'paymentTypeValue'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '付款方',
  prop: 'payer'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '收款方',
  prop: 'payee'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '交易金额',
  prop: 'transactionAmount'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '备注',
  prop: 'remark'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '操作人',
  prop: 'operator'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  type: 'slot',
  label: '状态',
  prop: 'status'
}, PUBLIC_TABLE_LABEL_CONFIG), {
  label: '创建时间',
  prop: 'createTime',
  sortable: 'endCreateTime',
  sortProp: 'createTime'
}, _objectSpread(_objectSpread({
  type: 'operation',
  label: '操作'
}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  formatter: function formatter(row) {
    var arr = [];
    arr.push({
      type: 'paymentRecord',
      label: '付款记录',
      permission: 'paymentInformation-getPaymentRecord'
    });
    return arr;
  }
})];
export var TABLE_CONFIG = {
  isSelect: true,
  rowKey: 'id',
  actions: [{
    label: '新增付款',
    type: 'addPayment',
    props: {
      size: 'small',
      type: 'danger'
    },
    permission: 'paymentInformation-addPayment'
  }, {
    label: '新增收款',
    type: 'addGathering',
    props: {
      size: 'small',
      type: 'success'
    },
    permission: 'paymentInformation-addReceipt'
  }]
}; // 新增 付款记录

export var FORM_CONFIG = [_objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '订单号',
  prop: 'orderNumber',
  props: {
    disabled: true
  }
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'select',
  label: '付款方',
  prop: 'payerId',
  options: [],
  props: {
    filterable: true
  },
  rules: [{
    required: true,
    message: '请选择',
    trigger: 'change'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'select',
  label: '收款方',
  prop: 'payeeId',
  value: 2,
  options: [],
  props: {
    filterable: true
  },
  rules: [{
    required: true,
    message: '请选择',
    trigger: 'change'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'select',
  label: '收款钱包',
  prop: 'walletId',
  options: [],
  rules: [{
    required: true,
    message: '请选择',
    trigger: 'change'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '交易金额',
  prop: 'transactionAmount',
  hidden: false,
  rules: [{
    required: true,
    message: '请输入交易金额',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '合同名称',
  prop: 'contractName',
  hidden: false,
  desc: '此名称用户在小程序中付款时可见',
  props: {
    placeholder: '请输入合同名称'
  },
  rules: [{
    required: true,
    message: '请输入合同名称',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '备注',
  prop: 'remark',
  desc: '此名称用户在小程序中付款时可见',
  props: {
    type: 'textarea',
    autosize: {
      minRows: 4,
      maxRows: 4
    },
    showWordLimit: true,
    placeholder: '请输入备注'
  }
})];