// 表头
export default [{
  label: '产品名称',
  prop: 'productName',
  width: '250'
}, {
  label: '项目类型1',
  prop: 'category1Text',
  width: '150'
}, {
  label: '项目类型2',
  prop: 'category2Text',
  width: '150'
}, {
  label: '开售日期',
  prop: 'sellStartDate',
  width: '150'
}, {
  label: '停售日期',
  prop: 'sellEndDate',
  width: '150'
}, {
  label: '实际销售天数',
  prop: 'actualDay',
  width: '150'
}, {
  label: '累计销售(万元）',
  prop: 'actualAmount',
  width: '150'
}, {
  label: '募集目标金额(万元)',
  prop: 'targetAmount',
  width: '150'
}, {
  label: '募集完成率(%)',
  prop: 'percent'
}];