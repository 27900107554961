var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        top: "5vh",
        width: "1300px",
        title: _vm.title,
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品Id", prop: "productId" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.formModel.productId,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "productId", $$v)
                          },
                          expression: "formModel.productId"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称", prop: "productName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.productName,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "productName", $$v)
                          },
                          expression: "formModel.productName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "前缀", prop: "prefix" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.prefix,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "prefix", $$v)
                          },
                          expression: "formModel.prefix"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "购销前缀", prop: "prefixBuy" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.prefixBuy,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "prefixBuy", $$v)
                          },
                          expression: "formModel.prefixBuy"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "业绩系数", prop: "businessRatio" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.businessRatio,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "businessRatio", $$v)
                          },
                          expression: "formModel.businessRatio"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品类型", prop: "productType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.productType,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "productType", $$v)
                            },
                            expression: "formModel.productType"
                          }
                        },
                        _vm._l(_vm.selectData.productType, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "目录1", prop: "category1" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.category1,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "category1", $$v)
                            },
                            expression: "formModel.category1"
                          }
                        },
                        _vm._l(_vm.selectData.category1, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "目录2", prop: "category2" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.category2,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "category2", $$v)
                            },
                            expression: "formModel.category2"
                          }
                        },
                        _vm._l(_vm.selectData.category2, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "目录3", prop: "category3" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.category3,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "category3", $$v)
                            },
                            expression: "formModel.category3"
                          }
                        },
                        _vm._l(_vm.selectData.category3, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "资金限额", prop: "moneyLimit" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "0~0" },
                        model: {
                          value: _vm.formModel.moneyLimit,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "moneyLimit", $$v)
                          },
                          expression: "formModel.moneyLimit"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "合同期限(月)", prop: "selectTimeLimit" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            clearable: "",
                            placeholder: "请选择"
                          },
                          model: {
                            value: _vm.selectTimeLimit,
                            callback: function($$v) {
                              _vm.selectTimeLimit = $$v
                            },
                            expression: "selectTimeLimit"
                          }
                        },
                        _vm._l(_vm.selectData.timeLimit, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "返还周期(月)",
                        prop: "selectReturnPeriod"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            clearable: "",
                            placeholder: "请选择"
                          },
                          model: {
                            value: _vm.selectReturnPeriod,
                            callback: function($$v) {
                              _vm.selectReturnPeriod = $$v
                            },
                            expression: "selectReturnPeriod"
                          }
                        },
                        _vm._l(_vm.selectData.returnPeriod, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "结算属性", prop: "countType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.countType,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "countType", $$v)
                            },
                            expression: "formModel.countType"
                          }
                        },
                        _vm._l(_vm.selectData.countType, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "必须附件（json格式）",
                        prop: "attachment"
                      }
                    },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.attachment,
                            expression: "formModel.attachment"
                          }
                        ],
                        staticClass: "el-textarea__inner",
                        attrs: {
                          placeholder:
                            "{'com':'共有的','person':'个人客户','org':'机构客户'}"
                        },
                        domProps: { value: _vm.formModel.attachment },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formModel,
                              "attachment",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品描述", prop: "description" } },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.description,
                            expression: "formModel.description"
                          }
                        ],
                        staticClass: "el-textarea__inner",
                        attrs: { placeholder: "请填写产品描述" },
                        domProps: { value: _vm.formModel.description },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formModel,
                              "description",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "合同号", prop: "isOfficialCd" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.formModel.isOfficialCd,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "isOfficialCd", $$v)
                            },
                            expression: "formModel.isOfficialCd"
                          }
                        },
                        [_vm._v("官方合同号")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          model: {
                            value: _vm.formModel.isOfficialCd,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "isOfficialCd", $$v)
                            },
                            expression: "formModel.isOfficialCd"
                          }
                        },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "按日计息", prop: "isByDay" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.formModel.isByDay,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "isByDay", $$v)
                            },
                            expression: "formModel.isByDay"
                          }
                        },
                        [_vm._v("是")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          model: {
                            value: _vm.formModel.isByDay,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "isByDay", $$v)
                            },
                            expression: "formModel.isByDay"
                          }
                        },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "启用", prop: "enabled" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.formModel.enabled,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "enabled", $$v)
                            },
                            expression: "formModel.enabled"
                          }
                        },
                        [_vm._v("启用")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          model: {
                            value: _vm.formModel.enabled,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "enabled", $$v)
                            },
                            expression: "formModel.enabled"
                          }
                        },
                        [_vm._v("禁用")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-tag", { attrs: { type: "warning" } }, [
                _vm._v(
                  '说明：以下日期区间指合同开始日期的范围，产品资料中的日期区间是"到帐日",T+1, 即合同开始日=到帐日+1。因此下面的日期区间，比产品资料的日期区间要+1'
                )
              ]),
              _c("el-tag", { attrs: { type: "warning" } }, [
                _vm._v(
                  "例如：产品资料合同录入日期 2022-10-01 ~ 2022-10-15期间的规则，下面要设置成 2022-10-02 ~ 2022-10-16"
                )
              ]),
              _c(
                "el-col",
                { attrs: { span: 22 } },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.tableDataExt,
                        "row-style": { height: "20px" },
                        "cell-style": { padding: "6px 0" },
                        height: "350",
                        size: "mini"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "dateBegin", label: "合同开始日期始" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择日期",
                                    "value-format": "yyyy-MM-dd"
                                  },
                                  model: {
                                    value: scope.row.dateBegin,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "dateBegin", $$v)
                                    },
                                    expression: "scope.row.dateBegin"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "dateEnd", label: "合同开始日期止" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择日期",
                                    "value-format": "yyyy-MM-dd"
                                  },
                                  model: {
                                    value: scope.row.dateEnd,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "dateEnd", $$v)
                                    },
                                    expression: "scope.row.dateEnd"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "contractEndDate",
                          label: "合同到期日期"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: scope.row.contractEndDate,
                                      expression: "scope.row.contractEndDate"
                                    }
                                  ],
                                  staticClass: "el-textarea__inner",
                                  attrs: {
                                    placeholder: "请填写合同到期日.json"
                                  },
                                  domProps: {
                                    value: scope.row.contractEndDate
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        scope.row,
                                        "contractEndDate",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "firstPayDate", label: "首次付息日" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: scope.row.firstPayDate,
                                      expression: "scope.row.firstPayDate"
                                    }
                                  ],
                                  staticClass: "el-textarea__inner",
                                  attrs: {
                                    placeholder: "请填写首次付息日.json"
                                  },
                                  domProps: { value: scope.row.firstPayDate },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        scope.row,
                                        "firstPayDate",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: { click: _vm.confirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }