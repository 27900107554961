var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "j-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
              modifiers: { fullscreen: true, lock: true }
            }
          ],
          attrs: {
            visible: _vm.addVisible,
            title: "新增礼品兑换",
            width: "35%",
            height: "700px"
          },
          on: {
            "update:visible": function($event) {
              _vm.addVisible = $event
            },
            confirm: _vm.addDialogConfirm,
            close: _vm.close
          }
        },
        [
          _c("j-form", {
            ref: "form",
            attrs: {
              "form-config": _vm.addFormConfig,
              "form-data": _vm.addFormData,
              "foot-active": false
            },
            on: {
              "update:formData": function($event) {
                _vm.addFormData = $event
              },
              "update:form-data": function($event) {
                _vm.addFormData = $event
              },
              change: _vm.formChange,
              submit: function($event) {
                return _vm.FormSubmit($event)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "customerId",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "customer",
                        on: { click: _vm.addCustomer }
                      },
                      [
                        _vm.customer.customerName
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.customer.customerName))
                            ])
                          : _c("span", { staticClass: "text" }, [
                              _vm._v("请选择客户")
                            ]),
                        _c("i", { staticClass: "el-icon-circle-plus-outline" })
                      ]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "giftId",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "customer", on: { click: _vm.addgiftId } },
                      [
                        _vm.gift.id
                          ? _c("span", [_vm._v(_vm._s(_vm.gift.giftName))])
                          : _c("span", { staticClass: "text" }, [
                              _vm._v("请选择礼品")
                            ]),
                        _c("i", { staticClass: "el-icon-circle-plus-outline" })
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "j-dialog",
        {
          attrs: { visible: _vm.openAllot, width: "35%", title: _vm.titles },
          on: {
            "update:visible": function($event) {
              _vm.openAllot = $event
            },
            confirm: _vm.allotConfirm,
            close: _vm.AddUserIdHide
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: { inline: true, model: _vm.userSelectData, size: "small" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _vm.typeGift === false
                    ? _c("el-input", {
                        attrs: { placeholder: "请输入客户名称/编码" },
                        model: {
                          value: _vm.userSelectData.customerValue,
                          callback: function($$v) {
                            _vm.$set(_vm.userSelectData, "customerValue", $$v)
                          },
                          expression: "userSelectData.customerValue"
                        }
                      })
                    : _vm._e(),
                  _vm.typeGift === true
                    ? _c("el-input", {
                        attrs: { placeholder: "请输入产品名称/编码" },
                        model: {
                          value: _vm.userSelectData.giftValue,
                          callback: function($$v) {
                            _vm.$set(_vm.userSelectData, "giftValue", $$v)
                          },
                          expression: "userSelectData.giftValue"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.userSearch }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.userTableData, "tooltip-effect": "dark" },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _vm.typeGift === false
                ? _c("el-table-column", {
                    attrs: { prop: "customerName", label: "客户姓名" }
                  })
                : _vm._e(),
              _vm.typeGift === false
                ? _c("el-table-column", {
                    attrs: { prop: "customerCode", label: "客户编码" }
                  })
                : _vm._e(),
              _vm.typeGift === false
                ? _c("el-table-column", {
                    attrs: { prop: "orgName", label: "机构" }
                  })
                : _vm._e(),
              _vm.typeGift === false
                ? _c("el-table-column", {
                    attrs: { prop: "agentUserName", label: "理顾" }
                  })
                : _vm._e(),
              _vm.typeGift === true
                ? _c("el-table-column", {
                    attrs: { prop: "giftCode", label: "礼品编码" }
                  })
                : _vm._e(),
              _vm.typeGift === true
                ? _c("el-table-column", {
                    attrs: { prop: "giftName", label: "礼品名称" }
                  })
                : _vm._e(),
              _vm.typeGift === true
                ? _c("el-table-column", {
                    attrs: { prop: "score", label: "分值" }
                  })
                : _vm._e(),
              _vm.typeGift === true
                ? _c("el-table-column", {
                    attrs: { prop: "stockAmount", label: "剩余库存" }
                  })
                : _vm._e(),
              _vm.typeGift === true
                ? _c("el-table-column", {
                    attrs: { prop: "orgName", label: "适用范围" }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }