// 表头
export default [{
  label: '名称',
  prop: 'name',
  width: '250px'
}, {
  label: '模块',
  prop: 'menuIdText',
  width: '150px'
}, {
  label: 'url',
  prop: 'url',
  width: '500px'
}, {
  label: '启用',
  prop: 'enabledText',
  width: '100px'
}, {
  label: '排序',
  prop: 'sort',
  width: '100px'
}];