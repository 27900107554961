// 银行流水
export default {
  importExcelBankBill: '/import/bankBill/importExcelBankBill',
  // 导入Excel流水
  list: '/import/bankBill/list',
  // 导入Excel数据列表
  importList: '/bank/bill/importList',
  //  外部导入列表
  exportExcelImportData: '/bank/bill/exportExcelImportData',
  // 导出Excel外部导入列表
  listNoBuy: '/bank/bill/listNoBuy',
  // 查询未认购列表
  listNoBuyMatch: '/bank/bill/listNoBuyMatch' //  查询未认购合同匹配列表

};