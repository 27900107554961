import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
// 权限
import request from '@/plugin/axios';
import STORE from "@/maps/enum/store";
import store from '@/store';
import func from '@/maps/url/rm/menu'; // TODO 获取侧边栏

export function getMenu() {
  var promise = new Promise(function (resolve, reject) {
    var str = sessionStorage.getItem(STORE.RM_MENU); //检查缓存是否有菜单

    if (str != null && str != "") {
      var menu = JSON.parse(str);
      store.commit('d2admin/menu/asideSet', menu);
      resolve(menu);
    } else {
      request.post(func.listPerson).then(function (res) {
        var _res$data = res.data,
            data = _res$data === void 0 ? [] : _res$data;

        var assembleMenu = function assembleMenu(arr) {
          return arr.map(function (item) {
            var _item$menuName = item.menuName,
                menuName = _item$menuName === void 0 ? '' : _item$menuName,
                _item$url = item.url,
                url = _item$url === void 0 ? '' : _item$url,
                _item$children = item.children,
                children = _item$children === void 0 ? [] : _item$children;
            var menuItem = {
              title: menuName
            }; // 如果有子菜单，则遍历子菜单

            if (children.length) {
              menuItem.children = assembleMenu(children);
            } else {
              menuItem.path = url || '';
            }

            return menuItem;
          });
        };

        var menu = assembleMenu(data || []);
        sessionStorage.setItem(STORE.RM_MENU, JSON.stringify(menu));
        store.commit('d2admin/menu/asideSet', menu);
        resolve(menu); // store.commit('d2admin/menu/headerSet', menu)
      });
    }
  });
  return promise;
} // TODO 用户拥有的菜单列表

export function listByUserId(params) {
  return request.post(func.listByUserId, {
    body: params
  });
} // TODO 用户目录菜单

export function listPerson(params) {
  return request.post(func.listPerson, {
    body: params
  });
} // TODO 一级菜单

export function listTop(params) {
  return request.post(func.listTop, {
    body: params
  });
} // TODO 列表

export function list(params) {
  return request.post(func.list, {
    body: params
  });
} // TODO 保存

export function save(params) {
  return request.post(func.save, {
    body: params
  });
} // TODO 删除

export function remove(params) {
  return request.post(func.remove, {
    body: params
  });
} // TODO 菜单树

export function listTree(params) {
  return request.post(func.listTree, {
    body: params
  });
}