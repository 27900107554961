//查询条件
var VAL_WIDTH = '150px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '创建日期',
  valueKey: 'createDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  valueKey: 'createDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}];