var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-person" },
    [
      _c(
        "el-row",
        { staticClass: "page-person-container", attrs: { type: "flex" } },
        [
          _c(
            "el-col",
            { staticClass: "tree-container", attrs: { span: 5 } },
            [
              _c("tree-organization", {
                attrs: { config: _vm.treeConfig },
                on: { "node-click": _vm.treeNodeClick }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "tree-container", attrs: { span: 9 } },
            [
              _c("dynamic-form", {
                ref: "form",
                attrs: { props: _vm.filterProps, forms: _vm.filterForms }
              }),
              _c(
                "el-row",
                {
                  staticClass: "actions",
                  attrs: { type: "flex", justify: "space-between" }
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            plain: "",
                            size: "mini",
                            icon: "el-icon-bottom-left"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickReset()
                            }
                          }
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            plain: "",
                            size: "mini",
                            icon: "el-icon-search"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickSearch()
                            }
                          }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.personTableData,
                    "highlight-current-row": "",
                    "row-style": { height: "20px" },
                    "cell-style": { padding: "6px 0" }
                  },
                  on: { "row-click": _vm.clickPersonRow }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "orgName", label: "机构", width: "200" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "titleText", label: "职务", width: "80" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "姓名", width: "150" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "code", label: "账号", width: "150" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "list-container", attrs: { span: 10 } },
            [
              _c(
                "el-row",
                {
                  staticClass: "actions",
                  attrs: { type: "flex", justify: "space-between" }
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "mini" },
                          on: { click: _vm.clickSave }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("el-tree", {
                ref: "rightTree",
                attrs: {
                  data: _vm.treeData,
                  "show-checkbox": "",
                  "node-key": "id",
                  props: _vm.defaultProps,
                  "default-checked-keys": _vm.rightTableData
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }