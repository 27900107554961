export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    //  团队

    /** 查询团队业绩目标 */
    ORG_GETGOALLIST: function ORG_GETGOALLIST(data) {
      return request({
        url: url.operationAnalysisH5.managementByObjectives.getGoalList,
        data: data
      });
    },

    /** 编辑团队月度业绩目标 */
    ORG_UPDATEGOAL: function ORG_UPDATEGOAL(data) {
      return request({
        url: url.operationAnalysisH5.managementByObjectives.updateGoal,
        data: data
      });
    },

    /** 导入团队月度业绩目标 */
    ORG_IMPORTGOAL: function ORG_IMPORTGOAL(data) {
      return request({
        url: url.operationAnalysisH5.managementByObjectives.importGoal,
        data: data
      });
    },

    /** 导出团队月度业绩目标 */
    ORG_EXPORTGOAL: function ORG_EXPORTGOAL(data) {
      return request({
        url: url.operationAnalysisH5.managementByObjectives.exportGoal,
        data: data,
        responseType: 'arraybuffer'
      });
    },

    /** 下载团队业绩目标导出模板 */
    ORG_GETGOALIMPORTTEMPLATE: function ORG_GETGOALIMPORTTEMPLATE() {
      return request({
        url: url.operationAnalysisH5.managementByObjectives.getGoalImportTemplate,
        responseType: 'arraybuffer'
      });
    },
    //  个人

    /** 分页查询个人业绩目标列 */
    PERSONAL_GETGOALPAGE: function PERSONAL_GETGOALPAGE(data) {
      return request({
        url: url.operationAnalysisH5.managementByObjectives.getGoalPage,
        data: data
      });
    },

    /** 编辑个人月度业绩目标 */
    PERSONAL_PERSONALUPDATEGOAL: function PERSONAL_PERSONALUPDATEGOAL(data) {
      return request({
        url: url.operationAnalysisH5.managementByObjectives.personalUpdateGoal,
        data: data
      });
    },

    /** 下载个人业绩目标导出模板 */
    PERSONAL_PERSONALGOALIMPOALIMPORTTEMPLATE: function PERSONAL_PERSONALGOALIMPOALIMPORTTEMPLATE() {
      return request({
        url: url.operationAnalysisH5.managementByObjectives.personalGetGoalImportTemplate,
        responseType: 'arraybuffer'
      });
    },

    /** 导入个人月度业绩目标 */
    PERSONAL_PERSONALIMPORTGOAL: function PERSONAL_PERSONALIMPORTGOAL(data) {
      return request({
        url: url.operationAnalysisH5.managementByObjectives.personalImportGoal,
        data: data
      });
    },

    /** 导出个人月度业绩目标 */
    PERSONAL_PERSONALEXPORTGOAL: function PERSONAL_PERSONALEXPORTGOAL(data) {
      return request({
        url: url.operationAnalysisH5.managementByObjectives.personalExportGoal,
        data: data,
        responseType: 'arraybuffer'
      });
    }
  };
});