export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    /** 获取证件编码的最新合同理顾id */
    GET_CONTRAC_AGENTUSERID: function GET_CONTRAC_AGENTUSERID(data) {
      return request({
        url: url.contrac.getAgentUserIdByIdentification,
        data: data
      });
    }
  };
});