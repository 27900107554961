import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import api from '@/api-new';
import dialogMixin from '@/mixins/dialog';
export default {
  name: 'dialog-person-dimission',
  props: {
    // 当前
    person: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      rules: {
        name: [{
          required: true,
          message: '请填写客户姓名',
          trigger: 'input'
        }],
        entryDate: [{
          required: true,
          message: '请填写入职日期',
          trigger: 'input'
        }]
      }
    };
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          var name = this.person.name;
          this.formModel = {
            name: name
          };
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 确定
    clickSave: function clickSave() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          // this.loading = true;
          var param = {
            userId: _this.person.personuuid,
            reason: _this.formModel.entryReason,
            entryDate: _this.formModel.entryDate
          }; // console.log('param', param);

          api.RE_ENTRY_RY(param).then(function () {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit('refresh');
          }).catch(function () {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};