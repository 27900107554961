//附件
export default {
  open: '/attachment/open',
  // 打开
  download: '/attachment/download',
  // 下载   
  upload: '/attachment/upload',
  // 上传
  uploadMultiple: '/attachment/uploadMultiple',
  // 上传多文件
  remove: '/attachment/remove',
  // 删除   
  list: '/attachment/list',
  // 列表    
  bale: '/attachment/bale',
  // 打包附件
  downloadPack: '/attachment/downloadPack',
  // 下载附件包
  imageThumbnailStart: '/attachment/imageThumbnailStart',
  // 合同附件压缩任务启动
  rename: '/attachment/rename',
  // 重命名
  save: '/attachment/save' // 保存视频

};