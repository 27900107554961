


























































































































































































export default function (Component) {
  Component.options.__source = "src/views/user/info/index.vue"
}
