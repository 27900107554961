//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '合同编号',
  prop: 'contractCd',
  width: '150px'
}, {
  label: '客户',
  prop: 'customerName',
  width: '80px'
}, {
  label: '机构',
  prop: 'orgName',
  width: '210px'
}, {
  label: '礼品',
  prop: 'giftName',
  width: '190px'
}, {
  label: '方案成本',
  prop: 'giftPrice',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '礼品状态',
  prop: 'status',
  width: '120px',
  type: 'slot'
}, {
  label: '申请日期',
  prop: 'submitDateFirst',
  width: '160px'
}, {
  label: '产品',
  prop: 'productName',
  width: '150px'
}, {
  label: '合同状态',
  prop: 'contractStateText',
  width: '120px'
}, {
  label: '合同金额',
  prop: 'amount',
  width: '120px',
  align: 'right'
}, {
  label: '年化入金',
  prop: 'yearAmount',
  width: '120px',
  align: 'right'
}, {
  label: '开始日期',
  prop: 'startDate',
  width: VAL_WIDTH
}, {
  label: '结束日期',
  prop: 'endDate',
  width: VAL_WIDTH
}, {
  label: '期限(月)',
  prop: 'timeLimit',
  width: '80px'
}, {
  label: '机构备注',
  prop: 'filialeRemark',
  width: '120px'
}, {
  label: '特殊申请',
  prop: 'applyText',
  width: '60px'
}];