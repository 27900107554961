




































































































































export default function (Component) {
  Component.options.__source = "src/components/business/dialog-import/index.vue"
}
