


































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/regionalOperation/messageSearch/index.vue"
}
