import "core-js/modules/es.object.to-string.js";
// import ENUM from '@/maps/enum/enum'
import ENABLE from "@/maps/enum/status/enable";
import { listTop } from "@api/rm/menu";
var VAL_WIDTH = '120px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '接口名称',
  valueKey: 'name',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '菜单模块',
  valueKey: 'arrayParentId',
  value: [],
  type: 'select',
  method: filterMenu,
  params: {},
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '启用',
  valueKey: 'enabled',
  value: '1',
  type: 'select',
  options: filterEnable(),
  props: {
    type: 'text',
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}]; //模块

function filterMenu() {
  var promise = new Promise(function (resolve, reject) {
    var param = {};
    listTop(param).then(function (res) {
      var array = [];

      if (res.data != null) {
        for (var i = 0; i < res.data.length; i++) {
          var n = res.data[i];
          var e = {
            value: n.id,
            text: n.menuName
          };
          array.push(e);
        }
      }

      resolve(array);
    }).catch(function (res) {
      reject(res);
    });
  });
  return promise;
} //启用


function filterEnable() {
  var array = [ENABLE.YES, ENABLE.NO];
  return array;
}