import _slicedToArray from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import useCloud from './use-cloud';
import useJSON from './use-json';
import util from '@/libs/util';
export default function useImageParse() {
  var _useJSON = useJSON(),
      parse = _useJSON.parse;

  var _useCloud = useCloud(),
      cloudUrl = _useCloud.cloudUrl;
  /** 获取文件对象中的 url ，拼接成完整的地址 */


  var changeToPath = function changeToPath(file) {
    var url = file.url,
        path = file.path;

    if (path) {
      return path;
    } else {
      return url ? "".concat(cloudUrl.value).concat(url) : '';
    }
  };
  /** 将数据解析为链接 */


  var parseImageToPath = function parseImageToPath(json) {
    var jsonType = util.dataType.getDataType(json);

    switch (jsonType) {
      case 'string':
        return parseImageToPath(parse < JYFAPI.FileDTO > json);

      case 'array':
        return json.map(changeToPath);

      case 'object':
        return changeToPath(json);
    }

    return '';
  };
  /** 解析 json 数据，并获取第一个值 */


  var parseImageAndGetFirst = function parseImageAndGetFirst(json) {
    var data = parseImageToPath(json);
    var jsonType = util.dataType.getDataType(data);
    /** 第一项值 */

    var first = data; // 如果是数组，则取第一项

    if (jsonType === 'array') {
      var _data = _slicedToArray(data, 1);

      first = _data[0];
    }

    return first;
  };

  return {
    /** 将数据解析为链接 */
    parseImageToPath: parseImageToPath,

    /** 解析 json 数据，并获取第一个值 */
    parseImageAndGetFirst: parseImageAndGetFirst
  };
}