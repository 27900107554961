import _defineProperty from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find-index.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import attachmentMap from "@/maps/url/crm/attachment/attachment";
import AttachmentUploadManage from "@/components/business/attachment/upload-manage";
import AttachmentPreview from "@/components/business/attachment/preview";
import util from "@/libs/util.js";
import { list, remove, rename } from "@api/crm/attachment/attachment"; // import {} from "@api/rm/tool";

export default {
  name: "tool-attachment",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    AttachmentUploadManage: AttachmentUploadManage,
    AttachmentPreview: AttachmentPreview,
    ElImageViewer: ElImageViewer
  },
  mixins: [dialogMixin],
  data: function data() {
    var _ref;

    return _ref = {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      tableData: [],
      previewList: [],
      //预览图列表
      bigList: [],
      //大图列表
      showUploadDialog: false,
      // 展示上传对话框
      showPreviewDialog: false
    }, _defineProperty(_ref, "showUploadDialog", false), _defineProperty(_ref, "showBigViewer", false), _defineProperty(_ref, "contractId", null), _defineProperty(_ref, "rules", {}), _ref;
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false; // this.formModel.contractCd = null;

      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    //查询列表
    loadData: function loadData() {
      var _this = this;

      var param = {
        contractCd: this.formModel.contractCd
      };
      this.loading = true;
      list({
        query: param
      }).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = res.data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var item = _step.value;
              var attachTypeName = ""; //附件类型

              switch (item.attachType) {
                case '1':
                  attachTypeName = "认购-合同资料";
                  break;

                case '2':
                  attachTypeName = "认购-回单凭证";
                  break;

                case '3':
                  attachTypeName = "赎回-申请资料";
                  break;

                case '4':
                  attachTypeName = "赎回-付息请求";
                  break;

                case '5':
                  attachTypeName = "赎回-付款凭证";
                  break;

                case '6':
                  attachTypeName = "赎回-回单凭证";
                  break;

                case '7':
                  attachTypeName = "赎回-出库单";
                  break;

                case '8':
                  attachTypeName = "续约-申请资料";
                  break;

                case '9':
                  attachTypeName = "续约-回单凭证";
                  break;

                case '10':
                  attachTypeName = "续约-付款凭证";
                  break;

                case '11':
                  attachTypeName = "变更-变更申请";
                  break;

                case '13':
                  attachTypeName = "付息-申请";
                  break;

                case '14':
                  attachTypeName = "付息-发票";
                  break;

                case '15':
                  attachTypeName = "合同-特殊申请";
                  break;

                case '16':
                  attachTypeName = "礼品-文件审批表";
                  break;

                case '17':
                  attachTypeName = "礼品-礼品签收表";
                  break;

                case '18':
                  attachTypeName = "礼品-申请说明";
                  break;

                case '19':
                  attachTypeName = "礼品-变更申请说明";
                  break;

                case '30':
                  attachTypeName = "合同录音";
                  break;

                case '31':
                  attachTypeName = "回访录音";
                  break;

                case 'document_template':
                  attachTypeName = "文档模板";
                  break;
              }

              ; //列表项

              var paramTableData = {
                attachId: item.attachId,
                attachType: attachTypeName,
                fileName: item.fileName,
                fileType: item.fileType
              };

              _this.tableData.push(paramTableData); //生成大图预览地址


              var paramBig = {
                body: {
                  attachId: item.attachId
                }
              };
              var url = process.env.VUE_APP_API + attachmentMap.open + "?X-Token=" + util.cookies.get("token") + "&data=" + JSON.stringify(paramBig);

              _this.previewList.push(url);

              if (_this.contractId == null) {
                _this.contractId = item.contractId;
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function () {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    //查询合同附件
    clickLoadContractAttachment: function clickLoadContractAttachment() {
      if (this.formModel.contractCd == null || this.formModel.contractCd == '') {
        this.$message({
          message: "请输入合同编号",
          type: "info"
        });
      } else {
        this.loadData();
      }
    },
    //点击查看
    clickPreview: function clickPreview() {
      if (this.tableData.length > 0) {
        this.showPreviewDialog = true;
        this.$emit("onAttachmentOpen"); //恢复到数据标签
      }
    },
    //点击单图预览
    clickBigPreview: function clickBigPreview(row) {
      //打开单个大图预览
      for (var i = 0; i < this.previewList.length; i++) {
        var url = this.previewList[i];

        if (url.indexOf(row.attachId) > 0) {
          this.bigList.push(url);
          break;
        }
      }

      this.showBigViewer = true;
    },
    closeBigViewer: function closeBigViewer() {
      this.bigList = [];
      this.showBigViewer = false;
    },
    clickRemove: function clickRemove(row) {
      var _this2 = this;

      this.loading = true;
      remove({
        attachId: row.attachId
      }).then(function (res) {
        _this2.loading = false;

        _this2.tableData.splice(_this2.tableData.findIndex(function (v) {
          return v.attachId === row.attachId;
        }), 1);
      }).catch(function (e) {
        _this2.loading = false;
      });
    },
    //重命名
    clickRename: function clickRename(row) {
      var _this3 = this;

      this.$prompt("原名：" + row.fileName, '附件重命名', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function (_ref2) {
        var value = _ref2.value;
        var param = {
          attachId: row.attachId,
          newName: value
        };
        rename(param).then(function (res) {
          _this3.loadData();
        });
      }).catch(function () {});
    },
    //点击上传
    clickUpload: function clickUpload() {
      this.showUploadDialog = true;
    }
  }
};