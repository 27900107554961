








































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/commission/plan/index.vue"
}
