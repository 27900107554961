//推介费台帐状态
export default {
  SAVE: {
    value: '0',
    text: '待提交'
  },
  SUBMIT: {
    value: '1',
    text: '待分公司审核'
  },
  ORG_AUDIT: {
    value: '2',
    text: '待财务审核'
  },
  ORG_AUDIT_FAILURE: {
    value: '-1',
    text: '分公司审核不通过'
  },
  FINANCE_AUDIT: {
    value: '3',
    text: '待财务总监审核'
  },
  FINANCE_AUDIT_FAILURE: {
    value: '-2',
    text: '财务审核不通过'
  },
  COUNT_AUDIT: {
    value: '5',
    text: '结算审核通过'
  },
  COUNT_AUDIT_FAILURE: {
    value: '-4',
    text: '结算审核不通过'
  },
  FINANCE_MANAGER_AUDIT: {
    value: '4',
    text: '完成'
  },
  FINANCE_MANAGER_AUDIT_FAILURE: {
    value: '-3',
    text: '结算审核不通过'
  }
};