var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        top: "10vh",
        width: "1250px",
        title: "台帐明细",
        modal: false,
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            "row-style": { height: "20px" },
            "cell-style": { padding: "6px 0" },
            hight: "350px"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _vm.isEdit
            ? _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              prop: "contractCd",
              label: "合同编号",
              width: "160",
              fixed: ""
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "customerName", label: "姓名", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "productName", label: "产品", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "orgName", label: "机构", width: "150" }
          }),
          _c("el-table-column", {
            attrs: { prop: "startDate", label: "开始日期", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "endDate", label: "结束日期", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "bussinessDate", label: "业绩归属日", width: "100" }
          }),
          _c(
            "el-table-column",
            { attrs: { label: "顾问", align: "center" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "listDetail[0].bonusUserName",
                  label: "人员",
                  width: "70"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "listDetail[0].percent",
                  label: "比例%",
                  width: "80",
                  align: "right"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "listDetail[0].bonus",
                  label: "金额",
                  width: "100",
                  align: "right"
                }
              })
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { label: "团队总监", align: "center" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "listDetail[1].bonusUserName",
                  label: "人员",
                  width: "70"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "listDetail[1].percent",
                  label: "比例%",
                  width: "80",
                  align: "right"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "listDetail[1].bonus",
                  label: "金额",
                  width: "100",
                  align: "right"
                }
              })
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { label: "营业部副总经理", align: "center" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "listDetail[4].bonusUserName",
                  label: "人员",
                  width: "70"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "listDetail[4].percent",
                  label: "比例%",
                  width: "80",
                  align: "right"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "listDetail[4].bonus",
                  label: "金额",
                  width: "100",
                  align: "right"
                }
              })
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { label: "营业部总经理", align: "center" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "listDetail[2].bonusUserName",
                  label: "人员",
                  width: "70"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "listDetail[2].percent",
                  label: "比例%",
                  width: "80",
                  align: "right"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "listDetail[2].bonus",
                  label: "金额",
                  width: "100",
                  align: "right"
                }
              })
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { label: "城市总/区域总", align: "center" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "listDetail[3].bonusUserName",
                  label: "人员",
                  width: "70"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "listDetail[3].percent",
                  label: "比例%",
                  width: "80",
                  align: "right"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "listDetail[3].bonus",
                  label: "金额",
                  width: "100",
                  align: "right"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.tableDataSpecial.length > 0
        ? [
            _c("el-divider", { attrs: { "content-position": "left" } }, [
              _vm._v("特殊推介费")
            ]),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableDataSpecial }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "bonusUserName", label: "人员", width: "180" }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "bonus",
                    label: "金额",
                    width: "100",
                    align: "right"
                  }
                }),
                _c("el-table-column", {
                  attrs: { prop: "remark", label: "备注" }
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", width: "80" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm.isEdit
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "primary",
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickRemoveSpecial(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3114704633
                  )
                })
              ],
              1
            )
          ]
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "contract-log-tip",
              attrs: { plain: "", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.showSummary()
                }
              }
            },
            [_vm._v("结算汇总")]
          ),
          _vm.isEdit
            ? _c(
                "el-dropdown",
                {
                  attrs: { "split-button": "", plain: "", size: "mini" },
                  on: { command: _vm.handleCommand }
                },
                [
                  _vm._v(" 更多菜单 "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: "add" } }, [
                        _vm._v("并入申请")
                      ]),
                      _c("el-dropdown-item", { attrs: { command: "remove" } }, [
                        _vm._v("删除申请")
                      ]),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "special" } },
                        [_vm._v("特殊推介费")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _vm.isEdit
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    size: "mini",
                    loading: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      return _vm.clickSubmit()
                    }
                  }
                },
                [_vm._v("提 交")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("dialog-application", {
        attrs: { propsModel: _vm.currentRow, batchId: _vm.batchId },
        on: { refresh: _vm.init },
        model: {
          value: _vm.showApplicationDialog,
          callback: function($$v) {
            _vm.showApplicationDialog = $$v
          },
          expression: "showApplicationDialog"
        }
      }),
      _c("dialog-special", {
        attrs: { propsModel: _vm.batchId },
        on: { refresh: _vm.init },
        model: {
          value: _vm.showSpecialDialog,
          callback: function($$v) {
            _vm.showSpecialDialog = $$v
          },
          expression: "showSpecialDialog"
        }
      }),
      _c("dialog-summary", {
        attrs: { propsModel: _vm.batchId },
        model: {
          value: _vm.showSummaryDialog,
          callback: function($$v) {
            _vm.showSummaryDialog = $$v
          },
          expression: "showSummaryDialog"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }