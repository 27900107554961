var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "dialogDrag",
          rawName: "v-dialogDrag.changeParend",
          modifiers: { changeParend: true }
        },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-preview-wrapper dialog-info",
      attrs: {
        title: "",
        "custom-class": "preview-image",
        modal: false,
        "lock-scroll": false,
        "close-on-click-modal": false,
        visible: _vm.showDialog,
        "append-to-body": true
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose,
        open: _vm.openDialog
      }
    },
    [
      _c(
        "div",
        { staticClass: "demo-image__lazy" },
        _vm._l(_vm.urls, function(url) {
          return _c("el-image", {
            key: url,
            attrs: { src: url, "preview-src-list": _vm.urls, lazy: "" },
            nativeOn: {
              dragstart: function($event) {
                $event.preventDefault()
                return function() {
                  return false
                }.apply(null, arguments)
              }
            }
          })
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }