//礼品申请
export default {
  create: '/gift/apply/create',
  //申请礼品 
  isExist: '/gift/apply/isExist',
  //合同是否已申请礼品
  remove: '/gift/apply/remove',
  //删除
  list: '/gift/apply/list',
  //礼品申请列表
  getInfo: '/gift/apply/getInfo',
  //详情
  submit: '/gift/apply/submit',
  //提交审核
  removeGift: '/gift/apply/removeGift',
  //删除礼品
  auditService: '/gift/apply/auditService',
  //客服审核
  auditFinance: '/gift/apply/auditFinance',
  //财务审核
  auditServiceBatch: '/gift/apply/auditServiceBatch',
  //批量客服审核
  auditFinanceBatch: '/gift/apply/auditFinanceBatch',
  //批量财务审核
  delivery: '/gift/apply/delivery',
  //寄出礼品
  receive: '/gift/apply/receive',
  //收货礼品    
  exportExcel: '/gift/apply/exportExcel' // 导出Excel

};