







































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/budget/budget/index.vue"
}
