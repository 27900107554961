import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import ENUM from '@/maps/enum/enum';
import { getEnumList } from '@api/common/common';
import { editExpress } from '@api/crm/paper/paper';
export default {
  name: 'paper-express-new-send',
  props: {
    propsModel: {
      type: Object,
      default: {}
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      selectData: {
        expressCompany: [] //快递公司

      },
      rules: {
        expressCompany: [{
          required: true,
          message: '请选择快递公司',
          trigger: 'input'
        }],
        expressNo: [{
          required: true,
          message: '请填写快递单号',
          trigger: 'input'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    // 快递公司
    getEnumList({
      enumCd: ENUM.ENUM_CD_0221
    }).then(function (res) {
      _this.selectData.expressCompany = res;
    });
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.formModel = _objectSpread({}, this.propsModel);
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    send: function send() {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = _objectSpread({}, _this2.formModel);

          _this2.loading = true;
          editExpress({
            object: param
          }).then(function (res) {
            _this2.$message.success('编辑成功');

            _this2.loading = false;
            _this2.showDialog = false;

            _this2.$emit('refresh');
          }).catch(function (e) {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};