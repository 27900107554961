var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("div", { staticStyle: { height: "56.23px" } }),
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          filtersFormatter: _vm.filtersFormatter,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        },
        scopedSlots: _vm._u([
          {
            key: "limit",
            fn: function(ref) {
              var row = ref.row
              return [
                _c("div", [
                  _vm._v(
                    "[" +
                      _vm._s(row.beginTimeLimit) +
                      "," +
                      _vm._s(row.endTimeLimit) +
                      ")"
                  )
                ])
              ]
            }
          }
        ])
      }),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "新增规则",
            height: "500px",
            width: "800px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            confirm: _vm.dialogConfirm
          }
        },
        [
          _c(
            "div",
            { staticClass: "alertbox" },
            [
              _c("el-alert", {
                attrs: {
                  title: "请谨慎设置规则，合同期限不能重复",
                  type: "warning",
                  closable: false,
                  "show-icon": ""
                }
              })
            ],
            1
          ),
          _vm.dialogFormData
            ? _c("j-form", {
                ref: "form",
                attrs: {
                  formConfig: _vm.dialogFormConfig,
                  formData: _vm.dialogFormData
                },
                on: {
                  "update:formData": function($event) {
                    _vm.dialogFormData = $event
                  },
                  "update:form-data": function($event) {
                    _vm.dialogFormData = $event
                  },
                  submit: _vm.dialogSubmit
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "endTimeLimit",
                      fn: function() {
                        return [
                          _c(
                            "el-col",
                            { attrs: { span: 11 } },
                            [
                              _c("el-input", {
                                attrs: { type: "number", placeholder: "" },
                                model: {
                                  value: _vm.dialogFormData.beginTimeLimit,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.dialogFormData,
                                      "beginTimeLimit",
                                      $$v
                                    )
                                  },
                                  expression: "dialogFormData.beginTimeLimit"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { staticClass: "line", attrs: { span: 2 } },
                            [_vm._v("-")]
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 11 } },
                            [
                              _c("el-input", {
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.dialogFormData.endTimeLimit,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.dialogFormData,
                                      "endTimeLimit",
                                      $$v
                                    )
                                  },
                                  expression: "dialogFormData.endTimeLimit"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3804324429
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }