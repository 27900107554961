

































































export default function (Component) {
  Component.options.__source = "src/views/user/password/index.vue"
}
