






















































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/memberPoints/examineAndApprove/add/index.vue"
}
