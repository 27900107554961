//短信模板
export default {
  list: '/smsTemplate/list',
  // 列表
  getInfo: '/smsTemplate/getInfo',
  // 详情
  remove: '/smsTemplate/remove',
  // 删除
  save: '/smsTemplate/save' // 保存

};