import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import ENUM from "@/maps/enum/enum";
import { getEnumList } from "@api/common/common";
import { getOrgTree, getInfo as _getInfo, addOrg, editOrg } from "@api/rm/org";
export default {
  name: "dialog-org-add",
  props: {
    org: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 是否为编辑
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      selectData: {
        orgType: [],
        //机构类型
        arrayOrg: [] //机构

      },
      rules: {
        orgName: [{
          required: true,
          message: "请填写机构名称",
          trigger: "input"
        }],
        orgType: [{
          required: true,
          message: "请选择机构类型",
          trigger: "input"
        }],
        parentOrgCd: [{
          required: true,
          message: "请选择机构归属",
          trigger: "input"
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    //机构类型
    getEnumList({
      enumCd: ENUM.ENUM_CD_0113
    }).then(function (res) {
      _this.selectData.orgType = res;
    }); //机构列表

    getOrgTree().then(function (res) {
      var array = [];
      array[0] = res.data;
      _this.selectData.arrayOrg = array;
    });
  },
  computed: {
    // 弹窗展示的标题
    title: function title() {
      return this.isEdit ? "修改" : "新增";
    }
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();

          if (this.isEdit) {
            this.getInfo();
          } else {
            this.formModel = {
              enabled: "1"
            };
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 获取机构详情
    getInfo: function getInfo() {
      var _this2 = this;

      var param = {
        orgCd: this.org.orgCd
      };

      _getInfo(param).then(function (res) {
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this2.formModel = data;
      });
    },
    // TODO 确定
    clickSave: function clickSave(done) {
      var _this3 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var method = _this3.isEdit ? editOrg : addOrg;
          _this3.loading = true;
          var param = {
            object: _objectSpread({}, _this3.formModel)
          };
          method(param).then(function (res) {
            _this3.loading = false;
            _this3.showDialog = false;

            _this3.$set(_this3.formModel, "orgCd", res.data); //将新生成Id赋值
            // this.$emit("refresh");

          }).catch(function (e) {
            _this3.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};