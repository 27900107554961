
























































































































































export default function (Component) {
  Component.options.__source = "src/views/rm/system/watch/log/index.vue"
}
