

































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/bank/bill/gs/components/dialog-import.vue"
}
