//查询条件
import { formatDate } from "@/libs/crm/format";
var VAL_WIDTH = '150px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '推介费月份',
  valueKey: 'planDateBegin',
  value: initPlanDateBegin(),
  type: 'date',
  props: {
    type: 'month',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'planDateEnd',
  value: initPlanDateEnd(),
  type: 'date',
  props: {
    type: 'month',
    style: {
      width: VAL_WIDTH
    }
  }
}];
/**
 * 默认提成月份开始
 * @returns 
 */

function initPlanDateBegin() {
  var date = new Date();
  date.setMonth(date.getMonth() - 3);
  var rs = formatDate(date);
  return rs;
}

;
/**
 * 默认提成月份结束
 * @returns 
 */

function initPlanDateEnd() {
  var date = new Date();
  var rs = formatDate(date);
  return rs;
}

;