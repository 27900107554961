// 表头
var VAL_WIDTH = '120px';
export default [{
  label: '推介费月份',
  prop: 'planDate'
}, {
  label: '合同编号',
  prop: 'contractCd'
}, {
  label: '机构推介费金额',
  prop: 'bonus'
}, {
  label: '支付日期',
  prop: 'paymentDate'
}, {
  label: '导入时间',
  prop: 'createTime'
}, {
  label: '导入状态',
  prop: 'stateText'
}, {
  label: '导入文件名',
  prop: 'fileName'
}, {
  label: '消息',
  prop: 'message'
}];