var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.visible,
            title: _vm.title,
            height: "700px",
            width: "1400px"
          },
          on: { confirm: _vm.confirm, close: _vm.close }
        },
        [
          _c("j-page", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            ref: "j_page",
            staticClass: "j_page",
            attrs: {
              filters: _vm.filters,
              filtersData: _vm.filtersData,
              table: _vm.table,
              tableLabel: _vm.tableLabel,
              tableData: _vm.tableData
            },
            on: {
              loadData: _vm.loadData,
              "selection-change": _vm.selectionChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }