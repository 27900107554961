var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        top: "10vh",
        width: "1050px",
        title: "汇总列表预览",
        modal: false,
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            "row-style": { height: "20px" },
            "cell-style": { padding: "6px 0" },
            height: "500px",
            "span-method": _vm.rowSpanMethod
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "orgName", label: "分公司", width: "130" }
          }),
          _c("el-table-column", {
            attrs: { prop: "bonusUserName", label: "姓名", width: "100" }
          }),
          _c(
            "el-table-column",
            { attrs: { label: "推介费明细", align: "center" } },
            [
              _c("el-table-column", {
                attrs: { prop: "productName", label: "产品", width: "220" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "totalYearAmount",
                  label: "年化入金(万)",
                  width: "120",
                  align: "right"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "commission",
                  label: "推介费",
                  width: "120",
                  align: "right"
                }
              })
            ],
            1
          ),
          _c("el-table-column", {
            attrs: {
              prop: "bonusSalaryAccount",
              label: "银行帐号",
              width: "180"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "commission",
              label: "实发合计",
              width: "120",
              align: "right"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }