//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '姓名',
  prop: 'customerName',
  width: '80px'
}, {
  label: '合同编号',
  prop: 'contractCd',
  width: '150px'
}, {
  label: '产品',
  prop: 'productName',
  width: '150px'
}, {
  label: '合同金额',
  prop: 'amount',
  width: '120px',
  align: 'right'
}, {
  label: '开始日期',
  prop: 'startDate',
  width: VAL_WIDTH
}, {
  label: '结束日期',
  prop: 'endDate',
  width: VAL_WIDTH
}, {
  label: '期限',
  prop: 'timeLimit',
  width: '80px'
}, {
  label: '返还周期',
  prop: 'returnPeriod',
  width: '80px'
}, {
  label: '合同状态',
  prop: 'contractStateText',
  width: '120px'
}, {
  label: '礼品状态',
  prop: 'giftApplyStatusText',
  width: '110px'
}, {
  label: '所属机构',
  prop: 'orgName',
  width: '210px'
}, {
  label: '首次提交',
  prop: 'submitDateFirst',
  width: '160px'
}, {
  label: '最后提交',
  prop: 'submitDate',
  width: '160px'
}, {
  label: '审核',
  prop: 'audit',
  width: VAL_WIDTH,
  type: 'slot'
}, {
  label: '审核人',
  prop: 'auditPersonName',
  width: '70px'
}, {
  label: '审核时间',
  prop: 'auditTime',
  width: '160px'
}, {
  label: '审核备注',
  prop: 'auditRemark',
  width: '120px'
}];