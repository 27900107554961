






















































































export default function (Component) {
  Component.options.__source = "src/views/crm/commission/collect/components/dialog-summary-add.vue"
}
