import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import echarts from "echarts";
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import DynamicForm from "@/components/common/dynamic-form";
import { formatDate, formatAmountToString } from "@/libs/crm/format";
import { sumYearAmountByMonth } from "@api/crm/report/report";
export default {
  name: "sum-yearAmount-month-and-org",
  components: {
    DynamicForm: DynamicForm
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      sum: {
        all: 0,
        org: 0,
        agent: 0,
        other: 0
      }
    };
  },
  computed: {},
  mounted: function mounted() {
    //设置初始搜索日期，过去12个月
    var date = new Date();
    date.setMonth(date.getMonth() - 12);
    date.setDate(1);
    var beginDate = formatDate(date);
    this.$refs.form.$data.model.dateBegin = beginDate;
    this.loadData();
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.loadData();
    },
    // TODO 获取列表
    loadData: function loadData() {
      var _this = this;

      this.initFilter();
      var query = this.query;
      var queryOrg = Object.assign({}, query);
      queryOrg.orgCd = "0150";
      var queryAgent = Object.assign({}, query);
      queryAgent.orgCd = "0102";
      var param = {
        query: query
      };
      var promiseArr = []; //多个请求数组

      promiseArr[0] = sumYearAmountByMonth(param); //查询总入金数据

      param = {
        query: queryOrg
      };
      promiseArr[1] = sumYearAmountByMonth(param); //查询直营分公司入金数据

      param = {
        query: queryAgent
      };
      promiseArr[2] = sumYearAmountByMonth(param); //查询代理机构入金数据
      //图表数据      

      Promise.all(promiseArr).then(function (res) {
        if (res[0].data != null && res[1].data != null) {
          var echartsData = [];
          echartsData[0] = ["年化入金", "总入金", "直营", "机构"];
          var allData = res[0].data;
          var orgData = res[1].data;
          var agentData = res[2].data;

          for (var i = 0; i < allData.length; i++) {
            _this.sum.all += allData[i].yearAmount; //总计       

            var arr = [];
            arr[0] = allData[i].month;
            arr[1] = Math.floor(allData[i].yearAmount / 10000 * 100) / 100; //总年化入金

            for (var j = 0; j < orgData.length; j++) {
              if (arr[0] == orgData[j].month) {
                _this.sum.org += orgData[j].yearAmount; //总计

                arr[2] = Math.floor(orgData[j].yearAmount / 10000 * 100) / 100; //直营年化入金

                break;
              }
            }

            for (var k = 0; k < agentData.length; k++) {
              if (arr[0] == agentData[k].month) {
                _this.sum.agent += agentData[k].yearAmount; //总计

                arr[3] = Math.floor(agentData[k].yearAmount / 10000 * 100) / 100; //直营年化入金

                break;
              }
            }

            echartsData.push(arr);
          }

          _this.sum.other = _this.sum.all - _this.sum.org - _this.sum.agent;
          _this.sum.all = formatAmountToString(Math.floor(_this.sum.all / 10000 * 100) / 100);
          _this.sum.org = formatAmountToString(Math.floor(_this.sum.org / 10000 * 100) / 100);
          _this.sum.agent = formatAmountToString(Math.floor(_this.sum.agent / 10000 * 100) / 100);
          _this.sum.other = formatAmountToString(Math.floor(_this.sum.other / 10000 * 100) / 100);

          _this.echartsDraw(echartsData);
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.$message({
          message: "查询数据出错",
          type: "error"
        });
      });
    },
    echartsDraw: function echartsDraw(data) {
      //绘图
      var echartDom = document.getElementById("chartsYearAmountAllDom");
      var myChart = echarts.init(echartDom);
      var option = {
        title: {
          text: "月份年化入金统计(万元)",
          left: "left",
          subtext: "",
          sublink: ""
        },
        legend: {},
        tooltip: {},
        dataset: {
          source: data
        },
        xAxis: {
          type: "category"
        },
        yAxis: {},
        series: [{
          type: "bar"
        }, {
          type: "bar"
        }, {
          type: "bar"
        }]
      };
      option && myChart.setOption(option);
    }
  },
  created: function created() {}
};