import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { listTop } from "@api/rm/menu";
import { save } from "@api/rm/api";
export default {
  name: "dialog-add-api",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      selectData: {
        menu: []
      },
      rules: {
        name: [{
          required: true,
          message: "请填写接口名",
          trigger: "input"
        }],
        url: [{
          required: true,
          message: "请填写地址",
          trigger: "input"
        }]
      }
    };
  },
  computed: {
    // 弹窗展示的标题
    title: function title() {
      return this.isEdit ? "修改" : "新增";
    },
    // 是否为编辑
    isEdit: function isEdit() {
      var id = this.propsModel.id;
      return !!id;
    }
  },
  created: function created() {
    var _this = this;

    //一级菜单
    listTop().then(function (res) {
      var array = [];

      if (res.data != null) {
        for (var i = 0; i < res.data.length; i++) {
          var n = res.data[i];
          var e = {
            value: n.id,
            text: n.menuName
          };
          array.push(e);
        }
      }

      _this.selectData.menu = array;
    });
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();

          if (this.isEdit) {
            this.formModel = Object.assign({}, this.propsModel); //复制新对象            
          } else {
            this.formModel = {
              enabled: '1'
            };
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.formModel = {};
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 确定
    confirm: function confirm() {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = _objectSpread({}, _this2.formModel);

          _this2.loading = true;
          save({
            object: param
          }).then(function (res) {
            _this2.loading = false;
            _this2.showDialog = false;

            _this2.$emit("refresh");
          }).catch(function (e) {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};