import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import BANK from '@/maps/enum/bank';
import { parse, batchAdd } from '@api/crm/bank/bill';
import BANKTYPE from '@/maps/enum/bank-type';
export default {
  name: 'bank-bill-add-js',
  props: {
    propsModel: {
      type: Array,
      default: []
    },
    kindValue: {
      type: String,
      default: ''
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      formModel: {
        kind: ''
      },
      // 表单值
      importText: '',
      // 复制粘贴区
      tableData: [],
      rules: {},
      selectData: {}
    };
  },
  computed: {},
  created: function created() {
    var array = [];

    for (var key in BANKTYPE) {
      array.push(BANKTYPE[key]);
    }

    this.selectData.kindList = array;
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        this.init();
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.importText = '';
      this.tableData = [];
      this.formModel = {}; // 取值默认银行

      if (this.kindValue) {
        this.$set(this.formModel, 'kind', this.kindValue);
      }
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    // TODO 确定
    confirm: function confirm() {
      var _this = this;

      if (this.tableData.length == 0) {
        this.$message('请解析录入数据');
      } else {
        var param = {
          arrayObject: this.tableData
        }; // 添加流水类型

        param.arrayObject.forEach(function (n) {
          n.type = '1';
        });
        this.loading = true;
        batchAdd(param).then(function (res) {
          _this.loading = false;
          _this.showDialog = false;

          _this.$emit('refresh');
        }).catch(function (e) {
          _this.loading = false;
        });
      }
    },
    //解析数据
    clickParse: function clickParse() {
      var _this2 = this;

      if (this.importText != '') {
        if (!this.formModel.kind) return this.$message({
          message: '请选择银行/商户',
          type: 'info'
        });
        var param = {
          kind: this.formModel.kind,
          data: this.importText
        };
        this.loading = true;
        parse(param).then(function (res) {
          _this2.loading = false;
          res.data.forEach(function (n) {
            // 金额取2位小数
            n.debtor = n.debtor.toFixed(2);
          });
          _this2.tableData = res.data;
        }).catch(function (e) {
          _this2.loading = false;
        });
      }
    }
  }
};