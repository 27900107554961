import "core-js/modules/es.object.to-string.js";
// 推介费
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatDate, formatUserDataPromise } from '@/libs/crm/format';
import func from '@/maps/url/crm/commissionV3/commissionAgencyV3'; // TODO 导入Excel推介费

export function importExcelCommission(params) {
  return request.post(func.importExcelCommission, params);
} // TODO  导入Excel数据列表

export function commissionAgencyV3List(params, formatType) {
  var re = request.post(func.list, {
    body: params
  });
  formatUserDataPromise(re, formatType); // 用户数据格式化

  return re;
} // TODO  推介费外部导入数据列表

export function commissionAgencyV3ListApplication(params, formatType) {
  var re = request.post(func.listApplication, {
    body: params
  });
  formatUserDataPromise(re, formatType); // 用户数据格式化

  return re;
} // TODO  导出Excel外部导入推介费

export function commissionAgencyV3ExportExcelImportData(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelImportData, {
    body: params
  }).then(function (blob) {
    util.download(blob, '导出推介费列表' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}