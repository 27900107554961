//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import { getEnumList } from '@api/common/common';
import { editBatch } from '@api/crm/bank/bill';
import { list } from '@api/crm/bank/bill';
export default {
  name: 'search-contract',
  components: {},
  props: {
    // 批量选择
    isBatch: {
      type: Boolean,
      default: false
    }
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      accountStatementsearch: {},
      // 查询条件
      selectData: {},
      multipleSelection: [],
      // 选择的银行流水
      multipleTableData: [],
      isEditSave: false
    };
  },
  // watch: {
  //   showDialog: {
  //     handler(val) {
  //       if (val) {
  //         this.init();
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
  created: function created() {
    var _this = this;

    // 获取银行/ 公司
    getEnumList({
      enumCd: 'bankBillKind'
    }).then(function (res) {
      _this.selectData.accountStatementKind = res;
    });
    this.selectData.isBuyList = [{
      value: '0',
      text: '否'
    }, {
      value: '1',
      text: '是'
    }];
  },
  methods: {
    // 银行流水选择赋值
    handleSelectionChange: function handleSelectionChange(val) {
      if (val.length > 1 && this.isBatch === false) {
        this.$message('当前项只能选择一项');
        this.$refs.multipleTable.clearSelection();
      } else {
        this.multipleSelection = val;
      }
    },
    // 取消
    accountStatemenCancel: function accountStatemenCancel() {
      this.showDialog = false;
      this.isEditSave = false;
      this.multipleTableData = [];
      this.accountStatementsearch = {};
      this.$emit('close');
    },
    // 编辑保存客户姓名
    clickEditSave: function clickEditSave() {
      var _this2 = this;

      if (this.multipleSelection.length === 0) {
        return this.$message('请选择银行流水');
      }

      if (this.isEditSave) {
        var arrayObject = [];
        arrayObject = this.multipleSelection;
        editBatch({
          arrayObject: arrayObject
        }).then(function (res) {
          _this2.isEditSave = false;
          _this2.loading = false;

          _this2.$message.success('编辑成功');
        }).catch(function (e) {
          _this2.loading = false;
        });
      } else {
        this.isEditSave = true;
      }
    },
    // 查询 银行流水列表
    clickAccountStatementsearch: function clickAccountStatementsearch() {
      var _this3 = this;

      var query = this.accountStatementsearch; // 实收金额不能为空

      if (query.debtor === '') {
        delete query.debtor;
      }

      list({
        query: query
      }).then(function (res) {
        _this3.multipleTableData = res.data;
      });
    },
    // 查询确认
    accountStatemenCancelAudit: function accountStatemenCancelAudit() {
      var isBatch = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (this.multipleSelection.length === 0) return this.$message('请选择银行流水');
      if (this.isEditSave) return this.$message('请保存编辑'); // 选择的项 multipleSelection 是否批量 isBatch

      this.$emit('affirm', this.multipleSelection, isBatch);
    },
    // TODO 多选，multiple时可用 选择禁用选项 SelectableDataName 禁用数据字段名 SelectableData 禁用判断值
    checkSelectable: function checkSelectable(row) {
      var checkSelectable = true;

      if (row.isBuy === '1') {
        checkSelectable = false;
      }

      return checkSelectable;
    }
  }
};