//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '合同编号',
  prop: 'contractCd',
  width: '150px'
}, {
  label: '原合同',
  prop: 'originalContractCd',
  width: '120px'
}, {
  label: '状态',
  prop: 'buyState',
  width: '120px',
  type: 'slot'
}, {
  label: '客户',
  prop: 'customerName',
  width: '80px'
}, {
  label: '顾问',
  prop: 'userName',
  width: '120px'
}, {
  label: '机构',
  prop: 'orgName',
  width: '210px'
}, {
  label: '产品',
  prop: 'productName',
  width: '150px'
}, {
  label: '合同金额',
  prop: 'amount',
  width: '120px',
  align: 'right'
}, {
  label: '开始日期',
  prop: 'startDate',
  width: VAL_WIDTH
}, {
  label: '结束日期',
  prop: 'endDate',
  width: VAL_WIDTH
}, {
  label: '期限(月)',
  prop: 'timeLimit',
  width: '80px'
}, {
  label: '返还周期',
  prop: 'returnPeriod',
  width: '80px'
}, {
  label: '提交时间',
  prop: 'submitDate',
  width: '160px'
}, {
  label: '创建人',
  prop: 'creatorName',
  width: '80px'
}, {
  label: '更新人',
  prop: 'updatorName',
  width: '80px'
}, {
  label: '更新时间',
  prop: 'updateTime',
  width: '160px'
}, {
  label: '付息总次数',
  prop: 'interestTotal',
  width: '100px'
}, {
  label: '原合同已付次数',
  prop: 'interestOriginal',
  width: '120px'
}, {
  label: '转让合同需要付息次数',
  prop: 'interestAssign',
  width: '170px'
}];