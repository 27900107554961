import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";

// TODO 获取路由文件
var getRoutes = function getRoutes(files) {
  var modules = {};
  files.keys().forEach(function (key) {
    modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default;
  });
  return modules;
}; // 导入路由文件


var files = require.context('./validate', false, /\.js$/);

export default getRoutes(files);