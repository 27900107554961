var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    {
      staticClass: "j-select-tree",
      class: [_vm.selectSize ? "j-select-tree--" + _vm.selectSize : ""],
      attrs: { trigger: "click" },
      on: { "visible-change": _vm.visibleChange, command: _vm.dropdownCommand }
    },
    [
      _c("div", { staticClass: "j-select-tree-wrapper" }, [
        _c("div", { staticClass: "j-select-tree-value" }, [
          _vm._v(_vm._s(_vm.currentNode.label || "请选择"))
        ]),
        _c("div", { staticClass: "j-select-tree--icon" }, [
          _c("i", {
            staticClass: "el-icon-arrow-down",
            class: [_vm.isShow ? "is-reverse" : ""]
          })
        ])
      ]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-tree",
            _vm._b(
              {
                ref: "tree",
                staticClass: "j-select-tree-options",
                attrs: {
                  data: _vm.options,
                  "current-node-key": _vm.currentNodeKey
                },
                on: { "current-change": _vm.handleNodeClick }
              },
              "el-tree",
              _vm.props,
              false
            )
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }