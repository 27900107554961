//机构团队
export default {
  listTeam: '/team/team/list',
  // 团队列表
  saveTeam: '/team/team/save',
  // 团队保存
  removeTeam: '/team/team/remove',
  // 团队删除
  listOrgPerson: '/team/org/listOrgPerson',
  // 分公司成员列表    
  listApplyLog: '/team/log/list',
  // 团队申请记录列表
  applyLog: '/team/log/apply',
  // 团队申请
  removeLog: '/team/log/remove',
  // 删除团队申请
  auditApplyLog: '/team/log/audit' // 审核团队申请

};