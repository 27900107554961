var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          filtersFormatter: _vm.filtersFormatter,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        },
        scopedSlots: _vm._u([
          {
            key: "renewalDetails",
            fn: function(ref) {
              var row = ref.row
              return [
                row.type === 1
                  ? _c(
                      "span",
                      {
                        staticClass: "reference",
                        staticStyle: { color: "#02aff4" },
                        on: {
                          click: function($event) {
                            return _vm.renewalDetailsClick(row.id)
                          }
                        }
                      },
                      [_vm._v("续约明细")]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisibleInfo,
            width: "30%",
            modal: false,
            "show-close": false,
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleInfo = $event
            },
            close: _vm.close
          }
        },
        [
          _c(
            "div",
            { staticClass: "right" },
            _vm._l(_vm.renewalDetailsInfo, function(item, index) {
              return _c(
                "el-descriptions",
                {
                  key: index,
                  staticClass: "margin-top",
                  attrs: {
                    title: "续约明细",
                    direction: "vertical",
                    border: ""
                  }
                },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "续约客户名称" } },
                    [_vm._v(_vm._s(item.renewalCustomerName))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "打款金额（元）" } },
                    [_vm._v(_vm._s(item.paymentAmount))]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "打款日期" } }, [
                    _vm._v(_vm._s(item.paymentDate))
                  ])
                ],
                1
              )
            }),
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }