var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig,
          filtersData: _vm.filtersData,
          "filters-formatter": _vm.filtersFormatter
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-row-change": _vm.tableRowChange,
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        }
      }),
      _c(
        "j-dialog",
        {
          attrs: { visible: _vm.dialogVisible, title: _vm.dialogTitle },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            close: function($event) {
              return _vm.dialogClose(_vm.dialogVisible)
            },
            confirm: _vm.dialogConfirm
          }
        },
        [
          _c("j-form", {
            ref: "form",
            attrs: {
              formConfig: _vm.dialogFormConfig,
              formData: _vm.dialogFormData
            },
            on: {
              "update:formData": function($event) {
                _vm.dialogFormData = $event
              },
              "update:form-data": function($event) {
                _vm.dialogFormData = $event
              },
              submit: _vm.dialogSubmit
            },
            scopedSlots: _vm._u([
              {
                key: "release",
                fn: function() {
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.releaseType,
                              callback: function($$v) {
                                _vm.releaseType = $$v
                              },
                              expression: "releaseType"
                            }
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("立即发布")
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v("定时发布")
                            ])
                          ],
                          1
                        ),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "top-start",
                              width: "450",
                              trigger: "hover"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "reference",
                                fn: function() {
                                  return [
                                    _c("i", { staticClass: "el-icon-question" })
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          },
                          [
                            _c("div", [
                              _c("div", [
                                _vm._v(
                                  "1、定时发布时间在当前时间之后，文章在所选时段定时发布；"
                                )
                              ]),
                              _c("div", [
                                _vm._v(
                                  "2、定时发布时间在当前时间之前，文章实时发布，网站展示所选时间；"
                                )
                              ])
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetime",
                            placeholder: "选择日期时间",
                            "value-format": "yyyy-MM-dd HH:mm:ss"
                          },
                          model: {
                            value: _vm.releaseTime,
                            callback: function($$v) {
                              _vm.releaseTime = $$v
                            },
                            expression: "releaseTime"
                          }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "j-dialog",
        {
          attrs: { visible: _vm.showVideoDialog, title: "视频详情" },
          on: {
            "update:visible": function($event) {
              _vm.showVideoDialog = $event
            },
            close: function($event) {
              return _vm.dialogClose(_vm.showVideoDialog)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function($event) {
                          _vm.showVideoDialog = false
                        }
                      }
                    },
                    [_vm._v("关闭")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("j-form", {
            ref: "formDetail",
            attrs: {
              formConfig: _vm.dialogFormDetail,
              formData: _vm.dialogFormData
            },
            on: {
              "update:formData": function($event) {
                _vm.dialogFormData = $event
              },
              "update:form-data": function($event) {
                _vm.dialogFormData = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "j-dialog",
        {
          attrs: {
            width: "56%",
            visible: _vm.showReadDialog,
            title: "阅读数据"
          },
          on: {
            "update:visible": function($event) {
              _vm.showReadDialog = $event
            },
            close: function($event) {
              return _vm.dialogClose(_vm.showReadDialog)
            },
            confirm: _vm.dialogConfirm
          }
        },
        [
          _c("dynamic-table", {
            ref: "jTable",
            staticStyle: { width: "932px", margin: "0 auto" },
            attrs: {
              headers: _vm.headers,
              table: _vm.table,
              pagination: _vm.pagination,
              data: _vm.tableData
            },
            on: { load: _vm.getReadInfo, "current-change": _vm.selectRow },
            scopedSlots: _vm._u([
              {
                key: "customerName",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.goCustomerDetail(row.customerId)
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.customerName))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }