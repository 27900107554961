var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        data: _vm.tableData,
        "row-style": { height: "20px" },
        "cell-style": { padding: "6px 0" },
        height: "300"
      }
    },
    [
      _c("el-table-column", {
        attrs: { prop: "customerName", label: "姓名", width: "80" }
      }),
      _c("el-table-column", {
        attrs: { prop: "identification", label: "证件号", width: "170" }
      }),
      _c("el-table-column", {
        attrs: { prop: "mobile", label: "联系方式", width: "110" }
      }),
      _c("el-table-column", {
        attrs: { prop: "orgName", label: "机构", width: "150" }
      }),
      _c("el-table-column", {
        attrs: { prop: "customerCode", label: "客户编码", width: "110" }
      }),
      _c("el-table-column", {
        attrs: { align: "right", width: "130" },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "absolute" },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { placeholder: "请输入姓名", change: "abInput" },
                        model: {
                          value: _vm.schKey,
                          callback: function($$v) {
                            _vm.schKey = $$v
                          },
                          expression: "schKey"
                        }
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            slot: "append",
                            size: "small",
                            icon: "el-icon-search"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickSearch()
                            }
                          },
                          slot: "append"
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      trigger: "hover",
                      placement: "top",
                      disabled: scope.row.agentUserHasResigned !== 1
                    }
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "该客户的理顾已离职（所在机构停用），请联系总部客服！"
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "name-wrapper",
                        attrs: { slot: "reference" },
                        slot: "reference"
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              disabled: scope.row.agentUserHasResigned === 1
                            },
                            on: {
                              click: function($event) {
                                return _vm.clickSelectItem(
                                  scope.$index,
                                  scope.row
                                )
                              }
                            }
                          },
                          [_vm._v("选择")]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }