var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { attrs: { gutter: 12 } },
    [
      _c(
        "el-col",
        { attrs: { span: 6 } },
        [
          _c(
            "el-card",
            [
              _c("div", [
                _vm._v(" 清理系统缓存，包括 "),
                _c("ul", [
                  _c("li", [_vm._v("cacheEnumManager: 常量缓存")]),
                  _c("li", [_vm._v("cacheOrgManager: 机构，操作人员缓存")]),
                  _c("li", [_vm._v("cacheUserManager: 人员特定配置缓存")])
                ])
              ]),
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "mini" },
                  on: { click: _vm.clickCleanCache }
                },
                [_vm._v("清理缓存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 6 } },
        [
          _c(
            "el-card",
            [
              _c("div", [
                _vm._v(
                  " 消息提醒定时任务默认周末不执行，如果假期调整，可以手动启动。手动启动后将忽略周末 "
                )
              ]),
              _c("br"),
              _c("el-switch", {
                attrs: { "active-text": "启用", "inactive-text": "关闭" },
                on: { change: _vm.clickRemindSwitch },
                model: {
                  value: _vm.remindManualSwitch,
                  callback: function($$v) {
                    _vm.remindManualSwitch = $$v
                  },
                  expression: "remindManualSwitch"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 6 } },
        [
          _c(
            "el-card",
            [
              _c("div", [_vm._v(" 短信开关，关闭后不能发送短信 ")]),
              _c("br"),
              _c("el-switch", {
                attrs: { "active-text": "启用", "inactive-text": "关闭" },
                on: { change: _vm.clickSmsSwitch },
                model: {
                  value: _vm.smsSwitch,
                  callback: function($$v) {
                    _vm.smsSwitch = $$v
                  },
                  expression: "smsSwitch"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }