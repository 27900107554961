//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '合同编号',
  prop: 'contractCd',
  width: '150px'
}, {
  label: '状态',
  prop: 'countState',
  width: '120px',
  type: 'slot'
}, {
  label: '客户',
  prop: 'customerName',
  width: '80px'
}, {
  label: '产品',
  prop: 'productName',
  width: '150px'
}, {
  label: '合同金额',
  prop: 'amount',
  width: '120px',
  align: 'right'
}, {
  label: '开始日期',
  prop: 'startDate',
  width: VAL_WIDTH
}, {
  label: '结束日期',
  prop: 'endDate',
  width: VAL_WIDTH
}, {
  label: '期限(月)',
  prop: 'timeLimit',
  width: '80px'
}, {
  label: '返还周期',
  prop: 'returnPeriod',
  width: '80px'
}, {
  label: '预期收益',
  prop: 'expectEarnings',
  width: '120px',
  align: 'right'
}, {
  label: '机构',
  prop: 'orgName',
  width: '210px'
}, {
  label: '核算日期',
  prop: 'countDate',
  width: VAL_WIDTH
}, {
  label: '客户回访',
  prop: 'customerVisitClaimText',
  width: '80px'
}, {
  label: '首次提交',
  prop: 'submitDateFirst',
  width: '160px'
}, {
  label: '最后提交',
  prop: 'submitDate',
  width: '160px'
}];