import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
};
export var TOP_FORM = {
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '任务名称',
    props: {
      placeholder: '请输入任务名称'
    }
  }),
  type: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '任务类型',
    props: {
      placeholder: '请选择'
    },
    options: []
  }),
  createTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '创建时间',
    propGroup: ['beginCreateTime', 'endCreateTime'],
    props: {
      type: 'daterange'
    }
  }),
  updateTime: {
    labelWidth: 120,
    type: 'datePicker',
    label: '最后修改时间',
    propGroup: ['updateTimeBegin', 'updateTimeEnd'],
    props: {
      type: 'daterange'
    }
  },
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '状态',
    props: {
      placeholder: '请选择状态'
    },
    options: [{
      label: '启用',
      value: 1
    }, {
      label: '停用',
      value: 0
    }]
  })
};
export var TABLE_LABEL = [{
  label: '任务名称',
  prop: 'name'
}, {
  label: '任务频次',
  prop: 'frequency',
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 1:
        res = '一次';
        break;

      case 2:
        res = '每天';
        break;

      case 3:
        res = '不限';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  label: '任务类型',
  prop: 'typeValue'
}, {
  label: '创建时间',
  prop: 'createTime',
  sortable: 'interestTask',
  sortProp: 'createTime'
}, {
  label: '最后修改时间',
  prop: 'updateTime'
}, {
  label: '最后操作人',
  prop: 'updateUserName'
}, {
  label: '状态',
  prop: 'status',
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 0:
        res = '停用';
        break;

      case 1:
        res = '启用';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  type: 'operation',
  label: '操作',
  // fixed: 'right',
  formatter: function formatter(row) {
    var arr = [];

    if (row.status === 1) {
      arr.push({
        type: 'edit',
        label: '修改',
        permission: 'ScoreTask-update'
      });
      arr.push({
        type: 'details',
        label: '参与详情',
        permission: 'ScoreTask-getInfo'
      });
      arr.push({
        type: 'stop',
        label: '停用',
        permission: 'ScoreTask-updateStatus'
      });
    } else {
      arr.push({
        type: 'edit',
        label: '修改',
        permission: 'ScoreTask-update'
      });
      arr.push({
        type: 'details',
        label: '参与详情',
        permission: 'ScoreTask-getInfo'
      });
      arr.push({
        type: 'delete',
        label: '删除',
        permission: 'ScoreTask-delete'
      });
      arr.push({
        type: 'start',
        label: '启用',
        permission: 'ScoreTask-updateStatus'
      });
    }

    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  rowKey: 'id',
  actions: [{
    label: '新增',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'ScoreTask-add'
  }]
}; // 弹窗中form表单配置

export var DIALOG_FORM_CONFIG = {
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '任务名称',
    props: {
      maxlength: 16,
      showWordLimit: true
    },
    rules: [{
      required: true,
      message: '任务名称不能为空',
      trigger: 'blur'
    }]
  }),
  description: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '描述',
    props: {
      maxlength: 32,
      showWordLimit: true
    }
  }),
  icon: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '图标',
    props: {
      placeholder: '最多可传1张，图片大小不超过200KB，支持GIF,JPEG,JPG,PNG,BMP格式',
      fileSize: 200,
      fileType: ['GIF', 'JPEG', 'JPG', 'PNG', 'BMP']
    }
  }),
  type: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '任务类型',
    options: [],
    rules: [{
      required: true,
      message: '请选择',
      trigger: 'blur'
    }]
  }),
  annualizedAmount: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '年化金额',
    hidden: true,
    props: {
      tips: '万元'
    },
    rules: [{
      required: true,
      message: '请填写年化金额',
      trigger: 'blur'
    }]
  }),
  frequency: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '任务频次',
    options: [{
      label: '一次',
      value: 1
    }, {
      label: '每天',
      value: 2
    }, {
      label: '不限',
      value: 3
    }],
    props: {
      disabled: false
    },
    rules: [{
      required: true,
      message: '请选择',
      trigger: 'blur'
    }]
  }),
  customerLevelId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '关联等级',
    options: []
  }),
  rewardScore: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    label: '完成奖励',
    rules: [{
      required: true,
      message: '请填写',
      trigger: 'blur'
    }]
  }),
  sort: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '排序',
    props: {
      type: 'number'
    },
    rules: [{
      required: true,
      message: '请填写',
      trigger: 'blur'
    }]
  }),
  isShow: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'radio',
    label: '是否显示',
    options: [{
      label: '是',
      value: 1
    }, {
      label: '否',
      value: 0
    }],
    props: {
      disabled: true
    }
  })
}; // 弹窗中form表单配置

export var DIALOG_FORM_DATA = {
  isShow: 0,
  sort: 0,
  customerLevelId: ''
};