var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          filtersFormatter: _vm.filtersFormatter,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        }
      }),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "审核",
            width: "350px",
            height: "300px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.closeApprove
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "80px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.active ? "客服审核" : "财务审核" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.form.approvalOperation,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "approvalOperation", $$v)
                        },
                        expression: "form.approvalOperation"
                      }
                    },
                    [_vm._v("审核通过")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.form.approvalOperation,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "approvalOperation", $$v)
                        },
                        expression: "form.approvalOperation"
                      }
                    },
                    [_vm._v("审核不通过")]
                  )
                ],
                1
              ),
              _vm.form.approvalOperation == 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "拒绝原因", prop: "rejectReason" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.form.rejectReason,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "rejectReason", $$v)
                          },
                          expression: "form.rejectReason"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.approvalConfirm }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisibleInfo,
            width: "25%",
            modal: false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleInfo = $event
            },
            close: _vm.close
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-title",
              attrs: { slot: "title" },
              slot: "title"
            },
            [
              _c(
                "el-row",
                [
                  _c("span", { staticClass: "dialog-title-text" }, [
                    _vm._v("客户信息")
                  ]),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { type: "primary" },
                      on: { click: _vm.skip }
                    },
                    [_vm._v("跳转详情")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "el-descriptions",
                { attrs: { direction: "horizontal", column: 1, border: "" } },
                [
                  _c("el-descriptions-item", { attrs: { label: "客户姓名" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.customerName))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "性别" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.sexValue))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "年龄" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.age))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "客户电话" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.mobile))
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "家庭住址", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.address))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "客户类型", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.customerTypeValue))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "客户等级", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.customerLevelValue))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "客户年限", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.customerYearLimit))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "理顾", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.agentUserName))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "部门", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.orgName))]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("Add", {
        attrs: { visible: _vm.addVisible },
        on: { close: _vm.addClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }