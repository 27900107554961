import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import SearchCustomer from '@/components/business/search-customer';
import { create, getCustomerBankCard, update } from '@api/crm/customer/bankCard';
export default {
  name: 'openingBankInfo-add',
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      loading: false,
      formModel: {},
      rules: {
        accountName: [{
          required: true,
          message: '请选择',
          trigger: 'input'
        }],
        accountNo: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }]
      },
      showSearchCustomer: false
    };
  },
  mixins: [dialogMixin],
  components: {
    SearchCustomer: SearchCustomer
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val && this.isEdit) {
          this.init();
        }
      },
      immediate: true
    }
  },
  created: function created() {},
  methods: {
    init: function init() {
      var _this = this;

      // 编辑
      if (this.isEdit) {
        getCustomerBankCard({
          accountNo: this.propsModel.accountNo
        }).then(function (res) {
          _this.formModel = _objectSpread({}, res.data);
        });
      }
    },
    dialogClose: function dialogClose() {
      this.formModel = {};
      this.$refs.form.resetFields();
      this.$emit('refresh');
    },
    // 获取客户信息
    popSelectCustomer: function popSelectCustomer(customer) {
      this.$set(this.formModel, 'accountName', customer.customerName); // 银行卡户名

      this.$set(this.formModel, 'customerCode', customer.customerCode); // 客户编码

      this.$set(this.formModel, 'idType', customer.idType); // 证件类型

      this.$set(this.formModel, 'identification', customer.identification); // 证件号码

      this.$set(this.formModel, 'orgName', customer.orgName); // 机构名称

      this.$set(this.formModel, 'agentUserName', customer.agentUserName); // 理顾名称

      this.showSearchCustomer = false;
    },
    // 提交
    submitForm: function submitForm() {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this2.isEdit) {
            // 编辑
            var object = {};
            object = {
              id: _this2.formModel.id,
              province: _this2.formModel.province,
              city: _this2.formModel.city,
              bankCode: _this2.formModel.bankCode,
              isCertify: _this2.formModel.isCertify
            };
            update({
              object: object
            }).then(function (res) {
              _this2.$message({
                message: '编辑成功',
                type: 'success'
              });

              _this2.showDialog = false;
            });
          } else {
            // 新增
            create({
              object: _this2.formModel
            }).then(function (res) {
              _this2.$message({
                message: '新增成功',
                type: 'success'
              });

              _this2.showDialog = false;
            });
          }
        }
      });
    }
  }
};