import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import dialogMixin from '@/mixins/dialog';
import crmMixin from '@/mixins/crm';
import { setExtendAttribute } from '@/libs/crm/crm';
import { commissionGetWaitAllocation, commissionAudit } from '@api/crm/commissionV3/commissionV3';
import LOG_TYPE from '@/maps/enum/contract-log-type';
import ContractLog from '@/components/business/contract-log';
import COMMISSION_STATUS from '@/maps/enum/status/commission-status';
import ATTACHMENT_TYPE from '@/maps/enum/attachment-type';
import Attachment from '@/components/business/attachment';
export default {
  name: 'commission-new-add',
  components: {
    ContractLog: ContractLog,
    Attachment: Attachment
  },
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  mixins: [dialogMixin, crmMixin],
  data: function data() {
    return {
      loading: false,
      tabActive: 'tabAllocation',
      // 标签框默认选中
      collapseActive: ['1', '2'],
      // 信息折叠默认展开
      model: {},
      formModel: {},
      // 表单
      tempFormModel: {},
      // 暂存表单值
      selectData: {
        person: []
      },
      rules: {},
      currentRow: {},
      // 当前点击行
      currentIndex: 0,
      showLogDialog: false,
      contractLogType: LOG_TYPE.COMMISSION,
      attachmentType: ATTACHMENT_TYPE.COMMISSION // 附件类型

    };
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.formModel = {};
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    onAttachmentOpen: function onAttachmentOpen() {
      // 初始化标签
      this.tabActive = 'tabContract';
    },
    // TODO 获取列表
    loadData: function loadData() {
      this.model = this.propsModel;
      setExtendAttribute(this.model); // 包装必要展示字段

      this.$set(this.formModel, 'isBackOffice', this.model.isBackOffice); // 是否后勤人员 0否 1是
      // 获取列表

      this.changeRadio();
    },
    // 是否后勤人员页面展示
    changeRadio: function changeRadio() {
      if (this.formModel.isBackOffice === '0') {
        this.getTableData(false);
      } else if (this.formModel.isBackOffice === '1') {
        this.getTableData(true);
      }
    },
    // 获取职级列表 true 非后勤人员 false 后勤人员
    getTableData: function getTableData(jedge) {
      var _this = this;

      var arr = [];

      if (jedge) {
        arr = [{
          titleId: 'i0',
          percent: null,
          bonus: null,
          bonusUserId: null
        }, {
          titleId: 'i5',
          percent: null,
          bonus: null,
          bonusUserId: null
        }];
      } else {
        arr = [{
          titleId: 'i0',
          percent: null,
          bonus: null,
          bonusUserId: null
        }, {
          titleId: 'i1',
          percent: null,
          bonus: null,
          bonusUserId: null
        }, {
          titleId: 'i4',
          percent: null,
          bonus: null,
          bonusUserId: null
        }, {
          titleId: 'i2',
          percent: null,
          bonus: null,
          bonusUserId: null
        }, {
          titleId: 'i3',
          percent: null,
          bonus: null,
          bonusUserId: null
        }];
      }

      this.$set(this.formModel, 'tableData', arr); // 表格初始值赋值

      this.formModel.tableData.forEach(function (item, index) {
        if (_this.model.detailList.length > 0) {
          // 遍历职级列表 有值赋值
          _this.model.detailList.forEach(function (j, i) {
            if (item.titleId === j.titleId) {
              _this.formModel.tableData[index] = j;
            }
          });
        }
      });
    },
    showLog: function showLog() {
      // 显示审核日志
      this.showLogDialog = true;
    },
    StatusText: function StatusText(Status) {
      var res = '';

      switch (Status) {
        case 'i0':
          res = '顾问';
          break;

        case 'i1':
          res = '团队总监';
          break;

        case 'i2':
          res = '营业部总经理';
          break;

        case 'i3':
          res = '区域总经理';
          break;

        case 'i4':
          res = '营业部副总';
          break;

        case 'i5':
          res = '后勤人员管理层';
          break;

        default:
          res = '';
      }

      return res;
    },
    clickAuditPlan: function clickAuditPlan() {
      var _this2 = this;

      if (this.formModel.advice == null || this.formModel.advice == '') {
        return this.$message('请填写审核意见');
      } // 退回计划


      this.$confirm('是否退回到待计划?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.audit(COMMISSION_STATUS.FINANCE_AUDIT_COUNT_FAIL.value);
      }).catch(function () {});
    },
    clickAuditFail: function clickAuditFail() {
      // 审核不通过
      if (this.formModel.advice == null || this.formModel.advice == '') {
        this.$message('请填写审核意见');
      } else {
        this.audit(COMMISSION_STATUS.FINANCE_AUDIT_FAIL.value);
      }
    },
    clickAudit: function clickAudit() {
      // 审核通过
      this.audit(COMMISSION_STATUS.MONEY_AUDIT.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this3 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var arrayApplicationId = [];
          arrayApplicationId[0] = _this3.model.applicationId;
          var param = {
            arrayApplicationId: arrayApplicationId,
            status: result,
            advice: _this3.formModel.advice
          };
          _this3.loading = true;
          commissionAudit(param).then(function (res) {
            _this3.loading = false;
            _this3.showDialog = false;

            _this3.$emit('refresh');
          }).catch(function (e) {
            _this3.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};