//角色
export default {
  list: '/role/list',
  // 列表
  add: '/role/add',
  // 新增
  edit: '/role/edit',
  // 新增
  remove: '/role/remove',
  // 删除
  setEnabled: '/role/setEnabled',
  // 启用/停用角色
  roleMenu: 'role/listRoleMenu',
  // 角色菜单
  setRoleData: '/role/setRoleData',
  // 设置角色数据权限
  getRoleData: '/role/listRoleOrg',
  // 获取角色数据权限
  setUserRole: '/role/setUserRole',
  // 设置用户角色
  listUserRole: '/role/listUserRole',
  // 查询用户的角色列表
  listRoleUser: '/role/listRoleUser',
  // 查询角色用户列表
  setRoleUser: '/role/setRoleUser',
  // 设置角色用户
  setSecrecy: '/role/setSecrecy',
  // 设置敏感词
  listSecrecy: '/role/listSecrecy',
  // 查询敏感词列表
  removeSecrecy: '/role/removeSecrecy' // 删除保密设置    

};