//代理机构推介费
export default {
  listNoCommission: '/commission/agency/listNoCommission',
  // 机构待推介费申请列表
  addPlan: '/commission/agency/addPlan',
  // 创建计划
  listNoCommissionCompany: '/commission/agency/listNoCommissionCompany',
  // 机构检查待处理推介费公司
  listCompanyNoCommission: '/commission/agency/listCompanyNoCommission',
  // 机构待处理的推介费列表
  addApplication: '/commission/agency/addApplication',
  //创建推介费申请
  removeApplication: '/commission/agency/removeApplication',
  // 删除推介费申请
  addBatch: '/commission/agency/addBatch',
  // 创建推介费台帐   
  removeBatch: '/commission/agency/removeBatch',
  // 删除推介费台帐明细 
  listCommissionBatch: '/commission/agency/listCommissionBatch',
  // 推介费台账列表
  listCommissionApplication: '/commission/agency/listCommissionApplication',
  // 推介费明细列表
  exportApplicationTemplate: '/commission/agency/exportApplicationTemplate',
  // 导出推介费台账Excel
  sumBatch: '/commission/agency/sumBatch',
  // 台帐汇总
  listSummary: '/commission/agency/listSummary',
  // 推介费汇总报表列表
  submit: '/commission/agency/submit',
  // 提交
  auditOrg: '/commission/agency/auditOrg',
  // 审核:分公司
  auditCount: '/commission/agency/auditCount',
  // 结算审核
  auditFinance: '/commission/agency/auditFinance',
  // 财务审核
  auditFinanceManager: '/commission/agency/auditFinanceManager',
  // 财务总监审核
  listBatchDetail: '/commission/agency/listBatchDetail',
  // 台账明细列表 
  addDetailToBatch: '/commission/agency/addDetailToBatch',
  // 添加申请明细到台帐  
  removeDetailFromBatch: '/commission/agency/removeDetailFromBatch',
  // 从台帐中移除明细
  listBatchBySummaryId: '/commission/agency/listBatchBySummaryId',
  // 汇总台帐列表
  addSummary: '/commission/agency/addSummary',
  // 添加汇总报表
  sumSummary: '/commission/agency/sumSummary',
  // 汇总表合并计算
  exportSummary: '/commission/agency/exportSummary',
  // 导出汇总表
  confirmSummary: '/commission/agency/confirmSummary' // 推介费汇总确认完成

};