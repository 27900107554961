var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c(
        "el-row",
        { staticClass: "page-table-container", attrs: { type: "flex" } },
        [
          _c(
            "el-col",
            { staticClass: "list-container", attrs: { span: 24 } },
            [
              _c("dynamic-form", {
                ref: "form",
                attrs: { props: _vm.filterProps, forms: _vm.filterForms },
                on: { onChange: _vm.itemChange }
              }),
              _c(
                "el-row",
                {
                  staticClass: "actions",
                  attrs: { type: "flex", justify: "space-between" }
                },
                [
                  _c("div"),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-refresh" },
                          on: {
                            click: function($event) {
                              return _vm.clickRefresh()
                            }
                          }
                        },
                        [_vm._v("刷新")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            plain: "",
                            size: "mini",
                            icon: "el-icon-bottom-left"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickReset()
                            }
                          }
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            plain: "",
                            size: "mini",
                            icon: "el-icon-search"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickSearch()
                            }
                          }
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "success",
                            plain: "",
                            size: "mini",
                            icon: "el-icon-download"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickExport()
                            }
                          }
                        },
                        [_vm._v("Excel")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: "business-Sms",
                              expression: "'business-Sms'"
                            }
                          ],
                          attrs: {
                            type: "primary",
                            plain: "",
                            size: "mini",
                            icon: "el-icon-search"
                          },
                          on: {
                            click: function($event) {
                              return _vm.testSendSms()
                            }
                          }
                        },
                        [_vm._v("测试短信")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("dynamic-table", {
                ref: "jTable",
                attrs: {
                  table: _vm.table,
                  pagination: _vm.pagination,
                  data: _vm.tableData,
                  loading: _vm.loading
                },
                on: { load: _vm.loadData, "current-change": _vm.selectRow },
                scopedSlots: _vm._u([
                  {
                    key: "operation",
                    fn: function() {
                      return [
                        _c("el-table-column", {
                          attrs: {
                            prop: "typeText",
                            label: "渠道",
                            width: "80"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "orgName",
                            label: "单位",
                            width: "250"
                          }
                        }),
                        _c(
                          "el-table-column",
                          { attrs: { label: "到期", align: "center" } },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "countAmount",
                                label: "到期合计（万元）",
                                width: "150"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "countFinishAmount",
                                label: "已到期（万元）",
                                width: "150"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "renewAmount",
                                label: "续约合同金额（万元）",
                                width: "170"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "renewPercent",
                                label: "续约合同占比（%）",
                                width: "150"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-table-column",
                          { attrs: { label: "成交", align: "center" } },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "inAmount",
                                label: "入金合计（万元）",
                                width: "140"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "inPercent",
                                label: "入金增长率（%）",
                                width: "140"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "inAdd",
                                label: "入金差（万元）",
                                width: "120"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "inOldCustomer",
                                label: "老客户入金（万元）",
                                width: "150"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "inOldPercent",
                                label: "老客户入金占比（%）",
                                width: "160"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "inNewCustomer",
                                label: "新客户入金（万元）",
                                width: "150"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "inNewPercent",
                                label: "新客户入金占比（%）",
                                width: "160"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-table-column",
                          { attrs: { label: "赎回", align: "center" } },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "outAmount",
                                label: "赎回金额（万元）",
                                width: "140"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "outInterest",
                                label: "付息出金（万元）",
                                width: "140"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "outInterestPercent",
                                label: "付息占比（%）",
                                width: "140"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "outApply",
                                label: "出金合计（万元）",
                                width: "100px"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "outApplyPercent",
                                label: "出金占比（%）",
                                width: "150"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "renewNoCount",
                                label: "未续约客户数",
                                width: "140"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "renewNoSum",
                                label: "未续约客户金额（万元）",
                                width: "130",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "renewPartNo",
                                label: "部分赎回金额（万元）",
                                width: "130",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "renewAllNo",
                                label: "全部赎回金额（万元）",
                                width: "130",
                                align: "center"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }