//付息
export default {
  list: '/interest/list',
  // 列表
  getInfo: '/interest/getInfo',
  // 详情
  save: '/interest/save',
  // 暂存
  submit: '/interest/submit',
  // 提交
  remove: '/interest/remove',
  // 删除
  auditService: '/interest/auditService',
  //客服审核
  auditCount: '/interest/auditCount',
  //结算审核
  auditFinance: '/interest/auditFinance',
  //财务审核
  auditFinanceManager: '/interest/auditFinanceManager',
  //财务总监审核
  auditPrint: '/interest/auditPrint',
  //出金打印审核
  auditMoney: '/interest/auditMoney',
  //资金审核
  printConfirm: '/interest/printConfirm',
  //打印确认
  exportExcel: '/interest/exportExcel',
  // 导出Excel
  exportExcelBill: '/interest/exportExcelBill',
  // 导出对帐单Excel
  exportExcelPayment: '/interest/exportExcelPayment',
  // 导出对帐单Excel
  getLastInfo: '/interest/getLastInfo',
  // 取最后一期付息详情
  getInitLastInfo: '/interest/getInitLastInfo',
  // 获取最后一期付息初始值
  create: '/interest/create' // 创建

};