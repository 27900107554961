export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    //  待办

    /** 分页列表 */
    WAITTOBEDONE_GETPAGE: function WAITTOBEDONE_GETPAGE(data) {
      return request({
        url: url.messageManagement.waitToBeDone.getPage,
        data: data
      });
    },

    /** 导出 */
    WAITTOBEDONE_XEPORT: function WAITTOBEDONE_XEPORT(data) {
      return request({
        url: url.messageManagement.waitToBeDone.export,
        data: data,
        responseType: 'arraybuffer'
      });
    },

    /** 待办续约明细列表 */
    WAITTOBEDONE_GETRENEWALDETAIL: function WAITTOBEDONE_GETRENEWALDETAIL(data) {
      return request({
        url: url.messageManagement.waitToBeDone.getRenewalDetail,
        data: data
      });
    },

    /** 作废 */
    WAITTOBEDONE_CANCEL: function WAITTOBEDONE_CANCEL(data) {
      return request({
        url: url.messageManagement.waitToBeDone.cancel,
        data: data
      });
    },
    // 消息

    /** 分页列表 */
    INFORMATION_GETPAGE: function INFORMATION_GETPAGE() {
      return request({
        url: url.messageManagement.information.getPage
      });
    },

    /** 导出 */
    INFORMATION_XEPORT: function INFORMATION_XEPORT(data) {
      return request({
        url: url.messageManagement.information.export,
        data: data,
        responseType: 'arraybuffer'
      });
    },

    /** 消息接收者记录 */
    INFORMATION_GETRECIPIENTLOGT: function INFORMATION_GETRECIPIENTLOGT(data) {
      return request({
        url: url.messageManagement.information.getRecipientLogt,
        data: data
      });
    },

    /** 新增 */
    INFORMATION_ADD: function INFORMATION_ADD(data) {
      return request({
        url: url.messageManagement.information.add,
        data: data
      });
    },

    /** 更新 */
    INFORMATION_UPDATE: function INFORMATION_UPDATE(data) {
      return request({
        url: url.messageManagement.information.update,
        data: data
      });
    },

    /** 作废 */
    INFORMATION_CANCEL: function INFORMATION_CANCEL(data) {
      return request({
        url: url.messageManagement.information.cancel,
        data: data
      });
    },

    /** 删除 */
    INFORMATION_DELETE: function INFORMATION_DELETE(data) {
      return request({
        url: url.messageManagement.information.delete,
        data: data
      });
    },
    // 节日祝福

    /** 分页列表 */
    HOLIDAYBLESSINGS_GETPAGE: function HOLIDAYBLESSINGS_GETPAGE(data) {
      return request({
        url: url.messageManagement.holidayBlessings.getPage,
        data: data
      });
    },

    /** 导出 */
    HOLIDAYBLESSINGS_XEPORT: function HOLIDAYBLESSINGS_XEPORT(data) {
      return request({
        url: url.messageManagement.holidayBlessings.export,
        data: data,
        responseType: 'arraybuffer'
      });
    },

    /** 详情 */
    HOLIDAYBLESSINGS_GETINFO: function HOLIDAYBLESSINGS_GETINFO(data) {
      return request({
        url: url.messageManagement.holidayBlessings.getInfo,
        data: data
      });
    },

    /** 新增 */
    HOLIDAYBLESSINGS_ADD: function HOLIDAYBLESSINGS_ADD(data) {
      return request({
        url: url.messageManagement.holidayBlessings.add,
        data: data
      });
    },

    /** 更新 */
    HOLIDAYBLESSINGS_UPDATE: function HOLIDAYBLESSINGS_UPDATE(data) {
      return request({
        url: url.messageManagement.holidayBlessings.update,
        data: data
      });
    },

    /** 删除 */
    HOLIDAYBLESSINGS_DELETE: function HOLIDAYBLESSINGS_DELETE(data) {
      return request({
        url: url.messageManagement.holidayBlessings.delete,
        data: data
      });
    }
  };
});