import "core-js/modules/es.object.to-string.js";
import request from '@/plugin/axios';
import func from '@/maps/url/common/common'; // TODO 获取验证码

export function getVerifyCode() {
  var verifyId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return request.post(func.getVerifyCode, {
    body: {
      verifyId: verifyId // 验证码Id，首次为null，后面刷新验证码时传参

    }
  }).then(function (res) {
    var _res$data = res.data,
        data = _res$data === void 0 ? {} : _res$data;
    var verifyId = data.verifyId,
        verifyImg = data.verifyImg;
    return {
      verifyId: verifyId,
      verifyImg: "data:image/jpeg;base64,".concat(verifyImg) // 拼接成base64图片

    };
  });
} // TODO 校验验证码

export function checkVerifyCode(data) {
  return request.post(func.checkVerifyCode, {
    body: data
  }).then(function (res) {
    return Promise.resolve(true);
  }).catch(function (e) {
    return Promise.resolve(false);
  });
} // TODO 获取常量列表

export function getEnumList(params) {
  var promise = new Promise(function (resolve, reject) {
    request.post(func.enumList, {
      body: params
    }).then(function (res) {
      var array = res.data;
      resolve(array);
    }).catch(function (res) {
      reject(res);
    });
  });
  return promise;
} // TODO 获取常量列表

export function getEnumListAll(params) {
  var promise = new Promise(function (resolve, reject) {
    request.post(func.enumListAll, {
      body: params
    }).then(function (res) {
      var array = res.data;
      resolve(array);
    }).catch(function (res) {
      reject(res);
    });
  });
  return promise;
}