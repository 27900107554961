// 表头
export default [{
  label: '月份',
  prop: 'month',
  width: '150px'
}, {
  label: '浮动收益率(%)',
  prop: 'floatRate',
  width: '150px',
  align: 'right'
}];