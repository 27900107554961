//纸合同
export default {
  save: '/paperContractBox/save',
  // 保存 
  removeBox: '/paperContractBox/removeBox',
  // 删除柜子
  listBox: '/paperContractBox/listBox',
  // 合同柜列表
  listNotArchive: '/paperContractBox/listNotArchive',
  // 未归档合同列表
  archive: '/paperContractBox/archive',
  //合同归档
  removeArchive: '/paperContractBox/removeArchive',
  //删除归档合同
  listArchive: '/paperContractBox/listArchive',
  //归档合同列表
  exportExcelArchive: '/paperContractBox/exportExcelArchive' //合同归档导出excel  

};