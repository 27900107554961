export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    /** 责任状业绩 列表 */
    CONZRZ_QUERYLIST: function CONZRZ_QUERYLIST(data) {
      return request({
        url: url.conzrz.queryList,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 责任状业绩 编辑 */
    CONZRZ_EDITBEAN: function CONZRZ_EDITBEAN(data) {
      return request({
        url: url.conzrz.editBean,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 责任状业绩 批量修改业绩归属部门 */
    CONZRZ_EDITBATCHORGCD: function CONZRZ_EDITBATCHORGCD(data) {
      return request({
        url: url.conzrz.editBatchOrgcd,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 责任状业绩 批量修改业绩系数 */
    CONZRZ_EDITBATCHFACTOR: function CONZRZ_EDITBATCHFACTOR(data) {
      return request({
        url: url.conzrz.editBatchFactor,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 责任状业绩 导出Excel */
    CONZRZ_EXPORTLIST: function CONZRZ_EXPORTLIST(data) {
      return request({
        url: url.conzrz.exportList,
        data: data,
        responseType: 'arraybuffer'
      }).then(function (res) {
        return res;
      });
    },

    /** 基本法业绩 列表 */
    CONJBF_QUERYLIST: function CONJBF_QUERYLIST(data) {
      return request({
        url: url.conjbf.queryList,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 基本法业绩 编辑 */
    CONJBF_EDITBEAN: function CONJBF_EDITBEAN(data) {
      return request({
        url: url.conjbf.editBean,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 基本法业绩 批量修改业绩归属部门 */
    CONJBF_EDITBATCHORGCD: function CONJBF_EDITBATCHORGCD(data) {
      return request({
        url: url.conjbf.editBatchOrgcd,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 基本法业绩 批量修改业绩系数 */
    CONJBF_EDITBATCHFACTOR: function CONJBF_EDITBATCHFACTOR(data) {
      return request({
        url: url.conjbf.editBatchFactor,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 基本法业绩 导出Excel */
    CONJBF_EXPORTLIST: function CONJBF_EXPORTLIST(data) {
      return request({
        url: url.conjbf.exportList,
        data: data,
        responseType: 'arraybuffer'
      }).then(function (res) {
        return res;
      });
    }
  };
});