





























































































































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/customer/manage/add/index.vue"
}
