//查询条件
var VAL_WIDTH = '150px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '帐号',
  valueKey: 'userAccount',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: '180px'
    }
  }
}, {
  label: '操作日期',
  valueKey: 'operationTimeBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'operationTimeEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '类型',
  valueKey: 'operationType',
  value: '',
  type: 'select',
  options: [{
    value: '01',
    text: '登陆'
  }, {
    value: '02',
    text: '退出'
  }, {
    value: '03',
    text: '修改密码'
  }, {
    value: '04',
    text: '新增'
  }, {
    value: '05',
    text: '编辑'
  }, {
    value: '06',
    text: '删除'
  }, {
    value: '07',
    text: '审核'
  }, {
    value: '08',
    text: 'Excel'
  }],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}];