// 目标管理
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [{
  path: "".concat(baseRoute, "/messageManagement/waitToBeDone"),
  name: 'waitToBeDone',
  meta: {
    title: '待办',
    auth: true,
    cache: true
  },
  component: _import('crm/messageManagement/waitToBeDone')
}, {
  path: "".concat(baseRoute, "/messageManagement/information"),
  name: 'information',
  meta: {
    title: '消息',
    auth: true,
    cache: true
  },
  component: _import('crm/messageManagement/information')
}, {
  path: "".concat(baseRoute, "/messageManagement/holidayBlessings"),
  name: 'holidayBlessings',
  meta: {
    title: '节日祝福素材',
    auth: true,
    cache: true
  },
  component: _import('crm/messageManagement/holidayBlessings')
}];