import "core-js/modules/es.object.to-string.js";
//查询条件
import STATUS from "@/maps/enum/status/buy";
import { listCacheOrg } from "@api/common/cache";
import { listEnum as listEnumProduct } from "@api/crm/setup/product";
import PRODUCT_CATEGORY1 from "@/maps/enum/product-category1";
import PRODUCT_CATEGORY2 from "@/maps/enum/product-category2";
import { getEnumList } from '@api/common/common';
import ENUM from '@/maps/enum/enum';
var VAL_WIDTH = '130px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '合同编号',
  valueKey: 'contractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '购销合同编号',
  valueKey: 'buyContractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '客户',
  valueKey: 'customerName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '合同状态',
  valueKey: 'contractState',
  value: '5',
  type: 'select',
  options: [STATUS.DEAL],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '产品',
  valueKey: 'arrayProductId',
  value: [],
  type: 'select',
  method: filterSAPProduct,
  params: {},
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '机构名称',
  valueKey: 'orgCd',
  value: '',
  type: 'cascader',
  method: listCacheOrg,
  params: {},
  options: [],
  props: {
    //expandTrigger: 'hover',
    checkStrictly: true,
    emitPath: false,
    showAllLevels: false,
    value: 'orgCd',
    label: 'orgName',
    children: 'children'
  }
}, {
  label: '开始日期',
  valueKey: 'startDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'startDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '提交',
  valueKey: 'submitDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'submitDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '收款方式',
  valueKey: 'arrayPayMethod',
  value: [],
  type: 'select',
  method: getEnumList,
  params: {
    enumCd: ENUM.ENUM_CD_0204
  },
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
},, {
  label: '续约',
  valueKey: 'isReNew',
  value: '',
  type: 'select',
  options: [{
    value: '0',
    text: '否'
  }, {
    value: '1',
    text: '是'
  }],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}]; //sap产品

function filterSAPProduct() {
  var promise = new Promise(function (resolve, reject) {
    var param = {
      query: {
        arrayProductCategory2: [PRODUCT_CATEGORY2.HJCZ, PRODUCT_CATEGORY2.HJWT, PRODUCT_CATEGORY2.HJXLS]
      }
    };
    listEnumProduct(param).then(function (res) {
      var array = res.data;
      resolve(array);
    }).catch(function (res) {
      reject(res);
    });
  });
  return promise;
} //收款方式


function filterPayMethod() {
  var array = [{
    value: '11',
    text: '通联POS-金雅福首饰制造'
  }, {
    value: '24',
    text: '金雅福首饰制造民生银行'
  }, {
    value: '30',
    text: '金雅福首饰建行'
  }, {
    value: '31',
    text: '通联POS--金雅福首饰'
  }, {
    value: '32',
    text: '通联POS机--中山易宝'
  }, {
    value: '34',
    text: '拉卡拉POS-金雅福传承文化'
  }, {
    value: '35',
    text: '金雅福传承文化民生银行'
  }, {
    value: '36',
    text: '金紫薇农行'
  }, {
    value: '37',
    text: '拉卡拉POS-金紫薇'
  }, {
    value: '38',
    text: '拉卡拉POS-金雅福首饰制造'
  }, {
    value: '39',
    text: '银盛POS-金雅福首饰制造'
  }, {
    value: '40',
    text: '拉卡拉POS-金雅福首饰'
  }, {
    value: '41',
    text: '银盛POS-金雅福首饰'
  }];
  return array;
}