























































































export default function (Component) {
  Component.options.__source = "src/views/rm/system/system/basic/index.vue"
}
