import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash';
import { FORM_DETAIL_CONFIG } from './config';
import { useRouter } from '@/router';
import { useSuccessTip } from '@/hooks/use-tip';
import url from '@/api-new/url';
import api from '@/api-new';
import EnumReleaseStatus from '@/maps/info/releaseStatus';
var apiUrl = url.info.document;
export default {
  name: 'PageInfoView',
  data: function data() {
    return {
      form: {
        config: cloneDeep(FORM_DETAIL_CONFIG),
        model: {
          informationType: 1
        }
      },
      releaseType: 1,
      releaseTime: '',
      releaseStatus: 0,
      isEdit: false,
      submiting: false
    };
  },
  watch: {
    'form.model.informationType': {
      handler: function handler(val) {
        if (val === 2) {
          this.form.config[0].children[2].hidden = false;
          this.form.config[1].children[0].hidden = true;
          this.form.config[1].title = '';
        } else {
          this.form.config[0].children[2].hidden = true;
          this.form.config[1].children[0].hidden = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  created: function created() {
    this.init();
    var router = useRouter();
    /* 从哪跳转过来的 */

    var query = router.currentRoute.query;
    /* 判断是否为编辑 */

    this.isEdit = query.type === 'edit';
  },
  methods: {
    /* 取消 */
    Cancel: function Cancel() {
      /* 跳转到资讯管理列表页 */
      this.$router.push({
        name: 'infoManager'
      });
    },
    findPathByLeafId: function findPathByLeafId(leafId, nodes, valueName, path) {
      if (path === undefined) {
        path = [];
      }

      for (var i = 0; i < nodes.length; i++) {
        var tmpPath = path.concat();
        tmpPath.push(nodes[i][valueName]);

        if (leafId === nodes[i][valueName]) {
          return tmpPath;
        }

        if (nodes[i].children) {
          var findResult = this.findPathByLeafId(leafId, nodes[i].children, valueName, tmpPath);

          if (findResult) {
            return findResult;
          }
        }
      }
    },

    /* 获取文章信息详情 */
    getDetail: function getDetail(id) {
      var _this = this;

      api.COMMON_REQUEST(apiUrl.getInfoById, {
        id: id
      }).then(function (res) {
        var _releaseType = res.releaseType,
            _releaseStatus = res.releaseStatus,
            _releaseTime = res.releaseTime,
            coverUrl = res.coverUrl;
        _this.releaseType = _releaseType;
        _this.releaseTime = _releaseTime;
        _this.releaseStatus = _releaseStatus;
        _this.form.model = res;
        _this.form.model.coverUrl = {
          url: coverUrl
        };
        _this.form.model.classifyId = _this.findPathByLeafId(_this.form.model.classifyId, _this.form.config[0].children[4].options, 'id');
        /* 目前能跑通，后期需优化 */
      }).catch(function () {});
    },

    /* 获取所属分类下拉 */
    getChooseList: function getChooseList() {
      var _this2 = this;

      api.GET_CHOOSE_LIST().then(function (res) {
        _this2.form.config[0].children[4].options = res;
        /* 点击修改跳过来的 */

        if (_this2.isEdit) {
          _this2.getDetail(_this2.$route.query.id);
        }
      });
    },
    init: function init() {
      this.getChooseList();
    }
  }
};