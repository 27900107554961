//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '合同编号',
  prop: 'contractCd',
  width: '150px'
}, {
  label: '客户',
  prop: 'customerName',
  width: '80px'
}, {
  label: '机构',
  prop: 'orgName',
  width: '210px'
}, {
  label: '自费',
  prop: 'selfPayingText',
  width: '60px'
}, {
  label: '礼品',
  prop: 'giftName',
  width: '190px'
}, {
  label: '礼品价格',
  prop: 'giftPrice',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '礼品数量',
  prop: 'num',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '礼品状态',
  prop: 'status',
  width: '120px',
  type: 'slot'
}, {
  label: '申请日期',
  prop: 'createDate',
  width: VAL_WIDTH
}, {
  label: '分公司备注',
  prop: 'filialeRemark',
  width: '120px'
}, {
  label: '产品',
  prop: 'productName',
  width: '150px'
}, {
  label: '合同状态',
  prop: 'contractStateText',
  width: '120px'
}, {
  label: '快递',
  prop: 'expressCompanyName',
  width: VAL_WIDTH
}, {
  label: '单号',
  prop: 'expressNo',
  width: '150px'
}, {
  label: '收件人',
  prop: 'receiver',
  width: VAL_WIDTH
}, {
  label: '发货日期',
  prop: 'provideDate',
  width: VAL_WIDTH
}];