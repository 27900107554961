


























































































































export default function (Component) {
  Component.options.__source = "src/views/rm/system/system/person/components/dialog-dimission.vue"
}
