




















































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/setup/documentTemplateSearch/index.vue"
}
