var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        top: "10vh",
        width: "1050px",
        title: "台帐",
        modal: false,
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c("dialog-detail", { attrs: { propsModel: _vm.propsModel.batchId } }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "contract-log-tip",
              attrs: { plain: "", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.showSummary()
                }
              }
            },
            [_vm._v("结算汇总")]
          ),
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          )
        ],
        1
      ),
      _c("dialog-summary", {
        attrs: { propsModel: _vm.batchId },
        model: {
          value: _vm.showSummaryDialog,
          callback: function($$v) {
            _vm.showSummaryDialog = $$v
          },
          expression: "showSummaryDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }