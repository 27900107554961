var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c(
        "el-row",
        { staticClass: "page-table-container", attrs: { type: "flex" } },
        [
          _c(
            "el-col",
            { staticClass: "list-container", attrs: { span: 24 } },
            [
              _c("dynamic-form", {
                ref: "form",
                attrs: { props: _vm.filterProps, forms: _vm.filterForms }
              }),
              _c(
                "el-row",
                {
                  staticClass: "actions",
                  attrs: { type: "flex", justify: "space-between" }
                },
                [
                  _c("div"),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-refresh" },
                          on: {
                            click: function($event) {
                              return _vm.clickRefresh()
                            }
                          }
                        },
                        [_vm._v("刷新")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            plain: "",
                            size: "mini",
                            icon: "el-icon-bottom-left"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickReset()
                            }
                          }
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            plain: "",
                            size: "mini",
                            icon: "el-icon-search"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickSearch()
                            }
                          }
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "success",
                            plain: "",
                            size: "mini",
                            icon: "el-icon-download"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickExport()
                            }
                          }
                        },
                        [_vm._v("Excel")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("dynamic-table", {
                ref: "jTable",
                attrs: {
                  table: _vm.table,
                  pagination: _vm.pagination,
                  data: _vm.tableData,
                  loading: _vm.loading
                },
                on: { load: _vm.loadData, "current-change": _vm.selectRow },
                scopedSlots: _vm._u([
                  {
                    key: "operation",
                    fn: function() {
                      return [
                        _c("el-table-column", {
                          attrs: { prop: "date", label: "日期", width: "150" }
                        }),
                        _vm._l(_vm.arrayProducname, function(item, index) {
                          return _c(
                            "el-table-column",
                            {
                              key: index,
                              attrs: {
                                label: item,
                                prop: item + "sum",
                                width: "250"
                              }
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.tableData[0][item]) + " "
                                )
                              ])
                            ],
                            2
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "totalsum",
                            label: "总计",
                            width: "150"
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }