

















































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/integralGift/integralDetail/index.vue"
}
