//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import EXPRESS from "@/maps/enum/express";
import dialogMixin from "@/mixins/dialog";
import { listExpressContract } from "@api/crm/paper/paper";
export default {
  name: "paper-express-info",
  props: {
    propsModel: {
      type: Object,
      default: {}
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tableData: [],
      //表数据   
      expressUrl: EXPRESS.SF.searchUrl + this.propsModel.expressNo,
      //快递查询
      selectData: {},
      rules: {}
    };
  },
  created: function created() {},
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    // TODO 获取列表
    loadData: function loadData() {
      var _this = this;

      var param = {
        page: {
          pageNumber: 1,
          pageSize: 999
        },
        query: {
          expressId: this.propsModel.expressId
        }
      };
      this.loading = true;
      listExpressContract(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data;
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    }
  }
};