import "core-js/modules/es.object.to-string.js";
// 到期与清算
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatUserDataPromise, formatDate } from '@/libs/crm/format';
import func from '@/maps/url/crm/budget/budget'; // TODO 列表

export function listReturnPay(params, formatType) {
  var re = request.post(func.listReturnPay, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '资金预算' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}