//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '合同编号',
  prop: 'contractCd',
  width: '150px'
}, {
  label: '客户',
  prop: 'customerName',
  width: '80px'
}, {
  label: '产品',
  prop: 'productName',
  width: '150px'
}, {
  label: '收货状态',
  prop: 'receiveStateText',
  width: '80px'
}, {
  label: '开始日期',
  prop: 'startDate',
  width: VAL_WIDTH
}, {
  label: '结束日期',
  prop: 'endDate',
  width: VAL_WIDTH
}, {
  label: '赎回日期',
  prop: 'applyDate',
  width: VAL_WIDTH
}, {
  label: '货物名称',
  prop: 'goodsName',
  width: '80px'
}, {
  label: '提取现货克重(克)',
  prop: 'goodsWeight',
  width: '150px'
}, {
  label: '提取现货合计(元)',
  prop: 'applyBackGoldAmount',
  width: '150px'
}, {
  label: '机构',
  prop: 'orgName'
}, {
  label: '赎回备注',
  prop: 'remark'
}];