var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            id: "attachTable",
            "row-key": "attachId",
            data: _vm.tableData,
            height: "350",
            "row-style": { height: "20px" },
            "cell-style": { padding: "6px 0" },
            "highlight-current-row": ""
          },
          on: { "current-change": _vm.selectRow }
        },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: { prop: "fileName", label: "附件名", width: "300" }
          }),
          _c("el-table-column", {
            attrs: { prop: "fileType", label: "类型", width: "80" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "确定删除该附件吗？" },
                        on: {
                          confirm: function($event) {
                            return _vm.clickRemove(row)
                          }
                        }
                      },
                      [
                        _vm.isAttachmentEdit
                          ? _c("el-button", {
                              attrs: {
                                slot: "reference",
                                type: "danger",
                                plain: "",
                                circle: "",
                                size: "mini",
                                icon: "el-icon-delete-solid"
                              },
                              slot: "reference"
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v("  "),
                    _c("el-button", {
                      attrs: {
                        plain: "",
                        size: "mini",
                        circle: "",
                        icon: "el-icon-search"
                      },
                      on: {
                        click: function($event) {
                          return _vm.clickBigPreview(row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.isAttachmentEdit
        ? _c(
            "el-button",
            {
              attrs: { plain: "", size: "mini", icon: "el-icon-upload" },
              on: {
                click: function($event) {
                  return _vm.clickUpload()
                }
              }
            },
            [_vm._v("上传")]
          )
        : _vm._e(),
      _c(
        "el-button",
        {
          attrs: { plain: "", size: "mini", icon: "el-icon-view" },
          on: {
            click: function($event) {
              return _vm.clickPreview()
            }
          }
        },
        [_vm._v("看图")]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "8vh",
            title: "预览",
            visible: _vm.showPdfViewer,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.showPdfViewer = $event
            },
            close: _vm.closePdf
          }
        },
        [
          _vm.pdfUrl
            ? _c("preview-pdf", {
                attrs: { pdfurl: _vm.pdfUrl },
                on: { closePdf: _vm.closePdf }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.showBigViewer
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeBigViewer,
              "url-list": _vm.bigList,
              modal: false,
              zIndex: 9999
            }
          })
        : _vm._e(),
      _c("attachment-upload", {
        attrs: {
          "upload-contract-id": _vm.attachmentContractId,
          "upload-ext-id": _vm.attachmentExtId,
          "upload-type": _vm.attachmentType,
          "upload-product-id": _vm.attachmentProductId,
          "upload-exist": _vm.tableData,
          "id-type": _vm.idType,
          "rent-ransom-type": _vm.rentRansomType
        },
        on: {
          "refresh-upload": function($event) {
            _vm.init()
            _vm.loadData()
          }
        },
        model: {
          value: _vm.showUploadDialog,
          callback: function($$v) {
            _vm.showUploadDialog = $$v
          },
          expression: "showUploadDialog"
        }
      }),
      _c("attachment-preview", {
        attrs: { "img-list": _vm.previewList },
        model: {
          value: _vm.showPreviewDialog,
          callback: function($$v) {
            _vm.showPreviewDialog = $$v
          },
          expression: "showPreviewDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }