



















































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/commission/financeManager/index.vue"
}
