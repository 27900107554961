import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import api from '@/api-new';
import { listOrgTree } from '@api/rm/org';
import dialogMixin from '@/mixins/dialog';
export default {
  name: 'dialog-person-dimission',
  props: {
    // 当前
    person: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      options: [],
      //选项
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      rules: {
        name: [{
          required: true,
          message: '请填写客户姓名',
          trigger: 'input'
        }] // amendDate: [{ required: true, message: '请填写入职日期', trigger: 'input' }],

      }
    };
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          var _this$person = this.person,
              name = _this$person.name,
              title = _this$person.title,
              orgCd = _this$person.orgCd; // console.log('orgCd', orgCd);

          this.formModel = {
            name: name,
            title: title,
            orgCd: orgCd
          };
          this.getTree();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 确定
    clickSave: function clickSave() {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          var param = {
            userId: _this2.person.personuuid
          }; //判断值是不是数组 数组取最后一位

          if (Array.isArray(_this2.formModel.orgCd)) {
            param.newOrgId = _this2.formModel.orgCd[_this2.formModel.orgCd.length - 1];
          } else {
            param.newOrgId = _this2.formModel.orgCd;
          }

          api.TRANSFERORG(param).then(function () {
            _this2.loading = false;
            _this2.showDialog = false;

            _this2.$emit('refresh');
          }).catch(function () {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    // TODO 获取机构树
    getTree: function getTree() {
      var _this3 = this;

      var _this = this;

      this.loading = true;
      var param = {// query: {
        //   enabled: YES_NO.YES.value,
        // }
      };
      listOrgTree(param).then(function (data) {
        _this3.loading = false; // console.log('data', data);

        _this3.options = data;
      }).catch(function (e) {
        _this3.loading = false;
      });
    }
  }
};