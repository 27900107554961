import "core-js/modules/es.object.to-string.js";
// 金价
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import { formatDate } from '@/libs/crm/format';
import func from '@/maps/url/crm/setup/goldPrice';
import util from '@/libs/util'; // TODO 新增

export function add(params) {
  return request.post(func.add, {
    body: params
  });
} // TODO 编辑

export function edit(params) {
  return request.post(func.edit, {
    body: params
  });
} // TODO 删除

export function remove(params) {
  return request.post(func.remove, {
    body: params
  });
} // TODO 列表

export function list(params) {
  return request.post(func.list, {
    body: params
  });
} // TODO 查询金价

export function getPrice(params) {
  return request.post(func.getPrice, {
    body: params
  });
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '金价' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}