//审核
export default {
  NOT_DO: {
    value: '0',
    text: '未审核'
  },
  YES: {
    value: '1',
    text: '同意'
  },
  NO: {
    value: '2',
    text: '不同意'
  },
  IGNORE: {
    value: '3',
    text: '忽略'
  },
  RETURN: {
    value: '4',
    text: '退回'
  }
};