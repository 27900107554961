









































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/setup/product/productCollect/index.vue"
}
