
















































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/gift/apply/delivery/components/dialog-delivery-batch.vue"
}
