var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "table-label": _vm.tableLabel,
          table: _vm.tableConfig,
          "http-config": _vm.httpConfig,
          "filters-formatter": _vm.filtersFormatter
        },
        on: {
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        }
      }),
      _c(
        "j-dialog",
        {
          attrs: { visible: _vm.dialogVisible, title: _vm.dialogTitle },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            confirm: _vm.dialogConfirm
          }
        },
        [
          _c("j-form", {
            ref: "form",
            attrs: {
              formConfig: _vm.dialogFormConfig,
              formData: _vm.dialogFormData
            },
            on: {
              "update:formData": function($event) {
                _vm.dialogFormData = $event
              },
              "update:form-data": function($event) {
                _vm.dialogFormData = $event
              },
              submit: _vm.dialogSubmit,
              change: _vm.auditStatusChange
            }
          })
        ],
        1
      ),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible2,
            title: _vm.dialogTitle2,
            height: "100px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible2 = $event
            },
            confirm: _vm.dialogConfirm2,
            close: _vm.close
          }
        },
        [
          _c(
            "div",
            { staticClass: "alertbox" },
            [
              _c("el-alert", {
                attrs: {
                  title: _vm.title,
                  type: "warning",
                  closable: false,
                  center: ""
                }
              })
            ],
            1
          ),
          _c("j-form", {
            ref: "form2",
            attrs: {
              formConfig: _vm.dialogFormConfig2,
              formData: _vm.dialogFormData2
            },
            on: {
              "update:formData": function($event) {
                _vm.dialogFormData2 = $event
              },
              "update:form-data": function($event) {
                _vm.dialogFormData2 = $event
              },
              submit: _vm.dialogSubmit2,
              change: _vm.auditStatusChangeBatch
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }