import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import dialogMixin from "@/mixins/dialog";
import { history } from "@api/crm/change/change";
import { getInfo as _getBuyInfo } from "@api/crm/buy/buy";
export default {
  name: "change-history",
  props: {
    contractId: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      loading: false,
      collapseActive: [],
      //信息折叠默认展开
      historyData: [],
      //数据
      currentModel: {},
      //当前合同
      compareData: [] //比较数据

    };
  },
  mixins: [dialogMixin],
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.list();
          this.getBuyInfo();
        }
      }
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.collapseActive = [];
      this.historyData = [];
      this.currentModel = {};
      this.compareData = [];
    },
    dialogClose: function dialogClose() {},
    openDialog: function openDialog() {},
    list: function list() {
      var _this = this;

      var param = {
        contractId: this.contractId
      };
      history(param, 0).then(function (res) {
        _this.historyData = res.data;
      });
    },
    //原合同明细
    getBuyInfo: function getBuyInfo() {
      var _this2 = this;

      var param = {
        contractId: this.contractId
      };

      _getBuyInfo(param, DATA_FORMAT_TYPE.NO).then(function (res) {
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this2.currentModel = data; //赎回实物联系方式

        if (res.data.contact != null) {
          var contact = res.data.contact;
          _this2.model.contactName = contact.name;
          _this2.model.contactMobile = contact.mobile;
          _this2.model.contactAddress = contact.address;
        }
      }).catch(function (e) {//this.loading = false;
      });
    },
    changeCollapse: function changeCollapse(val) {
      //对象可变更各变量含义
      var obj = {
        mobile: "联系方式",
        address: "住址",
        email: "email",
        orgName: "机构",
        userName: "顾问",
        amount: "合同金额",
        timeLimit: "期限",
        returnPeriod: "返还周期",
        startDate: "开始日期",
        endDate: "到期日期",
        payMethodText: "收款方式",
        annualRate: "收益率",
        contractGoldPrice: "金价",
        rentRansomTypeText: "赎回方式",
        accountTypeText: "账户类型",
        accountName: "银行户名",
        accountNo: "银行账号",
        bankName: "银行",
        remark: "合同备注"
      }; //比较选择历史数据和当前合同差别

      this.compareData = [];
      var historyModel = this.historyData[val]; //历史数据

      for (var key in historyModel) {
        var hd = historyModel[key] == null ? "" : historyModel[key];
        var cd = this.currentModel[key] == null ? "" : this.currentModel[key];

        if (obj[key] != null && hd != cd) {
          var txt = "[" + obj[key] + "]   变更前：" + hd + "   当前合同：" + cd;
          this.compareData.push(txt);
        }
      }
    }
  },
  created: function created() {}
};