//
//
//
//
//
//
//
//
import toBeReviewed from './toBeReviewed/index.vue';
import reviewedFail from './reviewedFail/index.vue';
export default {
  name: 'PageEnterpriseMembers',
  data: function data() {
    return {
      jTabData: {
        type: 'tabs',
        options: [{
          label: '待审核',
          value: 0
        }, {
          label: '审核不通过',
          value: 1
        }],
        prop: 'status',
        col: 6,
        labelWidth: 120
      },
      activeIndex: 0,
      currentComponent: toBeReviewed
    };
  },
  methods: {
    tabsChange: function tabsChange(val) {
      this.activeIndex = val.status;

      switch (this.activeIndex) {
        case 0:
          this.currentComponent = toBeReviewed; // 待审核

          break;

        case 1:
          this.currentComponent = reviewedFail; // 审核不通过

          break;
      }
    }
  }
};