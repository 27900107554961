//到期与清算
export default {
  list: '/clear/list',
  //列表
  submit: '/clear/submit',
  //审核
  auditCount: '/clear/auditCount',
  //结算审核 
  auditFinance: '/clear/auditFinance',
  //财务审核  
  exportExcel: '/clear/exportExcel' // 导出Excel

};