import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.sort.js";
export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    /* 所有菜单 */
    SYS_MENU_LIST: function SYS_MENU_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: url.sysMenuList,
        data: data
      }).then(function (res) {
        res.map(function (item) {
          item.value = item.id;
          item.label = item.title;
          return item;
        });
        return res;
      });
    },

    /* 菜单删除 */
    SYS_MENU_DELETE: function SYS_MENU_DELETE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: url.sysMenuDelete,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /* 菜单详情 */
    SYS_MENU_DETAIL: function SYS_MENU_DETAIL() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: url.sysMenuDetail,
        data: data
      }).then(function (res) {
        if (res.systemApiId) {
          res.systemApiId = res.systemApiId.split(';').map(Number);
        }

        return {
          id: res.id,
          pid: res.pid,
          title: res.title,
          resourceType: res.resourceType,
          perms: res.perms,
          permissionCode: res.permissionCode,
          systemApiId: res.systemApiId,
          status: res.status,
          sort: res.sort
        };
      });
    },

    /* 菜单新增/编辑 */
    SYS_MENU_ADD_OR_UPDATE: function SYS_MENU_ADD_OR_UPDATE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: url.sysMenuAddOrUpdate,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /* 菜单拖拽 */
    SYS_MENU_DRAG: function SYS_MENU_DRAG() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: url.sysMenuDrag,
        data: data
      }).then(function (res) {
        return res;
      });
    }
  };
});