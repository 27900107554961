// 活动管理
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 财务总监
{
  path: "".concat(baseRoute, "/activity/manage"),
  name: 'activtyManager',
  meta: {
    title: '活动和直播管理',
    auth: true,
    cache: true
  },
  component: _import('crm/activity/manage/list')
}, {
  path: "".concat(baseRoute, "/activity/manage/add"),
  name: 'activityAdd',
  meta: {
    title: '新增/修改活动',
    auth: true,
    cache: true
  },
  component: _import('crm/activity/manage/add')
}, {
  path: "".concat(baseRoute, "/activity/category"),
  name: 'activityCategory',
  meta: {
    title: '活动和直播分类',
    auth: true,
    cache: true
  },
  component: _import('crm/activity/category')
}, {
  path: "".concat(baseRoute, "/activity/evaluation/template"),
  name: 'activityTemplate',
  meta: {
    title: '评价模板',
    auth: true,
    cache: true
  },
  component: _import('crm/activity/evaluation')
}, {
  path: "".concat(baseRoute, "/activity/audit"),
  name: 'activityAudit',
  meta: {
    title: '活动和直播审核',
    auth: true,
    cache: true
  },
  component: _import('crm/activity/audit')
}];