//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ElePermission from '@/store/modules/d2admin/modules/elePermission';
import api from '@/api-new';
export default {
  name: 'BasicInfo',
  components: {},
  directives: {},
  filters: {},
  mixins: [],
  props: {
    info: {
      type: Object
    }
  },
  data: function data() {
    return {
      // 内容样式
      contentStyle: {
        width: '250px'
      },
      // label样式
      labelStyle: {
        width: '150px'
      },
      formData: {},
      basicInfo: {}
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    // 判断是否拥有权限
    isHasPermission: function isHasPermission(value) {
      var permission = ElePermission.state.permission;

      if (permission && permission.length > 0) {
        return permission.indexOf(value) > -1;
      } else {
        return false;
      }
    },
    init: function init() {}
  },
  watch: {
    info: {
      handler: function handler(val) {
        this.basicInfo = val;
        this.init();
      },
      deep: true
    }
  }
};