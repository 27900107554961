var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        top: "5vh",
        width: "40%",
        title: "礼品",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabActive,
            callback: function($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "合同信息", name: "tabContract" } },
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.collapseActive,
                    callback: function($$v) {
                      _vm.collapseActive = $$v
                    },
                    expression: "collapseActive"
                  }
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "合同信息", name: "1" } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("合同编号:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.contractCd) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("购销合同:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.buyContractCd) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("状态:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.model.contractStateText) + " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("产品:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.productName))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("姓名:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.customerName))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("机构:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.orgName) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("合同金额:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.amount))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("期限:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.timeLimit) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("开始日期:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.startDate) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("年化入金:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.yearAmount) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("年化积分:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.model.yearAmountSource) + " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("结束日期:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.endDate) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "礼品申请表", name: "tabAttachment" } },
            [
              _c("attachment", {
                ref: "attachmentGiftDescribe",
                attrs: {
                  "is-attachment-edit": true,
                  "attachment-contract-id": _vm.propsModel.contractId,
                  "attachment-type": _vm.attachmentGiftDescribe
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("礼品")
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "礼品", prop: "giftId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.giftId,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "giftId", $$v)
                            },
                            expression: "formModel.giftId"
                          }
                        },
                        _vm._l(_vm.selectData.gift, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "数量", prop: "num" } },
                    [
                      _c("el-input", {
                        attrs: { type: "number", disabled: true },
                        model: {
                          value: _vm.formModel.num,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "num", $$v)
                          },
                          expression: "formModel.num"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "特殊申请", prop: "apply" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择",
                            disabled: true
                          },
                          model: {
                            value: _vm.formModel.apply,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "apply", $$v)
                            },
                            expression: "formModel.apply"
                          }
                        },
                        _vm._l(_vm.selectData.apply, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "自费金额", prop: "price" } },
                    [
                      _c("el-input", {
                        attrs: { type: "number" },
                        model: {
                          value: _vm.formModel.price,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "price", $$v)
                          },
                          expression: "formModel.price"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "filialeRemark" } },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.filialeRemark,
                            expression: "formModel.filialeRemark"
                          }
                        ],
                        staticClass: "el-textarea__inner",
                        attrs: { placeholder: "请填写审核意见" },
                        domProps: { value: _vm.formModel.filialeRemark },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formModel,
                              "filialeRemark",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "contract-log-tip",
              attrs: { plain: "", size: "mini", icon: "el-icon-finished" },
              on: {
                click: function($event) {
                  return _vm.showLog()
                }
              }
            },
            [_vm._v("审核日志")]
          ),
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  return _vm.clickConfirm()
                }
              }
            },
            [_vm._v("提 交")]
          )
        ],
        1
      ),
      _c("contract-log", {
        attrs: {
          "contract-id": _vm.propsModel.contractId,
          active: _vm.contractLogType
        },
        model: {
          value: _vm.showLogDialog,
          callback: function($$v) {
            _vm.showLogDialog = $$v
          },
          expression: "showLogDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }