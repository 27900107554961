//金价
export default {
  add: '/goldPrice/add',
  // 新增
  edit: '/goldPrice/edit',
  // 编辑
  remove: '/goldPrice/remove',
  // 删除   
  list: '/goldPrice/list',
  // 列表 
  getPrice: '/goldPrice/getRenewPrice',
  // 查询续约金价，如果节假日，查找下一个工作日金价 
  exportExcel: '/goldPrice/exportExcel' // 导出excel

};