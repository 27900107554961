import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
// 查询条件
var PUBLIC_CONFIG = {
  labelWidth: 80
}; // 表格列宽

var PUBLIC_TABLE_LABEL_CONFIG = {
  width: 150
};
export var TOP_FORM = {
  giftName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '礼品',
    props: {
      placeholder: '请输入礼品名称'
    }
  }),
  customerName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户',
    props: {
      placeholder: '请输入客户名称'
    }
  }),
  approvalStatus: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '审批状态',
    col: 6,
    props: {
      placeholder: '请选择状态'
    },
    options: []
  }),
  createTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '兑换时间',
    col: 6,
    propGroup: ['beginExchangeTime', 'endExchangeTime'],
    props: {
      type: 'daterange'
    }
  }),
  orgCd: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    label: '部门',
    type: 'cascader',
    options: [],
    props: {
      placeholder: '请选择部门',
      filterable: true,
      props: {
        checkStrictly: true,
        emitPath: false,
        value: 'orgCd',
        label: 'orgName',
        children: 'children'
      }
    }
  })
};
export var TABLE_LABEL = [_objectSpread(_objectSpread({
  label: '礼品图片',
  prop: 'giftPicture',
  type: 'img'
}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  formatter: function formatter(row) {
    return "".concat(process.env.VUE_APP_CLOUD_UPLOAD).concat(row.giftPicture);
  }
}), _objectSpread({
  label: '客户编码',
  prop: 'customerCode',
  type: 'link',
  sortable: 'examineAndApprove',
  sortProp: 'customerCode'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '名称',
  prop: 'giftName',
  sortable: 'examineAndApprove',
  sortProp: 'giftName'
}, PUBLIC_TABLE_LABEL_CONFIG), {
  width: 200,
  label: '兑换单号',
  prop: 'exchangeCode',
  sortable: 'examineAndApprove',
  sortProp: 'exchangeCode'
}, _objectSpread({
  label: '分类',
  prop: 'giftClassificationName',
  sortable: 'examineAndApprove',
  sortProp: 'giftClassificationName'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '分值',
  prop: 'giftScore',
  sortable: 'examineAndApprove',
  sortProp: 'giftScore'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '单价/元',
  prop: 'giftPrice',
  sortable: 'examineAndApprove',
  sortProp: 'giftPrice'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '数量',
  prop: 'exchangeAmount',
  sortable: 'examineAndApprove',
  sortProp: 'exchangeAmount'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '总积分',
  prop: 'exchangeTotalScore',
  sortable: 'examineAndApprove',
  sortProp: 'exchangeTotalScore'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '拒绝原因',
  prop: 'rejectReason'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '客户',
  prop: 'customerName',
  sortable: 'examineAndApprove',
  sortProp: 'customerName'
}, PUBLIC_TABLE_LABEL_CONFIG), {
  label: '机构',
  prop: 'customerOrgName',
  width: 250,
  sortable: 'examineAndApprove',
  sortProp: 'customerOrgName'
}, _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '取件方式',
  prop: 'pickingMethod',
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 1:
        res = '自取';
        break;

      case 2:
        res = '直邮';
        break;

      default:
        res = '';
    }

    return res;
  }
}), _objectSpread({
  label: '收件人',
  prop: 'receiver'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '收件人电话',
  prop: 'receiverMobile'
}, PUBLIC_TABLE_LABEL_CONFIG), {
  label: '收件地址',
  prop: 'receiverAddress',
  width: 300
}, {
  label: '留言',
  prop: 'remark',
  width: 200
}, _objectSpread({
  label: '兑换时间',
  prop: 'exchangeTime',
  sortable: 'giftClassify',
  sortProp: 'exchangeTime'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '审批状态',
  prop: 'approvalStatusValue',
  sortable: 'examineAndApprove',
  sortProp: 'approvalStatusValue'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '兑换状态',
  prop: 'exchangeStatusValue',
  sortable: 'examineAndApprove',
  sortProp: 'exchangeStatusValue'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  type: 'operation',
  label: '操作',
  fixed: 'right',
  formatter: function formatter(row) {
    var arr = [];

    if (row.approvalStatus === '1') {
      arr.push({
        type: 'customerServiceApproval',
        label: '审核',
        permission: 'giftExchange-customerServiceApproval'
      }, {
        type: 'cancel',
        label: '取消',
        permission: 'giftExchange-cancelled'
      });
    } else if (row.approvalStatus === '2') {
      arr.push({
        type: 'financeApproval',
        label: '审核',
        permission: 'giftExchange-financeApproval'
      });
    } else if (row.approvalStatus === '3') {
      arr.push({
        type: 'customerServiceApproval',
        label: '重新审核',
        permission: 'giftExchange-customerServiceApproval'
      }, {
        type: 'cancel',
        label: '取消',
        permission: 'giftExchange-cancelled'
      });
    } else if (row.approvalStatus === '5') {
      arr.push({
        type: 'financeApproval',
        label: '重新审核',
        permission: 'giftExchange-financeApproval'
      }, {
        type: 'cancel',
        label: '取消',
        permission: 'giftExchange-cancelled'
      });
    }

    return arr;
  }
})];
export var TABLE_CONFIG = {
  isSelect: true,
  rowKey: 'id',
  actions: [{
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'giftExchange-export'
  }, {
    label: '客服批量审批',
    type: 'customerServiceBatchApproval',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'giftExchange-customerServiceBatchApproval'
  }, {
    label: '财务批量审批',
    type: 'financeBatchApproval',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'giftExchange-financeBatchApproval'
  }, {
    label: '新增',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'giftExchange-add'
  }]
};