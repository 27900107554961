







































































































































export default function (Component) {
  Component.options.__source = "src/views/rm/system/system/team/audit/components/dialog-info.vue"
}
