
































export default function (Component) {
  Component.options.__source = "src/components/d2-container/components/d2-container-full.vue"
}
