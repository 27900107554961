var orgUrl = {
  /** 组织管理 */
  orgManager: {
    /* 组织架构 */
    framework: {
      /* 组织架构列表 */
      listOrganization: '/alumniManage/organization/listOrganization',

      /* 组织架构组织详情 */
      getDetailOrganization: '/alumniManage/organization/getOrganization',

      /* 顶级组织绑定人数 */
      getTopBindNum: '/alumniManage/organization/getTopBindNum',

      /* 组织架构新增或修改 */
      addOrUpdateOrganization: '/alumniManage/organization/addOrUpdateOrganization',

      /* 组织架构状态修改 */
      updateStatus: '/alumniManage/organization/updateStatus',

      /* 组织架构删除 */
      deleteOrganization: '/alumniManage/organization/deleteOrganization',

      /* 组织架构审核状态修改 */
      updateIsAudit: '/alumniManage/organization/updateIsAudit',

      /* 组织架构置顶修改 */
      updateIsTop: '/alumniManage/organization/updateIsTop'
    },

    /* 组织成员 */
    member: {
      /* 组织成员列表 */
      listOrganizationMember: '/alumniManage/organizationMember/listOrganizationMember',

      /* 组织成员新增或修改 */
      addOrUpdateOrganizationMember: '/alumniManage/organizationMember/addOrUpdateOrganizationMember',

      /* 组织成员删除 */
      deleteOrganizationMember: '/alumniManage/organizationMember/deleteOrganizationMember',

      /* 组织成员审核列表 */
      listOrganizationUserApplyRecord: '/alumniManage/organizationUserApplyRecord/listOrganizationUserApplyRecord',

      /* 组织成员审核 */
      applyOrganizationUser: '/alumniManage/organizationUserApplyRecord/applyOrganizationUser',

      /* 删除组织成员申请 */
      deleteOrganizationUser: '/alumniManage/organizationUserApplyRecord/deleteOrganizationUser',

      /* 头部统计数据 */
      statisticalData: '/alumniManage/organizationUserApplyRecord/statisticalData',

      /* 修改排序值 */
      updataSort: '/alumniManage/organizationMember/updataSort',

      /* 组织成员导出excel */
      exportOrganizationMember: '/alumniManage/organizationMember/exportOrganizationMember'
    },

    /* 组织职务 */
    job: {
      /* 组织职务列表 */
      listOrganizationJob: '/alumniManage/job/listJob',

      /* 获取职务类型 */
      addOrUpdateOrganizationJob: '/alumniManage/job/addOrUpdateJob',

      /* 组织职务添加或修改 */
      getAllJobType: '/alumniManage/job/getAllJobType',

      /* 组织职务排序 */
      sortOrganizationJob: '/alumniManage/job/updateSort',

      /* 组织职务删除 */
      deleteOrganizationJob: '/alumniManage/job/deleteJob',

      /* 组织职务状态修改 */
      updateStatus: '/alumniManage/job/updateStatus'
    },

    /* 班级管理 */
    class: {
      /* 组织班级列表 */
      listGrade: '/alumniManage/grade/listGrade',

      /* 组织班级添加或修改 */
      addOrUpdateGrade: '/alumniManage/grade/addOrUpdateGrade',

      /* 组织班级修改状态值 */
      updateStatus: '/alumniManage/grade/updateStatus',

      /* 组织班级删除 */
      deleteGrade: '/alumniManage/grade/deleteGrade',

      /* 组织班级修改排序值 */
      updataSort: '/alumniManage/grade/updataSort'
    },

    /* 意见反馈 */
    feedback: {
      /* 组织意见列表 */
      listOpinion: '/alumniManage/opinion/listOpinion',

      /* 组织意见删除 */
      deleteOpinion: '/alumniManage/opinion/deleteOpinion'
    }
  }
};
export default orgUrl;