import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AUDIT_PROCESS from '@/maps/enum/audit-process';
import COLOR from '@/maps/enum/color';
import STATUS from '@/maps/enum/status/interest';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import PRODUCT_COUNT_TYPE from '@/maps/enum/product-count-type';
import { FILTER_PROPS, FILTER_FORMS } from './option/filter'; // 过滤条件的form

import TABLE_LIST from './option/list'; // 表头

import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import DialogAudit from './components/dialog-audit';
import DialogInfo from '../dialog-info';
import { formatDate } from '@/libs/crm/format';
import { list, exportExcel, create as createCount } from '@api/crm/interest/interest';
import SearchContract from '@/components/business/search-contract';
export default {
  name: 'interest-new',
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogAudit: DialogAudit,
    DialogInfo: DialogInfo,
    SearchContract: SearchContract
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {},
      pagination: {},
      process: AUDIT_PROCESS.NEW.value,
      //当前审核流程
      query: {
        arrayProductCountType: [PRODUCT_COUNT_TYPE.FIXED_DATE_GOLD.value, PRODUCT_COUNT_TYPE.FIXED_DATE.value] //固定日产品

      },
      tableData: [],
      showAuditDialog: false,
      // 审核窗口
      showInfoDialog: false,
      // 展示信息
      showSearchContractDialog: false,
      // 展示查找合同
      dialogAddVisible: false,
      // 新增
      currentRow: {},
      // 当前点击行
      canEditStatus: [STATUS.SAVE.value, STATUS.SERVICE_AUDIT_FAILURE.value, STATUS.COUNT_AUDIT_FAILURE.value, STATUS.FINANCE_AUDIT_FAILURE.value, STATUS.FINANCE_MANAGER_AUDIT_FAILURE.value, STATUS.MONEY_AUDIT_FAILURE.value],
      //可以编辑的状态
      paramAdd: {
        description: '',
        currentTimes: null,
        contractId: ''
      }
    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);

      if (this.query.arrayProductCountType.length == 0) {
        //默认值
        this.query.arrayProductCountType = [PRODUCT_COUNT_TYPE.FIXED_DATE_GOLD.value, PRODUCT_COUNT_TYPE.FIXED_DATE.value];
      }
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      var param = {
        process: this.process,
        page: page,
        query: this.query
      };
      this.loading = true;
      list(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.tableData = data;
          _this.pagination = {
            total: totalCount
          };
        } else {
          _this.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    // TODO
    clickAudit: function clickAudit() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currentRow = row;
      this.showAuditDialog = true;
    },
    //查看详情
    clickInfo: function clickInfo() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currentRow = row;
      this.showInfoDialog = true;
    },
    clickExport: function clickExport() {
      var _this2 = this;

      this.$confirm('确定按查询条件导出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.initFilter();

        var param = {
          process: _this2.process,
          query: _this2.query
        };
        exportExcel(param).catch(function (e) {
          _this2.$message({
            message: e,
            type: 'error'
          });
        });
      }).catch(function () {});
    },
    setStatusColor: function setStatusColor(status) {
      //状态颜色
      var className = '';

      switch (status) {
        case STATUS.SAVE.value:
          className = COLOR.BLUE;
          break;

        case STATUS.SUBMIT.value:
          className = COLOR.GREEN;
          break;

        case STATUS.SERVICE_AUDIT_FAILURE.value:
          className = COLOR.RED;
          break;

        case STATUS.COUNT_AUDIT_FAILURE.value:
          className = COLOR.RED;
          break;

        case STATUS.FINANCE_AUDIT_FAILURE.value:
          className = COLOR.RED;
          break;

        case STATUS.FINANCE_MANAGER_AUDIT_FAILURE.value:
          className = COLOR.RED;
          break;

        case STATUS.MONEY_AUDIT_FAILURE.value:
          className = COLOR.RED;
          break;
      }

      return className;
    },
    clickAdd: function clickAdd() {
      this.showSearchContractDialog = true;
    },
    popSelectContract: function popSelectContract(contract) {
      this.dialogAddVisible = true;
      this.paramAdd.contractId = contract.contractId;
      this.paramAdd.contractCd = contract.contractCd;
    },
    // 处理提交逻辑
    dialogAddSubmit: function dialogAddSubmit() {
      var _this3 = this;

      if (this.paramAdd.currentTimes === null || this.paramAdd.currentTimes === '') {
        return this.$message.error('请填写付息次数');
      } else if (this.paramAdd.description === null || this.paramAdd.description === '') {
        return this.$message.error('请填写新增原因');
      }

      createCount(this.paramAdd).then(function (res) {
        _this3.loading = false;

        _this3.$message({
          type: 'success',
          message: '新增成功!'
        });

        _this3.init();
      }).catch(function () {
        _this3.loading = false;
      });
      this.dialogAddCancel();
    },
    dialogAddCancel: function dialogAddCancel() {
      this.dialogAddVisible = false;
      this.paramAdd = {};
    }
  },
  created: function created() {}
};