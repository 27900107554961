var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig
        },
        on: {
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        },
        scopedSlots: _vm._u([
          {
            key: "backgroundColor",
            fn: function(scope) {
              return [
                _c("div", {
                  staticClass: "color-block",
                  style: {
                    background: scope.row.backgroundColor,
                    width: "40px",
                    height: "40px"
                  }
                })
              ]
            }
          }
        ])
      }),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: _vm.dialogTitle,
            height: "100%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            confirm: _vm.dialogConfirm
          }
        },
        [
          _c("j-form", {
            ref: "form",
            attrs: {
              formConfig: _vm.dialogFormConfig,
              formData: _vm.dialogFormData
            },
            on: {
              "update:formData": function($event) {
                _vm.dialogFormData = $event
              },
              "update:form-data": function($event) {
                _vm.dialogFormData = $event
              },
              submit: _vm.dialogSubmit
            },
            scopedSlots: _vm._u([
              {
                key: "backgroundColor",
                fn: function() {
                  return [
                    _c(
                      "el-form-item",
                      {
                        staticClass: "normsValArr",
                        attrs: {
                          label: "标签颜色",
                          size: "small",
                          prop: "backgroundColor",
                          rules: [
                            {
                              required: true,
                              message: "标签颜色不能为空",
                              trigger: "blur"
                            }
                          ]
                        }
                      },
                      [
                        _c("el-color-picker", {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.dialogFormData.backgroundColor,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.dialogFormData,
                                "backgroundColor",
                                $$v
                              )
                            },
                            expression: "dialogFormData.backgroundColor"
                          }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }