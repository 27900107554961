import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AttachmentAudioPlayer from "@/components/business/attachment/audio-player";
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import dialogMixin from "@/mixins/dialog";
import util from "@/libs/util.js";
import ROLE from "@/maps/enum/role";
import visitMap from "@/maps/url/crm/buy/visit";
import { getLoginUserInfo } from "@api/common/account";
import { listVisitLog, download } from "@api/crm/buy/visit";
export default {
  name: "buy-visit-info",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    AttachmentAudioPlayer: AttachmentAudioPlayer
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tableData: [],
      //表格内容
      isShowVideo: true,
      //是否显示录音（分公司不显示）
      showAudioPlayerDialog: false,
      //打开音频播放器
      audio: {
        //录音播放参数
        url: "",
        //地址
        param: {},
        //参数
        audioName: "" //录音名称

      }
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
          /*          
          let userInfo = getLoginUserInfo();//当前登入用户信息
          if(userInfo!=null) {                     
            let role = userInfo.role;//当前用户角色            
            if(role.indexOf(ROLE.GUEST)>-1 || role.indexOf(ROLE.ORG_SERVICE)>-1 || role.indexOf(ROLE.ORG_HR)>-1) {//游客，分公司，不显示录音
              this.isShowVideo = false;
            }            
          }  
          */
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.model = {};
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    // TODO 确定
    loadData: function loadData() {
      var _this = this;

      var param = {
        applyId: this.propsModel.id
      };
      this.loading = true;
      listVisitLog(param, DATA_FORMAT_TYPE.NO).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = res.data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var item = _step.value;
              var row = {
                id: item.id,
                appointDateTime: item.appointDate == null ? "" : item.appointDate + " " + item.timeStart + "~" + item.timeEnd,
                applyDesc: item.applyDesc,
                statusText: item.statusText,
                customerEvaluate: item.customerEvaluate,
                fileName: item.fileName,
                creator: item.creator,
                createTime: item.createTime.substr(0, 10)
              };

              _this.tableData.push(row);
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    //下载
    clickDownload: function clickDownload(row) {
      var _this2 = this;

      var param = {
        id: row.id,
        fileName: row.fileName
      };
      download(param).catch(function (e) {
        _this2.$message({
          message: e,
          type: "error"
        });
      });
    },
    //播放
    clickPlay: function clickPlay(row) {
      this.audio = {
        url: visitMap.open,
        param: {
          id: row.id
        },
        audioName: row.fileName
      };
      this.showAudioPlayerDialog = true;
    }
  }
};