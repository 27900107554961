//合同录入
export default {
  getLock: '/lock/con/get',
  // 获取锁单信息
  lock: '/lock/con/lock',
  // 合同上锁
  unlock: '/lock/con/unlock',
  // 合同解锁
  save: '/contract/save',
  // 暂存
  submit: '/contract/submit',
  // 提交    
  remove: '/contract/remove',
  // 删除   
  list: '/contract/list',
  // 列表 
  listBase: '/contract/listBase',
  // 列表基本信息
  getInfo: '/contract/getInfo',
  // 详情 
  isContractCdExist: '/contract/isContractCdExist',
  // 检查合同编号是否存在
  computeEarnings: '/contract/computeEarnings',
  // 试算收益
  auditService: '/contract/auditService',
  //客服审核
  auditFinance: '/contract/auditFinance',
  //财务审核
  auditMoney: '/contract/auditMoney',
  //资金审核
  exportExcelCount: '/contract/exportExcelCount',
  // 导出结算excel    
  exportExcelContractStart: '/contract/exportExcelContractStart',
  // 启动合同Excel导出数据封装任务
  exportExcelJYFJewelrySaleTemplate: '/contract/exportExcelJYFJewelrySaleTemplate',
  // 导出SAP首饰销售订单模板
  exportExcelJYFJewelryInheritSaleTemplate: '/contract/exportExcelJYFJewelryInheritSaleTemplate',
  // 导出SAP传承金订单模板
  exportExcelSAPSaleTemplate: '/contract/exportExcelSAPSaleTemplate',
  // 导出SAP销售订单模板
  exportExcelSAPInvoiceTemplate: '/contract/exportExcelSAPInvoiceTemplate',
  // 导出SAP发票模板
  listGoldWeightDetail: '/contract/listGoldWeightDetail',
  // 黄金克重明细列表
  newContractCd: '/contract/newContractCd',
  // 生成合同编号
  checkExcelProcess: '/contract/checkExcelProcess',
  // 检查Excel进度
  exportExcelAll: '/contract/exportExcelAll' // 导出全部excel

};