//有效性
export default {
  NEW: {
    value: '0',
    text: '待生效'
  },
  YES: {
    value: '1',
    text: '生效中'
  },
  NO: {
    value: '2',
    text: '停用'
  }
};