import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
// TODO 校验手机号格式是否正确
export default function (rule, value, callback) {
  var _rule$required = rule.required,
      required = _rule$required === void 0 ? false : _rule$required;
  var reg = /^1(3|4|5|6|7|8|9)\d{9}$/;

  if (required && !value) {
    callback(new Error('手机号不能为空'));
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确的手机号'));
  } else {
    callback();
  }
}