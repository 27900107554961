var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "member" },
    [
      _c("j-tabs", {
        attrs: { dataObj: _vm.jTabData },
        on: { change: _vm.tabsChange }
      }),
      _c(
        "div",
        { staticClass: "component-wrap" },
        [_c(_vm.currentComponent, { key: _vm.activeIndex, tag: "component" })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }