//查询条件
import ENUM from '@/maps/enum/enum';
import PRODUCT_COUNT_TYPE from '@/maps/enum/product-count-type';
import { formatDate } from '@/libs/crm/format';
import { listCacheOrg, listCacheProduct } from '@api/common/cache';
import { getEnumList } from '@api/common/common';
var VAL_WIDTH = '150px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '合同编号',
  valueKey: 'contractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '客户',
  valueKey: 'customerName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '产品',
  valueKey: 'arrayProductId',
  value: [],
  type: 'select',
  method: listCacheProduct,
  params: {},
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '机构名称',
  valueKey: 'orgCd',
  value: '',
  type: 'cascader',
  method: listCacheOrg,
  params: {},
  options: [],
  props: {
    //expandTrigger: 'hover',
    checkStrictly: true,
    emitPath: false,
    showAllLevels: false,
    value: 'orgCd',
    label: 'orgName',
    children: 'children'
  }
}, {
  label: '付息日期',
  valueKey: 'settlementsDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'settlementsDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '结算属性',
  valueKey: 'arrayProductCountType',
  value: [],
  type: 'select',
  options: filterProductCountType(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '赎回方式',
  valueKey: 'rentRansomType',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_0251
  }
}, {
  label: '合同期限',
  valueKey: 'timeLimit',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_0201
  }
}, {
  label: '返还周期',
  valueKey: 'returnPeriod',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_0202
  }
} // {
//   label: '结算属性',
//   valueKey: 'arrayProductCountType',
//   value: [],
//   type: 'select',
//   options: [],
//   props: {
//     clearable: true,
//     style: {
//       width: VAL_WIDTH,
//     },
//     multiple: true,
//   },
//   method: getEnumList,
//   params: {
//     // 请求时用的参数
//     enumCd: ENUM.ENUM_CD_PRODUCT_COUNT_TYPE,
//   },
// },
//{
//     label: '费用扣除类型',
//     valueKey: 'feeDeductType',
//     value: '',
//     type: 'select',
//     options: [],
//     props: {
//         clearable: true,
//         style: {
//             width: VAL_WIDTH,
//         },
//     },
//     method: getEnumList,
//     params: { // 请求时用的参数
//         enumCd: ENUM.ENUM_CD_DEDUCT_TYPE,
//     },
// }
];
/**
 * 默认付息日开始
 * @returns 
 
function initSettlementsDateBegin() {
    let rs = formatDate(new Date());
    return rs;
};*/

/**
 * 默认付息日结束
 * @returns 
 
function initSettlementsDateEnd() {
    let date = new Date();
    date.setDate(date.getDate() + 30);
    let rs = formatDate(date);
    return rs;
};*/

/**
 * 产品结算属性
 * @returns
 */

function filterProductCountType() {
  var array = [PRODUCT_COUNT_TYPE.FIXED_DATE_GOLD, PRODUCT_COUNT_TYPE.FIXED_DATE];
  return array;
}