import "core-js/modules/es.array.concat.js";
// 弹窗中form表单配置
export var DIALOG_FORM_CONFIG = {
  activityTypeText: {
    type: 'text',
    label: '活动类型'
  },
  activityTitle: {
    type: 'text',
    label: '活动标题'
  },
  classifyText: {
    type: 'text',
    label: '活动分类'
  },
  linkUrl: {
    type: 'text',
    label: '活动链接'
  },
  mainPicture: {
    label: '活动主图',
    type: 'slot',
    slotName: 'mainPicture',
    formatter: function formatter(row, column, cellValue) {
      return "".concat(process.env.VUE_APP_CLOUD_UPLOAD).concat(row.mainPicture);
    }
  },
  // orgName: {
  //   type: 'text',
  //   label: '主办方',
  // },
  time: {
    type: 'text',
    label: '活动时间'
  },
  budget: {
    type: 'text',
    label: '活动预算'
  },
  remark: {
    type: 'text',
    label: '备注'
  },
  isAppointmentText: {
    type: 'text',
    label: '是否可预约',
    formatter: function formatter(row, column, cellValue) {
      return cellValue === 1 ? '是' : '否';
    }
  },
  // appointmentTime: {
  //   type: 'text',
  //   label: '预约时间',
  // },
  isSignInText: {
    type: 'text',
    label: '是否可签到'
  },
  signInTime: {
    type: 'text',
    label: '签到时间'
  },
  isScoresText: {
    type: 'text',
    label: '活动积分'
  },
  commentScores: {
    type: 'text',
    label: '评价积分'
  },
  signInScores: {
    type: 'text',
    label: '签到积分'
  }
};
export var DIALOG_FORM_DATA = {
  id: null,
  title: '',
  organizationName: '',
  releaseStatus: 1,
  failReviewReason: ''
};