var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '大区',
  prop: 'orgName',
  width: '250'
}, {
  label: '周期',
  prop: 'timeLimit',
  width: '80'
}, {
  label: '比例',
  prop: 'percent1',
  width: '150'
}, {
  label: '合同金额',
  prop: 'sumAmount',
  width: '100'
}, {
  label: '责任状',
  prop: 'sumZrzAmount1',
  width: '100'
}, {
  label: '比例',
  prop: 'percent2',
  width: '100'
}, {
  label: '推介费',
  prop: 'sumCommission2',
  width: '100'
}, {
  label: '比例',
  prop: 'percent3',
  width: '100'
}, {
  label: '推介费',
  prop: 'sumCommission3',
  width: '100'
}, {
  label: '推介费合计',
  prop: 'sumCommission',
  width: '100'
}];