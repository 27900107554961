export default {
  add: '/person/add',
  // 新增用户
  edit: '/person/edit',
  // 编辑用户
  remove: '/person/remove',
  // 删除用户
  setUpAccount: '/person/setUpAccount',
  // 设置系统账户
  resetPassword: '/person/resetPassword',
  // 重置密码
  list: '/person/list',
  // 职员列表
  listRight: '/person/listRight',
  // 授权的职员列表
  getInfoBase: '/person/getInfoBase',
  // 用户基础信息
  getInfo: '/person/getInfo',
  // 用户详细详情
  exportExcel: '/person/exportExcel' // 导出excel

};