//颜色
export default {
  RED: '#DD0000',
  // 红色
  GREEN: '#008800',
  // 绿色
  BLUE: '#0099CC',
  // 蓝色
  GRAY: '#AAAAAA' // 灰色

};