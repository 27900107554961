










































































































export default function (Component) {
  Component.options.__source = "src/components/business/tree-configuration/index.vue"
}
