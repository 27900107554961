var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-search-contract",
      attrs: {
        top: "20vh",
        width: "970px",
        title: "已归档合同",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-row",
        {
          staticClass: "actions",
          attrs: { type: "flex", justify: "space-between" }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "mini" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "合同编号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "合同编号", clearable: "" },
                    model: {
                      value: _vm.model.contractCd,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "contractCd", $$v)
                      },
                      expression: "model.contractCd"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客户姓名" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "客户姓名", clearable: "" },
                    model: {
                      value: _vm.model.customerName,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "customerName", $$v)
                      },
                      expression: "model.customerName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "机构名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "机构名称", clearable: "" },
                    model: {
                      value: _vm.model.orgName,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "orgName", $$v)
                      },
                      expression: "model.orgName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        plain: "",
                        size: "mini",
                        icon: "el-icon-search"
                      },
                      on: {
                        click: function($event) {
                          return _vm.clickSearch()
                        }
                      }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            "row-style": { height: "20px" },
            "cell-style": { padding: "6px 0" },
            height: "350"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { prop: "contractCd", label: "合同号", width: "160" }
          }),
          _c("el-table-column", {
            attrs: { prop: "customerName", label: "姓名", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "productName", label: "产品", width: "160" }
          }),
          _c("el-table-column", {
            attrs: { prop: "orgName", label: "机构", width: "180" }
          }),
          _c("el-table-column", {
            attrs: { prop: "orderNo", label: "归档编号", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "archiveDate", label: "归档日期", width: "100" }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", plain: "", size: "mini" },
              on: { click: _vm.remove }
            },
            [_vm._v("移 除")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }