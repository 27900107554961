import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { listCacheOrg } from "@api/common/cache";
import { commissionOrgCd } from '@/maps/enum/status/commissionV3'; //查询条件

var VAL_WIDTH = '130px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
var isHalfMonth = [{
  text: '上半月',
  value: '上半月'
}, {
  text: '下半月',
  value: '下半月'
}];
export var FILTER_FORMS = [{
  label: '推介费月份',
  valueKey: 'planDate',
  value: '',
  type: 'date',
  props: {
    type: 'month',
    valueFormat: 'yyyy-MM',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '运营年度',
  valueKey: 'fiscalYear',
  value: '',
  type: 'date',
  props: {
    type: 'year',
    valueFormat: 'yyyy',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '推介费日期',
  valueKey: 'halfMonth',
  value: '',
  type: 'select',
  options: isHalfMonth,
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '大区',
  valueKey: 'arrayOrgCd',
  value: [],
  type: 'select',
  options: getOrgCd(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}];
/**
 * 人员汇总 
 * @returns 
 */

function getOrgCd() {
  return commissionOrgCd.filter(function (item) {
    return item.value !== '000029' && item.value !== '000254' && item.value !== '000249' && item.value !== '000026';
  });
}