// 合同认购 业绩
import request from '@/plugin/axios';
import { formatUserDataPromise } from '@/libs/crm/format';
import func from '@/maps/url/crm/buy/achievement';
/** 责任状业绩_列表 */

export function conzrzachievementList(params, formatType) {
  var res = request.post(func.conzrzachievementList, {
    body: params
  });
  formatUserDataPromise(res, formatType); // 用户数据格式化

  return res;
}
/** 责任状业绩_新增 */

export function conzrzachievementAdd(params) {
  var res = request.post(func.conzrzachievementAdd, {
    body: params
  });
  return res;
}
/** 责任状业绩_编辑 */

export function conzrzachievementUpdate(params) {
  var res = request.post(func.conzrzachievementUpdate, {
    body: params
  });
  return res;
}
/** 责任状业绩_删除 */

export function conzrzachievementRemove(params) {
  var res = request.post(func.conzrzachievementRemove, {
    body: params
  });
  return res;
}
/** 责任状业绩_新增时的页面信息 */

export function conzrzachievementGetConInfo(params) {
  var res = request.post(func.conzrzachievementGetConInfo, {
    body: params
  });
  return res;
}
/** 责任状业绩_编辑时的页面信息 */

export function conzrzachievementGetAchievementInfo(params) {
  var res = request.post(func.conzrzachievementGetAchievementInfo, {
    body: params
  });
  return res;
}
/** 基本法业绩_列表 */

export function conjbfachievementList(params, formatType) {
  var res = request.post(func.conjbfachievementList, {
    body: params
  });
  formatUserDataPromise(res, formatType); // 用户数据格式化

  return res;
}
/** 基本法业绩_新增 */

export function conjbfachievementAdd(params) {
  var res = request.post(func.conjbfachievementAdd, {
    body: params
  });
  return res;
}
/** 基本法业绩_编辑 */

export function conjbfachievementUpdate(params) {
  var res = request.post(func.conjbfachievementUpdate, {
    body: params
  });
  return res;
}
/** 基本法业绩_删除 */

export function conjbfachievementRemove(params) {
  var res = request.post(func.conjbfachievementRemove, {
    body: params
  });
  return res;
}
/** 基本法业绩_新增时的页面信息 */

export function conjbfachievementGetConInfo(params) {
  var res = request.post(func.conjbfachievementGetConInfo, {
    body: params
  });
  return res;
}
/** 基本法业绩_编辑时的页面信息 */

export function conjbfachievementGetAchievementInfo(params) {
  var res = request.post(func.conjbfachievementGetAchievementInfo, {
    body: params
  });
  return res;
}