var VAL_WIDTH = '130px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [// {
//     label: '合同编号',
//     valueKey: 'contractCd',
//     value: '',
//     type: 'input',
//     props: {
//         type: 'text',
//         style: {
//             width: VAL_WIDTH,
//         },
//     },
// },
{
  label: '导入状态',
  valueKey: 'state',
  value: '',
  type: 'select',
  options: [{
    value: '0',
    text: '失败'
  }, {
    value: '1',
    text: '成功'
  }],
  props: {
    type: 'text',
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}];