// 表头
var VAL_WIDTH = '120px';
export default [{
  label: '推介费月份',
  prop: 'planDate'
}, {
  label: '合同编号',
  prop: 'contractCd'
}, {
  label: '创建时间',
  prop: 'createTime'
}, {
  label: '机构名称',
  prop: 'orgName'
}, {
  label: '机构推介费金额',
  prop: 'paymentAmount'
}, {
  label: '支付日期',
  prop: 'paymentDate'
}];