import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//查询条件
var PUBLIC_CONFIG = {
  labelWidth: 80
};
var PUBLIC_CONFIGS = {
  labelWidth: 100
}; // 续约

export var TOP_FORM = {
  // 组织选择
  orgCds: _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
    type: 'slot',
    label: '组织',
    props: {
      placeholder: '请选择组织'
    },
    options: [],
    rules: [{
      required: true,
      message: '组织不能为空',
      trigger: 'blur'
    }]
  }),
  originType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '续约类型',
    // 字典ID 0228
    col: 6,
    props: {
      placeholder: '请选择续约类型'
    },
    options: [// {
      //   label: '其他续约(时间段)',
      //   value: '0',
      // },
    ],
    hidden: false
  }),
  agentName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '理顾姓名',
    col: 6,
    props: {
      placeholder: '请输入理顾姓名'
    }
  }),
  customerName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户姓名',
    col: 6,
    props: {
      placeholder: '请输入客户姓名'
    }
  }),
  customerCode: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户编号',
    col: 6,
    props: {
      placeholder: '请输入客户编号'
    }
  }),
  achievementDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
    type: 'datePicker',
    label: '统计日期',
    col: 6,
    propGroup: ['achievementDateStart', 'achievementDateEnd'],
    props: {
      type: 'daterange'
    } // rules: [
    //   { required: true, message: '统计日期不能为空', trigger: 'blur' },
    // ],

  }),
  contractStartDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '开始日期',
    col: 6,
    propGroup: ['contractSStartDate', 'contractSEndDate'],
    props: {
      type: 'daterange'
    }
  }),
  contractEndDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '到期日期',
    col: 6,
    propGroup: ['contractEStartDate', 'contractEEndDate'],
    props: {
      type: 'daterange'
    }
  })
}; // 到期

export var TOP_FORM2 = {
  // 组织选择
  orgCds: _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
    type: 'slot',
    label: '组织',
    props: {
      placeholder: '请选择组织'
    },
    options: [],
    rules: [{
      required: true,
      message: '组织不能为空',
      trigger: 'blur'
    }]
  }),
  agentName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '理顾姓名',
    col: 6,
    props: {
      placeholder: '请输入理顾姓名'
    }
  }),
  customerName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户姓名',
    col: 6,
    props: {
      placeholder: '请输入客户姓名'
    }
  }),
  customerCode: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户编号',
    col: 6,
    props: {
      placeholder: '请输入客户编号'
    }
  }),
  achievementDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
    type: 'datePicker',
    label: '统计日期',
    col: 6,
    propGroup: ['achievementDateStart', 'achievementDateEnd'],
    props: {
      type: 'daterange'
    } // rules: [
    //   { required: true, message: '统计日期不能为空', trigger: 'blur' },
    // ],

  }),
  contractStartDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '开始日期',
    col: 6,
    propGroup: ['contractSStartDate', 'contractSEndDate'],
    props: {
      type: 'daterange'
    }
  }),
  contractEndDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '到期日期',
    col: 6,
    propGroup: ['contractEStartDate', 'contractEEndDate'],
    props: {
      type: 'daterange'
    }
  })
}; // 到期出金

export var TABLE_LABEL = [{
  label: '序号',
  prop: 'serialNumber',
  width: 50
}, {
  label: '区域',
  prop: 'orgTypeText'
}, {
  label: '渠道分类',
  prop: 'contractLevel3OrgName'
}, {
  label: '渠道明细',
  prop: 'contractLevel5OrgName'
}, {
  label: '续约类型',
  prop: 'originTypeVal'
}, {
  label: '合同号',
  prop: 'contractCd'
}, {
  label: '顾问',
  prop: 'agentName'
}, {
  label: '客户编号',
  prop: 'customerCode'
}, {
  label: '合同金额',
  prop: 'amount'
}, {
  label: '年化金额',
  prop: 'yearAmount'
}, {
  label: '合同开始日',
  prop: 'startDate'
}, {
  label: '合同到期日',
  prop: 'endDate'
}, {
  label: '入金日期',
  prop: 'inAmountDate'
}, {
  label: '合同周期',
  prop: 'timeLimit'
}];
export var TABLE_LABEL2 = [{
  label: '序号',
  prop: 'serialNumber',
  width: 50
}, {
  label: '区域',
  prop: 'orgTypeText'
}, {
  label: '渠道分类',
  prop: 'contractLevel3OrgName'
}, {
  label: '渠道明细',
  prop: 'contractLevel5OrgName'
}, {
  label: '合同号',
  prop: 'contractCd'
}, {
  label: '顾问',
  prop: 'agentName'
}, {
  label: '客户编号',
  prop: 'customerCode'
}, {
  label: '合同金额',
  prop: 'amount'
}, {
  label: '年化金额',
  prop: 'yearAmount'
}, {
  label: '应付日期',
  prop: 'payableDate'
}, {
  label: '合同开始日',
  prop: 'startDate'
}, {
  label: '合同到期日',
  prop: 'endDate'
}, {
  label: '合同周期',
  prop: 'timeLimit'
}];
export var TABLE_CONFIG = {
  // isSelect: true,
  rowKey: 'id',
  actions: [{
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'financerenewAnaly-export'
  }]
};