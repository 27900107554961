var infoUrl = {
  info: {
    document: {
      /** 资讯列表 */
      infoList: '/manage/zbInformationBase/listInformationBase',

      /** 新增资讯 */
      addDocument: '/manage/zbInformationBase/addInformationBase',

      /** 更新资讯 */
      updateDocument: '/manage/zbInformationBase/updateInformationBase',

      /** 更新资讯状态 */
      updateStatus: '/manage/zbInformationBase/updateStatus',

      /** 逻辑删除资讯 */
      deleteById: '/manage/zbInformationBase/delete',

      /** 批量逻辑删除资讯 */
      deleteBatchByIds: '/alumniManage/information/document/deleteBatchByIds',

      /** 设置资讯是否置顶 */
      setTop: '/manage/zbInformationBase/updateIsTop',

      /** 获取资讯信息详情 */
      getInfoById: '/manage/zbInformationBase/getInfo',

      /** 获取底部信息下拉选择数据 */
      getBottomChooseList: '/alumniManage/information/bottom/getChooseList',

      /** 资讯审核或批量审核 */
      review: '/alumniManage/information/document/review',

      /** 存为草稿 */
      draft: '/manage/zbInformationBase/addInformationBase',

      /** 阅读记录 */
      readInfo: '/manage/zbInformationReadRecord/listInformationReadRecord',

      /** 新建视频 */
      addVideo: '/manage/zbInformationBase/addOrUpdateVideoInformation',

      /** 排序值 */
      sort: '/manage/zbInformationBase/updateSort'
    },
    material: {
      /** 素材库分页列表 */
      getReviewPageList: '/manage/zbInformationMaterial/listInformationMaterial',

      /** 新建素材库 */
      addInfo: '/manage/zbInformationMaterial/addOrUpdate',

      /** 素材状态修改 */
      updateStatus: '/manage/zbInformationMaterial/updateStatus',

      /** 获取素材详情 */
      getInfo: '/manage/zbInformationMaterial/getInfo'
    },
    classify: {
      /** 新增资讯分类 */
      addClassify: '/manage/zbInformationClassify/addClassify',

      /** 更新资讯分类 */
      updateClassify: '/manage/zbInformationClassify/updateClassify',

      /** 更新资讯分类状态 */
      updateStatus: '/manage/zbInformationClassify/updateStatus',

      /** 删除资讯分类 */
      deleteById: '/manage/zbInformationClassify/delete',

      /** 批量逻辑删除资讯分类 */
      deleteBatchByIds: '/alumniManage/information/classify/deleteBatchByIds',

      /** 获取资讯分类详情 */
      getInfoById: '/alumniManage/information/classify/getInfoById',

      /** 获取资讯分类树形列表 */
      getClassifyTreeList: '/manage/zbInformationClassify/getClassifyTreeList',

      /** 资讯分类下拉列表 */
      getChooseList: '/manage/zbInformationClassify/getAllInformationClassify',

      /** 获取所有一级咨讯分类 */
      listOneLevel: '/manage/zbInformationClassify/listOneLevel',

      /** 资讯分类树形下拉列表 */
      getChooseTreeList: '/alumniManage/information/classify/getChooseTreeList',

      /** 设置分类的置顶文章 */
      setDocumentTopByClassify: '/alumniManage/information/classify/setDocumentTopByClassify',

      /** 获取文章分页列表 */
      getClassifyDocumentPageList: '/alumniManage/information/classify/getClassifyDocumentPageList'
    },
    bottom: {
      /** 新增底部信息 */
      addBottom: '/alumniManage/information/bottom/addBottom',

      /** 更新底部信息 */
      updateBottom: '/alumniManage/information/bottom/updateBottom',

      /** 更新底部信息状态 */
      updateBottomStatus: '/alumniManage/information/bottom/updateBottomStatus',

      /** 获取底部信息分页列表 */
      getBottomPageList: '/alumniManage/information/bottom/getBottomPageList',

      /** 删除底部信息 */
      deleteById: '/alumniManage/information/bottom/deleteById',

      /** 批量逻辑删除底部信息 */
      deleteBatchByIds: '/alumniManage/information/bottom/deleteBatchByIds',

      /** 获取底部信息详情 */
      getBottomInfoById: '/alumniManage/information/bottom/getBottomInfoById',

      /** 更新底部信息排序值 */
      updateSort: '/manage/zbInformationClassify/updateSort'
    },
    // 每日金句
    dailySentence: {
      // 获取分页数据
      getPage: '/manage/goldenSentence/getPage',
      // 获取金句详情
      getInfo: '/manage/goldenSentence/getInfo',
      // 更新金句排序
      updateSort: '/manage/goldenSentence/updateSort',
      // 更新金句状态
      updateStatus: '/manage/goldenSentence/updateStatus',
      // 更新金句
      update: '/manage/goldenSentence/update',
      // 批量新增金句
      batchAdd: '/manage/goldenSentence/batchAdd',
      // 批量删除金句
      batchDelete: '/manage/goldenSentence/batchDelete'
    }
  }
};
export default infoUrl;