import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
// 查询条件
var PUBLIC_CONFIG = {
  labelWidth: 80
}; // 表格列宽

var PUBLIC_TABLE_LABEL_CONFIG = {
  width: 150
};
export var TOP_FORM = {
  giftName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '礼品名称',
    props: {
      placeholder: '请输入礼品名称'
    }
  }),
  classificationId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '礼品分类',
    col: 6,
    props: {
      placeholder: '请选择分类'
    },
    options: []
  }),
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '状态',
    col: 6,
    props: {
      placeholder: '请选择状态'
    },
    options: [{
      label: '启用',
      value: 1
    }, {
      label: '停用',
      value: 0
    }]
  }),
  createTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '创建时间',
    col: 6,
    propGroup: ['beginCreateTime', 'endCreateTime'],
    props: {
      type: 'daterange'
    }
  })
};
export var TABLE_LABEL = [_objectSpread(_objectSpread({
  label: '礼品图片',
  prop: 'mainPicture',
  type: 'img'
}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  formatter: function formatter(row) {
    return "".concat(process.env.VUE_APP_CLOUD_UPLOAD).concat(row.mainPicture);
  }
}), _objectSpread({
  label: '名称',
  prop: 'giftName',
  sortable: 'gift',
  sortProp: 'giftName'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '礼品分类',
  prop: 'classificationName',
  sortable: 'gift',
  sortProp: 'classificationName'
}, PUBLIC_TABLE_LABEL_CONFIG), {
  label: '礼品编码',
  prop: 'giftCode',
  sortable: 'gift',
  sortProp: 'giftCode',
  width: 110
}, {
  label: '分值',
  prop: 'score',
  sortable: 'gift',
  sortProp: 'score',
  width: 100
}, {
  label: '单价/元',
  prop: 'price',
  sortable: 'gift',
  sortProp: 'price',
  width: 100
}, {
  label: '已兑换',
  prop: 'exchangedAmount',
  sortable: 'gift',
  sortProp: 'exchangedAmount',
  width: 100
}, {
  label: '剩余库存',
  prop: 'stockAmount',
  sortable: 'gift',
  sortProp: 'stockAmount',
  width: 100
}, _objectSpread({
  label: '下架时间',
  prop: 'offTime',
  sortable: 'gift',
  sortProp: 'offTime'
}, PUBLIC_TABLE_LABEL_CONFIG), {
  label: '适用部门',
  prop: 'orgName',
  sortable: 'gift',
  sortProp: 'orgName',
  width: 300
}, {
  label: '每人兑换数量',
  prop: 'limitedExchangeAmount',
  width: 130,
  sortable: 'gift',
  sortProp: 'limitedExchangeAmount'
}, {
  label: '虚拟兑换数量',
  prop: 'fictitiousExchangeAmount',
  width: 130,
  sortable: 'gift',
  sortProp: 'fictitiousExchangeAmount'
}, {
  label: '创建时间',
  prop: 'createTime',
  width: 150,
  sortable: 'gift',
  sortProp: 'createTime'
}, _objectSpread({
  label: '创建人',
  prop: 'createUserName',
  sortable: 'gift',
  sortProp: 'createUserName'
}, PUBLIC_TABLE_LABEL_CONFIG), {
  label: '状态',
  prop: 'status',
  sortable: 'gift',
  sortProp: 'status',
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 0:
        res = '停用';
        break;

      case 1:
        res = '启用';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  label: '序号',
  prop: 'sort',
  sortable: 'gift',
  sortProp: 'sort'
}, {
  label: '备注',
  prop: 'remark',
  width: 150
}, _objectSpread(_objectSpread({
  type: 'operation',
  label: '操作',
  fixed: 'right'
}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  formatter: function formatter(row) {
    var arr = [];

    if (row.status === 1) {
      arr.push({
        type: 'compile',
        label: '编辑',
        permission: 'gift-update'
      }, {
        type: 'stop',
        label: '停用',
        permission: 'gift-updateStatus'
      });
    } else {
      arr.push({
        type: 'compile',
        label: '编辑',
        permission: 'gift-update'
      }, {
        type: 'start',
        label: '启用',
        permission: 'gift-updateStatus'
      });
    }

    return arr;
  }
})];
export var TABLE_CONFIG = {
  isSelect: true,
  rowKey: 'id',
  actions: [{
    label: '新增',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'gift-add'
  }, {
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'gift-export'
  }]
};