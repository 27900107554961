//特殊产品
export default {
  PRODUCT_1004: '1004',
  // 积存1号
  PRODUCT_1021: '1021',
  // 积存3号    
  PRODUCT_4001: '4001',
  // 金享未来
  PRODUCT_4003: '4003' // JCWL

};