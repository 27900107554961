//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import crmMixin from "@/mixins/crm";
import LOG_TYPE from "@/maps/enum/contract-log-type";
import ATTACHMENT_TYPE from "@/maps/enum/attachment-type";
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import Attachment from "@/components/business/attachment";
import AttachmentAudio from "@/components/business/attachment/audio";
import ContractLog from "@/components/business/contract-log";
import ContractGoldWeight from "@/components/business/contract-gold-weight";
import { setExtendAttribute } from "@/libs/crm/crm";
import { getInfo as _getInfo } from "@api/crm/buy/buy"; //合同认购

export default {
  name: "paper-service-info",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Attachment: Attachment,
    AttachmentAudio: AttachmentAudio,
    ContractLog: ContractLog,
    ContractGoldWeight: ContractGoldWeight
  },
  mixins: [dialogMixin, crmMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tabActive: "tabContract",
      //标签框默认选中  
      collapseActive: ["1"],
      //信息折叠默认展开
      attachmentType: ATTACHMENT_TYPE.CONTRACT_STAFF,
      //附件类型
      attachmentTypeAudio: ATTACHMENT_TYPE.CONTRACT_AUDIO,
      //附件类型：合同录音
      attachmentTypeReceptionBill: ATTACHMENT_TYPE.RECEPTION_BILL,
      //附件类型：回单凭证
      showLogDialog: false,
      contractLogType: LOG_TYPE.PAPER,
      attachmentOpened: '',
      //附件打开状态
      goldWeightDetail: {},
      //克重明细
      formModel: {},
      //表单
      rules: {
        receiveAmount: [{
          required: true,
          message: "请填写实收金额",
          trigger: "input"
        }],
        financeConfirmDate: [{
          required: true,
          message: "请填写收款日期",
          trigger: "input"
        }]
      }
    };
  },
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.getInfo();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tabActive = "tabContract"; //默认打开第一个标签页
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    onAttachmentOpen: function onAttachmentOpen() {
      //初始化标签
      this.tabActive = "tabContract";
      this.attachmentOpened = 'dialog-position';
    },
    getInfo: function getInfo() {
      var _this = this;

      var contractId = this.propsModel.contractId; //this.loading = true;

      _getInfo({
        contractId: contractId
      }, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        //this.loading = false;
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this.model = data;
        setExtendAttribute(_this.model); //包装必要展示字段              
      }).catch(function (e) {
        _this.loading = false;
      });
    },
    showGoldWeight: function showGoldWeight() {
      //显示克重明细
      this.showGoldWeightDialog = true;
    },
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    }
  }
};