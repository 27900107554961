var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-table" },
    [
      _c(
        "el-row",
        { staticClass: "page-table-container", attrs: { type: "flex" } },
        [
          _c(
            "el-col",
            { staticClass: "list-container", attrs: { span: 24 } },
            [
              _c(
                "el-row",
                {
                  staticClass: "actions",
                  attrs: { type: "flex", justify: "space-between" }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        plain: "",
                        size: "mini",
                        icon: "el-icon-plus"
                      },
                      on: {
                        click: function($event) {
                          return _vm.clickAdd({})
                        }
                      }
                    },
                    [_vm._v("新增")]
                  )
                ],
                1
              ),
              _c("el-row", { staticClass: "tip", attrs: { type: "flex" } }, [
                _vm._v(
                  " 统计归属为本部门的情况，无需在此处维护数据；系统会默认统计归属为本部门。 "
                )
              ]),
              _c(
                "dynamic-table",
                {
                  ref: "jTable",
                  attrs: {
                    headers: _vm.headers,
                    table: _vm.table,
                    pagination: _vm.pagination,
                    data: _vm.tableData,
                    loading: _vm.loading
                  },
                  on: { load: _vm.loadData, "current-change": _vm.selectRow }
                },
                [
                  _c("el-table-column", {
                    attrs: { slot: "operation", label: "操作", width: "60px" },
                    slot: "operation",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.clickEdit(row)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticClass: "btn-info",
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.remove(row)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("OrgSuperiorAdd", {
        attrs: {
          org: _vm.org,
          orgSuperior: _vm.currentRow,
          isEdit: _vm.OrgHeadAddIsEdit
        },
        on: { confirm: _vm.confirm },
        model: {
          value: _vm.OrgHeadAddShowAddDialog,
          callback: function($$v) {
            _vm.OrgHeadAddShowAddDialog = $$v
          },
          expression: "OrgHeadAddShowAddDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }