// 机构团队
import request from '@/plugin/axios';
import func from '@/maps/url/rm/team'; // TODO 列表

export function listTeam(params) {
  return request.post(func.listTeam, {
    body: params
  });
} // TODO 保存团队

export function saveTeam(params) {
  return request.post(func.saveTeam, {
    body: params
  });
} // TODO 团队删除

export function removeTeam(params) {
  return request.post(func.removeTeam, {
    body: params
  });
} // TODO 分公司成员列表

export function listOrgPerson(params) {
  return request.post(func.listOrgPerson, {
    body: params
  });
} // TODO 团队申请记录列表

export function listApplyLog(params) {
  return request.post(func.listApplyLog, {
    body: params
  });
} // TODO 团队申请

export function applyLog(params) {
  return request.post(func.applyLog, {
    body: params
  });
} // TODO 删除团队申请

export function removeLog(params) {
  return request.post(func.removeLog, {
    body: params
  });
} // TODO 审核团队申请

export function auditApplyLog(params) {
  return request.post(func.auditApplyLog, {
    body: params
  });
}