





































export default function (Component) {
  Component.options.__source = "src/views/system/index/components/d2-badge/index.vue"
}
