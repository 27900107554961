// 表头
var VAL_WIDTH = '120px';
export default [{
  label: '合同编号',
  prop: 'contractCd',
  width: '150px'
}, {
  label: '合同状态',
  prop: 'contractStateText',
  width: '100px'
}, {
  label: '客户名称',
  prop: 'customerName',
  width: '150px'
}, {
  label: '机构',
  prop: 'orgName',
  width: '250px'
}, {
  label: '产品',
  prop: 'productName',
  width: '150px'
}, {
  label: '合同金额',
  prop: 'amount',
  width: '120px',
  align: 'right'
}, {
  label: '运营年度',
  prop: 'fiscalYear',
  width: '100px'
}, {
  label: '开始日期',
  prop: 'startDate',
  width: '100px'
}, {
  label: '结束日期',
  prop: 'endDate',
  width: '100px'
}, {
  label: '期限',
  prop: 'timeLimit',
  width: '80px'
}, {
  label: '推介费状态',
  prop: 'status',
  width: '120px',
  type: 'slot'
}, {
  label: '推介费结算月份',
  prop: 'planDate',
  width: '100px'
}, {
  label: '推介费类型',
  prop: 'typeText',
  width: '150px'
}, {
  label: '是否后勤人员',
  prop: 'isBackOffice',
  width: '120px',
  type: 'slot'
}, {
  label: '总次数',
  prop: 'totalTimes',
  width: '80px'
}, {
  label: '次数',
  prop: 'currentTimes',
  width: '80px'
}, {
  label: '备注',
  prop: 'remark',
  width: '150px'
}];