var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          filtersFormatter: _vm.filtersFormatter,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        }
      }),
      _c(
        "j-dialog",
        {
          attrs: { visible: _vm.visible, title: "发货" },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            confirm: _vm.addSubmit,
            close: _vm.closejForm
          }
        },
        [
          _c("j-form", {
            ref: "jForm",
            attrs: {
              "form-config": _vm.addFormConfig,
              "form-data": _vm.addFormData,
              "foot-active": ""
            },
            on: {
              "update:formData": function($event) {
                _vm.addFormData = $event
              },
              "update:form-data": function($event) {
                _vm.addFormData = $event
              },
              submit: function($event) {
                return _vm.FormSubmit($event)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "footer",
                fn: function() {
                  return [_c("div", [_vm._v(" " + _vm._s("") + " ")])]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisibleInfo,
            width: "25%",
            modal: false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleInfo = $event
            },
            close: _vm.close
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-title",
              attrs: { slot: "title" },
              slot: "title"
            },
            [
              _c(
                "el-row",
                [
                  _c("span", { staticClass: "dialog-title-text" }, [
                    _vm._v("客户信息")
                  ]),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { type: "primary" },
                      on: { click: _vm.skip }
                    },
                    [_vm._v("跳转详情")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "el-descriptions",
                { attrs: { direction: "horizontal", column: 1, border: "" } },
                [
                  _c("el-descriptions-item", { attrs: { label: "客户姓名" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.customerName))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "性别" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.sexValue))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "年龄" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.age))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "客户电话" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.mobile))
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "家庭住址", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.address))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "客户类型", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.customerTypeValue))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "客户等级", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.customerLevelValue))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "客户年限", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.customerYearLimit))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "理顾", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.agentUserName))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "部门", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.orgName))]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.visibleImportShip,
            title: "Excel导入",
            height: "300%"
          },
          on: {
            "update:visible": function($event) {
              _vm.visibleImportShip = $event
            },
            close: _vm.hide,
            confirm: _vm.upSubmit
          }
        },
        [
          _c(
            "el-upload",
            {
              staticClass: "upload-demo",
              attrs: {
                action: _vm.uploadUrl,
                "auto-upload": false,
                accept: ".csv,.xls,.xlsx",
                "on-remove": _vm.handleRemove,
                "before-remove": _vm.beforeRemove,
                multiple: "",
                limit: 1,
                "on-exceed": _vm.handleExceed,
                "file-list": _vm.fileList,
                "on-change": _vm.handleChange,
                "before-upload": _vm.beforeUpload,
                "on-error": _vm.failed
              }
            },
            [
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("选择文件")
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip"
                },
                [
                  _vm._v(
                    " 只能上传.csv,.xls,.xlsx文件，单次只能引入一个文件！ "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "a",
            {
              staticClass: "up-Excel",
              attrs: {
                href: "/static/导入批量发货模板.xlsx",
                download: "导入批量发货模板"
              }
            },
            [_vm._v("下载导入模板")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }