




























































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/report/contract/total/index.vue"
}
