








































































































































export default function (Component) {
  Component.options.__source = "src/components/business/search-user/index.vue"
}
