






























































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/apply/financeManager/components/dialog-audit-batch.vue"
}
