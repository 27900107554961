






















































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/commission/new/components/dialog-add.vue"
}
