import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.regexp.to-string.js";
// 赎回出金打印
import { getLodop } from '@/plugin/c-lodop/LodopFuncs'; // import TABLE_DATA from '@/maps/enum/table_data';

import util from '@/libs/util.js';
import { formatDate, formatAmountToNumber, formatAmountToString, formatNumberToChinese, formatBankAccountNo } from '@/libs/crm/format';
import attachmentMap from '@/maps/url/crm/attachment/attachment';
import { list as attchmentList } from '@api/crm/attachment/attachment';
import { listLastGroup } from '@api/crm/other/contract-log'; // TODO 表格打印

export function printTable(arrayData, TABLE_DATA) {
  // 样式必须内联，写在外面不起作用
  // 判断类型 1 对日 2 固定日黄金 3固定日非黄金
  var titleType = '';
  var arrType = arrayData.map(function (i) {
    return i.productCountType;
  }); // 判断相同 true相同 false不相同

  var f = arrType.every(function (item) {
    return item == arrType[0];
  });

  if (f) {
    if (arrType[0] == '1') {
      titleType = ' 对日到期（黄金类）';
    }

    if (arrType[0] == '2') {
      titleType = ' 固定日到期（黄金类）';
    }

    if (arrType[0] == '3') {
      titleType = ' 固定日到期（非黄金类）';
    }
  }

  var total = 0; // 总金额

  var date = formatDate(new Date());
  var title = date + "".concat(titleType) + '付息清单';
  var tableHtml = '<style>' + '.table-tr {}' + '.table-th {border-top: 1px solid black;}' + '.table-border-first {border-left: 1px solid black;}' + '.table-border {border-right:1px solid black;border-bottom:1px solid black;}' + '.table-td {line-height: 24px;font-size: 12px;}' + '</style>' + "<table cellspacing='0'>" + '<thead>' + "<tr><th colspan='9' style='padding:10px 0px;font-size:15px;font-weight:bold;border:0px'>" + title + '</th></tr>' + '<tr>' + "<th class='table-th table-border table-border-first' style='width:20px'></th>" + "<th class='table-th table-border' style='width:100px'>客户姓名</th>" + "<th class='table-th table-border' style='width:140px'>合同号</th>" + "<th class='table-th table-border' style='width:140px'>产品名称</th>" + "<th class='table-th table-border' style='width:120px'>银行帐户</th>" + "<th class='table-th table-border' style='width:200px'>开户行</th>" + "<th class='table-th table-border' style='width:100px'>打款金额</th>" + "<th class='table-th table-border' style='width:70px'>合同周期</th>" + "<th class='table-th table-border' style='width:60px'>次数</th>" + "<th class='table-th table-border' colspan='2' style='width:90px'>打款状态</th>" + '</tr>' + '</thead>' + '<tbody>';

  for (var i = 0; i < arrayData.length; i++) {
    var item = arrayData[i];
    tableHtml += '<tr>' + "<td class='table-td table-border table-border-first' align='center'>" + (i + 1) + '</td>' + "<td class='table-td table-border'>" + item.customerName + '</td>' + "<td class='table-td table-border'>" + item.contractCd + '</td>' + "<td class='table-td table-border'>" + item.productName + '</td>' + "<td class='table-td table-border'>" + item.accountNo + '</td>' + "<td class='table-td table-border'>" + item.bankName + '</td>' + "<td class='table-td table-border' align='right'>" + (item.interest == null ? 0 : item.interest) + '</td>' + "<td class='table-td table-border' align='center'>" + item.timeLimit + '</td>' + "<td class='table-td table-border' align='center'>" + item.interestTimes + '</td>' + "<td class='table-td table-border'></td>" + "<td class='table-td table-border'></td>" + '</tr>';
    total += item.interest == null ? 0 : formatAmountToNumber(item.interest);
  }

  tableHtml += '<tr>' + "<td colspan='7' align='right' style='font-size:14px;font-weight:bold;'>合计:" + formatAmountToString(total) + '</td>' + '<td></td>' + '<td></td>' + '</tr>'; // 合计行

  tableHtml += '</tbody><tfoot>' + '<tr><th></th><th>制单:' + TABLE_DATA.create + '</th><th></th><th>结算:' + TABLE_DATA.count + '</th><th></th><th>复核:' + TABLE_DATA.finance + "</th><th></th><th colspan='2'></th></tr>" + '</tfoot></table>';
  var LODOP = getLodop();
  if (!LODOP) return;
  LODOP.PRINT_INIT('付息出金表格打印' + date);
  LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A4'); // 横向A4纸

  LODOP.ADD_PRINT_TABLE(0, 20, 1050, 680, tableHtml);
  LODOP.SET_PRINT_STYLEA(0, 'FontSize', 5);
  LODOP.SET_PRINT_MODE("AUTO_CLOSE_PREWINDOW", 1); //打印完关闭预览窗口

  LODOP.PREVIEW();
} // TODO 附件打印
// 付息附件表的contractId字段实际保存的是ipdId, extId才是合同ID

export function printAttachment(arrayIpdId) {
  // 查询applyId对应的附件，获取附件id
  var map = new Map(); // <ipdId, attachId>(附件表extId对应的attachId)

  var param = {
    query: {
      arrayContractId: arrayIpdId
    }
  };
  var promiseAttach = attchmentList(param).then(function (res) {
    var data = res.data;
    data.forEach(function (n) {
      map.set(n.contractId, n.attachId); // <contractId, attachId>(附件表contractId对应的attachId)
    });
  }); // 按参数合同顺序批量打印附件

  promiseAttach.then(function () {
    var arrayImgUrl = [];
    var token = util.cookies.get('token');
    var param = {
      body: {
        attachId: null
      }
    };
    arrayIpdId.forEach(function (n) {
      param.body.attachId = map.get(n);
      arrayImgUrl.push(process.env.VUE_APP_API + attachmentMap.open + '?X-Token=' + token + '&data=' + JSON.stringify(param)); // 拼装图片路径
    });
    var LODOP = getLodop();
    if (!LODOP) return;
    LODOP.PRINT_INIT('赎回出金附件打印');
    arrayImgUrl.forEach(function (n) {
      LODOP.ADD_PRINT_IMAGE(0, 0, '100%', '100%', "<img border='0' src='" + n + "'/>");
      LODOP.SET_PRINT_STYLEA(0, 'Stretch', 2); // (不可变形)扩展缩放模式
      // LODOP.SET_PRINT_STYLEA(0, "HtmWaitMilSecs", 1000); //延迟1000毫秒

      LODOP.NewPageA(); // 强制分页
    });
    LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
    LODOP.SET_PRINT_MODE("AUTO_CLOSE_PREWINDOW", 1); //打印完关闭预览窗口

    LODOP.PREVIEW();
  });
} // TODO 打印发票

export function printBill(object) {
  // 打印日期
  var printDate = formatDate(new Date());
  var arrayPrintDate = printDate.split('-'); // 金额数字转成数组

  var interest = formatAmountToNumber(object.interest).toString();
  var arrayCash = interest.split('.'); // 分离整数，小数部分

  var arrayInteger = []; // 整数部分

  var arrayFloat = ['0', '0']; // 小数部分，2位

  arrayInteger = arrayCash[0].split(''); // 转成数组    

  if (arrayCash.length > 1) {
    // 有小数
    arrayFloat = arrayCash[1].split('');
  } // 如果整数位少于8位，补全，前面补null，转为大写后前面是 ⓧ


  if (arrayInteger.length < 8) {
    var addNum = 8 - arrayInteger.length;

    for (var i = 0; i < addNum; i++) {
      arrayInteger.unshift(null); // 数组前面添加
    }
  } // 合同日志各种类型最后处理人    


  var param = {
    contractId: object.ipdId
  };
  listLastGroup(param).then(function (res) {
    var prepared = ''; // 制表人

    var checked1 = ''; // 审核人1

    var checked2 = ''; // 审核人2

    var accountingCharge1 = ''; // 复核人1

    var accountingCharge2 = ''; // 复核人2

    res.data.forEach(function (n) {
      if (n.contractState == '0') {
        prepared = n.userName;
      } else if (n.contractState == '1') {
        checked1 = n.userName;
      } else if (n.contractState == '2') {
        checked2 = n.userName;
      } else if (n.contractState == '3') {
        accountingCharge1 = n.userName;
      } else if (n.contractState == '4') {
        accountingCharge2 = n.userName;
      }
    });
    var LODOP = getLodop();
    if (!LODOP) return;
    LODOP.PRINT_INITA(1, 0, 908, 449, '打印发票');
    LODOP.SET_PRINT_PAGESIZE(1, '24cm', '12cm', '');
    LODOP.SET_SHOW_MODE('BKIMG_LEFT', 0);
    LODOP.SET_SHOW_MODE('BKIMG_TOP', 0);
    LODOP.SET_PRINT_STYLE('FontSize', 12);
    LODOP.SET_PRINT_MODE("AUTO_CLOSE_PREWINDOW", 1); //打印完关闭预览窗口

    LODOP.ADD_PRINT_TEXTA('accountName', '2.3cm', '3.5cm', 338, 24, object.accountName); // 客户名应该是银行姓名

    LODOP.ADD_PRINT_TEXTA('accountNo', '3.3cm', '3.5cm', 337, 24, formatBankAccountNo(object.accountNo));
    LODOP.ADD_PRINT_TEXTA('bankName', '4.1cm', '3.4cm', 337, 24, object.bankName);
    LODOP.ADD_PRINT_TEXTA('applyBackCash', '6.2cm', '8.2cm', 109, 24, object.applyBackCash);
    LODOP.ADD_PRINT_TEXTA('fen', '5.2cm', '12.4cm', 20, 24, formatNumberToChinese(arrayFloat[1])); // 金额"分"转中文大写

    LODOP.ADD_PRINT_TEXTA('jiao', '5.2cm', '11.3cm', 20, 24, formatNumberToChinese(arrayFloat[0])); // 金额"角"

    LODOP.ADD_PRINT_TEXTA('yuan', '5.2cm', '10.2cm', 20, 24, formatNumberToChinese(arrayInteger[7])); // 金额"元"

    LODOP.ADD_PRINT_TEXTA('shi', '5.2cm', '9.1cm', 20, 24, formatNumberToChinese(arrayInteger[6])); // 金额"十"

    LODOP.ADD_PRINT_TEXTA('bai', '5.2cm', '8.0cm', 20, 24, formatNumberToChinese(arrayInteger[5])); // 金额"百"

    LODOP.ADD_PRINT_TEXTA('qian', '5.2cm', '6.9cm', 20, 24, formatNumberToChinese(arrayInteger[4])); // 金额"千"

    LODOP.ADD_PRINT_TEXTA('wan', '5.2cm', '5.9cm', 20, 24, formatNumberToChinese(arrayInteger[3])); // 金额"万"

    LODOP.ADD_PRINT_TEXTA('shiwan', '5.2cm', '4.8cm', 20, 24, formatNumberToChinese(arrayInteger[2])); // 金额"十万"

    LODOP.ADD_PRINT_TEXTA('baiwan', '5.2cm', '3.8cm', 20, 24, formatNumberToChinese(arrayInteger[1])); // 金额"百万"

    LODOP.ADD_PRINT_TEXTA('qianwan', '5.2cm', '3.3cm', 20, 24, formatNumberToChinese(arrayInteger[0])); // 金额"千万"

    LODOP.ADD_PRINT_TEXT('1.5cm', '12.5cm', 44, 24, arrayPrintDate[0]); // 日期

    LODOP.ADD_PRINT_TEXT('1.5cm', '14.5cm', 26, 24, arrayPrintDate[1]);
    LODOP.ADD_PRINT_TEXT('1.5cm', '15.7cm', 26, 24, arrayPrintDate[2]);
    LODOP.ADD_PRINT_TEXTA('checked1', '10.4cm', '2.8cm', 79, 24, checked1);
    LODOP.ADD_PRINT_TEXTA('checked2', '7.5cm', '8.2cm', 106, 40, checked2);
    LODOP.ADD_PRINT_TEXTA('accountingCharge1', '10.3cm', '8.8cm', 79, 24, accountingCharge1);
    LODOP.ADD_PRINT_TEXTA('accountingCharge2', '10.7cm', '8.8cm', 79, 24, accountingCharge2);
    LODOP.ADD_PRINT_TEXT('10.4cm', '15.0cm', 105, 44, prepared);
    LODOP.PREVIEW();
  });
}