var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        top: "10vh",
        width: "800px",
        title: "台帐",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            "row-style": { height: "20px" },
            "cell-style": { padding: "6px 0" },
            hight: "350px",
            "highlight-current-row": ""
          },
          on: { "current-change": _vm.selectRow }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "orgName", label: "公司名称", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { prop: "createDate", label: "创建日期", width: "100" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sumBonus",
              label: "推介费合计",
              width: "150",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sumYearAmount",
              label: "年化合计",
              width: "180",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态", width: "130" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        style: { color: _vm.setStatusColor(scope.row.status) }
                      },
                      [_vm._v(_vm._s(scope.row.statusText))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "primary",
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.clickBatchDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("台帐明细")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.page.pageNumber,
          "page-sizes": [10, 20, 30, 40],
          "page-size": _vm.page.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.page.totalCount
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange
        }
      }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", plain: "", size: "mini" },
              on: {
                click: function($event) {
                  return _vm.clickRemoveBatch()
                }
              }
            },
            [_vm._v("删除台帐")]
          )
        ],
        1
      ),
      _c("dialog-batch-detail", {
        attrs: { propsModel: _vm.currentRow },
        on: { refresh: _vm.init },
        model: {
          value: _vm.showBatchDetailDialog,
          callback: function($$v) {
            _vm.showBatchDetailDialog = $$v
          },
          expression: "showBatchDetailDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }