//变更
export default {
  isContractCanChange: '/change/isContractCanChange',
  // 检查合同是否可以申请续约
  save: '/change/save',
  // 保存
  submit: '/change/submit',
  // 提交
  remove: '/change/remove',
  //删除
  auditService: '/change/auditService',
  //客服审核    
  auditFinance: '/change/auditFinance',
  //结算审核
  auditMoney: '/change/auditMoney',
  //资金审核
  list: '/change/list',
  //列表
  getInfo: '/change/getInfo',
  //明细
  history: '/change/history' // 变更历史

};