//查询条件
import AUDIT from "@/maps/enum/status/audit";
var VAL_WIDTH = '150px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '申请日期',
  valueKey: 'createDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'createDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '审核',
  valueKey: 'auditStatus',
  value: '',
  type: 'select',
  options: filterAuditStatus(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: false
  }
}]; //审核状态

function filterAuditStatus() {
  var array = [AUDIT.NOT_DO, AUDIT.YES, AUDIT.NO];
  return array;
}