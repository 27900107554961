var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 合同统计
{
  path: "".concat(baseRoute, "/report/contract/total"),
  name: 'contract-total',
  meta: {
    title: '合同统计',
    auth: true,
    cache: true
  },
  component: _import('crm/report/contract/total')
}, // 合同审核统计
{
  path: "".concat(baseRoute, "/report/contract/audit"),
  name: 'contract-audit',
  meta: {
    title: '合同审核统计',
    auth: true,
    cache: true
  },
  component: _import('crm/report/contract/audit')
}, // 财富月入金
{
  path: "".concat(baseRoute, "/report/orgjf/buy"),
  name: 'orgjf-buy',
  meta: {
    title: '财富月入金',
    auth: true,
    cache: true
  },
  component: _import('crm/report/orgjf/buy')
}, // 直营机构入金
{
  path: "".concat(baseRoute, "/report/org/buy"),
  name: 'org-buy',
  meta: {
    title: '直营机构入金',
    auth: true,
    cache: true
  },
  component: _import('crm/report/org/buy')
}, // 合同逾期录入统计
{
  path: "".concat(baseRoute, "/report/contract/overdue"),
  name: 'contract-overdue',
  meta: {
    title: '合同逾期录入统计',
    auth: true,
    cache: true
  },
  component: _import('crm/report/contract/overdue')
}, // 直营业绩追踪表
{
  path: "".concat(baseRoute, "/report/org/money"),
  name: 'org-money',
  meta: {
    title: '直营业绩追踪表',
    auth: true,
    cache: true
  },
  component: _import('crm/report/org/money')
}, // 直营业绩分析报表
{
  path: "".concat(baseRoute, "/report/org/analyze"),
  name: 'org-analyze',
  meta: {
    title: '直营业绩分析报表',
    auth: true,
    cache: true
  },
  component: _import('crm/report/org/analyze')
}, // 资金流水汇总报表
{
  path: "".concat(baseRoute, "/report/money/inout"),
  name: 'money-inout',
  meta: {
    title: '资金流水汇总报表',
    auth: true,
    cache: true
  },
  component: _import('crm/report/money/inout')
}, // 审核统计
{
  path: "".concat(baseRoute, "/report/audit/audit"),
  name: 'audit',
  meta: {
    title: '审核统计',
    auth: true,
    cache: true
  },
  component: _import('crm/report/audit/audit')
}, // 业务汇总报表
{
  path: "".concat(baseRoute, "/report/business"),
  name: 'business',
  meta: {
    title: '业务汇总报表',
    auth: true,
    cache: true
  },
  component: _import('crm/report/business')
}, // 财务管理_经营分析责任状业绩明细
{
  path: "".concat(baseRoute, "/report/finance/zrzachievement"),
  name: 'zrzAchievement',
  meta: {
    title: '经营分析责任状业绩明细',
    auth: true,
    cache: true
  },
  component: _import('crm/report/finance/zrzachievement')
}, // 财务管理_资金现金流分析明细
{
  path: "".concat(baseRoute, "/report/finance/cashflow"),
  name: 'cashflow',
  meta: {
    title: '资金现金流分析明细',
    auth: true,
    cache: true
  },
  component: _import('crm/report/finance/cashflow')
}, // 财务管理_基本法业绩统计汇总
{
  path: "".concat(baseRoute, "/report/finance/jbfachievement"),
  name: 'jbfachievement',
  meta: {
    title: '基本法业绩统计汇总',
    auth: true,
    cache: true
  },
  component: _import('crm/report/finance/jbfachievement')
}, // 财务管理_经营分析续约明细
{
  path: "".concat(baseRoute, "/report/finance/renewAnaly"),
  name: 'renewAnaly',
  meta: {
    title: '经营分析续约明细',
    auth: true,
    cache: true
  },
  component: _import('crm/report/finance/renewAnaly')
}, // 直营推介费报表
{
  path: "".concat(baseRoute, "/report/commissionV3/sumOrgPerson"),
  name: 'sumOrgPerson',
  meta: {
    title: '人员直营推介费报表',
    auth: true,
    cache: true
  },
  component: _import('crm/report/commissionV3/sumOrgPerson')
}, {
  path: "".concat(baseRoute, "/report/commissionV3/sumOrgMonth"),
  name: 'sumOrgMonth',
  meta: {
    title: '大区直营推介费报表',
    auth: true,
    cache: true
  },
  component: _import('crm/report/commissionV3/sumOrgMonth')
}, // 产品出金计划报表
{
  path: "".concat(baseRoute, "/report/product/withdrawalPlan"),
  name: 'withdrawalPlan',
  meta: {
    title: '产品出金计划报表',
    auth: true,
    cache: true
  },
  component: _import('crm/report/product/withdrawalPlan')
}, {
  path: "".concat(baseRoute, "/report/product/withdrawalDetail"),
  name: 'withdrawalDetail',
  meta: {
    title: '产品出金明细报表',
    auth: true,
    cache: true
  },
  component: _import('crm/report/product/withdrawalDetail')
}];