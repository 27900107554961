


































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/commissionAgencyV3/messageSearch/index.vue"
}
