//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '交易日期',
  prop: 'tallyDate',
  width: VAL_WIDTH
}, {
  label: '交易时间',
  prop: 'tallyTime',
  width: VAL_WIDTH
}, {
  label: '收入金额',
  prop: 'debtor',
  width: '150px',
  align: 'right'
}, {
  label: '对方户名',
  prop: 'accountName',
  width: '150px'
}, {
  label: '对方账号',
  prop: 'bankAccount',
  width: '180px'
}, {
  label: '交易行名',
  prop: 'bankName',
  width: '200px'
}, {
  label: '银行流水号',
  prop: 'waterNum',
  width: '150px'
}, {
  label: '是否已匹配合同',
  prop: 'isBuyText',
  width: '150px'
}, {
  label: '匹配合同',
  prop: 'contractCd',
  width: '150px'
}, {
  label: '摘要',
  prop: 'info',
  width: '250px'
}, {
  label: '备注',
  prop: 'remark',
  width: '400px'
}];