import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import SearchCustomer from "@/components/business/search-customer";
import ENUM from "@/maps/enum/enum";
import { getEnumList } from "@api/common/common";
import { saveBirthdayBonus } from "@api/crm/customer/customer";
export default {
  name: "dialog-add",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    SearchCustomer: SearchCustomer
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      showSearchCustomer: false,
      selectData: {
        vip: [] //vip级别

      },
      rules: {
        customerName: [{
          required: true,
          message: "请填写客户姓名",
          trigger: "input"
        }],
        vip: [{
          required: true,
          message: "请填写客户VIP特级",
          trigger: "input"
        }]
      }
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    //vip级别
    getEnumList({
      enumCd: ENUM.ENUM_CD_0233
    }).then(function (res) {
      _this.selectData.vip = res;
    });
  },
  watch: {
    showDialog: {
      handler: function handler(val) {},
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 确定
    confirm: function confirm() {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = {
            customerId: _this2.formModel.customerId,
            birthday: _this2.formModel.birthday,
            vip: _this2.formModel.vip
          };
          _this2.loading = true;
          saveBirthdayBonus({
            object: param
          }).then(function (res) {
            _this2.loading = false;
            _this2.showDialog = false;

            _this2.$emit("refresh");
          }).catch(function (e) {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    popSelectCustomer: function popSelectCustomer(customer) {
      //给查找客户子组件选中时调用
      // let formModel = this.$refs.form;
      this.formModel = _objectSpread({}, customer);
      this.formModel.userId = customer.agentUserId; //经纪人字段不对应

      this.formModel.userName = customer.agentUserName;
      this.showSearchCustomer = false;
    }
  }
};