//产品类型
export default {
  HJ: '1',
  // 黄金实物
  JJS: '2',
  // 金交所   
  YXHH: '3',
  // 有限合伙 
  SM: '4',
  // 私募    
  XT: '5',
  //信托
  OTHER: '6' //其他

};