










































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/report/finance/renewAnaly/index.vue"
}
