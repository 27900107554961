var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", { staticClass: "page-org" }, [
    _c(
      "div",
      [
        _c(
          "el-table",
          {
            ref: "multipleTable",
            staticStyle: { width: "100%" },
            attrs: {
              "header-cell-class-name": _vm.leftheaderStyle,
              data: _vm.tableData,
              "tooltip-effect": "dark",
              height: "660",
              border: ""
            },
            on: { "selection-change": _vm.handleSelectionChange }
          },
          [
            _c("el-table-column", {
              attrs: { prop: "applyMenu", label: "菜单-节点", width: "230" }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "templateTitle",
                label: "提醒格式类型",
                width: "200"
              }
            }),
            _c("el-table-column", {
              attrs: { prop: "templateContent", label: "提醒格式示例" }
            }),
            _c("el-table-column", {
              attrs: { prop: "remark", width: "200", label: "备注" }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }