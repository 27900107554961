export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    // 财务报表-财务管理

    /** 获取部门下拉列表内容 */
    GETALLORGUSANALY: function GETALLORGUSANALY(data) {
      return request({
        url: url.finance.common.getAllOrgUsAnaly,
        data: data
      });
    },

    /** 经营分析责任状业绩明细 获取详情数据 */
    GETACHIEVEMENT_GETQUERYLIST: function GETACHIEVEMENT_GETQUERYLIST(data) {
      return request({
        url: url.finance.achievement.queryList,
        data: data
      });
    },

    /** 经营分析责任状业绩明细 导出数据 */
    GETACHIEVEMENT_EXPORTLIST: function GETACHIEVEMENT_EXPORTLIST(data) {
      return request({
        url: url.finance.achievement.exportList,
        data: data,
        responseType: 'arraybuffer'
      });
    },

    /** 资金现金流分析明细_到期出金 导出数据 */
    GETCASHFLOW_EXPORTMATURITYLIST: function GETCASHFLOW_EXPORTMATURITYLIST(data) {
      return request({
        url: url.finance.cashflow.exportMaturityList,
        data: data,
        responseType: 'arraybuffer'
      });
    },

    /** 资金现金流分析明细_付息出金 导出数据 */
    GETCASHFLOW_EXPORTINTERESTLIST: function GETCASHFLOW_EXPORTINTERESTLIST(data) {
      return request({
        url: url.finance.cashflow.exportInterestList,
        data: data,
        responseType: 'arraybuffer'
      });
    },

    /** 基本法业绩汇总 列表数据 */
    GETJBFACHIEVEMENT_GETQUERYLIST: function GETJBFACHIEVEMENT_GETQUERYLIST(data) {
      return request({
        url: url.finance.jbfachievement.queryList,
        data: data
      });
    },

    /** 基本法业绩汇总 导出数据 */
    GETJBFACHIEVEMENT_EXPORTLIST: function GETJBFACHIEVEMENT_EXPORTLIST(data) {
      return request({
        url: url.finance.jbfachievement.exportList,
        data: data,
        responseType: 'arraybuffer'
      });
    },

    /** 基本法业绩汇总 明细数据导出 */
    GETJBFACHIEVEMENT_EXPORTAGENTACHLIST: function GETJBFACHIEVEMENT_EXPORTAGENTACHLIST(data) {
      return request({
        url: url.finance.jbfachievement.exportAgentAchList,
        data: data,
        responseType: 'arraybuffer'
      });
    },

    /** 获取字典值内容 */
    GETACHIEVEMENT_DICTIONARY: function GETACHIEVEMENT_DICTIONARY(data) {
      return request({
        url: url.finance.achievement.dictionary,
        data: data
      });
    },

    /** 经营分析续约明细_续约 导出数据 */
    GETRENEWANALY_EXPORTRENEWLIST: function GETRENEWANALY_EXPORTRENEWLIST(data) {
      return request({
        url: url.finance.renewAnaly.exportRenewList,
        data: data,
        responseType: 'arraybuffer'
      });
    },

    /** 经营分析续约明细_到期 导出数据 */
    GETRENEWANALY_EXPORTEXPIRELIST: function GETRENEWANALY_EXPORTEXPIRELIST(data) {
      return request({
        url: url.finance.renewAnaly.exportExpireList,
        data: data,
        responseType: 'arraybuffer'
      });
    }
  };
});