//权限
export default {
  listMenu: '/right/listMenu',
  // 用户菜单列表
  saveMenu: '/right/saveMenu',
  // 保存用户菜单
  listOrg: '/right/listOrg',
  // 用户机构列表
  saveOrg: '/right/saveOrg',
  // 保存用户机构
  listProduct: '/right/listProduct',
  // 用户产品列表
  saveProduct: '/right/saveProduct',
  // 保存用户产品
  saveProductToManyUser: '/right/saveProductToManyUser' // 保存多个用户同一产品   

};