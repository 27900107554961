import _toConsumableArray from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { setRoleMenu } from "@api/rm/roleMenu";
export default {
  name: "dialog-role-menu",
  props: {
    menuList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    propsModel: {
      type: Object,
      default: function _default() {}
    },
    defaultCheckedKeys: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      props: {
        label: 'menuName',
        value: 'id',
        children: 'children' // disabled: this.disFn,

      },
      checkedMenu: [],
      selectPermission: '',
      loading: false,
      model: {},
      // 表单值
      formModel: {} //表单

    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.checkedMenu = this.defaultCheckedKeys || [];
    },
    handleCheck: function handleCheck(currentNode, treeStatus) {
      var _this = this;

      /**
       * @des 根据父元素的勾选或取消勾选，将所有子级处理为选择或非选中状态
       * @param { node: Object }  当前节点
       * @param { status: Boolean } （true ： 处理为勾选状态 ； false： 处理非选中）
       */
      var setChildStatus = function setChildStatus(node, status) {
        /* 这里的 id children 也可以是其它字段，根据实际的业务更改 */
        _this.$refs.menuTree.setChecked(node.id, status);

        if (node.children) {
          /* 循环递归处理子节点 */
          for (var i = 0; i < node.children.length; i++) {
            setChildStatus(node.children[i], status);
          }
        }
      };
      /* 设置父节点为选中状态 */


      var setParentStatus = function setParentStatus(nodeObj) {
        /* 拿到tree组件中的node,使用该方法的原因是第一次传入的 node 没有 parent */
        var node = _this.$refs.menuTree.getNode(nodeObj);

        if (node.parent.key) {
          _this.$refs.menuTree.setChecked(node.parent, true);

          setParentStatus(node.parent);
        }
      };
      /* 判断当前点击是选中还是取消选中操作 */


      if (treeStatus.checkedKeys.includes(currentNode.id)) {
        setParentStatus(currentNode);
        setChildStatus(currentNode, true);
      } else {
        /* 取消选中 */
        if (currentNode.children) {
          setChildStatus(currentNode, false);
        }
      }

      this.checkedMenu = _toConsumableArray(this.$refs.menuTree.getCheckedKeys());
    },
    disFn: function disFn(data, node) {
      // 如果父级没有勾选，那么按钮类型的菜单不可选择
      if (data.type === '1') {
        return false;
      } else if (data.type === '2' && node.parent.checked) {
        return false;
      }

      this.$set(node, 'checked', false);
      return true;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.showDialog = false;
      this.$emit('refresh');
    },
    // TODO 确定
    confirm: function confirm() {
      var _this2 = this;

      var params = {
        roleId: this.propsModel.id,
        arrayMenuId: this.checkedMenu
      };
      setRoleMenu(params).then(function () {
        _this2.$message.success('菜单权限配置成功！');

        setTimeout(function () {
          _this2.dialogClose();
        }, 500);
      }).catch(function () {});
    }
  }
};