var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'rm';
export default [// 职员
{
  path: "".concat(baseRoute, "/system/system/person"),
  name: 'person',
  meta: {
    title: '职员',
    auth: true,
    cache: true
  },
  component: _import('rm/system/system/person')
}, // 组织架构
{
  path: "".concat(baseRoute, "/system/system/org"),
  name: 'org',
  meta: {
    title: '组织架构',
    auth: true,
    cache: true
  },
  component: _import('rm/system/system/org')
}, // 常量管理
{
  path: "".concat(baseRoute, "/system/enum"),
  name: 'enum',
  meta: {
    title: '基础代码',
    auth: true,
    cache: true
  },
  component: _import('rm/system/enum')
}, // 接口管理
{
  path: "".concat(baseRoute, "/system/system/api"),
  name: 'api',
  meta: {
    title: '接口',
    auth: true,
    cache: true
  },
  component: _import('rm/system/system/api')
}, // 菜单管理
{
  path: "".concat(baseRoute, "/system/system/menu"),
  name: 'menu',
  meta: {
    title: '菜单',
    auth: true,
    cache: true
  },
  component: _import('rm/system/system/menu')
}, // 角色管理
{
  path: "".concat(baseRoute, "/system/system/role"),
  name: 'role',
  meta: {
    title: '角色',
    auth: true,
    cache: true
  },
  component: _import('rm/system/system/role')
}, // 个人敏感字段
{
  path: "".concat(baseRoute, "/system/system/role/personSecrecy"),
  name: 'personSecrecy',
  meta: {
    title: '个人敏感字段',
    auth: true,
    cache: true
  },
  component: _import('rm/system/system/role/personSecrecy')
}, //基本信息
{
  path: "".concat(baseRoute, "/system/system/basic"),
  name: 'basic',
  meta: {
    title: '基本信息配置',
    auth: true,
    cache: true
  },
  component: _import('rm/system/system/basic')
}];