import "core-js/modules/es.object.to-string.js";
// 职员
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import { formatDate } from '@/libs/crm/format';
import func from '@/maps/url/rm/person';
import util from '@/libs/util'; // TODO 获取用户列表

export function list(params) {
  return request.post(func.list, {
    body: params
  });
} // TODO 授权的用户列表

export function listRight(params) {
  return request.post(func.listRight, {
    body: params
  });
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '职员' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 获取职员详情

export function getInfo(params) {
  return request.post(func.getInfo, {
    body: params
  });
} // TODO 新增职员

export function addPerson(params) {
  return request.post(func.add, {
    body: params
  });
} // TODO 编辑职员

export function editPerson(params) {
  return request.post(func.edit, {
    body: params
  });
} // TODO 设置账号

export function setUpAccount(params) {
  return request.post(func.setUpAccount, {
    body: params
  });
} // TODO 离职

export function personDimission(params) {
  return request.post(func.remove, {
    body: params
  });
} // TODO 重置密码

export function resetPassword(params) {
  return request.post(func.resetPassword, {
    body: params
  });
}