// 任务调度管理
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [{
  path: "".concat(baseRoute, "/merchantManagement/merchantManagement"),
  name: 'merchantManagement',
  meta: {
    title: '商户管理',
    auth: true,
    cache: true
  },
  component: _import('crm/merchantManagement/merchantManagement')
}, {
  path: "".concat(baseRoute, "/merchantManagement/receivingPaymentManagement"),
  name: 'receivingPaymentManagement',
  meta: {
    title: '收付信息',
    auth: true,
    cache: true
  },
  component: _import('crm/merchantManagement/receivingPaymentManagement')
}];