var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        width: "600px",
        title: _vm.title,
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "编号", prop: "orgCd" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formModel.orgCd,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "orgCd", $$v)
                          },
                          expression: "formModel.orgCd"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型", prop: "orgType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.orgType,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "orgType", $$v)
                            },
                            expression: "formModel.orgType"
                          }
                        },
                        _vm._l(_vm.selectData.orgType, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称", prop: "orgName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.orgName,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "orgName", $$v)
                          },
                          expression: "formModel.orgName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "简称", prop: "orgNameAbbr" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.orgNameAbbr,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "orgNameAbbr", $$v)
                          },
                          expression: "formModel.orgNameAbbr"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属机构", prop: "parentOrgCd" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          "show-all-levels": false,
                          options: _vm.selectData.arrayOrg,
                          props: {
                            //expandTrigger: 'hover',
                            checkStrictly: true,
                            emitPath: false,
                            value: "orgCd",
                            label: "orgName",
                            children: "children"
                          }
                        },
                        model: {
                          value: _vm.formModel.parentOrgCd,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "parentOrgCd", $$v)
                          },
                          expression: "formModel.parentOrgCd"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人", prop: "principal" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.principal,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "principal", $$v)
                          },
                          expression: "formModel.principal"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "email", prop: "email" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.email,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "email", $$v)
                          },
                          expression: "formModel.email"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话", prop: "phone" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "phone", $$v)
                          },
                          expression: "formModel.phone"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "地址", prop: "address" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.address,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "address", $$v)
                          },
                          expression: "formModel.address"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "顺序", prop: "orgIndex" } },
                    [
                      _c("el-input", {
                        attrs: { type: "number" },
                        model: {
                          value: _vm.formModel.orgIndex,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "orgIndex", $$v)
                          },
                          expression: "formModel.orgIndex"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否可用", prop: "enabled" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          model: {
                            value: _vm.formModel.enabled,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "enabled", $$v)
                            },
                            expression: "formModel.enabled"
                          }
                        },
                        [_vm._v("禁用")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.formModel.enabled,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "enabled", $$v)
                            },
                            expression: "formModel.enabled"
                          }
                        },
                        [_vm._v("启用")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否产生合同积分", prop: "isScore" } },
                    [
                      _c("el-switch", {
                        attrs: { "active-value": "1", "inactive-value": "0" },
                        model: {
                          value: _vm.formModel.isScore,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "isScore", $$v)
                          },
                          expression: "formModel.isScore"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产生合同积分日期", prop: "scoreDate" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.formModel.scoreDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "scoreDate", $$v)
                          },
                          expression: "formModel.scoreDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini", loading: _vm.loading },
              on: { click: _vm.clickSave }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }