var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("el-divider", { attrs: { "content-position": "left" } }, [
          _vm._v("基本信息")
        ]),
        _c(
          "el-descriptions",
          {
            staticClass: "margin-top",
            attrs: {
              contentStyle: _vm.contentStyle,
              labelStyle: _vm.labelStyle,
              column: 4,
              direction: "vertical",
              colon: false,
              border: ""
            }
          },
          [
            _c("el-descriptions-item", { attrs: { label: "流水号" } }, [
              _c("div", [_vm._v(_vm._s(_vm.basicInfo.code))])
            ]),
            _c("el-descriptions-item", { attrs: { label: "部门" } }, [
              _vm._v(_vm._s(_vm.basicInfo.orgName))
            ]),
            _c("el-descriptions-item", { attrs: { label: "沟通内容" } }, [
              _vm._v(_vm._s(_vm.basicInfo.content))
            ]),
            _c("el-descriptions-item", { attrs: { label: "经度" } }, [
              _vm._v(_vm._s(_vm.basicInfo.longitude))
            ]),
            _c("el-descriptions-item", { attrs: { label: "纬度" } }, [
              _vm._v(_vm._s(_vm.basicInfo.latitude))
            ]),
            _c("el-descriptions-item", { attrs: { label: "定位位置" } }, [
              _vm._v(_vm._s(_vm.basicInfo.location))
            ]),
            _c("el-descriptions-item", { attrs: { label: "邀约意向" } }, [
              _vm._v(_vm._s(_vm.basicInfo.invitationIntentValue))
            ]),
            _c(
              "el-descriptions-item",
              { attrs: { label: "是否参加类似活动" } },
              [_vm._v(_vm._s(_vm.basicInfo.attendSimilarActivityValue))]
            ),
            _c(
              "el-descriptions-item",
              { attrs: { label: "希望了解活动主题和内容" } },
              [_vm._v(_vm._s(_vm.basicInfo.moreAboutContentValue))]
            ),
            _c("el-descriptions-item", { attrs: { label: "希望交流方式" } }, [
              _vm._v(_vm._s(_vm.basicInfo.communicationMethodValue))
            ]),
            _c("el-descriptions-item", { attrs: { label: "" } }),
            _c("el-descriptions-item", { attrs: { label: "" } })
          ],
          1
        ),
        _c("el-divider", { attrs: { "content-position": "left" } }, [
          _vm._v("系统信息")
        ]),
        _c(
          "el-descriptions",
          {
            staticClass: "margin-top",
            attrs: {
              contentStyle: _vm.contentStyle,
              labelStyle: _vm.labelStyle,
              column: 4,
              direction: "vertical",
              colon: false,
              border: ""
            }
          },
          [
            _c("el-descriptions-item", { attrs: { label: "创建人" } }, [
              _vm._v(_vm._s(_vm.basicInfo.createUserName))
            ]),
            _c("el-descriptions-item", { attrs: { label: "创建时间" } }, [
              _vm._v(_vm._s(_vm.basicInfo.createTime))
            ]),
            _c("el-descriptions-item", { attrs: { label: "部门" } }, [
              _vm._v(_vm._s(_vm.basicInfo.orgName))
            ]),
            _c("el-descriptions-item", { attrs: { label: "" } }),
            _c("el-descriptions-item", { attrs: { label: "更新人" } }, [
              _vm._v(_vm._s(_vm.basicInfo.updateUserName))
            ]),
            _c("el-descriptions-item", { attrs: { label: "更新时间" } }, [
              _vm._v(_vm._s(_vm.basicInfo.updateTime))
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }