//查询条件
import AUDIT from "@/maps/enum/status/audit";
import { listCacheOrg, listCacheProduct } from "@api/common/cache";
var VAL_WIDTH = '150px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '合同编号',
  valueKey: 'contractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '客户',
  valueKey: 'customerName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '机构名称',
  valueKey: 'orgCd',
  value: '',
  type: 'cascader',
  method: listCacheOrg,
  params: {},
  options: [],
  props: {
    //expandTrigger: 'hover',
    checkStrictly: true,
    emitPath: false,
    showAllLevels: false,
    value: 'orgCd',
    label: 'orgName',
    children: 'children'
  }
}, {
  label: '产品',
  valueKey: 'arrayProductId',
  value: [],
  type: 'select',
  method: listCacheProduct,
  params: {},
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '审核日期',
  valueKey: 'offLineAuditDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'offLineAuditDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '审核',
  valueKey: 'arrayAuditState',
  value: [],
  type: 'select',
  options: filterAuditStatus(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '导出',
  valueKey: 'exportTemplateState',
  value: '0',
  type: 'select',
  options: [{
    value: '0',
    text: '未导出'
  }, {
    value: '1',
    text: '已导出'
  }],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}]; //合同状态

function filterAuditStatus() {
  var array = [AUDIT.NOT_DO, AUDIT.YES, AUDIT.NO, AUDIT.IGNORE];
  return array;
}