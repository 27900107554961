var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pdf_wrap" }, [
    _c(
      "div",
      { staticClass: "pdf_list" },
      _vm._l(_vm.numPages, function(i) {
        return _c("pdf", {
          key: i,
          staticClass: "pdf_item",
          staticStyle: { width: "100%" },
          attrs: { src: _vm.src, page: i }
        })
      }),
      1
    ),
    _c("div", { staticClass: "close", on: { click: _vm.closePdf } }, [
      _vm._v("关闭")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }