import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 80
};
export var TOP_FORM1 = {
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '标题',
    props: {
      placeholder: '请输入标题'
    }
  }),
  // informationType: {
  //   ...PUBLIC_CONFIG,
  //   type: 'select',
  //   label: '类型',
  //   props: {
  //     placeholder: '请选择类型',
  //   },
  //   options: [
  //     { label: '平台文章', value: 1 },
  //     { label: '外部链接', value: 2 },
  //     { label: '视频', value: 3 },
  //   ],
  // },
  classifyId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'cascader',
    label: '所属分类',
    props: {
      placeholder: '请选择所属分类',
      props: {
        label: 'name',
        value: 'id',
        children: 'children'
      }
    },
    options: []
  }) // organizationId: {
  //   ...PUBLIC_CONFIG,
  //   type: 'select',
  //   label: '发布组织',
  //   props: {
  //     placeholder: '请选择发布组织',
  //   },
  //   options: [],
  // },
  // releaseTime: {
  //   ...PUBLIC_CONFIG,
  //   type: 'datePicker',
  //   label: '发布时间',
  //   propGroup: ['startReleaseTime', 'endReleaseTime'],
  //   props: {
  //     type: 'daterange',
  //   },
  // },
  // isTop: {
  //   ...PUBLIC_CONFIG,
  //   type: 'select',
  //   label: '是否置顶',
  //   props: {
  //     placeholder: '请选择是否置顶',
  //   },
  //   options: [
  //     {
  //       label: '是',
  //       value: 1,
  //     },
  //     {
  //       label: '否',
  //       value: 0,
  //     },
  //   ],
  // },
  // releaseStatus: {
  //   ...PUBLIC_CONFIG,
  //   type: 'select',
  //   label: '发布状态',
  //   props: {
  //     placeholder: '请选择状态',
  //   },
  //   options: [
  //     {
  //       label: '已发布',
  //       value: 1,
  //     },
  //     {
  //       label: '草稿',
  //       value: 2,
  //     },
  //   ],
  // },
  // status: {
  //   ...PUBLIC_CONFIG,
  //   type: 'select',
  //   label: '状态',
  //   props: {
  //     placeholder: '请选择状态',
  //   },
  //   options: [
  //     {
  //       label: '启用',
  //       value: 1,
  //     },
  //     {
  //       label: '禁用',
  //       value: 0,
  //     },
  //   ],
  // },

};
export var TABLE_LABEL1 = [{
  label: '标题',
  prop: 'name',
  type: 'link'
}, {
  label: '发布时间',
  prop: 'releaseTime',
  sortable: 'custom',
  sortProp: 'releaseTime'
}, {
  type: 'slot',
  slotName: 'operation',
  label: '操作',
  width: 200 // formatter: function (row) {
  //   const arr = [
  //     {
  //       type: 'edit',
  //       label: '选择',
  //     },
  //   ];
  //   return arr;
  // },

}];
export var TABLE_CONFIG1 = {
  isSelect: false,
  defaultSort: {
    prop: 'releaseTime',
    order: 'descending'
  }
};