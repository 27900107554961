//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import AUDIT from "@/maps/enum/status/audit";
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import { getInfoOrgViolation, auditOrgViolation } from "@api/crm/service/org";
export default {
  name: "service-org-violation-audit",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      rules: {}
    };
  },
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.getInfo();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    clickAuditFail: function clickAuditFail() {
      //审核不通过
      if (this.formModel.advice == null || this.formModel.advice == "") {
        this.$message("请填写审核意见");
      } else {
        this.audit(AUDIT.NO.value);
      }
    },
    clickAudit: function clickAudit() {
      //审核通过
      this.audit(AUDIT.YES.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = {
            id: _this.model.id,
            audit: result,
            advice: _this.formModel.advice
          };
          _this.loading = true;
          auditOrgViolation(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit("refresh");
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    // TODO 获取详情
    getInfo: function getInfo() {
      var _this2 = this;

      var param = {
        id: this.propsModel.id
      }; //this.loading = true;

      getInfoOrgViolation(param, DATA_FORMAT_TYPE.AMOUNT).then(function (res) {
        //this.loading = false;
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this2.model = data;
      }).catch(function (e) {
        _this2.loading = false;
      });
    }
  }
};