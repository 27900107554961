







































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/count/countDate/components/dialog-settle-batch.vue"
}
