import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import crmMixin from '@/mixins/crm';
import LOG_TYPE from '@/maps/enum/contract-log-type';
import ATTACHMENT_TYPE from '@/maps/enum/attachment-type';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import AUDIT from '@/maps/enum/status/audit';
import Contract from '@/components/business/contract/businessAccountingIndex';
import Attachment from '@/components/business/attachment';
import ContractLog from '@/components/business/contract-log';
import ContractGoldWeight from '@/components/business/contract-gold-weight';
import { setExtendAttribute } from '@/libs/crm/crm';
import { getInfo as _getInfo, auditService } from '@api/crm/renew/renew'; //合同续约

export default {
  name: 'renew-service-audit',
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Contract: Contract,
    Attachment: Attachment,
    ContractLog: ContractLog,
    ContractGoldWeight: ContractGoldWeight
  },
  mixins: [dialogMixin, crmMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tabActive: 'tabContract',
      //标签框默认选中
      collapseActive: ['1', '2'],
      //信息折叠默认展开
      attachmentType: ATTACHMENT_TYPE.CONTRACT_STAFF,
      //附件类型
      isAttachmentEdit: false,
      //附件是否可编辑
      showLogDialog: false,
      showOriginalDialog: false,
      //显示原合同
      contractLogType: LOG_TYPE.RENEW,
      attachmentOpened: '',
      //附件打开状态
      goldWeightDetail: {},
      //克重明细
      formModel: {},
      //表单
      rules: {},
      originalContractId: ''
    };
  },
  computed: {
    //判断续约类型为 是否为 追加资金续约 合并续约
    isRenewMethod: function isRenewMethod() {
      return this.model.renewMethod == '3' || this.model.renewMethod == '4';
    }
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.getInfo();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tabActive = 'tabContract'; //默认打开第一个标签页

      this.goldWeightDetail = {};
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    onAttachmentOpen: function onAttachmentOpen() {
      //初始化标签
      this.tabActive = 'tabContract';
      this.attachmentOpened = 'dialog-position';
    },
    clickAuditFail: function clickAuditFail() {
      //审核不通过
      if (this.formModel.advice == null || this.formModel.advice == '') {
        this.$message('请填写审核意见');
      } else {
        this.audit(AUDIT.NO.value);
      }
    },
    clickAudit: function clickAudit() {
      //审核通过
      this.audit(AUDIT.YES.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      //this.$refs.form.validate(valid => {
      //  if (valid) {
      var param = {
        contractId: this.model.contractId,
        audit: result,
        advice: this.formModel.advice
      };
      this.loading = true;
      auditService(param).then(function (res) {
        _this.loading = false;
        _this.showDialog = false;

        _this.$emit('refresh');
      }).catch(function (e) {
        _this.loading = false;
      }); //  } else {
      //    return false;
      //  }
      //});
    },
    // TODO 获取详情
    getInfo: function getInfo() {
      var _this2 = this;

      var contractId = this.propsModel.contractId; //this.loading = true;

      _getInfo({
        contractId: contractId
      }, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        //this.loading = false;
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this2.model = data;
        setExtendAttribute(_this2.model); //包装必要展示字段
        //赎回实物联系方式

        if (res.data.contact != null) {
          var contact = res.data.contact;
          _this2.model.contactName = contact.name;
          _this2.model.contactMobile = contact.mobile;
          _this2.model.contactAddress = contact.address;
        }
      }).catch(function (e) {
        _this2.loading = false;
      });
    },
    showGoldWeight: function showGoldWeight() {
      //显示克重明细
      this.showGoldWeightDialog = true;
    },
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    },
    // 查看原合同
    clickOriginal: function clickOriginal(index) {
      var ids = this.model.originalContractId.split(',');
      this.originalContractId = ids[index];
      this.showOriginalDialog = true;
    }
  }
};