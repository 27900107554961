

































































































export default function (Component) {
  Component.options.__source = "src/layout/header-aside/components/header-remind/components/dialog-remind.vue"
}
