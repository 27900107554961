import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
}; // 弹窗中form表单配置

export var DIALOG_FORM_CONFIG = {
  id: {
    hidden: true
  },
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '任务名称',
    props: {
      placeholder: '请输入任务名称'
    },
    rules: [{
      required: true,
      message: '任务名称不能为空',
      trigger: 'blur'
    }]
  }),
  cron: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: 'cron',
    props: {
      placeholder: '请输入cron'
    },
    rules: [{
      required: true,
      message: 'cron不能为空',
      trigger: 'blur'
    }]
  }),
  method: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: 'method',
    props: {
      placeholder: '请输入执行方法'
    },
    rules: [{
      required: true,
      message: '执行方法不能为空',
      trigger: 'blur'
    }]
  })
};
export var DIALOG_FORM_DATA = {
  id: null,
  name: '',
  cron: '',
  method: ''
};