//付息
export default {
  SAVE: {
    value: '0',
    text: '待提交'
  },
  SUBMIT: {
    value: '1',
    text: '待客服审核'
  },
  SERVICE_AUDIT: {
    value: '2',
    text: '待结算审核'
  },
  SERVICE_AUDIT_FAILURE: {
    value: '-1',
    text: '客服审核不通过'
  },
  COUNT_AUDIT: {
    value: '3',
    text: '待财务复核'
  },
  COUNT_AUDIT_FAILURE: {
    value: '-2',
    text: '结算审核不通过'
  },
  FINANCE_AUDIT: {
    value: '4',
    text: '待财务复核'
  },
  FINANCE_AUDIT_FAILURE: {
    value: '-3',
    text: '财务复核不通过'
  },
  FINANCE_MANAGER_AUDIT: {
    value: '5',
    text: '待出金打印'
  },
  FINANCE_MANAGER_AUDIT_FAILURE: {
    value: '-4',
    text: '财务复核不通过'
  },
  PRINT: {
    value: '6',
    text: '待集团资金付款'
  },
  MONEY_AUDIT: {
    value: '7',
    text: '付息完成'
  },
  MONEY_AUDIT_FAILURE: {
    value: '-6',
    text: '集团资金付款不通过'
  },
  DELETE: {
    value: '-99',
    text: '已删除'
  }
};