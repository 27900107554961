var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            data: _vm.attachmentList,
            height: "250",
            "row-style": { height: "20px" },
            "cell-style": { padding: "6px 0" }
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "fileName", label: "附件名", width: "300" }
          }),
          _c("el-table-column", {
            attrs: { prop: "fileType", label: "类型", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-popconfirm",
                      {
                        attrs: { title: "确定删除该附件吗？" },
                        on: {
                          confirm: function($event) {
                            return _vm.clickRemove(row)
                          }
                        }
                      },
                      [
                        _vm.isAttachmentEdit
                          ? _c("el-button", {
                              attrs: {
                                slot: "reference",
                                type: "danger",
                                plain: "",
                                circle: "",
                                size: "mini",
                                icon: "el-icon-delete-solid"
                              },
                              slot: "reference"
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v("  "),
                    _c("el-button", {
                      attrs: {
                        plain: "",
                        size: "mini",
                        circle: "",
                        icon: "el-icon-download"
                      },
                      on: {
                        click: function($event) {
                          return _vm.clickDownload(row)
                        }
                      }
                    }),
                    _c("el-button", {
                      attrs: {
                        plain: "",
                        size: "mini",
                        circle: "",
                        icon: "el-icon-headset"
                      },
                      on: {
                        click: function($event) {
                          return _vm.clickPlay(row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.isAttachmentEdit
        ? _c(
            "el-button",
            {
              attrs: { plain: "", size: "mini", icon: "el-icon-upload" },
              on: {
                click: function($event) {
                  return _vm.clickUpload()
                }
              }
            },
            [_vm._v("上传")]
          )
        : _vm._e(),
      _c("attachment-upload", {
        attrs: {
          "upload-contract-id": _vm.attachmentContractId,
          "upload-type": _vm.attachmentType,
          "upload-exist": _vm.tableData
        },
        on: { "refresh-upload": _vm.loadData },
        model: {
          value: _vm.showUploadDialog,
          callback: function($$v) {
            _vm.showUploadDialog = $$v
          },
          expression: "showUploadDialog"
        }
      }),
      _c("attachment-audio-player", {
        attrs: {
          url: _vm.audio.url,
          param: _vm.audio.param,
          "audio-name": _vm.audio.audioName
        },
        model: {
          value: _vm.showAudioPlayerDialog,
          callback: function($$v) {
            _vm.showAudioPlayerDialog = $$v
          },
          expression: "showAudioPlayerDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }