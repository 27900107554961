export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    /** 新增任务 */
    GET_ID_CARD_INFO: function GET_ID_CARD_INFO(data) {
      return request({
        url: url.utils.getIdCardInfo,
        data: data
      });
    }
  };
});