var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          "table-label": _vm.tableLabel,
          table: _vm.tableConfig,
          "http-config": _vm.httpConfig,
          "filters-formatter": _vm.filtersFormatter
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        }
      }),
      _c(
        "j-dialog",
        {
          attrs: { visible: _vm.dialogVisible, title: _vm.dialogTitle },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            confirm: _vm.dialogConfirm
          }
        },
        [
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: { column: 1, colon: true, border: "" }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "活动类型" } }, [
                _vm._v(_vm._s(_vm.dialogFormData.activityTypeText))
              ]),
              _c("el-descriptions-item", { attrs: { label: "主办方" } }, [
                _vm._v(_vm._s(_vm.dialogFormData.orgName))
              ]),
              _c("el-descriptions-item", { attrs: { label: "活动时间" } }, [
                _vm._v(
                  _vm._s(_vm.dialogFormData.startTime) +
                    " ~ " +
                    _vm._s(_vm.dialogFormData.endTime)
                )
              ]),
              _c("el-descriptions-item", { attrs: { label: "活动地点" } }, [
                _vm._v(_vm._s(_vm.dialogFormData.address))
              ]),
              _c("el-descriptions-item", { attrs: { label: "活动预算" } }, [
                _vm._v(" " + _vm._s(_vm.dialogFormData.budget) + " ")
              ]),
              _vm.dialogFormData.activityType === "3"
                ? _c("el-descriptions-item", { attrs: { label: "业绩目标" } }, [
                    _vm._v(_vm._s(_vm.dialogFormData.targetAmountText))
                  ])
                : _vm._e(),
              _vm.dialogFormData.activityType === "3"
                ? _c("el-descriptions-item", { attrs: { label: "活动需求" } }, [
                    _vm._v(_vm._s(_vm.dialogFormData.activityRequires) + " ")
                  ])
                : _vm._e(),
              _c("el-descriptions-item", { attrs: { label: "备注" } }, [
                _vm._v(_vm._s(_vm.dialogFormData.remark))
              ]),
              _c("el-descriptions-item", { attrs: { label: "活动对象" } }, [
                _vm._v(
                  _vm._s(
                    _vm.dialogFormData.isCusRestrict
                      ? _vm.dialogFormData.customerName
                      : "部门全体客户"
                  )
                )
              ]),
              _c("el-descriptions-item", { attrs: { label: "活动积分" } }, [
                _vm._v(
                  _vm._s(_vm.dialogFormData.isScores === 1 ? "开启" : "关闭")
                )
              ]),
              _c("el-descriptions-item", { attrs: { label: "签到积分" } }, [
                _vm._v(_vm._s(_vm.dialogFormData.signInScores))
              ]),
              _c("el-descriptions-item", { attrs: { label: "评价积分" } }, [
                _vm._v(_vm._s(_vm.dialogFormData.commentScores))
              ]),
              _c("el-descriptions-item", { attrs: { label: "已审核角色" } }, [
                _vm._v(_vm._s(_vm.dialogFormData.auditRoles))
              ]),
              _c("el-descriptions-item", { attrs: { label: "审批" } }, [
                true
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "1" },
                            model: {
                              value: _vm.dialogFormData.auditStatus,
                              callback: function($$v) {
                                _vm.$set(_vm.dialogFormData, "auditStatus", $$v)
                              },
                              expression: "dialogFormData.auditStatus"
                            }
                          },
                          [_vm._v("审核通过")]
                        ),
                        _c(
                          "el-radio",
                          {
                            attrs: { label: "0" },
                            model: {
                              value: _vm.dialogFormData.auditStatus,
                              callback: function($$v) {
                                _vm.$set(_vm.dialogFormData, "auditStatus", $$v)
                              },
                              expression: "dialogFormData.auditStatus"
                            }
                          },
                          [_vm._v("审核不通过")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm.dialogFormData.auditStatus === "0"
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "拒绝原因" } },
                    [
                      _c("template", { slot: "label" }, [
                        _c("p", { staticClass: "must-star" }, [
                          _vm._v("拒绝原因")
                        ])
                      ]),
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.dialogFormData.auditRemark,
                              callback: function($$v) {
                                _vm.$set(_vm.dialogFormData, "auditRemark", $$v)
                              },
                              expression: "dialogFormData.auditRemark"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible2,
            title: _vm.dialogTitle2,
            height: "100px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible2 = $event
            },
            confirm: _vm.dialogConfirm2,
            close: _vm.close
          }
        },
        [
          _c(
            "div",
            { staticClass: "alertbox" },
            [
              _c("el-alert", {
                attrs: {
                  title: _vm.title,
                  type: "warning",
                  closable: false,
                  center: ""
                }
              })
            ],
            1
          ),
          _c("j-form", {
            ref: "form2",
            attrs: {
              formConfig: _vm.dialogFormConfig2,
              formData: _vm.dialogFormData2
            },
            on: {
              "update:formData": function($event) {
                _vm.dialogFormData2 = $event
              },
              "update:form-data": function($event) {
                _vm.dialogFormData2 = $event
              },
              submit: _vm.dialogSubmit2,
              change: _vm.auditStatusChangeBatch
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }