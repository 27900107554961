var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        top: "5vh",
        width: "520px",
        title: "划款日志",
        modal: false,
        visible: _vm.showDialog
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            "row-style": { height: "20px" },
            "cell-style": { padding: "6px 0" },
            height: "200"
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "50" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "actualTransferDate",
              label: "实划日期",
              width: "100"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "transferAmount",
              label: "实划金额",
              width: "100",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "transferState", label: "状态", width: "80" }
          }),
          _c("el-table-column", {
            attrs: { prop: "transferRemark", label: "备注" }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }