



































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/commissionAgency/collect/components/dialog-summary-list.vue"
}
