//到期与清算
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 到期查询
{
  path: "".concat(baseRoute, "/clear/expire"),
  name: 'clear-expire',
  meta: {
    title: '清算>到期查询',
    auth: true,
    cache: true
  },
  component: _import('crm/clear/expire')
}, // 到期清算/提前终止
{
  path: "".concat(baseRoute, "/clear/submit"),
  name: 'clear-submit',
  meta: {
    title: '清算>到期/终止',
    auth: true,
    cache: true
  },
  component: _import('crm/clear/submit')
}, // 清算结算审核

/* 结算和财务合并
{
    path: `${baseRoute}/clear/count`,
    name: 'clear-count',
    meta: {
        title: '清算>结算审核',
        auth: true,
        cache: true,
    },
    component: _import('crm/clear/count'),
},
*/
// 清算财务审核
{
  path: "".concat(baseRoute, "/clear/finance"),
  name: 'clear-finance',
  meta: {
    title: '清算>结算审核',
    auth: true,
    cache: true
  },
  component: _import('crm/clear/finance')
}, // 清算与终止查询
{
  path: "".concat(baseRoute, "/clear/search"),
  name: 'clear-search',
  meta: {
    title: '清算>查询',
    auth: true,
    cache: true
  },
  component: _import('crm/clear/search')
}];