import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import VISIT from "@/maps/enum/status/visit";
import dialogMixin from "@/mixins/dialog";
import DialogUpload from "./dialog-upload";
import Contract from "@/components/business/contract";
import { doVisit } from "@api/crm/buy/visit";
export default {
  name: "buy-visit-service-audit",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    DialogUpload: DialogUpload,
    Contract: Contract
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      showContractDialog: false,
      //显示原合同
      showUploadDialog: false,
      // 展示上传对话框
      collapseActive: ["1"],
      //信息折叠默认展开
      selectData: {
        audit: []
      },
      applyId: "",
      rules: {
        status: [{
          required: true,
          message: "请选择审核结果",
          trigger: "input"
        }]
      }
    };
  },
  computed: {},
  created: function created() {
    //审核结果
    for (var key in VISIT) {
      var item = VISIT[key];

      if (item.value != "0") {
        this.selectData.audit.push(item);
      }
    }
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.$set(this.formModel, "id", this.propsModel.id);
        } else {
          this.formModel = {};
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 确定
    confirm: function confirm() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = _objectSpread({}, _this.formModel);

          if (_this.formModel.nextRangTime != null) {
            param.nextTimeStart = _this.formModel.nextRangTime[0];
            param.nextTimeEnd = _this.formModel.nextRangTime[1];
          }

          _this.loading = true;
          doVisit(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit("refresh");
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    clickUpload: function clickUpload() {
      this.showUploadDialog = true;
      this.applyId = this.propsModel.id;
    },
    uploadSuccess: function uploadSuccess(val) {
      this.$message({
        message: "录音已上传",
        type: "success"
      });
      this.formModel.fileName = val.name;
      this.formModel.filePath = val.path;
    },
    //查看原合同  
    clickOriginal: function clickOriginal() {
      this.showContractDialog = true;
    }
  }
};