


































































































export default function (Component) {
  Component.options.__source = "src/views/crm/transfer/dialog-log.vue"
}
