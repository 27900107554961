import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.object.to-string.js";
import store from '@/store';
import axios from 'axios';
import { Message } from 'element-ui';
import util from '@/libs/util'; // 创建一个错误

function errorCreate(msg) {
  var error = new Error(msg);
  errorLog(error);
  throw error;
} // 记录和显示错误


function errorLog(error) {
  // 添加到日志
  store.dispatch('d2admin/log/push', {
    message: '数据请求异常',
    type: 'danger',
    meta: {
      error: error
    }
  }); // 打印到控制台

  if (process.env.NODE_ENV === 'development') {
    util.log.danger('>>>>>> Error >>>>>>');
    console.log(error);
  } // 显示提示


  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
} // 创建一个 axios 实例


var service = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 60000 // 请求超时时间,1分钟

}); // 创建二进制流的实例

var blobService = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 300000,
  // 请求超时时间，5分钟
  responseType: 'blob'
}); // 创建二进制流的实例

var htmlService = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 300000 // 请求超时时间，5分钟
  // responseType: 'blob',

}); // TODO 发起请求成功拦截

function requestSuccess(config) {
  // 在请求发送之前做一些处理
  var token = util.cookies.get('token'); // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改

  config.headers['X-Token'] = token || ''; // config.headers['authorization'] = token || ''

  return config;
} // TODO 发起请求失败拦截


function requestError(error) {
  // 发送失败
  console.log(error);
  return Promise.reject(error);
} // TODO 响应成功


function responseSuccess(response) {
  // dataAxios 是 axios 返回数据中的 data
  var dataAxios = response.data;
  var code = dataAxios.code,
      body = dataAxios.body,
      message = dataAxios.message,
      _dataAxios$head = dataAxios.head,
      head = _dataAxios$head === void 0 ? {} : _dataAxios$head;

  switch (code) {
    case '1':
      // [ 示例 ] code === 1 代表没有错误    
      return body;

    case '101':
      // 登录失效/已过期  
      store.dispatch('d2admin/account/logout', {}, {
        root: true
      });
      break;

    case '401':
      // 登录失效/已过期  
      store.dispatch('d2admin/account/logout', {}, {
        root: true
      });
      break;

    case '403':
      // 没有权限 
      window.location.href = "".concat(window.origin, "/#/403");
      break;

    default:
      // 不是正确的 code
      // errorCreate(`${message}: ${response.config.url}`)
      errorCreate(message);
      break;
  } // // 请求是否成功
  // if (!success) {
  //   // let { resultMessage, resultCode } = head;
  //   switch (Number(code)) {
  //     // 登录失效/已过期
  //     case 101:
  //       store.dispatch('d2admin/account/logout', {}, { root: true });
  //       break;
  //   }
  //   errorCreate(message)
  // } else {
  //   // 有 code 代表这是一个后端接口 可以进行进一步的判断
  //   switch (Number(code)) {
  //     case 1:
  //       // [ 示例 ] code === 1 代表没有错误
  //       return body
  //     case 401:
  //       // 登录失效
  //       store.dispatch('d2admin/account/logout', {}, { root: true });
  //       break
  //     default:
  //       // 不是正确的 code
  //       // errorCreate(`${message}: ${response.config.url}`)
  //       errorCreate(message)
  //       break
  //   }
  // }

} // TODO 响应失败


function responseError(error) {
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        error.message = '请求错误';
        break;

      case 401:
        error.message = '未授权，请登录';
        break;

      case 403:
        error.message = '拒绝访问';
        break;

      case 404:
        error.message = "\u8BF7\u6C42\u5730\u5740\u51FA\u9519: ".concat(error.response.config.url);
        break;

      case 408:
        error.message = '请求超时';
        break;

      case 500:
        error.message = '服务器内部错误';
        break;

      case 501:
        error.message = '服务未实现';
        break;

      case 502:
        error.message = '网关错误';
        break;

      case 503:
        error.message = '服务不可用';
        break;

      case 504:
        error.message = '网关超时';
        break;

      case 505:
        error.message = 'HTTP版本不受支持';
        break;

      default:
        break;
    }
  }

  errorLog(error);
  return Promise.reject(error);
} // 请求拦截器


service.interceptors.request.use(requestSuccess, requestError);
blobService.interceptors.request.use(requestSuccess, requestError);
htmlService.interceptors.request.use(requestSuccess, requestError); // 响应拦截器

service.interceptors.response.use(responseSuccess, responseError);
blobService.interceptors.response.use(function (response) {
  var promise = new Promise(function (resolve, reject) {
    var size = response.data.size;

    if (size < 500) {
      // 文件过小，可能是错误信息
      var reader = new FileReader();
      reader.readAsText(response.data);

      reader.onload = function (e) {
        var error = JSON.parse(e.target.result);
        reject(error.message);
      };
    } else {
      resolve(response.data);
    }
  });
  return promise;
}, responseError);
htmlService.interceptors.response.use(function (response) {
  return response;
}, responseError);
export var blobRequest = blobService;
export var htmlRequest = htmlService;
export default service;