var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c("j-form", {
        ref: "form",
        attrs: {
          type: "2",
          "is-row": "",
          loading: _vm.submiting,
          "form-config": _vm.formConfig,
          "form-data": _vm.formModel,
          footer: _vm.footerConfig
        },
        on: {
          "update:formData": function($event) {
            _vm.formModel = $event
          },
          "update:form-data": function($event) {
            _vm.formModel = $event
          },
          submit: _vm.formSubmit
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }