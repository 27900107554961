// 发布状态：0: 未发布，1：已发布，2：草稿，3：待审核，4：审核不通过
var EnumReleaseStatus = {
  /** 未发布 */
  UN_RELEASE: 0,

  /** 已发布 */
  RELEASE: 1,

  /** 草稿 */
  DRAFT: 2,

  /** 待审核 */
  WAITING: 3,

  /** 审核不通过 */
  REJECT: 4
};
export default EnumReleaseStatus;