













































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/rm/system/system/menu/components/dialog-add.vue"
}
