import "core-js/modules/es.object.to-string.js";
//查询条件
import { listCacheOrg } from "@api/common/cache";
import { listEnum as listEnumProduct } from "@api/crm/setup/product";
import YESNO from "@/maps/enum/status/yesNo";
var VAL_WIDTH = '150px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '合同编号',
  valueKey: 'contractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '客户',
  valueKey: 'customerName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '产品',
  valueKey: 'arrayProductId',
  value: [],
  type: 'select',
  method: filterProduct,
  params: {},
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '机构名称',
  valueKey: 'orgCd',
  value: '',
  type: 'cascader',
  method: listCacheOrg,
  params: {},
  options: [],
  props: {
    //expandTrigger: 'hover',
    checkStrictly: true,
    emitPath: false,
    showAllLevels: false,
    value: 'orgCd',
    label: 'orgName',
    children: 'children'
  }
}, {
  label: '到期日期',
  valueKey: 'endDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'endDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '结算状态',
  valueKey: 'hadEndSettle',
  value: '0',
  type: 'select',
  options: [{
    value: '0',
    text: '否'
  }, {
    value: '1',
    text: '是'
  }],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}];
/**
 * 产品列表
 */

function filterProduct() {
  var promise = new Promise(function (resolve, reject) {
    var param = {
      query: {
        isByDay: YESNO.NO.value
      }
    };
    listEnumProduct(param).then(function (res) {
      var array = res.data;
      resolve(array);
    }).catch(function (res) {
      reject(res);
    });
  });
  return promise;
}