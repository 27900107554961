//划款
export default {
  list: '/transfer/list',
  // 划款列表
  transfer: '/transfer/transfer',
  // 划款
  listContractTransfer: '/transfer/listContractTransfer',
  //合同各次划款列表
  listTransferLog: '/transfer/listTransferLog',
  //划款详情历史列表
  exportExcel: '/transfer/exportExcel' // 导出Excel

};