import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import YESNO from "@/maps/enum/status/yesNo";
import dialogMixin from "@/mixins/dialog";
import { formatAmountToNumber, formatAmountToString } from "@/libs/crm/format";
import { addApplication, listPlanDetail } from "@api/crm/commission/commission";
import { list as personList } from "@api/rm/person";
export default {
  name: "commission-new-add",
  components: {},
  props: {
    propsModel: {
      type: Object,
      default: {}
    }
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      formModel: {},
      //表单
      tableData: [],
      selectData: {
        person: []
      },
      rules: {},
      currentRow: {},
      // 当前点击行
      currentIndex: 0
    };
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 获取列表
    loadData: function loadData() {
      var _this = this;

      var param = {
        planId: this.propsModel.planId
      };
      this.loading = true;
      listPlanDetail(param, DATA_FORMAT_TYPE.NO).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data;

        for (var i = 0; i < _this.tableData.length; i++) {
          //金额格式化千分位
          _this.tableData[i].bonus = formatAmountToString(_this.tableData[i].bonus);
          _this.tableData[i].deduct = formatAmountToString(_this.tableData[i].deduct);
        }
      });
    },
    changePerson: function changePerson(index, row) {
      var n = {}; //原下拉框选项

      n = this.selectData.person.find(function (item) {
        return item.personuuid === row.bonusUserName;
      });

      if (n != null) {
        this.tableData[index].bonusUserId = n.personuuid;
        this.tableData[index].bonusUserName = n.name;
      }
    },
    //删除行
    clickRemoveRow: function clickRemoveRow(index, row) {
      this.tableData.splice(index, 1);
    },
    confirm: function confirm() {
      var _this2 = this;

      // this.$refs.form.validate((valid) => {
      //   if (valid) {          
      //删除没有填的项
      var arrayDetail = [];

      for (var i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].bonusUserName != null && this.tableData[i].bonusUserName != '') {
          var n = Object.assign({}, this.tableData[i]);
          n.bonus = formatAmountToNumber(n.bonus); //转回数字格式

          arrayDetail.push(n);
        }
      }

      if (this.tableData.length == 0) {
        this.$message("请选择推介费人员");
      } else {
        var param = {
          object: {
            contractId: this.propsModel.contractId,
            planId: this.propsModel.planId,
            remark: this.model.remark
          },
          arrayDetail: arrayDetail
        };
        this.loading = true;
        addApplication(param).then(function (res) {
          _this2.loading = false;
          _this2.showDialog = false;

          _this2.$emit("refresh");
        }).catch(function (e) {
          _this2.loading = false;
        });
      } //   }
      // });      

    },
    //搜索用户
    searchPerson: function searchPerson(key) {
      var _this3 = this;

      if (key != "") {
        var param = {
          query: {
            name: key //enabled: '1'            

          }
        };
        personList(param).then(function (res) {
          _this3.selectData.person = res.data;
        }).catch(function (e) {
          _this3.loading = false;

          _this3.$message({
            message: "查询用户出错",
            type: "error"
          });
        });
      }
    }
  }
};