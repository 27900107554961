





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/assign/new/public/components/dialog-add-xtxm.vue"
}
