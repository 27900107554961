import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
// TODO 校验身份证
export default function (rule, value, callback) {
  var _rule$required = rule.required,
      required = _rule$required === void 0 ? false : _rule$required;
  var regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

  if (required && !value) {
    callback(new Error('身份证不能为空'));
  } else if (!regIdCard.test(value)) {
    callback(new Error('身份证号填写有误'));
  } else {
    callback();
  }
}