// 消息提醒
import request from '@/plugin/axios';
import func from '@/maps/url/crm/remind/remind'; // TODO 列表

export function list(params) {
  var re = request.post(func.list, {
    body: params
  });
  return re;
} // TODO 用户赎回、付息需要出金的消息提醒

export function listPayment(params) {
  var re = request.post(func.listPayment, {
    body: params
  });
  return re;
} // 工具：获取当前手工模式是否启动

export function getManualModel(params) {
  var re = request.post(func.getManualModel, {
    body: params
  });
  return re;
} // 工具：手动启动

export function setManualModel(params) {
  var re = request.post(func.setManualModel, {
    body: params
  });
  return re;
}