// import ENUM from '@/maps/enum/enum';
// import { getEnumList } from '@api/common/common';
var VAL_WIDTH = '120px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '年份',
  valueKey: 'fiscalYear',
  value: '',
  type: 'date',
  props: {
    type: 'year',
    valueFormat: 'yyyy',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '部门编号',
  valueKey: 'orgCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '部门名称',
  valueKey: 'orgName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}];