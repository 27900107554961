import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { add, edit } from "@api/crm/setup/productRate";
export default {
  name: "dialog-product-rate-add",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {// DynamicForm
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      tableDataRate: [],
      //列表数组
      rules: {
        productId: [{
          required: true,
          message: "请填写产品Id",
          trigger: "input"
        }],
        amountLower: [{
          required: true,
          message: "请填写资金下限",
          trigger: "input"
        }],
        amountUpper: [{
          required: true,
          message: "请填写资金上限",
          trigger: "input"
        }]
      }
    };
  },
  computed: {
    // 弹窗展示的标题
    title: function title() {
      return this.isEdit ? "修改" : "新增";
    },
    // 是否为编辑
    isEdit: function isEdit() {
      var productId = this.propsModel.productId;
      return !!productId;
    }
  },
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();

          if (this.isEdit) {
            this.getInfo();
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.formModel = {};
      this.tableDataRate = []; //初始化表格

      var tb = [];
      var tbItem = {
        month: 0,
        rate: 0,
        lower: 0,
        upper: 0,
        returnPeriod: 0
      };

      for (var i = 0; i < 15; i++) {
        //复制10条
        var formItem = Object.assign({}, tbItem);
        tb[i] = formItem;
      }

      this.$set(this, "tableDataRate", tb);
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 获取详情
    getInfo: function getInfo() {
      this.formModel = Object.assign({}, this.propsModel); //复制新对象

      var arr = JSON.parse(this.formModel.rate);
      var tb = Object.assign({}, this.tableDataRate);

      for (var i = 0; i < arr.length; i++) {
        var fromItem = arr[i]; //从数据库查得

        tb[i].month = parseInt(fromItem.month);
        tb[i].rate = parseFloat(fromItem.rate);
        tb[i].lower = fromItem.lower == null ? 0 : parseFloat(fromItem.lower);
        tb[i].upper = fromItem.upper == null ? 0 : parseFloat(fromItem.upper);
        tb[i].returnPeriod = fromItem.returnPeriod == null ? 0 : parseInt(fromItem.returnPeriod);
      }
    },
    // TODO 确定
    confirm: function confirm() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = {
            object: _this.formModel
          }; //表格

          var arr = [];

          _this.tableDataRate.forEach(function (n) {
            if (n.rate > 0) {
              arr.push(n);
            }
          });

          var strArr = JSON.stringify(arr);
          param.object.rate = strArr;
          var method = _this.isEdit ? edit : add;
          _this.loading = true;
          method(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit("refresh");
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};