//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '机构',
  prop: 'orgName',
  width: '210px'
}, {
  label: '状态',
  prop: 'status',
  width: VAL_WIDTH,
  type: 'slot'
}, {
  label: '创建日期',
  prop: 'createDate',
  width: VAL_WIDTH
}, {
  label: '推介费合计',
  prop: 'sumBonus',
  width: '150px',
  align: 'right'
}, {
  label: '年化合计',
  prop: 'sumYearAmount',
  width: '150px',
  align: 'right'
}];