




































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/gift/apply/service/components/dialog-audit-batch.vue"
}
