import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import SearchUser from "@/components/business/search-user";
import { getInfo as getContractInfo } from "@/api/crm/buy/buy";
import { addApplication } from "@api/crm/commission/commission";
export default {
  name: "commission-close-debt",
  components: {
    SearchUser: SearchUser
  },
  props: {
    propsModel: {
      type: Object,
      default: {}
    }
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      formModel: {},
      //表单
      tableData: [],
      rules: {},
      currentRow: {},
      // 当前点击行
      currentIndex: 0
    };
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 获取列表
    loadData: function loadData() {
      this.tableData.push({
        titleId: "i0",
        title: "顾问",
        percent: 0,
        bonus: 0,
        bonusUserId: "",
        bonusUserName: ""
      });
      this.tableData.push({
        titleId: "i1",
        title: "团队总监",
        percent: 0,
        bonus: 0,
        bonusUserId: "",
        bonusUserName: ""
      });
      this.tableData.push({
        titleId: "i4",
        title: "营业部副总经理",
        percent: 0,
        bonus: 0,
        bonusUserId: "",
        bonusUserName: ""
      });
      this.tableData.push({
        titleId: "i2",
        title: "营业部总经理",
        percent: 0,
        bonus: 0,
        bonusUserId: "",
        bonusUserName: ""
      });
      this.tableData.push({
        titleId: "i3",
        title: "城市总/区域总",
        percent: 0,
        bonus: 0,
        bonusUserId: "",
        bonusUserName: ""
      });
    },
    clickSearchUser: function clickSearchUser(index, row) {
      this.currentIndex = index;
      this.currentRow = row;
    },
    popSelectUser: function popSelectUser(user) {
      //给查找经纪人子组件选中时调
      this.tableData[this.currentIndex].bonusUserId = user.personuuid;
      this.tableData[this.currentIndex].bonusUserName = user.name;
      this.$refs["popover-" + this.currentIndex].doClose(); //关闭人员查询
    },
    confirm: function confirm() {
      var _this = this;

      if (this.formModel.contractId == null) {
        this.$message("请填写合同编号");
      } else {
        var isSubmit = false; //检查是否有缺少人员的情况

        for (var i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].bonusUserId != null && this.tableData[i].bonusUserId != "" && this.tableData[i].bonus != 0) {
            //列表有任何一条有推介费人员和金额
            isSubmit = true;
            break;
          }
        }

        if (!isSubmit) {
          this.$message("请设置推介费人员和金额");
        } else {
          var param = {
            object: {
              contractId: this.formModel.contractId,
              remark: this.formModel.remark
            },
            arrayDetail: this.tableData
          };
          this.loading = true;
          addApplication(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit("refresh");
          }).catch(function (e) {
            _this.loading = false;
          });
        }
      }
    },
    //合同编号变更，检查合同是否存在
    changeContractCd: function changeContractCd() {
      var _this2 = this;

      if (this.formModel.contractCd != "") {
        var param = {
          contractCd: this.formModel.contractCd
        };
        getContractInfo(param).then(function (res) {
          _this2.model = res.data;
          _this2.formModel.contractId = _this2.model.contractId;
        });
      }
    },
    //变更推介费比例，金额
    changeBonus: function changeBonus(event) {
      if (this.model != null) {
        var amount = parseFloat(this.model.amount); //合同金额

        if (event.column.property == "percent") {
          //修改比例项，自动计算金额
          var bonus = Math.round(amount * parseFloat(event.row.percent) / 100 * 100) / 100;
          this.$set(event.row, "bonus", bonus); //预期现金流
        } else if (event.column.property == "bonus") {
          //修改金额项，自动计算比例
          var percent = Math.round(parseFloat(event.row.bonus) / amount * 100 * 100) / 100;
          this.$set(event.row, "percent", percent); //预期现金流
        }
      }
    }
  }
};