var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          filtersFormatter: _vm.filtersFormatter,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-row-change": _vm.tableRowChange,
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        }
      }),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.activityRewardVisible,
            title: "活动奖励",
            width: "57%"
          },
          on: {
            "update:visible": function($event) {
              _vm.activityRewardVisible = $event
            },
            confirm: _vm.activityRewardSubmit,
            close: _vm.closejForm
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [_c("div", [_vm._v(" " + _vm._s("") + " ")])]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "award" }, [
            _c(
              "div",
              { staticClass: "top" },
              [
                _c(
                  "el-checkbox",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "marketingActivity-updateVirtualGiftStatus",
                        expression:
                          "'marketingActivity-updateVirtualGiftStatus'"
                      }
                    ],
                    staticClass: "left",
                    on: { change: _vm.chanCirtuaChecked },
                    model: {
                      value: _vm.virtualChecked,
                      callback: function($$v) {
                        _vm.virtualChecked = $$v
                      },
                      expression: "virtualChecked"
                    }
                  },
                  [_vm._v("虚拟礼品 ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: "virtualGift-add",
                        expression: "'virtualGift-add'"
                      }
                    ],
                    attrs: { type: "primary" },
                    on: { click: _vm.addGift }
                  },
                  [_vm._v("添加礼品")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "botton" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableDatas },
                    on: { "cell-click": _vm.addYardage }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "name", label: "奖品名称", width: "100" }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponCount",
                        label: "券码数量",
                        width: "100"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "beginTime",
                        label: "生效时间",
                        width: "100"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "endTime",
                        label: "失效时间",
                        width: "100"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponReceivedCount",
                        label: "券码已领取数量",
                        width: "150"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [_vm._v(_vm._s(scope.row.couponReceivedCount))]
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponUnReceiveCount",
                        label: "券码未领取数量",
                        width: "150"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [_vm._v(_vm._s(scope.row.couponUnReceiveCount))]
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "virtualGift-importCouponCode",
                                    expression: "'virtualGift-importCouponCode'"
                                  }
                                ],
                                staticClass: "el-icon-circle-plus-outline",
                                staticStyle: { color: "#409eff" }
                              })
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "existReceiveLimit",
                        label: "是否限制",
                        width: "120"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "10px" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.existReceiveLimit === 0
                                        ? "不限制"
                                        : "限制"
                                    )
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "receiveCustomerTypeValue",
                        label: "适用客户类型",
                        width: "150"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "100" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: "virtualGift-delete",
                                      expression: "'virtualGift-delete'"
                                    }
                                  ],
                                  attrs: { type: "text", size: "small" },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.deleteRow(scope.row)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "j-dialog",
        {
          attrs: { visible: _vm.addGiftVisible, title: "新增礼品" },
          on: {
            "update:visible": function($event) {
              _vm.addGiftVisible = $event
            },
            confirm: _vm.addGiftSubmit,
            close: _vm.addGiftclose
          }
        },
        [
          _c("j-form", {
            ref: "form",
            attrs: {
              "form-config": _vm.addFormConfig,
              "form-data": _vm.addFormData,
              "foot-active": ""
            },
            on: {
              "update:formData": function($event) {
                _vm.addFormData = $event
              },
              "update:form-data": function($event) {
                _vm.addFormData = $event
              },
              submit: function($event) {
                return _vm.FormSubmit($event)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "existReceiveLimit",
                fn: function() {
                  return [
                    _c("div", [
                      _c(
                        "div",
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "0" },
                              model: {
                                value: _vm.existReceiveLimit,
                                callback: function($$v) {
                                  _vm.existReceiveLimit = $$v
                                },
                                expression: "existReceiveLimit"
                              }
                            },
                            [_vm._v("不限制，所有人可领")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              on: { change: _vm.customerTypeChange },
                              model: {
                                value: _vm.existReceiveLimit,
                                callback: function($$v) {
                                  _vm.existReceiveLimit = $$v
                                },
                                expression: "existReceiveLimit"
                              }
                            },
                            [_vm._v("按客户类型")]
                          )
                        ],
                        1
                      ),
                      _vm.existReceiveLimit === "1"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "select",
                                  attrs: {
                                    placeholder: "请选择客户类型",
                                    multiple: ""
                                  },
                                  model: {
                                    value: _vm.customerTypeValue,
                                    callback: function($$v) {
                                      _vm.customerTypeValue = $$v
                                    },
                                    expression: "customerTypeValue"
                                  }
                                },
                                _vm._l(_vm.customerTypeOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "footer",
                fn: function() {
                  return [_c("div", [_vm._v(" " + _vm._s("") + " ")])]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.yardageVisible,
            title: "新增劵码",
            height: "400%"
          },
          on: {
            "update:visible": function($event) {
              _vm.yardageVisible = $event
            },
            close: _vm.hide,
            confirm: _vm.upSubmit
          }
        },
        [
          _c("div", { staticClass: "yardage" }, [
            _c("div", { staticClass: "yardage-item" }, [
              _c("div", [_vm._v("发放数量：")]),
              _c("span", [
                _c("span", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.activityRewardVirtualRowdata.couponCount))
                ]),
                _vm._v(" 张")
              ])
            ]),
            _c("div", { staticClass: "yardage-item" }, [
              _c("div", [_vm._v("已发放数量：")]),
              _c("span", [
                _c("span", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(_vm.activityRewardVirtualRowdata.couponReceivedCount)
                  )
                ]),
                _vm._v(" 张")
              ])
            ]),
            _c("div", { staticClass: "yardage-item" }, [
              _c("div", [_vm._v("剩余数量：")]),
              _c("span", [
                _c("span", { staticClass: "text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.activityRewardVirtualRowdata.couponUnReceiveCount
                    )
                  )
                ]),
                _vm._v(" 张")
              ])
            ]),
            _c("div", { staticClass: "yardage-upload" }, [
              _c("div", { staticClass: "title" }, [_vm._v("生成卷码：")]),
              _c(
                "div",
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.uploadUrl,
                        "auto-upload": false,
                        accept: ".csv,.xls,.xlsx",
                        "on-remove": _vm.handleRemove,
                        "before-remove": _vm.beforeRemove,
                        multiple: "",
                        limit: 1,
                        "on-exceed": _vm.handleExceed,
                        "file-list": _vm.fileList,
                        "on-change": _vm.handleChange,
                        "before-upload": _vm.beforeUpload,
                        "on-error": _vm.failed
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            type: "primary",
                            icon: "el-icon-plus"
                          }
                        },
                        [_vm._v("上传卷码")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip"
                        },
                        [
                          _vm._v(
                            " 只能上传.csv,.xls,.xlsx文件，单次只能引入一个文件！ "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "up-Excel",
                      attrs: {
                        href: "/static/导入券码模板.xlsx",
                        download: "导入券码模板"
                      }
                    },
                    [_vm._v("下载导入模板")]
                  )
                ],
                1
              )
            ])
          ])
        ]
      ),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.pageAddressVisible,
            title: "页面地址",
            width: "40%",
            height: "250px"
          },
          on: {
            "update:visible": function($event) {
              _vm.pageAddressVisible = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.pageAddressVisible = false
                        }
                      }
                    },
                    [_vm._v("关闭")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            { staticClass: "pageAdderssBox" },
            [
              _c("div", { staticClass: "text" }, [_vm._v("小程序页面地址：")]),
              _c("el-input", {
                staticClass: "input",
                model: {
                  value: _vm.pageAddressValue,
                  callback: function($$v) {
                    _vm.pageAddressValue = $$v
                  },
                  expression: "pageAddressValue"
                }
              }),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.copy } },
                [_vm._v("复制链接")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }