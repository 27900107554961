var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        top: "30vh",
        width: "500px",
        title: _vm.title,
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "参数名", prop: "paramName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.paramName,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "paramName", $$v)
                          },
                          expression: "formModel.paramName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "参数值", prop: "paramValue" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.paramValue,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "paramValue", $$v)
                          },
                          expression: "formModel.paramValue"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否启用", prop: "enabled" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-text": "是",
                          "inactive-text": "否",
                          "active-value": "1",
                          "inactive-value": "0"
                        },
                        model: {
                          value: _vm.formModel.enabled,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "enabled", $$v)
                          },
                          expression: "formModel.enabled"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "参数描述", prop: "paramDesc" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.paramDesc,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "paramDesc", $$v)
                          },
                          expression: "formModel.paramDesc"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: { click: _vm.confirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }