var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        title: "音频播放",
        top: "35vh",
        width: "25%",
        modal: false,
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.audio.waiting,
              expression: "audio.waiting"
            }
          ],
          staticClass: "di main-wrap"
        },
        [
          _c("audio", {
            ref: "audio",
            staticClass: "dn",
            attrs: { src: _vm.audioUrl, preload: _vm.audio.preload },
            on: {
              play: _vm.onPlay,
              error: _vm.onError,
              waiting: _vm.onWaiting,
              pause: _vm.onPause,
              timeupdate: _vm.onTimeupdate,
              loadedmetadata: _vm.onLoadedmetadata
            }
          }),
          _c("div", [
            _c("span", { staticClass: "demonstration" }, [
              _vm._v(_vm._s(_vm.audioName))
            ]),
            _c(
              "div",
              { staticClass: "audio-process-container" },
              [
                _c("el-tag", { attrs: { type: "info" } }, [
                  _vm._v(_vm._s(_vm._f("formatSecond")(_vm.audio.currentTime)))
                ]),
                _c("el-slider", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.controlList.noProcess,
                      expression: "!controlList.noProcess"
                    }
                  ],
                  attrs: { "format-tooltip": _vm.formatProcessToolTip },
                  on: { change: _vm.changeCurrentTime },
                  nativeOn: {
                    mousedown: function($event) {
                      return _vm.onDraging.apply(null, arguments)
                    }
                  },
                  model: {
                    value: _vm.sliderTime,
                    callback: function($$v) {
                      _vm.sliderTime = $$v
                    },
                    expression: "sliderTime"
                  }
                }),
                _c("el-tag", { attrs: { type: "info" } }, [
                  _vm._v(_vm._s(_vm._f("formatSecond")(_vm.audio.maxTime)))
                ])
              ],
              1
            )
          ]),
          _c(
            "div",
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "播放/暂停",
                    placement: "bottom"
                  }
                },
                [
                  _c("el-button", {
                    attrs: { circle: "", size: "mini", icon: _vm.playIcon },
                    on: { click: _vm.startPlayOrPause }
                  })
                ],
                1
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "快进",
                    placement: "bottom"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.controlList.noSpeed,
                          expression: "!controlList.noSpeed"
                        }
                      ],
                      attrs: { round: "", size: "mini" },
                      on: { click: _vm.changeSpeed }
                    },
                    [_vm._v(_vm._s(_vm._f("transSpeed")(_vm.audio.speed)))]
                  )
                ],
                1
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "静音/放音",
                    placement: "bottom"
                  }
                },
                [
                  _c("el-button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.controlList.noMuted,
                        expression: "!controlList.noMuted"
                      }
                    ],
                    attrs: { circle: "", size: "mini", icon: _vm.voiceIcon },
                    on: { click: _vm.startMutedOrNot }
                  })
                ],
                1
              ),
              _c("el-slider", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.controlList.noVolume,
                    expression: "!controlList.noVolume"
                  }
                ],
                staticClass: "slider",
                attrs: { "format-tooltip": _vm.formatVolumeToolTip },
                on: { change: _vm.changeVolume },
                model: {
                  value: _vm.volume,
                  callback: function($$v) {
                    _vm.volume = $$v
                  },
                  expression: "volume"
                }
              })
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }