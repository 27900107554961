//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '姓名',
  prop: 'customerName',
  width: VAL_WIDTH
}, {
  label: '联系方式',
  prop: 'mobile',
  width: '130px'
}, {
  label: '合同编号',
  prop: 'contractCd',
  width: '150px'
}, {
  label: '合同状态',
  prop: 'contractStateText',
  width: '80px'
}, {
  label: '产品',
  prop: 'productName',
  width: '150px'
}, {
  label: '合同金额',
  prop: 'amount',
  width: '120px',
  align: 'right'
}, {
  label: '开始日期',
  prop: 'startDate',
  width: VAL_WIDTH
}, {
  label: '结束日期',
  prop: 'endDate',
  width: VAL_WIDTH
}, {
  label: '期限(月)',
  prop: 'timeLimit',
  width: '80px'
}, {
  label: '返还周期',
  prop: 'returnPeriod',
  width: '80px'
}, {
  label: '所属机构',
  prop: 'orgName',
  width: '210px'
}, {
  label: '申请时间',
  prop: 'appointDateTime',
  width: '190px'
}, {
  label: '申请备注',
  prop: 'applyDesc',
  width: '150px'
}, {
  label: '回访结果',
  prop: 'statusText',
  width: '80px'
}, {
  label: '客服意见',
  prop: 'customerEvaluate',
  width: '190px'
}];