//帐户对帐
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [//开户明细，对帐
{
  path: "".concat(baseRoute, "/bill/contract"),
  name: 'bill-contract',
  meta: {
    title: '帐单>开户明细',
    auth: true,
    cache: true
  },
  component: _import('crm/bill/contract')
} // //开户礼品查询 
// {
//     path: `${baseRoute}/bill/gift`,
//     name: 'bill-gift',
//     meta: {
//         title: '帐单>礼品查询',
//         auth: true,
//         cache: true,
//     },
//     component: _import('crm/bill/gift'),
// },
];