var VAL_WIDTH = '130px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '文档名称',
  valueKey: 'fileName',
  value: '',
  type: 'input',
  props: {
    style: {
      width: VAL_WIDTH
    }
  }
}];