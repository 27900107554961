//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BasicInfo from '../components/basicInfo.vue';
import api from '@/api-new';
import ElePermission from '@/store/modules/d2admin/modules/elePermission';
export default {
  components: {
    BasicInfo: BasicInfo
  },
  data: function data() {
    return {
      activeName: 'second',
      basicInfo: {},
      businessInfo: {},
      detailInfo: {},
      labelInfoList: [],
      info: {}
    };
  },
  methods: {
    // 判断是否拥有权限
    isHasPermission: function isHasPermission(value) {
      var permission = ElePermission.state.permission;

      if (permission && permission.length > 0) {
        return permission.indexOf(value) > -1;
      } else {
        return false;
      }
    },
    getDetail: function getDetail() {
      var _this = this;

      var query = this.$route.query;
      api.PAYMEN_FOLLOW_GETINFO({
        id: query.id
      }).then(function (res) {
        _this.info = res;
        _this.businessInfo = res;
      });
    },
    goBack: function goBack() {
      this.$router.push({
        name: 'customerFollow'
      });
    }
  },
  created: function created() {
    this.getDetail();
  }
};