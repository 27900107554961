var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        top: "5vh",
        width: "930px",
        title: "快递信息",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            "row-style": { height: "20px" },
            "cell-style": { padding: "6px 0" },
            height: "250"
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "contractCd", label: "合同编号", width: "160" }
          }),
          _c("el-table-column", {
            attrs: { prop: "customerName", label: "客户姓名", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { prop: "productName", label: "产品", width: "170" }
          }),
          _c("el-table-column", {
            attrs: { prop: "timeLimit", label: "期限", width: "60" }
          }),
          _c("el-table-column", {
            attrs: { prop: "startDate", label: "开始日期", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "endDate", label: "结束日期", width: "100" }
          }),
          _c("el-table-column", { attrs: { prop: "orgName", label: "机构" } })
        ],
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("快递信息")
      ]),
      _c(
        "el-row",
        { staticClass: "block-col-3" },
        [
          _c("el-col", { attrs: { span: 7 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("发件日期:")]),
            _vm._v(" " + _vm._s(_vm.propsModel.sendDate) + " ")
          ]),
          _c("el-col", { attrs: { span: 7 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("快递公司:")]),
            _vm._v(" " + _vm._s(_vm.propsModel.expressCompanyName) + " ")
          ]),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c("span", { staticClass: "d2-fwb" }, [_vm._v("单号:")]),
              _c(
                "el-link",
                {
                  attrs: {
                    href: _vm.expressUrl,
                    type: "primary",
                    target: "_blank"
                  }
                },
                [_vm._v(_vm._s(_vm.propsModel.expressNo))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "block-col-3" },
        [
          _c("el-col", { attrs: { span: 7 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("发件方:")]),
            _vm._v(" " + _vm._s(_vm.propsModel.sendOrgName) + " ")
          ]),
          _c("el-col", { attrs: { span: 7 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("发件人:")]),
            _vm._v(" " + _vm._s(_vm.propsModel.sender) + " ")
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: { click: _vm.sign }
            },
            [_vm._v("签 收")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }