/**
 * 权限
 */
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'rm';
export default [// 产品
{
  path: "".concat(baseRoute, "/system/system/right/product"),
  name: 'right-product',
  meta: {
    title: '产品权限',
    auth: true,
    cache: true
  },
  component: _import('rm/system/system/right/product')
}, // 机构
{
  path: "".concat(baseRoute, "/system/system/right/org"),
  name: 'right-org',
  meta: {
    title: '机构权限',
    auth: true,
    cache: true
  },
  component: _import('rm/system/system/right/org')
}, //菜单
{
  path: "".concat(baseRoute, "/system/system/right/menu"),
  name: 'right-menu',
  meta: {
    title: '菜单权限',
    auth: true,
    cache: true
  },
  component: _import('rm/system/system/right/menu')
}];