
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/commissionV3/components/service-add.vue"
}
