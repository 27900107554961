import "core-js/modules/es.object.to-string.js";
// 客户信息
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import { formatDate, formatUserDataPromise } from '@/libs/crm/format';
import util from '@/libs/util';
import func from '@/maps/url/crm/customer/customer'; // TODO 列表. formatType格式化类型：0不格式化，1只格式化金额，2按用户保密设置设置

export function list(params, formatType) {
  var re = request.post(func.list, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 保存

export function save(params) {
  var re = request.post(func.save, {
    body: params
  });
  return re;
} // TODO 删除

export function remove(params) {
  var re = request.post(func.remove, {
    body: params
  });
  return re;
} // TODO 详情. formatType格式化类型：0不格式化，1只格式化金额，2按用户保密设置设置

export function getInfo(params, formatType) {
  var re = request.post(func.getInfo, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '客户' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO VIP客户生日礼品列表. formatType格式化类型：0不格式化，1只格式化金额，2按用户保密设置设置

export function listBirthdayBonus(params, formatType) {
  var re = request.post(func.listBirthdayBonus, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 保存VIP客户生日礼品申请

export function saveBirthdayBonus(params) {
  var re = request.post(func.saveBirthdayBonus, {
    body: params
  });
  return re;
} // TODO 删除VIP客户生日礼品申请

export function removeBirthdayBonus(params) {
  var re = request.post(func.removeBirthdayBonus, {
    body: params
  });
  return re;
} // TODO VIP客户生日礼品导出Excel

export function exportExcelBirthdayBonus(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelBirthdayBonus, {
    body: params
  }).then(function (blob) {
    util.download(blob, 'VIP客户生日奖励' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}