var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("d2-container", { staticClass: "page-table" }, [
    _c("div", { staticClass: "detail-head" }, [
      _c(
        "div",
        { staticClass: "detail-head-title" },
        [
          _c("i", {
            staticClass: "el-icon-arrow-left",
            on: { click: _vm.goBack }
          }),
          _vm._v(" 客户：" + _vm._s(_vm.basicInfo.customerName) + " "),
          _vm._l(_vm.labelInfoList, function(tag) {
            return _c(
              "el-tag",
              {
                key: tag.name,
                staticClass: "detail-head-tag",
                attrs: { color: tag.backgroundColor, size: "mini" }
              },
              [_vm._v(" " + _vm._s(tag.name) + " ")]
            )
          })
        ],
        2
      ),
      _c("div", { staticClass: "detail-head-desc" }, [
        _c("div", [
          _vm._v("理财顾问：" + _vm._s(_vm.businessInfo.agentUserName))
        ]),
        _c("div", [
          _vm._v("客户类型：" + _vm._s(_vm.businessInfo.customerTypeValue))
        ]),
        _c("div", [
          _vm._v(
            " 距上次跟进：" +
              _vm._s(_vm.businessInfo.followIntervalDay) +
              " " +
              _vm._s(_vm.businessInfo.lastFollowTime) +
              " "
          )
        ]),
        _vm.businessInfo.recentContractExpireDay
          ? _c("div", [
              _vm._v(
                " 合同到期时间： 还剩" +
                  _vm._s(_vm.businessInfo.recentContractExpireDay) +
                  "天 " +
                  _vm._s(_vm.businessInfo.recentContractExpireTime) +
                  " "
              )
            ])
          : _vm._e()
      ])
    ]),
    _c(
      "div",
      [
        _c(
          "el-tabs",
          {
            model: {
              value: _vm.activeName,
              callback: function($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName"
            }
          },
          [
            _vm.isHasPermission("customer-dynamicLog")
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "动态", name: "first" } },
                  [_c("Dynamic")],
                  1
                )
              : _vm._e(),
            _c(
              "el-tab-pane",
              { attrs: { label: "基础信息", name: "second" } },
              [
                _c("BasicInfo", {
                  attrs: { info: _vm.info },
                  on: { refresh: _vm.getDetail }
                })
              ],
              1
            ),
            _vm.isHasPermission("customer-contract")
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "客户合同", name: "third" } },
                  [_c("Contract")],
                  1
                )
              : _vm._e(),
            _c(
              "el-tab-pane",
              { attrs: { label: "客户跟进", name: "fourth" } },
              [_c("Follow")],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "理顾变动记录", name: "userid" } },
              [_c("Counselor")],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }