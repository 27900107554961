import STORE from "@/maps/enum/store"; //消息提醒功能

export default {
  namespaced: true,
  state: {
    remindArray: [],
    //消息数组
    remindTotal: 0 //消息总数    

  },
  mutations: {
    /**
     * 从缓存中同步消息列表数组和总数
     
    syncRemind(state) {
        let strRemind = sessionStorage.getItem(STORE.CRM_REMIND);
        if (strRemind != null) {
            let array = JSON.parse(strRemind);
            state.remindArray = array;
            state.remindTotal = 0;
            for (let i = 0; i < array.length; i++) {
                let n = array[i];
                state.remindTotal += n.count;
            }
        }
    },
    */

    /**
     * 审核操作后更新消息提醒数量
     * @param {} menuCd 菜单编号
     
    updateRemind(state, menuCd) {
        let strRemind = sessionStorage.getItem(STORE.CRM_REMIND);
        if (strRemind != null) {
            let array = JSON.parse(strRemind);
            for (let i = 0; i < array.length; i++) {
                let n = array[i];
                if (n.menuCd == menuCd) { //当前菜单处理数-1
                    if (n.count > 0) {
                        n.count = n.count - 1;
                        state.remindTotal = state.remindTotal - 1;
                    }*/

    /*只能传入一个参数，下一步数量影响不大
    if (nextMenuCd != null && nextMenuCd != "") { //下一步流程菜单
        for (let j = 0; j < array.length; j++) {
            let nextN = array[j];
            if (nextN.menuCd == nextMenuCd) {
                if (nextN.count > 0) {
                    nextN.count = nextN.count + 1;
                    state.remindTotal = state.remindTotal + 1;
                }
            }
        }
    }*/

    /*
                            sessionStorage.setItem(STORE.CRM_REMIND, JSON.stringify(array));
                            break;
                        }
                    }
                    state.remindArray = array;
                }
            }*/
  }
};