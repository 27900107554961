import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import dialogMixin from "@/mixins/dialog";
import { listArchive, removeArchive } from "@api/crm/paper/box";
export default {
  name: "paper-box-paper",
  components: {},
  props: {
    propsModel: {
      type: Object,
      default: {}
    }
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      tableData: [],
      query: {},
      checkboxRows: [],
      model: {
        contractCd: "",
        //合同编号
        customerName: "",
        //客户姓名
        orgName: "" //机构名称

      },
      selectItem: {} //选择的项

    };
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {//this.$refs.form.resetFields();     
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.query = {
        contractCd: this.model.contractCd,
        customerName: this.model.customerName,
        orgName: this.model.orgName
      };
      this.loadData();
    },
    // TODO 获取列表
    loadData: function loadData() {
      var _this = this;

      this.query.boxNo = this.propsModel.boxNo;
      this.loading = true;
      listArchive({
        page: {
          pageNumber: 1,
          pageSize: 9999
        },
        query: this.query
      }, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data;
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    //复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      this.checkboxRows = rows;
    },
    remove: function remove() {
      var _this2 = this;

      if (this.checkboxRows.length == 0) {
        this.$message("请选择合同");
      } else {
        this.$confirm("移除后面合同将重新顺序编号，确定将选中合同移出 " + this.propsModel.boxName + " 柜子吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          var arrayArchiveId = [];

          _this2.checkboxRows.forEach(function (item) {
            arrayArchiveId.push(item.archiveId);
          });

          var param = {
            arrayArchiveId: arrayArchiveId,
            isResetOther: true
          };
          _this2.loading = true;
          removeArchive(param).then(function (res) {
            _this2.loading = false;
            _this2.showDialog = false;

            _this2.$emit("refresh");
          });
        }).catch(function () {});
      }
    }
  }
};