// 转让
export default {
  create: 'assign/create',
  // 创建
  save: 'assign/save',
  // 保存
  submit: 'assign/submit',
  // 提交
  auditService: 'assign/auditService',
  // 客服审核
  auditCount: 'assign/auditCount',
  // 结算审核
  remove: 'assign/remove',
  // 删除
  getInfo: 'assign/getInfo',
  // 详情
  list: 'assign/list',
  // 列表
  exportExcel: 'assign/exportExcel' // 导出Excel

};