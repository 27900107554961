var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          filtersFormatter: _vm.filtersFormatter,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        }
      }),
      _c(
        "j-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
              modifiers: { fullscreen: true, lock: true }
            }
          ],
          attrs: {
            visible: _vm.addVisible,
            title: _vm.addTitle,
            width: "35%",
            height: "700px"
          },
          on: {
            "update:visible": function($event) {
              _vm.addVisible = $event
            },
            confirm: _vm.addDialogConfirm,
            close: _vm.close
          }
        },
        [
          _c("j-form", {
            ref: "form",
            attrs: {
              "form-config": _vm.addFormConfig,
              "form-data": _vm.addFormData,
              "foot-active": false
            },
            on: {
              "update:formData": function($event) {
                _vm.addFormData = $event
              },
              "update:form-data": function($event) {
                _vm.addFormData = $event
              },
              change: _vm.formChange,
              submit: function($event) {
                return _vm.FormSubmit($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }