
































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/setup/product/product/add/yield.vue"
}
