import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import crmMixin from "@/mixins/crm";
import LOG_TYPE from "@/maps/enum/contract-log-type";
import ATTACHMENT_TYPE from "@/maps/enum/attachment-type";
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import Attachment from "@/components/business/attachment";
import ContractLog from "@/components/business/contract-log";
import ContractGoldWeight from "@/components/business/contract-gold-weight";
import DialogHistory from "../../dialog-history";
import { setExtendAttribute } from "@/libs/crm/crm";
import { formatAmountToNumber } from "@/libs/crm/format";
import { getInfo as _getInfo, save, submit } from '@api/crm/interest/interest';
export default {
  name: "interest-new-new",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Attachment: Attachment,
    ContractLog: ContractLog,
    DialogHistory: DialogHistory,
    ContractGoldWeight: ContractGoldWeight
  },
  mixins: [dialogMixin, crmMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tabActive: "tabContract",
      //标签框默认选中
      collapseActive: ["1"],
      //信息折叠默认展开
      attachmentType: ATTACHMENT_TYPE.CONTRACT_STAFF,
      //附件类型
      attachmentTypeReceptionBill: ATTACHMENT_TYPE.RECEPTION_BILL,
      //附件类型：回单凭证
      attachmentTypeInterestApply: ATTACHMENT_TYPE.INTEREST_STAFF,
      //附件类型：付息申请
      showLogDialog: false,
      showHistoryDialog: false,
      contractLogType: LOG_TYPE.INTEREST,
      attachmentOpened: '',
      //附件打开状态
      selectData: {
        customerVisitClaim: [] //客户是否接受回访

      },
      formModel: {},
      //表单
      tempFormModel: {},
      // 暂存表单值
      rules: {
        settlementsDates: [{
          required: true,
          message: "请填写付息日期",
          trigger: "input"
        }],
        interest: [{
          required: true,
          message: "请填写付息金额",
          trigger: "input"
        }],
        customerVisitClaim: [{
          required: true,
          message: "请选择客户回访",
          trigger: "input"
        }]
      }
    };
  },
  created: function created() {
    this.selectData.customerVisitClaim = [{
      value: "0",
      text: "否"
    }, {
      value: "1",
      text: "是"
    }];
  },
  computed: {
    //判断表单内容是否变化
    isFormChange: function isFormChange() {
      var strTempForm = JSON.stringify(this.tempFormModel);
      var strForm = JSON.stringify(this.formModel);
      return strTempForm != strForm;
    }
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.getInfo();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tabActive = "tabContract"; //默认打开第一个标签页

      this.formModel.customerVisitClaim = "0"; //默认是否接受回访

      this.formModel.feeDeductType = "3"; //默认提货费用
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.showDialog = false;
      this.$refs.form.resetFields();
      this.$emit('refresh');
    },
    onAttachmentOpen: function onAttachmentOpen() {
      //初始化标签
      this.tabActive = "tabContract";
      this.attachmentOpened = 'dialog-position';
    },
    // TODO 确定
    clickSave: function clickSave(result) {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          // let attachmentInterestApply = this.$refs.componentAttachmentInterestApply.tableData.length; //附件数
          // if (attachmentInterestApply == 0) {
          //   this.$message("请上传付息申请资料附件");
          // } else 
          if (_this.checkForm()) {
            var param = {
              ipdId: _this.model.ipdId,
              interest: _this.formModel.interest,
              interestRemark: _this.formModel.interestRemark,
              customerVisitClaim: _this.formModel.customerVisitClaim,
              customerVisitRemark: _this.formModel.customerVisitRemark,
              feeDeductType: _this.formModel.feeDeductType,
              goldFee: _this.formModel.goldFee
            };
            _this.loading = true;
            save(param).then(function (data) {
              _this.loading = false; //this.showDialog = false;
              //this.$emit("refresh");

              _this.$message({
                message: '数据已保存',
                type: 'success'
              });

              _this.tempFormModel = Object.assign({}, _this.formModel); //临时表单值，用于比较表单是否变化     
            }).catch(function (e) {
              _this.loading = false;
            });
          }
        }
      });
    },
    //提交
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      //审核通过
      if (this.checkForm()) {
        if (this.isFormChange) {
          //表单有变化，没有暂存就提交
          var param = {
            ipdId: this.model.ipdId,
            interest: this.formModel.interest,
            interestRemark: this.formModel.interestRemark,
            customerVisitClaim: this.formModel.customerVisitClaim,
            customerVisitRemark: this.formModel.customerVisitRemark,
            feeDeductType: this.formModel.feeDeductType,
            goldFee: this.formModel.goldFee
          };
          this.loading = true;
          save(param).then(function (res) {
            var param = {
              ipdId: _this2.formModel.ipdId
            };
            submit(param).then(function (res) {
              _this2.loading = false;
              _this2.showDialog = false;

              _this2.$message({
                message: '已提交',
                type: 'success'
              });

              _this2.dialogClose();
            });
          });
        } else {
          //已暂存过，直接提交
          var _param = {
            ipdId: this.formModel.ipdId
          };
          this.loading = true;
          submit(_param).then(function (res) {
            _this2.loading = false;
            _this2.showDialog = false;

            _this2.$message({
              message: '已提交',
              type: 'success'
            });

            _this2.dialogClose();
          });
        }
      }
    },
    // TODO 获取详情
    getInfo: function getInfo() {
      var _this3 = this;

      var ipdId = this.propsModel.ipdId; //this.loading = true;

      _getInfo({
        ipdId: ipdId
      }, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        //this.loading = false;
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this3.model = data;
        setExtendAttribute(_this3.model); //包装必要展示字段

        _this3.formModel = Object.assign({}, _this3.model); //克隆

        _this3.$set(_this3.formModel, "interest", formatAmountToNumber(_this3.model.interest));

        _this3.tempFormModel = Object.assign({}, _this3.formModel); //临时表单值，用于比较表单是否变化         
      }).catch(function (e) {
        _this3.loading = false;
      });
    },
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    },
    //显示付息历史
    showHistory: function showHistory() {
      this.showHistoryDialog = true;
    },
    //变更付息金额
    changeInterest: function changeInterest() {
      var _this4 = this;

      this.$confirm("系统付息金额为" + this.model.interest + "，是否变更?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {}).catch(function () {
        _this4.$set(_this4.formModel, "interest", formatAmountToNumber(_this4.model.interest));
      });
    },
    //变更是否接受采访
    changeCustomerVisitClaim: function changeCustomerVisitClaim() {
      var val = this.formModel.customerVisitClaim;

      if (val == 0) {
        //不接受
        this.$set(this.formModel, "customerVisitRemark", "客户不方便");
      } else if (val == 1) {
        this.$set(this.formModel, "customerVisitRemark", "");
      }
    },
    //表单必填项检查
    checkForm: function checkForm() {
      var rs = true;

      if (this.formModel.customerVisitClaim == "0" && (this.formModel.customerVisitRemark == "" || this.formModel.customerVisitRemark == null)) {
        this.$message("请填写不需回访原因");
        rs = false;
      }

      return rs;
    }
  }
};