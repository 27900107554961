// 客户
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 客户列表
{
  path: "".concat(baseRoute, "/customer/manage"),
  name: 'customerManage',
  meta: {
    title: '客户管理',
    auth: true,
    cache: true,
    keepAlive: true,
    isBack: false
  },
  component: _import('crm/customer/manage/list')
}, // 客户详情
{
  path: "".concat(baseRoute, "/customer/detail"),
  name: 'customerDetail',
  meta: {
    title: '客户详情',
    auth: true,
    cache: true
  },
  component: _import('crm/customer/manage/detail')
}, {
  path: "".concat(baseRoute, "/customer/follow"),
  name: 'customerFollow',
  meta: {
    title: '客户跟进',
    auth: true,
    cache: true
  },
  component: _import('crm/customer/follow')
}, // 客户跟进详情
{
  path: "".concat(baseRoute, "/customer/follow"),
  name: 'customerFollowDetail',
  meta: {
    title: '客户跟进详情',
    auth: true,
    cache: true
  },
  component: _import('crm/customer/follow/detail')
}, {
  path: "".concat(baseRoute, "/customer/level"),
  name: 'customerLevel',
  meta: {
    title: '客户等级管理',
    auth: true,
    cache: true
  },
  component: _import('crm/customer/level')
}, {
  path: "".concat(baseRoute, "/customer/label"),
  name: 'customerLabel',
  meta: {
    title: '客户标签管理',
    auth: true,
    cache: true
  },
  component: _import('crm/customer/label')
}, {
  path: "".concat(baseRoute, "/customer/h5"),
  name: 'customerH5',
  meta: {
    title: '客户H5账号管理',
    auth: true,
    cache: true
  },
  component: _import('crm/customer/h5')
}, {
  path: "".concat(baseRoute, "/customer/agentAppointment"),
  name: 'agentAppointment',
  meta: {
    title: '顾问预约',
    auth: true,
    cache: true
  },
  component: _import('crm/customer/agentAppointment')
}, {
  path: "".concat(baseRoute, "/customer/paymentSubmission"),
  name: 'customerPaymentSubmission',
  meta: {
    title: '客户打款提报',
    auth: true,
    cache: true
  },
  component: _import('crm/customer/paymentSubmission')
}];