import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import echarts from "echarts";
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import DynamicForm from "@/components/common/dynamic-form";
import { formatDate } from "@/libs/crm/format";
import { sumAudit } from "@api/crm/report/report";
export default {
  name: "sum-audit",
  components: {
    DynamicForm: DynamicForm
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS
    };
  },
  computed: {},
  mounted: function mounted() {
    var date = new Date();
    date.setDate(1);
    var beginDate = formatDate(date);
    this.$refs.form.$data.model.dateBegin = beginDate;
    this.loadData();
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.loadData();
    },
    // TODO 获取列表
    loadData: function loadData() {
      var _this = this;

      this.initFilter();
      var param = {
        query: this.query
      };
      sumAudit(param).then(function (res) {
        if (res.data != null) {
          var data = res.data;
          var echartsData = {
            x: [],
            //x轴数据
            y: [] //y轴数据

          };
          echartsData.x = ["分公司客服", "总部客服", "总部预约", "总部结算", "总部财务", "总部资金", "清算结算", "清算财务"];
          echartsData.y = [data.waitForSubmit, data.waitForServiceAudit, data.waitForReserveAudit, data.waitForCountAudit, data.waitForFinanceAudit, data.waitForMoneyAudit, data.waitForClearCountAudit, data.waitForClearFinanceAudit];

          _this.echartsDraw(echartsData);
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.$message({
          message: "查询数据出错",
          type: "error"
        });
      });
    },
    echartsDraw: function echartsDraw(data) {
      //绘图
      var echartDom = document.getElementById("echartAudtiDom");
      var myChart = echarts.init(echartDom);
      var option = {
        title: {
          text: "合同认购各审核流程统计",
          left: "left",
          subtext: "",
          sublink: ""
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'

          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: data.x
        },
        yAxis: {
          type: "value"
        },
        series: [{
          data: data.y,
          type: "bar"
        }]
      };
      option && myChart.setOption(option);
    }
  },
  created: function created() {}
};