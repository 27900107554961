import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import SearchUser from "@/components/business/search-user";
import ENUM from "@/maps/enum/enum";
import { computeCustomerBirthday } from "@/libs/crm/crm";
import { getEnumList } from "@api/common/common";
import { save } from "@api/crm/customer/customer";
export default {
  name: "dialog-add",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    SearchUser: SearchUser
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      showSearchUser: false,
      selectData: {
        vip: [],
        //vip级别
        sex: [],
        //性别
        idType: [],
        //证件类型
        accountType: [] //帐户类型

      },
      rules: {
        customerName: [{
          required: true,
          message: "请填写客户姓名",
          trigger: "input"
        }],
        idType: [{
          required: true,
          message: "请填写证件类型",
          trigger: "input"
        }],
        identification: [{
          required: true,
          message: "请填写证件号码",
          trigger: "input"
        }],
        mobile: [{
          required: true,
          message: "请选择手机号码",
          trigger: "input"
        }]
      }
    };
  },
  computed: {
    // 弹窗展示的标题
    title: function title() {
      return this.isEdit ? "修改" : "新增";
    },
    // 是否为编辑
    isEdit: function isEdit() {
      var customerId = this.propsModel.customerId;
      return !!customerId;
    }
  },
  created: function created() {
    var _this = this;

    //vip级别
    getEnumList({
      enumCd: ENUM.ENUM_CD_0233
    }).then(function (res) {
      _this.selectData.vip = res;
    }); //性别

    getEnumList({
      enumCd: ENUM.ENUM_CD_0102
    }).then(function (res) {
      _this.selectData.sex = res;
    }); //证件类型

    getEnumList({
      enumCd: ENUM.ENUM_CD_0208
    }).then(function (res) {
      _this.selectData.idType = res;
    }); //帐户类型

    getEnumList({
      enumCd: ENUM.ENUM_CD_0207
    }).then(function (res) {
      _this.selectData.accountType = res;
    });
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();

          if (this.isEdit) {
            this.getInfo();
          } else {
            this.formModel = {
              idType: "01",
              accountType: "1"
            };
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 获取详情
    getInfo: function getInfo() {
      this.formModel = Object.assign({}, this.propsModel); //克隆
    },
    // TODO 确定
    confirm: function confirm() {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = _objectSpread({}, _this2.formModel);

          _this2.loading = true;
          save({
            object: param
          }).then(function (res) {
            _this2.loading = false;
            _this2.showDialog = false;

            _this2.$emit("refresh");
          }).catch(function (e) {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    popSelectUser: function popSelectUser(user) {
      //给查找经纪人子组件选中时调
      this.formModel.agentUserId = user.personuuid;
      this.formModel.agentUserName = user.name;
      this.formModel.orgCd = user.orgCd;
      this.formModel.orgName = user.orgName;
      this.showSearchUser = false;
    },
    changeIdentification: function changeIdentification(val) {
      //变更证件号码，计算客户生日
      if (this.formModel.idType == "01" && this.formModel.identification.length != 18) {
        this.$message({
          message: "证件号位数错误",
          type: "error"
        });
      } else {
        var birthday = computeCustomerBirthday(this.formModel.identification);

        if (birthday != null && birthday != "") {
          this.$set(this.formModel, "birthday", birthday);
        }
      }
    },
    //输入项转大写
    inputToUpperCase: function inputToUpperCase() {
      //身份证号转大写
      if (this.formModel.identification != null && this.formModel.identification != "") {
        this.formModel.identification = this.formModel.identification.toUpperCase();
      }
    }
  }
};