import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter";
import TABLE_LIST from "./option/list";
import DynamicForm from "@/components/common/dynamic-form";
import DynamicTable from "@/components/common/dynamic-table";
import DialogSend from "./components/dialog-send";
import { listStampBack } from "@api/crm/paper/paper";
export default {
  name: "paper-express-back",
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogSend: DialogSend
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {
        multiple: true
      },
      pagination: {},
      query: {},
      tableData: [],
      showSendDialog: false,
      //发快递窗口
      currentRow: {},
      //当前点击行
      canEditStatus: [],
      //可以编辑的状态
      checkboxRows: [] //复选框选中行

    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: "数据已刷新",
        type: "info",
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      var param = {
        page: page,
        query: this.query
      };
      this.loading = true;
      listStampBack(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.tableData = data;
          _this.pagination = {
            total: totalCount
          };
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    //复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      //检查各数据是否是同一机构
      if (rows.length > 0) {
        var orgCd0 = rows[0].orgCd.substring(0, 6); //第一个数据的机构Cd

        for (var i = 0; i < rows.length; i++) {
          if (rows[i].orgCd.indexOf(orgCd0) != 0) {
            this.$message({
              message: "注意：勾选合同属于不同机构",
              type: "warning",
              offset: 180,
              duration: 0,
              showClose: true
            });
            break;
          }
        }
      }

      this.checkboxRows = rows;
    },
    clickBatchSend: function clickBatchSend() {
      if (this.checkboxRows.length == 0) {
        this.$message("请选择合同");
      } else {
        this.showSendDialog = true;
      }
    }
  },
  created: function created() {}
};