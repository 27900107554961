var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'user';
export default [// 用户
{
  path: "".concat(baseRoute, "/info"),
  name: 'user-info',
  meta: {
    title: '用户中心',
    auth: true,
    cache: true
  },
  component: _import('user/info')
}, // 更改密码
{
  path: "".concat(baseRoute, "/password"),
  name: 'user-password',
  meta: {
    title: '更改密码',
    auth: true,
    cache: true
  },
  component: _import('user/password')
}];