


































































































































































































































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/assign/dialog-info.vue"
}
