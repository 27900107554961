







































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/paper/express/receive/components/dialog-receive.vue"
}
