//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '礼品名称',
  prop: 'giftName',
  width: '300px'
}, {
  label: '直营价格',
  prop: 'price',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '机构价格',
  prop: 'specialPrice',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '类型',
  prop: 'kindText',
  width: VAL_WIDTH
}, {
  label: '状态',
  prop: 'status',
  width: VAL_WIDTH,
  type: 'slot'
}, {
  label: '创建日期',
  prop: 'createDate',
  width: '100px'
}, {
  label: '备注',
  prop: 'remark',
  width: '300px'
}];