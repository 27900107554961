var VAL_WIDTH = '120px'; // 表头

export default [{
  label: '文档名称',
  prop: 'fileName',
  width: '600px'
}, {
  label: '创建日期',
  prop: 'createDate',
  width: VAL_WIDTH
}, {
  label: '创建人',
  prop: 'createorName',
  width: VAL_WIDTH
}];