



































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/components/business/conjbf/index.vue"
}
