import "core-js/modules/es.object.to-string.js";
// 合同
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatDate, formatUserDataPromise } from '@/libs/crm/format';
import func from '@/maps/url/crm/service/contract'; //特殊申请
// TODO 特殊申请查询

export function listDataParticular(params, formatType) {
  var re = request.post(func.listDataParticular, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 导出特殊申请查询Excel

export function exporParticular(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exporParticular, {
    body: params
  }).then(function (blob) {
    util.download(blob, '合同特殊申请' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} //附件
// TODO 合同信息和附件统计查询

export function listDataCountAttachment(params, formatType) {
  var re = request.post(func.listDataCountAttachment, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
}