//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import DialogDetail from "./dialog-detail";
import DialogSummary from "./dialog-summary";
export default {
  name: "commission-agency-info",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    DialogDetail: DialogDetail,
    DialogSummary: DialogSummary
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      showSummaryDialog: false,
      batchId: this.propsModel.batchId
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    loadData: function loadData() {},
    //汇总报表
    showSummary: function showSummary() {
      this.batchId = this.propsModel.batchId;
      this.showSummaryDialog = true;
    }
  }
};