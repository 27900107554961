// 发布状态：0: 未发布，1：已发布，2：草稿，3：待审核，4：审核不通过
var EnumAuditStatus = {
  /** 审核通过 */
  PASS: 1,

  /** 待审核 */
  WAITING: 0,

  /** 审核不通过 */
  REJECT: 2
};
export default EnumAuditStatus;