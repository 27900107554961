var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        top: "20vh",
        width: "850px",
        title: "合同附件",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formModel,
                rules: _vm.rules,
                "label-width": "100px",
                size: "mini"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "合同编号", prop: "contractCd" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "input-with-select",
                              attrs: { placeholder: "请输入合同编号" },
                              model: {
                                value: _vm.formModel.contractCd,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "contractCd", $$v)
                                },
                                expression: "formModel.contractCd"
                              }
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.clickLoadContractAttachment()
                                  }
                                },
                                slot: "append"
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: {
                data: _vm.tableData,
                height: "350",
                "row-style": { height: "20px" },
                "cell-style": { padding: "6px 0" },
                "highlight-current-row": ""
              },
              on: { "current-change": _vm.selectRow }
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号" }
              }),
              _c("el-table-column", {
                attrs: { prop: "attachType", label: "模块", width: "120" }
              }),
              _c("el-table-column", {
                attrs: { prop: "fileName", label: "附件名", width: "400" }
              }),
              _c("el-table-column", {
                attrs: { prop: "fileType", label: "类型", width: "80" }
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确定删除该附件吗？" },
                            on: {
                              confirm: function($event) {
                                return _vm.clickRemove(row)
                              }
                            }
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "reference",
                                type: "danger",
                                plain: "",
                                circle: "",
                                size: "mini",
                                icon: "el-icon-delete-solid"
                              },
                              slot: "reference"
                            })
                          ],
                          1
                        ),
                        _vm._v("  "),
                        _c("el-button", {
                          attrs: {
                            plain: "",
                            size: "mini",
                            circle: "",
                            icon: "el-icon-edit"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickRename(row)
                            }
                          }
                        }),
                        _c("el-button", {
                          attrs: {
                            plain: "",
                            size: "mini",
                            circle: "",
                            icon: "el-icon-search"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickBigPreview(row)
                            }
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm.contractId != null
            ? _c(
                "el-button",
                {
                  attrs: { plain: "", size: "mini", icon: "el-icon-upload" },
                  on: {
                    click: function($event) {
                      return _vm.clickUpload()
                    }
                  }
                },
                [_vm._v("上传")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "mini", icon: "el-icon-view" },
              on: {
                click: function($event) {
                  return _vm.clickPreview()
                }
              }
            },
            [_vm._v("看图")]
          ),
          _vm.showBigViewer
            ? _c("el-image-viewer", {
                attrs: {
                  "on-close": _vm.closeBigViewer,
                  "url-list": _vm.bigList,
                  modal: false,
                  zIndex: 9999
                }
              })
            : _vm._e(),
          _c("attachment-upload-manage", {
            attrs: { "upload-contract-id": _vm.contractId },
            on: {
              "refresh-upload": function($event) {
                _vm.init()
                _vm.loadData()
              }
            },
            model: {
              value: _vm.showUploadDialog,
              callback: function($$v) {
                _vm.showUploadDialog = $$v
              },
              expression: "showUploadDialog"
            }
          }),
          _c("attachment-preview", {
            attrs: { "img-list": _vm.previewList },
            model: {
              value: _vm.showPreviewDialog,
              callback: function($$v) {
                _vm.showPreviewDialog = $$v
              },
              expression: "showPreviewDialog"
            }
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "mini" },
                  on: { click: _vm.cancel }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    size: "mini",
                    loading: _vm.loading
                  },
                  on: { click: _vm.confirm }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }