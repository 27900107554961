var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { staticClass: "d2-mr", attrs: { size: "small" } },
    [
      _c("span", { staticClass: "btn-text" }, [
        _vm._v(_vm._s(_vm.info.name ? "你好 " + _vm.info.name : "未登录"))
      ]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            {
              nativeOn: {
                click: function($event) {
                  return _vm.logOff.apply(null, arguments)
                }
              }
            },
            [
              _c("d2-icon", {
                staticClass: "d2-mr-5",
                attrs: { name: "power-off" }
              }),
              _vm._v(" 注销 ")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }