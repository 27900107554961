import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import AUDIT from '@/maps/enum/status/audit';
import { formatAmountToNumber, formatAmountToString } from '@/libs/crm/format';
import { auditFinance } from '@api/crm/count/count';
export default {
  name: 'settle-batch',
  props: {
    propsModel: {
      type: Array,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      showLogDialog: false,
      tableData: [],
      formModel: {},
      //表单
      sum: {},
      //统计
      rules: {}
    };
  },
  created: function created() {},
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.sum = {};
      this.formModel = {};
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    clickAuditFail: function clickAuditFail() {
      //审核不通过
      if (this.formModel.advice == null || this.formModel.advice == '') {
        this.$message('请填写审核意见');
      } else {
        this.audit(AUDIT.NO.value);
      }
    },
    clickAudit: function clickAudit() {
      //审核通过
      this.audit(AUDIT.YES.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this.$confirm("\u672C\u6B21\u64CD\u4F5C\u5408\u540C ".concat(_this.tableData.length, " \u7B14, \u63D0\u53D6\u91D1\u989D\u5408\u8BA1 ").concat(_this.sum.sumApplyBackCash, " \u5143,\n            \u63D0\u53D6\u73B0\u8D27\u514B\u91CD\u5408\u8BA1 ").concat(_this.sum.sumInvestGoldWeight, " \u514B\uFF0C\u7EED\u7EA6\u5408\u540C\u91D1\u989D\u5408\u8BA1 ").concat(_this.sum.sumGoOnAmount, " \u5143\uFF0C\u7EED\u7EA6\u6536\u76CA\u91D1\u989D\u5408\u8BA1 ").concat(_this.sum.sumGoOnEarnings, " \u5143"), '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            var arrayCountId = [];

            _this.tableData.forEach(function (n) {
              arrayCountId.push(n.countId);
            });

            var param = {
              arrayCountId: arrayCountId,
              audit: result,
              advice: _this.formModel.advice
            };
            _this.loading = true;
            auditFinance(param).then(function (res) {
              _this.loading = false;
              _this.showDialog = false;

              _this.$emit('refresh');
            }).catch(function (e) {
              _this.loading = false;
            });
          }).catch(function () {}); // 深度选择器无效 获取实例添加弹出宽度


          var el = document.querySelectorAll('.el-message-box');

          if (el) {
            el.forEach(function (item) {
              item.style.cssText = 'width:1000px';
            });
          }
        } else {
          return false;
        }
      });
    },
    loadData: function loadData() {
      var sumApplyBackCash = 0; // 提取金额汇总

      var sumInvestGoldWeight = 0; // 提取现货克重

      var sumGoOnAmount = 0; // 续约合同金额

      var sumGoOnEarnings = 0; // 续约收益金额

      this.propsModel.forEach(function (n) {
        n.holdDay = null; // n.actualEarnings = null;

        if (n.applyType != '04') {
          sumApplyBackCash += formatAmountToNumber(n.applyBackCash);
        }

        sumInvestGoldWeight += formatAmountToNumber(n.investGoldWeight);
        sumGoOnAmount += formatAmountToNumber(n.goOnAmount);
        sumGoOnEarnings += formatAmountToNumber(n.goOnEarnings);
      });
      this.sum.sumApplyBackCash = formatAmountToString(sumApplyBackCash);
      this.sum.sumInvestGoldWeight = sumInvestGoldWeight;
      this.sum.sumGoOnAmount = formatAmountToString(sumGoOnAmount);
      this.sum.sumGoOnEarnings = formatAmountToString(sumGoOnEarnings);
      this.tableData = Object.assign([], this.propsModel); //复制新对象
    },
    //设置表格统计行
    setSummaryRow: function setSummaryRow() {
      var sumRow = [];
      sumRow[0] = '合计';
      sumRow[1] = '共' + this.tableData.length + '笔';
      sumRow[8] = this.sum.sumApplyBackCash;
      sumRow[9] = this.sum.sumInvestGoldWeight;
      sumRow[16] = this.sum.sumGoOnAmount;
      sumRow[17] = this.sum.sumGoOnEarnings;
      return sumRow;
    }
  }
};