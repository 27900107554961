import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { resetPassword } from "@api/rm/person";
export default {
  data: function data() {
    return {
      password: "",
      rmMessage: ""
    };
  },
  methods: {
    submitClick: function submitClick() {
      var _this = this;

      var exp = /^.*(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])\w{6,}/; //密码至少6位，包含大小写字母和数字

      var isMatch = exp.test(this.password);

      if (!isMatch) {
        this.$message({
          message: "密码至少6位，包含大小写字母和数字",
          type: "warning"
        });
      } else {
        this.$confirm("确定重置密码?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          var param = {
            password: _this.password
          };
          resetPassword(param).then(function (res) {
            _this.$message({
              message: "重置密码成功，请记住新密码",
              type: "success"
            });
          }).catch(function (res) {
            _this.$message({
              message: "重置密码失败",
              type: "error"
            });
          });
        }).catch(function () {});
      }
    }
  }
};