//礼品申请
export default {
  SAVE: {
    value: '0',
    text: '待提交'
  },
  SUBMIT: {
    value: '1',
    text: '待客服审核'
  },
  SERVICE_AUDIT: {
    value: '2',
    text: '待财务审核'
  },
  DELIVER_GOODS: {
    value: '3',
    text: '待客服发货'
  },
  CONFIRM_RECEIPT: {
    value: '4',
    text: '确认收货'
  },
  SUCCESS: {
    value: '5',
    text: '已完成'
  },
  SERVICE_AUDIT_FAILURE: {
    value: '7',
    text: '客服审核不通过'
  },
  FINANCE_AUDIT_FAILURE: {
    value: '8',
    text: '财务审核不通过'
  },
  // CHANGE_SUCCESS: { value: '9', text: '礼品已变更' },
  // CHANGE_SAVE: { value: '20', text: '礼品变更申请待提交' },
  // CHANGE_APPLY: { value: '21', text: '礼品变更申请客服待审核' },
  // CHANGE_APPLY_FAILURE: { value: '22', text: '礼品变更申请客服待审核不通过' },
  DELETE: {
    value: '-99',
    text: '已删除'
  }
};