//银行
export default {
  JS: '0',
  // 建行和平支行
  TL2: '1',
  // 通联2
  GS_XM: '2',
  // 工商香梅支行
  GS_JYF: '3',
  // 工商
  JYFPOS: '4',
  // 金雅福POS
  TL2015: '5',
  // 通联2015
  PA: '6',
  // 平安
  ZX: '7',
  // 中信
  MS: '8',
  // 民生
  GS: '9',
  // 工商
  ZSYB: '10',
  // 工商，中山易宝
  JSBC: '11',
  // 建设，宝城支行
  GLYH: '12',
  // 桂林银行
  MS_JYFWH: '13',
  // 金雅福传承文化首饰民生
  NY_JYFWH: '14',
  // 金雅福传承文化首饰农行
  NY_SSZZ: '15' //首饰制造农业

};