// 弹窗中form表单配置
export var DIALOG_FORM_CONFIG = {
  budgetName: {
    type: 'input',
    label: '项目',
    rules: [{
      required: true,
      message: '项目不能为空',
      trigger: 'blur'
    }]
  },
  budgetAmount: {
    type: 'input',
    label: '价格',
    rules: [{
      required: true,
      message: '价格不能为空',
      trigger: 'blur'
    }],
    props: {
      type: 'number'
    }
  },
  remark: {
    type: 'input',
    label: '备注'
  }
};
export var DIALOG_FORM_DATA = {
  budgetName: '',
  budgetAmount: '',
  remark: ''
};