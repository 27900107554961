var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "40%",
        visible: _vm.showDialog,
        title: "选择用户",
        "append-to-body": "",
        center: ""
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.addUserIdHide
      }
    },
    [
      _c(
        "el-form",
        { attrs: { inline: true, model: _vm.userSelectData, size: "small" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c("el-cascader", {
                attrs: {
                  options: _vm.selectData.orgTreeData,
                  props: _vm.orgProps,
                  placeholder: "请选择部门",
                  filterable: "",
                  clearable: ""
                },
                model: {
                  value: _vm.userSelectData.orgCd,
                  callback: function($$v) {
                    _vm.$set(_vm.userSelectData, "orgCd", $$v)
                  },
                  expression: "userSelectData.orgCd"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入姓名" },
                model: {
                  value: _vm.userSelectData.searchContent,
                  callback: function($$v) {
                    _vm.$set(_vm.userSelectData, "searchContent", $$v)
                  },
                  expression: "userSelectData.searchContent"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.userSearch } },
                [_vm._v("查询")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.checkboxRows.length > 0
        ? _c(
            "el-table",
            {
              ref: "multipleCheckboxRows",
              staticStyle: { width: "100%" },
              attrs: {
                "tooltip-effect": "dark",
                data: _vm.checkboxRows,
                height: "200px"
              }
            },
            [
              _c("el-table-column", { attrs: { width: "55" } }),
              _c("el-table-column", {
                attrs: {
                  prop: "customerName",
                  label: "姓名(选中)",
                  width: "150"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "orgName", label: "部门", width: "300" }
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "80" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              nativeOn: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.deleteRow(
                                    scope.$index,
                                    _vm.checkboxRows
                                  )
                                }
                              }
                            },
                            [_vm._v(" 移除 ")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  355769023
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.userTableData,
            "tooltip-effect": "dark",
            height: "400",
            "row-key": _vm.getRowKey
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", "reserve-selection": true }
          }),
          _c("el-table-column", {
            attrs: { prop: "customerName", label: "姓名", width: "150" }
          }),
          _c("el-table-column", {
            attrs: { prop: "orgName", label: "部门", width: "300" }
          })
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c("el-button", { on: { click: _vm.addUserIdHide } }, [
            _vm._v("取 消")
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.userConfirm } },
            [_vm._v("选择")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }