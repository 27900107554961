//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '合同编号',
  prop: 'contractCd',
  width: '150px'
}, {
  label: '划款状态',
  prop: 'transferState',
  width: VAL_WIDTH,
  type: 'slot'
}, {
  label: '客户',
  prop: 'customerName',
  width: '80px'
}, {
  label: '机构',
  prop: 'orgName',
  width: '210px'
}, {
  label: '合同开始',
  prop: 'startDate',
  width: VAL_WIDTH
}, {
  label: '合同结束',
  prop: 'endDate',
  width: VAL_WIDTH
}, {
  label: '划款类型',
  prop: 'saveTypeText',
  width: '80'
}, {
  label: '金价',
  prop: 'goldPrice',
  width: '80',
  align: 'right'
}, {
  label: '应划款日期',
  prop: 'planTransferDate',
  width: VAL_WIDTH
}, {
  label: '实划日期',
  prop: 'actualTransferDate',
  width: VAL_WIDTH
}, {
  label: '应划款金额',
  prop: 'planTransferAmount',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '实划金额',
  prop: 'transferAmount',
  width: VAL_WIDTH,
  align: 'right'
}];