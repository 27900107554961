// 任务调度管理
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [{
  path: "".concat(baseRoute, "/task"),
  name: 'taskManage',
  meta: {
    title: '任务调度管理',
    auth: true,
    cache: true
  },
  component: _import('crm/task')
}];