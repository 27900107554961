//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '记账日期',
  prop: 'tallyDate',
  width: VAL_WIDTH
}, {
  label: '记账时间',
  prop: 'tallyTime',
  width: VAL_WIDTH
}, {
  label: '贷方',
  prop: 'debtor',
  width: '150px',
  align: 'right'
}, {
  label: '对方户名',
  prop: 'accountName',
  width: '150px'
}, {
  label: '对方账号',
  prop: 'bankAccount',
  width: '180px'
}, {
  label: '银行',
  prop: 'bankName',
  width: '200px'
}, {
  label: '摘要',
  prop: 'info',
  width: '200px'
}, {
  label: '备注',
  prop: 'remark',
  width: '200px'
}];