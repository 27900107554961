var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig,
          filtersData: _vm.filtersData,
          "filters-formatter": _vm.filtersFormatter
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        }
      }),
      _c(
        "j-dialog",
        {
          attrs: { visible: _vm.dialogVisible, title: _vm.dialogTitle },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            confirm: _vm.dialogConfirm
          }
        },
        [
          _c("j-form", {
            ref: "form",
            attrs: {
              formConfig: _vm.dialogFormConfig,
              formData: _vm.dialogFormData
            },
            on: {
              "update:formData": function($event) {
                _vm.dialogFormData = $event
              },
              "update:form-data": function($event) {
                _vm.dialogFormData = $event
              },
              submit: _vm.dialogSubmit
            }
          })
        ],
        1
      ),
      _c(
        "j-dialog",
        {
          attrs: { visible: _vm.dialogDetailVisible, title: "素材详情" },
          on: {
            "update:visible": function($event) {
              _vm.dialogDetailVisible = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: {
                        click: function($event) {
                          _vm.dialogDetailVisible = false
                        }
                      }
                    },
                    [_vm._v("关闭")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("j-form", {
            ref: "form",
            attrs: {
              formConfig: _vm.dialogFormDetail,
              formData: _vm.dialogFormData
            },
            on: {
              "update:formData": function($event) {
                _vm.dialogFormData = $event
              },
              "update:form-data": function($event) {
                _vm.dialogFormData = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }