//系统
export default {
  listEnum: '/system/enum/list',
  // 系统常量设置列表
  saveEnum: '/system/enum/save',
  // 保存常量设置
  removeEnum: '/system/enum/remove',
  // 删除常量设置
  getInfoEnum: '/system/enum/getInfo',
  // 常量设置详情
  listDetail: '/system/detail/list',
  // 系统常量详情列表
  saveDetail: '/system/detail/save',
  // 保存常量详情
  removeDetail: '/system/detail/remove' // 删除常量详情

};