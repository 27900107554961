import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import dialogMixin from "@/mixins/dialog";
import { list as productList } from "@api/crm/setup/product";
import { listNotArchive, archive } from "@api/crm/paper/box";
export default {
  name: "paper-box-archive-search",
  components: {},
  props: {
    propsModel: {
      type: Object,
      default: {}
    }
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      tableData: [],
      checkboxRows: [],
      selectData: {
        product: [] //产品

      },
      query: {
        contractCd: "",
        //合同编号
        customerName: "",
        //客户姓名
        orgName: "",
        //机构名称
        arrayProductId: [] //产品

      },
      selectItem: {} //选择的项

    };
  },
  created: function created() {
    var _this = this;

    var paramProduct = {
      page: {
        pageNumber: 0,
        pageSize: 999
      },
      query: {
        enabled: '1'
      }
    };
    this.loading = true;
    productList(paramProduct).then(function (res) {
      _this.loading = false;
      res.data.forEach(function (n) {
        _this.selectData.product.push({
          value: n.productId,
          text: n.productName
        });
      });
    });
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.query = {
        contractCd: "",
        //合同编号
        customerName: "",
        //客户姓名
        orgName: "" //机构名称

      };
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.loadData();
    },
    // TODO 获取列表
    loadData: function loadData() {
      var _this2 = this;

      this.loading = true;
      listNotArchive({
        page: {
          pageNumber: 1,
          pageSize: 9999
        },
        query: this.query
      }, DATA_FORMAT_TYPE.AMOUNT).then(function (res) {
        _this2.loading = false;
        _this2.tableData = res.data;
      }).catch(function (e) {
        _this2.loading = false;

        _this2.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    //复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      this.checkboxRows = rows;
    },
    confirm: function confirm() {
      var _this3 = this;

      if (this.checkboxRows.length == 0) {
        this.$message("请选择合同");
      } else {
        var left = this.propsModel.capcity - this.propsModel.countContract;

        if (this.checkboxRows.length > left) {
          this.$message({
            message: "柜子空间不足，只剩 " + left,
            type: "warning"
          });
        } else {
          this.$confirm("确定将选中合同归档到 " + this.propsModel.boxName + " 柜子吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(function () {
            var arrayContractId = [];

            _this3.checkboxRows.forEach(function (item) {
              arrayContractId.push(item.contractId);
            });

            var param = {
              boxNo: _this3.propsModel.boxNo,
              arrayContractId: arrayContractId
            };
            _this3.loading = true;
            archive(param).then(function (res) {
              _this3.loading = false;
              _this3.showDialog = false;

              _this3.$emit("refresh");
            });
          }).catch(function () {});
        }
      }
    }
  }
};