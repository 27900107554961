//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '团队',
  prop: 'name',
  width: '150px'
}, {
  label: '归属机构',
  prop: 'orgName',
  width: '180px'
}, {
  label: '总监',
  prop: 'teamManagerName',
  width: '150px'
}, {
  label: '成员',
  prop: 'teamPersonName',
  width: '400px'
}, {
  label: '团队描述',
  prop: 'description',
  width: '200px'
}, {
  label: '更新人',
  prop: 'updater',
  width: VAL_WIDTH
}, {
  label: '更新日期',
  prop: 'updateTime',
  width: '100px'
}];