//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { sysLogList as _sysLogList } from "@api/crm/other/contract-log"; //合同审核日志

export default {
  name: "contract-log",
  props: {
    contractId: {
      type: String,
      default: ""
    },
    extId: {
      type: String,
      default: ""
    },
    active: {
      type: String,
      default: "1"
    }
  },
  data: function data() {
    return {
      loading: false,
      collapseActive: [this.active],
      //信息折叠默认展开
      sysLog: [] //认购日志

    };
  },
  mixins: [dialogMixin],
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val != "") {
          this.init();
          this.sysLogList();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.sysLog = [];
    },
    dialogClose: function dialogClose() {},
    openDialog: function openDialog() {},
    sysLogList: function sysLogList() {
      var _this = this;

      var param = {
        contractId: this.contractId,
        extId: this.extId
      }; //this.loading = true;

      _sysLogList(param).then(function (res) {
        //this.loading = false;
        var data = res.data;
        _this.sysLog = data;
      });
    }
  },
  created: function created() {
    this.loading = false;
  }
};