var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        top: "25vh",
        width: "600px",
        title: "追加录音",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.collapseActive,
            callback: function($$v) {
              _vm.collapseActive = $$v
            },
            expression: "collapseActive"
          }
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { title: "合同信息", name: "1" } },
            [
              _c(
                "el-row",
                { staticClass: "block-col-2" },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [
                      _vm._v("合同编号:")
                    ]),
                    _vm._v(" " + _vm._s(this.propsModel.contractCd) + " ")
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [_vm._v("姓名:")]),
                    _c("span", { staticClass: "d2-emphasis" }, [
                      _vm._v(_vm._s(this.propsModel.customerName))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "block-col-2" },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [
                      _vm._v("联系方式:")
                    ]),
                    _vm._v(" " + _vm._s(this.propsModel.mobile) + " ")
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [
                      _vm._v("预约时间:")
                    ]),
                    _vm._v(" " + _vm._s(this.propsModel.appointDateTime) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "block-col-1" },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [
                      _vm._v("申请备注:")
                    ]),
                    _vm._v(" " + _vm._s(this.propsModel.applyDesc) + " ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "contract-log-tip",
              attrs: { plain: "", size: "mini", icon: "el-icon-upload" },
              on: {
                click: function($event) {
                  return _vm.clickUpload()
                }
              }
            },
            [_vm._v("上传录音")]
          ),
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          )
        ],
        1
      ),
      _c("dialog-upload", {
        attrs: { "upload-id": _vm.applyId },
        on: { "refresh-upload": _vm.uploadSuccess },
        model: {
          value: _vm.showUploadDialog,
          callback: function($$v) {
            _vm.showUploadDialog = $$v
          },
          expression: "showUploadDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }