import "core-js/modules/es.object.to-string.js";
import { SYSTEM_FLAG } from '@/config';
import { computed } from '@vue/composition-api';
var UUID_KEY = "".concat(SYSTEM_FLAG, "-uuid");
/** 使用用户唯一标识 */

export default function useUUID() {
  /**
   * 设置用户唯一标识
   * @param uuid 唯一标识值
   * @returns Promise<uuid>
   */
  var setUUID = function setUUID(uuid) {
    window.localStorage.setItem(UUID_KEY, uuid);
    return Promise.resolve(uuid);
  };
  /** 用户唯一标识  */


  var uuid = computed(function () {
    return window.localStorage.getItem(UUID_KEY) || 'ghost-uuid';
  });
  return {
    setUUID: setUUID,
    uuid: uuid
  };
}