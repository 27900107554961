// 目标管理
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [{
  path: "".concat(baseRoute, "/operationAnalysisH5/managementByObjectives/team"),
  name: 'teamManagement',
  meta: {
    title: '团队业绩目标管理',
    auth: true,
    cache: true
  },
  component: _import('crm/operationAnalysisH5/managementByObjectives/team')
}, {
  path: "".concat(baseRoute, "/operationAnalysisH5/managementByObjectives/personal"),
  name: 'personalManagement',
  meta: {
    title: '个人业绩目标管理',
    auth: true,
    cache: true
  },
  component: _import('crm/operationAnalysisH5/managementByObjectives/personal')
}];