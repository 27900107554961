import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listRight } from '@api/rm/person'; // 经纪人信息

import { listCacheOrg } from '@api/common/cache';
export default {
  name: 'SearchUserDialog',
  components: {},
  props: {
    visible: Boolean,
    title: String,

    /** 是否多选 */
    isMultiple: Boolean
  },
  data: function data() {
    return {
      filters: {
        orgCd: {
          type: 'cascader',
          label: '部门',
          labelWidth: 60,
          col: 8,
          props: {
            placeholder: '请选择',
            filterable: true,
            props: {
              checkStrictly: true,
              emitPath: false,
              value: 'orgCd',
              label: 'orgName',
              children: 'children'
            }
          },
          options: [],
          rules: [{
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }]
        },
        name: {
          type: 'input',
          label: '姓名',
          labelWidth: 50,
          col: 5,
          props: {
            placeholder: '请输入',
            maxlength: 32
          }
        },
        enabled: {
          type: 'select',
          label: '启用',
          labelWidth: 60,
          col: 6,
          props: {
            placeholder: '请选择'
          },
          options: [{
            value: '1',
            label: '在职'
          }, {
            value: '0',
            label: '离职'
          }]
        },
        isCurDept: {
          type: 'select',
          label: '本部门',
          labelWidth: 80,
          col: 6,
          props: {
            placeholder: '请选择'
          },
          options: [{
            value: 1,
            label: '本部门'
          }, {
            value: 2,
            label: '本部门及其下级部门数据'
          }],
          rules: [{
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }]
        }
      },
      filtersData: {
        orgCd: '01',
        enabled: '1',
        isCurDept: 1,
        name: ''
      },
      table: {
        isSelect: true,
        rowKey: 'personuuid'
      },
      tableLabel: [{
        label: '姓名',
        prop: 'name'
      }, {
        label: '机构',
        prop: 'orgName'
      }, {
        label: '工号',
        prop: 'personNum'
      }, {
        label: '职务',
        prop: 'title'
      }, {
        label: '联系方式',
        prop: 'mobile'
      }],
      tableData: [],
      selectItem: null,
      dialogVisible: false,
      loading: false
    };
  },
  emits: ['confirm', 'update:visible'],
  created: function created() {
    var _this = this;

    // 机构列表
    listCacheOrg().then(function (res) {
      _this.filters.orgCd.options = res;
    });
  },
  methods: {
    /** 自定义请求数据事件 */
    loadData: function loadData(params) {
      var _this2 = this;

      this.filtersData = params;
      this.loading = true;
      if (!this.filtersData.orgCd) this.filtersData.orgCd = '01';
      if (!this.filtersData.enabled && this.filtersData.enabled !== 0) this.filtersData.enabled = '1';
      if (!this.filtersData.isCurDept) this.filtersData.isCurDept = 1;
      if (!this.filtersData.name) this.filtersData.name = '';
      var param = {};
      param = {
        page: {
          pageNumber: 0,
          pageSize: 999
        },
        query: {
          enabled: this.filtersData.enabled,
          orgCd: this.filtersData.orgCd,
          name: this.filtersData.name,
          isCurDept: this.filtersData.isCurDept
        }
      };
      listRight(param).then(function (res) {
        _this2.tableDataFormatter(res.data);

        _this2.loading = false;
      }).catch(function (e) {
        _this2.loading = false;
      });
    },

    /** 对查询返回的数据进行处理 */
    tableDataFormatter: function tableDataFormatter(data) {
      this.tableData = data.map(function (item) {
        // 请注意表明字段 让调用方知晓字段使用
        return {
          personuuid: item.personuuid,
          name: item.name,
          orgName: item.orgName,
          personNum: item.personNum,
          title: item.title,
          mobile: item.mobile
        };
      });
    },

    /** 表格勾选事件 */
    selectionChange: function selectionChange(params) {
      if ((params === null || params === void 0 ? void 0 : params.length) > 1 && !this.isMultiple) {
        this.$message.warning('只能单选'); // 清空选中

        this.$refs.j_page.resetTableStatus(); // 切换某行选中
        // this.$refs.j_page.toggleTableRowSelection(this.tableData[1], true)
      }

      if (this.isMultiple) this.selectItem = params;else this.selectItem = params[0];
    },

    /* 确定 */
    confirm: function confirm() {
      this.$emit('update:visible', false);
      this.$emit('confirm', this.selectItem);
    },
    close: function close() {
      this.$emit('update:visible', false);
    }
  }
};