





























































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/activity/manage/list/index.vue"
}
