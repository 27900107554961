export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    /** 导出积分 */
    EXPORT_SCORE_DATA: function EXPORT_SCORE_DATA(data) {
      return request({
        url: url.score.exportScoreData,
        responseType: 'arraybuffer',
        data: data,
        timeout: 60000
      });
    },

    /** 批量调整积分 */
    BATCH_ADJUST_SCORE: function BATCH_ADJUST_SCORE(data) {
      return request({
        url: url.score.batchAdjustScore,
        data: data
      });
    },

    /** 调整积分 */
    ADJUST_SCORE: function ADJUST_SCORE(data) {
      return request({
        url: url.score.adjustScore,
        data: data
      });
    },

    /** 导出积分明细 */
    EXPORT_SCORE_DETAIL_DATA: function EXPORT_SCORE_DETAIL_DATA(data) {
      return request({
        url: url.score.exportScoreDetailData,
        responseType: 'arraybuffer',
        data: data,
        timeout: 60000
      });
    },

    /** 获取积分明细的变动记录列表 */
    GET_CHANGE_LIST: function GET_CHANGE_LIST(data) {
      return request({
        url: url.score.getChangeList,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 获取积分奖励规则获取详情 */
    GET_SCOREINFO: function GET_SCOREINFO() {
      return request({
        url: url.score.getInfo
      });
    },

    /** 获取积分奖励规更新详情 */
    GET_SCOREUPDETE: function GET_SCOREUPDETE(data) {
      return request({
        url: url.score.update,
        data: data
      });
    },
    // 积分规则

    /** 详情 */
    SCORERULE_GETINFO: function SCORERULE_GETINFO(data) {
      return request({
        url: url.scoreRule.getInfo,
        data: data
      });
    },

    /** 更新 */
    SCORERULE_UPDATE: function SCORERULE_UPDATE(data) {
      return request({
        url: url.scoreRule.update,
        data: data
      });
    },
    // 积分任务

    /** 分页数据 */
    SCORETASK_GETPAGE: function SCORETASK_GETPAGE(data) {
      return request({
        url: url.scoreTask.getPage,
        data: data
      });
    },

    /** 新增 */
    SCORETASK_ADD: function SCORETASK_ADD(data) {
      return request({
        url: url.scoreTask.add,
        data: data
      });
    },

    /** 更新 */
    SCORETASK_UPDATE: function SCORETASK_UPDATE(data) {
      return request({
        url: url.scoreTask.update,
        data: data
      });
    },

    /** 详情 */
    SCORETASK_GETINFO: function SCORETASK_GETINFO(data) {
      return request({
        url: url.scoreTask.getInfo,
        data: data
      });
    },

    /** 更新状态 */
    SCORETASK_UPDATESTATUS: function SCORETASK_UPDATESTATUS(data) {
      return request({
        url: url.scoreTask.updateStatus,
        data: data
      });
    },

    /** 删除 */
    SCORETASK_DELETE: function SCORETASK_DELETE(data) {
      return request({
        url: url.scoreTask.delete,
        data: data
      });
    }
  };
});