import "core-js/modules/es.object.to-string.js";
// 纸合同
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatDate, formatUserDataPromise } from '@/libs/crm/format';
import func from '@/maps/url/crm/paper/paper'; // TODO 未盖章合同查询

export function listNoStamp(params, formatType) {
  var re = request.post(func.listNoStamp, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 已盖章合同寄回查询

export function listStampBack(params, formatType) {
  var re = request.post(func.listStampBack, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 寄送快递

export function sendExpress(params) {
  var re = request.post(func.sendExpress, {
    body: params
  });
  return re;
} // TODO 审核查询

export function listAudit(params, formatType) {
  var re = request.post(func.listAudit, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 审核

export function audit(params) {
  var re = request.post(func.audit, {
    body: params
  });
  return re;
} // TODO 已审核合同导出Excel模板

export function exportExcelTemplate(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelTemplate, {
    body: params
  }).then(function (blob) {
    util.download(blob, '纸合同模板' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 购销合同导出Excel模板

export function exportExcelBuyTemplate(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelBuyTemplate, {
    body: params
  }).then(function (blob) {
    util.download(blob, '购销纸合同模板' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 快递信息查询

export function listExpress(params, formatType) {
  var re = request.post(func.listExpress, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 快递签收

export function receive(params) {
  var re = request.post(func.receive, {
    body: params
  });
  return re;
} // TODO 快递关联合同查询

export function listExpressContract(params, formatType) {
  var re = request.post(func.listExpressContract, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 快递编辑

export function editExpress(params) {
  var re = request.post(func.editExpress, {
    body: params
  });
  return re;
} // TODO 纸合同快递信息导出excel

export function exportExcelExpress(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelExpress, {
    body: params
  }).then(function (blob) {
    util.download(blob, '快递信息' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}