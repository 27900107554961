import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import crmMixin from '@/mixins/crm';
import { setExtendAttribute } from '@/libs/crm/crm';
import LOG_TYPE from '@/maps/enum/contract-log-type';
import ContractLog from '@/components/business/contract-log';
import ElePermission from '@/store/modules/d2admin/modules/elePermission';
import ATTACHMENT_TYPE from '@/maps/enum/attachment-type';
import Attachment from '@/components/business/attachment';
import ContractSysLog from '@/components/business/contract-sysLog';
export default {
  name: 'commission-new-add',
  components: {
    ContractLog: ContractLog,
    Attachment: Attachment,
    ContractSysLog: ContractSysLog
  },
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 是否显示无岗人员 默认显示
    isShowNoUser: {
      type: Boolean,
      default: true
    },
    // 是否显示规则 默认显示 但是需权限
    isShowRule: {
      type: Boolean,
      default: true
    },
    isSysLog: {
      type: Boolean,
      default: false
    },
    // 要显示的职级
    arrayTitleId: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 显示职级人员
    bonusUserName: {
      type: String,
      default: ''
    },
    // 是否查询
    isSearch: {
      type: Boolean,
      default: false
    }
  },
  mixins: [dialogMixin, crmMixin],
  data: function data() {
    return {
      loading: false,
      showLogSysLogDialog: false,
      tabActive: 'tabAllocation',
      // 标签框默认选中
      collapseActive: ['1', '2'],
      // 信息折叠默认展开
      model: {},
      formModel: {},
      // 表单
      tempFormModel: {},
      // 暂存表单值
      selectData: {
        person: []
      },
      rules: {},
      currentRow: {},
      // 当前点击行
      currentIndex: 0,
      showLogDialog: false,
      contractLogType: LOG_TYPE.COMMISSION,
      attachmentType: ATTACHMENT_TYPE.COMMISSION // 附件类型

    };
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.formModel = {};
    },
    onAttachmentOpen: function onAttachmentOpen() {
      // 初始化标签
      this.tabActive = 'tabContract';
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    // TODO 获取列表
    loadData: function loadData() {
      this.model = this.propsModel;
      setExtendAttribute(this.model); // 包装必要展示字段

      this.$set(this.formModel, 'isBackOffice', this.propsModel.isBackOffice); // 是否后勤人员 0否 1是
      // 获取列表

      this.changeRadio();
      this.formModel.remark = this.model.remark;
    },
    // 是否后勤人员页面展示
    changeRadio: function changeRadio() {
      if (this.formModel.isBackOffice === '0') {
        this.getTableData(false);
      } else if (this.formModel.isBackOffice === '1') {
        this.getTableData(true);
      }
    },
    // 获取职级列表 true 非后勤人员 false 后勤人员
    getTableData: function getTableData(jedge) {
      var _this = this;

      var arr = [];

      if (jedge) {
        arr = [{
          titleId: 'i0',
          percent: null,
          bonus: null,
          bonusUserId: null
        }, {
          titleId: 'i5',
          percent: null,
          bonus: null,
          bonusUserId: null
        }];
      } else {
        arr = [{
          titleId: 'i0',
          percent: null,
          bonus: null,
          bonusUserId: null
        }, {
          titleId: 'i1',
          percent: null,
          bonus: null,
          bonusUserId: null
        }, {
          titleId: 'i4',
          percent: null,
          bonus: null,
          bonusUserId: null
        }, {
          titleId: 'i2',
          percent: null,
          bonus: null,
          bonusUserId: null
        }, {
          titleId: 'i3',
          percent: null,
          bonus: null,
          bonusUserId: null
        }];
      }

      this.$set(this.formModel, 'tableData', arr); // 表格初始值赋值

      this.formModel.tableData.forEach(function (item, index) {
        if (_this.model.detailList.length > 0) {
          // 遍历职级列表 有值赋值
          _this.model.detailList.forEach(function (j, i) {
            if (item.titleId === j.titleId) {
              _this.formModel.tableData[index] = j;
            }
          });
        }
      }); // 剔除不展示职级

      var bonusUserList = []; // 是否有职级姓名查对应姓名 再查职级

      if (this.bonusUserName) {
        bonusUserList = this.formModel.tableData.filter(function (item) {
          return item.bonusUserName && item.bonusUserName.includes(_this.bonusUserName);
        }); // 查询职级

        if (this.arrayTitleId.length > 0) {
          bonusUserList = bonusUserList.filter(function (item) {
            return _this.showRank(item.titleId);
          });
        }
      } else {
        // 查询职级
        if (this.arrayTitleId.length > 0) {
          bonusUserList = this.formModel.tableData.filter(function (item) {
            return _this.showRank(item.titleId);
          });
        }
      } // 姓名 和 职级有值筛选


      if (this.bonusUserName || this.arrayTitleId.length > 0) {
        this.$set(this.formModel, 'tableData', bonusUserList);
      }
    },
    // 判断是否拥有权限
    isHasPermission: function isHasPermission(value) {
      var permission = ElePermission.state.permission;

      if (permission && permission.length > 0) {
        return permission.indexOf(value) > -1;
      } else {
        return false;
      }
    },
    StatusText: function StatusText(Status) {
      var res = '';

      switch (Status) {
        case 'i0':
          res = '顾问';
          break;

        case 'i1':
          res = '团队总监';
          break;

        case 'i2':
          res = '营业部总经理';
          break;

        case 'i3':
          res = '区域总经理';
          break;

        case 'i4':
          res = '营业部副总';
          break;

        case 'i5':
          res = '后勤人员管理层';
          break;

        default:
          res = '';
      }

      return res;
    },
    showLog: function showLog() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

      // 显示审核日志
      if (type) {
        this.showLogDialog = true;
      } else {
        // 显示操作日志
        this.showLogSysLogDialog = true;
      }
    },
    // 是否显示职级
    showRank: function showRank(id) {
      var f = false;

      if (this.arrayTitleId.length > 0) {
        f = this.arrayTitleId.includes(id);
      }

      return f;
    }
  }
};