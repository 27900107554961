import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import LOG_TYPE from "@/maps/enum/contract-log-type";
import ATTACHMENT_TYPE from "@/maps/enum/attachment-type";
import Attachment from "@/components/business/attachment";
import ContractLog from "@/components/business/contract-log";
import { submit } from "@api/crm/gift/apply";
import { list } from "@api/crm/gift/gift";
export default {
  name: "gift-apply-new",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Attachment: Attachment,
    ContractLog: ContractLog
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tabActive: "tabContract",
      //标签框默认选中 
      collapseActive: ["1"],
      //信息折叠默认展开  
      attachmentGiftCheck: ATTACHMENT_TYPE.CONTRACT_GIFT_DESCRIBE,
      //附件类型：礼品文件审批    
      showLogDialog: false,
      contractLogType: LOG_TYPE.GIFT,
      formModel: {},
      //表单
      selectData: {
        gift: [],
        //礼品
        apply: [{
          value: "1",
          text: "是"
        }, {
          value: "0",
          text: "否"
        }] //是否特殊申请

      },
      //下拉选择
      rules: {
        giftId: [{
          required: true,
          message: "请选择礼品",
          trigger: "input"
        }],
        num: [{
          required: true,
          message: "请填写礼品数量",
          trigger: "input"
        }]
      }
    };
  },
  created: function created() {},
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.formModel = Object.assign({}, this.propsModel); //复制新对象 

          this.$set(this.formModel, "num", 1);
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tabActive = "tabContract"; //默认打开第一个标签页

      this.loadGiftData();
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    //礼品列表
    loadGiftData: function loadGiftData() {
      var _this = this;

      var arrayKind = []; //礼品类型

      if (this.propsModel.orgType == "01") {
        //直营分公司
        arrayKind = [1, 2];
      } else if (this.propsModel.orgType == "02") {
        //代理机构
        arrayKind = [1, 3];
      } //礼品


      var param = {
        page: {
          pageSize: 999,
          pageNumber: 1
        },
        query: {
          status: "1",
          arrayKind: arrayKind
        }
      };
      this.loading = true;
      list(param).then(function (res) {
        _this.loading = false;
        var arr = [];
        res.data.forEach(function (n) {
          arr.push({
            value: n.giftId,
            text: n.giftName
          });
        });
        _this.selectData.gift = arr;
      });
    },
    // TODO 确定
    clickConfirm: function clickConfirm(result) {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this2.checkForm()) {
            var param = {
              applyGiftId: _this2.propsModel.applyGiftId,
              giftId: _this2.formModel.giftId,
              num: _this2.formModel.num,
              apply: _this2.formModel.apply,
              filialeRemark: _this2.formModel.filialeRemark
            };
            _this2.loading = true;
            submit(param).then(function (res) {
              _this2.loading = false;
              _this2.showDialog = false;

              _this2.$emit("refresh");
            }).catch(function (e) {
              _this2.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    },
    //表单必填项检查
    checkForm: function checkForm() {
      var rs = true;
      var componentAttachmentCount = this.$refs.giftCheckAttachment.tableData.length; //回单凭证附件数

      if (this.formModel.apply == "1" && componentAttachmentCount == 0) {
        this.$message("请上传礼品申请");
        rs = false;
      }

      return rs;
    }
  }
};