var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        top: "20vh",
        title: _vm.isEdit ? "编辑" : "新增",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "120px",
            size: "mini"
          }
        },
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("基本")
          ]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "accountName" } },
                    [
                      _vm.isEdit
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.formModel.accountName))
                          ])
                        : _c(
                            "el-input",
                            {
                              attrs: { readonly: "" },
                              model: {
                                value: _vm.formModel.accountName,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "accountName", $$v)
                                },
                                expression: "formModel.accountName"
                              }
                            },
                            [
                              _c(
                                "template",
                                { slot: "append" },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "right",
                                        width: "780",
                                        trigger: "click"
                                      },
                                      model: {
                                        value: _vm.showSearchCustomer,
                                        callback: function($$v) {
                                          _vm.showSearchCustomer = $$v
                                        },
                                        expression: "showSearchCustomer"
                                      }
                                    },
                                    [
                                      _c("search-customer", {
                                        ref: "searchCustomer",
                                        attrs: {
                                          "sch-word": _vm.formModel.accountName
                                        },
                                        on: {
                                          onSelectItem: _vm.popSelectCustomer
                                        }
                                      }),
                                      _c("el-button", {
                                        attrs: {
                                          slot: "reference",
                                          type: "primary",
                                          icon: "el-icon-zoom-in",
                                          plain: ""
                                        },
                                        slot: "reference"
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "理顾", prop: "agentUserName" } },
                    [
                      _vm.isEdit
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.formModel.agentUserName))
                          ])
                        : _c("el-input", {
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.formModel.agentUserName,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "agentUserName", $$v)
                              },
                              expression: "formModel.agentUserName"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "部门", prop: "orgName" } },
                    [
                      _vm.isEdit
                        ? _c("span", [_vm._v(_vm._s(_vm.formModel.orgName))])
                        : _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.formModel.orgName,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "orgName", $$v)
                              },
                              expression: "formModel.orgName"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "银行卡号", prop: "accountNo" } },
                    [
                      _vm.isEdit
                        ? _c("span", [_vm._v(_vm._s(_vm.formModel.accountNo))])
                        : _c("el-input", {
                            model: {
                              value: _vm.formModel.accountNo,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "accountNo", $$v)
                              },
                              expression: "formModel.accountNo"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开户行名称", prop: "bankName" } },
                    [
                      _vm.isEdit
                        ? _c("span", [_vm._v(_vm._s(_vm.formModel.bankName))])
                        : _c("el-input", {
                            model: {
                              value: _vm.formModel.bankName,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "bankName", $$v)
                              },
                              expression: "formModel.bankName"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联行号", prop: "bankCode" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.bankCode,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "bankCode", $$v)
                          },
                          expression: "formModel.bankCode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "省", prop: "province" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.province,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "province", $$v)
                          },
                          expression: "formModel.province"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "市", prop: "city" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.city,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "city", $$v)
                          },
                          expression: "formModel.city"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "mini" },
              on: {
                click: function($event) {
                  _vm.showDialog = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "", size: "mini" },
              on: { click: _vm.submitForm }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }