var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 客户开户行
{
  path: "".concat(baseRoute, "/openingBank/openingBankInfo"),
  name: 'openingBank-info',
  meta: {
    title: '客户开户行信息',
    auth: true,
    cache: true
  },
  component: _import('crm/openingBank/openingBankInfo')
}, // 客户开户行 导入消息查询
{
  path: "".concat(baseRoute, "/openingBank/messageSearch"),
  name: 'openingBank-messageSearch',
  meta: {
    title: '导入消息查询',
    auth: true,
    cache: true
  },
  component: _import('crm/openingBank/messageSearch')
}];