

















































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/rm/system/enum/components/dialog-detail.vue"
}
