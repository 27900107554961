var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-search-contract",
      attrs: {
        top: "10vh",
        width: "720px",
        title: "推介费人员",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      [
        _c(
          "el-table",
          {
            attrs: {
              data: _vm.tableData,
              border: "",
              "row-style": { height: "20px" },
              "cell-style": { padding: "6px 0" },
              hight: "200"
            }
          },
          [
            _c("el-table-column", {
              attrs: { label: "职位", prop: "title", width: "150" }
            }),
            _c("el-table-column", {
              attrs: { label: "推介费比例", prop: "percent", width: "90" }
            }),
            _c("el-table-column", {
              attrs: {
                label: "推介费金额",
                prop: "bonus",
                width: "150",
                align: "right"
              }
            }),
            _c("el-table-column", {
              attrs: { label: "人员", prop: "bonusUserName", width: "170" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            size: "mini",
                            filterable: "",
                            remote: "",
                            clearable: "",
                            placeholder: "请录入人员名称",
                            "remote-method": _vm.searchPerson
                          },
                          on: {
                            change: function($event) {
                              return _vm.changePerson(scope.$index, scope.row)
                            }
                          },
                          model: {
                            value: scope.row.bonusUserName,
                            callback: function($$v) {
                              _vm.$set(scope.row, "bonusUserName", $$v)
                            },
                            expression: "scope.row.bonusUserName"
                          }
                        },
                        _vm._l(_vm.selectData.person, function(item) {
                          return _c(
                            "el-option",
                            {
                              key: item.personuuid,
                              attrs: {
                                label: item.name,
                                value: item.personuuid
                              }
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                _vm._v(_vm._s(item.name))
                              ]),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    float: "right",
                                    color: "#8492a6",
                                    "font-size": "13px"
                                  }
                                },
                                [_vm._v(_vm._s(item.orgName))]
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "120" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "table-status-red",
                          attrs: {
                            slot: "reference",
                            size: "mini",
                            type: "text"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickRemoveRow(scope.$index, scope.row)
                            }
                          },
                          slot: "reference"
                        },
                        [_vm._v("删除")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "120px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 22 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remark" } },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.remark,
                            expression: "formModel.remark"
                          }
                        ],
                        staticClass: "el-textarea__inner",
                        attrs: { placeholder: "备注" },
                        domProps: { value: _vm.formModel.remark },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formModel,
                              "remark",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "", size: "mini" },
              on: { click: _vm.confirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }