



































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/openingBank/openingBankInfo/add/index.vue"
}
