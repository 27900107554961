// 合同
import request from '@/plugin/axios';
import { formatUserDataPromise } from '@/libs/crm/format';
import func from '@/maps/url/crm/service/org'; //违规合同
// TODO 合同信息和附件统计查询

export function listOrgViolation(params, formatType) {
  var re = request.post(func.listOrgViolation, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 详情

export function getInfoOrgViolation(params, formatType) {
  var re = request.post(func.getInfoOrgViolation, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 审核

export function auditOrgViolation(params) {
  var re = request.post(func.auditOrgViolation, {
    body: params
  });
  return re;
}