import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import { formatAmountToString } from "@/libs/crm/format";
import { listBatchDetail } from "@api/crm/commission/commission";
export default {
  name: "commission-detail",
  props: {
    propsModel: {
      type: String,
      default: ""
    }
  },
  components: {},
  data: function data() {
    return {
      loading: false,
      collapseActive: ["1", "2"],
      //信息折叠默认展开
      tableData: [],
      tableDataSpecial: [] //特殊推介费

    };
  },
  computed: {},
  created: function created() {},
  watch: {
    propsModel: {
      handler: function handler(val) {
        this.init();

        if (val != null && val != "") {
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.tableDataSpecial = [];
    },
    loadData: function loadData() {
      var _this = this;

      var param = {
        query: {
          batchId: this.propsModel
        }
      };
      this.loading = true;
      listBatchDetail(param, DATA_FORMAT_TYPE.AMOUNT).then(function (res) {
        _this.loading = false;
        _this.tableData = []; //重组推介费详细，凑够5个元素

        if (res.data != null) {
          res.data.forEach(function (item) {
            var arrayDetail = [];

            var _loop = function _loop(i) {
              var obj = item.listDetail.find(function (n) {
                return n.titleId == "i" + i;
              });

              if (obj == null) {
                obj = {};
              } else {
                //金额转成千分位
                obj.bonus = formatAmountToString(obj.bonus);
              }

              arrayDetail.push(obj);
            };

            for (var i = 0; i < 5; i++) {
              _loop(i);
            }

            item.listDetail = arrayDetail;
          });
          _this.tableData = res.data;
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    }
  }
};