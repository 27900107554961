var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("j-form", {
        ref: "form",
        attrs: {
          type: "2",
          "is-row": "",
          loading: _vm.submiting,
          "form-config": _vm.form.config,
          "form-data": _vm.form.model
        },
        on: {
          "update:formData": function($event) {
            return _vm.$set(_vm.form, "model", $event)
          },
          "update:form-data": function($event) {
            return _vm.$set(_vm.form, "model", $event)
          },
          submit: function($event) {
            return _vm.FormSubmit($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "btns-wrap" },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "small" }, on: { click: _vm.Cancel } },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.Release }
                      },
                      [_vm._v("确定")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }