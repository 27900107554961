//划款
export default {
  NOT_YET: {
    value: '0',
    text: '未划款'
  },
  SUCCESS: {
    value: '1',
    text: '已划款'
  },
  FAILURE: {
    value: '2',
    text: '划款失败'
  }
};