import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
var PUBLIC_CONFIG = {
  labelWidth: 80
};
export var TOP_FORM = {
  nickname: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '昵称',
    props: {
      placeholder: '请输入昵称'
    }
  }),
  registerMobile: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '账号',
    props: {
      placeholder: '请输入账号'
    }
  }),
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '账号状态',
    props: {
      placeholder: '请选择状态'
    },
    options: [{
      label: '启用',
      value: 1
    }, {
      label: '停用',
      value: 0
    }]
  }),
  createTime: {
    labelWidth: 80,
    type: 'datePicker',
    label: '注册时间',
    propGroup: ['startCreateTime', 'endCreateTime'],
    props: {
      type: 'daterange'
    }
  },
  authStatus: {
    labelWidth: 120,
    type: 'select',
    label: '实名认证状态',
    props: {
      placeholder: '请选择实名认证状态'
    },
    options: [{
      label: '已认证',
      value: 1
    }, {
      label: '认证中',
      value: 2
    }, {
      label: '未认证',
      value: 0
    }, {
      label: '认证失败',
      value: 3
    }]
  },
  customerCode: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户编号',
    props: {
      placeholder: '请输入客户编号'
    }
  })
};
export var TABLE_LABEL = [{
  label: '账号',
  prop: 'registerMobile',
  sortable: 'customerH5AccountList',
  sortProp: 'registerMobile',
  width: 150
}, {
  label: '客户编号',
  prop: 'customerCode',
  type: 'link',
  sortable: 'customerH5AccountList',
  sortProp: 'customerCode',
  width: 150
}, {
  label: '昵称',
  prop: 'nickname',
  sortable: 'customerH5AccountList',
  sortProp: 'nickname',
  width: 120
}, {
  label: '真实姓名',
  prop: 'actualName',
  sortable: 'customerH5AccountList',
  sortProp: 'actualName',
  width: 120
}, {
  label: '证件类型',
  prop: 'certificateTypeValue',
  sortable: 'customerH5AccountList',
  sortProp: 'certificateTypeValue',
  width: 120
}, {
  label: '证件编码',
  prop: 'certificateCode',
  width: 200,
  sortable: 'customerH5AccountList',
  sortProp: 'certificateCode'
}, {
  label: '性别',
  prop: 'sex',
  sortable: 'customerH5AccountList',
  sortProp: 'sex'
}, {
  label: '客户等级',
  prop: 'customerLevelValue'
}, {
  label: '成长值',
  prop: 'growthValue',
  sortable: 'customerH5AccountList',
  sortProp: 'growthValue',
  width: 150
}, {
  label: '当前积分',
  prop: 'score',
  sortable: 'customerH5AccountList',
  sortProp: 'score',
  width: 150
}, {
  label: '活动推荐码',
  prop: 'registrationActivity',
  sortable: 'customerH5AccountList',
  sortProp: 'registrationActivity',
  width: 150
}, {
  label: '推荐人手机号',
  prop: 'referrerMobile',
  width: 150
}, {
  label: '推荐人',
  prop: 'referrerName',
  width: 150
}, {
  label: '会员来源',
  prop: 'source',
  width: 150
}, {
  label: '注册时间',
  prop: 'createTime',
  sortable: 'customerH5AccountList',
  sortProp: 'createTime',
  width: 150
}, {
  label: '提交证件时间',
  prop: 'submitCertificateTime',
  sortable: 'customerH5AccountList',
  sortProp: 'submitCertificateTime',
  width: 150
}, {
  label: '审核通过时间',
  prop: 'approvedTime',
  sortable: 'customerH5AccountList',
  sortProp: 'approvedTime',
  width: 150
}, {
  label: '实名认证状态',
  prop: 'authStatus',
  sortable: 'customerH5AccountList',
  sortProp: 'authStatus',
  width: 150,
  formatter: function formatter(row, column, cellValue) {
    var res;

    switch (Number(cellValue)) {
      case 1:
        res = '已认证';
        break;

      case 0:
        res = '未认证';
        break;

      case 2:
        res = '认证中';
        break;

      case 3:
        res = '认证失败';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  label: '状态',
  prop: 'status',
  sortable: 'customerH5AccountList',
  sortProp: 'status',
  formatter: function formatter(row, column, cellValue) {
    var res;

    switch (Number(cellValue)) {
      case 0:
        res = '停用';
        break;

      case 1:
        res = '启用';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  type: 'operation',
  label: '操作',
  width: 250,
  fixed: 'right',
  formatter: function formatter(row) {
    var arr = [];

    if (row.status === 0) {
      arr.push({
        type: 'start',
        label: '激活',
        permission: 'customerH5Account-status'
      });
    }

    if (row.status === 1) {
      arr.push({
        type: 'stop',
        label: '冻结',
        permission: 'customerH5Account-status'
      });
    }

    if (row.authStatus === 2) {
      arr.push({
        type: 'audit',
        label: '审核',
        permission: 'customerH5Account-audit'
      });
    }

    if (row.status === 1) {
      arr.push({
        type: 'adjustGrowthValue',
        label: '修改成长值',
        permission: 'H5Account-adjustGrowthValue'
      });
      arr.push({
        type: 'accountAdjustScore',
        label: '调整积分',
        permission: 'H5Account-accountAdjustScore'
      });
      arr.push({
        type: 'writeOffEntitlement',
        label: '权益核销',
        permission: 'H5Account-writeOffEntitlement'
      });
    }

    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  rowKey: 'id',
  actions: [// {
  //   label: '新增',
  //   type: 'add',
  //   props: {
  //     size: 'small',
  //     type: 'primary',
  //   },
  // },
  {
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'customerH5Account-export'
  }, {
    label: '推送数据到会员中台',
    type: 'pushMemberToMiddlewareByIds',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'H5Account-pushMemberToMiddleware'
  }],
  defaultSort: {
    prop: 'createTime',
    order: 'descending'
  }
};