var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "member" },
    [
      _c("j-tabs", {
        attrs: { dataObj: _vm.jTabData },
        on: { change: _vm.tabsChange }
      }),
      _c(
        "div",
        { staticClass: "component-wrap" },
        [
          _vm.activeIndex === 0
            ? _c("basic-setting", {
                key: _vm.activeIndex,
                ref: "basic",
                attrs: { modelData: _vm.modelData }
              })
            : _vm._e(),
          _vm.activeIndex === 1
            ? _c("advanced-setting", {
                key: _vm.activeIndex,
                ref: "advanced",
                attrs: { modelData: _vm.modelData }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }