import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dayjs from 'dayjs';
import { mapActions } from 'vuex';
import localeMixin from '@/locales/mixin';
import util from '@/libs/util.js';
import { getVerifyCode } from '@api/common/common';
export default {
  mixins: [localeMixin],
  data: function data() {
    return {
      initPassword: "888888",
      //系统初始密码  
      timeInterval: null,
      time: dayjs().format('HH:mm:ss'),
      // 表单
      formLogin: {
        username: '',
        password: '',
        verifyCode: ''
      },
      // 表单校验
      rules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        verifyCode: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }]
      },
      verifyInfo: {},
      loading: false // 请求登录中

    };
  },
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/account', ['login'])), {}, {
    refreshTime: function refreshTime() {
      this.time = dayjs().format('HH:mm:ss');
    },
    // TODO 获取验证码
    getVerifyCode: util.throttle(function () {
      var _this = this;

      getVerifyCode().then(function (data) {
        _this.verifyInfo = data;
      });
    }),

    /**
     * @description 提交表单
     */
    // 提交登录信息
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          var verifyId = _this2.verifyInfo.verifyId; //this.loading = true;

          _this2.login(_objectSpread({
            verifyId: verifyId
          }, _this2.formLogin)).then(function () {
            var duration = 500;

            _this2.$message.success({
              message: '登录成功',
              duration: duration
            }); // 重定向对象不存在则返回顶层路径


            setTimeout(function () {
              _this2.loading = false;
              var url = _this2.$route.query.redirect || '/';

              if (_this2.initPassword == _this2.formLogin.password) {
                //如果是用初始密码登陆，跳转到修改密码页面
                url = "/user/password";
              }

              _this2.$router.replace(url);
            }, duration);
          }).catch(function (e) {
            _this2.loading = false;

            _this2.getVerifyCode();
          });
        } else {
          // 登录表单校验失败
          _this2.$message.error('表单校验失败，请检查');
        }
      });
    }
  }),
  created: function created() {
    this.getVerifyCode();
  },
  mounted: function mounted() {
    var _this3 = this;

    this.timeInterval = setInterval(function () {
      _this3.refreshTime();
    }, 1000);
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timeInterval);
  }
};