import "core-js/modules/es.object.to-string.js";
// 推介费
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatUserDataPromise, formatDate } from '@/libs/crm/format';
import func from '@/maps/url/crm/regionalOperation/regionalOperation'; // TODO 导入Excel推介费

export function importExcelOrgManageExpenses(params) {
  return request.post(func.importExcelOrgManageExpenses, params);
} // TODO  导入Excel数据列表

export function list(params, formatType) {
  var re = request.post(func.list, {
    body: params
  });
  formatUserDataPromise(re, formatType); // 用户数据格式化

  return re;
} // TODO  推介费外部导入数据列表

export function importList(params, formatType) {
  var re = request.post(func.importList, {
    body: params
  });
  formatUserDataPromise(re, formatType); // 用户数据格式化

  return re;
} // TODO  导出Excel外部导入经营费用

export function exportExcelImportData(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelImportData, {
    body: params
  }).then(function (blob) {
    util.download(blob, '导出经营费用列表' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 新增

export function addBatch(params) {
  var re = request.post(func.addBatch, {
    body: params
  });
  return re;
} // TODO 编辑

export function editBatch(params) {
  var re = request.post(func.editBatch, {
    body: params
  });
  return re;
} // TODO 删除

export function removeBatch(params) {
  var re = request.post(func.removeBatch, {
    body: params
  });
  return re;
} // TODO  列表

export function orgList(params, formatType) {
  var re = request.post(func.orgList, {
    body: params
  });
  formatUserDataPromise(re, formatType); // 用户数据格式化

  return re;
}