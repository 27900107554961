// 表头
var VAL_WIDTH = '120px';
export default [{
  label: '银行卡号',
  prop: 'accountNo'
}, {
  label: '帐户姓名',
  prop: 'accountName'
}, {
  label: '证件号码',
  prop: 'identification'
}, {
  label: '导入时间',
  prop: 'createTime'
}, {
  label: '导入状态',
  prop: 'stateText'
}, {
  label: '消息',
  prop: 'message'
}];