//查询条件
var VAL_WIDTH = '150px';
import STATUS from "@/maps/enum/status/express";
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '合同编号',
  valueKey: 'contractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '客户',
  valueKey: 'customerName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '快递单号',
  valueKey: 'expressNo',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '发件日期',
  valueKey: 'sendDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'sendDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '快递类型',
  valueKey: 'expressType',
  value: '',
  type: 'select',
  options: [{
    value: '1',
    text: '网点寄出'
  }, {
    value: '2',
    text: '总部寄回'
  }],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '快递状态',
  valueKey: 'expressStatus',
  value: '',
  type: 'select',
  options: filterExpressStatus(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '寄出机构',
  valueKey: 'sendOrgName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}]; //合同状态

function filterExpressStatus() {
  var array = [];

  for (var key in STATUS) {
    array.push({
      value: STATUS[key].value,
      text: STATUS[key].text
    });
  }

  return array;
}