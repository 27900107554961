var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        data: _vm.tableData,
        "row-style": { height: "20px" },
        "cell-style": { padding: "6px 0" }
      }
    },
    [
      _c("el-table-column", {
        attrs: { prop: "contractCd", label: "合同编号", width: "150" }
      }),
      _c("el-table-column", {
        attrs: { prop: "date", label: "开始日期", width: "100" }
      }),
      _c("el-table-column", {
        attrs: { prop: "business", label: "业务", width: "110" }
      }),
      _c("el-table-column", {
        attrs: {
          prop: "amount",
          label: "合同金额",
          width: "130",
          align: "right"
        }
      }),
      _c("el-table-column", {
        attrs: { prop: "goldPrice", label: "金价", width: "80", align: "right" }
      }),
      _c("el-table-column", {
        attrs: { prop: "goldWeight", label: "克重", align: "right" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }