export default {
  // 合同审核日志
  listContract: '/contract/log/listContract',
  // 列表    
  listLastGroup: '/contract/log/listLastGroup',
  // 合同日志各种类型各种状态最新一条数据
  // 合同操作日志列表
  sysLogList: '/contract/sysLog/list',
  // 合同操作日志列表
  // 检查合同流程
  checkProcess: '/contract/sum/checkProcess' // 检查合同流程

};