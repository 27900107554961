import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 150,
  col: 12
};
export var FORM_CONFIG = [{
  title: '基本信息',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '活动名称',
    prop: 'name',
    props: {
      placeholder: '请输入',
      disabled: false,
      showWordLimit: true
    },
    rules: [{
      required: true,
      message: '活动名称不能为空',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '活动开始时间',
    prop: 'beginTime',
    props: {
      type: 'datetime',
      valueFormat: 'yyyy-MM-dd HH:mm'
    },
    rules: [{
      required: true,
      message: '活动开始时间不能为空',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '活动结束时间',
    prop: 'endTime',
    props: {
      type: 'datetime',
      valueFormat: 'yyyy-MM-dd HH:mm'
    },
    rules: [{
      required: true,
      message: '活动结束时间不能为空',
      trigger: 'blur'
    }]
  })]
}, {
  title: '活动页面配置',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '营销图片',
    prop: 'marketingImage',
    rules: [{
      required: true,
      message: '营销图片不能为空',
      trigger: 'blur'
    }],
    props: {
      placeholder: '建议宽度750px，图片大小不超过4MB，支持GIF,JPEG,JPG,PNG,BMP格式'
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '分享图片',
    prop: 'shareImage',
    rules: [{
      required: true,
      message: '分享图片不能为空',
      trigger: 'blur'
    }],
    props: {
      placeholder: '建尺寸比例1：1，图片大小不超过4MB'
    }
  }) // {
  //   ...PUBLIC_CONFIG,
  //   type: 'uploadImage',
  //   label: '分享icon',
  //   prop: 'bannerPicturesa',
  //   props: {
  //     placeholder: '建议尺寸：宽200像素,高200像素或等比图片，图片大小不超过1MB，支持GIF,JPEG,JPG,PNG,BMP格式',
  //   },
  // },
  // {
  //   ...PUBLIC_CONFIG,
  //   type: 'uploadImage',
  //   label: '领取icon',
  //   prop: 'bannerPicturesaa',
  //   props: {
  //     placeholder: '建议尺寸：高88像素，图片大小不超过1MB，支持GIF,JPEG,JPG,PNG,BMP格式',
  //   },
  // },
  ]
}];