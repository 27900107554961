//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '机构',
  prop: 'orgName',
  width: '180px'
}, {
  label: '团队',
  prop: 'name',
  width: '200px'
}, {
  label: '状态',
  prop: 'statusText',
  width: '80px'
}, {
  label: '总监',
  prop: 'teamManagerName',
  width: '80px'
}, {
  label: '成员',
  prop: 'teamPersonName',
  width: '400px'
}, {
  label: '变更申请',
  prop: 'applyDesc',
  width: '400px'
}, {
  label: '变更审核',
  prop: 'auditStatus',
  width: '80px',
  type: 'slot'
}];