

























export default function (Component) {
  Component.options.__source = "src/views/system/error/403/index.vue"
}
