












































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/components/common/dynamic-form/index.vue"
}
