export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    /** 广告位列表 */
    GET_ADVERTISE_LOCATION: function GET_ADVERTISE_LOCATION(data) {
      return request({
        url: url.advertising.getAdvertiseLocation,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 新增广告位 */
    ADD_ADVERTISE_LOCATION: function ADD_ADVERTISE_LOCATION(data) {
      return request({
        url: url.advertising.addAdvertiseLocation,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 广告位详情 */
    GET_ADVERTISE_LOCATION_DETAIL: function GET_ADVERTISE_LOCATION_DETAIL(data) {
      return request({
        url: url.advertising.advertiseDetailLocation,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 删除广告位 */
    DELETE_ADVERTISE_LOCATION_DETAIL: function DELETE_ADVERTISE_LOCATION_DETAIL(data) {
      return request({
        url: url.advertising.deleteAdvertiseLocation,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 新增广告 */
    ADD_ADVERTISE_LIST: function ADD_ADVERTISE_LIST(data) {
      return request({
        url: url.advertising.addAdvertise,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 广告详情 */
    GET_ADVERTISE_DETAIL: function GET_ADVERTISE_DETAIL(data) {
      return request({
        url: url.advertising.advertiseDetail,
        data: data
      }).then(function (res) {
        return res;
      });
    },

    /** 删除广告 */
    DELETE_ADVERTISE_DETAIL: function DELETE_ADVERTISE_DETAIL(data) {
      return request({
        url: url.advertising.deleteAdvertise,
        data: data
      }).then(function (res) {
        return res;
      });
    }
  };
});