var VAL_WIDTH = '150px'; // 表头

export default [{
  label: '金价时间',
  prop: 'createDate',
  width: '200px'
}, {
  label: '开盘价',
  prop: 'openPrice',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '收盘价',
  prop: 'closingPrice',
  width: VAL_WIDTH,
  align: 'right'
}];