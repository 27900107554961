//核算
export default {
  create: '/count/create',
  // 创建
  save: '/count/save',
  // 保存 
  submit: '/count/submit',
  // 提交
  remove: '/count/remove',
  // 删除
  auditService: '/count/auditService',
  // 客服审核
  auditCount: '/count/auditCount',
  // 结算审核
  auditFinance: '/count/auditFinance',
  // 财务审核
  list: '/count/list',
  // 列表
  getInfo: '/count/getInfo',
  // 明细
  getInitCount: '/count/getInitCount',
  // 获取原始核算
  exportExcel: '/count/exportExcel',
  // 导出Excel
  listSettle: '/count/listSettle',
  // 合同结算列表
  settleContactBatch: '/count/settleContactBatch',
  // 合同结算
  computePayableDate: 'count/computePayableDate',
  // 计算应付日期
  computePayableDateBatch: 'count/computePayableDateBatch' // 批量计算应付日期

};