var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        top: "7vh",
        width: "70%",
        title: "推介费批量创建",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.formModel, rules: _vm.rules } },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.formModel.tableData,
                border: "",
                "row-style": { height: "20px" },
                "cell-style": { padding: "16px 0" },
                height: "550px"
              }
            },
            [
              _c("el-table-column", { attrs: { type: "index", width: "50" } }),
              _c("el-table-column", {
                attrs: {
                  prop: "contractCd",
                  label: "合同编号",
                  width: "120",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "customerName", label: "客户", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { prop: "orgName", label: "机构", width: "100" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "startDate",
                  label: "合同开始日期",
                  width: "110"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "endDate", label: "合同结束日期", width: "110" }
              }),
              _c("el-table-column", {
                attrs: { prop: "ruleId", label: "规则名称", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "tableData." + scope.$index + ".ruleId",
                              rules: _vm.rules.ruleId
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: scope.row.ruleId,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "ruleId", $$v)
                                  },
                                  expression: "scope.row.ruleId"
                                }
                              },
                              _vm._l(_vm.selectData.ruleId, function(item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isBackOffice",
                  label: "是否后勤人员",
                  width: "125"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.isBackOffice === "0" ? "否" : "是")
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "statusText", label: "推介费状态", width: "120" }
              }),
              _c("el-table-column", {
                attrs: { prop: "totalTimes", label: "总次数", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "tableData." + scope.$index + ".totalTimes",
                              rules: _vm.rules.totalTimes
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { title: "" },
                              model: {
                                value: scope.row.totalTimes,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "totalTimes", $$v)
                                },
                                expression: "scope.row.totalTimes"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "currentTimes", label: "第几次", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "tableData." + scope.$index + ".currentTimes",
                              rules: _vm.rules.currentTimes
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: { title: "" },
                              model: {
                                value: scope.row.currentTimes,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "currentTimes", $$v)
                                },
                                expression: "scope.row.currentTimes"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "type", label: "推介费类型", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "tableData." + scope.$index + ".type",
                              rules: _vm.rules.type
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { clearable: "", placeholder: "请选择" },
                                model: {
                                  value: scope.row.type,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "type", $$v)
                                  },
                                  expression: "scope.row.type"
                                }
                              },
                              _vm._l(_vm.selectData.type, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { label: item.text, value: item.value }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "planDate",
                  label: "推介费结算月份",
                  width: "160"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { width: "130px" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop:
                                    "tableData." + scope.$index + ".planDate",
                                  rules: _vm.rules.planDate
                                }
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    "value-format": "yyyy-MM",
                                    type: "month",
                                    placeholder: "开始时间",
                                    size: "small"
                                  },
                                  model: {
                                    value: scope.row.planDate,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "planDate", $$v)
                                    },
                                    expression: "scope.row.planDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "remark", label: "备注", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { title: "", placeholder: "请填写备注" },
                          model: {
                            value: scope.row.remark,
                            callback: function($$v) {
                              _vm.$set(scope.row, "remark", $$v)
                            },
                            expression: "scope.row.remark"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "i0name", label: "顾问姓名", width: "135" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              filterable: "",
                              remote: "",
                              clearable: "",
                              placeholder: "请录入人员",
                              "remote-method": function(query) {
                                _vm.searchPerson(query, scope.row, "i0")
                              }
                            },
                            on: {
                              change: function($event) {
                                return _vm.changePerson(
                                  scope.row,
                                  "i0",
                                  scope.row.i0name
                                )
                              }
                            },
                            model: {
                              value: scope.row.i0name,
                              callback: function($$v) {
                                _vm.$set(scope.row, "i0name", $$v)
                              },
                              expression: "scope.row.i0name"
                            }
                          },
                          _vm._l(_vm.selectData.person, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: item.personuuid,
                                attrs: {
                                  label: item.name,
                                  value: item.personuuid
                                }
                              },
                              [
                                _c("span", { staticStyle: { float: "left" } }, [
                                  _vm._v(_vm._s(item.name))
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "right",
                                      color: "#8492a6",
                                      "font-size": "13px"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.orgName))]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "i0percent", label: "顾问比例", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { title: "" },
                          on: {
                            input: function() {
                              return _vm.$forceUpdate()
                            }
                          },
                          model: {
                            value: scope.row.i0percent,
                            callback: function($$v) {
                              _vm.$set(scope.row, "i0percent", $$v)
                            },
                            expression: "scope.row.i0percent"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "i0bonus", label: "顾问金额", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { title: "" },
                          on: {
                            input: function() {
                              return _vm.$forceUpdate()
                            }
                          },
                          model: {
                            value: scope.row.i0bonus,
                            callback: function($$v) {
                              _vm.$set(scope.row, "i0bonus", $$v)
                            },
                            expression: "scope.row.i0bonus"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "i1name", label: "团队总监姓名", width: "135" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              filterable: "",
                              remote: "",
                              clearable: "",
                              placeholder: "请录入人员",
                              "remote-method": function(query) {
                                _vm.searchPerson(query, scope.row, "i1")
                              }
                            },
                            on: {
                              change: function($event) {
                                return _vm.changePerson(
                                  scope.row,
                                  "i1",
                                  scope.row.i1name
                                )
                              }
                            },
                            model: {
                              value: scope.row.i1name,
                              callback: function($$v) {
                                _vm.$set(scope.row, "i1name", $$v)
                              },
                              expression: "scope.row.i1name"
                            }
                          },
                          _vm._l(_vm.selectData.person, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: item.personuuid,
                                attrs: {
                                  label: item.name,
                                  value: item.personuuid
                                }
                              },
                              [
                                _c("span", { staticStyle: { float: "left" } }, [
                                  _vm._v(_vm._s(item.name))
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "right",
                                      color: "#8492a6",
                                      "font-size": "13px"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.orgName))]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "i1percent",
                  label: "团队总监比例",
                  width: "150"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { title: "" },
                          on: {
                            input: function() {
                              return _vm.$forceUpdate()
                            }
                          },
                          model: {
                            value: scope.row.i1percent,
                            callback: function($$v) {
                              _vm.$set(scope.row, "i1percent", $$v)
                            },
                            expression: "scope.row.i1percent"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "i1bonus", label: "团队总监金额", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { title: "" },
                          on: {
                            input: function() {
                              return _vm.$forceUpdate()
                            }
                          },
                          model: {
                            value: scope.row.i1bonus,
                            callback: function($$v) {
                              _vm.$set(scope.row, "i1bonus", $$v)
                            },
                            expression: "scope.row.i1bonus"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "i4name",
                  label: "营业部副总姓名",
                  width: "135"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              filterable: "",
                              remote: "",
                              clearable: "",
                              placeholder: "请录入人员",
                              "remote-method": function(query) {
                                _vm.searchPerson(query, scope.row, "i4")
                              }
                            },
                            on: {
                              change: function($event) {
                                return _vm.changePerson(
                                  scope.row,
                                  "i4",
                                  scope.row.i4name
                                )
                              }
                            },
                            model: {
                              value: scope.row.i4name,
                              callback: function($$v) {
                                _vm.$set(scope.row, "i4name", $$v)
                              },
                              expression: "scope.row.i4name"
                            }
                          },
                          _vm._l(_vm.selectData.person, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: item.personuuid,
                                attrs: {
                                  label: item.name,
                                  value: item.personuuid
                                }
                              },
                              [
                                _c("span", { staticStyle: { float: "left" } }, [
                                  _vm._v(_vm._s(item.name))
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "right",
                                      color: "#8492a6",
                                      "font-size": "13px"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.orgName))]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "i4bonus",
                  label: "营业部副总金额",
                  width: "150"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { title: "" },
                          on: {
                            input: function() {
                              return _vm.$forceUpdate()
                            }
                          },
                          model: {
                            value: scope.row.i4bonus,
                            callback: function($$v) {
                              _vm.$set(scope.row, "i4bonus", $$v)
                            },
                            expression: "scope.row.i4bonus"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "i4percent",
                  label: "营业部副总比例",
                  width: "150"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { title: "" },
                          on: {
                            input: function() {
                              return _vm.$forceUpdate()
                            }
                          },
                          model: {
                            value: scope.row.i4percent,
                            callback: function($$v) {
                              _vm.$set(scope.row, "i4percent", $$v)
                            },
                            expression: "scope.row.i4percent"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "i2name",
                  label: "营业部总经理姓名",
                  width: "135"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              filterable: "",
                              remote: "",
                              clearable: "",
                              placeholder: "请录入人员",
                              "remote-method": function(query) {
                                _vm.searchPerson(query, scope.row, "i2")
                              }
                            },
                            on: {
                              change: function($event) {
                                return _vm.changePerson(
                                  scope.row,
                                  "i2",
                                  scope.row.i2name
                                )
                              }
                            },
                            model: {
                              value: scope.row.i2name,
                              callback: function($$v) {
                                _vm.$set(scope.row, "i2name", $$v)
                              },
                              expression: "scope.row.i2name"
                            }
                          },
                          _vm._l(_vm.selectData.person, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: item.personuuid,
                                attrs: {
                                  label: item.name,
                                  value: item.personuuid
                                }
                              },
                              [
                                _c("span", { staticStyle: { float: "left" } }, [
                                  _vm._v(_vm._s(item.name))
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "right",
                                      color: "#8492a6",
                                      "font-size": "13px"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.orgName))]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "i2percent",
                  label: "营业部总经理比例",
                  width: "150"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { title: "" },
                          on: {
                            input: function() {
                              return _vm.$forceUpdate()
                            }
                          },
                          model: {
                            value: scope.row.i2percent,
                            callback: function($$v) {
                              _vm.$set(scope.row, "i2percent", $$v)
                            },
                            expression: "scope.row.i2percent"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "i2bonus",
                  label: "营业部总经理金额",
                  width: "150"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { title: "" },
                          on: {
                            input: function() {
                              return _vm.$forceUpdate()
                            }
                          },
                          model: {
                            value: scope.row.i2bonus,
                            callback: function($$v) {
                              _vm.$set(scope.row, "i2bonus", $$v)
                            },
                            expression: "scope.row.i2bonus"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "i3name",
                  label: "区域总经理姓名",
                  width: "135"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              filterable: "",
                              remote: "",
                              clearable: "",
                              placeholder: "请录入人员",
                              "remote-method": function(query) {
                                _vm.searchPerson(query, scope.row, "i3")
                              }
                            },
                            on: {
                              change: function($event) {
                                return _vm.changePerson(
                                  scope.row,
                                  "i3",
                                  scope.row.i3name
                                )
                              }
                            },
                            model: {
                              value: scope.row.i3name,
                              callback: function($$v) {
                                _vm.$set(scope.row, "i3name", $$v)
                              },
                              expression: "scope.row.i3name"
                            }
                          },
                          _vm._l(_vm.selectData.person, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: item.personuuid,
                                attrs: {
                                  label: item.name,
                                  value: item.personuuid
                                }
                              },
                              [
                                _c("span", { staticStyle: { float: "left" } }, [
                                  _vm._v(_vm._s(item.name))
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "right",
                                      color: "#8492a6",
                                      "font-size": "13px"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.orgName))]
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "i3percent",
                  label: "区域总经理比例",
                  width: "150"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { title: "" },
                          on: {
                            input: function() {
                              return _vm.$forceUpdate()
                            }
                          },
                          model: {
                            value: scope.row.i3percent,
                            callback: function($$v) {
                              _vm.$set(scope.row, "i3percent", $$v)
                            },
                            expression: "scope.row.i3percent"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "i3bonus",
                  label: "区域总经理金额",
                  width: "150"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { title: "" },
                          on: {
                            input: function() {
                              return _vm.$forceUpdate()
                            }
                          },
                          model: {
                            value: scope.row.i3bonus,
                            callback: function($$v) {
                              _vm.$set(scope.row, "i3bonus", $$v)
                            },
                            expression: "scope.row.i3bonus"
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  return _vm.clickAudit()
                }
              }
            },
            [_vm._v("批量创建")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }