// 表头
var VAL_WIDTH = '120px';
export default [{
  label: '商户名称',
  prop: 'kindText'
}, {
  label: '终端号',
  prop: 'endNumber'
}, {
  label: '交易日期',
  prop: 'tallyDate'
}, {
  label: '交易时间',
  prop: 'tallyTime'
}, {
  label: '交易金额',
  prop: 'debtor'
}, {
  label: '卡号',
  prop: 'bankAccount'
}, {
  label: '系统参考号',
  prop: 'systemNumber'
}, {
  label: '是否已匹配合同',
  prop: 'isBuyText'
}, {
  label: '匹配合同',
  prop: 'contractCd'
}];