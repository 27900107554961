// 表头
var VAL_WIDTH = '120px';
export default [{
  label: '计算公式名称',
  prop: 'formulaName',
  width: '150px'
}, {
  label: '比例编码',
  prop: 'code',
  width: '150px'
}, {
  label: '比例名称',
  prop: 'name',
  width: '300px'
}, {
  label: '创建时间',
  prop: 'createTime',
  width: '200px'
}, {
  label: '更新时间',
  prop: 'updateTime',
  width: '200px'
}, {
  label: '状态',
  prop: 'enabled',
  type: 'slot',
  width: VAL_WIDTH
}];