var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        top: "25vh",
        width: "700px",
        title: "新增",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "customerName" } },
                    [
                      _c(
                        "el-input",
                        {
                          model: {
                            value: _vm.formModel.customerName,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "customerName", $$v)
                            },
                            expression: "formModel.customerName"
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "append" },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "right",
                                    width: "600",
                                    trigger: "click"
                                  },
                                  model: {
                                    value: _vm.showSearchCustomer,
                                    callback: function($$v) {
                                      _vm.showSearchCustomer = $$v
                                    },
                                    expression: "showSearchCustomer"
                                  }
                                },
                                [
                                  _c("search-customer", {
                                    ref: "searchCustomer",
                                    attrs: {
                                      "sch-word": _vm.formModel.customerName
                                    },
                                    on: { onSelectItem: _vm.popSelectCustomer }
                                  }),
                                  _c("el-button", {
                                    attrs: {
                                      slot: "reference",
                                      type: "primary",
                                      icon: "el-icon-zoom-in",
                                      plain: ""
                                    },
                                    slot: "reference"
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "VIP级别", prop: "vip" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.vip,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "vip", $$v)
                            },
                            expression: "formModel.vip"
                          }
                        },
                        _vm._l(_vm.selectData.vip, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "证件类型", prop: "idTypeText" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: true },
                        model: {
                          value: _vm.formModel.idTypeText,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "idTypeText", $$v)
                          },
                          expression: "formModel.idTypeText"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "证件号码", prop: "identification" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: true },
                        model: {
                          value: _vm.formModel.identification,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "identification", $$v)
                          },
                          expression: "formModel.identification"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别", prop: "sex" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: true },
                        model: {
                          value: _vm.formModel.sexText,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "sexText", $$v)
                          },
                          expression: "formModel.sexText"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "生日", prop: "birthday" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: true },
                        model: {
                          value: _vm.formModel.birthday,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "birthday", $$v)
                          },
                          expression: "formModel.birthday"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号码", prop: "mobile" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: true },
                        model: {
                          value: _vm.formModel.mobile,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "mobile", $$v)
                          },
                          expression: "formModel.mobile"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "地址", prop: "address" } },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.address,
                            expression: "formModel.address"
                          }
                        ],
                        staticClass: "el-textarea__inner",
                        attrs: {
                          placeholder: "请填写客户地址",
                          readonly: true
                        },
                        domProps: { value: _vm.formModel.address },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formModel,
                              "address",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "机构", prop: "orgName" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: true },
                        model: {
                          value: _vm.formModel.orgName,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "orgName", $$v)
                          },
                          expression: "formModel.orgName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: { click: _vm.confirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }