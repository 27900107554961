import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { TOP_FORM, TABLE_CONFIG, TABLE_LABEL, DIALOG_FORM_DATA, DIALOG_FORM_CONFIG } from './config/list';
import { getHeaders } from '@/api-new/service';
import api from '@/api-new';
import url from '@/api-new/url';
import { useSuccessTip, useErrorTip } from '@/hooks/use-tip';
import { cloneDeep } from 'lodash-es';
export default {
  name: 'annualRule',
  data: function data() {
    return {
      loading: false,
      topForm: TOP_FORM,
      filtersData: {},
      tableConfig: TABLE_CONFIG,
      tableLabel: TABLE_LABEL,
      dialogVisible: false,

      /* 弹窗数据 */
      dialogFormData: cloneDeep(DIALOG_FORM_DATA),
      dialogFormConfig: cloneDeep(DIALOG_FORM_CONFIG)
    };
  },
  computed: {},
  methods: {
    /** 接口参数过滤函数 */
    filtersFormatter: function filtersFormatter(params) {},

    /** 点击表格项 */
    tableRowClick: function tableRowClick(action) {
      var _this = this;

      var _ref = action || {},
          clickItem = _ref.clickItem,
          row = _ref.row,
          type = _ref.type;

      var _ref2 = row || {},
          id = _ref2.id; // 编辑


      if (clickItem.type === 'edit') {
        api.ANNUALRULE_GETINFO({
          id: id
        }).then(function (res) {
          _this.dialogFormData = _objectSpread({}, res);
          _this.dialogVisible = true;
        });
      }
    },
    tableClick: function tableClick(params) {
      var _this2 = this;

      var _ref3 = params || {},
          clickItem = _ref3.clickItem,
          selections = _ref3.selections;

      var ids = selections.map(function (item) {
        return item.id;
      }); // 选中项id

      var _ref4 = clickItem || {},
          type = _ref4.type; // 新增


      if (type === 'add') {
        this.dialogVisible = true;
      } // 删除


      if (type === 'delete') {
        if (selections.length === 0) {
          return this.$message.error('请选择要删除的数据');
        }

        this.$confirm('是否确定删除所选数据，删除后无法恢复？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          api.ANNUALRULE_DELETE({
            ids: ids
          }).then(function () {
            useSuccessTip();

            if (_this2.$refs.jPage.tableData_.length === 1) {
              _this2.$refs.jPage.currentPage_ = _this2.$refs.jPage.currentPage_ > 1 ? _this2.$refs.jPage.currentPage_ - 1 : 1;
            }

            _this2.refresh();
          });
        }).catch(function () {});
      }
    },
    refresh: function refresh() {
      this.$refs.jPage.getPageData();
    },

    /* 弹窗确认 */
    dialogConfirm: function dialogConfirm() {
      this.$refs.form.submitForm();
    },

    /* 表单提交 */
    dialogSubmit: function dialogSubmit() {
      var _this3 = this;

      var data = this.dialogFormData;

      if (data.beginTimeLimit === data.endTimeLimit) {
        return useErrorTip('合同期限不能重复');
      }

      if (this.dialogFormData.id) {
        api.ANNUALRULE_UPDATE(data).then(function () {
          useSuccessTip('编辑成功');
          _this3.dialogVisible = false;
          _this3.dialogFormData = {};

          _this3.refresh();
        });
      } else {
        api.ANNUALRULE_ADD(data).then(function () {
          useSuccessTip('新增成功');
          _this3.dialogVisible = false;
          _this3.dialogFormData = {};

          _this3.refresh();
        });
      }
    }
  },
  created: function created() {
    var host = url.host,
        apiPrev = url.apiPrev;
    var apiUrl = url.gift.annualRule;
    this.httpConfig = {
      url: "".concat(host).concat(apiPrev).concat(apiUrl.getPage),
      headers: getHeaders()
    };
  }
};