//菜单
export default {
  listPerson: '/menu/listPerson',
  // 用户菜单列表
  listByUserId: '/menu/listByUserId',
  // 用户拥有的菜单列表
  listTop: '/menu/listTop',
  // 一级菜单
  list: '/menu/list',
  // 列表
  save: '/menu/save',
  // 保存
  remove: '/menu/remove',
  // 删除
  listTree: '/menu/listTree' // 菜单树

};