var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        top: "10vh",
        width: "500px",
        title: "审核",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-row",
        { staticClass: "block-col-3" },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("机构:")]),
            _vm._v(" " + _vm._s(_vm.model.orgName) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "block-col-3" },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("申请描述:")]),
            _vm._v(" " + _vm._s(_vm.model.applyDesc) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "block-col-3" },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("申请人:")]),
            _vm._v(" " + _vm._s(_vm.model.applyerName) + " ")
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("申请时间:")]),
            _vm._v(" " + _vm._s(_vm.model.applyTime) + " ")
          ])
        ],
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("审核")
      ]),
      _c(
        "el-row",
        { staticClass: "block-col-3" },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("审核结果:")]),
            _vm._v(" " + _vm._s(_vm.model.auditStatusText) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "block-col-3" },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("审核备注:")]),
            _vm._v(" " + _vm._s(_vm.model.auditRemark) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "block-col-3" },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("审核人:")]),
            _vm._v(" " + _vm._s(_vm.model.auditorName) + " ")
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("审核时间:")]),
            _vm._v(" " + _vm._s(_vm.model.auditTime) + " ")
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }