var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 公募合同录入
{
  path: "".concat(baseRoute, "/buy/new/public"),
  name: 'buy-public',
  meta: {
    title: '认购>录入',
    auth: true,
    cache: true
  },
  component: _import('crm/buy/new/public')
}, // 私募合同录入
{
  path: "".concat(baseRoute, "/buy/new/private"),
  name: 'buy-private',
  meta: {
    title: '认购>私募录入',
    auth: true,
    cache: true
  },
  component: _import('crm/buy/new/private')
}, // 预约审核
{
  path: "".concat(baseRoute, "/buy/reserve"),
  name: 'buy-reserve',
  meta: {
    title: '认购>预约审核',
    auth: true,
    cache: true
  },
  component: _import('crm/buy/reserve')
}, // 客服审核
{
  path: "".concat(baseRoute, "/buy/service"),
  name: 'buy-service',
  meta: {
    title: '认购>客服审核',
    auth: true,
    cache: true
  },
  component: _import('crm/buy/service')
}, // 财务审核
{
  path: "".concat(baseRoute, "/buy/finance"),
  name: 'buy-finance',
  meta: {
    title: '认购>结算审核',
    auth: true,
    cache: true
  },
  component: _import('crm/buy/finance')
}, // 资金审核
{
  path: "".concat(baseRoute, "/buy/money"),
  name: 'buy-money',
  meta: {
    title: '认购>资金审核',
    auth: true,
    cache: true
  },
  component: _import('crm/buy/money')
}, // 合同查询
{
  path: "".concat(baseRoute, "/buy/search"),
  name: 'buy-search',
  meta: {
    title: '认购>合同查询',
    auth: true,
    cache: true
  },
  component: _import('crm/buy/search')
}, // SAP模板
{
  path: "".concat(baseRoute, "/buy/sap"),
  name: 'buy-sap',
  meta: {
    title: '认购>SAP模板',
    auth: true,
    cache: true
  },
  component: _import('crm/buy/sap')
}, // 合同回访申请
{
  path: "".concat(baseRoute, "/buy/visit/new"),
  name: 'buy-visit-apply',
  meta: {
    title: '认购>私募回访申请',
    auth: true,
    cache: true
  },
  component: _import('crm/buy/visit/new')
}, // 合同回访
{
  path: "".concat(baseRoute, "/buy/visit/service"),
  name: 'buy-visit-service',
  meta: {
    title: '认购>私募回访',
    auth: true,
    cache: true
  },
  component: _import('crm/buy/visit/service')
}, // 合同回访查询
{
  path: "".concat(baseRoute, "/buy/visit/search"),
  name: 'buy-visit-search',
  meta: {
    title: '认购>私募回访查询',
    auth: true,
    cache: true
  },
  component: _import('crm/buy/visit/search')
}];