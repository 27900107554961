import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AUDIT_PROCESS from '@/maps/enum/audit-process';
import COLOR from '@/maps/enum/color';
import STATUS from '@/maps/enum/status/interest';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import { FILTER_PROPS, FILTER_FORMS } from './option/filter'; // 过滤条件的form

import TABLE_LIST from './option/list'; // 表头

import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import DialogAudit from './components/dialog-audit';
import DialogAuditBatch from './components/dialog-audit-batch';
import DialogInfo from '../dialog-info';
import { list } from '@api/crm/interest/interest';
export default {
  name: 'interest-money',
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogAudit: DialogAudit,
    DialogAuditBatch: DialogAuditBatch,
    DialogInfo: DialogInfo
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {
        multiple: true
      },
      pagination: {},
      process: AUDIT_PROCESS.MONEY.value,
      //当前审核流程
      query: {},
      tableData: [],
      showAuditDialog: false,
      // 审核窗口
      showAuditBatchDialog: false,
      //批量审核窗口
      showInfoDialog: false,
      // 展示信息
      currentRow: {},
      // 当前点击行
      checkboxRows: [],
      //复选框选中行
      canEditStatus: [STATUS.PRINT.value],
      //可以编辑的状态
      LastTime: true // 批量末次付息字段展示

    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      var param = {
        process: this.process,
        page: page,
        query: this.query
      };
      this.loading = true;
      list(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.tableData = data;
          _this.pagination = {
            total: totalCount
          };
        } else {
          _this.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    //复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      var _this2 = this;

      this.checkboxRows = [];
      rows.forEach(function (n) {
        if (STATUS.PRINT.value == n.status) {
          _this2.checkboxRows.push(n);
        }
      });
    },
    // TODO
    clickAudit: function clickAudit() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currentRow = row;
      this.showAuditDialog = true;
    },
    clickAuditBatch: function clickAuditBatch() {
      var query = this.$refs.form.getForm(); // isLast 0 不是 1 末次付息

      if (query.isLast === '0') {
        this.LastTime = false;
      } else {
        this.LastTime = true;
      }

      if (this.checkboxRows.length == 0) {
        this.$message('请选择合同');
      } else {
        //检查同否同一结算类型产品
        var typeSet = new Set();
        this.checkboxRows.forEach(function (n) {
          typeSet.add(n.productCountType);
        });

        if (typeSet.size > 1) {
          this.$message('请通过结算属性选择同类型合同批量审核');
        } else {
          this.showAuditBatchDialog = true;
        }
      }
    },
    //查看详情
    clickInfo: function clickInfo() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currentRow = row;
      this.showInfoDialog = true;
    },
    setStatusColor: function setStatusColor(status) {
      //状态颜色
      var className = '';

      switch (status) {
        case STATUS.PRINT.value:
          className = COLOR.BLUE;
          break;

        case STATUS.MONEY_AUDIT.value:
          className = COLOR.GREEN;
          break;

        case STATUS.MONEY_AUDIT_FAILURE.value:
          className = COLOR.RED;
          break;
      }

      return className;
    }
  },
  created: function created() {}
};