




























































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/service/customer/birthday/components/dialog-contract.vue"
}
