var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("j-page", {
        ref: "j_page",
        attrs: {
          filters: _vm.filters,
          table: _vm.table,
          "http-config": _vm.httpConfig,
          tableLabel: _vm.tableLabel,
          filtersFormatter: _vm.filtersFormatter
        },
        on: {
          "table-row-click": _vm.tableRowClick,
          "table-click": _vm.tableClick,
          "table-row-change": _vm.tableRowChange,
          "selection-change": _vm.selectionChange
        }
      }),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.dialogData.dialogVisible,
            title: _vm.dialogData.title,
            footActive: _vm.dialogData.footActive,
            width: _vm.dialogData.width,
            height: _vm.dialogData.height
          },
          on: {
            "update:visible": function($event) {
              return _vm.$set(_vm.dialogData, "dialogVisible", $event)
            },
            confirm: _vm.confirmEvent,
            close: _vm.handleDialog
          }
        },
        [
          _vm.operationType === "add"
            ? _c("j-table", {
                ref: "j_table",
                attrs: {
                  table: _vm.table1,
                  tableLabel: _vm.tableLabel1,
                  tableData: _vm.tableData
                },
                on: { "row-click": _vm.rowClickAdd },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "displayDate",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "选择展示日期"
                            },
                            model: {
                              value: row.displayDate,
                              callback: function($$v) {
                                _vm.$set(row, "displayDate", $$v)
                              },
                              expression: "row.displayDate"
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3665083823
                )
              })
            : _vm._e(),
          _vm.operationType === "form"
            ? _c("j-form", {
                ref: "jForm",
                attrs: {
                  "form-config": _vm.formConfig,
                  "form-data": _vm.formData
                },
                on: {
                  "update:formData": function($event) {
                    _vm.formData = $event
                  },
                  "update:form-data": function($event) {
                    _vm.formData = $event
                  },
                  submit: _vm.formSubmit
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "displayDate",
                      fn: function() {
                        return [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "选择展示日期"
                            },
                            model: {
                              value: _vm.formData.displayDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "displayDate", $$v)
                              },
                              expression: "formData.displayDate"
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2966327747
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }