import "core-js/modules/es.object.to-string.js";
// 银行流水
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatUserDataPromise, formatDate } from '@/libs/crm/format';
import func from '@/maps/url/crm/bill/contract'; // TODO 列表

export function listContract(params, formatType) {
  var re = request.post(func.listContract, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 详情

export function getInfoContract(params, formatType) {
  var re = request.post(func.getInfoContract, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} //TODO 导出Excel

export function exportExcelContract(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelContract, {
    body: params
  }).then(function (blob) {
    util.download(blob, '合同对帐' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}