// 查询列表的枚举常量
export default {
  ENUM_CD_0101: '0101',
  // 职位
  ENUM_CD_0102: '0102',
  // 性别
  ENUM_CD_0103: '0103',
  // 学历
  ENUM_CD_0104: '0104',
  // 用工形式
  ENUM_CD_0105: '0105',
  // 员工状态
  ENUM_CD_0106: '0106',
  // 婚姻状况
  ENUM_CD_0107: '0107',
  // 政治面貌
  ENUM_CD_0108: '0108',
  // 院校培养方式
  ENUM_CD_0109: '0109',
  // 招聘渠道
  ENUM_CD_0110: '0110',
  // 合同签约类型
  ENUM_CD_0111: '0111',
  // 离职类型
  ENUM_CD_0112: '0112',
  // 民族
  ENUM_CD_0113: '0113',
  // 机构类型
  ENUM_CD_0114: '0114',
  // 日志-操作类型
  ENUM_CD_0115: '0115',
  // 日志-业务模块
  ENUM_CD_0116: '0116',
  // 年份
  ENUM_CD_0117: '0117',
  // 月份
  ENUM_CD_0201: '0201',
  // 合同期限
  ENUM_CD_0202: '0202',
  // 返还周期
  ENUM_CD_0203: '0203',
  // 积存方式
  ENUM_CD_0204: '0204',
  // 收款方式
  ENUM_CD_0205: '0205',
  // 银行
  ENUM_CD_0206: '0206',
  // 合同资料类型
  ENUM_CD_0207: '0207',
  // 账户类型
  ENUM_CD_0208: '0208',
  // 证件类型
  ENUM_CD_0209: '0209',
  // 积存类型
  ENUM_CD_0210: '0210',
  // 合同状态
  ENUM_CD_0211: '0211',
  // 扣款日期
  ENUM_CD_0212: '0212',
  // 结算日
  ENUM_CD_0213: '0213',
  // 赎回状态
  ENUM_CD_0214: '0214',
  // 赎回方式
  ENUM_CD_0215: '0215',
  // 付款类型
  ENUM_CD_0216: '0216',
  // 付款状态
  ENUM_CD_0217: '0217',
  // 付款账号
  ENUM_CD_0218: '0218',
  // 续约申请状态
  ENUM_CD_0219: '0219',
  // 续约方式
  ENUM_CD_0220: '0220',
  // 续约类型
  ENUM_CD_0221: '0221',
  // 快递公司
  ENUM_CD_0222: '0222',
  // 快递状态
  ENUM_CD_0223: '0223',
  // 内容类型
  ENUM_CD_0225: '0225',
  // 回访结果
  ENUM_CD_0226: '0226',
  // 客户满意度
  ENUM_CD_0227: '0227',
  // 归档情况
  ENUM_CD_0228: '0228',
  // 积存赎回类别
  ENUM_CD_0229: '0229',
  // 核算状态
  ENUM_CD_0230: '0230',
  // 结算类型
  ENUM_CD_0231: '0231',
  // 机构推介费方案
  ENUM_CD_0232: '0232',
  // 机构推介费职位
  ENUM_CD_0233: '0233',
  // VIP等级
  ENUM_CD_0234: '0234',
  // 礼品发货状态
  ENUM_CD_0235: '0235',
  // 银行卡类型
  ENUM_CD_0236: '0236',
  // 通联状态类型
  ENUM_CD_0237: '0237',
  // 银行流水交易类型
  ENUM_CD_0239: '0239',
  // 划款状态
  ENUM_CD_0240: '0240',
  // 积存状态
  ENUM_CD_0241: '0241',
  // 合同状态-清算与终止查询
  ENUM_CD_0242: '0242',
  // 发放方式
  ENUM_CD_0243: '0243',
  // 盖章申请公司
  ENUM_CD_0244: '0244',
  // 盖章申请印章
  ENUM_CD_0245: '0245',
  // 合同快递类型
  ENUM_CD_0246: '0246',
  // 礼品类型
  ENUM_CD_0247: '0247',
  // 产品类型
  ENUM_CD_0248: '0248',
  // 礼品申请状态
  ENUM_CD_0249: '0249',
  // 租赁赎回方式
  ENUM_CD_0250: '0250',
  // 产品目录1
  ENUM_CD_0251: '0251',
  // 租赁赎回方式2
  ENUM_CD_0252: '0252',
  // 产品目录2
  ENUM_CD_0253: '0253',
  // 产品目录3
  ENUM_CD_ROLE_DATA: 'role_data',
  // 角色数据权限类
  ENUM_CD_PRODUCT_COUNT_TYPE: 'productCountType',
  // 产品结算类型
  ENUM_CD_DEDUCT_TYPE: 'deductType',
  // 费用扣减类型
  ENUM_CD_PRODUCT_CATEGORY3: 'productCategory3',
  // 产品目录3
  ENUM_CD_CONTRACTORIGIN_TYPE: 'contractOriginType' // 合同来源

};