import _defineProperty from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
// import ENUM from '@/maps/enum/enum'
import ENABLE from '@/maps/enum/status/enable';
import { listTop } from '@api/rm/menu';
import CRMENUM from '@/maps/enum/dingTalkCrm';
import { getEnumList } from '@api/common/common';
var VAL_WIDTH = '120px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '角色编号',
  valueKey: 'code',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '角色名称',
  valueKey: 'name',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '用户姓名',
  valueKey: 'userName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '用户账户',
  valueKey: 'userCode',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, _defineProperty({
  label: '归属系统',
  valueKey: 'belongSystem',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: CRMENUM.BELONGSYSTEM
  }
}, "props", {
  style: {
    width: VAL_WIDTH
  }
})];