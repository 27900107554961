var financeUrl = {
  finance: {
    /* 公共请求 */
    common: {
      /* 获取部门下拉内容 */
      getAllOrgUsAnaly: '/manage/rmOrg/getAllOrgUsAnaly'
    },

    /* 责任状业绩明细管理 */
    achievement: {
      /* 列表 */
      queryList: '/manage/financezrzachievement/queryList',

      /* 导出数据 */
      exportList: '/manage/financezrzachievement/exportList',

      /** 数据字典 */
      dictionary: '/manage/enum/getByCode'
    },

    /* 资金现金流分析明细管理 */
    cashflow: {
      /* 付息出金_列表 */
      queryInterestList: '/manage/financecashflow/queryInterestList',

      /* 付息出金_导出数据 */
      exportInterestList: '/manage/financecashflow/exportInterestList',

      /* 到期出金_列表 */
      queryMaturityList: '/manage/financecashflow/queryMaturityList',

      /* 到期出金_导出数据 */
      exportMaturityList: '/manage/financecashflow/exportMaturityList'
    },

    /** 基本法业绩汇总 */
    jbfachievement: {
      /* 列表 */
      queryList: '/manage/financejbfachievement/queryList',

      /* 导出数据 */
      exportList: '/manage/financejbfachievement/exportList',

      /* 明细数据导出 */
      exportAgentAchList: '/manage/financejbfachievement/exportAgentAchList'
    },

    /* 经营分析续约明细 */
    renewAnaly: {
      /* 续约_列表 */
      queryRenewList: '/manage/renewAnaly/queryRenewList',

      /* 续约_导出数据 */
      exportRenewList: '/manage/renewAnaly/exportRenewList',

      /* 到期_列表 */
      queryExpireList: '/manage/renewAnaly/queryExpireList',

      /* 到期_导出数据 */
      exportExpireList: '/manage/renewAnaly/exportExpireList'
    }
  }
};
export default financeUrl;