// 表头
var VAL_WIDTH = '120px';
export default [{
  label: '区域',
  prop: 'orgName'
}, {
  label: '经营支出',
  prop: 'payableAmount'
}, {
  label: '支付日期',
  prop: 'paymentDate'
}, {
  label: '创建时间',
  prop: 'createTime'
}];