import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import ENUM from "@/maps/enum/enum";
import { getEnumList } from "@api/common/common";
import { save } from "@api/crm/commission/rule";
export default {
  name: "commission-rule-add",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      tableDataRule: [],
      selectData: {
        saveType: [] //积存方式

      },
      rules: {
        productId: [{
          required: true,
          message: "请填写产品",
          trigger: "input"
        }],
        timeLimitStart: [{
          required: true,
          message: "请填写期限始",
          trigger: "input"
        }],
        timeLimitEnd: [{
          required: true,
          message: "请填写期限始",
          trigger: "input"
        }],
        amountStart: [{
          required: true,
          message: "请填写期限始",
          trigger: "input"
        }],
        amountEnd: [{
          required: true,
          message: "请填写期限始",
          trigger: "input"
        }]
      }
    };
  },
  computed: {
    // 弹窗展示的标题
    title: function title() {
      return this.isEdit ? "修改" : "新增";
    },
    // 是否为编辑
    isEdit: function isEdit() {
      var ruleId = this.propsModel.ruleId;
      return !!ruleId;
    }
  },
  created: function created() {
    var _this = this;

    //积存方式
    getEnumList({
      enumCd: ENUM.ENUM_CD_0203
    }).then(function (res) {
      _this.selectData.saveType = res;
    });
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();

          if (this.isEdit) {
            this.getInfo();
          } else {}
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.model = {};
      this.formModel.saveType = "01";
      ;
      this.tableDataRule = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    //添加规则，一次添加全部明细
    handleAddRule: function handleAddRule() {
      var cyclelength = 0; //cycle长度

      for (var i = 0; i < this.tableDataRule.length; i++) {
        var n = this.tableDataRule[i];

        if (n.cycle == 1) {
          cyclelength++;
        }
      }

      if (cyclelength == 0) {
        cyclelength = 1;
      }

      var times = Math.floor(this.tableDataRule.length / cyclelength); //cycle次数

      var cycle = times * 12; //周期

      if (cycle == 0) {
        cycle = 1;
      }

      this.tableDataRule.push({
        id: "i0",
        title: "顾问",
        data: "0",
        cycle: cycle
      });
      this.tableDataRule.push({
        id: "i1",
        title: "团队总监",
        data: "0",
        cycle: cycle
      });
      this.tableDataRule.push({
        id: "i4",
        title: "营业部副总经理",
        data: "0",
        cycle: cycle
      });
      this.tableDataRule.push({
        id: "i2",
        title: "营业部总经理",
        data: "0",
        cycle: cycle
      });
      this.tableDataRule.push({
        id: "i3",
        title: "城市总/区域总",
        data: "0",
        cycle: cycle
      });
    },
    //删除行
    handleRemoveRule: function handleRemoveRule(index, row) {
      delete this.tableDataRule[index]; //删除指定索引元素
    },
    // TODO 确定
    clickSave: function clickSave() {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          //组装rule json字符串
          var perCnt = 5; //一个周期数量

          var arrayJson = [];
          var cycles = _this2.tableDataRule.length / perCnt;

          for (var i = 0; i < cycles; i++) {
            var cycle = i * 12;

            if (cycle == 0) {
              cycle = 1;
            } //截取子数组


            var indexBegin = i * perCnt;
            var indexEnd = indexBegin + perCnt;

            var subArray = _this2.tableDataRule.slice(indexBegin, indexEnd);

            arrayJson[i] = {
              cycle: cycle,
              rule: subArray
            };
          }

          if (_this2.tableDataRule.length == 0) {
            _this2.$message("请设置规则明细");
          } else {
            var jsonString = JSON.stringify(arrayJson);

            var param = _objectSpread({}, _this2.formModel);

            param.ruleJson = jsonString;
            _this2.loading = true;
            save({
              object: param
            }).then(function (res) {
              _this2.loading = false;
              _this2.showDialog = false;

              _this2.$emit("refresh");
            }).catch(function (e) {
              _this2.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    getInfo: function getInfo() {
      this.formModel = Object.assign({}, this.propsModel); //复制新对象      

      this.tableDataRule = []; //json解析到数组

      var arrayJson = JSON.parse(this.formModel.ruleJson);

      for (var i = 0; i < arrayJson.length; i++) {
        var arrayRuleData = arrayJson[i].rule;

        for (var j = 0; j < arrayRuleData.length; j++) {
          arrayRuleData[j].cycle = arrayJson[i].cycle;
          this.tableDataRule.push(arrayRuleData[j]);
        }
      }
    }
  }
};