
















































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/gift/apply/receive/components/dialog-receive-batch.vue"
}
