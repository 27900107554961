// 表头
export default [{
  label: '姓名',
  prop: 'personName',
  width: '100px'
}, {
  label: '工号',
  prop: 'personNum',
  width: '100px'
}, {
  label: '职位',
  prop: 'personTitle',
  width: '200px'
}, {
  label: '所属组织',
  prop: 'orgName',
  width: '200px'
}, {
  label: '本年度(万元)/年化折算标准',
  prop: 'g500',
  width: '150px'
}, {
  label: '12月',
  prop: 'g212',
  width: '100px'
}, {
  label: '1月',
  prop: 'g201',
  width: '100px'
}, {
  label: '2月',
  prop: 'g202',
  width: '100px'
}, {
  label: '一季度(万元)',
  prop: 'g301',
  width: '130px'
}, {
  label: '3月',
  prop: 'g203',
  width: '100px'
}, {
  label: '4月',
  prop: 'g204',
  width: '100px'
}, {
  label: '5月',
  prop: 'g205',
  width: '100px'
}, {
  label: '二季度(万元)',
  prop: 'g302',
  width: '130px'
}, {
  label: '上半年(万元)',
  prop: 'g401',
  width: '130px'
}, {
  label: '6月',
  prop: 'g206',
  width: '100px'
}, {
  label: '7月',
  prop: 'g207',
  width: '100px'
}, {
  label: '8月',
  prop: 'g208',
  width: '100px'
}, {
  label: '三季度(万元)',
  prop: 'g303',
  width: '130px'
}, {
  label: '9月',
  prop: 'g209',
  width: '100px'
}, {
  label: '10月',
  prop: 'g210',
  width: '100px'
}, {
  label: '11月',
  prop: 'g211',
  width: '100px'
}, {
  label: '四季度(万元)',
  prop: 'g304',
  width: '130px'
}, {
  label: '下半年(万元)',
  prop: 'g402',
  width: '130px'
}];