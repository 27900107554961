import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import ENUM from "@/maps/enum/enum";
import SearchUser from "@/components/business/search-user";
import { mapState } from "vuex";
import { getEnumList } from "@api/common/common";
import { formatDate } from "@/libs/crm/format";
import { sendExpress } from "@api/crm/paper/paper";
export default {
  name: "paper-express-back-send",
  props: {
    propsModel: {
      type: Array,
      default: []
    }
  },
  components: {
    SearchUser: SearchUser
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      showSearchUser: false,
      selectData: {
        expressCompany: [] //快递公司

      },
      rules: {
        // receiveOrgCd: [
        //   { required: true, message: "请选择收件公司", trigger: "input" }
        // ],
        expressCompany: [{
          required: true,
          message: "请选择快递公司",
          trigger: "input"
        }],
        expressNo: [{
          required: true,
          message: "请填写快递单号",
          trigger: "input"
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    //快递公司
    getEnumList({
      enumCd: ENUM.ENUM_CD_0221
    }).then(function (res) {
      _this.selectData.expressCompany = res;
    });
  },
  computed: _objectSpread({}, mapState("d2admin/user", {
    userInfo: function userInfo(state) {
      return state.info;
    }
  })),
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.$set(this.formModel, "sender", this.userInfo.name);
          this.$set(this.formModel, "expressCompany", "sfkd");
          this.$set(this.formModel, "sendDate", formatDate(new Date(), "yyyy-MM-dd"));
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    send: function send() {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = _objectSpread({}, _this2.formModel);

          param.sendOrgCd = _this2.userInfo.orgCd;
          param.expressType = "2"; //总部寄回分公司

          param.arrayContractId = [];

          for (var i = 0; i < _this2.propsModel.length; i++) {
            param.arrayContractId[i] = _this2.propsModel[i].contractId;
          }

          _this2.loading = true;
          sendExpress({
            object: param
          }).then(function (res) {
            _this2.loading = false;
            _this2.showDialog = false;

            _this2.$emit("refresh");
          }).catch(function (e) {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    popSelectUser: function popSelectUser(user) {
      //给查找经纪人子组件选中时调
      this.formModel.receiveOrgName = user.orgName;
      this.formModel.receiveOrgCd = user.orgCd;
      this.formModel.receiver = user.name;
      this.showSearchUser = false;
    },
    //列表删除
    clickRemove: function clickRemove(row) {
      for (var i = 0; i < this.propsModel.length; i++) {
        if (this.propsModel[i].contractId == row.contractId) {
          this.propsModel.splice(i, 1);
          break;
        }
      }
    }
  }
};