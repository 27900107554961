import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import echarts from "echarts";
import COLOR from "@/maps/enum/color";
import STATUS_BUY from "@/maps/enum/status/buy";
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import TABLE_LIST from "./option/list"; // 表头

import DynamicForm from "@/components/common/dynamic-form";
import DynamicTable from "@/components/common/dynamic-table";
import { contractAuditLog, contractAuditLogCount, exportExcelContractAudit } from "@api/crm/report/report";
export default {
  name: "buy-service",
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {
        auto: false
      },
      pagination: {},
      query: {},
      tableData: [],
      currentRow: {},
      // 当前点击行
      sumText: '' //合计文字

    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: "数据已刷新",
        type: "info",
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();

      if (this.query.status != null && this.query.status != '') {
        //转成数组后台接收
        this.query.arrayStatus = [this.query.status];
      }

      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      var param = {
        page: page,
        query: this.query
      }; //查询表格数据

      this.loading = true;
      contractAuditLog(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.tableData = data;
          _this.pagination = {
            total: totalCount
          };
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      }); //查询饼图数据

      contractAuditLogCount(param).then(function (res) {
        var echartsDataAgree = []; //同意

        var echartsDataReject = []; //不同意

        if (res.data != null) {
          var sumAgree = 0;
          var sumReject = 0;

          for (var i = 0; i < res.data.length; i++) {
            var n = res.data[i];
            var cntAgree = parseInt(n.cntOrgAgree);
            var cntReject = parseInt(n.cntOrgReject);
            sumAgree += cntAgree;
            sumReject += cntReject;
            echartsDataAgree.push({
              value: n.cntOrgAgree,
              name: n.companyName + "(" + n.cntOrgAgree + ")"
            });
            echartsDataReject.push({
              value: n.cntOrgReject,
              name: n.companyName + "(" + n.cntOrgReject + ")"
            });
          }

          var sumTotal = sumAgree + sumReject;
          var percentAgree = 0;

          if (sumTotal > 0) {
            percentAgree = Math.floor(sumAgree / sumTotal * 100);
          }

          var percentReject = 100 - percentAgree;
          _this.sumText = "总数：" + sumTotal + ", 同意: " + sumAgree + "(" + percentAgree + "%), 不同意: " + sumReject + "(" + percentReject + "%)";
        }

        _this.echartsDrawAgree(echartsDataAgree);

        _this.echartsDrawReject(echartsDataReject);
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    echartsDrawAgree: function echartsDrawAgree(data) {
      //同意绘图
      //绘图
      var echartDom = document.getElementById("echartAuditAgreeDom");
      var myChart = echarts.init(echartDom);
      var option = {
        title: {
          text: "审核通过",
          left: "left",
          subtext: "",
          sublink: ""
        },
        tooltip: {
          trigger: 'item'
        },
        series: [{
          name: '',
          type: 'pie',
          radius: '50%',
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      };
      option && myChart.setOption(option);
    },
    echartsDrawReject: function echartsDrawReject(data) {
      //不同意绘图
      //绘图
      var echartDom = document.getElementById("echartAuditRejectDom");
      var myChart = echarts.init(echartDom);
      var option = {
        title: {
          text: "审核不通过",
          left: "left",
          subtext: "",
          sublink: ""
        },
        tooltip: {
          trigger: 'item'
        },
        series: [{
          name: '',
          type: 'pie',
          radius: '50%',
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      };
      option && myChart.setOption(option);
    },
    //导出Excel
    clickExport: function clickExport() {
      var _this2 = this;

      this.$confirm("按查询条件导出数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this2.initFilter();

        if (_this2.query.status != null && _this2.query.status != '') {
          //转成数组后台接收
          _this2.query.arrayStatus = [_this2.query.status];
        }

        var param = {
          query: _this2.query
        };
        exportExcelContractAudit(param).catch(function (e) {
          _this2.$message({
            message: e,
            type: "error"
          });
        });
      }).catch(function () {});
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    setStatusColor: function setStatusColor(status) {
      //合同状态颜色
      var className = "";

      switch (status) {
        case STATUS_BUY.AUDIT_FAIL.value:
          className = COLOR.RED;
          break;

        case STATUS_BUY.DEAL_FAIL.value:
          className = COLOR.RED;
          break;

        case STATUS_BUY.WAIT_FOR_FINANCE.value:
          className = COLOR.RED;
          break;

        case STATUS_BUY.FINANCE_AUDIT_FAIL.value:
          className = COLOR.RED;
          break;

        case STATUS_BUY.FINANCE_AUDIT.value:
          className = COLOR.RED;
          break;

        case STATUS_BUY.COUNT_AUDIT_FAIL.value:
          className = COLOR.RED;
          break;
      }

      return className;
    }
  },
  created: function created() {}
};