var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '合同号',
  prop: 'contractCd',
  width: '120px'
}, {
  label: '购销合同号',
  prop: 'buyContractCd',
  width: '120px'
}, {
  label: '客户姓名',
  prop: 'customerName',
  width: '100px'
}, {
  label: '产品',
  prop: 'productName',
  width: '150px'
}, {
  label: '结算属性',
  prop: 'productCountTypeText',
  width: '150px'
}, {
  label: '收款日期',
  prop: 'moneyConfirmDate',
  width: '120px'
}, {
  label: '开始日期',
  prop: 'startDate',
  width: '120px'
}, {
  label: '到期日期',
  prop: 'endDate',
  width: '120px'
}, {
  label: '合同金额',
  prop: 'amount',
  width: '120px',
  align: 'right'
}, {
  label: '合同周期(月)',
  prop: 'timeLimit',
  width: '120px'
}, {
  label: '返还周期',
  prop: 'returnPeriod',
  width: '100px'
}, {
  label: '预期年化收益率（%）',
  prop: 'annualRate',
  width: '160px'
}, {
  label: '浮动年化收益率',
  prop: 'countActualRate',
  width: '120px'
}, {
  label: '预期收益总金额',
  prop: 'expectEarnings',
  width: '120px',
  align: 'right'
}, {
  label: '赎回方式',
  prop: 'rentRansomTypeText',
  width: '130px'
}, {
  label: '续约收益金额',
  prop: 'goOnEarnings',
  width: '120px',
  align: 'right'
}, {
  label: '提取现货费用',
  prop: 'goldFee',
  width: '120px',
  align: 'right'
}, {
  label: '费用扣减方式',
  prop: 'rentRansomTypeText',
  width: '130px',
  align: 'right'
}, {
  label: '集团打款日期',
  prop: 'applyPaymentDate',
  width: '120px'
}, {
  label: '所属机构',
  prop: 'orgName',
  width: '250px'
}, {
  label: '顾问',
  prop: 'userName',
  width: '100px'
}, {
  label: '首次提交日期',
  prop: 'submitDateFirst',
  width: '160px'
}, {
  label: '开户行',
  prop: 'bankName',
  width: '200px'
}, {
  label: '银行户名',
  prop: 'accountName',
  width: '120px'
}, {
  label: '银行账号',
  prop: 'accountNo',
  width: '180px'
}, {
  label: '应付日期',
  prop: 'countPayableDate',
  width: '120px'
}, {
  label: '核算状态',
  prop: 'countStateText',
  width: '120px'
}, // {
//     label: '核算日期',
//     prop: 'countDate',
//     width: '120px',
// },
{
  label: '年化入金',
  prop: 'yearAmount',
  width: '120px',
  align: 'right'
}, {
  label: '手续费',
  prop: 'serviceFee',
  width: '100px',
  align: 'right'
}, {
  label: '权益合计',
  prop: 'countAmountAll',
  width: '120px',
  align: 'right'
}, {
  label: '续约合同金额',
  prop: 'goOnAmount',
  width: '120px',
  align: 'right'
}, {
  label: '提取金额',
  prop: 'applyBackCash',
  width: '120px',
  align: 'right'
}, // {
//     label: '核算备注',
//     prop: 'countRemark',
//     width: '120px',
// },
{
  label: '核算说明',
  prop: 'countDescription',
  width: '120px'
}, {
  label: '结算类型',
  prop: 'countTypeText',
  width: '120px'
}, // {
//     label: '实际金额（未知）',
//     prop: 'lastInterest',
//     width: '120px',
//     align: 'right',
// },
// {
//     label: '末次付息',
//     prop: 'lastInterest',
//     width: '100px',
//     align: 'right',
// },
{
  label: '违约金',
  prop: 'penalty',
  width: '100px',
  align: 'right'
}, {
  label: '付息状态',
  prop: 'interestStatusText',
  width: '120px'
}, // {
//     label: '付息日期',
//     prop: 'settlementsDates',
//     width: '120px',
// },
{
  label: '付息金额',
  prop: 'interest',
  width: '120px',
  align: 'right'
}, {
  label: '付息次数',
  prop: 'interestTimes',
  width: '120px',
  align: 'right'
}, {
  label: '付息备注',
  prop: 'interestRemark',
  width: '120px'
}, // {
//     label: '支付状态',
//     prop: 'paymentStateText',
//     width: '120px',
// },
{
  label: '支付日期',
  prop: 'paymentDate',
  width: '120px'
}, {
  label: '支付金额',
  prop: 'paymentAmount',
  width: '120px'
}, {
  label: '实际起息日',
  prop: 'actualStartDate',
  width: '120px'
}, {
  label: '产品类别',
  prop: 'productCategory3Text',
  width: '120px'
}];