//查询条件
import ENUM from '@/maps/enum/enum';
import { getEnumList } from '@api/common/common';
import STATUS from "@/maps/enum/status/apply";
import { listCacheOrg, listCacheProduct } from "@api/common/cache";
var VAL_WIDTH = '150px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '合同编号',
  valueKey: 'contractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '客户',
  valueKey: 'customerName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '产品',
  valueKey: 'arrayProductId',
  value: [],
  type: 'select',
  method: listCacheProduct,
  params: {},
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '结算属性',
  valueKey: 'arrayProductCountType',
  value: [],
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_PRODUCT_COUNT_TYPE
  }
}, {
  label: '机构名称',
  valueKey: 'orgCd',
  value: '',
  type: 'cascader',
  method: listCacheOrg,
  params: {},
  options: [],
  props: {
    //expandTrigger: 'hover',
    checkStrictly: true,
    emitPath: false,
    showAllLevels: false,
    value: 'orgCd',
    label: 'orgName',
    children: 'children'
  }
}, {
  label: '赎回日期',
  valueKey: 'applyDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'applyDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '赎回状态',
  valueKey: 'arrayApplyState',
  value: [],
  type: 'select',
  options: filterApplyStatus(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}]; //赎回状态

function filterApplyStatus() {
  var array = [STATUS.FINANCE_AUDIT, //STATUS.FINANCE_MANAGER_CONFIRM,
  STATUS.PRINT, STATUS.MONEY_AUDIT, STATUS.COMPLETE, //STATUS.FINANCE_AUDIT_FAILURE,
  //STATUS.FINANCE_MANAGER_CONFIRM_FAILURE,
  STATUS.MONEY_AUDIT_FAILURE];
  return array;
}