import { getEnumList } from '@api/common/common';
var VAL_WIDTH = '130px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
var isBuy = [{
  text: '是',
  value: '1'
}, {
  text: '否',
  value: '0'
}];
export var FILTER_FORMS = [{
  label: '商户名称',
  valueKey: 'kind',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    filterable: true,
    style: {
      width: VAL_WIDTH
    }
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: 'bankBillKind'
  }
}, {
  label: '交易日期',
  valueKey: 'tallyDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'tallyDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '是否已匹配合同',
  valueKey: 'isBuy',
  value: '',
  type: 'select',
  options: isBuy,
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '银行卡号后4位',
  valueKey: 'bankAccountEndWith',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '匹配合同',
  valueKey: 'contractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}];