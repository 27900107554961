var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入合同编码" },
            on: { blur: _vm.handleFilter },
            model: {
              value: _vm.listQuery.contractCd,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "contractCd", $$v)
              },
              expression: "listQuery.contractCd"
            }
          })
        ],
        1
      ),
      _c(
        "el-table",
        {
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "contractCd", label: "合同编码", width: "180" }
          }),
          _c("el-table-column", {
            attrs: { prop: "buyContractCd", label: "购销合同号", width: "180" }
          }),
          _c("el-table-column", {
            attrs: { prop: "contractStateValue", label: "状态" }
          }),
          _c("el-table-column", {
            attrs: { prop: "productName", label: "产品" }
          }),
          _c("el-table-column", {
            attrs: { prop: "amount", label: "合同金额" }
          }),
          _c("el-table-column", {
            attrs: { prop: "startDate", label: "开始日期" }
          }),
          _c("el-table-column", {
            attrs: { prop: "endDate", label: "结束日期" }
          }),
          _c("el-table-column", {
            attrs: { prop: "timeLimit", label: "期限（月）" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-center" },
        [
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0"
              }
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page.pageNum,
              limit: _vm.listQuery.page.pageSize
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery.page, "pageNum", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery.page, "pageSize", $event)
              },
              pagination: _vm.getList
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }