//合同审核日志
import request from '@/plugin/axios';
import func from '@/maps/url/crm/other/contract-log'; // TODO 列表

export function listContract(params) {
  var re = request.post(func.listContract, {
    body: params
  });
  return re;
} // TODO 合同日志各种类型各种状态最新一条数据

export function listLastGroup(params) {
  var re = request.post(func.listLastGroup, {
    body: params
  });
  return re;
} // TODO 合同操作日志列表

export function sysLogList(params) {
  var re = request.post(func.sysLogList, {
    body: params
  });
  return re;
} // TODO 检查合同流程

export function checkProcess(params) {
  var re = request.post(func.checkProcess, {
    body: params
  });
  return re;
}