//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import LOG_TYPE from "@/maps/enum/contract-log-type";
import ContractLog from "@/components/business/contract-log";
import ENUM from "@/maps/enum/enum";
import { getEnumList } from "@api/common/common";
import { formatDate } from "@/libs/crm/format";
import { delivery } from "@api/crm/gift/apply";
export default {
  name: "gift-apply-delivery",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    ContractLog: ContractLog
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      collapseActive: ["1", "2"],
      //信息折叠默认展开
      showLogDialog: false,
      contractLogType: LOG_TYPE.GIFT,
      selectData: {
        provideWay: [],
        //发放方式
        express: [] //快递公司

      },
      formModel: {},
      //表单
      rules: {
        provideWay: [{
          required: true,
          message: "请选择发放方式",
          trigger: "input"
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    //发放方式
    getEnumList({
      enumCd: ENUM.ENUM_CD_0242
    }).then(function (res) {
      _this.selectData.provideWay = res;
    }); //快递公司

    getEnumList({
      enumCd: ENUM.ENUM_CD_0221
    }).then(function (res) {
      _this.selectData.express = res;
    });
  },
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.model = Object.assign({}, this.propsModel); //复制新对象

          this.$set(this.formModel, "applyGiftId", this.propsModel.applyGiftId);
          this.$set(this.formModel, "provideWay", "1");
          this.$set(this.formModel, "express", "sfkd");
          this.$set(this.formModel, "provideDate", formatDate(new Date()));
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {},
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.loading = false;
    },
    // TODO 确定
    clickDelivery: function clickDelivery(result) {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = {
            arrayObject: [_this2.formModel]
          };
          _this2.loading = true;
          delivery(param).then(function (res) {
            _this2.loading = false;
            _this2.showDialog = false;

            _this2.$emit("refresh");
          }).catch(function (e) {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    // TODO 获取详情
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    }
  }
};