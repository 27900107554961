// 弹窗中form表单配置
export var DIALOG_FORM_CONFIG = {
  linkUrl: {
    type: 'uploadVideo',
    label: '视频',
    props: {
      directory: 'aamanage/image/jqvideo/',
      placeholder: '最多上传1个文件，文件大小建议不超过250M,文件支持mp4,mov, qlv, qsv, ogg, flv, wmv, rmvb格式',
      fileType: ['mov', 'mp4', 'qlv', 'qsv', 'ogg', 'flv', 'wmv', 'rmvb'],
      timeout: 10 * 60 * 1000
    }
  }
}; // 弹窗中form表单配置

export var EXAMINE_FORM_CONFIG = {
  linkUrl: {
    type: 'uploadVideo',
    label: '视频',
    props: {
      directory: 'aamanage/image/jqvideo/',
      disabled: true
    }
  }
};
export var DIALOG_FORM_DATA = {
  linkUrl: null
};