//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import DialogSummaryPreview from "./dialog-summary-preview";
import { listSummary, exportSummary, confirmSummary } from "@api/crm/commission/commissionAgency";
export default {
  name: "commission-agency-collect-summary-list",
  props: {},
  components: {
    DialogSummaryPreview: DialogSummaryPreview
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      tableData: [],
      currentRow: {},
      //当前行
      showSummaryPreviewDialog: false
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    loadData: function loadData() {
      var _this = this;

      var param = {
        page: {
          pageNumber: 1,
          pageSize: 999
        },
        query: {
          status: "0"
        }
      };
      listSummary(param).then(function (res) {
        _this.tableData = res.data;
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    //预览
    clickSummaryPreview: function clickSummaryPreview(row) {
      this.currentRow = row;
      this.showSummaryPreviewDialog = true;
    },
    //导出汇总表
    clickSummaryExport: function clickSummaryExport(row) {
      var _this2 = this;

      this.$confirm("确定导出数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this2.currentRow = row;
        var param = {
          summaryId: row.summaryId
        };
        exportSummary(param).catch(function (e) {
          _this2.$message({
            message: e,
            type: "error"
          });
        });
      }).catch(function () {});
    },
    //点击确认完成
    clickConfirm: function clickConfirm(row) {
      var _this3 = this;

      var param = {
        summaryId: row.summaryId
      };
      confirmSummary(param).then(function (res) {
        _this3.$message({
          message: "已提交",
          type: "success"
        });

        _this3.loadData();
      });
    }
  }
};