//广告管理
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [{
  path: "".concat(baseRoute, "/advertising/manage"),
  name: 'advertisingManager',
  meta: {
    title: '广告管理',
    auth: true,
    cache: true
  },
  component: _import('crm/advertising/manage')
}, {
  path: "".concat(baseRoute, "/advertising/position"),
  name: 'advertisingPosition',
  meta: {
    title: '广告位管理',
    auth: true,
    cache: true
  },
  component: _import('crm/advertising/position')
}];