//查询条件
import { listCacheOrg, listCacheProduct } from "@api/common/cache";
import { formatDate } from "@/libs/crm/format";
var VAL_WIDTH = '130px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '合同编号',
  valueKey: 'contractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '客户',
  valueKey: 'customerName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '产品',
  valueKey: 'arrayProductId',
  value: [],
  type: 'select',
  method: listCacheProduct,
  params: {},
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '机构名称',
  valueKey: 'orgCd',
  value: '',
  type: 'cascader',
  method: listCacheOrg,
  params: {},
  options: [],
  props: {
    //expandTrigger: 'hover',
    checkStrictly: true,
    emitPath: false,
    showAllLevels: false,
    value: 'orgCd',
    label: 'orgName',
    children: 'children'
  }
}, {
  label: '到期日期',
  valueKey: 'endDateBegin',
  value: formatDate(new Date()),
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'endDateEnd',
  value: defaultEndDateEnd(),
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}];
/**
 * 默认搜索结束日期
 */

function defaultEndDateEnd() {
  var endDateEnd = new Date();
  endDateEnd.setDate(endDateEnd.getDate() + 7); //未来一周

  return formatDate(endDateEnd);
}