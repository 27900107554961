








































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/count/search/index.vue"
}
