//
//
//
//
//
//
//
//
//
//
//
import pdf from 'vue-pdf-signature';
import CMapReaderFactory from 'vue-pdf-signature/src/CMapReaderFactory.js';
export default {
  components: {
    pdf: pdf
  },
  props: {
    pdfurl: {
      type: String
    }
  },
  data: function data() {
    return {
      src: '',
      numPages: undefined
    };
  },
  mounted: function mounted() {
    this.loadPdf(this.pdfurl);
  },
  methods: {
    loadPdf: function loadPdf(url) {
      var _this = this;

      this.src = pdf.createLoadingTask(url, {
        url: url,
        CMapReaderFactory: CMapReaderFactory,
        // 引入pdf.js字体，templ
        cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/',
        cMapPacked: true
      }); // 加载完PDF后对缓存进行清除

      for (var key in require.cache) {
        if (key.indexOf('bcmap') >= 0) {
          delete require.cache[key];
        }
      }

      this.src.promise.then(function (pdf) {
        _this.numPages = pdf.numPages; // 这里拿到当前pdf总页数
      });
    },
    closePdf: function closePdf() {
      this.$emit('closePdf');
    }
  }
};