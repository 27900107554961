//查询条件
import dayjs from 'dayjs';
export function addDate(val) {
  // 时间戳为10位需*1000，时间戳为13位不需乘1000
  var dateTime = new Date(val);
  var date1 = dateTime.setDate(dateTime.getDate() + 1); // let date2 = new Date(date1).toLocaleString();

  var date = new Date(date1);
  var Y = date.getFullYear() + "-";
  var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
  var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return Y + M + D; // return dayjs(dateTime).format('YYYY-MM-DD')
}