import { formulaDropDownList } from '@api/crm/commissionV3/commissionV3';
var VAL_WIDTH = '150px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '计算公式',
  valueKey: 'formulaId',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  },
  method: formulaDropDownList,
  params: {}
}, {
  label: '比列编号',
  valueKey: 'code',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '比列规则名称',
  valueKey: 'name',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '创建时间',
  valueKey: 'createStartTime',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '20px',
  valueKey: 'createEndTime',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '状态',
  valueKey: 'enabled',
  value: '',
  type: 'select',
  options: [{
    value: '0',
    text: '停用'
  }, {
    value: '1',
    text: '启用'
  }],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '更新时间',
  valueKey: 'updateStartTime',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '20px',
  valueKey: 'updateEndTime',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}];