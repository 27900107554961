










































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/paper/express/new/components/dialog-send.vue"
}
