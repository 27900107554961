var agentAppointmentURL = {
  agentAppointment: {
    /** 获取顾问预约分页列表 */
    getAgentAppointmentPage: '/manage/customer/agentAppointment/getAgentAppointmentPage',

    /** 导出顾问预约数据 */
    exportAgentAppointmentData: '/manage/customer/agentAppointment/exportAgentAppointmentData',

    /** 删除顾问预约 */
    deleteAgentAppointment: '/manage/customer/agentAppointment/deleteAgentAppointment'
  }
};
export default agentAppointmentURL;