var VAL_WIDTH = '130px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '金价日期始',
  valueKey: 'priceDateBegin',
  value: '',
  type: 'date',
  props: {
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '金价日期止',
  valueKey: 'priceDateEnd',
  value: '',
  type: 'date',
  props: {
    style: {
      width: VAL_WIDTH
    }
  }
}];