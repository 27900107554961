import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import useImageParse from '@/hooks/use-image-parse';
var PUBLIC_CONFIG = {
  labelWidth: 150,
  col: 10
};
export var FORM_DETAIL_CONFIG = [{
  title: '基本信息',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'radio',
    label: '资讯类型',
    prop: 'informationType',
    props: {
      disabled: true
    },
    options: [{
      label: '平台文章',
      value: 1
    }, {
      label: '外部链接',
      value: 2
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '资讯标题',
    prop: 'name',
    props: {
      readonly: true
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '资讯链接',
    prop: 'linkUrl',
    props: {
      readonly: true
    },
    hidden: true
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '资讯封面',
    prop: 'coverUrl',
    props: {
      placeholder: '最多可传1张，图片大小不超过200KB，支GIF,JPEG,JPG,PNG,BMP格式',
      disabled: true
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'cascader',
    label: '所属分类',
    prop: 'classifyId',
    props: {
      props: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      multiple: true,
      disabled: true
    },
    options: []
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '作者',
    prop: 'author',
    props: {
      readonly: true
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    label: '发布时间',
    prop: 'releaseTime',
    props: {
      disabled: true
    }
  })]
}, {
  title: '文章正文',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'editor',
    label: '',
    prop: 'content',
    col: 24,
    hidden: false,
    props: {
      disabled: true
    }
  })]
}];