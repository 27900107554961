import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { TABLE_CONFIG, TABLE_LABEL } from './config/list';
import { DIALOG_FORM_CONFIG, DIALOG_FORM_DATA } from './config/add';
import { getHeaders } from '@/api-new/service';
import { useSuccessTip } from '@/hooks/use-tip';
import { cloneDeep } from 'lodash-es';
import api from '@/api-new';
import url from '@/api-new/url';
export default {
  name: 'CustomerList',
  data: function data() {
    return {
      loading: false,
      tableConfig: TABLE_CONFIG,
      tableLabel: TABLE_LABEL,
      httpConfig: {},

      /* 弹窗数据 */
      dialogFormData: cloneDeep(DIALOG_FORM_DATA),
      dialogFormConfig: cloneDeep(DIALOG_FORM_CONFIG),

      /* 弹窗是否显示 */
      dialogVisible: false,

      /* 弹窗标题 */
      dialogTitle: '新增等级'
    };
  },
  computed: {},
  methods: {
    /** 接口参数过滤函数 */
    filtersFormatter: function filtersFormatter() {},

    /* 弹窗确认 */
    dialogConfirm: function dialogConfirm() {
      var _this = this;

      this.$nextTick(function () {
        _this.$refs.form.submitForm();
      });
    },

    /* 弹窗确认 */
    dialogSubmit: function dialogSubmit() {
      var _this2 = this;

      if (this.dialogFormData.id) {
        this.dialogVisible = false;
      } else {
        api.ADD_CUSTOMER_LABEL(this.dialogFormData).then(function () {
          useSuccessTip();

          _this2.refresh();
        });
      }

      this.dialogVisible = false;
    },

    /** 点击表格项 */
    tableRowClick: function tableRowClick(action) {
      var _ref = action || {},
          clickItem = _ref.clickItem,
          row = _ref.row;

      var _ref2 = clickItem || {},
          type = _ref2.type;

      switch (type) {
        // 处理列表编辑
        case 'edit':
          this.Edit(row);
          break;
      }
    },
    tableClick: function tableClick(params) {
      console.log('params: ', params);

      var _ref3 = params || {},
          clickItem = _ref3.clickItem,
          selections = _ref3.selections;

      var labelIds = selections.map(function (item) {
        return item.id;
      });

      var _ref4 = clickItem || {},
          type = _ref4.type;

      if (type === 'add') {
        this.dialogVisible = true;
        this.dialogFormData = cloneDeep(DIALOG_FORM_DATA);
      }

      if (type === 'delete') {
        if (labelIds && labelIds === 0) {
          return this.$message.error('请选择要删除的标签');
        }

        this.delete(labelIds);
      }
    },
    refresh: function refresh() {
      this.$refs.jPage.getPageData();
    },

    /* 操作编辑 */
    Edit: function Edit(row) {
      console.log('row: ', row);
      this.dialogVisible = true;
      this.dialogTitle = '编辑标签';
      this.dialogFormData = row; // getDialogDetail(row.id);
    },

    /* 操作删除 */
    delete: function _delete(ids) {
      var _this3 = this;

      console.log(ids);
      api.DELETE_CUSTOMER_LABEL(ids).then(function () {
        useSuccessTip();

        _this3.refresh();
      });
    }
  },
  created: function created() {
    var host = url.host,
        apiPrev = url.apiPrev;
    var apiUrl = url.customer.label;
    this.httpConfig = {
      url: "".concat(host).concat(apiPrev).concat(apiUrl.getCustomerLabelPage),
      headers: getHeaders()
    };
  }
};