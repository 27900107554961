




















































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/bank/bill/nysszz/index.vue"
}
