//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import LOG_TYPE from "@/maps/enum/contract-log-type";
import ATTACHMENT_TYPE from "@/maps/enum/attachment-type";
import Attachment from "@/components/business/attachment";
import ContractLog from "@/components/business/contract-log";
import AUDIT from "@/maps/enum/status/audit";
import { auditFinance } from "@api/crm/gift/apply";
export default {
  name: "gift-applySelf-finance-audit",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Attachment: Attachment,
    ContractLog: ContractLog
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tabActive: "tabContract",
      //标签框默认选中
      collapseActive: ["1", "2"],
      //信息折叠默认展开
      attachmentGiftCheck: ATTACHMENT_TYPE.CONTRACT_GIFT_CKECK,
      //附件类型：礼品文件审批
      showLogDialog: false,
      contractLogType: LOG_TYPE.GIFT,
      formModel: {
        realPrice: 0.00 //实际成本

      },
      //表单
      rules: {}
    };
  },
  created: function created() {},
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.model = Object.assign({}, this.propsModel); //复制新对象         
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tabActive = "tabContract"; //默认打开第一个标签页
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    clickAuditFail: function clickAuditFail() {
      //审核不通过
      if (this.formModel.remark == null || this.formModel.remark == "") {
        this.$message("请填写审核意见");
      } else {
        this.audit(AUDIT.NO.value);
      }
    },
    clickAudit: function clickAudit() {
      //审核通过
      this.audit(AUDIT.YES.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = {
            applyGiftId: _this.model.applyGiftId,
            realPrice: _this.formModel.realPrice,
            audit: result,
            applyRemark: _this.formModel.accountantRemark,
            remark: _this.formModel.remark
          };
          _this.loading = true;
          auditFinance(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit("refresh");
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    // TODO 获取详情
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    }
  }
};