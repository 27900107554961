import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
import treeConfig from './option';
import YES_NO from '@/maps/enum/status/yesNo';
import { listOrgTree } from '@api/rm/org';
export default {
  name: 'tree-organization',
  props: {
    // 配置参数
    config: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      treeData: [],
      expanded: ['01']
    };
  },
  computed: {
    props: function props() {
      return _objectSpread(_objectSpread({}, treeConfig), this.config);
    }
  },
  methods: {
    handleNodeClick: function handleNodeClick(node) {
      this.$emit('node-click', node);
    },
    // TODO 获取机构树
    getTree: function getTree() {
      var _this2 = this;

      var _this = this;

      this.loading = true;
      var param = {// query: {
        //   enabled: YES_NO.YES.value,
        // }
      };
      listOrgTree(param).then(function (data) {
        // console.log('data', data);
        _this2.loading = false;
        _this2.treeData = data;
      }).catch(function (e) {
        _this2.loading = false;
      });
    },
    //展开选中树状结构
    setCheckedKeys: function setCheckedKeys(e) {
      var nodes = this.$refs.tree.store.nodesMap;

      for (var i in nodes) {
        if (i == e) {
          nodes[i].expanded = true; //展开选中

          nodes[i].isCurrent = true; //高亮颜色
        } else {
          nodes[i].expanded = false;
          nodes[i].isCurrent = false;
        }
      }

      this.expanded = [e]; //默认展开选中
    }
  },
  created: function created() {
    this.getTree();
  },
  mounted: function mounted() {}
};