var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("合同信息")
      ]),
      _c(
        "el-row",
        { staticClass: "block-col-3" },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("合同编号:")]),
            _vm._v(" " + _vm._s(_vm.model.contractCd) + " ")
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("合同状态:")]),
            _vm._v(" " + _vm._s(_vm.model.contractStateText) + " ")
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("开始日期:")]),
            _vm._v(" " + _vm._s(_vm.model.startDate) + " ")
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("到期日期:")]),
            _vm._v(" " + _vm._s(_vm.model.endDate) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "block-col-3" },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("客户名称:")]),
            _c("span", { staticClass: "d2-emphasis" }, [
              _vm._v(_vm._s(_vm.model.customerName))
            ])
          ]),
          _c("el-col", { attrs: { span: 18 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("机构:")]),
            _vm._v(" " + _vm._s(_vm.model.orgName) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "block-col-3" },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("产品:")]),
            _vm._v(" " + _vm._s(_vm.model.productName) + " ")
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("合同金额:")]),
            _vm._v(" " + _vm._s(_vm.model.amount) + " ")
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("期限:")]),
            _vm._v(" " + _vm._s(_vm.model.timeLimit) + " ")
          ]),
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("返还周期:")]),
            _vm._v(" " + _vm._s(_vm.model.returnPeriod) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }