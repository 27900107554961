import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
}; // 弹窗中form表单配置

export var DIALOG_FORM_CONFIG = {
  id: {
    hidden: true
  },
  addressLocationId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '广告位',
    rules: [{
      required: true,
      message: '广告位不能为空',
      trigger: ['blur', 'change']
    }],
    options: []
  }),
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '广告名称',
    rules: [{
      required: true,
      message: '广告名称不能为空',
      trigger: 'blur'
    }]
  }),
  describe: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '描述',
    props: {
      type: 'textarea',
      rows: 4,
      maxlength: 300,
      showWordLimit: true
    }
  }),
  pictureUrl: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '广告图片',
    props: {
      placeholder: '最多可传1张，图片大小不超过200KB，支GIF,JPEG,JPG,PNG,BMP格式'
    },
    rules: [{
      required: true,
      message: '广告图片不能为空',
      trigger: 'blur'
    }]
  }),
  linkType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '广告类型',
    options: [{
      label: '自定义链接',
      value: 1
    }, {
      label: '链接资讯',
      value: 2
    }, {
      label: '链接小程序',
      value: 3
    }, {
      label: '图片链接',
      value: 4
    }, {
      label: 'Web链接',
      value: 5
    }]
  }),
  imglinkUrl: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '图片',
    hidden: true
  }),
  linkUrl: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '广告链接',
    hidden: false
  }),
  appId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '小程序appId',
    hidden: true
  }),
  path: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '页面路径',
    hidden: true
  }),
  informationId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    slotName: 'info',
    label: '资讯',
    hidden: true,
    rules: [{
      required: true,
      message: '链接资讯不能为空',
      trigger: 'blur'
    }]
  }),
  sort: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'inputNumber',
    label: '优先级',
    props: {
      max: 999
    },
    rules: [{
      required: true,
      message: '优先级不能为空',
      trigger: 'blur'
    }]
  }),
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'radio',
    label: '状态',
    options: [{
      label: '启用',
      value: 1
    }, {
      label: '禁用',
      value: 0
    }],
    rules: [{
      required: true,
      message: '状态不能为空',
      trigger: 'blur'
    }]
  })
};
export var DIALOG_FORM_DATA = {
  id: null,
  addressLocationId: '',
  position: '',
  name: '',
  describe: '',
  pictureUrl: {},
  linkType: '',
  linkUrl: '',
  sort: '',
  status: 1,
  informationId: ''
};