// 金价
import request from '@/plugin/axios';
import func from '@/maps/url/crm/setup/monthFloatRate'; // TODO 新增

export function add(params) {
  return request.post(func.add, {
    body: params
  });
} // TODO 编辑

export function edit(params) {
  return request.post(func.edit, {
    body: params
  });
} // TODO 删除

export function remove(params) {
  return request.post(func.remove, {
    body: params
  });
} // TODO 列表

export function list(params) {
  return request.post(func.list, {
    body: params
  });
}