import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import DynamicForm from "@/components/common/dynamic-form";
import { formatDate } from "@/libs/crm/format";
import { moneyInOut, exportExcelMoneyInOut } from "@api/crm/report/report";
export default {
  name: "report-org-analyze",
  components: {
    DynamicForm: DynamicForm
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      tableTitle: {
        date: "",
        same: "",
        chain: ""
      },
      tableData: [],
      query: {}
    };
  },
  computed: {},
  mounted: function mounted() {
    this.setTableTitle();
    this.loadData();
  },
  methods: {
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.setTableTitle();
      this.loadData();
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 获取列表
    loadData: function loadData() {
      var _this = this;

      this.initFilter();
      var query = this.query;
      var param = {
        query: _objectSpread({}, query)
      };
      moneyInOut(param).then(function (res) {
        _this.tableData = [];

        if (res.data != null) {
          _this.tableData = res.data;

          for (var i = 0; i < _this.tableData.length; i++) {
            var m = _this.tableData[i];
            m.inPrivateOrg = _this.formatData(m.inPrivateOrg);
            m.inPrivateAgency = _this.formatData(m.inPrivateAgency);
            m.inPrivateLogistics = _this.formatData(m.inPrivateLogistics);
            m.inPublicOrg = _this.formatData(m.inPublicOrg);
            m.inPublicAgency = _this.formatData(m.inPublicAgency);
            m.inPublicLogistics = _this.formatData(m.inPublicLogistics);
            m.inRenew = _this.formatData(m.inRenew);
            m.outPrivate = _this.formatData(m.outPrivate);
            m.outPublic = _this.formatData(m.outPublic);
            m.outInterest = _this.formatData(m.outInterest);
          }
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    //format数据，金额转为“万元”
    formatData: function formatData(val) {
      var value = 0;

      if (val != null && val != "null" && val != "") {
        value = Math.floor(val / 10000 * 100) / 100;
      }

      return value;
    },
    //设置表头
    setTableTitle: function setTableTitle() {
      var query = this.$refs.form.getForm();
      var dateBegin = query.dateBegin;
      var dateEnd = query.dateEnd;

      if (dateBegin == "") {
        //搜索条件有日期，用搜索条件日期作表头，否则用当天日期
        var date = new Date();
        date.setDate(1);
        date.setMonth(date.getMonth() - 1);
        dateBegin = formatDate(date);
        this.$refs.form.$data.model.dateBegin = dateBegin;
      }

      if (dateEnd == "") {
        var _date = new Date();

        _date.setDate(1);

        _date.setMonth(_date.getMonth() + 1);

        _date.setDate(_date.getDate() - 1); //设置日期为月份最后一天（月+1再日-1）


        dateEnd = formatDate(_date);
        this.$refs.form.$data.model.dateEnd = dateEnd;
      }
    },
    //导出Excel
    clickExport: function clickExport() {
      var _this2 = this;

      this.$confirm("确定数据导出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var query = _this2.$refs.form.getForm();

        var param = {
          query: _objectSpread({}, query)
        };

        if (param.query.orgCd == "") {
          param.query.orgCd = "0150";
        }

        exportExcelMoneyInOut(param).catch(function (e) {
          _this2.$message({
            message: e,
            type: "error"
          });
        });
      }).catch(function () {});
    }
  },
  created: function created() {}
};