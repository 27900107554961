// 会员积分管理
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 礼品管理
{
  path: "".concat(baseRoute, "/memberPoints/gift"),
  name: 'gift',
  meta: {
    title: '积分礼品资料',
    auth: true,
    cache: false
  },
  component: _import('crm/memberPoints/gift')
}, // 礼品分类
{
  path: "".concat(baseRoute, "/memberPoints/giftClassify"),
  name: 'giftClassify',
  meta: {
    title: '积分礼品分类',
    auth: true,
    cache: true
  },
  component: _import('crm/memberPoints/giftClassify')
}, // 礼品兑换审批
{
  path: "".concat(baseRoute, "/memberPoints/examineAndApprove"),
  name: 'examineAndApprove',
  meta: {
    title: '积分礼品兑换',
    auth: true,
    cache: true
  },
  component: _import('crm/memberPoints/examineAndApprove')
}, // 发货管理
{
  path: "".concat(baseRoute, "/memberPoints/shipments"),
  name: 'shipments',
  meta: {
    title: '发货管理',
    auth: true,
    cache: true
  },
  component: _import('crm/memberPoints/shipments')
}, // 新增礼品
{
  path: "".concat(baseRoute, "/memberPoints/gift/add"),
  name: 'giftAdd',
  meta: {
    title: '新增/修改礼品',
    auth: true,
    cache: false
  },
  component: _import('crm/memberPoints/gift/add')
}, // 年化规则
{
  path: "".concat(baseRoute, "/memberPoints/annualRule"),
  name: 'annualRule',
  meta: {
    title: '年化规则',
    auth: true,
    cache: true
  },
  component: _import('crm/memberPoints/annualRule')
}, // 会员权益
{
  path: "".concat(baseRoute, "/memberPoints/memberInterest"),
  name: 'memberInterest',
  meta: {
    title: '会员权益',
    auth: true,
    cache: true
  },
  component: _import('crm/memberPoints/memberInterest')
}];