// 消息提醒
export default {
  list: '/manage/remind/list',
  // 列表    
  listPayment: '/remind/listPayment',
  // 用户赎回、付息需要出金的消息提醒    
  getManualModel: '/remind/getManualModel',
  // 工具：获取当前手工模式是否启动
  setManualModel: '/remind/setManualModel' // 工具：手动启动  

};