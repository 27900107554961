import { getEnumList } from '@api/common/common';
var VAL_WIDTH = '130px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '商户名称',
  valueKey: 'kind',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    filterable: true,
    style: {
      width: VAL_WIDTH
    }
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: 'bankBillKind'
  }
}];