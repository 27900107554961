import "core-js/modules/es.object.to-string.js";
// 合同录入
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatUserDataPromise, formatDate } from '@/libs/crm/format';
import func from '@/maps/url/crm/buy/buy'; // TODO 暂存

export function save(params) {
  var re = request.post(func.save, {
    body: params
  });
  return re;
} // TODO 提交

export function submit(params) {
  var re = request.post(func.submit, {
    body: params
  });
  return re;
} // TODO 删除

export function remove(params) {
  var re = request.post(func.remove, {
    body: params
  });
  return re;
} // TODO 检查合同编号是否存在

export function isContractCdExist(params) {
  var re = request.post(func.isContractCdExist, {
    body: params
  });
  return re;
} // TODO 试算收益

export function computeEarnings(params) {
  var re = request.post(func.computeEarnings, {
    body: params
  });
  return re;
} // TODO 列表. formatType格式化类型：0不格式化，1只格式化金额，2按用户保密设置设置

export function list(params, formatType) {
  var re = request.post(func.list, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 列表 formatType格式化类型：0不格式化，1只格式化金额，2按用户保密设置设置

export function listBase(params, formatType) {
  var re = request.post(func.listBase, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 详情

export function getInfo(params, formatType) {
  var re = request.post(func.getInfo, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 客服审核

export function auditService(params) {
  var re = request.post(func.auditService, {
    body: params
  });
  return re;
} // TODO 财务审核

export function auditFinance(params) {
  var re = request.post(func.auditFinance, {
    body: params
  });
  return re;
} // TODO 资金审核

export function auditMoney(params) {
  var re = request.post(func.auditMoney, {
    body: params
  });
  return re;
} // TODO 导出全部Excel

export function exportExcelCount(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelCount, {
    body: params
  }).then(function (blob) {
    util.download(blob, '认购结算' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 导出全部Excel

export function exportExcelAll(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelAll, {
    body: params
  }).then(function (blob) {
    util.download(blob, '合同' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 启动未清算合同Excel导出数据封装任务

export function exportExcelContractStart(params) {
  var re = request.post(func.exportExcelContractStart, {
    body: params
  });
  return re;
} // TODO 导出金雅福首饰销售订单模板

export function exportExcelJYFJewelrySaleTemplate(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelJYFJewelrySaleTemplate, {
    body: params
  }).then(function (blob) {
    util.download(blob, "金雅福首饰销售订单" + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 导出金雅福传承金销售订单模板

export function exportExcelJYFJewelryInheritSaleTemplate(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelJYFJewelryInheritSaleTemplate, {
    body: params
  }).then(function (blob) {
    util.download(blob, "金雅福传承文化金销售订单" + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 导出SAP销售订单模板

export function exportExcelSAPSaleTemplate(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelSAPSaleTemplate, {
    body: params
  }).then(function (blob) {
    util.download(blob, 'SAP销售模板' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 导出SAP发票模板

export function exportExcelSAPInvoiceTemplate(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelSAPInvoiceTemplate, {
    body: params
  }).then(function (blob) {
    util.download(blob, 'SAP发票模板' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 黄金克重明细列表

export function listGoldWeightDetail(params) {
  var re = request.post(func.listGoldWeightDetail, {
    body: params
  });
  return re;
} // TODO 生成合同编号

export function newContractCd(params) {
  var re = request.post(func.newContractCd, {
    body: params
  });
  return re;
} // TODO 检查Excel进度

export function checkExcelProcess(params) {
  var re = request.post(func.checkExcelProcess, {
    body: params
  });
  return re;
} // TODO 获取锁单信息

export function getLock(params) {
  var re = request.post(func.getLock, {
    body: params
  });
  return re;
} // TODO 合同上锁

export function lock(params) {
  var re = request.post(func.lock, {
    body: params
  });
  return re;
} // TODO 合同解锁

export function unlock(params) {
  var re = request.post(func.unlock, {
    body: params
  });
  return re;
}