//推介费
export default {
  listNoCommission: '/commission/listNoCommission',
  // 直营待推介费申请列表
  addPlan: '/commission/addPlan',
  // 创建计划
  listNoPersonPlan: '/commission/listNoPersonPlan',
  // 直营待录入人员计划列表
  listPlanDetail: '/commission/listPlanDetail',
  //获取各岗位按规则推介费明细
  listNoCommissionCompany: '/commission/listNoCommissionCompany',
  // 直营机构检查待处理推介费公司
  listCompanyNoCommission: '/commission/listCompanyNoCommission',
  // 直营机构待处理的推介费列表
  addApplication: '/commission/addApplication',
  //保存计划明细
  removeApplication: '/commission/removeApplication',
  // 删除推介费申请
  listCommissionApplication: '/commission/listCommissionApplication',
  // 推介费明细列表    
  addBatch: '/commission/addBatch',
  // 创建推介费台帐   
  removeBatch: '/commission/removeBatch',
  // 删除推介费台帐明细 
  listCommissionBatch: '/commission/listCommissionBatch',
  // 推介费台账列表
  listBatchDetail: '/commission/listBatchDetail',
  // 台账明细列表 
  exportExcelBatchDetail: '/commission/exportExcelBatchDetail',
  // 导出excel台帐明细   
  listBatchBySummaryId: '/commission/listBatchBySummaryId',
  // 汇总台帐列表
  addDetailToBatch: '/commission/addDetailToBatch',
  // 添加申请明细到台帐  
  removeDetailFromBatch: '/commission/removeDetailFromBatch',
  // 从台帐中移除明细
  addBatchSpecial: '/commission/addBatchSpecial',
  // 添加特殊申请
  removeBatchSpecial: '/commission/removeBatchSpecial',
  // 删除特殊申请
  listBatchSpecial: '/commission/listBatchSpecial',
  // 特殊申请列表
  exportApplicationTemplate: '/commission/exportApplicationTemplate',
  // 导出推介费台账Excel
  sumBatch: '/commission/sumBatch',
  // 台帐汇总
  listSummary: '/commission/listSummary',
  // 推介费汇总报表列表
  addSummary: '/commission/addSummary',
  // 添加汇总报表
  sumSummary: '/commission/sumSummary',
  // 汇总表合并计算
  exportSummary: '/commission/exportSummary',
  // 导出汇总表
  confirmSummary: '/commission/confirmSummary',
  // 推介费汇总确认完成
  submit: '/commission/submit',
  // 提交
  auditOrg: '/commission/auditOrg',
  // 审核:分公司
  auditFinance: '/commission/auditFinance',
  // 财务审核
  auditFinanceManager: '/commission/auditFinanceManager' // 财务总监审核

};