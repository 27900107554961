import "core-js/modules/es.object.to-string.js";
// 划款
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatDate, formatUserDataPromise } from '@/libs/crm/format';
import func from '@/maps/url/crm/transfer/transfer'; // TODO 划款

export function transfer(params) {
  var re = request.post(func.transfer, {
    body: params
  });
  return re;
} // TODO 列表

export function list(params, formatType) {
  var re = request.post(func.list, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 合同各次划款列表

export function listContractTransfer(params, formatType) {
  var re = request.post(func.listContractTransfer, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 划款详情历史列表

export function listTransferLog(params, formatType) {
  var re = request.post(func.listTransferLog, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '划款' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}