import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import DynamicForm from "@/components/common/dynamic-form";
import { formatDate } from "@/libs/crm/format";
import { orgMoney, exportExcelOrgMoney } from "@api/crm/report/report";
export default {
  name: "report-org-money",
  components: {
    DynamicForm: DynamicForm
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      tableTitle: {
        date: "",
        week: "",
        month: ""
      },
      tableData: [],
      query: {}
    };
  },
  computed: {},
  mounted: function mounted() {
    this.setTableTitle();
    this.loadData();
  },
  methods: {
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.setTableTitle();
      this.loadData();
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 获取列表
    loadData: function loadData() {
      var _this = this;

      this.initFilter();
      var query = this.query;
      var param = {
        query: _objectSpread({}, query)
      };

      if (param.query.orgCd == "") {
        param.query.orgCd = "0150";
      }

      orgMoney(param).then(function (res) {
        if (res.data != null) {
          _this.tableData = res.data; //金额转为万元

          for (var i = 0; i < _this.tableData.length; i++) {
            var m = _this.tableData[i];
            m.amount = _this.formatData(m.amount);
            m.receiveAmount = _this.formatData(m.receiveAmount);
            m.yearAmount = _this.formatData(m.yearAmount);
            m.newCustomer = m.newCustomer;
            m.weekAmount = _this.formatData(m.weekAmount);
            m.weekReceiveAmount = _this.formatData(m.weekReceiveAmount);
            m.weekYearAmount = _this.formatData(m.weekYearAmount);
            m.weekNewCustomer = m.weekNewCustomer;
            m.monthAmount = _this.formatData(m.monthAmount);
            m.monthReceiveAmount = _this.formatData(m.monthReceiveAmount);
            m.monthYearAmount = _this.formatData(m.monthYearAmount);
            m.monthNewCustomer = m.monthNewCustomer;
          }
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    //format数据，金额转为“万元”
    formatData: function formatData(val) {
      var value = 0;

      if (val != null && val != "null" && val != "") {
        value = Math.floor(val / 10000 * 100) / 100;
      }

      return value;
    },
    //设置表头
    setTableTitle: function setTableTitle() {
      var query = this.$refs.form.getForm();
      var dateBegin = query.dateBegin;

      if (dateBegin == "") {
        //搜索条件有日期，用搜索条件日期作表头，否则用当天日期
        var _date = new Date();

        dateBegin = formatDate(_date);
        this.$refs.form.$data.model.dateBegin = dateBegin;
      }

      var arr = dateBegin.split("-");
      var date = new Date(arr[0], arr[1] - 1, arr[2]);
      this.tableTitle.date = dateBegin;
      var day = date.getDay(); //星期几

      if (day == 0) {
        //如果周日，查询上一周
        day = day + 7;
      }

      date.setDate(date.getDate() - day + 1); //星期开始，从周一开始

      var weekBegin = formatDate(date); //周开始日期

      date.setDate(date.getDate() + 6);
      var weekEnd = formatDate(date); //周结束日期

      this.tableTitle.week = "周统计(" + weekBegin + " ~ " + weekEnd + ")";
      date = new Date(arr[0], arr[1] - 1, arr[2]);
      date.setDate(1);
      var monthBegin = formatDate(date); //月份开始日期

      date.setMonth(date.getMonth() + 1);
      date.setDate(date.getDate() - 1);
      var monthEnd = formatDate(date); //月结束日期

      this.tableTitle.month = "月统计(" + monthBegin + " ~ " + monthEnd + ")";
    },
    //导出Excel
    clickExport: function clickExport() {
      var _this2 = this;

      this.$confirm("确定数据导出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var query = _this2.$refs.form.getForm();

        var param = {
          query: _objectSpread({}, query)
        };

        if (param.query.orgCd == "") {
          param.query.orgCd = "0150";
        }

        exportExcelOrgMoney(param).catch(function (e) {
          _this2.$message({
            message: e,
            type: "error"
          });
        });
      }).catch(function () {});
    }
  },
  created: function created() {}
};