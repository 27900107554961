























































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/paper/express/search/components/dialog-info.vue"
}
