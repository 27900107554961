//查询条件
import { listCacheOrg, listCacheProduct } from "@api/common/cache";
var VAL_WIDTH = '130px';
var filterLogType = [{
  value: 1,
  text: '直营'
}, {
  value: 2,
  text: '机构'
}, {
  value: 3,
  text: '吉林'
}, {
  value: 4,
  text: '其他渠道'
}, {
  value: 9,
  text: '全国'
}];
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '渠道',
  valueKey: 'type',
  value: '',
  type: 'select',
  options: filterLogType,
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '月份',
  valueKey: 'month',
  value: '',
  type: 'date',
  props: {
    type: 'month',
    valueFormat: 'yyyy-MM',
    style: {
      width: VAL_WIDTH
    },
    defaultTime: ['2022-01']
  }
}, {
  label: '开始日期',
  valueKey: 'dateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'dateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}];