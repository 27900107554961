import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
};
export var TOP_FORM = {
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '任务名称',
    props: {
      placeholder: '请输入任务名称'
    }
  }),
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '状态',
    props: {
      placeholder: '请选择状态'
    },
    options: [{
      label: '启用',
      value: 1
    }, {
      label: '禁用',
      value: 0
    }]
  })
};
export var TABLE_LABEL = [{
  label: '任务名称',
  prop: 'name'
}, {
  label: 'cron',
  prop: 'cron'
}, {
  label: '执行任务的方法',
  prop: 'method'
}, {
  label: '创建时间',
  prop: 'createTime',
  sortable: 'ScoreDetailList',
  sortProp: 'createTime'
}, {
  label: '状态',
  prop: 'status',
  formatter: function formatter(row, column, cellValue) {
    var res;

    switch (Number(cellValue)) {
      case 0:
        res = '禁用';
        break;

      case 1:
        res = '启用';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  type: 'operation',
  label: '操作',
  width: 200,
  formatter: function formatter(row) {
    var arr = [{
      type: 'edit',
      label: '编辑'
    }];

    if (row.status === 1) {
      arr.push({
        type: 'disabled',
        label: '禁用'
      });
    } else {
      arr.push({
        type: 'enable',
        label: '启用'
      });
    }

    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [{
    label: '新增',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    }
  }, {
    label: '执行任务',
    type: 'execute',
    props: {
      size: 'small',
      type: 'primary'
    }
  }, {
    label: '执行日志',
    type: 'log',
    props: {
      size: 'small',
      type: 'primary'
    }
  }]
};