import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 80
};
export var TOP_FORM = {
  name: {
    labelWidth: 100,
    type: 'input',
    label: '广告位名称',
    props: {
      placeholder: '请输入标题'
    }
  },
  code: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '编码',
    props: {
      placeholder: '请输入编码'
    }
  }),
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '状态',
    props: {
      placeholder: '请选择状态'
    },
    options: [{
      label: '启用',
      value: 1
    }, {
      label: '禁用',
      value: 0
    }]
  })
};
export var TABLE_LABEL = [{
  label: '广告位名称',
  prop: 'name'
}, {
  label: '编码',
  prop: 'code'
}, {
  label: '广告位宽度',
  prop: 'width'
}, {
  label: '广告位高度',
  prop: 'height'
}, {
  label: '广告位描述',
  prop: 'describe'
}, {
  label: '状态',
  prop: 'status',
  width: 150,
  formatter: function formatter(row) {
    var res = '';

    switch (row.status) {
      case 0:
        res = '禁用';
        break;

      case 1:
        res = '启用';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  type: 'operation',
  label: '操作',
  width: 200,
  formatter: function formatter() {
    var arr = [{
      type: 'edit',
      label: '修改',
      permission: 'advertisingPosition-edit'
    }, {
      type: 'delete',
      label: '删除',
      permission: 'advertisingPosition-delete'
    }];
    return arr;
  }
}];
export var TABLE_CONFIG = {
  actions: [{
    label: '新增广告位',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'advertisingPosition-edit'
  }]
};