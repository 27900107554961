var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        top: "7vh",
        width: "90%",
        title: "客服审核",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            "row-style": { height: "10px" },
            "cell-style": { padding: "3px 0" },
            height: "550px",
            "summary-method": _vm.setSummaryRow,
            "show-summary": ""
          }
        },
        [
          _c("el-table-column", { attrs: { type: "index", width: "50" } }),
          _c("el-table-column", {
            attrs: {
              prop: "contractCd",
              label: "合同编号",
              width: "120",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "customerName", label: "客户", width: "100" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "productName",
              label: "产品",
              width: "120",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orgName",
              label: "机构",
              width: "180",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: { label: "核算操作", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _c("p", [
                        _vm._v(
                          _vm._s(scope.row.goOnProductId ? "续约" : "确认 ")
                        )
                      ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "applyTypeText", label: "结算方式", width: "80" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "到期现金",
              width: "130",
              align: "right",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      scope.row.productCountType == "2" &&
                      scope.row.rentRansomType == "1"
                        ? _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.actualEarnings
                                    ? scope.row.actualEarnings
                                    : scope.row.expectEarnings
                                ) +
                                " "
                            )
                          ])
                        : _c("p", [_vm._v(" " + _vm._s(0) + " ")])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "applyBackCash",
              label: "提取金额",
              width: "130",
              align: "right",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      scope.row.applyType != "04"
                        ? _c("p", [
                            _vm._v(" " + _vm._s(scope.row.applyBackCash) + " ")
                          ])
                        : _c("p", [_vm._v(" " + _vm._s(0) + " ")])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "investGoldWeight",
              label: "提取现货克重",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      !scope.row.investGoldWeight
                        ? _c("p", [_vm._v(" " + _vm._s(0) + " ")])
                        : _c("p", [
                            _vm._v(
                              " " + _vm._s(scope.row.investGoldWeight) + " "
                            )
                          ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "权益合计",
              width: "130",
              align: "right",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      scope.row.productCountType === "2" &&
                      scope.row.rentRansomType === "1"
                        ? _c("p", [_vm._v(" " + _vm._s(0) + " ")])
                        : _c("p", [
                            _vm._v(" " + _vm._s(scope.row.countAmountAll) + " ")
                          ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "startDate", label: "开始日期", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "endDate", label: "到期日期", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "countDate", label: "结算日期", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "countTypeText", label: "结算类型", width: "80" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "goOnProductName",
              label: "续约产品",
              width: "130",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "goOnAmount",
              label: "续约合同金额",
              width: "130",
              align: "right",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      !scope.row.goOnAmount
                        ? _c("p", [_vm._v(" " + _vm._s(0) + " ")])
                        : _c("p", [
                            _vm._v(" " + _vm._s(scope.row.goOnAmount) + " ")
                          ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "goOnEarnings",
              label: "续约收益金额",
              width: "130",
              align: "right",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      !scope.row.goOnEarnings
                        ? _c("p", [_vm._v(" " + _vm._s(0) + " ")])
                        : _c("p", [
                            _vm._v(" " + _vm._s(scope.row.goOnEarnings) + " ")
                          ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "countRemark",
              label: "核算备注",
              width: "180",
              "show-overflow-tooltip": ""
            }
          })
        ],
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("审核")
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核意见", prop: "advice" } },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.advice,
                            expression: "formModel.advice"
                          }
                        ],
                        staticClass: "el-textarea__inner",
                        attrs: { placeholder: "请填写审核意见" },
                        domProps: { value: _vm.formModel.advice },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formModel,
                              "advice",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "warning",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  return _vm.clickAuditFail()
                }
              }
            },
            [_vm._v("不同意")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  return _vm.clickAudit()
                }
              }
            },
            [_vm._v("同 意")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }