var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        top: "10vh",
        width: "500px",
        title: "【" + _vm.propsModel.name + "】敏感字段配置",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "div",
        [
          _c("p", [_vm._v("1.查看字段*号")]),
          _c("j-form", {
            ref: "form",
            attrs: {
              formConfig: _vm.dialogFormConfig,
              formData: _vm.dialogFormData
            },
            on: {
              "update:formData": function($event) {
                _vm.dialogFormData = $event
              },
              "update:form-data": function($event) {
                _vm.dialogFormData = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("p", [_vm._v("2.导出字段*号")]),
          _c("j-form", {
            ref: "form",
            attrs: {
              formConfig: _vm.dialogFormConfig1,
              formData: _vm.dialogFormData1
            },
            on: {
              "update:formData": function($event) {
                _vm.dialogFormData1 = $event
              },
              "update:form-data": function($event) {
                _vm.dialogFormData1 = $event
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "mini" },
              on: { click: _vm.dialogClose }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "", size: "mini" },
              on: { click: _vm.confirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }