var giftUrl = {
  gift: {
    /* 礼品管理 */
    manage: {
      /* 新增礼品 */
      giftAdd: '/manage/gift/add',

      /* 更新礼品 */
      update: '/manage/gift/update',

      /* 更新礼品状态 */
      updateStatus: '/manage/gift/updateStatus',

      /* 更新排序值 */
      updateSort: '/manage/gift/updateSort',

      /* 获取分页列表 */
      getPageList: '/manage/gift/getPageList',

      /* 获取详情数据 */
      getInfoById: '/manage/gift/getInfoById',

      /* 导出数据 */
      exportList: '/manage/gift/exportList',

      /* 获取礼品选择分页列表 */
      getChoosePageList: '/manage/gift/getChoosePageList'
    },

    /* 礼品分类 */
    classify: {
      /* 新增礼品分类 */
      giftClassiAdd: '/manage/giftClassification/add',

      /* 更新礼品分类 */
      update: '/manage/giftClassification/update',

      /* 更新礼品分类排序值 */
      updateSort: '/manage/giftClassification/updateSort',

      /* 更新礼品分类状态 */
      updateStatus: '/manage/giftClassification/updateStatus',

      /* 获取礼品分类详情 */
      getInfoById: '/manage/giftClassification/getInfoById',

      /* 获取礼品分类分页列表 */
      getPageList: '/manage/giftClassification/getPageList',

      /* 获取礼品分类下拉列表 */
      getChooseList: '/manage/giftClassification/getChooseList'
    },

    /* 审核 兑换 */
    examineAndApprove: {
      /* 获取分页数据 */
      getPage: '/manage/giftExchange/getPage',

      /* 导出数据 */
      exportList: '/manage/giftExchange/exportList',

      /* 客服审批 */
      customerServiceApproval: '/manage/giftExchange/customerServiceApproval',

      /* 财务审批 */
      financeApproval: '/manage/giftExchange/financeApproval',

      /* 客服批量审批 */
      customerServiceBatchApproval: '/manage/giftExchange/customerServiceBatchApproval',

      /* 财务批量审批 */
      financeBatchApproval: '/manage/giftExchange/financeBatchApproval',

      /* 取消礼品兑换审批数据 */
      cancelled: '/manage/giftExchange/cancelled',

      /* 新增礼品兑换 */
      addExchange: '/manage/giftExchange/add'
    },

    /* 发货 */
    shipments: {
      /* 获取分页数据 */
      getPage: '/manage/giftDelivery/getPage',

      /* 导出数据 */
      exportData: '/manage/giftDelivery/exportData',

      /* 发货 */
      ship: '/manage/giftDelivery/ship',

      /* 确认收货 */
      confirmReceipt: '/manage/giftDelivery/confirmReceipt',

      /* 导入批量发货 */
      importShip: '/manage/giftDelivery/importShip'
    },

    /* 年化规则 */
    annualRule: {
      /* 获取分页数据 */
      getPage: '/manage/annualizationRule/getPage',

      /* 新增 */
      add: '/manage/annualizationRule/add',

      /* 更新 */
      update: '/manage/annualizationRule/update',

      /* 详情 */
      getInfo: '/manage/annualizationRule/getInfo',

      /* 删除 */
      delete: '/manage/annualizationRule/delete'
    },

    /* 会员权益 */
    memberInterest: {
      /* 获取分页数据 */
      getPage: '/manage/entitlement/getPage',

      /* 新增 */
      add: '/manage/entitlement/add',

      /* 更新 */
      update: '/manage/entitlement/update',

      /* 详情 */
      getInfo: '/manage/entitlement/getInfo',

      /* 更新状态 */
      updateStatus: '/manage/entitlement/updateStatus',

      /* 删除 */
      delete: '/manage/entitlement/delete',

      /* 权益选择数据 */
      getChooseList: '/manage/entitlement/getChooseList'
    }
  }
};
export default giftUrl;