import _defineProperty from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";

var _methods;

import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { TOP_FORM, TABLE_CONFIG, TABLE_LABEL } from './config/list';
import { DIALOG_FORM_CONFIG, DIALOG_FORM_DATA } from './config/add';
import { getHeaders } from '@/api-new/service';
import { cloneDeep } from 'lodash-es';
import { useSuccessTip } from '@/hooks/use-tip';
import api from '@/api-new';
import url from '@/api-new/url';
export default {
  name: 'CustomerList',
  data: function data() {
    return {
      loading: false,
      topForm: TOP_FORM,
      filtersData: {},
      addVisible: false,
      tableConfig: TABLE_CONFIG,
      tableLabel: TABLE_LABEL,
      httpConfig: {},

      /* 弹窗数据 */
      dialogFormData: cloneDeep(DIALOG_FORM_DATA),
      dialogFormConfig: cloneDeep(DIALOG_FORM_CONFIG),

      /* 弹窗是否显示 */
      dialogVisible: false,

      /* 弹窗标题 */
      dialogTitle: '新增模板',
      options: []
    };
  },
  computed: {},
  methods: (_methods = {
    /** 接口参数过滤函数 */
    filtersFormatter: function filtersFormatter(params) {
      var startTime = params.startTime,
          endTime = params.endTime;
      delete params.createTime;
      return _objectSpread(_objectSpread({}, params), {}, {
        startTime: startTime ? "".concat(startTime, " 00:00:00") : null,
        endTime: endTime ? "".concat(endTime, " 23:59:59") : null
      });
    },
    tableRowChange: function tableRowChange() {},
    tableClick: function tableClick(params) {
      console.log('params: ', params);

      var _ref = params || {},
          clickItem = _ref.clickItem;

      var _ref2 = clickItem || {},
          type = _ref2.type;

      if (type === 'add') {
        this.dialogVisible = true;
        this.dialogFormData = cloneDeep(DIALOG_FORM_DATA);
      }
    },

    /* 弹窗确认 */
    dialogConfirm: function dialogConfirm() {
      var _this = this;

      this.$nextTick(function () {
        _this.$refs.form.submitForm();
      });
    },

    /* 弹窗确认 */
    dialogSubmit: function dialogSubmit() {
      this.addOrupdate();
    }
  }, _defineProperty(_methods, "tableRowChange", function tableRowChange(params) {
    var _ref3 = params || {},
        prop = _ref3.prop,
        row = _ref3.row;

    switch (prop) {
      case 'sort':
        this.Sort(row);
        break;
    }
  }), _defineProperty(_methods, "tableRowClick", function tableRowClick(action) {
    var _ref4 = action || {},
        clickItem = _ref4.clickItem,
        row = _ref4.row;

    var _ref5 = clickItem || {},
        type = _ref5.type;

    switch (type) {
      // 处理列表编辑
      case 'edit':
        this.Edit(row);
        break;
      // 处理列表删除

      case 'delete':
        this.Delete(row.id);
        break;
      // 处理列表停用

      case 'start':
        this.updateStatus(row);
        break;
      // 处理列表停用

      case 'stop':
        this.updateStatus(row);
        break;
    }
  }), _defineProperty(_methods, "addOrupdate", function addOrupdate() {
    var _this2 = this;

    api.ADD_OR_UPDATE_TEMPLATE(this.dialogFormData).then(function () {
      _this2.dialogVisible = false;
      useSuccessTip();

      _this2.$refs.jPage.getPageData();
    });
  }), _defineProperty(_methods, "updateStatus", function updateStatus(row) {
    var id = row.id,
        status = row.status;
    var isStop = status === 0; // 是否为停用

    api.UPDATE_STATUS_TEMPLATE({
      id: id,
      status: isStop ? 1 : 0
    });
    useSuccessTip();
    console.log(this.$refs.jPage);
    this.$refs.jPage.getPageData();
  }), _defineProperty(_methods, "Edit", function Edit(row) {
    var _this3 = this;

    var host = url.host,
        apiPrev = url.apiPrev;
    var apiUrl = url.activity.template;
    console.log('row: ', row);
    this.dialogVisible = true;
    this.dialogTitle = '编辑活动模板';
    api.COMMON_REQUEST(apiUrl.getActivityTemplate, {
      id: row.id
    }).then(function (res) {
      _this3.dialogFormData = res;
    });
  }), _defineProperty(_methods, "Delete", function Delete(idList) {
    var _this4 = this;

    this.$confirm('确定要删除所选模板吗？', '删除', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      api.DELETE_TEMPLATE(idList).then(function () {
        useSuccessTip();

        _this4.$refs.jPage.getPageData();
      });
    });
  }), _defineProperty(_methods, "Sort", function Sort(row) {
    var _this5 = this;

    var id = row.id,
        sort = row.sort;
    api.UPDATASORT_TEMPLATE({
      id: id,
      sort: sort
    }).then(function () {
      useSuccessTip();

      _this5.$refs.jPage.getPageData();
    });
  }), _methods),
  created: function created() {
    var host = url.host,
        apiPrev = url.apiPrev;
    var apiUrl = url.activity.template;
    this.httpConfig = {
      url: "".concat(host).concat(apiPrev).concat(apiUrl.listActivityTemplate),
      headers: getHeaders()
    };
  }
};