






















































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/buy/reserve/components/dialog-audit.vue"
}
