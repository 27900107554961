// 表头
var VAL_WIDTH = '100px';
export default [{
  label: '产品Id',
  prop: 'productId',
  width: VAL_WIDTH
}, {
  label: '产品名称',
  prop: 'productName',
  width: '180px'
}, {
  label: '机构专有',
  prop: 'orgName',
  width: '150px'
}, {
  label: '截止日期',
  prop: 'endDate',
  width: VAL_WIDTH
}, {
  label: '金额下限(万)',
  prop: 'amountLower',
  width: '120px',
  align: 'right'
}, {
  label: '金额上限(万)',
  prop: 'amountUpper',
  width: '120px',
  align: 'right'
}, {
  label: '是否按天数',
  prop: 'byDay',
  width: VAL_WIDTH,
  type: 'slot'
}, {
  label: '收益率',
  prop: 'rate',
  width: '650px'
}];