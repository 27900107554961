var merchantManagement = {
  merchantManagement: {
    /* 营销活动 */
    manage: {
      /* 列表分页 */
      getPage: '/manage/merchant/getPage',

      /* 新增 */
      add: '/manage/merchant/add',

      /* 更新 */
      update: '/manage/merchant/update',

      /* 删除 */
      delete: '/manage/merchant/delete'
    },

    /* 收付信息 */
    receivingPaymentManagement: {
      /* 生成订单号 */
      addVirtualGift: '/manage/paymentInformation/createOrderNumber',

      /* 新增付款单 */
      addPayment: '/manage/paymentInformation/addPayment',

      /* 新增收款单 */
      addReceipt: '/manage/paymentInformation/addReceipt',

      /* 商户选择数据列表 */
      getMerchantChooseList: '/manage/paymentInformation/getMerchantChooseList',

      /* 客户选择数据列表 */
      getCustomerChooseList: '/manage/paymentInformation/getCustomerChooseList',

      /* 收付信息分页数据 */
      getPage: '/manage/paymentInformation/getPage',

      /* 收付信息统计数据 */
      getStatistics: '/manage/paymentInformation/getStatistics',

      /* 支付记录 */
      getPaymentRecord: '/manage/paymentInformation/getPaymentRecord',

      /* 客户钱包选择列表 */
      getCustomerWalletChooseList: '/manage/paymentInformation/getCustomerWalletChooseList'
    }
  }
};
export default merchantManagement;