//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
export default {
  props: {
    imgList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      urls: []
    };
  },
  mixins: [dialogMixin],
  showDialog: {
    handler: function handler(val) {},
    immediate: true
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.urls = this.imgList;
        }
      }
    }
  },
  methods: {
    init: function init() {
      this.urls = [];
    },
    dialogClose: function dialogClose() {},
    openDialog: function openDialog() {},
    onImgDrap: function onImgDrap() {
      return false;
    }
  },
  mounted: function mounted() {},
  created: function created() {}
};