var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        },
        { name: "dialogDrag", rawName: "v-dialogDrag" }
      ],
      staticClass: "dialog-search-contract",
      attrs: {
        top: "2vh",
        width: "1155px",
        title: _vm.title,
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-tabs",
        {
          key: _vm.tableKey,
          on: { "tab-click": _vm.changeTab },
          model: {
            value: _vm.tabActive,
            callback: function($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "推介费信息", name: "tabContract" } },
            [
              _c("ContractInfo", { attrs: { model: this.model } }),
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _vm._v("推介费信息")
              ]),
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formModel,
                    rules: _vm.rules,
                    "label-width": "120px",
                    size: "mini",
                    "label-position": "left"
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticStyle: { "margin-right": "10px" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "规则名称", prop: "ruleId" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: "请选择"
                                    },
                                    model: {
                                      value: _vm.formModel.ruleId,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formModel, "ruleId", $$v)
                                      },
                                      expression: "formModel.ruleId"
                                    }
                                  },
                                  _vm._l(_vm.selectData.ruleId, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "推介费比例编码",
                                prop: "totalSaveAmount"
                              }
                            },
                            [
                              _c("div", [
                                _vm._v(_vm._s(_vm.formModel.ruleIdText))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否后勤人员",
                                prop: "isBackOffice"
                              }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.changeRadio },
                                  model: {
                                    value: _vm.formModel.isBackOffice,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "isBackOffice",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.isBackOffice"
                                  }
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("是")
                                  ]),
                                  _c("el-radio", { attrs: { label: "0" } }, [
                                    _vm._v("否")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticStyle: { "margin-right": "10px" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "推介费状态", prop: "status" }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled: !_vm.isStatus,
                                      clearable: "",
                                      placeholder: "请选择"
                                    },
                                    model: {
                                      value: _vm.formModel.status,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formModel, "status", $$v)
                                      },
                                      expression: "formModel.status"
                                    }
                                  },
                                  _vm._l(_vm.selectData.status, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.text,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "总次数", prop: "totalTimes" } },
                            [
                              _c("el-input", {
                                attrs: { title: "" },
                                model: {
                                  value: _vm.formModel.totalTimes,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "totalTimes", $$v)
                                  },
                                  expression: "formModel.totalTimes"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticStyle: { "margin-left": "10px" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "第几次", prop: "currentTimes" }
                              },
                              [
                                _c("el-input", {
                                  attrs: { title: "" },
                                  model: {
                                    value: _vm.formModel.currentTimes,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "currentTimes",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.currentTimes"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm.isSearch
                        ? _c("el-col", { attrs: { span: 6 } }, [
                            _c(
                              "div",
                              { staticStyle: { "margin-left": "10px" } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "支付日期",
                                      prop: "paymentDate"
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        placeholder: "请选择支付日期",
                                        "value-format": "yyyy-MM-dd"
                                      },
                                      model: {
                                        value: _vm.formModel.paymentDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formModel,
                                            "paymentDate",
                                            $$v
                                          )
                                        },
                                        expression: "formModel.paymentDate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticStyle: { "margin-right": "10px" } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "推介费类型", prop: "type" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择"
                                    },
                                    model: {
                                      value: _vm.formModel.type,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formModel, "type", $$v)
                                      },
                                      expression: "formModel.type"
                                    }
                                  },
                                  _vm._l(_vm.selectData.type, function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.text,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "推介费结算月份",
                                prop: "planDate"
                              }
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  "value-format": "yyyy-MM",
                                  type: "month",
                                  placeholder: "开始时间",
                                  size: "small"
                                },
                                on: { change: _vm.changeDate },
                                model: {
                                  value: _vm.formModel.planDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "planDate", $$v)
                                  },
                                  expression: "formModel.planDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticStyle: { "margin-left": "10px" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "运营年度", prop: "fiscalYear" }
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "year",
                                    "value-format": "yyyy",
                                    placeholder: "选择年"
                                  },
                                  model: {
                                    value: _vm.formModel.fiscalYear,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, "fiscalYear", $$v)
                                    },
                                    expression: "formModel.fiscalYear"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("el-col", { attrs: { span: 6 } }, [
                        _c(
                          "div",
                          { staticStyle: { "margin-left": "10px" } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "推介费日期",
                                  prop: "halfMonth"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      clearable: "",
                                      placeholder: "请选择"
                                    },
                                    model: {
                                      value: _vm.formModel.halfMonth,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "halfMonth",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.halfMonth"
                                    }
                                  },
                                  _vm._l(_vm.selectData.halfMonth, function(
                                    item
                                  ) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.text,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注", prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: "请填写备注"
                                },
                                model: {
                                  value: _vm.formModel.remark,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "remark", $$v)
                                  },
                                  expression: "formModel.remark"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("el-divider"),
              _c(
                "el-table",
                {
                  key: _vm.tableKey,
                  attrs: {
                    data: _vm.formModel.tableData,
                    border: "",
                    "row-style": { height: "20px" },
                    "cell-style": { padding: "6px 0" },
                    hight: "200"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "职级", prop: "titleId" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm.StatusText(scope.row.titleId)) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "比例", prop: "percent", align: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: { title: "" },
                              model: {
                                value: scope.row.percent,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "percent", $$v)
                                },
                                expression: "scope.row.percent"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "金额", prop: "bonus", align: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: { title: "" },
                              model: {
                                value: scope.row.bonus,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "bonus", $$v)
                                },
                                expression: "scope.row.bonus"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "人员", prop: "bonusUserName" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  size: "mini",
                                  filterable: "",
                                  remote: "",
                                  clearable: "",
                                  placeholder: "请录入人员名称",
                                  "remote-method": function(query) {
                                    _vm.searchPerson(
                                      query,
                                      scope.$index,
                                      scope.row
                                    )
                                  }
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.changePerson(
                                      scope.$index,
                                      scope.row
                                    )
                                  }
                                },
                                model: {
                                  value: scope.row.bonusUserName,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "bonusUserName", $$v)
                                  },
                                  expression: "scope.row.bonusUserName"
                                }
                              },
                              _vm._l(_vm.selectData.person, function(item) {
                                return _c(
                                  "el-option",
                                  {
                                    key: item.personuuid,
                                    attrs: {
                                      label: item.name,
                                      value: item.personuuid
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { float: "left" } },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          color: "#8492a6",
                                          "font-size": "13px"
                                        }
                                      },
                                      [_vm._v(_vm._s(item.orgName))]
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm.isShowNoUser
                    ? _c("el-table-column", {
                        attrs: { label: "无人岗位", prop: "title" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.titleId !== "i0"
                                    ? _c(
                                        "el-radio-group",
                                        {
                                          on: {
                                            change: function($event) {
                                              return _vm.changeCheckbox(
                                                scope.row
                                              )
                                            }
                                          },
                                          model: {
                                            value: scope.row.isNoUser,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "isNoUser",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.isNoUser"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "1" } },
                                            [_vm._v("是")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: "0" } },
                                            [_vm._v("否")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3222713855
                        )
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.formModel.contractId
            ? _c(
                "el-tab-pane",
                { attrs: { label: "附件", name: "tabAttachment" } },
                [
                  _c("attachment", {
                    ref: "componentAttachment",
                    attrs: {
                      "is-attachment-edit": true,
                      "attachment-contract-id": _vm.formModel.contractId,
                      "attachment-type": _vm.attachmentType
                    },
                    on: { onAttachmentOpen: _vm.onAttachmentOpen }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _vm.info.contractId != "" && _vm.info.applicationId
            ? _c(
                "el-button",
                {
                  staticClass: "contract-log-tip",
                  attrs: { plain: "", size: "mini", icon: "el-icon-finished" },
                  on: {
                    click: function($event) {
                      return _vm.showLog()
                    }
                  }
                },
                [_vm._v("审核日志")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "mini" },
              on: { click: _vm.dialogClose }
            },
            [_vm._v("取 消")]
          ),
          _vm.isSearch && _vm.formModel.status != "-99"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", size: "mini" },
                  on: { click: _vm.clickDelete }
                },
                [_vm._v("删 除")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                disabled: !_vm.isFormChange
              },
              on: {
                click: function($event) {
                  return _vm.clickEdit(true, _vm.isSearch)
                }
              }
            },
            [_vm._v("暂 存")]
          ),
          !_vm.isSearch
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", size: "mini" },
                  on: {
                    click: function($event) {
                      return _vm.clickEdit(false, _vm.addPlan)
                    }
                  }
                },
                [_vm._v("创建计划")]
              )
            : _vm._e()
        ],
        1
      ),
      _c("contract-log", {
        attrs: {
          "contract-id": _vm.info.contractId,
          active: _vm.contractLogType,
          extId: _vm.info.applicationId
        },
        model: {
          value: _vm.showLogDialog,
          callback: function($$v) {
            _vm.showLogDialog = $$v
          },
          expression: "showLogDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }