var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c(
        "el-row",
        { staticClass: "page-table-container", attrs: { type: "flex" } },
        [
          _c(
            "el-col",
            {
              staticClass: "list-container",
              staticStyle: { width: "1300px" },
              attrs: { span: 20 }
            },
            [
              _c("dynamic-form", {
                ref: "form",
                attrs: { props: _vm.filterProps, forms: _vm.filterForms }
              }),
              _c(
                "el-row",
                {
                  staticClass: "actions",
                  attrs: { type: "flex", justify: "space-between" }
                },
                [
                  _c(
                    "div",
                    [
                      _c("el-link", { attrs: { type: "success" } }, [
                        _vm._v("年化入金(万元)：" + _vm._s(_vm.sum.all))
                      ]),
                      _vm._v("      "),
                      _c("el-link", { attrs: { type: "success" } }, [
                        _vm._v("直营分公司：" + _vm._s(_vm.sum.org))
                      ]),
                      _vm._v("      "),
                      _c("el-link", { attrs: { type: "success" } }, [
                        _vm._v("代理机构：" + _vm._s(_vm.sum.agent))
                      ]),
                      _vm._v("      "),
                      _c("el-link", { attrs: { type: "success" } }, [
                        _vm._v("其他：" + _vm._s(_vm.sum.other))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            plain: "",
                            size: "mini",
                            icon: "el-icon-bottom-left"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickReset()
                            }
                          }
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            plain: "",
                            size: "mini",
                            icon: "el-icon-search"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickSearch()
                            }
                          }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("div", {
                staticStyle: { width: "80%", height: "400px" },
                attrs: { id: "chartsYearAmountAllDom" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }