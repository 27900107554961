import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import LOG_TYPE from "@/maps/enum/contract-log-type";
import AUDIT from "@/maps/enum/status/audit";
import { auditFinanceBatch } from "@api/crm/gift/apply";
export default {
  name: "gift-apply-finance-audit-batch",
  props: {
    propsModel: {
      type: Array,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      showLogDialog: false,
      contractLogType: LOG_TYPE.GIFT,
      tableData: [],
      formModel: {
        realPrice: 0.00 //实际成本

      },
      //表单
      rules: {}
    };
  },
  created: function created() {},
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    clickAuditFail: function clickAuditFail() {
      //审核不通过
      if (this.formModel.remark == null || this.formModel.remark == "") {
        this.$message("请填写审核意见");
      } else {
        this.audit(AUDIT.NO.value);
      }
    },
    clickAudit: function clickAudit() {
      //审核通过
      this.audit(AUDIT.YES.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var arrayApplyGiftId = [];

          _this.propsModel.forEach(function (n) {
            arrayApplyGiftId.push(n.applyGiftId);
          });

          var param = {
            arrayApplyGiftId: arrayApplyGiftId,
            realPrice: _this.formModel.realPrice,
            audit: result,
            applyRemark: _this.formModel.accountantRemark,
            remark: _this.formModel.remark
          };
          _this.loading = true;
          auditFinanceBatch(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit("refresh");
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    loadData: function loadData() {
      this.tableData = Object.assign([], this.propsModel); //复制新对象 
    }
  }
};