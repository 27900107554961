import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.number.constructor.js";
var PUBLIC_CONFIG = {
  labelWidth: 80
};
export var TOP_FORM = {
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '标题',
    props: {
      placeholder: '请输入标题'
    }
  }),
  releaseTime: {
    labelWidth: 110,
    type: 'datePicker',
    label: '创建时间',
    propGroup: ['startTime', 'endTime'],
    props: {
      type: 'daterange'
    }
  },
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '状态',
    props: {
      placeholder: '请选择状态'
    },
    options: [{
      label: '停用',
      value: 0
    }, {
      label: '启用',
      value: 1
    }]
  })
};
export var TABLE_LABEL = [{
  label: '封面',
  prop: 'coverUrl',
  type: 'img',
  formatter: function formatter(row) {
    return "".concat(process.env.VUE_APP_CLOUD_UPLOAD).concat(row.coverUrl);
  }
}, {
  label: '标题',
  prop: 'name',
  type: 'link'
}, {
  label: '创建时间',
  prop: 'createTime',
  sortable: 'custom',
  sortProp: 'createTime'
}, {
  label: '状态',
  prop: 'status',
  // type: 'slot',
  // slotName: 'releaseStatus',
  width: 150,
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 0:
        res = '停用';
        break;

      case 1:
        res = '启用';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  type: 'operation',
  label: '操作',
  width: 200,
  formatter: function formatter(row) {
    var arr = [{
      type: 'edit',
      label: '编辑',
      permission: 'infoMaterial-edit'
    }];

    if (row.status === 1) {
      arr.push({
        type: 'stop',
        label: '停用',
        permission: 'infoMaterial-status'
      });
    } else {
      arr.push({
        type: 'start',
        label: '启用',
        permission: 'infoMaterial-status'
      });
    }

    arr.push({
      type: 'copy',
      label: '复制链接'
    });
    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [{
    label: '新增',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'infoMaterial-edit'
  }],
  defaultSort: {
    prop: 'createTime',
    order: 'descending'
  }
};