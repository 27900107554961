//查询条件
import ENUM from "@/maps/enum/enum";
import { getEnumList } from "@api/common/common";
import { listCacheOrg, listCacheProduct } from "@api/common/cache";
import STATUS from "@/maps/enum/status/gift-apply";
var VAL_WIDTH = '150px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '合同编号',
  valueKey: 'contractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '客户',
  valueKey: 'customerName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '申请状态',
  valueKey: 'arrayStatus',
  value: [],
  type: 'select',
  options: filterCountStatus(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '产品',
  valueKey: 'arrayProductId',
  value: [],
  type: 'select',
  method: listCacheProduct,
  params: {},
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '机构名称',
  valueKey: 'orgCd',
  value: '',
  type: 'cascader',
  method: listCacheOrg,
  params: {},
  options: [],
  props: {
    //expandTrigger: 'hover',
    checkStrictly: true,
    emitPath: false,
    showAllLevels: false,
    value: 'orgCd',
    label: 'orgName',
    children: 'children'
  }
}, {
  label: '礼品名称',
  valueKey: 'giftName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '自费',
  valueKey: 'selfPaying',
  value: '',
  type: 'select',
  options: [{
    value: '0',
    text: '机构'
  }, {
    value: '1',
    text: '自费'
  }],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '发货日期',
  valueKey: 'provideDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'provideDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '合同开始日期',
  valueKey: 'startDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'startDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '合同到期日期',
  valueKey: 'endDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'endDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}]; //核算状态

function filterCountStatus() {
  var array = [STATUS.SAVE, STATUS.SUBMIT, STATUS.SERVICE_AUDIT, STATUS.DELIVER_GOODS, STATUS.CONFIRM_RECEIPT, STATUS.SUCCESS, STATUS.SERVICE_AUDIT_FAILURE, STATUS.FINANCE_AUDIT_FAILURE];
  return array;
}