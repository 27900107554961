//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '客户',
  prop: 'customerName',
  width: '80px'
}, {
  label: '手机号码',
  prop: 'mobile',
  width: '110px'
}, {
  label: '性别',
  prop: 'sexText',
  width: '50px'
}, {
  label: '年龄',
  prop: 'age',
  width: '50px'
}, {
  label: '证件类型',
  prop: 'idTypeText',
  width: '80px'
}, {
  label: '证件号码',
  prop: 'identification',
  width: '170px'
}, {
  label: '地址',
  prop: 'address',
  width: '300px'
}, {
  label: '生日',
  prop: 'birthday',
  width: VAL_WIDTH
}, {
  label: 'VIP级别',
  prop: 'vipText',
  width: VAL_WIDTH
}, {
  label: '归属机构',
  prop: 'orgName',
  width: '210px'
}];