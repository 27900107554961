var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("el-card", { staticClass: "box-card", attrs: { id: "user-info" } }, [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header"
          },
          [_c("span", [_vm._v("个人信息")])]
        ),
        _c(
          "div",
          { staticClass: "text item" },
          [
            _c(
              "el-row",
              { staticClass: "block-col-2" },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("姓名:")]),
                  _vm._v(" " + _vm._s(_vm.person.name) + " ")
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("账号:")]),
                  _vm._v(" " + _vm._s(_vm.person.code) + " ")
                ])
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "block-col-2" },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("机构名称:")]),
                  _vm._v(" " + _vm._s(_vm.person.orgName) + " ")
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("岗位:")]),
                  _vm._v(" " + _vm._s(_vm.person.titleText) + " ")
                ])
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "block-col-2" },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("工号:")]),
                  _vm._v(" " + _vm._s(_vm.person.personNum) + " ")
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("是否后勤:")]),
                  _vm._v(" " + _vm._s(_vm.person.isBackofficeText) + " ")
                ])
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "block-col-2" },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("性别:")]),
                  _vm._v(" " + _vm._s(_vm.person.sexualText) + " ")
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("联系方式:")]),
                  _vm._v(" " + _vm._s(_vm.person.mobile) + " ")
                ])
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "block-col-2" },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("email:")]),
                  _vm._v(" " + _vm._s(_vm.person.email) + " ")
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("qq:")]),
                  _vm._v(" " + _vm._s(_vm.person.qq) + " ")
                ])
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "block-col-2" },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("微信:")]),
                  _vm._v(" " + _vm._s(_vm.person.weixin) + " ")
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("民族:")]),
                  _vm._v(" " + _vm._s(_vm.person.nationText) + " ")
                ])
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "block-col-2" },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("婚姻情况:")]),
                  _vm._v(" " + _vm._s(_vm.person.marriageStatusText) + " ")
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("政治面貌:")]),
                  _vm._v(" " + _vm._s(_vm.person.politicalTeamText) + " ")
                ])
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "block-col-2" },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("籍贯:")]),
                  _vm._v(" " + _vm._s(_vm.person.original) + " ")
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("身份证号:")]),
                  _vm._v(" " + _vm._s(_vm.person.identification) + " ")
                ])
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "block-col-2" },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [
                    _vm._v("身份证地址:")
                  ]),
                  _vm._v(" " + _vm._s(_vm.person.idAddress) + " ")
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("住址:")]),
                  _vm._v(" " + _vm._s(_vm.person.liveAddress) + " ")
                ])
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "block-col-2" },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("学历:")]),
                  _vm._v(" " + _vm._s(_vm.person.degreeText) + " ")
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("毕业院校:")]),
                  _vm._v(" " + _vm._s(_vm.person.college) + " ")
                ])
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "block-col-2" },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("专业:")]),
                  _vm._v(" " + _vm._s(_vm.person.major) + " ")
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [_vm._v("入职日期:")]),
                  _vm._v(" " + _vm._s(_vm.person.comeInDate) + " ")
                ])
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "block-col-2" },
              [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [
                    _vm._v("紧急联系人:")
                  ]),
                  _vm._v(" " + _vm._s(_vm.person.emName) + " ")
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("span", { staticClass: "d2-fwb" }, [
                    _vm._v("紧急联系方式:")
                  ]),
                  _vm._v(" " + _vm._s(_vm.person.emMobile) + " ")
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }