export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      showDialog: false
    };
  },
  watch: {
    value: {
      handler: function handler(val) {
        this.showDialog = val;
      },
      immediate: true
    },
    showDialog: {
      handler: function handler(val) {
        this.$emit('input', val);
      },
      immediate: true
    }
  },
  methods: {
    // TODO 点击取消
    cancel: function cancel() {
      this.showDialog = false;
      this.$emit('cancel');
    },
    // TODO 点击确定
    confirm: function confirm() {
      this.showDialog = false;
      this.$emit('confirm');
    }
  }
};