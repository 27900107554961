var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("el-alert", {
        staticStyle: { width: "400px", margin: "0 auto 50px" },
        attrs: {
          title: "更改密码",
          type: "warning",
          description: "您的密码不符合保密要求，请及时更改",
          "show-icon": "",
          closable: false
        }
      }),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          staticStyle: { width: "500px" },
          attrs: { "status-icon": "", "label-width": "100px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "新密码", prop: "password" } },
            [
              _c("el-input", {
                attrs: { type: "text", placeholder: "请输入新密码" },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              }),
              _c("div", [_vm._v("密码至少6位，包含大小写字母和数字")])
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitClick }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }