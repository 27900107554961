






















































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/commission/deal/components/dialog-application.vue"
}
