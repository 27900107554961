






















































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/apply/financeManager/index.vue"
}
