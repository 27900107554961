







































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/setup/monthFloatRate/components/dialog-add.vue"
}
