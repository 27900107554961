var render, staticRenderFns
var script = {}


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=vue-filename-injector"
if (typeof block0 === 'function') block0(component)

component.options.__file = "src/views/crm/paper/express/edit/index.vue"
export default component.exports