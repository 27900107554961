import "core-js/modules/es.object.to-string.js";
// 推介费
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatDate, formatUserDataPromise } from '@/libs/crm/format';
import func from '@/maps/url/crm/commission/commission'; // TODO 直营待推介费申请列表

export function listNoCommission(params, formatType) {
  var re = request.post(func.listNoCommission, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 创建计划

export function addPlan(params) {
  var re = request.post(func.addPlan, {
    body: params
  });
  return re;
} // TODO 直营待录入人员计划列表

export function listNoPersonPlan(params, formatType) {
  var re = request.post(func.listNoPersonPlan, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 获取各岗位按规则推介费明细

export function listPlanDetail(params, formatType) {
  var re = request.post(func.listPlanDetail, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 直营机构检查待处理推介费公司

export function listNoCommissionCompany(params) {
  var re = request.post(func.listNoCommissionCompany, {
    body: params
  });
  return re;
} // TODO 直营机构待处理的推介费列表

export function listCompanyNoCommission(params, formatType) {
  var re = request.post(func.listCompanyNoCommission, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 创建推介费申请

export function addApplication(params) {
  var re = request.post(func.addApplication, {
    body: params
  });
  return re;
} // TODO 删除推介费申请

export function removeApplication(params) {
  var re = request.post(func.removeApplication, {
    body: params
  });
  return re;
} // TODO 创建推介费台帐

export function addBatch(params) {
  var re = request.post(func.addBatch, {
    body: params
  });
  return re;
} // TODO 删除推介费台帐明细

export function removeBatch(params) {
  var re = request.post(func.removeBatch, {
    body: params
  });
  return re;
} // TODO 推介费台账列表

export function listCommissionBatch(params, formatType) {
  var re = request.post(func.listCommissionBatch, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 推介费台账列表

export function listBatchDetail(params, formatType) {
  var re = request.post(func.listBatchDetail, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 导出Excel

export function exportExcelBatchDetail(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelBatchDetail, {
    body: params
  }).then(function (blob) {
    util.download(blob, '台帐明细' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 添加申请明细到台帐

export function addDetailToBatch(params) {
  var re = request.post(func.addDetailToBatch, {
    body: params
  });
  return re;
} // TODO 从台帐中移除明细

export function removeDetailFromBatch(params) {
  var re = request.post(func.removeDetailFromBatch, {
    body: params
  });
  return re;
} // TODO 推介费明细列表

export function listCommissionApplication(params, formatType) {
  var re = request.post(func.listCommissionApplication, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 添加特殊申请

export function addBatchSpecial(params) {
  var re = request.post(func.addBatchSpecial, {
    body: params
  });
  return re;
} // TODO 删除特殊申请

export function removeBatchSpecial(params) {
  var re = request.post(func.removeBatchSpecial, {
    body: params
  });
  return re;
} // TODO 汇总台帐列表

export function listBatchBySummaryId(params) {
  var re = request.post(func.listBatchBySummaryId, {
    body: params
  });
  return re;
} // TODO 特殊申请列表

export function listBatchSpecial(params, formatType) {
  var re = request.post(func.listBatchSpecial, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 导出推介费台账Excel

export function exportApplicationTemplate(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportApplicationTemplate, {
    body: params
  }).then(function (blob) {
    util.download(blob, '直营推介费' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 台帐汇总

export function sumBatch(params) {
  var re = request.post(func.sumBatch, {
    body: params
  });
  return re;
} // TODO 推介费汇总报表列表

export function listSummary(params) {
  var re = request.post(func.listSummary, {
    body: params
  });
  return re;
} // TODO 汇总表合并计算

export function sumSummary(params) {
  var re = request.post(func.sumSummary, {
    body: params
  });
  return re;
} // TODO 添加汇总报表

export function addSummary(params) {
  var re = request.post(func.addSummary, {
    body: params
  });
  return re;
} // TODO 导出推介费台账Excel

export function exportSummary(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportSummary, {
    body: params
  }).then(function (blob) {
    util.download(blob, '直营分公司推介费汇总表' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 推介费汇总确认完成

export function confirmSummary(params) {
  var re = request.post(func.confirmSummary, {
    body: params
  });
  return re;
} // TODO 提交

export function submit(params) {
  var re = request.post(func.submit, {
    body: params
  });
  return re;
} // TODO 审核:分公司

export function auditOrg(params) {
  var re = request.post(func.auditOrg, {
    body: params
  });
  return re;
} // TODO 财务审核

export function auditFinance(params) {
  var re = request.post(func.auditFinance, {
    body: params
  });
  return re;
} // TODO 财务总监审核

export function auditFinanceManager(params) {
  var re = request.post(func.auditFinanceManager, {
    body: params
  });
  return re;
}