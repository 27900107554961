//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { getSmsSwitch, setSmsSwitch } from "@api/rm/tool";
export default {
  name: "tool-sms",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      smsSwitch: "OFF",
      //开关值
      rules: {}
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.getInfo();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.smsSwitch = "OFF";
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    //获取详情
    getInfo: function getInfo() {
      var _this = this;

      getSmsSwitch().then(function (res) {
        _this.smsSwitch = res.data;
      });
    },
    // TODO 确定
    confirm: function confirm() {
      var _this2 = this;

      var msg = "";

      if (this.smsSwitch == "ON") {
        msg = "注意：开启短信相关操作将会向客户发短信，测试环境请不要开启";
      } else if (this.smsSwitch == "OFF") {
        msg = "注意：关闭短信相关操作将无法发送短信";
      }

      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var param = {
          strSwitch: _this2.smsSwitch
        };
        setSmsSwitch(param).then(function (res) {
          _this2.$message({
            message: "已设置",
            type: "success"
          });

          _this2.showDialog = false;
        });
      }).catch(function () {});
    }
  }
};