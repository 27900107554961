//浮动利率
export default {
  add: '/floatAnnualRate/add',
  // 新增
  edit: '/floatAnnualRate/edit',
  // 编辑
  remove: '/floatAnnualRate/remove',
  // 删除   
  list: '/floatAnnualRate/list' // 列表    

};