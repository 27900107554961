






































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/report/product/withdrawalPlan/index.vue"
}
