import "core-js/modules/es.object.to-string.js";
// 合同回访
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatUserDataPromise, formatDate } from '@/libs/crm/format';
import func from '@/maps/url/crm/buy/visit'; // TODO 待回访合同列表

export function listContract(params, formatType) {
  var re = request.post(func.listContract, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 申请回访

export function add(params) {
  var re = request.post(func.add, {
    body: params
  });
  return re;
} // TODO 申请回访

export function remove(params) {
  var re = request.post(func.remove, {
    body: params
  });
  return re;
} // TODO 上传回访录音

export function uploadVudio(params) {
  var re = request.post(func.uploadVudio, {
    body: params
  });
  return re;
} // TODO 回访

export function doVisit(params) {
  var re = request.post(func.doVisit, {
    body: params
  });
  return re;
} // TODO 回访历史

export function listVisitLog(params, formatType) {
  var re = request.post(func.listVisitLog, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 回访申请列表

export function listVisit(params, formatType) {
  var re = request.post(func.listVisit, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 追加录音

export function appendFile(params) {
  var re = request.post(func.appendFile, {
    body: params
  });
  return re;
} // TODO 打开

export function open(params) {
  var re = request.post(func.open, {
    body: params
  });
  return re;
} // TODO 下载

export function download(params) {
  return blobRequest.post(func.download, {
    body: params
  }).then(function (blob) {
    util.download(blob, params.fileName);
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '私募回访' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}