var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        top: "10vh",
        width: "50%",
        title: "礼品",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabActive,
            callback: function($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "发货信息", name: "tabContract" } },
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.collapseActive,
                    callback: function($$v) {
                      _vm.collapseActive = $$v
                    },
                    expression: "collapseActive"
                  }
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "发货信息", name: "1" } },
                    [
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.tableData,
                            border: "",
                            "row-style": { height: "20px" },
                            "cell-style": { padding: "6px 0" },
                            height: "300px"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "contractCd",
                              label: "合同编号",
                              width: "160"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "customerName",
                              label: "姓名",
                              width: "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "giftName",
                              label: "礼品",
                              width: "160"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "expressCompanyName",
                              label: "快递",
                              width: "80"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "expressNo",
                              label: "单号",
                              width: "140"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "provideDate",
                              label: "发货日期",
                              width: "100"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              "el-table-column": "",
                              prop: "deliverGoodsRemark",
                              label: "发货备注",
                              width: "180"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "礼品签收单", name: "tabAttachment" } },
            [
              _c("attachment", {
                ref: "componentAttachment",
                attrs: {
                  "is-attachment-edit": true,
                  "attachment-contract-id": _vm.firstContractId,
                  "attachment-type": _vm.attachmentType
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  return _vm.clickReceive()
                }
              }
            },
            [_vm._v("收 货")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }