import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
import { formatAmountToString } from "@/libs/crm/format";
import { listGoldWeightDetail } from "@api/crm/buy/buy"; //合同

export default {
  props: {
    propsModel: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      loading: false,
      tableData: [] //数据

    };
  },
  computed: {},
  watch: {
    propsModel: {
      handler: function handler(val) {
        this.init();

        if (val != null && val != '') {
          this.loadTableData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.tableData = [];
    },
    loadTableData: function loadTableData() {
      var _this = this;

      var param = {
        contractId: this.propsModel
      };
      this.loading = true;
      listGoldWeightDetail(param).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data;

        _this.tableData.forEach(function (n) {
          n.amount = formatAmountToString(n.amount); //格式化千分位 

          n.goldPrice = n.goldPrice.toFixed(2);
          n.goldWeight = n.goldWeight.toFixed(2);
        });

        _this.$emit("onComplete", _this.tableData);
      });
    }
  },
  created: function created() {
    this.loading = false;
  }
};