import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AUDIT_PROCESS from "@/maps/enum/audit-process";
import dialogMixin from "@/mixins/dialog";
import COLOR from "@/maps/enum/color";
import STATUS from "@/maps/enum/status/commission-batch";
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import DialogBatchDetail from "./dialog-batch-detail";
import { listCommissionBatch, removeBatch } from "@api/crm/commission/commissionAgency";
export default {
  name: "commission-agency-batch",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    DialogBatchDetail: DialogBatchDetail
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      page: {
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0
      },
      tableData: [],
      process: AUDIT_PROCESS.NEW.value,
      //当前审核流程
      showBatchDetailDialog: false,
      // 推介费台帐明细窗口
      currentRow: {} // 当前点击行

    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    dialogClose: function dialogClose() {},
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    loadData: function loadData() {
      var _this = this;

      var param = {
        process: this.process,
        page: this.page,
        query: {
          orgCd: this.propsModel.orgCd
        }
      };
      listCommissionBatch(param, DATA_FORMAT_TYPE.AMOUNT).then(function (res) {
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              page = res.page;
          _this.tableData = data;
          _this.page = page;
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    //查看台帐明细
    clickBatchDetail: function clickBatchDetail(row) {
      this.currentRow = row;
      this.showBatchDetailDialog = true;
    },
    //删除台帐
    clickRemoveBatch: function clickRemoveBatch() {
      var _this2 = this;

      if (Object.keys(this.currentRow).length === 0) {
        this.$message("请选择合同");
      } else {
        var status = String(this.currentRow.status);

        if (status != STATUS.SAVE.value) {
          this.$message("台帐已生效不能删除");
        } else {
          this.$confirm("台帐删除明细将退回到推介费处理，确定要删除吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(function () {
            var param = {
              batchId: _this2.currentRow.batchId
            };
            removeBatch(param).then(function (res) {
              _this2.$message({
                message: "台账已删除",
                type: "success"
              });

              _this2.loadData();
            }).catch(function (e) {
              _this2.loading = false;
            });
          }).catch(function () {});
        }
      }
    },
    setStatusColor: function setStatusColor(status) {
      var className = "";
      status = String(status);

      switch (status) {
        case STATUS.SAVE.value:
          className = COLOR.BLUE;
          break;

        case STATUS.SUBMIT.value:
          className = COLOR.BLUE;
          break;

        case STATUS.ORG_AUDIT.value:
          className = COLOR.BLUE;
          break;

        case STATUS.FINANCE_AUDIT.value:
          className = COLOR.BLUE;
          break;

        case STATUS.COUNT_AUDIT.value:
          className = COLOR.BLUE;
          break;

        case STATUS.FINANCE_MANAGER_AUDIT.value:
          className = COLOR.GREEN;
          break;

        case STATUS.ORG_AUDIT_FAILURE.value:
          className = COLOR.RED;
          break;

        case STATUS.FINANCE_AUDIT_FAILURE.value:
          className = COLOR.RED;
          break;

        case STATUS.COUNT_AUDIT_FAILURE.value:
          className = COLOR.RED;
          break;

        case STATUS.FINANCE_MANAGER_AUDIT_FAILURE.value:
          className = COLOR.RED;
          break;
      }

      return className;
    },
    handleSizeChange: function handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.page.pageNumber = val;
      this.loadData();
    }
  }
};