// 消息类型
export var messageType = [{
  value: 1,
  text: '公司资讯'
}, // { value: 2, text: '员工资讯' },
// { value: 3, text: '目标达成' },
// { value: 4, text: '业绩冲刺' },
// { value: 5, text: '付息' },
{
  value: 6,
  text: '活动'
} // { value: 7, text: '新人入职' },
]; // 接收人类型

export var recipientType = [{
  value: 1,
  text: '全公司'
}, {
  value: 2,
  text: '理顾'
}, {
  value: 3,
  text: '个人'
}, {
  value: 4,
  text: '部门'
}];