var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "j-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: {
        width: "1300px",
        title: _vm.title,
        visible: _vm.showDialog,
        footActive: !_vm.isExamine
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        confirm: _vm.clickSubmit
      }
    },
    [
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("产品推介费")
      ]),
      _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formModel,
                rules: _vm.rules,
                "label-width": "100px",
                size: "mini"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品名称", prop: "productId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isExamine,
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择"
                              },
                              model: {
                                value: _vm.formModel.productId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "productId", $$v)
                                },
                                expression: "formModel.productId"
                              }
                            },
                            _vm._l(_vm.selectData.productId, function(item) {
                              return _c("el-option", {
                                key: item.productId,
                                attrs: {
                                  label: item.productName,
                                  value: item.productId
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-form-item", { attrs: { label: "产品编码" } }, [
                        _c("div", [_vm._v(_vm._s(_vm.formModel.productId))])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "适用合同开始日期",
                            prop: "startDate",
                            "label-width": "150px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "startDate" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  disabled: _vm.isExamine,
                                  "value-format": "yyyy-MM-dd",
                                  type: "date",
                                  placeholder: "开始时间",
                                  size: "small"
                                },
                                model: {
                                  value: _vm.formModel.startDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "startDate", $$v)
                                  },
                                  expression: "formModel.startDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "itme" }, [_vm._v("~")]),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "endDate" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  disabled: _vm.isExamine,
                                  "value-format": "yyyy-MM-dd",
                                  type: "date",
                                  placeholder: "结束时间",
                                  size: "small"
                                },
                                model: {
                                  value: _vm.formModel.endDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "endDate", $$v)
                                  },
                                  expression: "formModel.endDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "适用架构范围",
                            prop: "orgCd",
                            "label-width": "110px"
                          }
                        },
                        [
                          _c("el-cascader", {
                            attrs: {
                              disabled: _vm.isExamine,
                              "show-all-levels": false,
                              options: _vm.selectData.arrayOrg,
                              filterable: "",
                              props: {
                                checkStrictly: true,
                                emitPath: false,
                                value: "orgCd",
                                label: "orgName",
                                children: "children",
                                multiple: true
                              }
                            },
                            model: {
                              value: _vm.formModel.orgCd,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "orgCd", $$v)
                              },
                              expression: "formModel.orgCd"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "规则名称", prop: "ruleId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isExamine,
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择"
                              },
                              model: {
                                value: _vm.formModel.ruleId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "ruleId", $$v)
                                },
                                expression: "formModel.ruleId"
                              }
                            },
                            _vm._l(_vm.selectData.ruleId, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "规则编码", prop: "totalSaveAmount" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "clickRatioCode",
                              on: { click: _vm.clickRatioCode }
                            },
                            [_vm._v(_vm._s(_vm.formModel.ratioCode))]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否启用", prop: "enabled" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isExamine,
                                clearable: "",
                                placeholder: "请选择"
                              },
                              model: {
                                value: _vm.formModel.enabled,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "enabled", $$v)
                                },
                                expression: "formModel.enabled"
                              }
                            },
                            _vm._l(_vm.selectData.enabled, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }