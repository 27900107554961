import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import { FILTER_PROPS, FILTER_FORMS } from './option/filter'; // 过滤条件的form

import TABLE_LIST from './option/list'; // 表头

import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import { formatAmountToNumber, formatAmountToString } from '@/libs/crm/format';
import { listReturnPay, exportExcel } from '@api/crm/budget/budget';
export default {
  name: 'budget',
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {
        auto: false
      },
      pagination: {},
      query: {},
      tableData: [],
      sumInterest: 0
    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();

      if (this.query.contractCd == '' && this.query.customerName == '' && this.query.arrayProductId.length == 0 && this.query.orgCd == '' && (this.query.endDateBegin == '' || this.query.endDateEnd == '') && (this.query.payDateBegin == '' || this.query.payDateEnd == '')) {
        //如果一个日期设置，则别一个也必须设置
        this.$message('数据较多，请细化筛选条件');
      } else {
        this.$refs.jTable.pageChange(1);
      }
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      var param = {
        page: {
          pageNumber: 1,
          pageSize: 999
        },
        query: this.query
      };
      this.loading = true;
      listReturnPay(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.tableData = data;
          _this.pagination = {
            total: totalCount,
            pageSizes: [999]
          }; //合计

          var sum = 0;

          _this.tableData.forEach(function (n) {
            sum += formatAmountToNumber(n.interest);
          });

          _this.sumInterest = formatAmountToString(sum);
        } else {
          _this.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    clickExport: function clickExport() {
      var _this2 = this;

      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
      var isEmptySearch = true; //是否搜索条件为空

      for (var key in this.query) {
        if (this.query[key] != null && this.query[key] != '') {
          isEmptySearch = false;
          break;
        }
      }

      if (isEmptySearch) {
        this.$message('数据多，请细化搜索条件');
      } else {
        this.$confirm('确定按查询条件导出吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          var param = {
            query: _this2.query
          };
          exportExcel(param).catch(function (e) {
            _this2.$message({
              message: e,
              type: 'error'
            });
          });
        }).catch(function () {});
      }
    }
  },
  created: function created() {}
};