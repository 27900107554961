










































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/service/customer/birthday/components/dialog-add.vue"
}
