import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.error.cause.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash-es';
import { filters, tableLabel, table, dialogData, tableLabel1, table1, formConfig } from './config';
import { getHeaders } from '@/api-new/service';
import dayjs from 'dayjs';
import api from '@/api-new';
import url from '@/api-new/url';
var host = url.host,
    apiPrev = url.apiPrev;
var apiUrl = url.info.dailySentence;
export default {
  name: 'dailySentence',
  data: function data() {
    return {
      filters: cloneDeep(filters),
      tableLabel: cloneDeep(tableLabel),
      table: cloneDeep(table),
      dialogData: cloneDeep(dialogData),
      tableLabel1: cloneDeep(tableLabel1),
      table1: cloneDeep(table1),
      formConfig: cloneDeep(formConfig),
      operationType: null,
      httpConfig: {},
      tableData: [{
        content: '',
        author: '',
        displayDate: dayjs(new Date()).format('YYYY-MM-DD'),
        status: 1,
        sort: 0
      }],
      selectData: [],
      formData: {}
    };
  },
  created: function created() {
    /** 表格请求配置 */
    this.httpConfig = {
      url: "".concat(host).concat(apiPrev).concat(apiUrl.getPage),
      headers: getHeaders()
    };
  },
  methods: {
    // 筛选区域进行格式化处理
    filtersFormatter: function filtersFormatter(params) {
      params.beginCreateTime = params.beginCreateTime ? params.beginCreateTime + ' 00:00:00' : null;
      params.endCreateTime = params.endCreateTime ? params.endCreateTime + ' 23:59:59' : null;
      delete params.createTime;
      delete params.displayDate; // 默认排序为更新时间倒序

      if (params.page.orderBy == null) {
        params.page.orderBy = 'updateTime';
      }

      return params;
    },
    // 表格上方功能点击
    tableClick: function tableClick(params) {
      var _this = this;

      console.log('tableClick', params);

      if (params.clickItem.prop === 'add') {
        this.operationType = 'add';
        this.dialogData.dialogVisible = true;
        this.dialogData.title = '新增';
      } else if (params.clickItem.prop === 'delAll') {
        if (this.selectData.length <= 0) return this.$message.warning('请先勾选状态为停用的数据！'); // 批量删除

        this.$confirm('是否确定删除所选数据？', '删除', {
          type: 'warning'
        }).then(function () {
          _this.deleteSentence({
            ids: _this.selectData
          });
        });
      }
    },
    // 行点击事件
    tableRowClick: function tableRowClick(params) {
      var _this2 = this;

      if (params.clickItem.type === 'delete') {
        this.$confirm('是否确定删除所选数据？', '删除', {
          type: 'warning'
        }).then(function () {
          _this2.deleteSentence({
            ids: [params.row.id]
          });
        });
      } else if (params.clickItem.type === 'disable') {
        this.$confirm('是否确定将所选的数据停用？', '停用', {
          type: 'warning'
        }).then(function () {
          var data = {
            id: params.row.id,
            status: 0
          };

          _this2.updateSentenceStatus(data);
        });
      } else if (params.clickItem.type === 'start') {
        this.$confirm('是否确定将所选的数据启用？', '启用', {
          type: 'warning'
        }).then(function () {
          var data = {
            id: params.row.id,
            status: 1
          };

          _this2.updateSentenceStatus(data);
        });
      } else if (params.clickItem.type === 'edit') {
        this.getSentenceInfo({
          id: params.row.id
        });
        this.operationType = 'form';
        this.dialogData.width = '700px';
        this.dialogData.height = '500px';
        this.dialogData.dialogVisible = true;
        this.dialogData.title = '修改';
      }
    },
    // 行数据change事件
    tableRowChange: function tableRowChange(params) {
      if (params.prop === 'sort') {
        var data = {
          id: params.row.id,
          sort: params.row.sort
        };
        this.updateSentenceSort(data);
      }
    },
    // 新增表格行点击事件
    rowClickAdd: function rowClickAdd(params) {
      if (params.clickItem) {
        if (params.clickItem.type === 'batAdd') {
          this.$refs.j_table.tableDatas.push( // cloneDeep(this.$refs.j_table.tableDatas[params.index]),
          {
            content: '',
            author: '',
            displayDate: dayjs(new Date()).format('YYYY-MM-DD'),
            status: 1,
            sort: 0
          });
        } else if (params.clickItem.type === 'delete') {
          this.$refs.j_table.tableDatas.splice(params.index, 1);
        }
      }
    },
    // 全选勾选项
    selectionChange: function selectionChange(params) {
      this.selectData = params.filter(function (item) {
        return item.status === 0;
      }).map(function (it) {
        return it.id;
      });
    },
    // 表单提交
    formSubmit: function formSubmit() {
      var data = {
        id: this.formData.id,
        status: this.formData.status,
        author: this.formData.author,
        content: this.formData.content,
        sort: this.formData.sort,
        displayDate: dayjs(this.formData.displayDate).format('YYYY-MM-DD')
      };
      this.updateSentenceInfo(data);
    },
    confirmEvent: function confirmEvent() {
      var _this3 = this;

      if (this.operationType === 'form') {
        this.$refs.jForm.submitForm();
      } else if (this.operationType === 'add') {
        var data = this.$refs.j_table.tableDatas.map(function (item) {
          return {
            content: item.content,
            author: item.author,
            displayDate: item.displayDate ? dayjs(item.displayDate).format('YYYY-MM-DD') : null,
            status: item.status,
            sort: item.sort
          };
        });
        data.forEach(function (item) {
          if (!item.content) {
            _this3.$message.error('内容不能为空！');

            throw new Error();
          }

          if (!item.displayDate) {
            _this3.$message.error('展示日期不能为空！');

            throw new Error();
          }

          if (item.status === '') {
            _this3.$message.error('状态不能为空！');

            throw new Error();
          }
        });
        this.batchSentenceAdd({
          sentenceAddList: data
        });
      }
    },
    // 关闭弹框
    handleDialog: function handleDialog() {
      this.operationType = null;
      this.dialogData.dialogVisible = false;
      this.dialogData.width = '70%';
      this.dialogData.height = '600px';
      this.dialogData.title = '新增';
    },
    // 删除金句
    deleteSentence: function deleteSentence(data) {
      var _this4 = this;

      api.DELETE_SENTENCE(data).then(function (res) {
        _this4.$message.success('操作成功'); // 更新全选项为[]


        _this4.selectData = []; // 刷新列表数据

        _this4.$refs.j_page.getPageData(); // 清除已勾选项


        _this4.$refs.j_page.getTableInstance().clearSelection();
      }).catch(function () {});
    },
    // 更新状态
    updateSentenceStatus: function updateSentenceStatus(data) {
      var _this5 = this;

      api.UPDATE_SENTENCE_STATUS(data).then(function (res) {
        _this5.$message.success('操作成功');

        _this5.$refs.j_page.getPageData();
      }).catch(function () {});
    },
    // 更新排序值
    updateSentenceSort: function updateSentenceSort(data) {
      var _this6 = this;

      api.UPDATE_SENTENCE_SORT(data).then(function (res) {
        _this6.$message.success('操作成功');

        _this6.$refs.j_page.getPageData();
      }).catch(function () {});
    },
    // 增加金句
    batchSentenceAdd: function batchSentenceAdd(data) {
      var _this7 = this;

      api.BATCH_SENTENCE_ADD(data).then(function (res) {
        _this7.handleDialog();

        _this7.$message.success('操作成功');

        _this7.$refs.j_page.getPageData();
      }).catch(function () {});
    },
    // 获取金句详情
    getSentenceInfo: function getSentenceInfo(data) {
      var _this8 = this;

      api.GET_SENTENCE_INFO(data).then(function (res) {
        _this8.formData = res;
      }).catch(function () {});
    },
    // 修改金句
    updateSentenceInfo: function updateSentenceInfo(data) {
      var _this9 = this;

      api.UPDATE_SENTENCE_INFO(data).then(function (res) {
        _this9.handleDialog();

        _this9.$message.success('操作成功');

        _this9.$refs.j_page.getPageData();
      }).catch(function () {});
    }
  }
};