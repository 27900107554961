//赎回类型
export default {
  MONEY_ONLY: '01',
  // 现金
  MONEY_AND_JEWELRY: '02',
  // 现金+现货
  JEWELRY_AND_MONEY_APPEND: '03',
  // 现货+补差价
  JEWELRY: '04' // 现货

};