//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '合同编号',
  prop: 'contractCd',
  width: '150px'
}, {
  label: '状态',
  prop: 'contractStateText',
  width: '120px'
}, {
  label: '产品',
  prop: 'productName',
  width: '150px'
}, {
  label: '机构',
  prop: 'orgName',
  width: '210px'
}, {
  label: '客户',
  prop: 'customerName',
  width: '80px'
}, {
  label: '证件类型',
  prop: 'idTypeText',
  width: VAL_WIDTH
}, {
  label: '证件号',
  prop: 'identification',
  width: '170'
}, {
  label: '联系电话',
  prop: 'mobile',
  width: '120'
}, {
  label: '合同金额',
  prop: 'amount',
  width: '120px',
  align: 'right'
}, {
  label: '开始日期',
  prop: 'startDate',
  width: VAL_WIDTH
}, {
  label: '结束日期',
  prop: 'endDate',
  width: VAL_WIDTH
}, {
  label: '期限(月)',
  prop: 'timeLimit',
  width: '80px'
}, {
  label: '返还周期',
  prop: 'returnPeriod',
  width: '80px'
}, {
  label: '收款方式',
  prop: 'payMethodText',
  width: '180px'
}, {
  label: '银行',
  prop: 'bankName',
  width: '200px'
}, {
  label: '银行账号',
  prop: 'accountNo',
  width: '180px'
}, {
  label: '备注',
  prop: 'remark',
  width: '170px'
}];