//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { reCountBackup, reCount } from "@api/rm/tool";
export default {
  name: "tool-contract-recount",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      isBackup: false,
      //是否已备份  
      rules: {
        contractCd: [{
          required: true,
          message: "请输入合同编号",
          trigger: "input"
        }]
      }
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.isBackup = false;
      this.formModel = {
        contractCd: null
      };
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    clickBackUp: function clickBackUp() {
      var _this = this;

      var param = {
        contractCd: this.formModel.contractCd
      };
      reCountBackup(param).then(function (res) {
        _this.$message({
          message: "已备份数据",
          type: "success"
        });

        _this.isBackup = true;
      });
    },
    // TODO 确定
    confirm: function confirm() {
      var _this2 = this;

      var msg = "";
      this.$confirm(msg, '重新核算将清除后续的赎回，最后一次付息，续约流程相关数据，请确保没有影响？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (!_this2.isBackup) {
          _this2.$message("请先点击备份数据");
        } else {
          var param = {
            contractCd: _this2.formModel.contractCd
          };
          reCount(param).then(function (res) {
            _this2.$message({
              message: "已清理后续流程数据,并重新生成核算申请",
              type: "success"
            });

            _this2.showDialog = false;
          });
        }
      }).catch(function () {});
    }
  }
};