var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        "custom-class": _vm.attachmentOpened,
        top: "10vh",
        width: "1000px",
        title: "审核",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabActive,
            callback: function($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "合同信息", name: "tabContract" } },
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.collapseActive,
                    callback: function($$v) {
                      _vm.collapseActive = $$v
                    },
                    expression: "collapseActive"
                  }
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "合同信息", name: "1" } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v(_vm._s(_vm.labelContractCd) + ":")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.contractCd) + " ")
                          ]),
                          _vm.isHaveContractBuyCd
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v(_vm._s(_vm.labelBuyContractCd) + ":")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.buyContractCd) + " "
                                )
                              ])
                            : _vm._e(),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("状态:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.model.contractStateText) + " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("姓名:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.customerName))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("证件类型:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.idTypeText) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("证件号码:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.identification) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("联系方式:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.mobile) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 17 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("住址:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.address) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("机构:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.orgName) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("顾问:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.userName) + " ")
                          ])
                        ],
                        1
                      ),
                      _c("el-divider"),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("产品:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.productName))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("合同金额:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.amount))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("开始日期:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.model.startDate) +
                                " (存续:" +
                                _vm._s(_vm.model.holdDay) +
                                "天) "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("期限(月):")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.timeLimit) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("返还周期(月):")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.returnPeriod) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("到期日期:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.model.endDate) +
                                " (到期:" +
                                _vm._s(_vm.model.leftDay) +
                                "天) "
                            )
                          ])
                        ],
                        1
                      ),
                      _vm.isHaveAnnualRate
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("收益率(%):")
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.annualRate))
                                ])
                              ]),
                              _vm.model.floatAnnualRateTo > 0
                                ? _c("el-col", { attrs: { span: 7 } }, [
                                    _c("span", { staticClass: "d2-fwb" }, [
                                      _vm._v("浮动(%):")
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.model.floatAnnualRateFrom) +
                                        " ~ " +
                                        _vm._s(_vm.model.floatAnnualRateTo) +
                                        " "
                                    )
                                  ])
                                : _vm._e(),
                              _vm.isProductCategorySM
                                ? _c("el-col", { attrs: { span: 10 } }, [
                                    _c("span", { staticClass: "d2-fwb" }, [
                                      _vm._v(_vm._s(_vm.labelExpectCache) + ":")
                                    ]),
                                    _c("span", { staticClass: "d2-emphasis" }, [
                                      _vm._v(_vm._s(_vm.model.expectCache))
                                    ])
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _vm.isHaveExpectEarnings
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v(
                                    _vm._s(_vm.model.extLabelExpectEarnings) +
                                      ":"
                                  )
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.extExpectEarnings))
                                ])
                              ])
                            : _vm._e(),
                          _vm.isHaveGoldWeight
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("金价:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.contractGoldPrice) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.isHaveGoldWeight
                            ? _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("克重:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.contractGoldWeight) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("赎回方式:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.model.rentRansomTypeText) + " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("收款方式:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.payMethodText) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-1" },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("合同备注:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.remark) + " ")
                          ])
                        ],
                        1
                      ),
                      _c("el-divider"),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("账户类型:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.model.accountTypeText) + " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行户名:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.accountName) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行账号:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.accountNo) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-1" },
                        [
                          _c("el-col", { attrs: { span: 14 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行+分行+支行:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.bankName) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-1" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("省: ")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.BankCardInfo.province) + " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("市: ")
                            ]),
                            _vm._v(" " + _vm._s(_vm.BankCardInfo.city) + " ")
                          ]),
                          _vm.model.productCountType === "3"
                            ? _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("联行号: ")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.BankCardInfo.bankCode) + " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "赎回", name: "2" } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("申请赎回日:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.applyDate) + " ")
                          ]),
                          _vm.isHaveGoldWeight
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("赎回类型:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.applyMethodText) + " "
                                )
                              ])
                            : _vm._e(),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("赎回状态:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.applyStateText) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("结算方式:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.applyTypeText) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("手续费:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.serviceFee) + " ")
                          ]),
                          _vm.model.rentRansomTypeText != "黄金实物+现金"
                            ? _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("权益合计(本金+收益-手续费):")
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.countAmountAll))
                                ])
                              ])
                            : _vm._e(),
                          _vm.isProductCountType &&
                          _vm.model.rentRansomType == "1"
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("到期现金:")
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.expectEarnings))
                                ])
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          !_vm.isProductCategorySM
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("补差价:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.applyBackAppend) + " "
                                )
                              ])
                            : _vm._e(),
                          !_vm.isProductCategorySM &&
                          _vm.model.applyType != "01"
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("提取现货合计:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.applyBackGoldAmount) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.model.applyType != "04"
                            ? _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("提取金额:")
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.applyBackCash))
                                ])
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("续约产品:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.model.goOnProductName) + " "
                            )
                          ]),
                          _vm.model.rentRansomType == "1" &&
                          _vm.model.goOnProductId
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("续约克重:")
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.goOnWeight))
                                ])
                              ])
                            : _vm._e(),
                          _vm.model.rentRansomType == "1" &&
                          _vm.model.goOnProductId
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("续约金价:")
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(
                                    _vm._s(_vm.model.goOnContractGoldPrice)
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm.isProduct1021
                            ? _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("实际浮动收益(%):")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.monthFloatRate) + " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("续约合同金额:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.goOnAmount))
                            ])
                          ]),
                          !_vm.model.rentRansomType
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("续约收益金额:")
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.goOnEarnings))
                                ])
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.isProduct1004
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("累计积存金额:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.totalSaveAmount) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("累计积存黄金:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.totalSaveWeight) + " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isProduct1004
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("赠送黄金(克):")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.countGiftWeight) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("赠送金额:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.countGiftAmount) + " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isProductCategorySM && _vm.model.applyType != "01"
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("投资金条(克):")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.investGoldWeight) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("投资金条加工费:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.investGoldFee) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("投资金条合计:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.investGoldAmount) + " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isProductCategorySM && _vm.model.applyType != "01"
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("工艺金条(克):")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.productGoldWeight) +
                                    " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("工艺金条加工费:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.productGoldFee) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("工艺金条合计:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.productGoldAmount) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isProductCategorySM && _vm.model.applyType != "01"
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("黄金首饰(克):")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.jewelryGoldWeight) +
                                    " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("黄金首饰加工费:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.jewelryGoldFee) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("黄金首饰合计:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.jewelryGoldAmount) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isProductCategorySM && _vm.model.applyType != "01"
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("包装费:")
                                ]),
                                _vm._v(" " + _vm._s(_vm.model.packFee) + " ")
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("保价费:")
                                ]),
                                _vm._v(" " + _vm._s(_vm.model.insureFee) + " ")
                              ]),
                              _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("运费:")
                                ]),
                                _vm._v(" " + _vm._s(_vm.model.expressFee) + " ")
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isProductCategorySM && _vm.model.applyType != "01"
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("费用支付方式:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.feeDeductTypeText) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _vm.model.productCountType == "3" &&
                          _vm.model.productCategory1 == "5"
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("起息日期:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.actualStartDate) + " "
                                )
                              ])
                            : _vm._e(),
                          _c("el-col", { attrs: { span: 17 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("赎回备注:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.applyRemark) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("核算情况说明:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.model.countDescription ===
                                    "出金后当天续约"
                                    ? _vm.model.countDescription
                                    : ""
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "收货信息", name: "spotSignatureInfo" } },
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.collapseActive,
                    callback: function($$v) {
                      _vm.collapseActive = $$v
                    },
                    expression: "collapseActive"
                  }
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "收货信息", name: "1" } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("合同编码:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.SpotSignatureInfo.contractCd) +
                                " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("客户:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.SpotSignatureInfo.customerName))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("提货方式:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.SpotSignatureInfo.pickupTypeText) +
                                " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("货物名称:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.SpotSignatureInfo.goodsName) +
                                " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("提取现货合计(元):")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.SpotSignatureInfo.applyBackGoldAmount
                                ) +
                                " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("提取现货克重(克):")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.SpotSignatureInfo.goodsWeight) +
                                " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("发货人:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.SpotSignatureInfo.senderName) +
                                " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("发货日期:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.SpotSignatureInfo.sendDate) + " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("是否本人提货:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.SpotSignatureInfo.receiveSelfText) +
                                " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("收货人:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.SpotSignatureInfo.receiveName) +
                                " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("收货人电话:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.SpotSignatureInfo.receiveMobile) +
                                " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("收货地址:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.SpotSignatureInfo.receiveAddress) +
                                " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("快递公司:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.SpotSignatureInfo.expressName) +
                                " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("快递单号:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.SpotSignatureInfo.expressNo) +
                                " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("发货备注:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.SpotSignatureInfo.remark) + " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "结算申请", name: "tabAttachmentCount" } },
            [
              _c("attachment", {
                ref: "componentAttachmentCount",
                attrs: {
                  "is-attachment-edit": false,
                  "attachment-contract-id": _vm.propsModel.contractId,
                  "attachment-type": _vm.attachmentTypeCount
                },
                on: { onAttachmentOpen: _vm.onAttachmentOpen }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "出库货品", name: "tabAttachmentJewelryOut" } },
            [
              _c("attachment", {
                ref: "componentAttachmentJewelryOut",
                attrs: {
                  "attachment-contract-id": _vm.propsModel.contractId,
                  "attachment-ext-id": _vm.model.applyId,
                  "attachment-type": _vm.attachmentTypeJewelry
                },
                on: { onAttachmentOpen: _vm.onAttachmentOpen }
              })
            ],
            1
          ),
          _c(
            "el-tab-pane",
            {
              attrs: { label: "现货签收单", name: "tabAttachmentSpotSignature" }
            },
            [
              _c("attachment", {
                ref: "componentAttachmentSpotSignature",
                attrs: {
                  "is-attachment-edit": true,
                  "attachment-contract-id": _vm.propsModel.contractId,
                  "attachment-ext-id": _vm.model.applyId,
                  "attachment-type": _vm.attachmentTypeSpotSignature
                },
                on: { onAttachmentOpen: _vm.onAttachmentOpen }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "contract-log-tip",
              attrs: { plain: "", size: "mini", icon: "el-icon-finished" },
              on: {
                click: function($event) {
                  return _vm.showLog()
                }
              }
            },
            [_vm._v("审核日志")]
          ),
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("关 闭")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "", size: "mini" },
              on: { click: _vm.confirmReceipt }
            },
            [_vm._v("确认收货")]
          )
        ],
        1
      ),
      _c("contract-log", {
        attrs: {
          "contract-id": _vm.propsModel.contractId,
          active: _vm.contractLogType
        },
        model: {
          value: _vm.showLogDialog,
          callback: function($$v) {
            _vm.showLogDialog = $$v
          },
          expression: "showLogDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }