import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import BasicInfo from '../components/basicInfo.vue';
import Contract from '../components/contract.vue';
import Dynamic from '../components/dynamic.vue';
import Follow from '../components/follow.vue';
import Counselor from '../components/counselor.vue';
import api from '@/api-new';
import ElePermission from '@/store/modules/d2admin/modules/elePermission';
export default {
  components: {
    BasicInfo: BasicInfo,
    Dynamic: Dynamic,
    Contract: Contract,
    Follow: Follow,
    Counselor: Counselor
  },
  data: function data() {
    return {
      activeName: 'second',
      basicInfo: {},
      businessInfo: {},
      detailInfo: {},
      labelInfoList: [],
      info: {}
    };
  },
  methods: {
    // 判断是否拥有权限
    isHasPermission: function isHasPermission(value) {
      var permission = ElePermission.state.permission;

      if (permission && permission.length > 0) {
        return permission.indexOf(value) > -1;
      } else {
        return false;
      }
    },
    getDetail: function getDetail() {
      var _this = this;

      var query = this.$route.query;
      api.GET_CUSTOMER_INFO(query.id).then(function (res) {
        _this.info = res;
        _this.basicInfo = res.customerBaseInfo;
        _this.businessInfo = res.customerBusinessInfo;
        _this.detailInfo = res.customerDetailInfo;

        if (res.customerDetailInfo.customerInfoLabelList != null) {
          _this.labelInfoList = res.customerDetailInfo.customerInfoLabelList;
        }
      });
    },
    goBack: function goBack() {
      this.$router.push({
        name: 'customerManage'
      });
    }
  },
  created: function created() {
    this.getDetail(); //  进入详情页保存客户表头信息

    localStorage.setItem('filtersData', JSON.stringify(this.$route.query.filtersData));
  }
};