import "core-js/modules/es.object.to-string.js";
// 产品收益率
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import func from '@/maps/url/crm/setup/productRate';
import util from '@/libs/util';
import { formatDate } from '@/libs/crm/format'; // TODO 列表

export function list(params) {
  return request.post(func.list, {
    body: params
  });
} // TODO 详情

export function getInfo(params) {
  return request.post(func.getInfo, {
    body: params
  });
} // TODO 新增

export function add(params) {
  return request.post(func.add, {
    body: params
  });
} // TODO 保存

export function save(params) {
  return request.post(func.save, {
    body: params
  });
} // TODO 编辑

export function edit(params) {
  return request.post(func.edit, {
    body: params
  });
} // TODO 删除

export function remove(params) {
  return request.post(func.remove, {
    body: params
  });
} // TODO 获取收益率

export function getRate(params) {
  return request.post(func.getRate, {
    body: params
  });
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '产品收益信息' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}