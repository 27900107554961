// 弹窗中form表单配置
export var DIALOG_FORM_CONFIG = {
  id: {
    hidden: true
  },
  activityTypeText: {
    type: 'text',
    label: '活动类型'
  },
  orgName: {
    type: 'text',
    label: '主办方'
  },
  time: {
    type: 'text',
    label: '活动时间'
  },
  address: {
    type: 'text',
    label: '活动地点'
  },
  budget: {
    type: 'text',
    label: '活动预算'
  },
  targetAmountText: {
    type: 'text',
    label: '业绩目标'
  },
  activityRequires: {
    type: 'text',
    // slotName: 'activityRequires',
    label: '活动需求'
  },
  remark: {
    type: 'text',
    label: '备注'
  },
  customerList: {
    type: 'text',
    label: '活动对象'
  },
  isScoresText: {
    type: 'text',
    label: '活动积分'
  },
  commentScores: {
    type: 'text',
    label: '评价积分'
  },
  signInScores: {
    type: 'text',
    label: '签到积分'
  },
  auditStatus: {
    type: 'radio',
    label: '执行操作',
    options: [{
      'label': '审核通过',
      'value': 1
    }, {
      'label': '审核不通过',
      'value': 0
    }],
    rules: [{
      required: true,
      message: '状态不能为空',
      trigger: 'blur'
    }]
  },
  auditRemark: {
    type: 'input',
    label: '拒绝原因',
    rules: [{
      required: true,
      message: '拒绝原因不能为空',
      trigger: 'blur'
    }],
    props: {
      type: 'textarea',
      placeholder: '请输入拒绝原因',
      rows: 4,
      maxlength: 200,
      showWordLimit: true
    },
    hidden: true
  }
};
export var DIALOG_FORM_DATA = {
  id: null,
  activityTypeText: '',
  orgName: '',
  address: '',
  budgetList: '',
  targetAmountText: '',
  activityRequires: '',
  remark: '',
  customerList: 1,
  isScoresText: '',
  commentScores: '',
  signInScores: '',
  auditStatus: '',
  auditRemark: ''
};