import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { saveVideo } from '@api/crm/attachment/attachment'; //保存视频路径

import { list, remove } from '@api/crm/attachment/attachment';
import ATTACHMENT_TYPE from '@/maps/enum/attachment-type';
import { DIALOG_FORM_CONFIG, DIALOG_FORM_DATA, EXAMINE_FORM_CONFIG } from './config/add';
import { cloneDeep } from 'lodash';
export default {
  props: {
    isAttachmentEdit: {
      type: Boolean,
      default: false
    },
    attachmentContractId: {
      type: String,
      default: ''
    },
    attachmentType: {
      type: String,
      default: ''
    },
    attachmentExtId: {
      type: String,
      default: ''
    }
  },
  computed: {},
  watch: {
    attachmentContractId: {
      handler: function handler(val) {
        if (val != '') {
          this.loadData();
        }
      },
      immediate: true
    }
  },
  created: function created() {},
  data: function data() {
    return {
      dialogFormConfig: cloneDeep(DIALOG_FORM_CONFIG),
      dialogFormData: cloneDeep(DIALOG_FORM_DATA),
      examineFormConfig: cloneDeep(EXAMINE_FORM_CONFIG),
      examineFormData: cloneDeep(DIALOG_FORM_DATA),
      tableData: [],
      visible: false,
      examineVisible: false
    };
  },
  methods: {
    UploadVisible: function UploadVisible() {
      this.visible = true;
    },
    // 删除
    clickRemove: function clickRemove(row) {
      var _this = this;

      this.loading = true;
      remove({
        attachId: row.attachId
      }).then(function (res) {
        _this.loading = false;

        _this.tableData.splice(_this.tableData.findIndex(function (v) {
          return v.attachId === row.attachId;
        }), 1);

        _this.$message({
          message: '操作成功',
          type: 'success'
        });
      }).catch(function (e) {
        _this.loading = false;
      });
    },
    //点击单图查看
    clickBigPreview: function clickBigPreview(row) {
      this.examineFormData.linkUrl = {
        url: row.url
      };
      this.examineVisible = true;
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    loadData: function loadData() {
      var _this2 = this;

      var param = {
        contractId: this.attachmentContractId,
        extId: this.attachmentExtId,
        attachType: this.attachmentType
      };
      this.loading = true;
      list({
        query: param
      }).then(function (res) {
        _this2.loading = false;
        _this2.tableData = [];

        if (res.data != null) {
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = res.data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var item = _step.value;
              //列表项
              var paramTableData = {
                attachId: item.attachId,
                fileName: item.fileName,
                fileType: item.fileType,
                url: item.path
              };

              _this2.tableData.push(paramTableData);
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }
        } else {
          _this2.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function () {
        _this2.loading = false;

        _this2.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },

    /* 弹窗确认 */
    dialogSubmit: function dialogSubmit() {
      var _this3 = this;

      var linkUrl = this.dialogFormData.linkUrl;

      if (!linkUrl) {
        return this.$message({
          message: '请先上传视频',
          type: 'error'
        });
      }

      var param = {
        type: ATTACHMENT_TYPE.CONTRACT_VIDEO,
        path: linkUrl.url,
        fileName: linkUrl.name,
        contractId: this.attachmentContractId
      };
      saveVideo(param).then(function (res) {
        _this3.loadData();

        _this3.visible = false;

        _this3.$message({
          message: '上传成功',
          type: 'success'
        });
      });
    }
  }
};