//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import STATUS from "@/maps/enum/status/contract-reserve-buy";
import LOG_TYPE from "@/maps/enum/contract-log-type";
import ContractLog from "@/components/business/contract-log";
import { audit } from "@api/crm/buy/reserve"; //预约审核

export default {
  name: "buy-reserve-audit",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    ContractLog: ContractLog
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      collapseActive: ["1"],
      //信息折叠默认展开
      showLogDialog: false,
      contractLogType: LOG_TYPE.BUY,
      selectData: {
        audit: [] //审核结果

      },
      rules: {
        audit: [{
          required: true,
          message: "请选择审核结果",
          trigger: "input"
        }]
      }
    };
  },
  computed: {},
  created: function created() {
    //审核结果
    for (var key in STATUS) {
      if (STATUS[key].value != STATUS.AUDIT_NO.value) {
        this.selectData.audit.push(STATUS[key]);
      }
    }
  },
  watch: {},
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 确定
    confirm: function confirm() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = {
            id: _this.propsModel.id,
            audit: _this.formModel.audit,
            auditRemark: _this.formModel.auditRemark
          };
          _this.loading = true;
          audit({
            object: param
          }).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit("refresh");
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    }
  }
};