//到期与清算
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 到期查询
{
  path: "".concat(baseRoute, "/budget/budget"),
  name: 'budget-money',
  meta: {
    title: '预算>资金',
    auth: true,
    cache: true
  },
  component: _import('crm/budget/budget')
}];