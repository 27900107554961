var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("div", { staticStyle: { height: "56.23px" } }),
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          filtersFormatter: _vm.filtersFormatter,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        }
      }),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "新增/修改权益",
            top: "10vh",
            height: "800px",
            width: "800px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            confirm: _vm.dialogConfirm,
            close: _vm.dialogClose
          }
        },
        [
          _vm.dialogFormData
            ? _c("j-form", {
                ref: "form",
                attrs: {
                  formConfig: _vm.dialogFormConfig,
                  formData: _vm.dialogFormData
                },
                on: {
                  "update:formData": function($event) {
                    _vm.dialogFormData = $event
                  },
                  "update:form-data": function($event) {
                    _vm.dialogFormData = $event
                  },
                  submit: _vm.dialogSubmit
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "validDay",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "projectOrderNotice" }, [
                            _c(
                              "div",
                              { staticClass: "projectOrderNotice-input" },
                              [
                                _vm._v(" 有效期 获得后 "),
                                _c("el-input", {
                                  attrs: {
                                    "show-word-limits": "",
                                    maxlength: "256",
                                    placeholder: ""
                                  },
                                  model: {
                                    value: _vm.dialogFormData.validDay,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.dialogFormData,
                                        "validDay",
                                        $$v
                                      )
                                    },
                                    expression: "dialogFormData.validDay"
                                  }
                                }),
                                _vm._v(" 天失效 ")
                              ],
                              1
                            ),
                            _c("span", { staticClass: "bank-tips" }, [
                              _vm._v(
                                "为空则获得后不限有效期（等级变化后可能失效）"
                              )
                            ])
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "designatedCustomerName",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "designatedCustomer",
                              on: { click: _vm.clickDesignatedCustomer }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.dialogFormData.designatedCustomerName
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "attachmentUrl",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "file-box" }, [
                            _c(
                              "div",
                              { staticClass: "file-list" },
                              [
                                _vm._l(_vm.fileList, function(item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "file-item" },
                                    [
                                      _vm.fileList.length > 0
                                        ? _c("div", [
                                            (item.name
                                            ? _vm.imgType.includes(
                                                item.name.split(".")[1]
                                              )
                                            : false)
                                              ? _c(
                                                  "div",
                                                  { staticClass: "img" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "close" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-circle-close",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.remove(
                                                                index
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _c("img", {
                                                      attrs: {
                                                        src:
                                                          "" +
                                                          _vm.imageUrl +
                                                          item.url
                                                      }
                                                    })
                                                  ]
                                                )
                                              : _c("div", [
                                                  _vm._v(_vm._s(item.name))
                                                ])
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                }),
                                _c("j-uploadFile", {
                                  attrs: {
                                    serverType: 1,
                                    showFile: false,
                                    directory: "aamanage/image/jqImage/"
                                  },
                                  on: { upLoadFile: _vm.upLoadFile }
                                })
                              ],
                              2
                            ),
                            _c("div", { staticClass: "bank-tips" }, [
                              _vm._v(
                                " 最多可传1个文件，文件大小不超过100MB，支持图片GIF,JPEG,JPG,PNG,BMP,文件docx,xlsx,doc,xls "
                              )
                            ])
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1986153525
                )
              })
            : _vm._e()
        ],
        1
      ),
      _c("SearchCustomer", {
        attrs: { selectDate: _vm.selectDate },
        on: { onSelectItem: _vm.getUsers, close: _vm.getUsersClose },
        model: {
          value: _vm.openUser,
          callback: function($$v) {
            _vm.openUser = $$v
          },
          expression: "openUser"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }