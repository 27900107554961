var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        top: "7vh",
        width: "70%",
        title: "推介费批量分配",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            border: "",
            "row-style": { height: "20px" },
            "cell-style": { padding: "6px 0" },
            height: "550px",
            "summary-method": _vm.setSummaryRow,
            "show-summary": ""
          }
        },
        [
          _c("el-table-column", { attrs: { type: "index", width: "50" } }),
          _c("el-table-column", {
            attrs: {
              prop: "contractCd",
              label: "合同编号",
              width: "120",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "customerName", label: "客户", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "orgName", label: "机构", width: "100" }
          }),
          _c("el-table-column", {
            attrs: { prop: "startDate", label: "合同开始日期", width: "110" }
          }),
          _c("el-table-column", {
            attrs: { prop: "endDate", label: "合同结束日期", width: "110" }
          }),
          _c("el-table-column", {
            attrs: { prop: "planDate", label: "推介费结算月份", width: "120" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "i0bonus",
              label: "顾问金额",
              width: "100",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "i0name", label: "顾问姓名", width: "135" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          size: "mini",
                          filterable: "",
                          remote: "",
                          disabled: scope.row.i0isNoUser === "1",
                          clearable: "",
                          placeholder: "请录入人员",
                          "remote-method": function(query) {
                            _vm.searchPerson(query, scope.row, "i0")
                          }
                        },
                        on: {
                          change: function($event) {
                            return _vm.changePerson(
                              scope.row,
                              "i0",
                              scope.row.i0name
                            )
                          }
                        },
                        model: {
                          value: scope.row.i0name,
                          callback: function($$v) {
                            _vm.$set(scope.row, "i0name", $$v)
                          },
                          expression: "scope.row.i0name"
                        }
                      },
                      _vm._l(_vm.selectData.person, function(item) {
                        return _c(
                          "el-option",
                          {
                            key: item.personuuid,
                            attrs: { label: item.name, value: item.personuuid }
                          },
                          [
                            _c("span", { staticStyle: { float: "left" } }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  float: "right",
                                  color: "#8492a6",
                                  "font-size": "13px"
                                }
                              },
                              [_vm._v(_vm._s(item.orgName))]
                            )
                          ]
                        )
                      }),
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "i1bonus",
              label: "团队总监金额",
              width: "110",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "i1name", label: "团队总监姓名", width: "135" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          size: "mini",
                          filterable: "",
                          remote: "",
                          disabled: scope.row.i1isNoUser === "1",
                          clearable: "",
                          placeholder: "请录入人员",
                          "remote-method": function(query) {
                            _vm.searchPerson(query, scope.row, "i1")
                          }
                        },
                        on: {
                          change: function($event) {
                            return _vm.changePerson(
                              scope.row,
                              "i1",
                              scope.row.i1name
                            )
                          }
                        },
                        model: {
                          value: scope.row.i1name,
                          callback: function($$v) {
                            _vm.$set(scope.row, "i1name", $$v)
                          },
                          expression: "scope.row.i1name"
                        }
                      },
                      _vm._l(_vm.selectData.person, function(item) {
                        return _c(
                          "el-option",
                          {
                            key: item.personuuid,
                            attrs: { label: item.name, value: item.personuuid }
                          },
                          [
                            _c("span", { staticStyle: { float: "left" } }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  float: "right",
                                  color: "#8492a6",
                                  "font-size": "13px"
                                }
                              },
                              [_vm._v(_vm._s(item.orgName))]
                            )
                          ]
                        )
                      }),
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "无人岗位", prop: "i1isNoUser", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-radio-group",
                      {
                        on: {
                          change: function($event) {
                            return _vm.changeCheckbox(
                              scope.row,
                              "i1",
                              scope.$index
                            )
                          }
                        },
                        model: {
                          value: scope.row.i1isNoUser,
                          callback: function($$v) {
                            _vm.$set(scope.row, "i1isNoUser", $$v)
                          },
                          expression: "scope.row.i1isNoUser"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("是")
                        ]),
                        _c("el-radio", { attrs: { label: "0" } }, [
                          _vm._v("否")
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "i4bonus",
              label: "营业部副总金额",
              width: "120",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "i4name", label: "营业部副总姓名", width: "135" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          size: "mini",
                          filterable: "",
                          remote: "",
                          disabled: scope.row.i4isNoUser === "1",
                          clearable: "",
                          placeholder: "请录入人员",
                          "remote-method": function(query) {
                            _vm.searchPerson(query, scope.row, "i4")
                          }
                        },
                        on: {
                          change: function($event) {
                            return _vm.changePerson(
                              scope.row,
                              "i4",
                              scope.row.i4name
                            )
                          }
                        },
                        model: {
                          value: scope.row.i4name,
                          callback: function($$v) {
                            _vm.$set(scope.row, "i4name", $$v)
                          },
                          expression: "scope.row.i4name"
                        }
                      },
                      _vm._l(_vm.selectData.person, function(item) {
                        return _c(
                          "el-option",
                          {
                            key: item.personuuid,
                            attrs: { label: item.name, value: item.personuuid }
                          },
                          [
                            _c("span", { staticStyle: { float: "left" } }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  float: "right",
                                  color: "#8492a6",
                                  "font-size": "13px"
                                }
                              },
                              [_vm._v(_vm._s(item.orgName))]
                            )
                          ]
                        )
                      }),
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "无人岗位", prop: "i4isNoUser", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-radio-group",
                      {
                        on: {
                          change: function($event) {
                            return _vm.changeCheckbox(
                              scope.row,
                              "i4",
                              scope.$index
                            )
                          }
                        },
                        model: {
                          value: scope.row.i4isNoUser,
                          callback: function($$v) {
                            _vm.$set(scope.row, "i4isNoUser", $$v)
                          },
                          expression: "scope.row.i4isNoUser"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("是")
                        ]),
                        _c("el-radio", { attrs: { label: "0" } }, [
                          _vm._v("否")
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "i2bonus",
              label: "营业部总经理金额",
              width: "135",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "i2name", label: "营业部总经理姓名", width: "135" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          size: "mini",
                          filterable: "",
                          remote: "",
                          disabled: scope.row.i2isNoUser === "1",
                          clearable: "",
                          placeholder: "请录入人员",
                          "remote-method": function(query) {
                            _vm.searchPerson(query, scope.row, "i2")
                          }
                        },
                        on: {
                          change: function($event) {
                            return _vm.changePerson(
                              scope.row,
                              "i2",
                              scope.row.i2name
                            )
                          }
                        },
                        model: {
                          value: scope.row.i2name,
                          callback: function($$v) {
                            _vm.$set(scope.row, "i2name", $$v)
                          },
                          expression: "scope.row.i2name"
                        }
                      },
                      _vm._l(_vm.selectData.person, function(item) {
                        return _c(
                          "el-option",
                          {
                            key: item.personuuid,
                            attrs: { label: item.name, value: item.personuuid }
                          },
                          [
                            _c("span", { staticStyle: { float: "left" } }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  float: "right",
                                  color: "#8492a6",
                                  "font-size": "13px"
                                }
                              },
                              [_vm._v(_vm._s(item.orgName))]
                            )
                          ]
                        )
                      }),
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "无人岗位", prop: "i2isNoUser", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-radio-group",
                      {
                        on: {
                          change: function($event) {
                            return _vm.changeCheckbox(
                              scope.row,
                              "i2",
                              scope.$index
                            )
                          }
                        },
                        model: {
                          value: scope.row.i2isNoUser,
                          callback: function($$v) {
                            _vm.$set(scope.row, "i2isNoUser", $$v)
                          },
                          expression: "scope.row.i2isNoUser"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("是")
                        ]),
                        _c("el-radio", { attrs: { label: "0" } }, [
                          _vm._v("否")
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "i3bonus",
              label: "区域总经理金额",
              width: "120",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "i3name", label: "区域总经理姓名", width: "135" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          size: "mini",
                          filterable: "",
                          remote: "",
                          disabled: scope.row.i3isNoUser === "1",
                          clearable: "",
                          placeholder: "请录入人员",
                          "remote-method": function(query) {
                            _vm.searchPerson(query, scope.row, "i3")
                          }
                        },
                        on: {
                          change: function($event) {
                            return _vm.changePerson(
                              scope.row,
                              "i3",
                              scope.row.i3name
                            )
                          }
                        },
                        model: {
                          value: scope.row.i3name,
                          callback: function($$v) {
                            _vm.$set(scope.row, "i3name", $$v)
                          },
                          expression: "scope.row.i3name"
                        }
                      },
                      _vm._l(_vm.selectData.person, function(item) {
                        return _c(
                          "el-option",
                          {
                            key: item.personuuid,
                            attrs: { label: item.name, value: item.personuuid }
                          },
                          [
                            _c("span", { staticStyle: { float: "left" } }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  float: "right",
                                  color: "#8492a6",
                                  "font-size": "13px"
                                }
                              },
                              [_vm._v(_vm._s(item.orgName))]
                            )
                          ]
                        )
                      }),
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "无人岗位", prop: "i3isNoUser", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-radio-group",
                      {
                        on: {
                          change: function($event) {
                            return _vm.changeCheckbox(
                              scope.row,
                              "i3",
                              scope.$index
                            )
                          }
                        },
                        model: {
                          value: scope.row.i3isNoUser,
                          callback: function($$v) {
                            _vm.$set(scope.row, "i3isNoUser", $$v)
                          },
                          expression: "scope.row.i3isNoUser"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("是")
                        ]),
                        _c("el-radio", { attrs: { label: "0" } }, [
                          _vm._v("否")
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "i5bonus",
              label: "后勤人员管理层金额",
              width: "150",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "i5name",
              label: "后勤人员管理层姓名",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          size: "mini",
                          filterable: "",
                          remote: "",
                          disabled: scope.row.i5isNoUser === "1",
                          clearable: "",
                          placeholder: "请录入人员",
                          "remote-method": function(query) {
                            _vm.searchPerson(query, scope.row, "i5")
                          }
                        },
                        on: {
                          change: function($event) {
                            return _vm.changePerson(
                              scope.row,
                              "i5",
                              scope.row.i5name
                            )
                          }
                        },
                        model: {
                          value: scope.row.i5name,
                          callback: function($$v) {
                            _vm.$set(scope.row, "i5name", $$v)
                          },
                          expression: "scope.row.i5name"
                        }
                      },
                      _vm._l(_vm.selectData.person, function(item) {
                        return _c(
                          "el-option",
                          {
                            key: item.personuuid,
                            attrs: { label: item.name, value: item.personuuid }
                          },
                          [
                            _c("span", { staticStyle: { float: "left" } }, [
                              _vm._v(_vm._s(item.name))
                            ]),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  float: "right",
                                  color: "#8492a6",
                                  "font-size": "13px"
                                }
                              },
                              [_vm._v(_vm._s(item.orgName))]
                            )
                          ]
                        )
                      }),
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "无人岗位", prop: "i5isNoUser", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-radio-group",
                      {
                        on: {
                          change: function($event) {
                            return _vm.changeCheckbox(
                              scope.row,
                              "i5",
                              scope.$index
                            )
                          }
                        },
                        model: {
                          value: scope.row.i5isNoUser,
                          callback: function($$v) {
                            _vm.$set(scope.row, "i5isNoUser", $$v)
                          },
                          expression: "scope.row.i5isNoUser"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("是")
                        ]),
                        _c("el-radio", { attrs: { label: "0" } }, [
                          _vm._v("否")
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sumBonus",
              label: "合计金额",
              width: "100",
              align: "right"
            }
          })
        ],
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("审核")
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分配意见", prop: "advice" } },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.advice,
                            expression: "formModel.advice"
                          }
                        ],
                        staticClass: "el-textarea__inner",
                        attrs: { placeholder: "请填写分配意见" },
                        domProps: { value: _vm.formModel.advice },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formModel,
                              "advice",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  return _vm.clickAuditPlan()
                }
              }
            },
            [_vm._v("退回上一级")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: {
                click: function($event) {
                  return _vm.clickAudit()
                }
              }
            },
            [_vm._v("完成分配")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }