//查询条件
import ENUM from '@/maps/enum/enum';
import { getEnumList } from '@api/common/common';
var VAL_WIDTH = '130px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '产品ID',
  valueKey: 'productId',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '产品名称',
  valueKey: 'productName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '产品类型',
  valueKey: 'productType',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_0247
  }
}, {
  label: '目录1',
  valueKey: 'arrayProductCategory1',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_0250
  }
}, {
  label: '目录2',
  valueKey: 'arrayProductCategory2',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_0252
  }
}, {
  label: '目录2',
  valueKey: 'arrayProductCategory2',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_0252
  }
}, {
  label: '目录3',
  valueKey: 'arrayProductCategory3',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: "productCategory3"
  }
}, {
  label: '结算属性',
  valueKey: 'arrayCountType',
  value: [],
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_PRODUCT_COUNT_TYPE
  }
}, {
  label: '销售状态',
  valueKey: 'sellState',
  value: '1',
  type: 'select',
  options: [{
    text: '在售',
    value: '1'
  }, {
    text: '停售',
    value: '0'
  }],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}];