var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "contract-add-dialog",
      attrs: {
        "custom-class": _vm.attachmentOpened,
        top: "10vh",
        width: "1050px",
        title: _vm.title,
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabActive,
            callback: function($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "合同信息", name: "tabContract" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formModel,
                    rules: _vm.rules,
                    "label-width": "100px",
                    size: "mini"
                  }
                },
                [
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("基本")
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "合同编号", prop: "contractCd" }
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: true },
                                model: {
                                  value: _vm.formModel.contractCd,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "contractCd", $$v)
                                  },
                                  expression: "formModel.contractCd"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "客户姓名", prop: "customerName" }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.model.customerName) + " "
                              ),
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.customerName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "customerName", $$v)
                                  },
                                  expression: "formModel.customerName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "证件类型", prop: "idType" } },
                            [
                              _vm._v(" " + _vm._s(_vm.model.idTypeText) + " "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.formModel.idType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, "idType", $$v)
                                    },
                                    expression: "formModel.idType"
                                  }
                                },
                                _vm._l(_vm.selectData.idType, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证件号码",
                                prop: "identification"
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.model.identification) + " "
                              ),
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.identification,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      "identification",
                                      $$v
                                    )
                                  },
                                  expression: "formModel.identification"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "购销合同号",
                                prop: "buyContractCd"
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.model.buyContractCd) + " "
                              ),
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.buyContractCd,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      "buyContractCd",
                                      $$v
                                    )
                                  },
                                  expression: "formModel.buyContractCd"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系方式", prop: "mobile" } },
                            [
                              _vm._v(" " + _vm._s(_vm.model.mobile) + " "),
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.mobile,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "mobile", $$v)
                                  },
                                  expression: "formModel.mobile"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "机构", prop: "orgName" } },
                            [
                              _vm._v(" " + _vm._s(_vm.model.orgName) + " "),
                              _c("el-input", {
                                attrs: { readonly: true },
                                model: {
                                  value: _vm.formModel.orgName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "orgName", $$v)
                                  },
                                  expression: "formModel.orgName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "顾问", prop: "userName" } },
                            [
                              _vm._v(" " + _vm._s(_vm.model.userName) + " "),
                              _c(
                                "el-input",
                                {
                                  attrs: { readonly: true },
                                  model: {
                                    value: _vm.formModel.userName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, "userName", $$v)
                                    },
                                    expression: "formModel.userName"
                                  }
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "append" },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "right",
                                            width: "910",
                                            trigger: "click"
                                          },
                                          model: {
                                            value: _vm.showSearchUser,
                                            callback: function($$v) {
                                              _vm.showSearchUser = $$v
                                            },
                                            expression: "showSearchUser"
                                          }
                                        },
                                        [
                                          _c("search-user", {
                                            ref: "searchUser",
                                            on: {
                                              onSelectItem: _vm.popSelectUser
                                            }
                                          }),
                                          _c("el-button", {
                                            attrs: {
                                              slot: "reference",
                                              type: "primary",
                                              icon: "el-icon-zoom-in",
                                              plain: ""
                                            },
                                            slot: "reference"
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "邮箱", prop: "email" } },
                            [
                              _vm._v(" " + _vm._s(_vm.model.email) + " "),
                              _c("el-input", {
                                attrs: { type: "email" },
                                model: {
                                  value: _vm.formModel.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "email", $$v)
                                  },
                                  expression: "formModel.email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "紧急联系人", prop: "emName" } },
                            [
                              _vm._v(" " + _vm._s(_vm.model.emName) + " "),
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.emName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "emName", $$v)
                                  },
                                  expression: "formModel.emName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "紧急联系方式", prop: "emMobile" }
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.model.emMobile) + " "),
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.emMobile,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "emMobile", $$v)
                                  },
                                  expression: "formModel.emMobile"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "住址", prop: "address" } },
                            [
                              _vm._v(" " + _vm._s(_vm.model.address) + " "),
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.address,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "address", $$v)
                                  },
                                  expression: "formModel.address"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("产品")
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品", prop: "productId" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                    disabled: true
                                  },
                                  model: {
                                    value: _vm.formModel.productId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, "productId", $$v)
                                    },
                                    expression: "formModel.productId"
                                  }
                                },
                                _vm._l(_vm.selectData.productId, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "整存/零存", prop: "saveMethod" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                    disabled: true
                                  },
                                  model: {
                                    value: _vm.formModel.saveMethod,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, "saveMethod", $$v)
                                    },
                                    expression: "formModel.saveMethod"
                                  }
                                },
                                _vm._l(_vm.selectData.saveMethod, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "期限(月)", prop: "timeLimit" } },
                            [
                              _vm._v(" " + _vm._s(_vm.model.timeLimit) + " "),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.changeEndDate()
                                      _vm.changeEarnings()
                                    }
                                  },
                                  model: {
                                    value: _vm.formModel.timeLimit,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, "timeLimit", $$v)
                                    },
                                    expression: "formModel.timeLimit"
                                  }
                                },
                                _vm._l(_vm.selectData.timeLimit, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "返还周期", prop: "returnPeriod" }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.model.returnPeriod) + " "
                              ),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.formModel.returnPeriod,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "returnPeriod",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.returnPeriod"
                                  }
                                },
                                _vm._l(_vm.selectData.returnPeriod, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "开始日期", prop: "startDate" } },
                            [
                              _vm._v(" " + _vm._s(_vm.model.startDate) + " "),
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.changeEndDate()
                                  }
                                },
                                model: {
                                  value: _vm.formModel.startDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "startDate", $$v)
                                  },
                                  expression: "formModel.startDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "到期日期", prop: "endDate" } },
                            [
                              _vm._v(" " + _vm._s(_vm.model.endDate) + " "),
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd"
                                },
                                model: {
                                  value: _vm.formModel.endDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "endDate", $$v)
                                  },
                                  expression: "formModel.endDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "合同金额", prop: "amount" } },
                            [
                              _vm._v(" " + _vm._s(_vm.model.amount) + " "),
                              _c("el-input", {
                                attrs: { type: "number" },
                                on: {
                                  change: function($event) {
                                    _vm.changeEarnings()
                                    _vm.changeGoldWeight()
                                  }
                                },
                                model: {
                                  value: _vm.formModel.amount,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "amount", $$v)
                                  },
                                  expression: "formModel.amount"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "收款方式", prop: "payMethod" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.model.payMethodText) + " "
                              ),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.formModel.payMethod,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, "payMethod", $$v)
                                    },
                                    expression: "formModel.payMethod"
                                  }
                                },
                                _vm._l(_vm.selectData.payMethod, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _vm.isHaveExpectEarnings
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "租金回报",
                                    prop: "expectEarnings"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.model.expectEarnings) + " "
                                  ),
                                  _c("el-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.formModel.expectEarnings,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "expectEarnings",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.expectEarnings"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isHaveGoldWeight
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "合同金价",
                                    prop: "contractGoldPrice"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.model.contractGoldPrice) +
                                      " "
                                  ),
                                  _c("el-input", {
                                    attrs: { type: "number" },
                                    on: {
                                      change: function($event) {
                                        return _vm.changeGoldWeight()
                                      }
                                    },
                                    model: {
                                      value: _vm.formModel.contractGoldPrice,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "contractGoldPrice",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.contractGoldPrice"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isHaveGoldWeight
                        ? _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "克重", prop: "goldWeight" }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.model.goldWeight) + " "
                                  ),
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "right",
                                        width: "650",
                                        trigger: "click"
                                      }
                                    },
                                    [
                                      _c("contract-gold-weight", {
                                        attrs: {
                                          propsModel: _vm.model.contractId
                                        }
                                      }),
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            type: "warning"
                                          },
                                          slot: "reference"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.model.contractGoldWeight)
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.isHaveAnnualRate
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isHaveFloatRate,
                                  expression: "isHaveFloatRate"
                                }
                              ],
                              attrs: { span: 6 }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "浮动下限",
                                    prop: "floatAnnualRateFrom"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.model.floatAnnualRateFrom) +
                                      " "
                                  ),
                                  _c("el-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.formModel.floatAnnualRateFrom,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "floatAnnualRateFrom",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formModel.floatAnnualRateFrom"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isHaveFloatRate,
                                  expression: "isHaveFloatRate"
                                }
                              ],
                              attrs: { span: 6 }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "浮动上限",
                                    prop: "floatAnnualRateTo"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.model.floatAnnualRateTo) +
                                      " "
                                  ),
                                  _c("el-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.formModel.floatAnnualRateTo,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "floatAnnualRateTo",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.floatAnnualRateTo"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isHaveGoldWeight
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "赎回方式",
                                    prop: "rentRansomType"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.model.rentRansomTypeText) +
                                      " "
                                  ),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择"
                                      },
                                      model: {
                                        value: _vm.formModel.rentRansomType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formModel,
                                            "rentRansomType",
                                            $$v
                                          )
                                        },
                                        expression: "formModel.rentRansomType"
                                      }
                                    },
                                    _vm._l(
                                      _vm.selectData.rentRansomType,
                                      function(item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.text,
                                            value: item.value
                                          }
                                        })
                                      }
                                    ),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isHaveContact,
                                  expression: "isHaveContact"
                                }
                              ],
                              attrs: { span: 7 }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "联系人",
                                    prop: "contactName"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.model.contactName) + " "
                                  ),
                                  _c("el-input", {
                                    model: {
                                      value: _vm.formModel.contactName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "contactName",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.contactName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isHaveContact,
                                  expression: "isHaveContact"
                                }
                              ],
                              attrs: { span: 10 }
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "联系电话",
                                    prop: "contactMobile"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.model.contactMobile) + " "
                                  ),
                                  _c("el-input", {
                                    model: {
                                      value: _vm.formModel.contactMobile,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formModel,
                                          "contactMobile",
                                          $$v
                                        )
                                      },
                                      expression: "formModel.contactMobile"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isHaveContact,
                              expression: "isHaveContact"
                            }
                          ],
                          attrs: { span: 24 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "联系地址",
                                prop: "contactAddress"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.contactAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      "contactAddress",
                                      $$v
                                    )
                                  },
                                  expression: "formModel.contactAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("银行")
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "类型", prop: "accountType" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.model.accountTypeText) + " "
                              ),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.formModel.accountType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "accountType",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.accountType"
                                  }
                                },
                                _vm._l(_vm.selectData.accountType, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "户名", prop: "accountName" } },
                            [
                              _vm._v(" " + _vm._s(_vm.model.accountName) + " "),
                              _c("el-input", {
                                on: {
                                  change: function($event) {
                                    return _vm.isShowBankCard3cButton()
                                  }
                                },
                                model: {
                                  value: _vm.formModel.accountName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "accountName", $$v)
                                  },
                                  expression: "formModel.accountName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 9 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "账号", prop: "accountNo" } },
                            [
                              _vm._v(" " + _vm._s(_vm.model.accountNo) + " "),
                              _c("el-autocomplete", {
                                staticClass: "inline-input",
                                staticStyle: { width: "270px" },
                                attrs: {
                                  oninput: "value = value.replace(/\\s/g, '')",
                                  "fetch-suggestions": _vm.queryBankCard,
                                  placeholder: "请输入银行卡号"
                                },
                                model: {
                                  value: _vm.formModel.accountNo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "accountNo", $$v)
                                  },
                                  expression: "formModel.accountNo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "银行", prop: "bankName" } },
                            [
                              _vm._v(" " + _vm._s(_vm.model.bankName) + " "),
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.bankName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "bankName", $$v)
                                  },
                                  expression: "formModel.bankName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("其他")
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "合同备注", prop: "remark" } },
                            [
                              _vm._v(" " + _vm._s(_vm.model.remark) + " "),
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.remark,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "remark", $$v)
                                  },
                                  expression: "formModel.remark"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "业绩归属日",
                                prop: "bussinessDate",
                                disabled: true
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.model.bussinessDate) + " "
                              ),
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  "value-format": "yyyy-MM-dd"
                                },
                                model: {
                                  value: _vm.formModel.bussinessDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formModel,
                                      "bussinessDate",
                                      $$v
                                    )
                                  },
                                  expression: "formModel.bussinessDate"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "变更内容", prop: "changeDesc" }
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.model.changeDesc) + " "),
                              _c("el-input", {
                                model: {
                                  value: _vm.formModel.changeDesc,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "changeDesc", $$v)
                                  },
                                  expression: "formModel.changeDesc"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "合同转让",
                                prop: "contractSignType"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.model.contractSignTypeText) +
                                  " "
                              ),
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.formModel.contractSignType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "contractSignType",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.contractSignType"
                                  }
                                },
                                _vm._l(
                                  _vm.selectData.contractSignType,
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.text,
                                        value: item.value
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.model.contractId != ""
            ? _c(
                "el-tab-pane",
                { attrs: { label: "附件", name: "tabAttachment" } },
                [
                  _c("attachment", {
                    ref: "componentAttachment",
                    attrs: {
                      "is-attachment-edit": false,
                      "attachment-contract-id": _vm.formModel.contractId,
                      "attachment-type": _vm.attachmentType
                    },
                    on: { onAttachmentOpen: _vm.onAttachmentOpen }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.formModel.changeApplyId != null
            ? _c(
                "el-tab-pane",
                { attrs: { label: "变更资料", name: "tabAttachmentChange" } },
                [
                  _c("attachment", {
                    ref: "componentAttachment",
                    attrs: {
                      "is-attachment-edit": _vm.isAttachmentCanEdit,
                      "attachment-contract-id": _vm.formModel.contractId,
                      "attachment-ext-id": _vm.formModel.changeApplyId,
                      "attachment-type": _vm.attachmentTypeChange
                    },
                    on: { onAttachmentOpen: _vm.onAttachmentOpen }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "contract-log-tip",
              attrs: { plain: "", size: "mini", icon: "el-icon-finished" },
              on: {
                click: function($event) {
                  return _vm.showLog()
                }
              }
            },
            [_vm._v("审核日志")]
          ),
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                plain: "",
                size: "mini",
                loading: _vm.loading,
                disabled: !_vm.isFormChange
              },
              on: { click: _vm.clickSave }
            },
            [_vm._v("暂存")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: { click: _vm.clickSubmit }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _c("contract-log", {
        attrs: {
          "contract-id": _vm.propsModel.contractId,
          active: _vm.contractLogType
        },
        model: {
          value: _vm.showLogDialog,
          callback: function($$v) {
            _vm.showLogDialog = $$v
          },
          expression: "showLogDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }