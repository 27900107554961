import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import COLOR from '@/maps/enum/color';
import STATUS from '@/maps/enum/status/express';
import EXPRESS from '@/maps/enum/express';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import { FILTER_PROPS, FILTER_FORMS } from './option/filter';
import TABLE_LIST from './option/list';
import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import DialogInfo from './components/dialog-info';
import DialogEdit from './components/dialog-edit';
import { listExpress, exportExcelExpress } from '@api/crm/paper/paper';
export default {
  name: 'paper-express-search',
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogInfo: DialogInfo,
    DialogEdit: DialogEdit
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {},
      pagination: {},
      query: {},
      tableData: [],
      showInfoDialog: false,
      //快递窗口
      showEditDialog: false,
      //编辑窗口
      currentRow: {},
      //当前点击行
      canEditStatus: [] //可以编辑的状态

    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      var query = this.query;
      var param = {
        page: page,
        query: query
      };
      this.loading = true;
      listExpress(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.tableData = data;
          _this.pagination = {
            total: totalCount
          };
        } else {
          _this.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    clickInfo: function clickInfo(row) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      this.currentRow = row;

      if (type === 'edit') {
        this.showEditDialog = true;
      } else {
        this.showInfoDialog = true;
      }
    },
    exportExcel: function exportExcel() {
      var _this2 = this;

      this.initFilter();
      var param = {
        query: this.query
      };
      exportExcelExpress(param).catch(function (e) {
        _this2.$message({
          message: e,
          type: 'error'
        });
      });
    },
    //物流跟踪
    clickExpressCheck: function clickExpressCheck(row) {
      //顺丰
      if (row.expressCompany == EXPRESS.SF.value) {
        var url = EXPRESS.SF.searchUrl + row.expressNo;
        window.open(url);
      }
    },
    setStatusColor: function setStatusColor(status) {
      //合同状态颜色
      var className = '';

      switch (status) {
        case STATUS.CONFIRM_SENT.value:
          className = COLOR.BLUE;
          break;

        case STATUS.CONFIRM_SIGN.value:
          className = COLOR.GREEN;
          break;

        case STATUS.LOST.value:
          className = COLOR.RED;
          break;
      }

      return className;
    }
  },
  created: function created() {}
};