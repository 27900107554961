import Vue from 'vue';
import Vuex from 'vuex';
import d2admin from './modules/d2admin';
Vue.use(Vuex);
/**
 * @description 创建一个 store
 * @returns store 实例
 */

function createStore() {
  return new Vuex.Store({
    modules: {
      d2admin: d2admin
    }
  });
}

export var store = createStore();
export var useStore = function useStore() {
  return store;
};
export default store;