import "core-js/modules/es.object.to-string.js";
import screenfull from 'screenfull';
export default {
  namespaced: true,
  state: {
    // 全屏激活
    active: false
  },
  actions: {
    /**
     * @description 初始化监听
     * @param {Object} context
     */
    listen: function listen(_ref) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        if (screenfull.enabled) {
          screenfull.on('change', function () {
            console.log('1');

            if (!screenfull.isFullscreen) {
              commit('set', false);
            }
          });
        } // end


        resolve();
      });
    },

    /**
     * @description 切换全屏
     * @param {Object} context
     */
    toggle: function toggle(_ref2) {
      var commit = _ref2.commit;
      return new Promise(function (resolve) {
        if (screenfull.isFullscreen) {
          screenfull.exit();
          commit('set', false);
        } else {
          screenfull.request();
          commit('set', true);
        } // end


        resolve();
      });
    }
  },
  mutations: {
    /**
     * @description 设置 store 里的全屏状态
     * @param {Object} state state
     * @param {Boolean} active active
     */
    set: function set(state, active) {
      state.active = active;
    }
  }
};