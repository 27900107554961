export default {
  expandOnClickNode: false,
  // 点击是否展开
  highlightCurrent: true,
  // 是否高亮当前项
  nodeKey: 'orgCd',
  // 唯一标识
  props: {
    children: 'childList',
    label: 'orgName'
  },
  defaultExpandedKeys: ['01']
};