import "core-js/modules/es.object.to-string.js";
// 金价
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import func from '@/maps/url/crm/setup/document'; // TODO 列表

export function list(params) {
  return request.post(func.list, {
    body: params
  });
} // TODO 上传

export function upload(params) {
  return request.post(func.upload, {
    body: params
  });
} // TODO 删除

export function remove(params) {
  return request.post(func.remove, {
    body: params
  });
} // TODO 下载

export function download(params) {
  return blobRequest.post(func.download, {
    body: params
  }).then(function (blob) {
    util.download(blob, params.fileName);
  }).catch(function (e) {
    return Promise.reject(e);
  });
}