var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        title: "文件上传",
        top: "30vh",
        width: "500px",
        modal: false,
        visible: _vm.showDialog,
        closeOnClickModal: false,
        "show-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "附件类型", prop: "attachmentType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          on: {
                            change: function($event) {
                              return _vm.changeIdAttachmentType()
                            }
                          },
                          model: {
                            value: _vm.formModel.attachmentType,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "attachmentType", $$v)
                            },
                            expression: "formModel.attachmentType"
                          }
                        },
                        _vm._l(_vm.selectData.attachmentType, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "upload-demo",
          attrs: {
            action: _vm.uploadUrl,
            headers: _vm.uploadHeader,
            "file-list": _vm.fileList,
            "auto-upload": false,
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
            "on-success": _vm.handleSuccess,
            "on-error": _vm.handleError,
            "on-change": _vm.handleChange,
            "before-upload": _vm.handleBeforeUpload,
            name: _vm.uploadFileName,
            data: _vm.uploadParams,
            multiple: ""
          }
        },
        [
          _c(
            "el-button",
            { attrs: { slot: "trigger", size: "small" }, slot: "trigger" },
            [_vm._v("文件")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", plain: "", size: "small" },
              on: { click: _vm.submitUpload }
            },
            [_vm._v("上传")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }