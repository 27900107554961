// import ENUM from '@/maps/enum/enum'
var VAL_WIDTH = '120px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '工号',
  valueKey: 'personNum',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '账号',
  valueKey: 'code',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '姓名',
  valueKey: 'name',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '启用',
  valueKey: 'enabled',
  value: '1',
  type: 'select',
  options: [{
    value: '0',
    text: '离职'
  }, {
    value: '1',
    text: '在职'
  }],
  props: {
    type: 'text',
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '有帐号',
  valueKey: 'haveCode',
  value: '',
  type: 'checkbox',
  options: ['1'],
  props: {
    type: 'checkbox',
    style: {
      width: VAL_WIDTH
    }
  }
}];