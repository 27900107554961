var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '大区',
  prop: 'orgName',
  width: '250'
}, {
  label: '姓名',
  prop: 'bonusUserName',
  width: '100'
}, {
  label: '推介费月份',
  prop: 'planDate',
  width: '120'
}, {
  label: '首年推介费',
  prop: 'sumBonus1',
  width: '150'
}, {
  label: '第二年推介费',
  prop: 'sumBonus2',
  width: '150'
}, {
  label: '第三年推介费',
  prop: 'sumBonus3',
  width: '150'
}, {
  label: '第四年推介费',
  prop: 'sumBonus4',
  width: '150'
}, {
  label: '第五年推介费',
  prop: 'sumBonus5',
  width: '150'
}, {
  label: '推介费合计',
  prop: 'sumBonus',
  width: '150'
}];