import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { addSummary } from "@api/crm/commission/commission";
export default {
  name: "commission-collect-summary-add",
  props: {
    propsModel: {
      type: Array,
      default: []
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      formModel: {},
      //表单
      rules: {}
    };
  },
  computed: {},
  created: function created() {},
  watch: {},
  methods: {
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.formModel = {};
    },
    clickSave: function clickSave() {
      var _this = this;

      var arrayBatchId = [];
      this.propsModel.forEach(function (m) {
        arrayBatchId.push(m.batchId);
      });
      var param = {
        summary: this.formModel.summary,
        arrayBatchId: arrayBatchId
      };
      addSummary(param).then(function (res) {
        _this.showDialog = false;

        _this.$emit("refresh");
      });
    }
  }
};