import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { listRoleUser, setRoleUser } from "@api/rm/role";
export default {
  name: "dialog-role-user-list",
  props: {
    // 当前角色
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    userList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      tableData: [],
      formModel: {},
      checkboxRows: [],
      rules: {}
    };
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    },
    checkboxRows: {
      //列表的复选框默认选中
      handler: function handler(val) {
        var _this = this;

        if (val != null && val.length > 0) {
          this.$nextTick(function () {
            val.forEach(function (n) {
              var row = _this.tableData.find(function (item) {
                return item.personuuid == n.personuuid;
              });

              _this.$refs.multipleTable.toggleRowSelection(row, true);
            });
          });
        }
      }
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [], this.formModel = {}, this.checkboxRows = [];
      this.loadData();
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
      this.showDialog = false;
    },
    //加载数据
    loadData: function loadData() {
      this.tableData = this.userList;
      this.checkboxRows = this.tableData; //默认全部选中
    },
    // TODO 确定
    clickSave: function clickSave() {
      var _this2 = this;

      if (this.tableData.length > this.checkboxRows.length) {
        //有取消勾选用户
        this.$confirm("确定批量重设该角色的用户吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this2.loading = true;
          var arrayUserId = [];

          _this2.checkboxRows.forEach(function (n) {
            arrayUserId.push(n.personuuid);
          });

          var param = {
            roleId: _this2.propsModel.id,
            arrayUserId: arrayUserId
          };
          setRoleUser(param).then(function (res) {
            _this2.loading = false;

            _this2.$message({
              message: "设置角色用户成功",
              type: "success"
            });

            _this2.dialogClose();
          });
        }).catch(function (e) {
          _this2.loading = false;
        });
      }
    },
    //复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      this.checkboxRows = rows;
    }
  }
};