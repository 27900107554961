import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
};
export var TOP_FORM = {
  recCode: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '流水号',
    props: {
      placeholder: '请输入'
    }
  }),
  contractCd: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '合同编号',
    props: {
      placeholder: '请输入'
    }
  }),
  productIds: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    value: [],
    type: 'select',
    label: '产品',
    options: [],
    props: {
      clearable: true,
      placeholder: '请输入',
      multiple: true,
      filterable: true,
      collapseTags: true
    }
  }),
  contractUserName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '合同理顾',
    props: {
      placeholder: '请输入'
    }
  }),
  contractOrgCds: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'cascader',
    label: '合同部门',
    options: [],
    props: {
      placeholder: '请输入',
      filterable: true,
      collapseTags: true,
      showAllLevels: false,
      props: {
        checkStrictly: true,
        emitPath: false,
        multiple: true,
        value: 'orgCd',
        label: 'orgName',
        children: 'children'
      }
    }
  }),
  contractOrgType: {
    labelWidth: 120,
    type: 'select',
    label: '合同部门类型',
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '直营',
      value: '01'
    }, {
      label: '机构',
      value: '02'
    }, {
      label: '其他',
      value: '03'
    }]
  },
  achievementState: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '状态',
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '生效',
      value: '1'
    }, {
      label: '作废',
      value: '0'
    }]
  }),
  achievementTimeLimit: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '业绩周期',
    props: {
      placeholder: '请输入'
    }
  }),
  achievementUserName: {
    labelWidth: 120,
    type: 'input',
    label: '业绩归属理顾',
    props: {
      placeholder: '请输入'
    }
  },
  achievementOrgCds: {
    labelWidth: 120,
    type: 'cascader',
    label: '业绩归属部门',
    options: [],
    props: {
      placeholder: '请输入',
      filterable: true,
      collapseTags: true,
      showAllLevels: false,
      props: {
        checkStrictly: true,
        emitPath: false,
        multiple: true,
        value: 'orgCd',
        label: 'orgName',
        children: 'children'
      }
    }
  },
  achievementOrgType: {
    labelWidth: 150,
    type: 'select',
    label: '业绩归属部门类型',
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '全部',
      value: null
    }, {
      label: '直营',
      value: '01'
    }, {
      label: '机构',
      value: '02'
    }]
  },
  achievementDateArr: {
    labelWidth: 110,
    type: 'datePicker',
    label: '业绩归属日期',
    propGroup: ['achievementStartDate', 'achievementEndDate'],
    props: {
      type: 'daterange'
    }
  },
  isPerformance: {
    labelWidth: 120,
    type: 'select',
    label: '是否统计业绩',
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '是',
      value: '1'
    }, {
      label: '否',
      value: '0'
    }]
  },
  contractS: {
    labelWidth: 110,
    type: 'datePicker',
    label: '合同开始日期',
    propGroup: ['contractSStartDate', 'contractSEndDate'],
    props: {
      type: 'daterange'
    }
  },
  contractE: {
    labelWidth: 110,
    type: 'datePicker',
    label: '合同结束日期',
    propGroup: ['contractEStartDate', 'contractEEndDate'],
    props: {
      type: 'daterange'
    }
  },
  receivable: {
    labelWidth: 120,
    type: 'datePicker',
    label: '合同应收款日期',
    propGroup: ['receivableStartDate', 'receivableEndDate'],
    props: {
      type: 'daterange'
    }
  },
  productType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '产品类型',
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '前端',
      value: '1'
    }, {
      label: '后端',
      value: '2'
    }]
  }),
  financeConfirm: {
    labelWidth: 110,
    type: 'datePicker',
    label: '财务确认日期',
    propGroup: ['financeConfirmStartDate', 'financeConfirmEndDate'],
    props: {
      type: 'daterange'
    }
  },
  clear: {
    labelWidth: 110,
    type: 'datePicker',
    label: '提前终止日期',
    propGroup: ['clearStartDate', 'clearEndDate'],
    props: {
      type: 'daterange'
    }
  }
};
export var TABLE_LABEL = [{
  label: '流水号',
  prop: 'recCode',
  width: 100
}, {
  label: '合同编号',
  prop: 'contractCd'
}, {
  label: '产品名称',
  prop: 'productName'
}, {
  label: '产品类型',
  prop: 'productType',
  formatter: function formatter(row, column, cellValue) {
    if (cellValue === '1') return '前端';else if (cellValue === '2') return '后端';else return cellValue;
  }
}, {
  label: '合同金额',
  prop: 'contractAmount'
}, {
  label: '合同理顾',
  prop: 'contractUserName'
}, {
  label: '合同部门',
  width: 110,
  prop: 'contractOrgName'
}, {
  label: '业绩类型',
  prop: 'achievementType',
  formatter: function formatter(row, column, cellValue) {
    if (cellValue === '1') return '责任状';else if (cellValue === '2') return '基本法';else return cellValue;
  }
}, {
  label: '合同周期',
  prop: 'timeLimit'
}, {
  label: '业绩周期',
  prop: 'achievementTimeLimit'
}, {
  label: '业绩系数',
  prop: 'factor'
}, {
  label: '业绩额',
  prop: 'amount'
}, {
  label: '业绩归属理顾',
  prop: 'achievementUserName'
}, {
  label: '业绩归属部门',
  prop: 'achievementOrgName'
}, {
  label: '业绩归属日期',
  prop: 'achievementDate',
  width: 110
}, {
  label: '合同开始日期',
  prop: 'startDate',
  width: 110
}, {
  label: '合同结束日期',
  prop: 'endDate',
  width: 110
}, {
  label: '合同应收款日期',
  prop: 'receivableDate',
  width: 110
}, {
  label: '财务确认收款日期',
  prop: 'financeConfirmDate',
  width: 110
}, {
  label: '提前终止日期',
  prop: 'clearDate',
  width: 110,
  type: 'html',
  formatter: function formatter(row, column, cellValue) {
    return "<span style='color: #F56C6C'>".concat(cellValue !== null && cellValue !== void 0 ? cellValue : '', "</span>");
  }
}, {
  label: '合同转让日期',
  prop: 'assignDate',
  width: 110
}, {
  label: '是否统计业绩',
  prop: 'isPerformance',
  type: 'html',
  formatter: function formatter(row, column, cellValue) {
    if (cellValue === '1') return "<span>\u662F</span>";else if (cellValue === '0') return "<span style='color: #F56C6C'>\u5426</span>";else return cellValue;
  }
}, {
  label: '状态',
  prop: 'achievementState',
  type: 'html',
  formatter: function formatter(row, column, cellValue) {
    if (cellValue === '1') return "<span>\u751F\u6548</span>";else if (cellValue === '0') return "<span style='color: #F56C6C'>\u4F5C\u5E9F</span>";else return cellValue;
  }
}, {
  type: 'operation',
  label: '操作',
  width: 110,
  fixed: 'right',
  formatter: function formatter() {
    return [{
      type: 'edit',
      label: '编辑',
      permission: 'ConJbfAchievement-exportList'
    }];
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [{
    label: '批量修改业绩归属部门',
    type: 'editBatch',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'ConZrzAchievement-editBatchOrgcd'
  }, {
    label: '批量修改业绩系数',
    type: 'editFactor',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'ConZrzAchievement-editBatchFactor'
  }, {
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'ConZrzAchievement-exportList'
  }] // defaultSort: {
  //   prop: 'updateTime',
  //   order: 'descending'
  // }

};
/** 编辑 表单配置 */

export var DIALOG_FORM_CONFIG = {
  recCode: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '流水号',
    col: 8,
    props: {
      disabled: true
    }
  }),
  contractCd: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '合同编号',
    col: 8,
    props: {
      disabled: true
    }
  }),
  productName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '产品名称',
    col: 8,
    props: {
      disabled: true
    }
  }),
  contractUserName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '合同理顾',
    col: 8,
    props: {
      disabled: true
    }
  }),
  contractOrgName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '合同部门',
    col: 8,
    props: {
      disabled: true
    }
  }),
  isPerformance: {
    labelWidth: 120,
    type: 'select',
    label: '是否统计业绩',
    col: 8,
    props: {
      disabled: true
    },
    options: [{
      label: '是',
      value: '1'
    }, {
      label: '否',
      value: '0'
    }]
  },
  achievementType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '业绩类型',
    col: 8,
    props: {
      disabled: true
    },
    options: [{
      label: '责任状',
      value: '1'
    }, {
      label: '基本法',
      value: '2'
    }]
  }),
  achievementTimeLimit: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '业绩周期',
    col: 8
  }),
  timeLimit: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '合同周期',
    col: 8
  }),
  contractAmount: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '合同金额',
    col: 8,
    props: {
      disabled: true
    }
  }),
  factor: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '业绩系数',
    col: 8
  }),
  amount: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '业绩额',
    col: 8,
    props: {
      disabled: true
    }
  }),
  startDate: {
    labelWidth: 120,
    type: 'datePicker',
    label: '合同开始日期',
    col: 8,
    props: {
      disabled: true
    }
  },
  endDate: {
    labelWidth: 120,
    type: 'datePicker',
    label: '合同结束日期',
    col: 8,
    props: {
      disabled: true
    }
  },
  receivableDate: {
    labelWidth: 120,
    type: 'datePicker',
    label: '合同应付日期',
    col: 8,
    props: {
      disabled: true
    }
  },
  financeConfirmDate: {
    labelWidth: 140,
    type: 'datePicker',
    label: '财务确认收款日期',
    col: 8,
    props: {
      disabled: true
    }
  },
  clearDate: {
    labelWidth: 120,
    type: 'datePicker',
    label: '提前终止日期',
    col: 8,
    props: {
      disabled: true
    }
  },
  achievementDate: {
    labelWidth: 120,
    type: 'datePicker',
    label: '业绩归属日期',
    col: 8,
    rules: [{
      required: true,
      message: '不能为空',
      trigger: 'blur'
    }]
  },
  achievementUserId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    label: '业绩理顾',
    col: 8,
    options: [],
    rules: [{
      required: true,
      message: '不能为空',
      trigger: 'blur'
    }]
  }),
  achievementOrgCd: {
    labelWidth: 120,
    label: '业绩归属部门',
    type: 'cascader',
    col: 8,
    options: [],
    props: {
      placeholder: '请选择',
      filterable: true,
      // showAllLevels: false,
      props: {
        checkStrictly: true,
        emitPath: false,
        value: 'orgCd',
        label: 'orgName',
        children: 'children'
      }
    },
    rules: [{
      required: true,
      message: '不能为空',
      trigger: 'blur'
    }]
  },
  achievementState: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '状态',
    col: 8,
    options: [{
      label: '生效',
      value: '1'
    }, {
      label: '作废',
      value: '0'
    }],
    rules: [{
      required: true,
      message: '不能为空',
      trigger: 'blur'
    }]
  })
};
export var DIALOG_FORM_DATA = {
  achievementId: null
};
/** 编辑 表单值 */

/** 批量修改业绩归属部门 表单配置 */

export var EDIT_BATCH_FORM_CONFIG = {
  achievementOrgCd: {
    labelWidth: 120,
    label: '业绩归属部门',
    type: 'cascader',
    options: [],
    props: {
      placeholder: '请选择',
      filterable: true,
      // showAllLevels: false,
      props: {
        checkStrictly: true,
        emitPath: false,
        value: 'orgCd',
        label: 'orgName',
        children: 'children'
      }
    },
    rules: [{
      required: true,
      message: '不能为空',
      trigger: 'blur'
    }]
  }
};
/** 批量修改业绩归属部门 表单值 */

export var EDIT_BATCH_FORM_DATA = {
  achievementOrgCd: null
};
/** 批量修改业绩系数 表单配置 */

export var EDIT_FACTOR_FORM_CONFIG = {
  factor: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '业绩系数',
    props: {
      placeholder: '请输入'
    },
    rules: [{
      required: true,
      message: '不能为空',
      trigger: 'blur'
    }]
  })
};
/** 批量修改业绩系数 表单值 */

export var EDIT_FACTOR_FORM_DATA = {
  factor: null
};