//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import DialogLog from "./dialog-log";
import { listContractTransfer } from "@api/crm/transfer/transfer";
export default {
  name: "transfer-history",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    DialogLog: DialogLog
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      collapseActive: ["1"],
      //信息折叠默认展开
      showLogDialog: false,
      tableData: [],
      currentRow: {} // 当前点击行

    };
  },
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.model = Object.assign({}, this.propsModel); //复制新对象          

          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    loadData: function loadData() {
      var _this = this;

      var param = {
        contractId: this.model.contractId
      };
      listContractTransfer(param).then(function (res) {
        _this.tableData = res.data;
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    clickLog: function clickLog(row) {
      this.currentRow = row;
      this.showLogDialog = true;
    }
  }
};