import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
};
export var TOP_FORM = {
  customerCode: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户流水号',
    props: {
      placeholder: '请输入客户流水号'
    }
  }),
  customerName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '姓名',
    props: {
      placeholder: '请输入姓名'
    }
  }),
  levelId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '客户等级',
    props: {
      placeholder: '请选择客户等级'
    },
    options: []
  }),
  releaseTime: {
    labelWidth: 110,
    type: 'datePicker',
    label: '注册时间',
    propGroup: ['beginRegisterTime', 'endRegisterTime'],
    props: {
      type: 'daterange'
    }
  },
  orgId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    label: '部门',
    type: 'cascader',
    options: [],
    props: {
      placeholder: '请选择部门',
      filterable: true,
      props: {
        checkStrictly: true,
        emitPath: false,
        value: 'orgCd',
        label: 'orgName',
        children: 'children'
      }
    }
  })
};
export var TABLE_LABEL = [{
  label: '客户流水号',
  prop: 'customerCode',
  sortable: 'ScoreList',
  sortProp: 'customerCode'
}, {
  label: '姓名',
  prop: 'customerName',
  sortable: 'ScoreList',
  sortProp: 'customerName'
}, {
  label: '会员等级',
  prop: 'customerLevelName',
  sortable: 'ScoreList',
  sortProp: 'customerLevelName'
}, {
  label: '总积分',
  prop: 'score',
  sortable: 'ScoreList',
  sortProp: 'score'
}, {
  label: '合同积分',
  prop: 'contractScore',
  sortable: 'ScoreList',
  sortProp: 'contractScore'
}, {
  label: '活动积分',
  prop: 'activityScore',
  sortable: 'ScoreList',
  sortProp: 'activityScore'
}, {
  label: '注册时间',
  prop: 'createTime',
  sortable: 'ScoreList',
  sortProp: 'createTime'
}, {
  label: '部门',
  prop: 'orgName',
  sortable: 'ScoreList',
  sortProp: 'orgName'
}, {
  type: 'operation',
  label: '操作',
  width: 200,
  formatter: function formatter() {
    return [{
      type: 'adjust',
      label: '调整积分',
      permission: 'integral-adjustScore'
    }, {
      type: 'detail',
      label: '积分明细',
      permission: 'integral-scoreDetail'
    }];
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [{
    label: '批量调整活动积分',
    type: 'batchAdjustment',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'integral-batchAdjustScore'
  }, {
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'integral-exportScore'
  }],
  defaultSort: {
    prop: 'updateTime',
    order: 'descending'
  }
};