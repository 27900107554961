var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        top: "25vh",
        width: "500px",
        title: "审核",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.collapseActive,
            callback: function($$v) {
              _vm.collapseActive = $$v
            },
            expression: "collapseActive"
          }
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { title: "合同信息", name: "1" } },
            [
              _c(
                "el-row",
                { staticClass: "block-col-1" },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [
                      _vm._v("合同编号:")
                    ]),
                    _vm._v(" " + _vm._s(_vm.propsModel.contractCd) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "block-col-2" },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [
                      _vm._v("产品名称:")
                    ]),
                    _vm._v(" " + _vm._s(_vm.propsModel.productName) + " ")
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [
                      _vm._v("合同金额:")
                    ]),
                    _c("span", { staticClass: "d2-emphasis" }, [
                      _vm._v(_vm._s(_vm.propsModel.amount))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "block-col-2" },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [_vm._v("姓名:")]),
                    _c("span", { staticClass: "d2-emphasis" }, [
                      _vm._v(_vm._s(_vm.propsModel.customerName))
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [_vm._v("机构:")]),
                    _vm._v(" " + _vm._s(_vm.propsModel.orgName) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "block-col-2" },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [
                      _vm._v("开始日期:")
                    ]),
                    _vm._v(" " + _vm._s(_vm.propsModel.startDate) + " ")
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [
                      _vm._v("结束日期:")
                    ]),
                    _vm._v(" " + _vm._s(_vm.propsModel.endDate) + " ")
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "block-col-2" },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [
                      _vm._v("期限(月):")
                    ]),
                    _vm._v(" " + _vm._s(_vm.propsModel.timeLimit) + " ")
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [
                      _vm._v("返还周期(月):")
                    ]),
                    _vm._v(" " + _vm._s(_vm.propsModel.returnPeriod) + " ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("审核")
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核", prop: "audit" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.formModel.audit,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "audit", $$v)
                            },
                            expression: "formModel.audit"
                          }
                        },
                        _vm._l(_vm.selectData.audit, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核备注", prop: "auditRemark" } },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.auditRemark,
                            expression: "formModel.auditRemark"
                          }
                        ],
                        staticClass: "el-textarea__inner",
                        attrs: { placeholder: "请填写审核意见" },
                        domProps: { value: _vm.formModel.auditRemark },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formModel,
                              "auditRemark",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "contract-log-tip",
              attrs: { plain: "", size: "mini", icon: "el-icon-finished" },
              on: {
                click: function($event) {
                  return _vm.showLog()
                }
              }
            },
            [_vm._v("审核日志")]
          ),
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: { click: _vm.confirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      ),
      _c("contract-log", {
        attrs: {
          "contract-id": _vm.propsModel.contractId,
          active: _vm.contractLogType
        },
        model: {
          value: _vm.showLogDialog,
          callback: function($$v) {
            _vm.showLogDialog = $$v
          },
          expression: "showLogDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }