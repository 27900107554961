import "core-js/modules/es.object.to-string.js";
// 礼品申请
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatDate, formatUserDataPromise } from '@/libs/crm/format';
import func from '@/maps/url/crm/gift/apply'; // TODO 申请礼品

export function create(params) {
  var re = request.post(func.create, {
    body: params
  });
  return re;
} // TODO 合同是否已申请礼品

export function isExist(params) {
  var re = request.post(func.isExist, {
    body: params
  });
  return re;
} // TODO 删除

export function remove(params) {
  var re = request.post(func.remove, {
    body: params
  });
  return re;
} // TODO 礼品申请列表

export function list(params, formatType) {
  var re = request.post(func.list, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 详情

export function getInfo(params, formatType) {
  var re = request.post(func.getInfo, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 提交审核

export function submit(params) {
  var re = request.post(func.submit, {
    body: params
  });
  return re;
} // TODO 删除礼品

export function removeGift(params) {
  var re = request.post(func.removeGift, {
    body: params
  });
  return re;
} // TODO 客服审核

export function auditService(params) {
  var re = request.post(func.auditService, {
    body: params
  });
  return re;
} // TODO 财务审核

export function auditFinance(params) {
  var re = request.post(func.auditFinance, {
    body: params
  });
  return re;
} // TODO 批量客服审核

export function auditServiceBatch(params) {
  var re = request.post(func.auditServiceBatch, {
    body: params
  });
  return re;
} // TODO 批量财务审核

export function auditFinanceBatch(params) {
  var re = request.post(func.auditFinanceBatch, {
    body: params
  });
  return re;
} // TODO 寄出礼品

export function delivery(params) {
  var re = request.post(func.delivery, {
    body: params
  });
  return re;
} // TODO 收货礼品

export function receive(params) {
  var re = request.post(func.receive, {
    body: params
  });
  return re;
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '礼品申请' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}