










































































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/info/category/index.vue"
}
