import "core-js/modules/es.object.to-string.js";
// 到期与清算
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import { formatDate } from '@/libs/crm/format';
import util from '@/libs/util';
import func from '@/maps/url/crm/gift/gift'; // TODO 保存

export function save(params) {
  var re = request.post(func.save, {
    body: params
  });
  return re;
} // TODO 删除

export function remove(params) {
  var re = request.post(func.remove, {
    body: params
  });
  return re;
} // TODO 设置启用

export function setStatus(params) {
  var re = request.post(func.setStatus, {
    body: params
  });
  return re;
} // TODO 列表

export function list(params) {
  var re = request.post(func.list, {
    body: params
  });
  return re;
} // TODO 列表

export function getInfo(params) {
  var re = request.post(func.getInfo, {
    body: params
  });
  return re;
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '礼品' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}