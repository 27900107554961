import { listCacheOrg } from "@api/common/cache";
var VAL_WIDTH = '130px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '支付日期',
  valueKey: 'paymentDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'paymentDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '导入日期',
  valueKey: 'createTimeBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'createTimeEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '机构名称',
  valueKey: 'arrayOrgCd',
  value: [],
  type: 'cascader',
  method: listCacheOrg,
  params: {},
  options: [],
  props: {
    checkStrictly: true,
    emitPath: false,
    showAllLevels: false,
    multiple: true,
    value: 'orgCd',
    label: 'orgName',
    children: 'children'
  }
}];