var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          filtersFormatter: _vm.filtersFormatter,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig,
          tableDataFormatter: _vm.tableDataFormatter
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-row-change": _vm.tableRowChange,
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        },
        scopedSlots: _vm._u([
          {
            key: "orgCds",
            fn: function() {
              return [
                _c(
                  "el-select",
                  {
                    staticClass: "dept-select",
                    attrs: {
                      placeholder: "请选择组织",
                      multiple: "",
                      "collapse-tags": "",
                      size: "small"
                    },
                    on: { change: _vm.selectChange },
                    model: {
                      value: _vm.mineStatus,
                      callback: function($$v) {
                        _vm.mineStatus = $$v
                      },
                      expression: "mineStatus"
                    }
                  },
                  [
                    _c(
                      "el-option",
                      {
                        staticStyle: { height: "auto" },
                        attrs: { value: _vm.mineStatusValue }
                      },
                      [
                        _c("el-tree", {
                          ref: "tree",
                          attrs: {
                            data: _vm.allDept,
                            "show-checkbox": "",
                            "check-strictly": "",
                            "node-key": "orgCd",
                            "highlight-current": "",
                            props: _vm.defaultProps
                          },
                          on: { check: _vm.handleCheckChange }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }