










































































































































































export default function (Component) {
  Component.options.__source = "src/components/common/j-select-tree/index.vue"
}
