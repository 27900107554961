import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import AUDIT from "@/maps/enum/status/audit";
import { formatAmountToNumber, formatAmountToString } from "@/libs/crm/format";
import { auditFinance } from "@api/crm/clear/clear";
export default {
  name: "contract-clear-batch-finance-audit",
  props: {
    propsModel: {
      type: Array,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      showLogDialog: false,
      tableData: [],
      selectData: {},
      formModel: {},
      //表单
      rules: {}
    };
  },
  created: function created() {},
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    loadData: function loadData() {
      this.tableData = this.propsModel;
    },
    clickAuditFail: function clickAuditFail() {
      //审核不通过
      if (this.formModel.advice == null || this.formModel.advice == "") {
        this.$message("请填写审核意见");
      } else {
        this.audit(AUDIT.NO.value);
      }
    },
    clickAudit: function clickAudit() {
      this.audit(AUDIT.YES.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      var arrayContractId = [];
      this.tableData.forEach(function (n) {
        arrayContractId.push(n.contractId);
      });
      var param = {
        arrayContractId: arrayContractId,
        audit: result,
        advice: this.formModel.advice
      };
      this.loading = true;
      auditFinance(param).then(function (res) {
        _this.loading = false;
        _this.showDialog = false;

        _this.$emit("refresh");
      }).catch(function (e) {
        _this.loading = false;
      });
    },
    //列表删除
    clickRemove: function clickRemove(row) {
      for (var i = 0; i < this.propsModel.length; i++) {
        if (this.propsModel[i].contractId == row.contractId) {
          this.propsModel.splice(i, 1);
          break;
        }
      }
    },
    //统计列表
    //统计列表
    sumTable: function sumTable(param) {
      var array = [];
      var sumAmount = 0;
      var sumExpectEarnings = 0;

      for (var i = 0; i < param.data.length; i++) {
        var n = param.data[i];
        sumAmount += formatAmountToNumber(n.amount);
        sumExpectEarnings += formatAmountToNumber(n.expectEarnings);
      }

      array[0] = "合计";
      array[7] = formatAmountToString(sumAmount);
      array[8] = formatAmountToString(sumExpectEarnings);
      return array;
    }
  }
};