// 表头
export default [{
  label: '帐号',
  prop: 'userAccount',
  width: '150px'
}, {
  label: '姓名',
  prop: 'userName',
  width: '150px'
}, {
  label: '岗位',
  prop: 'userTitleText',
  width: '150px'
}, {
  label: '操作类型',
  prop: 'operationTypeText',
  width: '100px'
}, {
  label: '业务',
  prop: 'businessText',
  width: '100px'
}, {
  label: '操作时间',
  prop: 'operationTime',
  width: '250px'
}, {
  label: '操作描述',
  prop: 'operationDesc',
  width: '300px'
}];