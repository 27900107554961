





















































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/gift/applySelf/new/components/dialog-edit.vue"
}
