// 默认表格配置
export var TABLE = {
  auto: true,
  // 初始化是否触发加载方法
  multipart: false,
  // 是否多选，即是否展示复选框
  headerCellStyle: function headerCellStyle() {
    // 表头样式
    return {
      // backgroundColor: '#F5F5F5',
      color: '#333333'
    };
  },
  highlightCurrentRow: false // 选中行是否高亮

}; // 多选项的配置

export var MULTIPLE = {
  width: 40,
  reserveSelection: false // 是否保存之前的选中状态

}; // 默认分页配置

export var PAGINATION = {
  use: true,
  layout: 'total, sizes, prev, pager, next, jumper',
  pageSizes: [20, 30, 40, 60],
  total: 0,
  page: {
    pageNumber: 1,
    pageSize: 20
  }
};