














































































































































export default function (Component) {
  Component.options.__source = "src/views/rm/system/system/role/components/dialog-role-menu.vue"
}
