import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 150,
  col: 10
};
export var FORM_CONFIG = [{
  title: '基本信息',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'radio',
    label: '礼品类型',
    prop: 'type',
    props: {
      disabled: false
    },
    options: [{
      label: '常规礼品',
      value: 1
    }, {
      label: '秒杀礼品',
      value: 2
    }],
    rules: [{
      required: true,
      message: '请选择礼品类型',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'radio',
    label: '启用状态',
    prop: 'status',
    options: [{
      label: '启用',
      value: 1
    }, {
      label: '禁用',
      value: 0
    }],
    rules: [{
      required: true,
      message: '请选择状态',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '礼品封面',
    prop: 'mainPicture',
    rules: [{
      required: true,
      message: '礼品封面不能为空',
      trigger: 'blur'
    }],
    props: {
      placeholder: '最多可传1张，图片建议大小不超过1MB，支GIF,JPEG,JPG,PNG,BMP格式'
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '图片（详情的轮播图）',
    prop: 'bannerPicture',
    rules: [{
      required: true,
      message: '详情的轮播图不能为空',
      trigger: 'blur'
    }],
    props: {
      type: 3,
      limit: 3,
      placeholder: '最多可传3张，图片建议大小不超过1MB，支GIF,JPEG,JPG,PNG,BMP格式'
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '礼品名称',
    prop: 'giftName',
    props: {
      placeholder: '请输入',
      disabled: false,
      showWordLimit: true
    },
    rules: [{
      required: true,
      message: '礼品名称不能为空',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '礼品分类',
    prop: 'classificationId',
    props: {
      placeholder: '请输入',
      disabled: false
    },
    options: [],
    rules: [{
      required: true,
      message: '礼品分类不能为空',
      trigger: 'change'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '分值',
    prop: 'score',
    props: {
      placeholder: '请输入',
      disabled: false
    },
    rules: [{
      required: true,
      message: '分值不能为空',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '单价',
    prop: 'price',
    props: {
      placeholder: '请输入',
      disabled: false
    } // rules: [{ required: true, message: '请输入单价', trigger: 'blur' }],

  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '剩余库存',
    prop: 'stockAmount',
    props: {
      placeholder: '请输入',
      disabled: false
    },
    rules: [{
      required: true,
      message: '库存不能为空',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '每人限兑数量',
    prop: 'limitedExchangeAmount',
    props: {
      placeholder: '请输入',
      disabled: false
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '虚拟兑换数量',
    prop: 'fictitiousExchangeAmount',
    props: {
      placeholder: '请输入',
      disabled: false
    },
    rules: [{
      required: true,
      message: '兑换数量不能为空',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '上架时间',
    prop: 'shelvesTime',
    props: {
      type: 'datetime',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '下架时间',
    prop: 'offTime',
    props: {
      type: 'datetime',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    label: '部门',
    type: 'cascader',
    options: [],
    prop: 'orgCdList',
    rules: [{
      required: true,
      message: '部门不能为空',
      trigger: 'blur'
    }],
    props: {
      placeholder: '请选择部门',
      filterable: true,
      props: {
        checkStrictly: true,
        multiple: true,
        emitPath: false,
        value: 'orgCd',
        label: 'orgName',
        children: 'children'
      }
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '序号',
    prop: 'sort',
    props: {
      placeholder: '请输入',
      disabled: false,
      showWordLimit: true
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    prop: 'remark',
    type: 'input',
    label: '备注',
    props: {
      type: 'textarea',
      autosize: {
        minRows: 4,
        maxRows: 4
      },
      maxlength: 60,
      showWordLimit: true,
      placeholder: '请输入',
      disabled: false
    }
  })]
}, {
  title: '介绍',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'editor',
    label: '',
    prop: 'content',
    col: 24,
    hidden: false,
    props: {
      disabled: false
    }
  })]
}];