import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
// 查询条件
var PUBLIC_CONFIG = {
  labelWidth: 80
};
var PUBLIC_CONFIGS = {
  labelWidth: 100
}; // 表格列宽

var PUBLIC_TABLE_LABEL_CONFIG = {
  width: 150
};
export var TOP_FORM = {
  giftName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '礼品',
    props: {
      placeholder: '请输入礼品名称'
    }
  }),
  customerName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户',
    props: {
      placeholder: '请输入客户名称'
    }
  }),
  exchangeStatus: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '礼品状态',
    col: 6,
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '已取消',
      value: '0'
    }, {
      label: '审批中',
      value: '1'
    }, {
      label: '待发货',
      value: '2'
    }, {
      label: '待确认收货',
      value: '3'
    }, {
      label: '待自提',
      value: '4'
    }, {
      label: '已确认收货',
      value: '5'
    }]
  }),
  createTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '申请时间',
    col: 6,
    propGroup: ['beginExchangeTime', 'endExchangeTime'],
    props: {
      type: 'daterange'
    }
  }),
  orgCd: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    label: '部门',
    type: 'cascader',
    options: [],
    props: {
      placeholder: '请选择部门',
      filterable: true,
      props: {
        checkStrictly: true,
        emitPath: false,
        value: 'orgCd',
        label: 'orgName',
        children: 'children'
      }
    }
  }),
  pickingMethod: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '取件方式',
    col: 6,
    props: {
      placeholder: '请选择方式'
    },
    options: [{
      label: '自取',
      value: 1
    }, {
      label: '直邮',
      value: 2
    }]
  })
};
export var TABLE_LABEL = [_objectSpread({
  label: '发货编码',
  prop: 'deliveryCode',
  sortable: 'shipments',
  sortProp: 'deliveryCode'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '客户编码',
  prop: 'customerCode',
  type: 'link',
  sortable: 'shipments',
  sortProp: 'customerCode'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '客户',
  prop: 'customerName',
  sortable: 'shipments',
  sortProp: 'customerName'
}), {
  width: 200,
  label: '兑换单号',
  prop: 'exchangeCode',
  sortable: 'shipments',
  sortProp: 'exchangeCode'
}, {
  label: '部门',
  prop: 'customerOrgName',
  width: 250,
  sortable: 'shipments',
  sortProp: 'customerOrgName'
}, {
  label: '礼品',
  prop: 'giftName',
  sortable: 'shipments',
  sortProp: 'giftName',
  width: 200
}, _objectSpread({
  label: '礼品编码',
  prop: 'giftCode',
  sortable: 'shipments',
  sortProp: 'giftCode'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '单价/元',
  prop: 'giftPrice',
  sortable: 'shipments',
  sortProp: 'giftPrice'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '分值',
  prop: 'giftScore',
  sortable: 'shipments',
  sortProp: 'giftScore'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '数量',
  prop: 'exchangeAmount',
  sortable: 'shipments',
  sortProp: 'exchangeAmount'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '总积分',
  prop: 'exchangeTotalScore',
  sortable: 'shipments',
  sortProp: 'exchangeTotalScore'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread(_objectSpread({
  label: '取件方式',
  prop: 'pickingMethod'
}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 1:
        res = '自取';
        break;

      case 2:
        res = '直邮';
        break;

      default:
        res = '';
    }

    return res;
  }
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '礼品状态',
  prop: 'exchangeStatusValue',
  sortable: 'shipments',
  sortProp: 'exchangeStatusValue'
}), _objectSpread({
  prop: 'exchangeTime',
  label: '申请时间',
  sortable: 'shipments',
  sortProp: 'exchangeTime'
}, PUBLIC_TABLE_LABEL_CONFIG), {
  label: '单号',
  prop: 'logisticsNumber',
  sortable: 'shipments',
  sortProp: 'logisticsNumber',
  width: 150
}, _objectSpread({
  label: '收件人',
  prop: 'receiver'
}, PUBLIC_TABLE_LABEL_CONFIG), _objectSpread({
  label: '收件人电话',
  prop: 'receiverMobile'
}, PUBLIC_TABLE_LABEL_CONFIG), {
  label: '收件人地址',
  prop: 'receiverAddress',
  width: 300
}, _objectSpread({
  prop: 'deliveryDate',
  label: '发货时间',
  sortable: 'shipments',
  sortProp: 'deliveryDate'
}, PUBLIC_TABLE_LABEL_CONFIG), {
  width: 200,
  label: '备注',
  prop: 'remark'
}, {
  width: 150,
  type: 'operation',
  label: '操作',
  fixed: 'right',
  formatter: function formatter(row) {
    var arr = []; // 待确认收货 自取

    if (row.exchangeStatus === '3' && row.pickingMethod === 1) {
      arr.push({
        type: 'logisticsTracking',
        label: '物流追踪',
        permission: 'giftDelivery-logisticsTracking'
      }, {
        type: 'ConfirmGoods',
        label: '确认收货',
        permission: 'giftDelivery-confirmReceipt'
      }); // 待发货 自取
    } else if (row.exchangeStatus === '2') {
      arr.push({
        type: 'delivery',
        label: '发货',
        permission: 'giftDelivery-ship'
      }); // 待自提 自取 或 直邮和待确认收货
    } else if (row.exchangeStatus === '4' && row.pickingMethod === 1 || row.exchangeStatus === '3' && row.pickingMethod === 2 || row.exchangeStatus === '5') {
      arr.push({
        type: 'logisticsTracking',
        label: '物流追踪',
        permission: 'giftDelivery-logisticsTracking'
      });
    }

    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [{
    label: '导出',
    type: 'exportData',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'giftDelivery-export'
  }, {
    label: '导入批量发货',
    type: 'importShip',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'giftDelivery-importShip'
  }]
};
export var FORM_CONFIG = [_objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '收件人',
  prop: 'receiver',
  rules: [{
    required: true,
    message: '请输入姓名',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '收件人电话',
  prop: 'receiverMobile',
  rules: [{
    required: true,
    message: '请输入电话',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '收件人地址',
  prop: 'receiverAddress',
  rules: [{
    required: true,
    message: '请输入地址',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '快递公司',
  prop: 'logisticsCompany',
  rules: [{
    required: true,
    message: '请输入快递公司',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '快递单号',
  prop: 'logisticsNumber',
  rules: [{
    required: true,
    message: '请输入单号',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '备注',
  prop: 'remark',
  props: {
    type: 'textarea',
    autosize: {
      minRows: 4,
      maxRows: 4
    },
    maxlength: 60,
    showWordLimit: true,
    placeholder: '请输入'
  }
})];