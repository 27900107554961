// 权限
import request from '@/plugin/axios';
import func from '@/maps/url/rm/right'; // TODO 用户菜单列表

export function listMenu(params) {
  return request.post(func.listMenu, {
    body: params
  });
} // TODO 保存用户菜单

export function saveMenu(params) {
  return request.post(func.saveMenu, {
    body: params
  });
} // TODO 用户机构列表

export function listOrg(params) {
  return request.post(func.listOrg, {
    body: params
  });
} // TODO 保存用户机构

export function saveOrg(params) {
  return request.post(func.saveOrg, {
    body: params
  });
} // TODO 用户产品列表

export function listProduct(params) {
  return request.post(func.listProduct, {
    body: params
  });
} // TODO 保存用户产品

export function saveProduct(params) {
  return request.post(func.saveProduct, {
    body: params
  });
} // TODO 保存多个用户同一产品

export function saveProductToManyUser(params) {
  return request.post(func.saveProductToManyUser, {
    body: params
  });
}