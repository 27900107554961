// 表头
export default [{
  label: '名称',
  prop: 'orgName'
}, {
  label: '编号',
  prop: 'orgCd',
  width: '100px'
}, {
  label: '简称',
  prop: 'orgNameAbbr',
  width: '200px'
}, {
  label: '顺序',
  prop: 'orgIndex',
  width: '100px'
}, {
  label: '产品是否适用本机构',
  prop: 'enabled',
  width: '150px',
  type: 'slot'
}, {
  label: '是否默认应用到新下级',
  prop: 'orgCdInc',
  width: '170px',
  type: 'slot'
}, {
  label: '到期前3天提醒',
  prop: 'contract_expires',
  width: '120px',
  type: 'slot'
}, {
  label: '认购成交提醒',
  prop: 'deal_succes_to_customer',
  width: '110px',
  type: 'slot'
}, {
  label: '续约成交提醒',
  prop: 'renew_success',
  width: '110px',
  type: 'slot'
}];