var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        top: "5vh",
        width: "600px",
        title: "审核",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("违规合同")])]
                ),
                _c("div", { staticClass: "text item" }, [
                  _c("div", [
                    _vm._v("合同编号: " + _vm._s(_vm.model.contractCd))
                  ]),
                  _c("div", [
                    _vm._v("姓名: " + _vm._s(_vm.model.customerName))
                  ]),
                  _c("div", [
                    _vm._v("证件类型: " + _vm._s(_vm.model.idTypeText))
                  ]),
                  _c("div", [
                    _vm._v("证件号码: " + _vm._s(_vm.model.identification))
                  ]),
                  _c("div", [_vm._v("手机号: " + _vm._s(_vm.model.mobile))]),
                  _c("div", [_vm._v("产品: " + _vm._s(_vm.model.productName))]),
                  _c("div", [_vm._v("合同金额: " + _vm._s(_vm.model.amount))]),
                  _c("div", [
                    _vm._v("年化率: " + _vm._s(_vm.model.annualRate))
                  ]),
                  _c("div", [
                    _vm._v("预期收益: " + _vm._s(_vm.model.expectEarnings))
                  ]),
                  _c("div", [_vm._v("期限: " + _vm._s(_vm.model.timeLimit))]),
                  _c("div", [
                    _vm._v("开始日期: " + _vm._s(_vm.model.startDate))
                  ]),
                  _c("div", [_vm._v("结束日期: " + _vm._s(_vm.model.endDate))]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        "font-size": "13px",
                        color: "red"
                      }
                    },
                    [_vm._v("机构: " + _vm._s(_vm.model.orgName))]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        "font-size": "13px",
                        color: "red"
                      }
                    },
                    [_vm._v("顾问: " + _vm._s(_vm.model.personName))]
                  ),
                  _c("div", [
                    _vm._v("合同状态: " + _vm._s(_vm.model.contractStatusText))
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("最后认购合同")])]
                ),
                _c("div", { staticClass: "text item" }, [
                  _c("div", [
                    _vm._v("合同编号: " + _vm._s(_vm.model.lastContractCd))
                  ]),
                  _c("div", [
                    _vm._v("姓名: " + _vm._s(_vm.model.lastCustomerName))
                  ]),
                  _c("div", [
                    _vm._v("证件类型: " + _vm._s(_vm.model.lastIdTypeText))
                  ]),
                  _c("div", [
                    _vm._v("证件号码: " + _vm._s(_vm.model.lastIdentification))
                  ]),
                  _c("div", [
                    _vm._v("手机号: " + _vm._s(_vm.model.lastMobile))
                  ]),
                  _c("div", [
                    _vm._v("产品: " + _vm._s(_vm.model.lastProductName))
                  ]),
                  _c("div", [
                    _vm._v("合同金额: " + _vm._s(_vm.model.lastAmount))
                  ]),
                  _c("div", [
                    _vm._v("年化率: " + _vm._s(_vm.model.lastAnnualRate))
                  ]),
                  _c("div", [
                    _vm._v("预期收益: " + _vm._s(_vm.model.lastExpectEarnings))
                  ]),
                  _c("div", [
                    _vm._v("期限: " + _vm._s(_vm.model.lastTimeLimit))
                  ]),
                  _c("div", [
                    _vm._v("开始日期: " + _vm._s(_vm.model.lastStartDate))
                  ]),
                  _c("div", [
                    _vm._v("结束日期: " + _vm._s(_vm.model.lastEndDate))
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        "font-size": "13px"
                      }
                    },
                    [_vm._v("机构: " + _vm._s(_vm.model.lastOrgName))]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        "font-size": "13px"
                      }
                    },
                    [_vm._v("顾问: " + _vm._s(_vm.model.lastPersonName))]
                  ),
                  _c("div", [
                    _vm._v(
                      "合同状态: " + _vm._s(_vm.model.lastContractStatusText)
                    )
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v("审核")
      ]),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v("审核状态: " + _vm._s(_vm.model.auditStatusText))
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v("审核备注: " + _vm._s(_vm.model.auditRemark))
          ])
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v("审核人: " + _vm._s(_vm.model.auditPersonName))
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _vm._v("审核时间: " + _vm._s(_vm.model.auditTime))
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }