
































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/bank/bill/tl2015/components/dialog-add.vue"
}
