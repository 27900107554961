var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '渠道',
  prop: 'typeText',
  width: '80px'
}, {
  label: '单位',
  prop: 'orgName',
  width: '250px'
}, {
  label: '到期合计（万元）',
  prop: 'countAmount',
  width: '110px'
}, {
  label: '已到期（万元）',
  prop: 'countFinishAmount',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '续约合同金额（万元）',
  prop: 'renewAmount',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '续约合同占比（%）',
  prop: 'renewPercent',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '入金合计（万元）',
  prop: 'inAmount',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '入金增长率（%）',
  prop: 'inPercent',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '入金差（万元）',
  prop: 'inAdd',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '老客户入金（万元）',
  prop: 'inOldCustomer',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '老客户入金占比（%）',
  prop: 'inOldPercent',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '新客户入金（万元）',
  prop: 'inNewCustomer',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '新客户入金占比（%）',
  prop: 'inNewPercent',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '赎回金额（万元）',
  prop: 'outAmount',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '付息出金（万元）',
  prop: 'outInterest',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '付息占比（%）',
  prop: 'outInterestPercent',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '出金合计（万元）',
  prop: 'outApply',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '出金占比（%）',
  prop: 'outApplyPercent',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '未续约客户数',
  prop: 'renewNoCount',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '未续约客户金额（万元）',
  prop: 'renewNoSum',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '部分赎回金额（万元）',
  prop: 'renewPartNo',
  width: VAL_WIDTH,
  align: 'right'
}, {
  label: '全部赎回金额（万元）',
  prop: 'renewAllNo',
  width: VAL_WIDTH,
  align: 'right'
}];