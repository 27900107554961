import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import ENUM from "@/maps/enum/enum";
import { getEnumList } from "@api/common/common";
import { save } from "@api/crm/gift/gift";
export default {
  name: "gift-dialog-add",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      selectData: {
        kind: []
      },
      rules: {
        giftName: [{
          required: true,
          message: "请填写礼品名称",
          trigger: "input"
        }],
        kind: [{
          required: true,
          message: "请选择类型",
          trigger: "input"
        }],
        price: [{
          required: true,
          message: "请设置价格",
          trigger: "input"
        }],
        status: [{
          required: true,
          message: "请设置是否启用",
          trigger: "input"
        }]
      }
    };
  },
  computed: {
    // 弹窗展示的标题
    title: function title() {
      return this.isEdit ? "修改" : "新增";
    },
    // 是否为编辑
    isEdit: function isEdit() {
      var giftId = this.propsModel.giftId;
      return !!giftId;
    }
  },
  created: function created() {
    var _this = this;

    //礼品类型
    getEnumList({
      enumCd: ENUM.ENUM_CD_0246
    }).then(function (res) {
      _this.selectData.kind = res;
    });
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();

          if (this.isEdit) {
            this.formModel = Object.assign({}, this.propsModel); //复制新对象          
          } else {
            this.formModel = {};
            this.$set(this.formModel, "status", "1");
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 确定
    confirm: function confirm() {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = _objectSpread({}, _this2.formModel);

          _this2.loading = true;
          save({
            object: param
          }).then(function (res) {
            _this2.loading = false;
            _this2.showDialog = false;

            _this2.$emit("refresh");
          }).catch(function (e) {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};