//续约
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 申请
{
  path: "".concat(baseRoute, "/renew/new/oneToOne"),
  name: 'renew-new-oneToOne',
  meta: {
    title: '续约>一对一',
    auth: true,
    cache: true
  },
  component: _import('crm/renew/new/oneToOne')
}, {
  path: "".concat(baseRoute, "/renew/new/oneToMany"),
  name: 'renew-new-oneToMany',
  meta: {
    title: '续约>一对多',
    auth: true,
    cache: true
  },
  component: _import('crm/renew/new/oneToMany')
}, // 客服审核
{
  path: "".concat(baseRoute, "/renew/service"),
  name: 'renew-service',
  meta: {
    title: '续约>客服审核',
    auth: true,
    cache: true
  },
  component: _import('crm/renew/service')
}, // 结算审核

/*结算和财务合并
{
    path: `${baseRoute}/renew/count`,
    name: 'renew-count',
    meta: {
        title: '续约>结算审核',
        auth: true,
        cache: true,
    },
    component: _import('crm/renew/count'),
},
*/
// 财务审核
{
  path: "".concat(baseRoute, "/renew/finance"),
  name: 'renew-finance',
  meta: {
    title: '续约>结算审核',
    auth: true,
    cache: true
  },
  component: _import('crm/renew/finance')
}, // 资金审核
{
  path: "".concat(baseRoute, "/renew/money"),
  name: 'renew-money',
  meta: {
    title: '续约>资金审核',
    auth: true,
    cache: true
  },
  component: _import('crm/renew/money')
}, // 核算查询
{
  path: "".concat(baseRoute, "/renew/search"),
  name: 'renew-search',
  meta: {
    title: '续约>查询',
    auth: true,
    cache: true
  },
  component: _import('crm/renew/search')
}];