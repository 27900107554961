import "core-js/modules/es.error.cause.js";
import { Message } from 'element-ui';
import store from '@/store';
import util from '@/libs/util';
/**
 * @description 安全地解析 json 字符串
 * @param {String} jsonString 需要解析的 json 字符串
 * @param {String} defaultValue 默认值
 */

export function parse() {
  var jsonString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '{}';
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var result = defaultValue;

  try {
    result = JSON.parse(jsonString);
  } catch (error) {
    /* eslint-disable no-console */
    console.log(error);
  }

  return result;
}
/**
 * @description 接口请求返回
 * @param {Any} data 返回值
 * @param {String} msg 状态信息
 * @param {Number} code 状态码
 */

export function response() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var code = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '0';
  var success = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  return [200, {
    code: code,
    success: success,
    message: message,
    data: data
  }];
}
/**
 * @description 接口请求返回 正确返回
 * @param {Any} data 返回值
 * @param {String} msg 状态信息
 */

export function responseSuccess() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var msg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '成功';
  return response(data, msg);
}
/**
 * @description 接口请求返回 错误返回
 * @param {Any} data 返回值
 * @param {String} msg 状态信息
 * @param {Number} code 状态码
 */

export function responseError() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var msg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '请求失败';
  var code = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '500';
  return response(data, msg, code);
}
/**
 * @description 记录和显示错误
 * @param {Error} error 错误对象
 */

export function errorLog(error) {
  // 添加到日志
  store.dispatch('d2admin/log/push', {
    message: '数据请求异常',
    type: 'danger',
    meta: {
      error: error
    }
  }); // 打印到控制台

  if (process.env.NODE_ENV === 'development') {
    util.log.danger('>>>>>> Error >>>>>>');
    /* eslint-disable no-console */

    console.log(error);
  } // 显示提示


  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
}
/**
 * @description 创建一个错误
 * @param {String} msg 错误信息
 */

export function errorCreate(msg) {
  var error = new Error(msg);
  errorLog(error);
  throw error;
}