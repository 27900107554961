var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "permission" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            { name: "dialogDrag", rawName: "v-dialogDrag" },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: {
            top: "10vh",
            width: "500px",
            title: "【" + _vm.propsModel.name + "】数据权限配置",
            visible: _vm.showDialog,
            closeOnClickModal: false
          },
          on: {
            "update:visible": function($event) {
              _vm.showDialog = $event
            },
            close: _vm.dialogClose
          }
        },
        [
          _vm.enumList && _vm.enumList.length
            ? _c(
                "div",
                { staticClass: "dialog-content" },
                _vm._l(_vm.enumList, function(item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.selectPermission,
                            callback: function($$v) {
                              _vm.selectPermission = $$v
                            },
                            expression: "selectPermission"
                          }
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              staticClass: "permission-type",
                              attrs: { label: item.value }
                            },
                            [_vm._v(_vm._s(item.text))]
                          )
                        ],
                        1
                      ),
                      _vm.selectPermission === "4" && item.value === "4"
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.chooseDept()
                                    }
                                  }
                                },
                                [_vm._v(" 选择部门")]
                              ),
                              _vm.selectedDept && _vm.selectedDept.length
                                ? _c(
                                    "div",
                                    _vm._l(_vm.selectedDept, function(
                                      tag,
                                      index
                                    ) {
                                      return _c(
                                        "el-tag",
                                        {
                                          key: index,
                                          attrs: { closable: "" },
                                          on: {
                                            close: function($event) {
                                              return _vm.handleClose(index)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(tag.orgName) + " "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "mini" },
                  on: { click: _vm.dialogClose }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    size: "mini",
                    loading: _vm.loading
                  },
                  on: { click: _vm.confirm }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c("dialog-department", {
        attrs: {
          deptList: _vm.deptList,
          defaultCheckedKeys: _vm.defaultCheckedKeys
        },
        on: { callback: _vm.callback },
        model: {
          value: _vm.showDeptDialog,
          callback: function($$v) {
            _vm.showDeptDialog = $$v
          },
          expression: "showDeptDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }