














































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/report/org/analyze/components/dialog-add.vue"
}
