






































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/paper/service/components/dialog-info.vue"
}
