import "core-js/modules/es.array.map.js";
export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    /* 可选系统菜单api列表 */
    SYS_API_OPTION_LIST: function SYS_API_OPTION_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: url.sysApiOptionList,
        data: data
      }).then(function (res) {
        res.map(function (item) {
          item.label = item.apiName;
          item.value = item.id;
          return item;
        });
        return res;
      });
    },

    /* 新增/编辑菜单api */
    SYS_API_ADD_OR_UPDATE: function SYS_API_ADD_OR_UPDATE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: url.sysApiAddOrUpdate,
        data: data
      }).then(function () {});
    },

    /*  菜单api详情 */
    SYS_API_DETAIL: function SYS_API_DETAIL(id) {
      return request({
        url: url.sysApiDetail,
        data: {
          id: id
        }
      }).then(function (res) {
        return res;
      });
    },

    /* api 删除 */
    SYS_API_DELETE: function SYS_API_DELETE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: url.sysApiDelete,
        data: data
      }).then(function (res) {
        return res;
      });
    }
  };
});