var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c(
        "el-row",
        { staticClass: "page-table-container", attrs: { type: "flex" } },
        [
          _c(
            "el-col",
            {
              staticClass: "list-container",
              staticStyle: { width: "500px" },
              attrs: { span: 24 }
            },
            [
              _c("dynamic-form", {
                ref: "form",
                attrs: { props: _vm.filterProps, forms: _vm.filterForms }
              }),
              _c("dynamic-table", {
                ref: "jTable",
                staticStyle: { width: "500px" },
                attrs: {
                  headers: _vm.headers,
                  table: _vm.table,
                  pagination: _vm.pagination,
                  data: _vm.tableData,
                  loading: _vm.loading
                },
                on: { load: _vm.loadData, "current-change": _vm.selectRow }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }