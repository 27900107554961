//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getInfo } from "@api/rm/person";
export default {
  name: "user-info",
  data: function data() {
    return {
      person: {
        type: Object,
        default: function _default() {
          return {};
        }
      }
    };
  },
  created: function created() {
    var _this = this;

    var param = {
      personuuid: null
    };
    getInfo(param).then(function (res) {
      var _res$data = res.data,
          data = _res$data === void 0 ? {} : _res$data;
      _this.person = data;

      for (var i in _this.person) {
        //如果属性值为null，赋值为""
        if (_this.person[i] == null) {
          _this.person[i] = "";
        }
      }
    });
  }
};