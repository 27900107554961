import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { list as personList } from "@api/rm/person";
import { saveProductToManyUser } from "@api/rm/right";
export default {
  name: "right-product-dialog-add",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      formModel: {
        productId: "",
        //产品
        arrayPersonuuid: [] //用户数组

      },
      // 表单值      
      tableData: [],
      checkboxRows: [],
      selectData: {},
      rules: {}
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadPerson();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.formModel = {
        productId: "",
        //产品
        arrayPersonuuid: [] //用户数组

      };
    },
    dialogClose: function dialogClose() {},
    //职员列表
    loadPerson: function loadPerson() {
      var _this = this;

      var param = {
        page: {
          pageSize: 99999
        },
        query: {
          orgCd: "01",
          enabled: "1",
          isSystemUser: "1"
        }
      };
      personList(param).then(function (res) {
        _this.tableData = res.data;
      });
    },
    //复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      this.checkboxRows = rows;
    },
    //保存
    clickSave: function clickSave() {
      var _this2 = this;

      this.checkboxRows.forEach(function (n) {
        _this2.formModel.arrayPersonuuid.push(n.personuuid);
      });

      if (this.formModel.productId == "" || this.formModel.arrayPersonuuid.length == 0) {
        this.$message({
          message: "请选择产品和授权人员"
        });
      } else if (isNaN(this.formModel.productId) || this.formModel.productId.length != 4) {
        this.$message({
          message: "请正确填写4位产品Id"
        });
      } else {
        var param = _objectSpread({}, this.formModel);

        saveProductToManyUser(param).then(function (res) {
          _this2.$message({
            message: "已保存",
            type: "success"
          });

          _this2.showDialog = false;
        });
      }
    }
  }
};