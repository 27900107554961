var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '客户',
  prop: 'customerName',
  width: '120px'
}, {
  label: '合同编号',
  prop: 'contractCd',
  width: '150px'
}, {
  label: '合同状态',
  prop: 'contractStateText',
  width: VAL_WIDTH
}, {
  label: '产品',
  prop: 'productName',
  width: '150px'
}, {
  label: '合同期限',
  prop: 'timeLimit',
  width: '80px'
}, {
  label: '返还周期',
  prop: 'returnPeriod',
  width: '80px'
}, {
  label: '合同金额',
  prop: 'amount',
  width: '120px',
  align: 'right'
}, // {
//     label: '年化入金',
//     prop: 'yearAmount',   
//     width: '120px',
//     align: 'right',
// }, 
{
  label: '开始日期',
  prop: 'startDate',
  width: VAL_WIDTH
}, {
  label: '结束日期',
  prop: 'endDate',
  width: VAL_WIDTH
}, {
  label: '机构',
  prop: 'orgName',
  width: '210px'
}, {
  label: '顾问',
  prop: 'userName',
  width: '120px'
}];