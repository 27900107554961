import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import STORE from "@/maps/enum/store";
import low from 'lowdb';
import LocalStorage from 'lowdb/adapters/LocalStorage';
import util from '@/libs/util';
import { cloneDeep } from 'lodash'; // const adapter = new LocalStorage(`d2admin-${process.env.VUE_APP_VERSION}`)

var adapter = new LocalStorage(STORE.CRM_INFO);
var db = low(adapter);
db.defaults({
  sys: {},
  database: {}
}).write();
export default db;
/**
 * @description 检查路径是否存在 不存在的话初始化
 * @param {Object} payload dbName {String} 数据库名称
 * @param {Object} payload path {String} 路径
 * @param {Object} payload user {Boolean} 区分用户
 * @param {Object} payload validator {Function} 数据校验钩子 返回 true 表示验证通过
 * @param {Object} payload defaultValue {*} 初始化默认值
 * @returns {String} 可以直接使用的路径
 */

export function pathInit(_ref) {
  var _ref$dbName = _ref.dbName,
      dbName = _ref$dbName === void 0 ? 'database' : _ref$dbName,
      _ref$path = _ref.path,
      path = _ref$path === void 0 ? '' : _ref$path,
      _ref$user = _ref.user,
      user = _ref$user === void 0 ? true : _ref$user,
      _ref$validator = _ref.validator,
      validator = _ref$validator === void 0 ? function () {
    return true;
  } : _ref$validator,
      _ref$defaultValue = _ref.defaultValue,
      defaultValue = _ref$defaultValue === void 0 ? '' : _ref$defaultValue;
  var uuid = util.cookies.get('uuid') || 'ghost-uuid';
  var currentPath = "".concat(dbName, ".").concat(user ? "user.".concat(uuid) : 'public').concat(path ? ".".concat(path) : '');
  var value = db.get(currentPath).value();

  if (!(value !== undefined && validator(value))) {
    db.set(currentPath, defaultValue).write();
  }

  return currentPath;
}
/**
 * @description 将数据存储到指定位置 | 路径不存在会自动初始化
 * @description 效果类似于取值 dbName.path = value
 * @param {Object} payload dbName {String} 数据库名称
 * @param {Object} payload path {String} 存储路径
 * @param {Object} payload value {*} 需要存储的值
 * @param {Object} payload user {Boolean} 是否区分用户
 */

export function dbSet(_ref2) {
  var _ref2$dbName = _ref2.dbName,
      dbName = _ref2$dbName === void 0 ? 'database' : _ref2$dbName,
      _ref2$path = _ref2.path,
      path = _ref2$path === void 0 ? '' : _ref2$path,
      _ref2$value = _ref2.value,
      value = _ref2$value === void 0 ? '' : _ref2$value,
      _ref2$user = _ref2.user,
      user = _ref2$user === void 0 ? false : _ref2$user;
  db.set(pathInit({
    dbName: dbName,
    path: path,
    user: user
  }), value).write();
}
/**
 * @description 获取数据
 * @description 效果类似于取值 dbName.path || defaultValue
 * @param {Object} payload dbName {String} 数据库名称
 * @param {Object} payload path {String} 存储路径
 * @param {Object} payload defaultValue {*} 取值失败的默认值
 * @param {Object} payload user {Boolean} 是否区分用户
 */

export function dbGet(_ref3) {
  var _ref3$dbName = _ref3.dbName,
      dbName = _ref3$dbName === void 0 ? 'database' : _ref3$dbName,
      _ref3$path = _ref3.path,
      path = _ref3$path === void 0 ? '' : _ref3$path,
      _ref3$defaultValue = _ref3.defaultValue,
      defaultValue = _ref3$defaultValue === void 0 ? '' : _ref3$defaultValue,
      _ref3$user = _ref3.user,
      user = _ref3$user === void 0 ? false : _ref3$user;
  return new Promise(function (resolve) {
    resolve(cloneDeep(db.get(pathInit({
      dbName: dbName,
      path: path,
      user: user,
      defaultValue: defaultValue
    })).value()));
  });
}
/**
 * @description 获取存储数据库对象
 * @param {Object} payload user {Boolean} 是否区分用户
 */

export function database() {
  var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref4$dbName = _ref4.dbName,
      dbName = _ref4$dbName === void 0 ? 'database' : _ref4$dbName,
      _ref4$path = _ref4.path,
      path = _ref4$path === void 0 ? '' : _ref4$path,
      _ref4$user = _ref4.user,
      user = _ref4$user === void 0 ? false : _ref4$user,
      _ref4$validator = _ref4.validator,
      validator = _ref4$validator === void 0 ? function () {
    return true;
  } : _ref4$validator,
      _ref4$defaultValue = _ref4.defaultValue,
      defaultValue = _ref4$defaultValue === void 0 ? '' : _ref4$defaultValue;

  return new Promise(function (resolve) {
    resolve(db.get(pathInit({
      dbName: dbName,
      path: path,
      user: user,
      validator: validator,
      defaultValue: defaultValue
    })));
  });
}