//核算
export default {
  SAVE: {
    value: '1',
    text: '待提交'
  },
  SUBMIT: {
    value: '2',
    text: '待客服审核'
  },
  SERVICE_AUDIT: {
    value: '3',
    text: '客服已审核'
  },
  SERVICE_AUDIT_FAILURE: {
    value: '4',
    text: '客服审核不通过'
  },
  FINANCE_AUDIT: {
    value: '12',
    text: '财务已审核'
  },
  FINANCE_AUDIT_FAILURE: {
    value: '11',
    text: '财务审核不通过'
  },
  MONEY_AUDIT: {
    value: '5',
    text: '资金已审核'
  },
  MONEY_AUDIT_FAILURE: {
    value: '9',
    text: '资金审核不通过'
  },
  DELETE: {
    value: '-99',
    text: '已删除'
  }
};