import "core-js/modules/es.object.to-string.js";
// 付息
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatUserDataPromise, formatDate } from '@/libs/crm/format';
import func from '@/maps/url/crm/interest/interest'; // TODO 列表

export function list(params, formatType) {
  var re = request.post(func.list, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 详情

export function getInfo(params, formatType) {
  var re = request.post(func.getInfo, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 取最后一期付息详情

export function getLastInfo(params, formatType) {
  var re = request.post(func.getLastInfo, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 获取最后一期付息初始值

export function getInitLastInfo(params, formatType) {
  var re = request.post(func.getInitLastInfo, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 暂存

export function save(params) {
  var re = request.post(func.save, {
    body: params
  });
  return re;
} // TODO 提交

export function submit(params) {
  var re = request.post(func.submit, {
    body: params
  });
  return re;
} // TODO 删除

export function remove(params) {
  var re = request.post(func.remove, {
    body: params
  });
  return re;
} // TODO 客服审核

export function auditService(params) {
  var re = request.post(func.auditService, {
    body: params
  });
  return re;
} // TODO 结算审核

export function auditCount(params) {
  var re = request.post(func.auditCount, {
    body: params
  });
  return re;
} // TODO 财务审核

export function auditFinance(params) {
  var re = request.post(func.auditFinance, {
    body: params
  });
  return re;
} // TODO 财务总监审核

export function auditFinanceManager(params) {
  var re = request.post(func.auditFinanceManager, {
    body: params
  });
  return re;
} // TODO 出金打印审核

export function auditPrint(params) {
  var re = request.post(func.auditPrint, {
    body: params
  });
  return re;
} // TODO 资金审核

export function auditMoney(params) {
  var re = request.post(func.auditMoney, {
    body: params
  });
  return re;
} // TODO 打印确认

export function printConfirm(params) {
  var re = request.post(func.printConfirm, {
    body: params
  });
  return re;
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '付息' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 导出对帐单Excel

export function exportExcelBill(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelBill, {
    body: params
  }).then(function (blob) {
    util.download(blob, '付息清单' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 导出付款单Excel

export function exportExcelPayment(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelPayment, {
    body: params
  }).then(function (blob) {
    util.download(blob, '付息付款清单' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 新增创建

export function create(params) {
  var re = request.post(func.create, {
    body: params
  });
  return re;
}