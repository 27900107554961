// 归属系统 
export default {
  BELONGSYSTEM: 'belongSystem',
  // 金承CRM：jq_crm  金承经营分析: jq_analy）
  PERFORMANCELEVEL: 'performanceLevel',
  // 绩效统计层级
  PERFORMANCETYPE: 'performanceType',
  // 绩效统计类型
  org_statistical_type: 'org_statistical_type',
  // 统计类型
  ORGSTATISTICALTYPE: 'org_statistical_type',
  // 统计类型
  PERFORMANCETYPE2: 'performanceType2' // 统计归属部门类型

};