// 菜单 侧边栏
export default [{
  title: '资讯管理',
  children: [{
    path: '/crm/info/manage',
    title: '资讯管理'
  }, {
    path: '/crm/info/category',
    title: '资讯分类'
  }, {
    path: '/crm/info/material',
    title: '素材库'
  }]
}, {
  title: '活动管理',
  children: [{
    path: '/crm/activity/manage',
    title: '活动管理'
  }, {
    path: '/crm/activity/category',
    title: '活动分类'
  }, {
    path: '/crm/activity/evaluation/template',
    title: '评价模板'
  }, {
    path: '/crm/activity/audit',
    title: '活动审核'
  }]
}, {
  title: '客户管理',
  children: [{
    path: '/crm/customer/manage',
    title: '客户管理'
  }, {
    path: '/crm/customer/follow',
    title: '客户跟进'
  }, {
    path: '/crm/customer/level',
    title: '客户等级管理'
  }, {
    path: '/crm/customer/label',
    title: '客户标签管理'
  }, {
    path: '/crm/customer/h5',
    title: '客户H5账号管理'
  }, {
    path: '/crm/customer/agentAppointment',
    title: '顾问预约管理'
  }]
}, {
  title: '广告管理',
  children: [{
    path: '/crm/advertising/manage',
    title: '广告管理'
  }, {
    path: '/crm/advertising/position',
    title: '广告位管理'
  }]
}, {
  title: '积分礼品管理',
  children: [{
    path: '/crm/integralGift/integral/manage',
    title: '积分管理'
  }]
}, {
  title: '系统配置',
  children: [{
    path: '/sys/system/basic',
    title: '基本信息配置'
  }]
}];