// 附件
import request from '@/plugin/axios';
import func from '@/maps/url/crm/contractSpecial/contractSpecial'; // TODO 保存

export function save(params) {
  return request.post(func.save, {
    body: params
  });
} // TODO 明细

export function getInfo(params) {
  return request.post(func.getInfo, {
    body: params
  });
} // TODO 列表

export function list(params) {
  return request.post(func.list, {
    body: params
  });
}