var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'rm';
export default [// 清理缓存
{
  path: "".concat(baseRoute, "/system/tool"),
  name: 'tool',
  meta: {
    title: '工具',
    auth: true,
    cache: true
  },
  component: _import('rm/system/tool')
}];