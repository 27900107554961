//纸合同
export default {
  listNoStamp: '/paperContract/listNoStamp',
  // 未盖章合同查询 
  listStampBack: '/paperContract/listStampBack',
  // 已盖章合同寄回查询
  sendExpress: '/paperContract/sendExpress',
  // 寄送快递
  listAudit: '/paperContract/listAudit',
  // 审核查询
  audit: '/paperContract/audit',
  //审核
  exportExcelTemplate: '/paperContract/exportExcelTemplate',
  //已审核合同导出Excel模板
  exportExcelBuyTemplate: '/paperContract/exportExcelBuyTemplate',
  //购销合同导出Excel模板
  listExpress: '/paperContract/listExpress',
  //快递信息查询
  receive: '/paperContract/receive',
  //快递签收
  listExpressContract: '/paperContract/listExpressContract',
  //快递关联合同查询
  editExpress: '/paperContract/editExpress',
  //快递编辑
  exportExcelExpress: '/paperContract/exportExcelExpress' //纸合同快递信息导出excel

};