






































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/commissionV3/product/index.vue"
}
