//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { syncGoldPrice } from "@api/rm/tool";
export default {
  name: "tool-sync-gold-price",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单  
      rules: {
        priceDate: [{
          required: true,
          message: "请输入金价",
          trigger: "input"
        }]
      }
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.formModel = {
        priceDate: null
      };
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 确定
    confirm: function confirm() {
      var _this = this;

      var msg = "";
      this.$confirm(msg, '确定同步指定日金价？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var param = {
          priceDate: _this.formModel.priceDate
        };
        syncGoldPrice(param).then(function (res) {
          _this.$message({
            message: "已更新",
            type: "success"
          });

          _this.showDialog = false;
        });
      }).catch(function () {});
    }
  }
};