var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        top: "10vh",
        width: "600px",
        title: "自定义选择部门（支持多选）",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c("div", { staticClass: "dialog-content" }, [
        _c("div", { staticClass: "flex-title" }, [
          _c("p", [_vm._v("团队组织")]),
          _c(
            "div",
            { staticClass: "is-contain-select" },
            [
              _vm._v(" 是否包含下属部门： "),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.isContain,
                    callback: function($$v) {
                      _vm.isContain = $$v
                    },
                    expression: "isContain"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("否")])
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm.deptList && _vm.deptList.length
          ? _c(
              "div",
              [
                _c("el-tree", {
                  ref: "deptTree",
                  attrs: {
                    "check-strictly": !_vm.isContain,
                    "default-checked-keys": _vm.defaultCheckedKeys,
                    data: _vm.deptList,
                    props: _vm.props,
                    "node-key": "orgCd",
                    "show-checkbox": "",
                    "default-expanded-keys": _vm.defaultExpandKeys
                  },
                  on: { check: _vm.checkNodes }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "mini" },
              on: { click: _vm.dialogClose }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: { click: _vm.confirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }