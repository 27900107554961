var VAL_WIDTH = '130px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '统计日期',
  valueKey: 'dateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'day',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'dateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'day',
    style: {
      width: VAL_WIDTH
    }
  }
}];