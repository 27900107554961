//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import AUDIT from "@/maps/enum/status/audit";
import DialogDetail from "../../dialog-detail";
import DialogSummary from "../../dialog-summary";
import { auditFinanceManager } from "@api/crm/commission/commissionAgency";
export default {
  name: "commission-agency-finance-audit",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    DialogDetail: DialogDetail,
    DialogSummary: DialogSummary
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      showSummaryDialog: false,
      formModel: {},
      //表单
      rules: {},
      batchId: this.propsModel.batchId
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.tableData = [];
    },
    loadData: function loadData() {},
    clickAuditFail: function clickAuditFail() {
      //审核不通过
      //if (this.formModel.advice == null || this.formModel.advice == "") {
      //  this.$message("请填写审核意见");
      //} else {
      this.audit(AUDIT.NO.value); //}
    },
    clickAudit: function clickAudit() {
      //审核通过
      this.audit(AUDIT.YES.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      //this.$refs.form.validate(valid => {
      //  if (valid) {
      var param = {
        batchId: this.propsModel.batchId,
        audit: result,
        advice: this.formModel.advice
      };
      this.loading = true;
      auditFinanceManager(param).then(function (res) {
        _this.loading = false;
        _this.showDialog = false;

        _this.$emit("refresh");
      }).catch(function (e) {}); // } else {
      //   return false;
      // }
      // });
    },
    //汇总报表
    showSummary: function showSummary() {
      this.batchId = this.propsModel.batchId;
      this.showSummaryDialog = true;
    }
  }
};