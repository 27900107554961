//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '合同编号',
  prop: 'contractCd',
  width: '150px'
}, {
  label: '客户',
  prop: 'customerName',
  width: '80px'
}, {
  label: '机构',
  prop: 'orgName',
  width: '210px'
}, {
  label: '产品',
  prop: 'productName',
  width: '150px'
}, {
  label: '开始日期',
  prop: 'startDate',
  width: VAL_WIDTH
}, {
  label: '结束日期',
  prop: 'endDate',
  width: VAL_WIDTH
}, {
  label: '档案柜',
  prop: 'boxName',
  width: '80px'
}, {
  label: '归档编号',
  prop: 'orderNo',
  width: '120px'
}, {
  label: '归档日期',
  prop: 'archiveDate',
  width: '100px'
}, {
  label: '线下审核',
  prop: 'offlineAuditStateText',
  width: '80px'
}, {
  label: '盖章',
  prop: 'sealStateText',
  width: '80px'
}, {
  label: '核算日期',
  prop: 'countDate',
  width: VAL_WIDTH
}];