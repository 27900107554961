var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        top: "20vh",
        width: "600px",
        title: "待处理消息",
        visible: _vm.showDialog,
        modal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            "row-style": { height: "20px" },
            "cell-style": { padding: "6px 0" },
            height: "300"
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "menuName", label: "项目", width: "200" }
          }),
          _c("el-table-column", {
            attrs: { label: "待处理", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.routerChange(scope.row.menuUrl)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.doToCount))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "100" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _c("div", { staticStyle: { color: "blue" } }, [
                      _vm._v("将超时")
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        class: {
                          active: scope.row.leastCount > 0 ? true : false
                        },
                        staticStyle: { color: "blue" },
                        on: {
                          click: function($event) {
                            return _vm.routerChange(scope.row.menuUrl)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.leastCount))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { width: "100" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _c("div", { staticStyle: { color: "red" } }, [
                      _vm._v("已超时")
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "div",
                      {
                        class: {
                          active: scope.row.overdueCount > 0 ? true : false
                        },
                        staticStyle: { color: "red" },
                        on: {
                          click: function($event) {
                            return _vm.routerChange(scope.row.menuUrl)
                          }
                        }
                      },
                      [_vm._v(_vm._s(scope.row.overdueCount))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }