import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import { listDetail as list, saveDetail as save } from '@api/rm/system';
export default {
  name: 'system-enum-detail',
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      tableData: [],
      selectData: {},
      rules: {},
      fileList: [],
      imageUrl: process.env.VUE_APP_CLOUD_UPLOAD
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    /** 移除 */
    remove: function remove(row) {
      row.url = '';
    },
    upLoadFile: function upLoadFile(params, row) {
      console.log('-----upload', params);
      row.url = params.url;
    },
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 获取列表
    loadData: function loadData() {
      var _this = this;

      this.loading = true;
      var param = {
        enumCd: this.propsModel.enumCd
      };
      list(param).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data;
          _this.tableData = data;
        } else {
          _this.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    // TODO 确定
    clickSave: function clickSave() {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = {
            enumCd: _this2.propsModel.enumCd,
            arrayObject: _this2.tableData
          };
          console.log('this.tableData', _this2.tableData);
          save(param).then(function (res) {
            _this2.loading = false;
            _this2.showDialog = false;

            _this2.$emit('refresh');
          }).catch(function (e) {
            _this2.loading = false;
          });
        }
      });
    },
    //点击新增行
    add: function add() {
      this.tableData.push({
        enumDetailId: '',
        enumCd: this.propsModel.enumCd,
        enumDetailValue: '',
        enumDetailLabel: '',
        enumDetailIndex: '',
        enabled: '1'
      });
    },
    //点击删除
    handleDelete: function handleDelete(index) {
      this.tableData.splice(index, 1);
    }
  }
};