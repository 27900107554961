//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import STATUS from "@/maps/enum/status/transfer";
import { transfer } from "@api/crm/transfer/transfer";
export default {
  name: "transfer-audit",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      collapseActive: ["1"],
      //信息折叠默认展开
      formModel: {},
      //表单
      rules: {
        transferDate: [{
          required: true,
          message: "请选择划款日期",
          trigger: "input"
        }]
      }
    };
  },
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.model = Object.assign({}, this.propsModel); //复制新对象          
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    clickAuditFail: function clickAuditFail() {
      //审核不通过
      if (this.formModel.remark == null || this.formModel.remark == "") {
        this.$message("请填写划款备注");
      } else {
        this.audit(STATUS.FAILURE.value);
      }
    },
    clickAudit: function clickAudit() {
      //审核通过
      this.audit(STATUS.SUCCESS.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = {
            transferId: _this.model.transferId,
            transferDate: _this.formModel.transferDate,
            state: result,
            remark: _this.formModel.remark
          };
          _this.loading = true;
          transfer(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit("refresh");
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};