// 资讯管理
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [{
  path: "".concat(baseRoute, "/info/manage"),
  name: 'infoManager',
  meta: {
    title: '资讯和视频管理',
    auth: true,
    cache: true
  },
  component: _import('crm/info/manage/list')
}, {
  path: "".concat(baseRoute, "/info/manage/add"),
  name: 'infoAdd',
  meta: {
    title: '新增/修改资讯',
    auth: true,
    cache: true
  },
  component: _import('crm/info/manage/add')
}, {
  path: "".concat(baseRoute, "/info/manage/view"),
  name: 'infoView',
  meta: {
    title: '资讯和视频详情',
    auth: true,
    cache: true
  },
  component: _import('crm/info/manage/view')
}, {
  path: "".concat(baseRoute, "/info/category"),
  name: 'infoCategory',
  meta: {
    title: '资讯和视频分类',
    auth: true,
    cache: true
  },
  component: _import('crm/info/category')
}, {
  path: "".concat(baseRoute, "/info/material"),
  name: 'infoMaterial',
  meta: {
    title: '素材库',
    auth: true,
    cache: true
  },
  component: _import('crm/info/material')
}, {
  path: "".concat(baseRoute, "/info/dailySentence"),
  name: 'dailySentence',
  meta: {
    title: '每日金句',
    auth: true,
    cache: true
  },
  component: _import('crm/info/dailySentence')
}];