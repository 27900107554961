//产品类别
export default {
  HJCG: '1',
  // 黄金实物存管
  HJCZ: '2',
  // 黄金实物出租  
  HJWT: '3',
  // 黄金实物委托
  WHJ: '4',
  // 文化金
  CC: '5',
  //产城
  HJHS: '6',
  //黄金回收
  YSZK: '7',
  //应收账款
  HJXSD: '8',
  //5D黄金新时代
  GXZB: '9',
  //共享珠宝
  HJZBDZ: '10',
  //黄金珠宝定制
  CYY: '11',
  //产业园
  TL: '12',
  //套利
  ZQJJ: '13',
  //证券基金
  YXHHJJ: '14',
  //有限合伙型基金
  QYXJJ: '15',
  //契约型基金
  GQJJ: '16',
  //股权基金
  DYZJXT: '17',
  //单一资金信托
  OTHER: '18',
  //其他
  JD: '19',
  //金店
  HJHSSM: '20',
  //黄金回收(私募)
  HJXLS: '21' //黄金新零售

};