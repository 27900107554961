//礼品物理
export default {
  save: '/gift/save',
  //保存
  remove: '/gift/remove',
  //删除
  setStatus: '/gift/setStatus',
  //设置启用
  list: '/gift/list',
  //礼品列表
  getInfo: '/gift/getInfo',
  //详情
  exportExcel: '/gift/exportExcel' // 导出Excel

};