// 职务 
export var arrayTitleId = {
  I0: {
    value: 'i0',
    text: '顾问'
  },
  I1: {
    value: 'i1',
    text: '团队总监'
  },
  I2: {
    value: 'i2',
    text: '营业部总经理'
  },
  I3: {
    value: 'i3',
    text: '区域总经理'
  },
  I4: {
    value: 'i4',
    text: '营业部副总'
  },
  I5: {
    value: 'i5',
    text: '后勤人员管理层'
  }
}; // 推介费大区编号

export var commissionOrgCd = [{
  value: '000003',
  text: '中后台管理'
}, {
  value: '000016',
  text: '一区'
}, {
  value: '000017',
  text: '二区'
}, {
  value: '000018',
  text: '三区'
}, {
  value: '000019',
  text: '四区'
}, {
  value: '000020',
  text: '五区'
}, {
  value: '000021',
  text: '六区'
}, {
  value: '000022',
  text: '七区'
}, {
  value: '000224',
  text: '新营业部孵化中心'
}, {
  value: '000029',
  text: '杭州营业部'
}, {
  value: '000254',
  text: '常州营业部'
}, {
  value: '000249',
  text: '南京营业部'
}, {
  value: '000026',
  text: '广州雅御湾区招商中心'
}, {
  value: '010001',
  text: '直营-其他'
}];