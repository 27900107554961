var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: {
                data: _vm.tableData,
                height: "350",
                "row-style": { height: "20px" },
                "cell-style": { padding: "6px 0" },
                "highlight-current-row": ""
              },
              on: { "current-change": _vm.selectRow }
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号" }
              }),
              _c("el-table-column", {
                attrs: { prop: "fileName", label: "附件名", width: "300" }
              }),
              _c("el-table-column", {
                attrs: { prop: "fileType", label: "类型", width: "80" }
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "确定删除该附件吗？" },
                            on: {
                              confirm: function($event) {
                                return _vm.clickRemove(row)
                              }
                            }
                          },
                          [
                            _vm.isAttachmentEdit
                              ? _c("el-button", {
                                  attrs: {
                                    slot: "reference",
                                    type: "danger",
                                    plain: "",
                                    circle: "",
                                    size: "mini",
                                    icon: "el-icon-delete-solid"
                                  },
                                  slot: "reference"
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v("  "),
                        _c("el-button", {
                          attrs: {
                            plain: "",
                            size: "mini",
                            circle: "",
                            icon: "el-icon-search"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickBigPreview(row)
                            }
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm.isAttachmentEdit
            ? _c(
                "el-button",
                {
                  staticClass: "buttonUpload",
                  attrs: { plain: "", size: "mini", icon: "el-icon-upload" },
                  on: { click: _vm.UploadVisible }
                },
                [_vm._v("上传")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "文件上传",
            top: "30vh",
            width: "50%",
            modal: false,
            visible: _vm.visible
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "VideoBox" },
            [
              _c("j-form", {
                ref: "jForm",
                attrs: {
                  formConfig: _vm.dialogFormConfig,
                  formData: _vm.dialogFormData
                },
                on: {
                  "update:formData": function($event) {
                    _vm.dialogFormData = $event
                  },
                  "update:form-data": function($event) {
                    _vm.dialogFormData = $event
                  },
                  submit: _vm.dialogSubmit
                }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      _vm.visible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.dialogSubmit }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "查看",
            top: "30vh",
            width: "50%",
            modal: false,
            visible: _vm.examineVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.examineVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "VideoBox" },
            [
              _c("j-form", {
                ref: "jForm",
                attrs: {
                  formConfig: _vm.examineFormConfig,
                  formData: _vm.examineFormData
                },
                on: {
                  "update:formData": function($event) {
                    _vm.examineFormData = $event
                  },
                  "update:form-data": function($event) {
                    _vm.examineFormData = $event
                  }
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }