//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '合同编号',
  prop: 'contractCd',
  width: '150px'
}, {
  label: '购销合同号',
  prop: 'buyContractCd',
  width: '120px'
}, {
  label: '状态',
  prop: 'contractState',
  width: '120px',
  type: 'slot'
}, {
  label: '客户',
  prop: 'customerName',
  width: '80px'
}, {
  label: '产品',
  prop: 'productName',
  width: '150px'
}, {
  label: '合同金额',
  prop: 'amount',
  width: '120px',
  align: 'right'
}, {
  label: '开始日期',
  prop: 'startDate',
  width: VAL_WIDTH
}, {
  label: '结束日期',
  prop: 'endDate',
  width: VAL_WIDTH
}, {
  //     label: '存续天数',
  //     prop: 'holdDay',
  //     width: '80px',
  // }, {
  //     label: '到期天数',
  //     prop: 'leftDay',
  //     width: '80px',
  // }, {
  label: '期限(月)',
  prop: 'timeLimit',
  width: '80px'
}, {
  label: '返还周期',
  prop: 'returnPeriod',
  width: '80px' // }, {
  //     label: '年化收益(%)',
  //     prop: 'annualRate',
  //     width: VAL_WIDTH,
  //     align: 'right',

}, {
  label: '预期收益',
  prop: 'expectEarnings',
  width: '120px',
  align: 'right'
}, {
  label: '银行账号',
  prop: 'accountNo',
  width: '180px'
}, {
  label: '收款方式',
  prop: 'payMethodText',
  width: '180px'
}, {
  label: '机构',
  prop: 'orgName',
  width: '210px'
}];