









































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/components/business/attachment/audio-player.vue"
}
