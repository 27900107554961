var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "25%",
            modal: false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.close
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-title",
              attrs: { slot: "title" },
              slot: "title"
            },
            [
              _c(
                "el-row",
                [
                  _c("span", { staticClass: "dialog-title-text" }, [
                    _vm._v("客户信息")
                  ]),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { type: "primary" },
                      on: { click: _vm.skip }
                    },
                    [_vm._v("跳转详情")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "el-descriptions",
                { attrs: { direction: "horizontal", column: 1, border: "" } },
                [
                  _c("el-descriptions-item", { attrs: { label: "客户姓名" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.customerName))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "性别" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.sexValue))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "年龄" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.age))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "客户电话" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.mobile))
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "家庭住址", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.address))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "客户类型", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.customerTypeValue))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "客户等级", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.customerLevelValue))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "客户年限", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.customerYearLimit))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "理顾", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.agentUserName))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "部门", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.orgName))]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "j-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
              modifiers: { fullscreen: true, lock: true }
            }
          ],
          attrs: {
            visible: _vm.conflictCustomerVisible,
            title: _vm.conflictCustomerTitle,
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.conflictCustomerVisible = $event
            },
            confirm: _vm.mergeCustomer,
            close: _vm.closeConflictCustomer
          }
        },
        [
          _c(
            "el-table",
            {
              ref: "singleTable",
              attrs: {
                "highlight-current-row": "",
                data: _vm.conflictCustomerData
              },
              on: { "selection-change": _vm.handleCurrentChange }
            },
            [
              _vm._v(' style="width: 100%" > '),
              _c("el-table-column", { attrs: { type: "selection" } }),
              _c("el-table-column", {
                attrs: { prop: "customerCode", label: "客户编码" }
              }),
              _c("el-table-column", {
                attrs: { prop: "customerName", label: "客户姓名" }
              }),
              _c("el-table-column", {
                attrs: { prop: "orgName", label: "组织名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "agentUserName", label: "理顾名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "sexValue", label: "性别" }
              }),
              _c("el-table-column", { attrs: { prop: "age", label: "年龄" } }),
              _c("el-table-column", {
                attrs: { prop: "birthplaceValue", label: "出生地" }
              }),
              _c("el-table-column", {
                attrs: { prop: "mobile", label: "手机号码" }
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "地址" }
              }),
              _c("el-table-column", {
                attrs: { prop: "customerTypeValue", label: "客户类型" }
              }),
              _c("el-table-column", {
                attrs: { prop: "customerLevelValue", label: "客户等级" }
              }),
              _c("el-table-column", {
                attrs: { prop: "customerYearLimit", label: "客户年限" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "j-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.allotConfirmLoading,
              expression: "allotConfirmLoading",
              modifiers: { fullscreen: true, lock: true }
            }
          ],
          attrs: { visible: _vm.openAllot, title: "选择理顾" },
          on: {
            "update:visible": function($event) {
              _vm.openAllot = $event
            },
            confirm: _vm.allotConfirm,
            close: _vm.AddUserIdHide
          }
        },
        [
          _c(
            "el-form",
            {
              attrs: { inline: true, model: _vm.userSelectData, size: "small" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      options: _vm.orgTreeData,
                      props: _vm.orgProps,
                      placeholder: "请选择部门",
                      filterable: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.userSelectData.orgCd,
                      callback: function($$v) {
                        _vm.$set(_vm.userSelectData, "orgCd", $$v)
                      },
                      expression: "userSelectData.orgCd"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.userSelectData.userName,
                      callback: function($$v) {
                        _vm.$set(_vm.userSelectData, "userName", $$v)
                      },
                      expression: "userSelectData.userName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.userSearch }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.userTableData, "tooltip-effect": "dark" },
              on: { "selection-change": _vm.handleSelectionChange }
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" }
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "姓名" }
              }),
              _c("el-table-column", {
                attrs: { prop: "orgName", label: "部门" }
              }),
              _c("el-table-column", {
                attrs: { prop: "userCode", label: "工号" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "j-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.allotConfirmLoading,
              expression: "allotConfirmLoading",
              modifiers: { fullscreen: true, lock: true }
            }
          ],
          attrs: {
            visible: _vm.adjustmentVisible,
            title: _vm.adjustmentTitle,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.adjustmentVisible = $event
            },
            confirm: _vm.adjustmentConfirm,
            close: _vm.adjustmentClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "adjustmentForm",
              attrs: {
                model: _vm.adjustmentForm,
                rules: _vm.adjustmentRules,
                "label-width": "110px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      _vm.adjustmentTitle === "调整成长值"
                        ? "调整成长值"
                        : "调整积分",
                    prop: "value"
                  }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "operationMethod" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.adjustmentForm.operationMethod,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.adjustmentForm,
                                    "operationMethod",
                                    $$v
                                  )
                                },
                                expression: "adjustmentForm.operationMethod"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "增加", value: 1 }
                              }),
                              _c("el-option", {
                                attrs: { label: "减少", value: 2 }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "value" } },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            model: {
                              value: _vm.adjustmentForm.value,
                              callback: function($$v) {
                                _vm.$set(_vm.adjustmentForm, "value", $$v)
                              },
                              expression: "adjustmentForm.value"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      "show-word-limit": "",
                      maxlength: "200"
                    },
                    model: {
                      value: _vm.adjustmentForm.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.adjustmentForm, "remark", $$v)
                      },
                      expression: "adjustmentForm.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.writeOffEntitlementVisible,
            title: "权益明细",
            footActive: false
          },
          on: {
            "update:visible": function($event) {
              _vm.writeOffEntitlementVisible = $event
            },
            close: _vm.writeOffEntitlementClose
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.writeOffEntitlementData }
            },
            [
              _c("el-table-column", {
                attrs: { fixed: "", prop: "entitlementName", label: "权益名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "remainingQuantity", label: "数量" }
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              disabled: scope.row.hasQuantity === 0
                            },
                            on: {
                              click: function($event) {
                                return _vm.writeOffEntitlementClick(scope.row)
                              }
                            }
                          },
                          [_vm._v("核销")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "j-dialog",
            {
              attrs: {
                visible: _vm.writeOffEntitlementVisibleSon,
                title: "确认核销",
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.writeOffEntitlementVisibleSon = $event
                },
                confirm: _vm.writeOffEntitlementConfirm
              }
            },
            [
              _c(
                "el-row",
                { staticStyle: { margin: "30px" } },
                [
                  _c("el-col", { attrs: { span: 4, st: "" } }, [
                    _c("div", [_vm._v("权益名称：")])
                  ]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.writeOffEntitlementObj.entitlementName))
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { margin: "30px" } },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", [_vm._v("核销数量：")])
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "span",
                      [
                        _c("el-input", {
                          attrs: { type: "number", size: "small" },
                          model: {
                            value: _vm.remainingQuantity,
                            callback: function($$v) {
                              _vm.remainingQuantity = $$v
                            },
                            expression: "remainingQuantity"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }