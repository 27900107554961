






















































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/clear/finance/components/dialog-audit-batch.vue"
}
