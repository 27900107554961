//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '名称',
  prop: 'toolName',
  width: '300px'
}, {
  label: '说明',
  prop: 'description',
  width: '800px'
}];