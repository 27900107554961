//文档模板
export default {
  list: '/document/template/list',
  // 列表 
  upload: '/document/template/upload',
  // 上传
  remove: '/document/template/remove',
  // 删除
  download: '/document/template/download' // 下载

};