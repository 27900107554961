var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-collapse",
    {
      model: {
        value: _vm.collapseActive,
        callback: function($$v) {
          _vm.collapseActive = $$v
        },
        expression: "collapseActive"
      }
    },
    [
      _c(
        "el-collapse-item",
        { attrs: { title: "推介费明细", name: "1" } },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData,
                border: "",
                "row-style": { height: "20px" },
                "cell-style": { padding: "6px 0" },
                hight: "350px"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "contractCd",
                  label: "合同编号",
                  width: "160",
                  fixed: ""
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "customerName", label: "姓名", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { prop: "productName", label: "产品", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { prop: "orgName", label: "机构", width: "150" }
              }),
              _c("el-table-column", {
                attrs: { prop: "startDate", label: "开始日期", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { prop: "endDate", label: "结束日期", width: "100" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "bussinessDate",
                  label: "业绩归属日",
                  width: "100"
                }
              }),
              _c(
                "el-table-column",
                { attrs: { label: "机构推介费", align: "center" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "listDetail[0].percent",
                      label: "比例(%)",
                      width: "100",
                      align: "right"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "listDetail[0].bonus",
                      label: "金额",
                      width: "120",
                      align: "right"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "listDetail[0].deductPercent",
                      label: "代扣比例(%)",
                      width: "100",
                      align: "right"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "listDetail[0].deduct",
                      label: "代扣金额",
                      width: "120",
                      align: "right"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "listDetail[0].commission",
                      label: "实发金额",
                      width: "120",
                      align: "right"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }