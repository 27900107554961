// 过滤条件
export var filters = {
  content: {
    type: 'input',
    label: '内容',
    props: {
      placeholder: '请输入内容',
      maxlength: 32
    }
  },
  author: {
    type: 'input',
    label: '作者',
    props: {
      placeholder: '请输入作者',
      maxlength: 32
    }
  },
  createUserName: {
    type: 'input',
    label: '创建人',
    props: {
      placeholder: '请输入创建人',
      maxlength: 32
    }
  },
  createTime: {
    type: 'datePicker',
    label: '创建时间',
    propGroup: ['beginCreateTime', 'endCreateTime'],
    props: {
      type: 'daterange'
    }
  },
  displayDate: {
    type: 'datePicker',
    label: '展示日期',
    propGroup: ['beginDisplayDate', 'endDisplayDate'],
    props: {
      type: 'daterange'
    }
  },
  status: {
    type: 'select',
    label: '状态',
    props: {
      placeholder: '请选择状态'
    },
    options: [{
      label: '停用',
      value: 0
    }, {
      label: '启用',
      value: 1
    }]
  }
}; // 列表label

export var tableLabel = [{
  label: '内容',
  prop: 'content',
  sortable: 'sentence',
  sortProp: 'content'
}, {
  label: '作者',
  prop: 'author',
  sortable: 'sentence',
  sortProp: 'author'
}, {
  type: 'inputNumber',
  label: '排序值',
  sortable: 'sentence',
  sortProp: 'sort',
  prop: 'sort'
}, {
  label: '创建人',
  prop: 'createUserName',
  sortable: 'sentence',
  sortProp: 'createUserName'
}, {
  label: '展示日期',
  sortable: 'sentence',
  sortProp: 'displayDate',
  prop: 'displayDate'
}, {
  label: '创建时间',
  sortable: 'sentence',
  sortProp: 'createTime',
  prop: 'createTime'
}, {
  label: '状态',
  prop: 'status',
  sortable: 'sentence',
  sortProp: 'status',
  formatter: function formatter(row) {
    return row.status === 0 ? '停用' : '启用';
  }
}, {
  type: 'operation',
  label: '操作',
  operations: [],
  formatter: function formatter(row) {
    var operations = [{
      type: 'edit',
      label: '修改',
      permission: 'sentence-update'
    }];

    if (row.status === 0) {
      operations.push({
        type: 'start',
        label: '启用',
        permission: 'sentence-updateStatus'
      });
      operations.push({
        type: 'delete',
        label: '删除',
        permission: 'sentence-batchDelete'
      });
    }

    if (row.status === 1) {
      operations.push({
        type: 'disable',
        label: '停用',
        permission: 'sentence-updateStatus'
      });
    }

    return operations;
  }
}]; // 新增表格列表label

export var tableLabel1 = [{
  type: 'input',
  label: '内容',
  prop: 'content',
  props: {
    placeholder: '请输入内容',
    type: 'textarea',
    autosize: true
  }
}, {
  type: 'input',
  label: '作者',
  prop: 'author',
  props: {
    placeholder: '请输入作者',
    type: 'textarea',
    autosize: true
  }
}, {
  type: 'slot',
  label: '展示日期',
  prop: 'displayDate'
}, {
  type: 'inputNumber',
  label: '排序值',
  prop: 'sort',
  width: '150px'
}, {
  type: 'select',
  label: '状态',
  prop: 'status',
  options: [{
    label: '停用',
    value: 0
  }, {
    label: '启用',
    value: 1
  }]
}, {
  type: 'operation',
  label: '操作',
  operations: [],
  formatter: function formatter(row, column, cellValue, index) {
    var operations = [{
      type: 'batAdd',
      label: '新增'
    }];

    if (index > 0) {
      operations = [{
        type: 'delete',
        label: '删除'
      }];
    }

    return operations;
  }
}]; // 表格配置

export var table = {
  isSelect: true,
  rowKey: 'id',
  actions: [{
    type: 'button',
    label: '新增',
    prop: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'sentence-batchAdd'
  }, {
    type: 'button',
    label: '批量删除',
    prop: 'delAll',
    props: {
      size: 'small'
    },
    permission: 'sentence-batchDelete'
  }],
  defaultSort: {
    prop: 'updateTime',
    order: 'descending'
  }
}; // 新增表格配置

export var table1 = {
  isSelect: false,
  rowKey: 'id'
}; // 修改表单配置

export var formConfig = {
  // 内容
  content: {
    type: 'input',
    label: '内容',
    props: {
      placeholder: '请输入内容',
      type: 'textarea',
      autosize: true
    },
    rules: [{
      required: true,
      message: '内容不能为空',
      trigger: 'blur'
    }]
  },
  // 作者
  author: {
    type: 'input',
    label: '作者',
    props: {
      placeholder: '请输入作者',
      type: 'textarea',
      autosize: true
    }
  },
  // 展示日期
  displayDate: {
    type: 'slot',
    label: '展示日期',
    rules: [{
      required: true,
      message: '展示日期不能为空',
      trigger: 'blur'
    }]
  },
  // 排序值
  sort: {
    type: 'inputNumber',
    label: '排序值',
    rules: [{
      required: true,
      message: '排序值不能为空',
      trigger: 'blur'
    }]
  },
  // 状态
  status: {
    type: 'select',
    label: '状态',
    options: [{
      label: '停用',
      value: 0
    }, {
      label: '启用',
      value: 1
    }],
    rules: [{
      required: true,
      message: '状态不能为空',
      trigger: 'blur'
    }]
  }
}; // 弹窗配置

export var dialogData = {
  // 弹窗状态
  dialogVisible: false,
  width: '70%',
  height: '600px',
  // title
  title: '新增',
  // 弹窗名字
  name: 'dialogData',
  Height: '',
  // 点击确定按钮，后返回回来的唯一参数
  type: '',
  id: '',
  // 是否显示底部按钮
  footActive: true
};