import ENUM from '@/maps/enum/enum';
import { getEnumList } from '@api/common/common';
import { listCacheOrg } from '@api/common/cache';
import { formulaDropDownList } from '@api/crm/commissionV3/commissionV3';
var VAL_WIDTH = '130px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '产品编码',
  valueKey: 'productId',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '产品名称',
  valueKey: 'productName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '计算公式',
  valueKey: 'formulaId',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  },
  method: formulaDropDownList,
  params: {}
}, {
  label: '比例编码',
  valueKey: 'ratioCode',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '比例名称',
  valueKey: 'ratioName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '状态',
  valueKey: 'enabled',
  value: '',
  type: 'select',
  options: [{
    value: '0',
    text: '停用'
  }, {
    value: '1',
    text: '启用'
  }],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '产品类型',
  valueKey: 'productType',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_0247
  }
}, {
  label: '目录1',
  valueKey: 'category1',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_0250
  }
}, {
  label: '目录2',
  valueKey: 'category2',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_0252
  }
}, {
  label: '目录3',
  valueKey: 'category3',
  value: '',
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_0253
  }
}, {
  label: '结算属性',
  valueKey: 'countType',
  value: [],
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_PRODUCT_COUNT_TYPE
  }
}, // {
//   label: '机构名称',
//   valueKey: 'orgCd',
//   value: '',
//   type: 'cascader',
//   method: listCacheOrg,
//   params: {},
//   options: [],
//   props: {
//     //expandTrigger: 'hover',
//     checkStrictly: true,
//     emitPath: false,
//     showAllLevels: false,
//     value: 'orgCd',
//     label: 'orgName',
//     children: 'children',
//     // multiple: true,
//   },
// },
{
  label: '创建时间',
  valueKey: 'createStartTime',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '20px',
  valueKey: 'createEndTime',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '更新时间',
  valueKey: 'updateStartTime',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '20px',
  valueKey: 'updateEndTime',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}];