var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tooltip",
        {
          attrs: { effect: "dark", content: "待处理消息", placement: "bottom" }
        },
        [
          _c(
            "el-badge",
            { staticClass: "message-wrapper", attrs: { value: _vm.sumData } },
            [
              _c(
                "el-button",
                {
                  staticClass: "d2-mr btn-text can-hover icon-message",
                  attrs: { type: "text" },
                  on: { click: _vm.clickShowRemind }
                },
                [_c("d2-icon", { attrs: { name: "comment-o" } })],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("dialog-remind", {
        attrs: { propsModel: _vm.tableData },
        model: {
          value: _vm.showRemindDialog,
          callback: function($$v) {
            _vm.showRemindDialog = $$v
          },
          expression: "showRemindDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }