import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AUDIT_PROCESS from '@/maps/enum/audit-process';
import COMMISSION_STATUS from '@/maps/enum/status/commission-status';
import COLOR from '@/maps/enum/color';
import { FILTER_PROPS, FILTER_FORMS } from './option/filter';
import TABLE_LIST from './option/list'; // 表头

import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import DialogAuditBatch from './components/dialog-audit-batch';
import DialogInfo from '../components/dialog-info';
import DialogAdd from './components/dialog-add.vue';
import { commissionListApplication, commissionExportExcel } from '@api/crm/commissionV3/commissionV3';
export default {
  name: 'commissionV3Count',
  created: function created() {},
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogAuditBatch: DialogAuditBatch,
    DialogInfo: DialogInfo,
    DialogAdd: DialogAdd
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      query: {},
      currentRow: {},
      // 当前点击行
      checkboxRows: [],
      // 复选框选中行
      table: {
        multiple: true
      },
      // 多选
      headers: TABLE_LIST,
      // 表头
      pagination: {},
      tableData: [],
      process: AUDIT_PROCESS.FINANCE.value,
      // 当前审核流程
      showAuditBatchDialog: false,
      // 批量审核窗口
      showDialogInfo: false,
      showDialogAdd: false,
      propsModelInfo: {},
      loadingBtu: false
    };
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    // 初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    clickEdit: function clickEdit(row) {
      var examine = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.propsModelInfo = row;

      if (examine) {
        this.showDialogInfo = true;
      } else {
        this.showDialogAdd = true;
      }
    },
    // 批量审核
    clickAuditBatch: function clickAuditBatch() {
      if (this.checkboxRows.length == 0) {
        this.$message('请选择合同');
      } else {
        this.showAuditBatchDialog = true;
      }
    },
    // 点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    // 点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();

      if (this.$refs.form.model.arrayProductCategory1 === '') {
        this.$refs.form.model.arrayProductCategory1 = [];
      }

      if (this.$refs.form.model.arrayProductCategory2 === '') {
        this.$refs.form.model.arrayProductCategory2 = [];
      }
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      var _this = this;

      this.initFilter();
      this.$nextTick(function () {
        _this.$refs.jTable.pageChange(1);
      });
    },
    // 导出
    clickExport: function clickExport() {
      var _this2 = this;

      this.$confirm('确定按查询条件导出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.initFilter();

        var param = {
          process: _this2.process,
          query: _this2.query
        };
        _this2.loadingBtu = true;
        commissionExportExcel(param).catch(function (e) {
          _this2.$message({
            message: e,
            type: 'error'
          });
        }).finally(function () {
          _this2.loadingBtu = false;
        });
      }).catch(function () {});
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    // 复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      this.checkboxRows = rows;
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this3 = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      var param = {
        process: this.process,
        page: page,
        query: this.query
      };
      this.loading = true;
      commissionListApplication(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this3.loading = false;
        _this3.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this3.tableData = data;
          _this3.pagination = {
            total: totalCount
          };
        } else {
          _this3.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this3.loading = false;

        _this3.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    setStatusColor: function setStatusColor(status) {
      // 合同状态颜色
      var className = '';

      switch (status) {
        case COMMISSION_STATUS.COUNT_AUDIT.value:
          className = COLOR.BLUE;
          break;

        case COMMISSION_STATUS.MONEY_AUDIT_FAIL.value:
          className = COLOR.RED;
          break;
      }

      return className;
    }
  }
};