





















































































































































































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/apply/money/components/dialog-audit.vue"
}
