import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import TABLE_LIST from "./option/list"; // 表头

import DynamicForm from "@/components/common/dynamic-form";
import DynamicTable from "@/components/common/dynamic-table";
import DialogAdd from "./components/dialog-add";
import { list, remove } from "@api/crm/setup/monthFloatRate";
export default {
  name: "monthFloatRate",
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogAdd: DialogAdd
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {},
      pagination: {},
      tableData: [],
      query: {},
      showAddDialog: false,
      // 展示新增/编辑弹窗
      currentRow: {} // 当前点击行

    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: "数据已刷新",
        type: "info",
        duration: 500
      });
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.loading = true;
      this.initFilter();
      list({
        page: page,
        query: this.query
      }).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.tableData = data;
          _this.pagination = {
            total: totalCount
          };
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    // TODO 点击新增
    clickEdit: function clickEdit() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currentRow = row;
      this.showAddDialog = true;
    },
    // TODO 点击删除
    clickRemove: function clickRemove() {
      var _this2 = this;

      if (Object.keys(this.currentRow).length === 0) {
        this.$message("请选择浮动收益率");
      } else {
        this.$confirm("确定删除当前浮动收益率吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          var monthFloatId = _this2.currentRow.monthFloatId;
          _this2.loading = true;
          remove({
            monthFloatId: monthFloatId
          }).then(function (res) {
            _this2.loading = false;

            _this2.init();
          }).catch(function (e) {
            _this2.loading = false;
          });
        }).catch(function () {});
      }
    }
  },
  created: function created() {}
};