//查询条件
var VAL_WIDTH = '150px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '礼品名称',
  valueKey: 'giftName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '价格始',
  valueKey: 'priceBegin',
  value: 0,
  type: 'number',
  props: {
    type: 'number',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'priceEnd',
  value: 0,
  type: 'number',
  props: {
    type: 'number',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '启用',
  valueKey: 'status',
  value: '1',
  type: 'select',
  options: [{
    value: '0',
    text: '关闭'
  }, {
    value: '1',
    text: '启用'
  }],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}];