var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tree",
    _vm._b(
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        ref: "tree",
        attrs: {
          data: _vm.treeData,
          "node-key": "orgCd",
          "highlight-current": "",
          "default-expanded-keys": _vm.expanded
        },
        on: { "node-click": _vm.handleNodeClick }
      },
      "el-tree",
      _vm.props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }