import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find-index.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import attachmentMap from "@/maps/url/crm/attachment/attachment";
import AttachmentUpload from "@/components/business/attachment/upload";
import AttachmentAudioPlayer from "@/components/business/attachment/audio-player";
import { list, remove, download } from "@api/crm/attachment/attachment";
export default {
  props: {
    isAttachmentEdit: {
      type: Boolean,
      default: false
    },
    attachmentContractId: {
      type: String,
      default: ""
    },
    attachmentType: {
      type: String,
      default: ""
    }
  },
  components: {
    AttachmentUpload: AttachmentUpload,
    AttachmentAudioPlayer: AttachmentAudioPlayer
  },
  data: function data() {
    return {
      loading: false,
      name: "attachment-table",
      showUploadDialog: false,
      // 展示上传对话框
      showAudioPlayerDialog: false,
      //打开音频播放器
      attachmentList: [],
      tableData: [],
      attachId: "",
      //指定附件ID
      attachName: "",
      //指定附件名称
      audio: {
        //录音播放参数
        url: "",
        //地址
        param: {},
        //参数
        audioName: "" //录音名称

      }
    };
  },
  watch: {
    attachmentContractId: {
      handler: function handler(val) {
        this.init();

        if (val != "") {
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.showUploadDialog = false;
      this.showAudioPlayerDialog = false;
      this.attachmentList = [];
      this.tableData = [];
      this.attachId = "";
      this.attachName = "";
    },
    loadData: function loadData() {
      var _this = this;

      if (this.attachmentContractId) {
        var param = {
          contractId: this.attachmentContractId,
          attachType: this.attachmentType
        };
        this.loading = true;
        list({
          query: param
        }).then(function (res) {
          _this.loading = false;

          if (res.data != null) {
            _this.attachmentList = [];

            if (res.data.length > 0) {
              var _iteratorNormalCompletion = true;
              var _didIteratorError = false;
              var _iteratorError = undefined;

              try {
                for (var _iterator = res.data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                  var item = _step.value;

                  _this.attachmentList.push({
                    attachId: item.attachId,
                    fileName: item.fileName,
                    fileType: item.fileType
                  });
                }
              } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                  }
                } finally {
                  if (_didIteratorError) {
                    throw _iteratorError;
                  }
                }
              }
            }
          } else {
            _this.$message({
              message: "暂无数据",
              type: "info"
            });
          }
        }).catch(function () {
          _this.loading = false;

          _this.$message({
            message: "查询列表数据出错",
            type: "error"
          });
        });
      }
    },
    clickUpload: function clickUpload() {
      this.showUploadDialog = true;
    },
    clickRemove: function clickRemove(row) {
      var _this2 = this;

      this.loading = true;
      remove({
        attachId: row.attachId
      }).then(function (res) {
        _this2.loading = false;

        _this2.attachmentList.splice(_this2.attachmentList.findIndex(function (v) {
          return v.attachId === row.attachId;
        }), 1);
      }).catch(function (e) {
        _this2.loading = false;
      });
    },
    clickDownload: function clickDownload(row) {
      var _this3 = this;

      //下载
      var param = {
        attachId: row.attachId,
        fileName: row.fileName + '.' + row.fileType
      };
      download(param).catch(function (e) {
        _this3.$message({
          message: e,
          type: "error"
        });
      });
    },
    //播放
    clickPlay: function clickPlay(row) {
      this.audio = {
        url: attachmentMap.open,
        param: {
          attachId: row.attachId
        },
        audioName: row.fileName
      };
      this.showAudioPlayerDialog = true;
    }
  },
  created: function created() {//this.init();
  }
};