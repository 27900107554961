import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import DialogApplication from "./dialog-application";
import DialogSummary from "../../dialog-summary";
import STATUS from "@/maps/enum/status/commission-batch";
import AUDIT from "@/maps/enum/status/audit";
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import { formatAmountToString } from "@/libs/crm/format";
import { listBatchDetail, removeDetailFromBatch, submit } from "@api/crm/commission/commissionAgency";
export default {
  name: "commission-agency-batch-detail",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    DialogApplication: DialogApplication,
    DialogSummary: DialogSummary
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tableData: [],
      tableDataSpecial: [],
      //特殊推介费
      showApplicationDialog: false,
      // 推介费申请窗口
      showSummaryDialog: false,
      checkboxRows: [],
      //复选框
      currentRow: {},
      // 当前点击行
      batchId: this.propsModel.batchId,
      canEditStatus: [STATUS.SAVE.value] //可以编辑的状态

    };
  },
  computed: {
    isEdit: function isEdit() {
      var isEdit = false;

      if (this.propsModel != null && this.canEditStatus.includes(String(this.propsModel.status))) {
        isEdit = true;
      }

      return isEdit;
    }
  },
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    loadData: function loadData() {
      var _this = this;

      var param = {
        query: {
          batchId: this.propsModel.batchId
        }
      };
      listBatchDetail(param, DATA_FORMAT_TYPE.AMOUNT).then(function (res) {
        _this.tableData = [];

        if (res.data != null) {
          res.data.forEach(function (n) {
            var bonus = n.listDetail[0].bonus == null ? 0 : parseFloat(n.listDetail[0].bonus);
            var deduct = n.listDetail[0].deduct == null ? 0 : parseFloat(n.listDetail[0].deduct);
            n.listDetail[0].commission = formatAmountToString(bonus + deduct); //实发金额

            n.listDetail[0].bonus = formatAmountToString(n.listDetail[0].bonus);
            n.listDetail[0].deduct = formatAmountToString(n.listDetail[0].deduct);
          });
          _this.tableData = res.data;
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    handleCommand: function handleCommand(command) {
      if (command == "add") {
        //并入明细
        this.clickAddApplication();
      } else if (command == "remove") {
        //移除明细
        this.clickRemoveApplication();
      }
    },
    //删除申请
    clickRemoveApplication: function clickRemoveApplication() {
      var _this2 = this;

      if (this.checkboxRows.length == 0) {
        this.$message("请选择合同");
      } else {
        this.$confirm("移除明细后将退回推介费申请明细列表，确定要移除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          var param = {
            arrayDetailId: []
          };

          _this2.checkboxRows.forEach(function (item) {
            param.arrayDetailId.push(item.detailId);
          });

          removeDetailFromBatch(param).then(function (res) {
            _this2.$message({
              message: "已移除",
              type: "success"
            });

            _this2.init();
          });
        }).catch(function () {});
      }
    },
    //添加明细到台帐
    clickAddApplication: function clickAddApplication() {
      this.batchId = this.propsModel.batchId;
      this.currentRow = this.propsModel;
      this.showApplicationDialog = true;
    },
    //汇总报表
    showSummary: function showSummary() {
      this.batchId = this.propsModel.batchId;
      this.showSummaryDialog = true;
    },
    //复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      this.checkboxRows = rows;
    },
    clickSubmit: function clickSubmit() {
      var _this3 = this;

      this.$confirm("确定台帐提交审核吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var param = {
          batchId: _this3.propsModel.batchId,
          audit: AUDIT.YES.value
        };
        submit(param).then(function (res) {
          _this3.loading = false;
          _this3.showDialog = false;

          _this3.$emit("refresh");
        }).catch(function (e) {
          _this3.loading = false;
        });
      }).catch(function () {});
    }
  }
};