




























































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/service/attachment/download/index.vue"
}
