import "core-js/modules/es.object.to-string.js";
// 客户银行卡信息
import request from '@/plugin/axios';
import func from '@/maps/url/crm/customer/bankCard';
import util from '@/libs/util';
import { blobRequest } from '@/plugin/axios';
import { formatUserDataPromise, formatDate } from '@/libs/crm/format'; // TODO 列表

export function listCustomerBankCard(params, formatType) {
  var re = request.post(func.listCustomerBankCard, {
    body: params
  });
  formatUserDataPromise(re, formatType); // 用户数据格式化

  return re;
} // TODO 详情

export function getCustomerBankCard(params) {
  var re = request.post(func.getCustomerBankCard, {
    body: params
  });
  return re;
} // TODO 更新详情

export function updateCustomerBankCard(params) {
  var re = request.post(func.updateCustomerBankCard, {
    body: params
  });
  return re;
} // TODO 3要素验证

export function checkBankCard3c(params) {
  var re = request.post(func.checkBankCard3c, {
    body: params
  });
  return re;
} // TODO 获取银行卡验证信息

export function getCheckBankCardInfo(params) {
  var re = request.post(func.getCheckBankCardInfo, {
    body: params
  });
  return re;
} // TODO 更新银行卡详情

export function updateBankInfo(params) {
  var re = request.post(func.updateBankInfo, {
    body: params
  });
  return re;
} // TODO 编辑

export function update(params) {
  var re = request.post(func.update, {
    body: params
  });
  return re;
} // TODO 创建

export function create(params) {
  var re = request.post(func.create, {
    body: params
  });
  return re;
} // TODO 创建

export function remove(params) {
  var re = request.post(func.remove, {
    body: params
  });
  return re;
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '开户行信息' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 导入Excel流水

export function importExcel(params) {
  return request.post(func.importExcel, params);
} // TODO 获取预期银行卡信息

export function getExpectInfo(params) {
  var re = request.post(func.getExpectInfo, {
    body: params
  });
  return re;
} // TODO 保存

export function bankCardInfoSave(params) {
  var re = request.post(func.bankCardInfoSave, {
    body: params
  });
  return re;
} // TODO 导入结果列表

export function bankCardList(params) {
  var re = request.post(func.bankCardList, {
    body: params
  });
  return re;
}