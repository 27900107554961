import CONTRACT_STATUS from '@/maps/enum/status/buy';
import COMMISSION_STATUS from '@/maps/enum/status/commission-status';
import { arrayTitleId } from '@/maps/enum/status/commissionV3';
import COMMISSION_TYPE from '@/maps/enum/commission-type';
import { listCacheOrg, listCacheProduct } from "@api/common/cache";
import { getEnumList } from '@api/common/common';
var VAL_WIDTH = '130px';
var isBackOfficeData = [{
  text: '是',
  value: '1'
}, {
  text: '否',
  value: '0'
}];
var isHalfMonth = [{
  text: '上半月',
  value: '上半月'
}, {
  text: '下半月',
  value: '下半月'
}];
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '合同编号',
  valueKey: 'contractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '客户',
  valueKey: 'customerName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '产品',
  valueKey: 'arrayProductId',
  value: [],
  type: 'select',
  method: listCacheProduct,
  params: {},
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '机构名称',
  valueKey: 'orgCd',
  value: '',
  type: 'cascader',
  method: listCacheOrg,
  params: {},
  options: [],
  props: {
    //expandTrigger: 'hover',
    checkStrictly: true,
    emitPath: false,
    showAllLevels: false,
    value: 'orgCd',
    label: 'orgName',
    children: 'children'
  }
}, {
  label: '合同状态',
  valueKey: 'arrayContractState',
  value: [],
  type: 'select',
  options: filterContractStatus(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '推介费类型',
  valueKey: 'arrayType',
  value: [],
  type: 'select',
  options: filterCommissionType(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '运营年度',
  valueKey: 'fiscalYear',
  value: '',
  type: 'date',
  props: {
    type: 'year',
    valueFormat: 'yyyy',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '推介费状态',
  valueKey: 'arrayStatus',
  value: [],
  type: 'select',
  options: filterCommissionStatus(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '是否后勤人员',
  valueKey: 'isBackOffice',
  value: '',
  type: 'select',
  options: isBackOfficeData,
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '开始日期',
  valueKey: 'startDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'startDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '结束日期',
  valueKey: 'endDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'endDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '合同期限',
  valueKey: 'arrayTimeLimit',
  value: [],
  type: 'select',
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: '0201'
  },
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '返还周期',
  valueKey: 'arrayReturnPeriod',
  value: [],
  type: 'select',
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: '0202'
  },
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '姓名',
  valueKey: 'bonusUserName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '职级',
  valueKey: 'arrayTitleId',
  value: [],
  type: 'select',
  options: filterCommissionRank(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '推介费结算月份',
  valueKey: 'planDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'month',
    valueFormat: 'yyyy-MM',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'planDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'month',
    valueFormat: 'yyyy-MM',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '推介费日期',
  valueKey: 'halfMonth',
  value: '',
  type: 'select',
  options: isHalfMonth,
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}]; //合同状态

function filterContractStatus() {
  var array = [CONTRACT_STATUS.SAVE, CONTRACT_STATUS.SUBMIT, CONTRACT_STATUS.AUDIT, CONTRACT_STATUS.COUNT_AUDIT, CONTRACT_STATUS.DEAL, CONTRACT_STATUS.SUSPEND, CONTRACT_STATUS.PAYED, CONTRACT_STATUS.AUDIT_FAIL, CONTRACT_STATUS.DEAL_FAIL, CONTRACT_STATUS.COUNT_AUDIT_FAIL, CONTRACT_STATUS.RESERVE_BUY, CONTRACT_STATUS.RESERVE_BUY_FAIL, CONTRACT_STATUS.CLEAR_SUSPEND_SERVICE, CONTRACT_STATUS.CLEAR_SUSPEND_COUNT_FAIL, CONTRACT_STATUS.CLEAR_PAYED_SERVICE, CONTRACT_STATUS.CLEAR_PAYED_COUNT_FAIL, CONTRACT_STATUS.DEAL_RETURN];
  return array;
}
/**
 * 推介费状态
 * @returns 
 */


function filterCommissionStatus() {
  var array = [];

  for (var key in COMMISSION_STATUS) {
    if (COMMISSION_STATUS[key].value !== '-99') {
      array.push(COMMISSION_STATUS[key]);
    }
  }

  return array;
}
/**
 * 推介费类型
 * @returns 
 */


function filterCommissionType() {
  var array = [];

  for (var key in COMMISSION_TYPE) {
    array.push(COMMISSION_TYPE[key]);
  }

  return array;
}
/**
 * 职级
 * @returns 
 */


function filterCommissionRank() {
  var array = [];

  for (var key in arrayTitleId) {
    array.push(arrayTitleId[key]);
  }

  return array;
}