





























































































































export default function (Component) {
  Component.options.__source = "src/views/crm/advertising/position/index.vue"
}
