//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '合同柜名',
  prop: 'boxName',
  width: '100px'
}, {
  label: '备注',
  prop: 'remark',
  width: '250px'
}, {
  label: '容量',
  prop: 'capcity',
  width: '100px',
  align: 'right'
}, {
  label: '已存合同数',
  prop: 'countContract',
  width: '100px',
  align: 'right'
}, {
  label: '剩余空间',
  prop: 'left',
  width: '100px',
  align: 'right'
}, {
  label: '上次归档时间',
  prop: 'archiveDate',
  width: '110px'
}];