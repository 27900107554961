import { Message } from 'element-ui';
/**
 * 使用成功提示
 * @param message 提示消息
 */

export var useSuccessTip = function useSuccessTip() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '操作成功';
  Message({
    message: message,
    type: 'success'
  });
};
export var useWarningTip = function useWarningTip() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '警告提示';
  Message({
    message: message,
    type: 'warning'
  });
};
export var useErrorTip = function useErrorTip() {
  var message = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '错误提示';
  Message({
    message: message,
    type: 'error'
  });
};