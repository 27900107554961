// 合同认购 业绩
export default {
  conzrzachievementList: '/conzrzachievement/list',
  // 责任状业绩_列表
  conzrzachievementAdd: '/conzrzachievement/add',
  // 责任状业绩_新增
  conzrzachievementUpdate: '/conzrzachievement/update',
  // 责任状业绩_编辑
  conzrzachievementRemove: '/conzrzachievement/remove',
  // 责任状业绩_删除
  conzrzachievementGetConInfo: '/conzrzachievement/getConInfo',
  // 责任状业绩_新增时的页面信息
  conzrzachievementGetAchievementInfo: '/conzrzachievement/getAchievementInfo',
  // 责任状业绩_编辑时的页面信息
  conjbfachievementList: '/conjbfachievement/list',
  // 基本法业绩_列表
  conjbfachievementAdd: '/conjbfachievement/add',
  // 基本法业绩_新增
  conjbfachievementUpdate: '/conjbfachievement/update',
  // 基本法业绩_编辑
  conjbfachievementRemove: '/conjbfachievement/remove',
  // 基本法业绩_删除
  conjbfachievementGetConInfo: '/conjbfachievement/getConInfo',
  // 基本法业绩_新增时的页面信息
  conjbfachievementGetAchievementInfo: '/conjbfachievement/getAchievementInfo' // 基本法业绩_编辑时的页面信息

};