


























































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/buy/reserve/index.vue"
}
