//产品结算类型
export default {
  SAME_DATE_GOLD: {
    value: '1',
    text: '对日到期（黄金类）'
  },
  FIXED_DATE_GOLD: {
    value: '2',
    text: '固定日到期（黄金类）'
  },
  FIXED_DATE: {
    value: '3',
    text: '固定日到期（非黄金类）'
  },
  OTHER: {
    value: '4',
    text: '其他'
  }
};