
















































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/gift/apply/finance/index.vue"
}
