
































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/openingBank/messageSearch/index.vue"
}
