import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import { batchAdd, editBatch } from '@api/crm/bank/bill';
import { getEnumList } from '@api/common/common';
import { formatObjectAmountToNumber } from '@/libs/crm/format';
export default {
  name: 'bank-bill-add-js',
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    kindValue: {
      type: String,
      default: ''
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      formModel: {},
      // 表单值
      selectData: {},
      rules: {
        accountName: [{
          required: true,
          message: '请填写帐户名',
          trigger: 'blur'
        }],
        bankAccount: [{
          required: true,
          message: '请填写银行帐号',
          trigger: 'blur'
        }],
        tallyDate: [{
          required: true,
          message: '请填写交易日期',
          trigger: 'blur'
        }],
        kind: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }] // waterNum: [{ required: true, message: '请填写银行流水', trigger: 'blur' }],
        // debtor: [{ required: true, message: '请填写金额', trigger: 'blur' }],
        // amount: [{ required: true, message: '请填写金额', trigger: 'blur' }],

      }
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    // 获取银行/ 公司
    getEnumList({
      enumCd: 'bankBillKind'
    }).then(function (res) {
      _this.selectData.accountStatementKind = res;
    }); // 交易类型

    getEnumList({
      enumCd: '0237'
    }).then(function (res) {
      _this.selectData.tradeTypeList = res;
    });
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();

          if (this.isEdit) {
            this.getinfo();
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false; // 取值默认银行

      if (this.kindValue) {
        this.$set(this.formModel, 'kind', this.kindValue);
      }
    },
    getinfo: function getinfo() {
      this.formModel = _objectSpread({}, this.propsModel);
      formatObjectAmountToNumber(this.formModel);
    },
    // TODO 取消
    cancel: function cancel() {
      this.showDialog = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.formModel = {};
      this.$refs.form.resetFields();
    },
    // TODO 确定
    confirm: function confirm() {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var arrayObject = [];
          arrayObject[0] = _objectSpread(_objectSpread({}, _this2.formModel), {}, {
            type: '1'
          });
          var param = {
            arrayObject: arrayObject
          };
          _this2.loading = true;

          if (_this2.isEdit) {
            editBatch(param).then(function (res) {
              _this2.loading = false;
              _this2.showDialog = false;

              _this2.$message({
                message: '编辑成功',
                type: 'success'
              });

              _this2.$emit('refresh');
            }).catch(function (e) {
              _this2.loading = false;
            });
          } else {
            batchAdd(param).then(function (res) {
              _this2.loading = false;
              _this2.showDialog = false;

              _this2.$message({
                message: '新增成功',
                type: 'success'
              });

              _this2.$emit('refresh');
            }).catch(function (e) {
              _this2.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    }
  }
};