import "core-js/modules/es.object.to-string.js";
// 赎回
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatUserDataPromise, formatDate } from '@/libs/crm/format';
import func from '@/maps/url/crm/apply/apply'; // TODO 财务总监审核

export function auditFinanceManager(params) {
  var re = request.post(func.auditFinanceManager, {
    body: params
  });
  return re;
} // TODO 资金审核

export function auditMoney(params) {
  var re = request.post(func.auditMoney, {
    body: params
  });
  return re;
} // TODO 列表

export function list(params, formatType) {
  var re = request.post(func.list, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 详情

export function getInfo(params, formatType) {
  var re = request.post(func.getInfo, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 列表

export function listPrint(params, formatType) {
  var re = request.post(func.listPrint, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 批量打印确认

export function printConfirm(params) {
  var re = request.post(func.printConfirm, {
    body: params
  });
  return re;
} // TODO 批量发货确认

export function jewelryOutConfirm(params) {
  var re = request.post(func.jewelryOutConfirm, {
    body: params
  });
  return re;
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '赎回' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 导出出金Excel

export function exportExcelBill(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelBill, {
    body: params
  }).then(function (blob) {
    util.download(blob, '赎回付款清单' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 导出付款清单Excel

export function exportExcelPayment(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelPayment, {
    body: params
  }).then(function (blob) {
    util.download(blob, '赎回付款清单' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 创建

export function create(params) {
  var re = request.post(func.create, {
    body: params
  });
  return re;
} // TODO 删除

export function remove(params) {
  var re = request.post(func.remove, {
    body: params
  });
  return re;
} // TODO 确认发货

export function sendJewelryOut(params) {
  var re = request.post(func.sendJewelryOut, {
    body: params
  });
  return re;
} // TODO 保存发货

export function saveJewelryOut(params) {
  var re = request.post(func.saveJewelryOut, {
    body: params
  });
  return re;
} // TODO 确认收货

export function receiveJewelryOut(params) {
  var re = request.post(func.receiveJewelryOut, {
    body: params
  });
  return re;
} // TODO 发货列表

export function listJewelryOut(params) {
  var re = request.post(func.listJewelryOut, {
    body: params
  });
  return re;
} // TODO 发货详情

export function getInfoJewelryOut(params) {
  var re = request.post(func.getInfoJewelryOut, {
    body: params
  });
  return re;
}