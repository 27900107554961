var operationAnalysisH5Url = {
  operationAnalysisH5: {
    /* 团队 个人业绩目标 */
    managementByObjectives: {
      /* 查询团队业绩目标 */
      getGoalList: 'manage/goal/org/getGoalList',

      /* 编辑团队月度业绩目标 */
      updateGoal: '/manage/goal/org/updateGoal',

      /* 导入团队月度业绩目标 */
      importGoal: '/manage/goal/org/importGoal',

      /* 导出团队月度业绩目标 */
      exportGoal: '/manage/goal/org/exportGoal',

      /* 下载团队业绩目标导出模板 */
      getGoalImportTemplate: '/manage/goal/org/getGoalImportTemplate',
      // 个人业绩目标 

      /* 分页查询个人业绩目标列 */
      getGoalPage: '/manage/goal/personal/getGoalPage',

      /* 编辑个人月度业绩目标 */
      personalUpdateGoal: '/manage/goal/personal/updateGoal',

      /* 下载个人业绩目标导出模板 */
      personalGetGoalImportTemplate: '/manage/goal/personal/getGoalImportTemplate',

      /* 导入个人月度业绩目标 */
      personalImportGoal: '/manage/goal/personal/importGoal',

      /* 导出个人月度业绩目标 */
      personalExportGoal: '/manage/goal/personal/exportGoal'
    }
  }
};
export default operationAnalysisH5Url;