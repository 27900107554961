

































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/marketingCampaign/manage/add/index.vue"
}
