// 弹窗中form表单配置
export var DIALOG_FORM_CONFIG = {
  roleId: {
    hidden: true
  },
  userId: {
    hidden: true
  },
  type: {
    hidden: true,
    value: 1
  },
  name: {
    col: 12,
    type: 'checkbox',
    options: [{
      label: '姓名'
    }]
  },
  identification: {
    col: 12,
    type: 'checkbox',
    options: [{
      label: '身份证号'
    }]
  },
  mobile: {
    col: 12,
    type: 'checkbox',
    options: [{
      label: '手机号'
    }]
  },
  accountNo: {
    col: 12,
    type: 'checkbox',
    options: [{
      label: '银行卡号'
    }]
  },
  email: {
    col: 12,
    type: 'checkbox',
    options: [{
      label: 'Email'
    }]
  },
  address: {
    col: 12,
    type: 'checkbox',
    options: [{
      label: '地址'
    }]
  } // export: {
  //     col: 12,
  //     type: 'checkbox',
  //     options: [{
  //         label: '导出',
  //     }, ]
  // },

};
export var DIALOG_FORM_CONFIG1 = {
  roleId: {
    hidden: true
  },
  userId: {
    hidden: true
  },
  type: {
    hidden: true,
    value: 1
  },
  name: {
    col: 12,
    type: 'checkbox',
    options: [{
      label: '姓名',
      value: 1
    }]
  },
  identification: {
    col: 12,
    type: 'checkbox',
    options: [{
      label: '身份证号'
    }]
  },
  mobile: {
    col: 12,
    type: 'checkbox',
    options: [{
      label: '手机号'
    }]
  },
  accountNo: {
    col: 12,
    type: 'checkbox',
    options: [{
      label: '银行卡号'
    }]
  },
  email: {
    col: 12,
    type: 'checkbox',
    options: [{
      label: 'Email'
    }]
  },
  address: {
    col: 12,
    type: 'checkbox',
    options: [{
      label: '地址'
    }]
  }
  /*
  export: {
      col: 12,
      type: 'checkbox',
      options: [{
          label: '导出',
      }, ]
  },
  */

};
export var DIALOG_FORM_DATA = {
  name: true,
  identification: true,
  mobile: true,
  accountNo: true,
  email: true,
  address: true,
  export: true
};
export var DIALOG_FORM_DATA1 = {
  name: true,
  identification: true,
  mobile: true,
  address: true,
  email: true,
  accountNo: true //export: false,

};