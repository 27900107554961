import _toConsumableArray from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _typeof from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.number.is-finite.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.max-safe-integer.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import util from "@/libs/util";

function realFormatSecond(second) {
  var secondType = _typeof(second);

  if (secondType === "number" || secondType === "string") {
    second = parseInt(second);
    var hours = Math.floor(second / 3600);
    second = second - hours * 3600;
    var mimute = Math.floor(second / 60);
    second = second - mimute * 60;
    return hours + ":" + ("0" + mimute).slice(-2) + ":" + ("0" + second).slice(-2);
  } else {
    return "00:00:00";
  }
}

export default {
  props: {
    url: {
      type: String,
      default: ""
    },
    param: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    audioName: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      loading: false,
      name: "attachment-audio-player",
      audioUrl: "",
      theControlList: "",
      sliderTime: 0,
      volume: 100,
      speeds: [1, 1.5, 2],
      playIcon: "el-icon-video-play",
      //播放/暂停图标
      voiceIcon: "el-icon-bell",
      //静音/放音图标
      isDraging: false,
      //是否拖动
      audio: {
        currentTime: 0,
        maxTime: 0,
        playing: false,
        muted: false,
        speed: 1,
        waiting: true,
        preload: "auto"
      },
      controlList: {
        // 不显示下载
        noDownload: false,
        // 不显示静音
        noMuted: false,
        // 不显示音量条
        noVolume: false,
        // 不显示进度条
        noProcess: false,
        // 只能播放一个
        onlyOnePlaying: false,
        // 不要快进按钮
        noSpeed: false
      }
    };
  },
  mixins: [dialogMixin],
  showDialog: {
    handler: function handler(val) {},
    immediate: true
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val != "") {
          this.init();
          this.readly();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.audioUrl = null;
      this.sliderTime = 0;
      this.audio.currentTime = 0;
      this.audio.maxTime = 0;
    },
    dialogClose: function dialogClose() {
      this.init();
      this.pausePlay();
    },
    readly: function readly() {
      var param = {
        body: this.param
      };
      this.audioUrl = process.env.VUE_APP_API + this.url + "?X-Token=" + util.cookies.get("token") + "&data=" + JSON.stringify(param);
    },
    setControlList: function setControlList() {
      var _this = this;

      var controlList = this.theControlList.split(" ");
      controlList.forEach(function (item) {
        if (_this.controlList[item] !== undefined) {
          _this.controlList[item] = true;
        }
      });
    },
    changeSpeed: function changeSpeed() {
      var index = this.speeds.indexOf(this.audio.speed) + 1;
      this.audio.speed = this.speeds[index % this.speeds.length];
      this.$refs.audio.playbackRate = this.audio.speed;
    },
    startMutedOrNot: function startMutedOrNot() {
      this.$refs.audio.muted = !this.$refs.audio.muted;
      this.audio.muted = this.$refs.audio.muted;

      if (!this.audio.muted) {
        this.voiceIcon = "el-icon-bell";
      } else {
        this.voiceIcon = "el-icon-close-notification";
      }
    },
    // 音量条toolTip
    formatVolumeToolTip: function formatVolumeToolTip(index) {
      return "音量: " + index;
    },
    // 进度条toolTip
    formatProcessToolTip: function formatProcessToolTip() {
      var index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      index = parseInt(this.audio.maxTime / 100 * index);
      return "进度: " + realFormatSecond(index);
    },
    // 音量改变
    changeVolume: function changeVolume() {
      var index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      this.$refs.audio.volume = index / 100;
      this.volume = index;
    },
    // 播放跳转
    changeCurrentTime: function changeCurrentTime(index) {
      this.isDraging = false;
      this.$refs.audio.currentTime = parseInt(index / 100 * this.audio.maxTime);
    },
    startPlayOrPause: function startPlayOrPause() {
      return this.audio.playing ? this.pausePlay() : this.startPlay();
    },
    // 开始播放
    startPlay: function startPlay() {
      this.playIcon = "el-icon-video-pause";
      this.$refs.audio.play();
    },
    // 暂停
    pausePlay: function pausePlay() {
      this.playIcon = "el-icon-video-play";
      this.$refs.audio.pause();
    },
    // 当音频暂停
    onPause: function onPause() {
      this.audio.playing = false;
    },
    // 当发生错误, 就出现loading状态
    onError: function onError() {
      this.audio.waiting = true;
    },
    // 当音频开始等待
    onWaiting: function onWaiting(res) {},
    // 当音频开始播放
    onPlay: function onPlay(res) {
      this.audio.playing = true;
      this.audio.loading = false;

      if (!this.controlList.onlyOnePlaying) {
        return;
      }

      var target = res.target;
      var audios = document.getElementsByTagName("audio");

      _toConsumableArray(audios).forEach(function (item) {
        if (item !== target) {
          item.pause();
        }
      });
    },
    // 当timeupdate事件大概每秒一次，用来更新音频流的当前播放时间
    onTimeupdate: function onTimeupdate(res) {
      if (!Number.isFinite(res.target.duration)) {
        this.audio.currentTime = Number.MAX_SAFE_INTEGER;
        this.audio.currentTime = 0;
        this.audio.maxTime = 0;
      } else {
        if (!this.isDraging) {
          this.audio.currentTime = res.target.currentTime;
          this.sliderTime = parseInt(this.audio.currentTime / this.audio.maxTime * 100);
        }
      }
    },
    // 当加载语音流元数据完成后，会触发该事件的回调函数
    // 语音元数据主要是语音的长度之类的数据
    onLoadedmetadata: function onLoadedmetadata(res) {
      this.audio.waiting = false;
      var duration = res.target.duration;

      if (duration === Infinity) {
        this.audio.maxTime = 0;
      } else {
        this.audio.maxTime = parseInt(duration);
      }
    },
    //拖放
    onDraging: function onDraging() {
      this.isDraging = true;
    }
  },
  filters: {
    formatSecond: function formatSecond() {
      var second = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      return realFormatSecond(second);
    },
    transPlayPause: function transPlayPause(value) {
      return value ? "暂停" : "播放";
    },
    transMutedOrNot: function transMutedOrNot(value) {
      return value ? "放音" : "静音";
    },
    transSpeed: function transSpeed(value) {
      return "快进: x" + value;
    }
  },
  created: function created() {
    this.setControlList();
  }
};