//
//
//
//
//
//
//
//
import D2PageCover from './components/d2-page-cover';
export default {
  components: {
    D2PageCover: D2PageCover
  }
};