import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import OPERATE from "@/maps/enum/operate";
import { listTeam, listOrgPerson, applyLog } from "@api/rm/team";
export default {
  name: "team-apply",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      teamId: "",
      //团队Id
      teamName: "",
      //团队名称
      teamManager: "",
      //团队总监
      teamPerson: [],
      //团队成员
      orgPerson: [],
      //公司全体成员
      selectData: {
        team: [],
        //团队
        manage: [],
        //可选总监列表
        person: [] //可选成员列表

      },
      rules: {
        name: [{
          required: true,
          message: "请填写团队名称",
          trigger: "input"
        }]
      }
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadTeam();
          this.loadPerson();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.formModel = {};
      this.teamId = "";
      this.teamName = "";
      this.teamManager = "";
      this.teamPerson = [];
      this.selectData = {
        team: [],
        //团队
        manage: [],
        //可选总监列表
        person: [] //可选成员列表

      };
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    loadTeam: function loadTeam() {
      var _this = this;

      this.loading = true;
      var param = {
        page: {
          pageNumber: 1,
          pageSize: 999
        },
        query: {
          level: "3"
        }
      };
      listTeam(param).then(function (res) {
        _this.loading = false;
        _this.selectData.team = res.data;
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询团队列表出错",
          type: "error"
        });
      });
    },
    // TODO 获取列表
    loadPerson: function loadPerson() {
      var _this2 = this;

      this.loading = true;
      listOrgPerson().then(function (res) {
        _this2.loading = false;

        if (res.data != null) {
          _this2.orgPerson = res.data;

          _this2.filterTeamPerson();
        }
      }).catch(function (e) {
        _this2.loading = false;

        _this2.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    //选择团队
    changeTeam: function changeTeam(teamId) {
      var n = {}; //原下拉框选项

      n = this.selectData.team.find(function (item) {
        return item.id === teamId;
      });

      if (n != null) {
        //选择原团队变更
        this.teamId = n.id;
        this.teamName = n.name;
        this.formModel.description = n.description;
      }

      this.teamManager = "";
      this.teamPerson = [];
      this.filterTeamPerson(this.teamId);
    },
    //清除团队
    clearTeam: function clearTeam() {
      this.formModel = {};
      this.teamId = "";
      this.teamName = "";
      this.teamManager = "";
      this.teamPerson = [];
      this.filterTeamPerson();
    },
    //筛选团队成员
    filterTeamPerson: function filterTeamPerson(teamId) {
      //标记和去掉已加入其他团队成员
      this.selectData.manage = [];
      this.selectData.person = [];

      if (teamId == null || teamId == "") {
        //新增团队         
        for (var i = 0; i < this.orgPerson.length; i++) {
          var n = this.orgPerson[i];

          if (n.teamId == null || n.teamId == "") {
            //未加入其他队的
            this.selectData.manage.push(n);
            this.selectData.person.push(n);
          }
        }
      } else {
        //编辑团队
        for (var _i = 0; _i < this.orgPerson.length; _i++) {
          var _n = this.orgPerson[_i];

          if (_n.teamId == null || _n.teamId == teamId) {
            //未加入团队和本团队的人员
            if (teamId != null && _n.teamId == teamId) {
              //标记本团队的总监和成员
              if (_n.areaTitle == "1") {
                this.teamManager = _n.personuuid;
              } else {
                this.teamPerson.push(_n.personuuid);
              }
            }

            this.selectData.manage.push(_n);
            this.selectData.person.push(_n);
          }
        }
      }
    },
    // TODO 确定
    confirm: function confirm() {
      var _this3 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this3.teamName == "" && _this3.formModel.newTeamName == "") {
            _this3.$message({
              message: "请填写团队名称或选择现有团队",
              type: "warning"
            });
          } else if (_this3.teamManager == "") {
            _this3.$message({
              message: "请选择团队总监人选",
              type: "warning"
            });
          } else if (_this3.teamPerson.length == 0) {
            _this3.$message({
              message: "请选择团队成员人选",
              type: "warning"
            });
          } else {
            var action = null; //操作

            var actionDesc = null; //操作描述
            //成员列表中，去除已选为总监人员，避免保存人员重复

            for (var i = 0; i < _this3.teamPerson.length; i++) {
              if (_this3.teamPerson[i] == _this3.teamManager) {
                _this3.teamPerson.splice(i, 1);
              }
            }

            if (_this3.teamId == null || _this3.teamId == "") {
              action = OPERATE.ADD;
              _this3.teamName = _this3.formModel.newTeamName;
              actionDesc = _this3.applyDescNew();
            } else {
              action = OPERATE.CHANGE;

              if (_this3.formModel.newTeamName != null && _this3.formModel.newTeamName != "") {
                //如果变更团队名称
                _this3.teamName = _this3.formModel.newTeamName;
              }

              actionDesc = _this3.applyDescUpdate();
            }

            var actionParam = {
              action: action,
              //动作(add/change/remove)              
              teamName: _this3.teamName,
              //团队名称
              manager: _this3.teamManager,
              //主管
              person: _this3.teamPerson,
              //成员
              description: _this3.formModel.description //团队描述

            }; //动作

            var param = {
              teamId: _this3.teamId,
              applyDesc: actionDesc,
              applyAction: JSON.stringify(actionParam)
            };
            applyLog({
              object: param
            }).then(function (res) {
              _this3.loading = false;
              _this3.showDialog = false;

              _this3.$emit("refresh");
            }).catch(function (e) {
              _this3.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    //新增团队操作描述
    applyDescNew: function applyDescNew() {
      var desc = "";
      var manageDesc = "";
      var personDesc = "";

      for (var i = 0; i < this.selectData.person.length; i++) {
        var n = this.selectData.person[i];

        if (n.personuuid == this.teamManager) {
          //总监
          manageDesc = n.personName;
          break;
        }
      }

      for (var _i2 = 0; _i2 < this.teamPerson.length; _i2++) {
        //团队成员
        for (var j = 0; j < this.selectData.person.length; j++) {
          var _n2 = this.selectData.person[j];

          if (_n2.personuuid == this.teamPerson[_i2]) {
            personDesc += _n2.personName + ",";
            break;
          }
        }
      }

      desc += "新增团队: " + this.teamName + "; ";
      desc += "团队总监: " + manageDesc + "; ";
      desc += "团队成员: " + personDesc + "; ";
      return desc;
    },
    //更新团队操作描述
    applyDescUpdate: function applyDescUpdate() {
      var _this4 = this;

      var desc = "";
      var oldManager = null; //原总监

      var oldPerson = []; //原成员

      var newManager = null; //新总监

      var newPerson = []; //新成员
      //检索新老总监人员

      for (var i = 0; i < this.selectData.manage.length; i++) {
        var n = this.selectData.person[i];

        if (n.areaTitle == "1") {
          oldManager = n;
        }

        if (n.personuuid == this.teamManager) {
          newManager = n;
        }
      } //检索新老成员列表


      for (var _i3 = 0; _i3 < this.selectData.person.length; _i3++) {
        var _n3 = this.selectData.person[_i3];

        if (_n3.teamId != null && _n3.areaTitle != "1") {
          oldPerson.push(_n3);
        }

        for (var j = 0; j < this.teamPerson.length; j++) {
          if (_n3.personuuid == this.teamPerson[j]) {
            newPerson.push(_n3);
            break;
          }
        }
      }

      if (this.formModel.newTeamName != null && this.teamName != this.formModel.newTeamName) {
        desc += "变更团队: 团队名 " + this.teamName + " 变更为 " + this.formModel.newTeamName + "; ";
      } else {
        desc += "变更团队: " + this.teamName + "; ";
      }

      if (oldManager != null && newManager != null && oldManager.personuuid != newManager.personuuid) {
        desc += "总监由 " + oldManager.personName + " 变更为 " + newManager.personName + "; ";
      }

      desc += "成员：";

      for (var _i4 = 0; _i4 < newPerson.length; _i4++) {
        desc += newPerson[_i4].personName + ",";
      } //检查成员是否新增人员


      var newPersonName = ""; //新增成员名

      var removePersonName = ""; //移除成员名

      for (var _i5 = 0; _i5 < newPerson.length; _i5++) {
        var isNew = true; //是否新增成员

        for (var _j = 0; _j < oldPerson.length; _j++) {
          if (newPerson[_i5].personuuid == oldPerson[_j].personuuid) {
            isNew = false;
            break;
          }
        }

        if (isNew) {
          newPersonName += newPerson[_i5].personName + ",";
        }
      } //检查是否移除成员


      for (var _i6 = 0; _i6 < oldPerson.length; _i6++) {
        var isRemove = true;

        for (var _j2 = 0; _j2 < newPerson.length; _j2++) {
          if (oldPerson[_i6].personuuid == newPerson[_j2].personuuid) {
            isRemove = false;
            break;
          }
        }

        if (isRemove) {
          removePersonName += oldPerson[_i6].personName + ",";
        }
      }

      if (newPersonName != "") {
        desc += "新增成员: " + newPersonName + "; ";
      }

      if (removePersonName != "") {
        desc += "移除成员: " + removePersonName + "; ";
      } //检查团队描述是否变化


      var team = {}; //原下拉框选项

      team = this.selectData.team.find(function (item) {
        return item.id === _this4.teamId;
      });

      if (team.description != this.formModel.description) {
        desc += "团队描述由：" + team.description + " 变更为 " + this.formModel.description + "; ";
      }

      return desc;
    }
  }
};