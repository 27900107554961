//查询条件
import { listCacheOrg, listCacheProduct } from "@api/common/cache";
import { getEnumList } from '@api/common/common';
import ENUM from '@/maps/enum/enum';
import STATUS from '@/maps/enum/status/buy';
var VAL_WIDTH = '150px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '合同编号',
  valueKey: 'contractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '客户',
  valueKey: 'customerName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '原合同号',
  valueKey: 'originalContractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '产品',
  valueKey: 'arrayProductId',
  value: [],
  type: 'select',
  method: listCacheProduct,
  params: {},
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '机构名称',
  valueKey: 'orgCd',
  value: '',
  type: 'cascader',
  method: listCacheOrg,
  params: {},
  options: [],
  props: {
    //expandTrigger: 'hover',
    checkStrictly: true,
    emitPath: false,
    showAllLevels: false,
    value: 'orgCd',
    label: 'orgName',
    children: 'children'
  }
}, {
  label: '开始日期',
  valueKey: 'startDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'startDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '到期日期',
  valueKey: 'endDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'endDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '顾问',
  valueKey: 'userName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '结算属性',
  valueKey: 'arrayProductCountType',
  value: [],
  type: 'select',
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  },
  method: getEnumList,
  params: {
    // 请求时用的参数
    enumCd: ENUM.ENUM_CD_PRODUCT_COUNT_TYPE
  }
}, {
  label: '合同状态',
  valueKey: 'arrayContractState',
  value: [],
  type: 'select',
  options: filterContractStatus(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '认购状态',
  valueKey: 'arrayBuyState',
  value: [],
  type: 'select',
  options: filterBuyStatus(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}]; // 合同状态

function filterContractStatus() {
  var array = [STATUS.SAVE, STATUS.SUBMIT, STATUS.AUDIT, STATUS.COUNT_AUDIT, STATUS.DEAL, STATUS.SUSPEND, STATUS.PAYED, STATUS.AUDIT_FAIL, STATUS.DEAL_FAIL, STATUS.COUNT_AUDIT_FAIL, STATUS.RESERVE_BUY, STATUS.RESERVE_BUY_FAIL, STATUS.CLEAR_SUSPEND_SERVICE, STATUS.CLEAR_SUSPEND_COUNT_FAIL, STATUS.CLEAR_PAYED_SERVICE, STATUS.CLEAR_PAYED_COUNT_FAIL, STATUS.DEAL_RETURN];
  return array;
} //认购状态


function filterBuyStatus() {
  var array = [STATUS.SAVE, STATUS.SUBMIT, STATUS.AUDIT, STATUS.COUNT_AUDIT, STATUS.DEAL];
  return array;
}