//直营推介费
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 推介费计划
{
  path: "".concat(baseRoute, "/commission/plan"),
  name: 'commission-plan',
  meta: {
    title: '直营推介费>计划',
    auth: true,
    cache: true
  },
  component: _import('crm/commission/plan')
}, // 推介费录入
{
  path: "".concat(baseRoute, "/commission/new"),
  name: 'commission-new',
  meta: {
    title: '直营推介费>录入',
    auth: true,
    cache: true
  },
  component: _import('crm/commission/new')
}, // 推介费处理
{
  path: "".concat(baseRoute, "/commission/deal"),
  name: 'commission-deal',
  meta: {
    title: '直营推介费>处理',
    auth: true,
    cache: true
  },
  component: _import('crm/commission/deal')
}, // 分公司审核
{
  path: "".concat(baseRoute, "/commission/org"),
  name: 'commission-org',
  meta: {
    title: '直营推介费>分公司',
    auth: true,
    cache: true
  },
  component: _import('crm/commission/org')
}, // 财务审核
{
  path: "".concat(baseRoute, "/commission/finance"),
  name: 'commission-finance',
  meta: {
    title: '直营推介费>财务',
    auth: true,
    cache: true
  },
  component: _import('crm/commission/finance')
}, // 财务总监审核
{
  path: "".concat(baseRoute, "/commission/financeManager"),
  name: 'commission-financeManager',
  meta: {
    title: '直营推介费>财务总监',
    auth: true,
    cache: true
  },
  component: _import('crm/commission/financeManager')
}, // 汇总打印
{
  path: "".concat(baseRoute, "/commission/collect"),
  name: 'commission-collect',
  meta: {
    title: '直营推介费>汇总打印',
    auth: true,
    cache: true
  },
  component: _import('crm/commission/collect')
}, // 集团资金确认
{
  path: "".concat(baseRoute, "/commission/money"),
  name: 'commission-money',
  meta: {
    title: '直营推介费>资金',
    auth: true,
    cache: true
  },
  component: _import('crm/commission/money')
}, // 查询
{
  path: "".concat(baseRoute, "/commission/search"),
  name: 'commission-search',
  meta: {
    title: '直营推介费>查询',
    auth: true,
    cache: true
  },
  component: _import('crm/commission/search')
}, // 推介费规则
{
  path: "".concat(baseRoute, "/commission/rule"),
  name: 'commission-rule',
  meta: {
    title: '直营推介费>规则',
    auth: true,
    cache: true
  },
  component: _import('crm/commission/rule')
}];