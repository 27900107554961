//服务与支持
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 特殊申请
{
  path: "".concat(baseRoute, "/contract/particular/new"),
  name: 'service-particular-new',
  meta: {
    title: '服务>特殊申请',
    auth: true,
    cache: true
  },
  component: _import('crm/service/particular/new')
}, //特殊申请查询
{
  path: "".concat(baseRoute, "/contract/particular/search"),
  name: 'service-particular-search',
  meta: {
    title: '服务>特殊申请查询',
    auth: true,
    cache: true
  },
  component: _import('crm/service/particular/search')
}, // 客户资料
{
  path: "".concat(baseRoute, "/customer/info"),
  name: 'service-customer-info',
  meta: {
    title: '服务>客户资料',
    auth: true,
    cache: true
  },
  component: _import('crm/service/customer/info')
}, // VIP生日礼品
{
  path: "".concat(baseRoute, "/customer/birthday"),
  name: 'service-customer-birthday',
  meta: {
    title: '服务>生日礼品',
    auth: true,
    cache: true
  },
  component: _import('crm/service/customer/birthday')
}, //合同附件下载
{
  path: "".concat(baseRoute, "/contract/attachment/download"),
  name: 'service-attachment-download',
  meta: {
    title: '服务>附件下载',
    auth: true,
    cache: true
  },
  component: _import('crm/service/attachment/download')
}, // 机构违规合同
{
  path: "".concat(baseRoute, "/violation/contract"),
  name: 'org-violation-contract',
  meta: {
    title: '服务>违规合同',
    auth: true,
    cache: true
  },
  component: _import('crm/service/violation/contract')
}];