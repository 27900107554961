export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    /** 导出积分 */
    EXPORT_AGENT_APPOINTMENT_DATA: function EXPORT_AGENT_APPOINTMENT_DATA(data) {
      return request({
        url: url.agentAppointment.exportAgentAppointmentData,
        responseType: 'arraybuffer',
        data: data
      });
    },

    /** 批量调整积分 */
    DELETE_AGENT_APPOINTMENT: function DELETE_AGENT_APPOINTMENT(data) {
      return request({
        url: url.agentAppointment.deleteAgentAppointment,
        data: data
      });
    }
  };
});