import "core-js/modules/es.object.to-string.js";
// 统计汇总
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatDate, formatUserDataPromise } from '@/libs/crm/format';
import func from '@/maps/url/crm/report/report'; // TODO 合同汇总

export function contractSum(params) {
  var re = request.post(func.contractSum, {
    body: params
  });
  return re;
} // TODO 合同审核统计列表

export function sumAudit(params) {
  var re = request.post(func.sumAudit, {
    body: params
  });
  return re;
} // TODO 统计机构包括下属机构按月份入金数据

export function sumYearAmountByMonth(params) {
  var re = request.post(func.sumYearAmountByMonth, {
    body: params
  });
  return re;
} // TODO 统计机构各下属机构入金数据

export function sumYearAmountByOrg(params) {
  var re = request.post(func.sumYearAmountByOrg, {
    body: params
  });
  return re;
} // TODO 统计机构各下属机构合同录入后受理天数

export function countOrgAcceptDay(params) {
  var re = request.post(func.countOrgAcceptDay, {
    body: params
  });
  return re;
} // TODO 公司业绩追踪

export function orgMoney(params) {
  var re = request.post(func.orgMoney, {
    body: params
  });
  return re;
} // TODO 导出Excel

export function exportExcelOrgMoney(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelOrgMoney, {
    body: params
  }).then(function (blob) {
    util.download(blob, '直营分公司业绩报表' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 公司业绩剖析

export function orgAnalyze(params) {
  var re = request.post(func.orgAnalyze, {
    body: params
  });
  return re;
} // TODO 导出Excel

export function exportExcelAnalyze(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelAnalyze, {
    body: params
  }).then(function (blob) {
    util.download(blob, '直营分公司业绩分析报表' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 公司出入金分析

export function moneyInOut(params) {
  var re = request.post(func.moneyInOut, {
    body: params
  });
  return re;
} // TODO 公司出入金分析导出Excel

export function exportExcelMoneyInOut(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelMoneyInOut, {
    body: params
  }).then(function (blob) {
    util.download(blob, '公司出入金分析报表' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 合同审核日志统计

export function contractAuditLog(params, formatType) {
  var re = request.post(func.contractAuditLog, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 合同审核日志数量统计

export function contractAuditLogCount(params) {
  var re = request.post(func.contractAuditLogCount, {
    body: params
  });
  return re;
} // TODO 合同审核日志统计导出Excel

export function exportExcelContractAudit(params) {
  //let date = formatDate(new Date());
  var date = "";

  if (params.query.logTimeBegin != "") {
    date = params.query.logTimeBegin;
  } else if (params.query.dateBegin != "") {
    date = params.query.dateBegin;
  }

  if (params.query.logTimeEnd != "") {
    date += "~" + params.query.logTimeEnd;
  } else if (params.query.dateEnd != "") {
    date += "~" + params.query.dateEnd;
  }

  return blobRequest.post(func.exportExcelContractAudit, {
    body: params
  }).then(function (blob) {
    util.download(blob, '合同审核日志统计' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}
/**
 * 各机构业务汇总
 * @param {*} params 
 * @returns 
 */

export function listOrgBusiness(params) {
  return request.post(func.listOrgBusiness, {
    body: params
  });
} ////（上线时删除）//////////////////////////////////////////

/**
 * 测试短信
 * @param {*} params 
 * @returns 
 */

export function testSumBusiness(params) {
  return request.post(func.testSumBusiness, {
    body: params
  });
} ///////////////////////////////////////////////

/**
 * TODO 业务报表导出Excel
 * @param {*} params 
 * @returns 
 */

export function businessExportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelMoneyInOut, {
    body: params
  }).then(function (blob) {
    util.download(blob, '业务报表Excel' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}
/**
 * TODO 业务报表导出Excel
 * @param {*} params 
 * @returns 
 */

export function exportExcelOrgBusiness(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelOrgBusiness, {
    body: params
  }).then(function (blob) {
    util.download(blob, '业务报表Excel' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}
/**
 * TODO 出金计划列表
 * @param {*} params 
 * @returns 
 */

export function listSumProductMoneyOut(params) {
  return request.post(func.listSumProductMoneyOut, {
    body: params
  });
}
/**
 * TODO 导出出金计划
 * @param {*} params 
 * @returns 
 */

export function exportExcelSumProductMoneyOut(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelSumProductMoneyOut, {
    body: params
  }).then(function (blob) {
    util.download(blob, '产品出金计划' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}
/**
 * TODO 产品出金明细列表
 * @param {*} params 
 * @returns 
 */

export function listContractMoneyOut(params) {
  return request.post(func.listContractMoneyOut, {
    body: params
  });
}
/**
 * TODO 导出产品出金明细
 * @param {*} params 
 * @returns 
 */

export function exportExcelContractMoneyOut(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelContractMoneyOut, {
    body: params
  }).then(function (blob) {
    util.download(blob, '产品出金明细' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}