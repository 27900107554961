export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    /** 获取基本配置信息 */
    GET_SYS_CONFIG_BASIC: function GET_SYS_CONFIG_BASIC() {
      return request({
        url: url.getPlatformBaseInfo
      });
    },

    /** 更新基本配置信息 */
    SYS_CONFIG_BASIC_UPDATE: function SYS_CONFIG_BASIC_UPDATE(data) {
      return request({
        url: url.addOrUpdatePlatformBaseInfo,
        data: data
      });
    }
  };
});