import "core-js/modules/es.object.to-string.js";
// 推介费
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatDate, formatUserDataPromise } from '@/libs/crm/format';
import func from '@/maps/url/crm/bank/accountStatement'; // TODO 导入Excel流水

export function importExcelBankBill(params) {
  return request.post(func.importExcelBankBill, params);
} // TODO  导入Excel数据列表

export function list(params, formatType) {
  var re = request.post(func.list, {
    body: params
  });
  formatUserDataPromise(re, formatType); // 用户数据格式化

  return re;
} // TODO  外部导入列表

export function importList(params, formatType) {
  var re = request.post(func.importList, {
    body: params
  });
  formatUserDataPromise(re, formatType); // 用户数据格式化

  return re;
} // TODO  导出Excel外部导入列表

export function exportExcelImportData(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelImportData, {
    body: params
  }).then(function (blob) {
    util.download(blob, '导入银行流水列表' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 查询未认购列表

export function listNoBuy(params) {
  var re = request.post(func.listNoBuy, {
    body: params
  });
  return re;
} // TODO 查询未认购合同匹配列表

export function listNoBuyMatch(params) {
  var re = request.post(func.listNoBuyMatch, {
    body: params
  });
  return re;
}