import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    /* 组织架构列表 */
    LIST_ORGANIZATION: function LIST_ORGANIZATION() {
      return request({
        url: url.orgManager.framework.listOrganization
      }).then(function (res) {
        var children = res.list.map(function (item) {
          return _objectSpread(_objectSpread({}, item), {}, {
            label: item.name
          });
        }) || [];
        /* 配合后端做写死处理，等待后端有时间处理后再作调整 */

        var list = [{
          children: children,
          id: 0,
          isAudit: null,
          isTop: null,
          levelParentId: null,
          name: '创融会',
          orgType: null,
          parentId: null,
          status: null,
          bindNum: 0,
          isMock: true // 判断是否为写死的假数据,是则只有新增组织按钮

        }];
        return list;
      });
    },

    /* 组织架构新增或修改 */
    ADD_OR_UPDATE_ORGANIZATION: function ADD_OR_UPDATE_ORGANIZATION(data) {
      return request({
        url: url.orgManager.framework.addOrUpdateOrganization,
        data: data
      });
    },

    /* 组织架构组织详情 */
    GET_DETAIL_ORGANIZATION: function GET_DETAIL_ORGANIZATION(id) {
      return request({
        url: url.orgManager.framework.getDetailOrganization,
        data: {
          id: id
        }
      });
    },

    /* 顶级组织绑定人数 */
    GET_TOP_BINDNUM: function GET_TOP_BINDNUM() {
      return request({
        url: url.orgManager.framework.getTopBindNum
      });
    },

    /* 组织架构状态修改 */
    UPDATE_STATUS_ORGANIZATION: function UPDATE_STATUS_ORGANIZATION(data) {
      return request({
        url: url.orgManager.framework.updateStatus,
        data: data
      });
    },

    /* 组织架构删除 */
    DELETE_ORGANIZATION: function DELETE_ORGANIZATION(data) {
      return request({
        url: url.orgManager.framework.deleteOrganization,
        data: data
      });
    },

    /* 组织架构审核状态修改 */
    UPDATE_IS_AUDIT_ORGANIZATION: function UPDATE_IS_AUDIT_ORGANIZATION(data) {
      return request({
        url: url.orgManager.framework.updateIsAudit,
        data: data
      });
    },

    /* 组织架构置顶修改 */
    UPDATE_IS_TOP_ORGANIZATION: function UPDATE_IS_TOP_ORGANIZATION(data) {
      return request({
        url: url.orgManager.framework.updateIsTop,
        data: data
      });
    },

    /** 组织成员头部统计数据 */
    STATISTICAL_DATA_ORG_MEMBER: function STATISTICAL_DATA_ORG_MEMBER() {
      return request({
        url: url.orgManager.member.statisticalData
      });
    },

    /** 组织成员新增或修改 */
    ADD_OR_UPDATE_ORGANIZATION_MEMBER: function ADD_OR_UPDATE_ORGANIZATION_MEMBER(data) {
      return request({
        url: url.orgManager.member.addOrUpdateOrganizationMember,
        data: data
      });
    },

    /** 组织成员审核 */
    APPLY_ORGANIZATION_USER: function APPLY_ORGANIZATION_USER(data) {
      return request({
        url: url.orgManager.member.applyOrganizationUser,
        data: data
      });
    },

    /** 删除组织成员 */
    DELETE_ORGANIZATION_MEMBER: function DELETE_ORGANIZATION_MEMBER(data) {
      return request({
        url: url.orgManager.member.deleteOrganizationMember,
        data: data
      });
    },

    /** 删除组织成员申请 */
    DELETE_ORGANIZATION_USER: function DELETE_ORGANIZATION_USER(data) {
      return request({
        url: url.orgManager.member.deleteOrganizationUser,
        data: data
      });
    },

    /** 组织成员导出 */
    EXPORT_ORGANIZATION_MEMBER: function EXPORT_ORGANIZATION_MEMBER(data) {
      return request({
        url: url.orgManager.member.exportOrganizationMember,
        data: data,
        responseType: 'arraybuffer'
      });
    },

    /** 修改组织成员排序值 */
    UPDATA_SORT_ORGANIZATION: function UPDATA_SORT_ORGANIZATION(data) {
      return request({
        url: url.orgManager.member.updataSort,
        data: data
      });
    },

    /** 获取职务类型 */
    GET_ALL_JOB_TYPE: function GET_ALL_JOB_TYPE(data) {
      return request({
        url: url.orgManager.job.getAllJobType,
        data: data // jobType 职务类型：1=会内组织，2=俱乐部，3=班级

      }).then(function (res) {
        return res.list.map(function (item) {
          return {
            label: item.value,
            value: item.key
          };
        });
      });
    },

    /** 组织职务添加或修改 */
    ADD_OR_UPDATE_ORGANIZATION_JOB: function ADD_OR_UPDATE_ORGANIZATION_JOB(data) {
      return request({
        url: url.orgManager.job.addOrUpdateOrganizationJob,
        data: data
      });
    },

    /** 组织职务删除 */
    DELETE_ORGANIZATION_JOB: function DELETE_ORGANIZATION_JOB(data) {
      return request({
        url: url.orgManager.job.deleteOrganizationJob,
        data: data
      });
    },

    /** 组织职务排序 */
    SORT_ORGANIZATION_JOB: function SORT_ORGANIZATION_JOB(data) {
      return request({
        url: url.orgManager.job.sortOrganizationJob,
        data: data
      });
    },

    /** 组织职务状态修改 */
    UPDATE_ORGANIZATION_JOB_STATUS: function UPDATE_ORGANIZATION_JOB_STATUS(data) {
      return request({
        url: url.orgManager.job.updateStatus,
        data: data
      });
    },

    /** 组织班级添加或修改 */
    ADD_OR_UPDATE_GRADE: function ADD_OR_UPDATE_GRADE(data) {
      return request({
        url: url.orgManager.class.addOrUpdateGrade,
        data: data
      });
    },

    /** 组织班级修改状态值 */
    UPDATE_STATUS_GRADE: function UPDATE_STATUS_GRADE(data) {
      return request({
        url: url.orgManager.class.updateStatus,
        data: data
      });
    },

    /** 组织班级删除 */
    DELETE_GRADE: function DELETE_GRADE(data) {
      return request({
        url: url.orgManager.class.deleteGrade,
        data: data
      });
    },

    /** 组织班级修改排序值 */
    UPDATA_SORT_GRADE: function UPDATA_SORT_GRADE(data) {
      return request({
        url: url.orgManager.class.updataSort,
        data: data
      });
    },

    /** 意见反馈删除 */
    DELETE_OPINION_FEEDBACK: function DELETE_OPINION_FEEDBACK(data) {
      return request({
        url: url.orgManager.feedback.deleteOpinion,
        data: data
      });
    }
  };
});