// 区域经营
export default {
  // 导入Excel经营费用 
  importExcelOrgManageExpenses: 'import/orgManage/importExcelOrgManageExpenses',
  // 导入Excel经营费用
  list: '/import/orgManage/list',
  // 导入Excel数据列表
  importList: '/org/manage/importList',
  // 外部导入数据列表
  exportExcelImportData: '/org/manage/exportExcelImportData',
  // 外部导入数据列表
  addBatch: '/org/manage/addBatch',
  // 新增
  editBatch: '/org/manage/editBatch',
  //  编辑
  orgList: '/org/manage/list',
  //  列表
  removeBatch: '/org/manage/removeBatch' //  删除

};