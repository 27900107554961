import LOG_TYPE from "@/maps/enum/contract-log-type";
import { formatDate } from '@/libs/crm/format';
import { listCacheOrg } from "@api/common/cache"; //查询条件

var VAL_WIDTH = '150px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
var date = new Date();
var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
var initDateBegin = formatDate(firstDay); //默认开始日期

var initDateEnd = formatDate(lastDay); //默认开始日期

export var FILTER_FORMS = [{
  label: '审核人',
  valueKey: 'userName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '合同开始日期',
  valueKey: 'dateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'dateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '机构名称',
  valueKey: 'orgCd',
  value: '',
  type: 'cascader',
  method: listCacheOrg,
  params: {},
  options: [],
  props: {
    //expandTrigger: 'hover',
    checkStrictly: true,
    emitPath: false,
    showAllLevels: false,
    value: 'orgCd',
    label: 'orgName',
    children: 'children'
  }
}, {
  label: '业务流程',
  valueKey: 'arrayLogType',
  value: [],
  type: 'select',
  options: filterLogType(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '审核结果',
  valueKey: 'status',
  value: [],
  type: 'select',
  options: filterAudit(),
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '审核日期',
  valueKey: 'logTimeBegin',
  value: initDateBegin,
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'logTimeEnd',
  value: initDateEnd,
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}]; //日志类型

function filterLogType() {
  var array = [{
    value: '1',
    text: '认购'
  }, {
    value: '2',
    text: '核算'
  }, {
    value: '3',
    text: '赎回'
  }, {
    value: '4',
    text: '续约'
  }, {
    value: '5',
    text: '变更'
  }, {
    value: '6',
    text: '付息'
  }, {
    value: '7',
    text: '礼品'
  }, {
    value: '8',
    text: '纸合同'
  }];
  return array;
} //审核结果


function filterAudit() {
  var array = [{
    value: '1',
    text: '同意'
  }, {
    value: '2',
    text: '不同意'
  }];
  return array;
}