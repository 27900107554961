//启用
export default {
  ALL: {
    value: '',
    text: '全部'
  },
  YES: {
    value: '1',
    text: '是'
  },
  NO: {
    value: '0',
    text: '否'
  }
};