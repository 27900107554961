import "core-js/modules/es.object.to-string.js";
// 到期与清算
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatUserDataPromise, formatDate } from '@/libs/crm/format';
import func from '@/maps/url/crm/clear/clear'; // TODO 列表

export function list(params, formatType) {
  var re = request.post(func.list, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 提交

export function submit(params) {
  var re = request.post(func.submit, {
    body: params
  });
  return re;
} // TODO 结算审核

export function auditCount(params) {
  var re = request.post(func.auditCount, {
    body: params
  });
  return re;
} // TODO 财务审核

export function auditFinance(params) {
  var re = request.post(func.auditFinance, {
    body: params
  });
  return re;
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '清算合同' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}