import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _methods;

import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import COLOR from '@/maps/enum/color';
import STATUS from '@/maps/enum/status/contract-reserve-buy';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import { FILTER_PROPS, FILTER_FORMS } from './option/filter'; // 过滤条件的form

import TABLE_LIST from './option/list'; // 表头

import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import DialogAudit from './components/dialog-audit';
import { list, exportExcel } from '@api/crm/buy/reserve';
export default {
  name: 'buy-reserve',
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogAudit: DialogAudit
  },
  data: function data() {
    var _ref;

    return _ref = {
      loading: false,
      filename: __filename,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      tableData: [],
      query: {},
      pagination: {}
    }, _defineProperty(_ref, "loading", false), _defineProperty(_ref, "headers", TABLE_LIST), _defineProperty(_ref, "table", {}), _defineProperty(_ref, "showAuditDialog", false), _defineProperty(_ref, "currentRow", {}), _defineProperty(_ref, "canEditStatus", [STATUS.AUDIT_NO.value, STATUS.AUDIT_RESULT_NO.value]), _ref;
  },
  computed: {},
  methods: (_methods = {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    }
  }, _defineProperty(_methods, "clickReset", function clickReset() {
    this.$refs.form.reset();
  }), _defineProperty(_methods, "clickSearch", function clickSearch() {
    this.initFilter();
    this.$refs.jTable.pageChange(1);
  }), _defineProperty(_methods, "loadData", function loadData(_ref2) {
    var _this = this;

    var _ref2$page = _ref2.page,
        page = _ref2$page === void 0 ? {} : _ref2$page;
    this.initFilter();
    var param = {
      page: page,
      query: this.query
    };
    this.loading = true;
    list(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
      _this.loading = false;
      _this.tableData = [];

      if (res.data != null) {
        var _res$data = res.data,
            data = _res$data === void 0 ? [] : _res$data,
            _page = res.page;
        var _page$totalCount = _page.totalCount,
            totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
        _this.tableData = data;
        _this.pagination = {
          total: totalCount
        };
      } else {
        _this.$message({
          message: '暂无数据',
          type: 'info'
        });
      }
    }).catch(function (e) {
      _this.loading = false;

      _this.$message({
        message: '查询列表数据出错',
        type: 'error'
      });
    });
  }), _defineProperty(_methods, "selectRow", function selectRow(row) {
    this.currentRow = row;
  }), _defineProperty(_methods, "clickEdit", function clickEdit() {
    var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    this.currentRow = row;
    this.showAuditDialog = true;
  }), _defineProperty(_methods, "clickInfo", function clickInfo() {
    var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  }), _defineProperty(_methods, "clickRemove", function clickRemove() {
    var _this2 = this;

    if (Object.keys(this.currentRow).length === 0) {
      this.$message('请选择合同');
    } else {
      this.$confirm('确定删除当前合同吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.loading = true;
        remove({
          contractId: _this2.currentRow.contractId
        }).then(function (res) {
          _this2.loading = false;

          _this2.init();
        }).catch(function (e) {
          _this2.loading = false;
        });
      }).catch(function () {});
    }
  }), _defineProperty(_methods, "clickExport", function clickExport() {
    var _this3 = this;

    this.$confirm('确定按此查询条件导出数据?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(function () {
      _this3.initFilter();

      var param = {
        query: _this3.query
      };
      exportExcel(param).catch(function (e) {
        _this3.$message({
          message: e,
          type: 'error'
        });
      });
    });
  }), _defineProperty(_methods, "setStatusColor", function setStatusColor(status) {
    //状态颜色
    var className = '';

    switch (status) {
      case STATUS.AUDIT_NO.value:
        className = COLOR.BLUE;
        break;

      case STATUS.AUDIT_RESULT_YES.value:
        className = '';
        break;

      case STATUS.AUDIT_RESULT_NO.value:
        className = '';
        break;

      case STATUS.AUDIT_RESULT_ALLOW.value:
        className = '';
        break;
    }

    return className;
  }), _methods),
  created: function created() {}
};