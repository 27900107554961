import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { TOP_FORM, TABLE_CONFIG, TABLE_LABEL } from './config/list';
import { DIALOG_FORM_CONFIG, DIALOG_FORM_DATA } from './config/add';
import { getHeaders } from '@/api-new/service';
import { cloneDeep } from 'lodash-es';
import { useSuccessTip } from '@/hooks/use-tip';
import api from '@/api-new';
import url from '@/api-new/url';
export default {
  name: 'CustomerList',
  data: function data() {
    return {
      loading: false,
      topForm: TOP_FORM,
      filtersData: {},
      selectData: {},
      allotForm: {},
      openAllot: false,
      tableConfig: TABLE_CONFIG,
      tableLabel: TABLE_LABEL,
      httpConfig: {},

      /* 弹窗数据 */
      dialogFormData: cloneDeep(DIALOG_FORM_DATA),
      dialogFormConfig: cloneDeep(DIALOG_FORM_CONFIG),

      /* 弹窗是否显示 */
      dialogVisible: false,

      /* 弹窗标题 */
      dialogTitle: '新增分类'
    };
  },
  computed: {},
  methods: {
    /** 接口参数过滤函数 */
    filtersFormatter: function filtersFormatter() {},

    /* 弹窗确认 */
    dialogConfirm: function dialogConfirm() {
      var _this = this;

      this.$nextTick(function () {
        _this.$refs.form.submitForm();
      });
    },

    /* 弹窗确认 */
    dialogSubmit: function dialogSubmit() {
      this.addOrUpdateCategory();
    },

    /* 添加或修改 */
    addOrUpdateCategory: function addOrUpdateCategory() {
      var _this2 = this;

      api.ADD_OR_UPDATE_CATEGORY(this.dialogFormData).then(function () {
        _this2.dialogVisible = false;
        useSuccessTip();

        _this2.$refs.jPage.getPageData();
      });
    },
    tableClick: function tableClick(params) {
      console.log('params: ', params);

      var _ref = params || {},
          clickItem = _ref.clickItem;

      var _ref2 = clickItem || {},
          type = _ref2.type;

      if (type === 'add') {
        /* 新增类别 */
        this.dialogVisible = true;
        this.dialogFormData = cloneDeep(DIALOG_FORM_DATA);
      }
    },

    /** 表格数据改变 */
    tableRowChange: function tableRowChange(params) {
      var _ref3 = params || {},
          prop = _ref3.prop,
          row = _ref3.row;

      switch (prop) {
        case 'sort':
          this.Sort(row);
          break;
      }
    },

    /** 点击表格项 */
    tableRowClick: function tableRowClick(action) {
      var _ref4 = action || {},
          clickItem = _ref4.clickItem,
          row = _ref4.row;

      var _ref5 = clickItem || {},
          type = _ref5.type;

      switch (type) {
        // 处理列表编辑
        case 'edit':
          this.Edit(row);
          break;
        // 处理列表删除

        case 'delete':
          this.Delete(row.id);
          break;
        // 处理列表停用

        case 'start':
          this.updateStatus(row);
          break;
        // 处理列表停用

        case 'stop':
          this.updateStatus(row);
          break;
      }
    },
    // 更新状态
    updateStatus: function updateStatus(row) {
      var _this3 = this;

      var id = row.id,
          status = row.status;
      var isStop = status === 0; // 是否为停用

      api.UPDATE_STATUS_CATEGORY({
        id: id,
        status: isStop ? 1 : 0
      }).then(function () {
        useSuccessTip();

        _this3.$refs.jPage.getPageData();
      });
    },

    /* 处理列表编辑 */
    Edit: function Edit(row) {
      console.log('row: ', row);
      this.dialogVisible = true;
      this.dialogTitle = '编辑分类';
      this.dialogFormData = row;
    },

    /* 处理列表删除 */
    Delete: function Delete(idList) {
      var _this4 = this;

      this.$confirm('确定要删除所选分类吗？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        api.DELETE_CATEGORY(idList).then(function () {
          useSuccessTip();

          _this4.$refs.jPage.getPageData();
        });
      });
    },

    /* 排序处理 */
    Sort: function Sort(row) {
      var _this5 = this;

      var id = row.id,
          sort = row.sort;
      api.UPDATASORT_CATEGORY({
        id: id,
        sort: sort
      }).then(function () {
        useSuccessTip();

        _this5.$refs.jPage.getPageData();
      });
    }
  },
  created: function created() {
    var host = url.host,
        apiPrev = url.apiPrev;
    var apiUrl = url.activity.classify;
    this.httpConfig = {
      url: "".concat(host).concat(apiPrev).concat(apiUrl.listActivityClassify),
      headers: getHeaders()
    };
  }
};