// 角色菜单
import request from '@/plugin/axios';
import func from '@/maps/url/rm/roleMenu'; // menuTree 菜单树

export function menuTree(params) {
  return request.post(func.menuTree, {
    body: params
  });
} // setRoleMenu 设置菜单权限

export function setRoleMenu(params) {
  return request.post(func.setRoleMenu, {
    body: params
  });
}