import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import dialogMixin from "@/mixins/dialog";
import DialogApplication from "./dialog-application";
import DialogSpecial from "./dialog-special";
import DialogSummary from "../../dialog-summary";
import STATUS from "@/maps/enum/status/commission-batch";
import AUDIT from "@/maps/enum/status/audit";
import { formatAmountToString } from "@/libs/crm/format";
import { listBatchDetail, removeDetailFromBatch, listBatchSpecial, removeBatchSpecial, submit } from "@api/crm/commission/commission";
export default {
  name: "commission-batch-detail",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    DialogApplication: DialogApplication,
    DialogSpecial: DialogSpecial,
    DialogSummary: DialogSummary
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tableData: [],
      tableDataSpecial: [],
      //特殊推介费
      showApplicationDialog: false,
      // 推介费申请窗口
      showSpecialDialog: false,
      showSummaryDialog: false,
      checkboxRows: [],
      //复选框
      currentRow: {},
      // 当前点击行
      batchId: this.propsModel.batchId,
      canEditStatus: [STATUS.SAVE.value] //可以编辑的状态

    };
  },
  computed: {
    isEdit: function isEdit() {
      var edit = false;

      if (this.propsModel != null && this.canEditStatus.includes(String(this.propsModel.status))) {
        edit = true;
      }

      return edit;
    }
  },
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.tableDataSpecial = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    loadData: function loadData() {
      var _this = this;

      var param = {
        query: {
          batchId: this.propsModel.batchId
        }
      };
      this.loading = true;
      listBatchDetail(param, DATA_FORMAT_TYPE.AMOUNT).then(function (res) {
        _this.loading = false;
        _this.tableData = []; //重组推介费详细，凑够5个元素

        if (res.data != null) {
          res.data.forEach(function (item) {
            var arrayDetail = [];

            var _loop = function _loop(i) {
              var obj = item.listDetail.find(function (n) {
                return n.titleId == "i" + i;
              });

              if (obj == null) {
                obj = {};
              } else {
                //金额转成千分位
                obj.bonus = formatAmountToString(obj.bonus);
              }

              arrayDetail.push(obj);
            };

            for (var i = 0; i < 5; i++) {
              _loop(i);
            }

            item.listDetail = arrayDetail;
          });
          _this.tableData = res.data;
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      }); //加载特殊申请列表

      listBatchSpecial({
        batchId: this.propsModel.batchId
      }, 1).then(function (res) {
        _this.tableDataSpecial = res.data;
      });
    },
    handleCommand: function handleCommand(command) {
      if (command == "add") {
        //并入明细
        this.clickAddApplication();
      } else if (command == "remove") {
        //移除明细
        this.clickRemoveApplication();
      } else if (command == "special") {
        //特殊推介费
        this.clickSpecialApplication();
      }
    },
    //删除申请
    clickRemoveApplication: function clickRemoveApplication() {
      var _this2 = this;

      if (this.checkboxRows.length == 0) {
        this.$message("请选择合同");
      } else {
        this.$confirm("移除明细后将退回推介费申请明细列表，确定要移除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          var param = {
            arrayDetailId: []
          };

          _this2.checkboxRows.forEach(function (item) {
            param.arrayDetailId.push(item.detailId);
          });

          removeDetailFromBatch(param).then(function (res) {
            _this2.$message({
              message: "已移除",
              type: "success"
            });

            _this2.init();
          });
        }).catch(function () {});
      }
    },
    //添加明细到台帐
    clickAddApplication: function clickAddApplication() {
      this.batchId = this.propsModel.batchId;
      this.currentRow = this.propsModel;
      this.showApplicationDialog = true;
    },
    //点击特殊推介费
    clickSpecialApplication: function clickSpecialApplication() {
      this.batchId = this.propsModel.batchId;
      this.showSpecialDialog = true;
    },
    //删除特殊推介费
    clickRemoveSpecial: function clickRemoveSpecial(row) {
      var _this3 = this;

      this.$confirm("确定要删除特殊推介费吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var param = {
          specialId: row.specialId
        };
        removeBatchSpecial(param).then(function (res) {
          _this3.$message({
            message: "已移除",
            type: "success"
          });

          _this3.init();
        });
      }).catch(function () {});
    },
    //汇总报表
    showSummary: function showSummary() {
      this.batchId = this.propsModel.batchId;
      this.showSummaryDialog = true;
    },
    //复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      this.checkboxRows = rows;
    },
    clickSubmit: function clickSubmit() {
      var _this4 = this;

      this.$confirm("确定台帐提交审核吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var param = {
          batchId: _this4.propsModel.batchId,
          audit: AUDIT.YES.value
        };
        _this4.loading = true;
        submit(param).then(function (res) {
          _this4.loading = false;
          _this4.showDialog = false;

          _this4.$emit("refresh");
        }).catch(function (e) {
          _this4.loading = false;
        });
      }).catch(function () {});
    }
  }
};