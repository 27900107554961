import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AUDIT_PROCESS from "@/maps/enum/audit-process";
import COLOR from "@/maps/enum/color";
import STATUS from "@/maps/enum/status/gift-apply";
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter";
import TABLE_LIST from "./option/list";
import DynamicForm from "@/components/common/dynamic-form";
import DynamicTable from "@/components/common/dynamic-table";
import DialogAudit from "./components/dialog-audit";
import DialogAuditBatch from "./components/dialog-audit-batch";
import DialogInfo from "../dialog-info";
import { list } from "@api/crm/gift/apply";
export default {
  name: "gift-applySelf-finance",
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogAudit: DialogAudit,
    DialogAuditBatch: DialogAuditBatch,
    DialogInfo: DialogInfo
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {
        multiple: true
      },
      pagination: {},
      process: AUDIT_PROCESS.FINANCE.value,
      //当前审核流程
      query: {
        selfPaying: "1"
      },
      tableData: [],
      showAuditDialog: false,
      showAuditBatchDialog: false,
      //批量审核
      showInfoDialog: false,
      currentRow: {},
      checkboxRows: [],
      //复选框选中行
      canEditStatus: [STATUS.SERVICE_AUDIT.value] //可以编辑的状态

    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: "数据已刷新",
        type: "info",
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      var param = {
        process: this.process,
        page: page,
        query: this.query
      };
      this.loading = true;
      list(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.pagination = {
            total: totalCount
          };
          _this.tableData = data;
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    clickAudit: function clickAudit(row) {
      this.currentRow = row;
      this.showAuditDialog = true;
    },
    clickAuditBatch: function clickAuditBatch() {
      if (this.checkboxRows.length == 0) {
        this.$message("请选择合同");
      } else {
        //如果不是待发货状态，从选择列表中删除
        var arr = [];

        for (var i = 0; i < this.checkboxRows.length; i++) {
          if (this.checkboxRows[i].status == STATUS.SERVICE_AUDIT.value) {
            arr.push(this.checkboxRows[i]);
          }
        }

        if (arr.length > 0) {
          this.showAuditBatchDialog = true;
        } else {
          this.$message("没有待审核合同");
        }
      }
    },
    clickInfo: function clickInfo(row) {
      this.currentRow = row;
      this.showInfoDialog = true;
    },
    //复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      var _this2 = this;

      this.checkboxRows = [];
      rows.forEach(function (n) {
        if (STATUS.SERVICE_AUDIT.value == n.status) {
          _this2.checkboxRows.push(n);
        }
      });
    },
    setStatusColor: function setStatusColor(status) {
      //合同状态颜色
      var className = "";

      switch (status) {
        case STATUS.SERVICE_AUDIT.value:
          className = COLOR.BLUE;
          break;

        case STATUS.DELIVER_GOODS.value:
          className = COLOR.GREEN;
          break;

        case STATUS.FINANCE_AUDIT_FAILURE.value:
          className = COLOR.RED;
          break;
      }

      return className;
    }
  },
  created: function created() {}
};