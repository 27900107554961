var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "25%",
            modal: false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.close
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-title",
              attrs: { slot: "title" },
              slot: "title"
            },
            [
              _c(
                "el-row",
                [
                  _c("span", { staticClass: "dialog-title-text" }, [
                    _vm._v("客户信息")
                  ]),
                  _c(
                    "el-button",
                    {
                      staticStyle: { float: "right" },
                      attrs: { type: "primary" },
                      on: { click: _vm.skip }
                    },
                    [_vm._v("跳转详情")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "el-descriptions",
                { attrs: { direction: "horizontal", column: 1, border: "" } },
                [
                  _c("el-descriptions-item", { attrs: { label: "客户姓名" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.customerName))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "性别" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.sexValue))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "年龄" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.age))
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "客户电话" } }, [
                    _vm._v(_vm._s(_vm.CustomerInfo.mobile))
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "家庭住址", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.address))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "客户类型", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.customerTypeValue))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "客户等级", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.customerLevelValue))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "客户年限", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.customerYearLimit))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "理顾", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.agentUserName))]
                  ),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "部门", span: 2 } },
                    [_vm._v(_vm._s(_vm.CustomerInfo.orgName))]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }