import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AUDIT_PROCESS from '@/maps/enum/audit-process';
import COLOR from '@/maps/enum/color';
import STATUS from '@/maps/enum/status/gift-apply';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import { FILTER_PROPS, FILTER_FORMS } from './option/filter';
import TABLE_LIST from './option/list';
import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import SearchContract from '@/components/business/search-contract';
import DialogEdit from './components/dialog-edit';
import DialogInfo from '../dialog-info';
import { list, isExist, create as createCount } from '@api/crm/gift/apply';
export default {
  name: 'gift-apply-new',
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    SearchContract: SearchContract,
    DialogEdit: DialogEdit,
    DialogInfo: DialogInfo
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {},
      pagination: {},
      process: AUDIT_PROCESS.NEW.value,
      //当前审核流程
      query: {
        selfPaying: '0'
      },
      tableData: [],
      showSearchContractDialog: false,
      showEditDialog: false,
      showInfoDialog: false,
      currentRow: {},
      canEditStatus: [STATUS.SAVE.value, STATUS.SERVICE_AUDIT_FAILURE.value, STATUS.FINANCE_AUDIT_FAILURE.value] //可以编缉的状态

    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      var param = {
        process: this.process,
        page: page,
        query: this.query
      };
      this.loading = true;
      list(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.pagination = {
            total: totalCount
          };
          _this.tableData = data;
        } else {
          _this.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    clickAdd: function clickAdd() {
      this.showSearchContractDialog = true;
    },
    clickEdit: function clickEdit(row) {
      this.currentRow = row;
      this.showEditDialog = true;
    },
    clickInfo: function clickInfo(row) {
      this.currentRow = row;
      this.showInfoDialog = true;
    },
    popSelectContract: function popSelectContract(contract) {
      var _this2 = this;

      //检查合同是否已生成礼品申请
      var param = {
        contractId: contract.contractId
      };
      isExist(param).then(function (res) {
        if (res.data) {
          //已存在
          _this2.$message.error('合同已申请过礼品');
        } else {
          _this2.$prompt('确定创建' + contract.contractCd + '礼品申请吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPlaceholder: '请输入新增原因',
            type: 'warning'
          }).then(function (_ref2) {
            var value = _ref2.value;

            if (value === null || value === '') {
              return _this2.$message.error('请输入新增原因');
            }

            var param = {
              contractId: contract.contractId,
              description: value
            };
            _this2.loading = true;
            createCount(param).then(function (res) {
              _this2.loading = false;

              _this2.$message({
                type: 'success',
                message: '新增成功!'
              });

              _this2.init();
            }).catch(function () {
              _this2.loading = false;
            });
          }).catch(function () {});
        }
      });
    },
    setStatusColor: function setStatusColor(status) {
      //合同状态颜色
      var className = '';

      switch (status) {
        case STATUS.SAVE.value:
          className = COLOR.BLUE;
          break;

        case STATUS.SUBMIT.value:
          className = COLOR.GREEN;
          break;

        case STATUS.SERVICE_AUDIT_FAILURE.value:
          className = COLOR.RED;
          break;

        case STATUS.FINANCE_AUDIT_FAILURE.value:
          className = COLOR.RED;
          break;
      }

      return className;
    }
  },
  created: function created() {}
};