// 角色
import request from '@/plugin/axios';
import func from '@/maps/url/rm/role'; // TODO 列表

export function list(params) {
  return request.post(func.list, {
    body: params
  });
} // TODO 新增

export function add(params) {
  return request.post(func.add, {
    body: params
  });
} // TODO 编辑

export function edit(params) {
  return request.post(func.edit, {
    body: params
  });
} // TODO 删除

export function remove(params) {
  return request.post(func.remove, {
    body: params
  });
} // TODO 启用/停用角色

export function setEnabled(params) {
  return request.post(func.setEnabled, {
    body: params
  });
} // getRoleMenu 获取角色菜单

export function roleMenu(params) {
  return request.post(func.roleMenu, {
    body: params
  });
} // 设置角色数据权限

export function setRoleData(params) {
  return request.post(func.setRoleData, {
    body: params
  });
} // 获取角色数据权限

export function getRoleData(params) {
  return request.post(func.getRoleData, {
    body: params
  });
} // 设置用户角色

export function setUserRole(params) {
  return request.post(func.setUserRole, {
    body: params
  });
} // 查询用户的角色列表

export function listUserRole(params) {
  return request.post(func.listUserRole, {
    body: params
  });
} // 查询角色的用户列表

export function listRoleUser(params) {
  return request.post(func.listRoleUser, {
    body: params
  });
} // 设置角色的用户

export function setRoleUser(params) {
  return request.post(func.setRoleUser, {
    body: params
  });
} // setSecrecy 设置敏感词

export function setSecrecy(params) {
  return request.post(func.setSecrecy, {
    body: params
  });
} // listSecrecy 获取敏感词列表

export function listSecrecy(params) {
  return request.post(func.listSecrecy, {
    body: params
  });
} // 删除保密设置

export function removeSecrecy(params) {
  return request.post(func.removeSecrecy, {
    body: params
  });
}