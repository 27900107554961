var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        top: "30vh",
        width: "600px",
        title: "同步钉钉机构及人员",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _vm._v(
                  " 说明：为减少接口调用，默认只同步指定ID机构及人员。如果勾选下属机构，则只包括直接下属机构及人员。一般下属机构有变化才需要包括下属"
                ),
                _c("br"),
                _vm._v(
                  " 例如：三区(000018)->广州营业一部(000027)->市场一部(000102)->团队一部(000154)."
                ),
                _c("br"),
                _vm._v(
                  " 设置机构ID:000027，只同步广州营业一部(000027)及人员，勾选下属，则同步广州营业一部(000027), 及市场一部(000102)。不包括团队一部(000154) "
                )
              ])
            ],
            1
          ),
          _c("el-divider", { attrs: { "content-position": "left" } }),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "机构ID", prop: "orgCd" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入机构ID" },
                        model: {
                          value: _vm.formModel.orgCd,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "orgCd", $$v)
                          },
                          expression: "formModel.orgCd"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "包括直接下属", prop: "incSub" } },
                    [
                      _c("el-checkbox", {
                        model: {
                          value: _vm.formModel.incSub,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "incSub", $$v)
                          },
                          expression: "formModel.incSub"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: { click: _vm.confirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }