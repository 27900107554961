import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import useImageParse from '@/hooks/use-image-parse';
var PUBLIC_CONFIG = {
  labelWidth: 80
};
export var TOP_FORM = {
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '跟进方式',
    props: {
      placeholder: '请选择跟进方式'
    },
    options: [{
      label: '拜访',
      value: '1'
    }, {
      label: '会面',
      value: '2'
    }, {
      label: '电话',
      value: '3'
    }, {
      label: '微信',
      value: '4'
    }, {
      label: '邀约',
      value: '5'
    }]
  }),
  agentUserName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '理顾'
  }),
  customerName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户'
  }),
  createDateType: {
    labelWidth: 120,
    type: 'slot',
    label: '创建日期'
  }
};
export var TABLE_LABEL = [{
  label: '流水号',
  prop: 'code',
  type: 'link'
}, {
  label: '客户',
  prop: 'customerName'
}, {
  label: '创建人',
  prop: 'agentUserName'
}, {
  label: '部门',
  prop: 'orgName'
}, {
  label: '跟进方式',
  prop: 'typeValue',
  formatter: function formatter(row, column, cellValue) {
    /** 如果分类超过两个 用...省略 */
    if (cellValue) {
      var arr = cellValue.split(',');

      if (arr.length > 2) {
        return "".concat(arr.slice(0, 2).join(','), "...");
      } else {
        return cellValue;
      }
    }
  }
}, {
  label: '沟通内容',
  prop: 'content'
}, {
  label: '签到地址',
  prop: 'location'
}, {
  label: '总结',
  prop: 'informationType'
}, {
  label: '创建时间',
  prop: 'createTime'
}];
export var TABLE_CONFIG = {
  isSelect: true,
  rowKey: 'id',
  actions: [{
    label: '导出',
    type: 'export',
    props: {
      size: 'small' // type: 'primary',

    },
    permission: 'CustomerFollow-export'
  }, {
    label: '删除',
    type: 'delete',
    props: {
      size: 'small',
      type: 'danger'
    },
    permission: 'CustomerFollow-delete'
  }]
};