


















































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/buy/new/private/index.vue"
}
