//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import dialogMixin from "@/mixins/dialog";
import { list } from "@api/crm/buy/buy";
export default {
  name: "service-customer-contract",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      page: {
        pageNumber: 1,
        pageSize: 10,
        totalCount: 0
      },
      tableData: [],
      currentRow: {} // 当前选中行

    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    dialogClose: function dialogClose() {},
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    loadData: function loadData() {
      var _this = this;

      var param = {
        page: this.page,
        query: {
          idType: this.propsModel.idType,
          identification: this.propsModel.identification
        }
      };
      this.loading = true;
      list(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              page = res.page;
          _this.tableData = data;
          _this.page = page;
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.page.pageSize = val;
      this.loadData();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.page.pageNumber = val;
      this.loadData();
    }
  }
};