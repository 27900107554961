// 表头
export default [{
  label: '用户',
  prop: 'userName',
  width: '100px'
}, {
  label: '机构',
  prop: 'orgName',
  width: '250px'
}, {
  label: '类型',
  prop: 'typeText',
  width: '100px'
}, {
  label: '姓名(隐藏)',
  prop: 'nameText',
  width: '110px'
}, {
  label: '证件号(隐藏)',
  prop: 'identificationText',
  width: '110px'
}, {
  label: '电话(隐藏)',
  prop: 'mobileText',
  width: '110px'
}, {
  label: '地址(隐藏)',
  prop: 'addressText',
  width: '110px'
}, {
  label: 'email(隐藏)',
  prop: 'emailText',
  width: '110px'
}, {
  label: '银行卡(隐藏)',
  prop: 'accountNoText',
  width: '110px'
}, {
  label: '导出(限制)',
  prop: 'exportText',
  width: '110px'
}];