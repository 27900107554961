var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        top: "20vh",
        width: "900px",
        title: "新增或变更团队",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "团队名称", prop: "teamName" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择或新增团队"
                          },
                          on: { change: _vm.changeTeam, clear: _vm.clearTeam },
                          model: {
                            value: _vm.formModel.team,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "team", $$v)
                            },
                            expression: "formModel.team"
                          }
                        },
                        _vm._l(_vm.selectData.team, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "新团队名称", prop: "newTeamName" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.formModel.newTeamName,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "newTeamName", $$v)
                          },
                          expression: "formModel.newTeamName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "团队总监", prop: "teamManager" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.teamManager,
                            callback: function($$v) {
                              _vm.teamManager = $$v
                            },
                            expression: "teamManager"
                          }
                        },
                        _vm._l(_vm.selectData.manage, function(item) {
                          return _c(
                            "el-radio",
                            {
                              key: item.personuuid,
                              attrs: { label: item.personuuid }
                            },
                            [_vm._v(_vm._s(item.personName))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "团队成员", prop: "teamPerson" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.teamPerson,
                            callback: function($$v) {
                              _vm.teamPerson = $$v
                            },
                            expression: "teamPerson"
                          }
                        },
                        _vm._l(_vm.selectData.person, function(item) {
                          return _c(
                            "el-checkbox",
                            {
                              key: item.personuuid,
                              attrs: { label: item.personuuid }
                            },
                            [_vm._v(_vm._s(item.personName))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "团队描述", prop: "description" } },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formModel.description,
                            expression: "formModel.description"
                          }
                        ],
                        staticClass: "el-textarea__inner",
                        attrs: { placeholder: "请填写团队描述" },
                        domProps: { value: _vm.formModel.description },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formModel,
                              "description",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: { click: _vm.confirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }