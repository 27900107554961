//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import TABLE_LIST from "./option/list"; // 表头

import DynamicForm from "@/components/common/dynamic-form";
import DynamicTable from "@/components/common/dynamic-table";
import DialogSms from "./components/dialog-sms";
import DialogGoldPrice from "./components/dialog-gold-price";
import DialogOrgDd from "./components/dialog-org-dd";
import DialogAttachment from "./components/dialog-attachment";
import DialogRecount from "./components/dialog-recount";
import { cleanCache } from "@api/rm/tool";
export default {
  name: "tool",
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogSms: DialogSms,
    DialogGoldPrice: DialogGoldPrice,
    DialogOrgDd: DialogOrgDd,
    DialogAttachment: DialogAttachment,
    DialogRecount: DialogRecount
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {},
      tableData: [],
      query: {},
      showSMSDialog: false,
      // 设置短信弹窗
      showGoldPriceDialog: false,
      // 设置金价弹窗
      showOrgDDDialog: false,
      //同步钉钉机构及人员界面
      showAttachmentDialog: false,
      //附件重命名
      showReCountDialog: false,
      //合同重新核算
      currentRow: {} // 当前点击行

    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = []; //this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData() {
      this.tableData = [{
        id: "toolClearCache",
        toolName: "清除服务器缓存",
        description: "清除服务器缓存，不影响当前使用"
      }, {
        id: "toolSetupSMS",
        toolName: "设置短信开关",
        description: "启用 / 关闭 发送短信服务，测试环境请关闭短信"
      }, {
        id: "toolSynGoldPrice",
        toolName: "同步上交所金价",
        description: "行情抓取自上海黄金交易所 https://www.sge.com.cn/sjzx/yshqbg"
      }, {
        id: "toolSyncOrg",
        toolName: "同步钉钉机构和人员",
        description: "为减少第三方接口调用，请尽量精确同步所在的机构"
      }, {
        id: "toolAttachment",
        toolName: "合同附件",
        description: "合同附件新增，删除，重命名"
      }, {
        id: "toolReCount",
        toolName: "重新生成核算",
        description: "注意重新核算，后续的赎回，付息，续约数据都会清除，需要做好数据备份工作"
      }];
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    //清除服务器缓存
    clickClearCache: function clickClearCache() {
      var _this = this;

      cleanCache().then(function (res) {
        _this.$message({
          message: "已清除",
          type: "success"
        });
      });
    },
    //设置短信开关
    clickSetupSMS: function clickSetupSMS() {
      this.showSMSDialog = true;
    },
    //同步金价
    clickSyncGoldPrice: function clickSyncGoldPrice() {
      this.showGoldPriceDialog = true;
    },
    //同步钉钉机构及人员
    clickSyncOrgDD: function clickSyncOrgDD() {
      this.showOrgDDDialog = true;
    },
    //合同附件
    clickAttachment: function clickAttachment() {
      this.showAttachmentDialog = true;
    },
    //重新核算
    clickReCount: function clickReCount() {
      this.showReCountDialog = true;
    }
  },
  created: function created() {}
};