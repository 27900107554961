var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
      attrs: {
        width: "65%",
        title: _vm.title,
        visible: _vm.showDialog,
        closeOnClickModal: false,
        "append-to-body": true
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.rules,
            "label-width": "100px",
            size: "mini"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "部门编号", prop: "orgCd" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formModel.orgCd,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "orgCd", $$v)
                          },
                          expression: "formModel.orgCd"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "部门名称", prop: "orgName" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formModel.orgName,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "orgName", $$v)
                          },
                          expression: "formModel.orgName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "加入上级部门日期",
                        prop: "joinSuperActualDate"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "加入上级部门日期",
                          "value-format": "yyyy-MM-dd",
                          disabled: true
                        },
                        model: {
                          value: _vm.formModel.joinSuperActualDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "joinSuperActualDate", $$v)
                          },
                          expression: "formModel.joinSuperActualDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "离开上级部门日期",
                        prop: "leaveSuperActualDate"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "离开上级部门日期",
                          "value-format": "yyyy-MM-dd",
                          disabled: true
                        },
                        model: {
                          value: _vm.formModel.leaveSuperActualDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "leaveSuperActualDate", $$v)
                          },
                          expression: "formModel.leaveSuperActualDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "统计类型", prop: "statisticalType" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.formModel.statisticalType,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "statisticalType", $$v)
                            },
                            expression: "formModel.statisticalType"
                          }
                        },
                        _vm._l(_vm.selectData.statisticalTypeList, function(
                          item
                        ) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "统计归属部门类型", prop: "belongType" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          on: { change: _vm.belongTypeChange },
                          model: {
                            value: _vm.formModel.belongType,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "belongType", $$v)
                            },
                            expression: "formModel.belongType"
                          }
                        },
                        _vm._l(_vm.selectData.belongTypeList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _vm.formModel.belongType !== "3"
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "统计归属部门",
                            prop: "superiorOrgCd"
                          }
                        },
                        [
                          _c("el-cascader", {
                            attrs: {
                              "show-all-levels": false,
                              options: _vm.selectData.arrayOrg,
                              props: {
                                //expandTrigger: 'hover',
                                checkStrictly: true,
                                emitPath: false,
                                value: "orgCd",
                                label: "orgName",
                                children: "children"
                              }
                            },
                            on: { change: _vm.superiorOrgCdChange },
                            model: {
                              value: _vm.formModel.superiorOrgCd,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "superiorOrgCd", $$v)
                              },
                              expression: "formModel.superiorOrgCd"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "统计归属部门",
                            prop: "superiorOrgCd"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              on: { change: _vm.superiorOrgCdChange },
                              model: {
                                value: _vm.formModel.superiorOrgCd,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "superiorOrgCd", $$v)
                                },
                                expression: "formModel.superiorOrgCd"
                              }
                            },
                            _vm._l(_vm.selectData.superiorOrgCdList, function(
                              item
                            ) {
                              return _c("el-option", {
                                key: item.orgCd,
                                attrs: {
                                  label: item.orgName,
                                  value: item.orgCd
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "统计归属部门编号",
                        prop: "superiorOrgCd"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formModel.superiorOrgCd,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "superiorOrgCd", $$v)
                          },
                          expression: "formModel.superiorOrgCd"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "统计归属开始日期", prop: "beginTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "统计归属开始日期",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.formModel.beginTime,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "beginTime", $$v)
                          },
                          expression: "formModel.beginTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "统计归属结束日期", prop: "endTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "date",
                          placeholder: "统计归属结束日期",
                          "value-format": "yyyy-MM-dd"
                        },
                        model: {
                          value: _vm.formModel.endTime,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "endTime", $$v)
                          },
                          expression: "formModel.endTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini", loading: _vm.loading },
              on: { click: _vm.clickSave }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }