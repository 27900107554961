import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
import basicSetting from './basicSetting/index.vue';
import advancedSetting from './advancedSetting/index.vue';
export default {
  name: 'ActivityAdd',
  components: {
    basicSetting: basicSetting,
    advancedSetting: advancedSetting
  },
  data: function data() {
    return {
      activeIndex: 0,
      currentComponent: basicSetting,
      jTabData: {
        type: 'tabs',
        options: [{
          label: '基础设置',
          value: 0
        }, {
          label: '高级设置',
          value: 1
        }],
        prop: 'status',
        col: 6,
        labelWidth: 120
      },
      modelData: {}
    };
  },
  mounted: function mounted() {// this.modelData = {
    //   ...this.$refs.basic?.form.model,
    //   ...this.$refs.advanced?.form.model
    // }
  },
  methods: {
    /* tab切换 */
    tabsChange: function tabsChange(val) {
      var _this$$refs$advanced,
          _this$$refs$basic,
          _this = this;

      switch (val.status) {
        case 0:
          this.modelData = _objectSpread({}, (_this$$refs$advanced = this.$refs.advanced) === null || _this$$refs$advanced === void 0 ? void 0 : _this$$refs$advanced.form.model);
          break;

        case 1:
          this.modelData = _objectSpread({}, (_this$$refs$basic = this.$refs.basic) === null || _this$$refs$basic === void 0 ? void 0 : _this$$refs$basic.form.model);
          break;
      }

      setTimeout(function () {
        _this.activeIndex = val.status;
      }, 500);
    }
  }
};