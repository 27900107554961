//回访
export default {
  APPLY: {
    value: '0',
    text: '申请中'
  },
  SUCCESS: {
    value: '1',
    text: '成功'
  },
  FAILURE: {
    value: '2',
    text: '失败'
  },
  ACTION: {
    value: '3',
    text: '回访中'
  },
  ABANDON: {
    value: '4',
    text: '作废'
  }
};