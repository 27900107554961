import "core-js/modules/es.object.to-string.js";
//工具
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import func from '@/maps/url/rm/tool'; // TODO 清理缓存

export function cleanCache() {
  return request.post(func.cleanCache, {});
} // TODO 消息提醒：获取当前手工模式是否启动

export function getRemindSwitch(params) {
  return request.post(func.getRemindSwitch, {
    body: params
  });
} // TODO 消息提醒：手动启动

export function setRemindSwitch(params) {
  return request.post(func.setRemindSwitch, {
    body: params
  });
} // TODO 短信：获取当前是否启动

export function getSmsSwitch(params) {
  return request.post(func.getSmsSwitch, {
    body: params
  });
} // TODO 短信：手动启动

export function setSmsSwitch(params) {
  return request.post(func.setSmsSwitch, {
    body: params
  });
} // TODO 同步金价

export function syncGoldPrice(params) {
  return request.post(func.syncGoldPrice, {
    body: params
  });
} // TODO 同步钉钉机构和用户，只同步指定机构的直接下属机构

export function syncOrgFromDing(params) {
  return request.post(func.syncOrgFromDing, {
    body: params
  });
} // TODO 合同重新核算备份相关数据

export function reCountBackup(params) {
  return blobRequest.post(func.reCountBackup, {
    body: params
  }).then(function (blob) {
    util.download(blob, params.contractCd + '核算备份' + '.txt');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 合同重新核算

export function reCount(params) {
  return request.post(func.reCount, {
    body: params
  });
}