



























































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/merchantManagement/receivingPaymentManagement/index.vue"
}
