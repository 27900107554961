import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { formatAmountToNumber, formatAmountToString } from "@/libs/crm/format";
import { submit } from "@api/crm/clear/clear";
export default {
  name: "contract-clear-batch",
  props: {
    propsModel: {
      type: Array,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      showLogDialog: false,
      tableData: [],
      selectData: {},
      formModel: {},
      //表单
      rules: {}
    };
  },
  created: function created() {},
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    loadData: function loadData() {
      this.tableData = this.propsModel;
    },
    //确认提前终止
    clickSubmitSuspend: function clickSubmitSuspend() {
      var _this = this;

      if (this.formModel.advice == null || this.formModel.advice == "") {
        this.$message({
          message: "请填写提前终止原因",
          type: "info"
        });
      } else {
        this.$confirm("确定提前终止合同吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this.clickClearBatch("6");
        }).catch(function () {});
      }
    },
    //确认到期清算
    clickSubmitPayed: function clickSubmitPayed() {
      var _this2 = this;

      this.$confirm("确定手动到期清算合同吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this2.clickClearBatch("7");
      }).catch(function () {});
    },
    //批量清算
    clickClearBatch: function clickClearBatch(result) {
      var _this3 = this;

      var arrayContractId = [];
      this.tableData.forEach(function (n) {
        arrayContractId.push(n.contractId);
      });
      var param = {
        arrayContractId: arrayContractId,
        applyMethod: result,
        advice: this.formModel.advice
      };
      this.loading = true;
      submit(param).then(function (res) {
        _this3.loading = false;
        _this3.showDialog = false;

        _this3.$emit("refresh");
      }).catch(function (e) {
        _this3.loading = false;
      });
    },
    //列表删除
    clickRemove: function clickRemove(row) {
      for (var i = 0; i < this.propsModel.length; i++) {
        if (this.propsModel[i].contractId == row.contractId) {
          this.propsModel.splice(i, 1);
          break;
        }
      }
    },
    //统计列表
    sumTable: function sumTable(param) {
      var array = [];
      var sumAmount = 0;
      var sumExpectEarnings = 0;

      for (var i = 0; i < param.data.length; i++) {
        var n = param.data[i];
        sumAmount += formatAmountToNumber(n.amount);
        sumExpectEarnings += formatAmountToNumber(n.expectEarnings);
      }

      array[0] = "合计";
      array[7] = formatAmountToString(sumAmount);
      array[8] = formatAmountToString(sumExpectEarnings);
      return array;
    }
  }
};