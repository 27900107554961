import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { formatAmountToString } from "@/libs/crm/format";
import { sumBatch } from "@api/crm/commission/commission";
export default {
  name: "commission-summary",
  props: {
    propsModel: {
      type: String,
      default: ""
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      tableDataUser: [],
      tableDataProduct: [],
      currentRow: {} // 当前点击行

    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableDataUser = [];
      this.tableDataProduct = [];
    },
    dialogClose: function dialogClose() {},
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    loadData: function loadData() {
      var _this = this;

      var param = {
        batchId: this.propsModel
      };
      sumBatch(param).then(function (res) {
        var sumUser = 0; //按个人合计

        var sumYearAmount = 0; //按产品合计

        _this.tableData = [];

        if (res.data != null) {
          res.data.user.forEach(function (item) {
            sumUser += parseFloat(item.sum);
            item.sum = formatAmountToString(item.sum);
          });
          res.data.product.forEach(function (item) {
            sumYearAmount += parseFloat(item.sumYearAmount);
            item.sumYearAmount = formatAmountToString(item.sumYearAmount);
          });
          _this.tableDataUser = res.data.user;
          _this.tableDataProduct = res.data.product; //补充一条合计

          _this.tableDataUser.push({
            product: "合计",
            sum: formatAmountToString(sumUser)
          });

          _this.tableDataProduct.push({
            product: "合计",
            sumYearAmount: formatAmountToString(sumYearAmount)
          });
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    }
  }
};