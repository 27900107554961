










































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/report/product/withdrawalDetail/index.vue"
}
