import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { FILTER_PROPS, FILTER_FORMS } from './option/filter';
import TABLE_LIST from './option/list'; // 表头

import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import { bankCardList } from '@api/crm/customer/bankCard';
export default {
  name: 'openingBank-messageSearch',
  created: function created() {},
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable
  },
  data: function data() {
    return {
      loading: false,
      loadingBtu2: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      query: {},
      model: {},
      // 合同信息
      currentRow: {},
      // 当前点击行
      table: {},
      // 多选
      headers: TABLE_LIST,
      // 表头
      pagination: {},
      tableData: []
    };
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    // 初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    // 点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    // 点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      var _this = this;

      this.initFilter();
      this.$nextTick(function () {
        _this.$refs.jTable.pageChange(1);
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this2 = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      var param = {
        page: page,
        query: this.query
      };
      this.loading = true;
      bankCardList(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this2.loading = false;
        _this2.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this2.tableData = data;
          _this2.pagination = {
            total: totalCount
          };
        } else {
          _this2.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this2.loading = false;

        _this2.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    }
  }
};