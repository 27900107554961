var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }),
      _c(
        "el-table",
        {
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "oldAgentUserName",
              label: "原理顾名字",
              width: "180"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "newAgentUserName",
              label: "新理顾名字",
              width: "180"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "createUserName", label: "创建人名字" }
          }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "创建时间" }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-center" },
        [
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0"
              }
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.page.pageNum,
              limit: _vm.listQuery.page.pageSize
            },
            on: {
              "update:page": function($event) {
                return _vm.$set(_vm.listQuery.page, "pageNum", $event)
              },
              "update:limit": function($event) {
                return _vm.$set(_vm.listQuery.page, "pageSize", $event)
              },
              pagination: _vm.getList
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }