import "core-js/modules/es.object.to-string.js";
// 核算
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatDate, formatUserDataPromise } from '@/libs/crm/format';
import func from '@/maps/url/crm/count/count'; // TODO 创建

export function create(params) {
  var re = request.post(func.create, {
    body: params
  });
  return re;
} // TODO 保存

export function save(params) {
  var re = request.post(func.save, {
    body: params
  });
  return re;
} // TODO 提交

export function submit(params) {
  var re = request.post(func.submit, {
    body: params
  });
  return re;
} // TODO 删除

export function remove(params) {
  var re = request.post(func.remove, {
    body: params
  });
  return re;
} // TODO 客服审核

export function auditService(params) {
  var re = request.post(func.auditService, {
    body: params
  });
  return re;
} // TODO 结算审核

export function auditCount(params) {
  var re = request.post(func.auditCount, {
    body: params
  });
  return re;
} // TODO 财务审核

export function auditFinance(params) {
  var re = request.post(func.auditFinance, {
    body: params
  });
  return re;
} // TODO 列表

export function list(params, formatType) {
  var re = request.post(func.list, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 详情

export function getInfo(params, formatType) {
  var re = request.post(func.getInfo, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 获取原始核算详情

export function getInitCount(params, formatType) {
  var re = request.post(func.getInitCount, {
    body: params
  });
  formatUserDataPromise(re, formatType); // 用户数据格式化

  return re;
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '核算' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 合同结算列表

export function listSettle(params, formatType) {
  var re = request.post(func.listSettle, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 合同结算

export function settleContactBatch(params) {
  var re = request.post(func.settleContactBatch, {
    body: params
  });
  return re;
} // TODO 计算应付日期

export function computePayableDate(params) {
  var re = request.post(func.computePayableDate, {
    body: params
  });
  return re;
} // TODO 批量计算应付日期

export function computePayableDateBatch(params) {
  var re = request.post(func.computePayableDateBatch, {
    body: params
  });
  return re;
}