//产品收益率
export default {
  list: '/product/rate/list',
  // 列表
  getInfo: '/product/rate/getInfo',
  // 详情
  add: '/product/rate/add',
  // 新增
  edit: '/product/rate/edit',
  // 编辑
  remove: '/product/rate/remove',
  // 删除
  getRate: '/product/rate/getRate',
  // 获取收益率
  save: '/product/rate/save',
  // 保存
  exportExcel: '/product/rate/exportExcel' // 导出Excel

};