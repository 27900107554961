// 机构
export default {
  add: '/org/add',
  // 新增机构
  edit: '/org/edit',
  // 编辑机构
  remove: '/org/remove',
  // 删除机构
  getInfo: '/org/getInfo',
  // 详情
  getOrgTree: '/org/getTree',
  // 获取机构树
  getAllTree: '/org/getAllTree',
  // 机构树全树，没有权限管理
  exportExcel: '/org/exportExcel',
  // 导出
  orgHeadAdd: '/orgHead/add',
  // 新增机构负责人
  orgHeadEdit: '/orgHead/edit',
  // 编辑机构负责人
  orgHeadRemove: '/orgHead/remove',
  // 删除机构负责人
  orgHeadList: '/orgHead/list',
  // 机构负责人列表
  orgHeadGetInfo: '/orgHead/getInfo',
  // 机构负责人详情
  orgSuperiorAdd: '/orgSuperior/add',
  // 新增上级机构
  orgSuperiorEdit: '/orgSuperior/edit',
  // 编辑上级机构
  orgSuperiorRemove: '/orgSuperior/remove',
  // 删除上级机构
  orgSuperiorList: '/orgSuperior/list',
  // 上级机构列表
  orgSuperiorGetInfo: '/orgSuperior/getInfo' // 上级机构详情

};