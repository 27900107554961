// 流水银行
export default {
  JS: {
    value: '0',
    text: '建行和平支行'
  },
  TL2: {
    value: '1',
    text: '通联2'
  },
  GS_XM: {
    value: '2',
    text: '香梅支行(工行)'
  },
  GS_JYF: {
    value: '3',
    text: '云金雅福银行流水(工行)'
  },
  JYFPOS: {
    value: '4',
    text: '云金雅福流水'
  },
  TL2015: {
    value: '5',
    text: '通联2015'
  },
  PA: {
    value: '6',
    text: '云金雅福平安银行流水'
  },
  ZX: {
    value: '7',
    text: '云金雅福中信银行流水'
  },
  MS: {
    value: '8',
    text: '金雅福首饰民生'
  },
  GS: {
    value: '9',
    text: '金雅福首饰工商'
  },
  ZSYB: {
    value: '10',
    text: '中山易宝网络科技'
  },
  JSBC: {
    value: '11',
    text: '建设宝城支行'
  },
  GLYH: {
    value: '12',
    text: '桂林银行深圳支行'
  },
  MS_JYFWH: {
    value: '13',
    text: '金雅福传承文化首饰民生'
  },
  NY_JYFWH: {
    value: '14',
    text: '金雅福传承文化首饰农行'
  },
  NY_SSZZ: {
    value: '15',
    text: '首饰制造农业'
  }
};