var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("img", {
        staticStyle: { width: "138px" },
        attrs: { src: require("./image/d2-help-button@2x.png") },
        on: {
          click: function($event) {
            _vm.dialogVisible = true
          }
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "帮助",
            width: "600px",
            visible: _vm.dialogVisible,
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: { "margin-top": "-25px", "margin-bottom": "-25px" }
            },
            [
              _c(
                "el-button-group",
                { staticClass: "d2-mb" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$open(
                            "https://github.com/d2-projects/d2-admin"
                          )
                        }
                      }
                    },
                    [
                      _c("d2-icon", {
                        staticClass: "d2-mr-5",
                        attrs: { name: "github" }
                      }),
                      _vm._v(" 主页 ")
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$open(
                            "https://fairyever.com/d2-admin/doc/zh/"
                          )
                        }
                      }
                    },
                    [
                      _c("d2-icon", {
                        staticClass: "d2-mr-5",
                        attrs: { name: "book" }
                      }),
                      _vm._v(" 中文文档 ")
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$open(
                            "https://github.com/d2-projects/d2-admin/issues"
                          )
                        }
                      }
                    },
                    [
                      _c("d2-icon", {
                        staticClass: "d2-mr-5",
                        attrs: { name: "question" }
                      }),
                      _vm._v(" issues ")
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$open(
                            "https://github.com/d2-projects/d2-admin/issues/new/choose"
                          )
                        }
                      }
                    },
                    [
                      _c("d2-icon", {
                        staticClass: "d2-mr-5",
                        attrs: { name: "plus" }
                      }),
                      _vm._v(" New issue ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-alert", {
                        staticClass: "d2-mb",
                        attrs: {
                          closable: false,
                          type: "info",
                          title: "QQ群 [ 1500+ / 2000 人 ]"
                        }
                      }),
                      _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: { src: require("./image/qq.jpg") }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-alert", {
                        staticClass: "d2-mb",
                        attrs: {
                          closable: false,
                          type: "info",
                          title: "与作者成为好友后邀请进微信群"
                        }
                      }),
                      _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: { src: require("./image/we.jpg") }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }