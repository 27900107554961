





















































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/assign/service/components/dialog-audit.vue"
}
