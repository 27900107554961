





































































































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/buy/service/components/dialog-audit.vue"
}
