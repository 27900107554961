//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '产品',
  prop: 'productName',
  width: '350px'
}, {
  label: '积存方式',
  prop: 'saveTypeText',
  width: VAL_WIDTH
}, {
  label: '期限下限',
  prop: 'timeLimitStart',
  width: VAL_WIDTH
}, {
  label: '期限上限',
  prop: 'timeLimitEnd',
  width: VAL_WIDTH
}, {
  label: '资金下限',
  prop: 'amountStart',
  width: '160',
  align: 'right'
}, {
  label: '资金上限',
  prop: 'amountEnd',
  width: '160',
  align: 'right'
}];