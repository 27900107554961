var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        top: "10vh",
        width: "850px",
        title: "客户合同",
        visible: _vm.showDialog
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            "row-style": { height: "20px" },
            "cell-style": { padding: "6px 0" },
            hight: "350px",
            "highlight-current-row": ""
          },
          on: { "current-change": _vm.selectRow }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "contractCd", label: "合同编号", width: "180" }
          }),
          _c("el-table-column", {
            attrs: { prop: "productName", label: "产品", width: "180" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "amount",
              label: "投资金额",
              width: "120",
              align: "right"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "startDate", label: "开始日期", width: "110" }
          }),
          _c("el-table-column", {
            attrs: { prop: "endDate", label: "结束日期", width: "110" }
          }),
          _c("el-table-column", {
            attrs: { prop: "contractStateText", label: "状态", width: "110" }
          })
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.page.pageNumber,
          "page-sizes": [10, 20, 30, 40],
          "page-size": _vm.page.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _vm.page.totalCount
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange
        }
      }),
      _c("div", {
        staticClass: "dialog-footer",
        attrs: { slot: "footer" },
        slot: "footer"
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }