// 表头
var VAL_WIDTH = '120px';
export default [{
  label: '产品编码',
  prop: 'productId',
  width: '140px'
}, {
  label: '产品名称',
  prop: 'productName',
  width: '120px'
}, {
  label: '计算公式',
  prop: 'formulaName',
  width: '180px'
}, {
  label: '比例编码',
  prop: 'ratioCode',
  width: '80px'
}, {
  label: '比例名称',
  prop: 'ratioName',
  type: 'slot',
  width: '400px'
}, {
  label: '适用合同开始日期起',
  prop: 'startDate',
  width: '120px'
}, {
  label: '适用合同开始日期起止',
  prop: 'endDate',
  width: '120px'
}, {
  label: '适用架构范围',
  prop: 'orgName',
  width: '230px'
}, {
  label: '状态',
  prop: 'enabled',
  type: 'slot',
  width: '100px'
}, {
  label: '创建时间',
  prop: 'createTime',
  width: '150px'
}, {
  label: '更新时间',
  prop: 'updateTime',
  width: '150px'
}];