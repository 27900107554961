//列表项
var VAL_WIDTH = '100px'; // 表头

export default [{
  label: '推介费表名称',
  prop: 'remark',
  width: '400px'
}, {
  label: '状态',
  prop: 'status',
  width: '100px',
  type: 'slot'
}, {
  label: '创建日期',
  prop: 'createDate',
  width: VAL_WIDTH
}];