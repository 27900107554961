// 表头
export default [{
  label: '客户名称',
  prop: 'customerName',
  width: '150px',
  type: 'slot'
}, {
  label: '客户类型',
  prop: 'customerType',
  width: '150px'
}, {
  label: '客户等级',
  prop: 'level',
  width: '150px'
}, {
  label: '部门',
  prop: 'orgName',
  width: '150px'
}, {
  label: '阅读次数',
  prop: 'readingVolume',
  width: '150px'
}, {
  label: '阅读时间',
  prop: 'updateTime',
  width: '180px'
}];