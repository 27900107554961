//参数设置
export default {
  list: '/setup/parameter/list',
  // 列表
  save: '/setup/parameter/save',
  // 保存  
  remove: '/setup/parameter/remove',
  // 删除
  getInfo: '/setup/parameter/getInfo' // 详情

};