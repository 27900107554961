import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
// 推介费
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatDate, formatUserDataPromise } from '@/libs/crm/format';
import func from '@/maps/url/crm/commissionV3/commissionV3'; // 产品推介费
// TODO 产品推介费新增编辑

export function commissionSave(params) {
  return request.post(func.commissionSave, {
    body: params
  });
} // TODO 产品推介费列表

export function commissionList(params) {
  return request.post(func.commissionList, {
    body: params
  });
} // TODO 产品推荐费详情

export function commissionGetInfo(params) {
  return request.post(func.commissionGetInfo, {
    body: params
  });
} // TODO 产品推介费导出

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '产品推介费' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 产品下拉选择

export function getDropDownList(params) {
  return request.post(func.getDropDownList, {
    body: params
  });
} // 推荐费比例
// TODO 推荐费比例新增或编辑

export function commissionFormulaSave(params) {
  return request.post(func.commissionFormulaSave, {
    body: params
  });
} // TODO 推荐费比例列表

export function commissionFormulaList(params) {
  return request.post(func.commissionFormulaList, {
    body: params
  });
} // TODO 推荐费比例详情

export function commissionFormulaGetInfo(params) {
  return request.post(func.commissionFormulaGetInfo, {
    body: params
  });
} // TODO 推介费比例导出

export function formulaExportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.formulaExportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '推介费比例' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 推介费比例公式下拉选择列表

export function formulaDropDownList(params) {
  return request.post(func.formulaDropDownList, {
    body: params
  }).then(function (res) {
    res.data = res.data.map(function (item) {
      return {
        value: item.id,
        text: item.name,
        formula: item.formula
      };
    });
    return res.data;
  });
} // 合同推介费
// TODO 批量创建自定义计划

export function commissionSavePlanAndApplyBatch(params) {
  return request.post(func.commissionSavePlanAndApplyBatch, {
    body: params
  });
} // 提交自定义计划

export function commissionSubmitPlanAndApplyBatch(params) {
  return request.post(func.commissionSubmitPlanAndApplyBatch, {
    body: params
  });
} // 推介费申请列表
// TODO 列表 formatType格式化类型：0不格式化，1只格式化金额，2按用户保密设置设置

export function commissionListApplication(params, formatType) {
  var re = request.post(func.commissionListApplication, {
    body: params
  });
  formatUserDataPromise(re, formatType); // 用户数据格式化

  return re;
}
export function commissionReferralGetInfo(params, formatType) {
  var re = request.post(func.commissionReferralGetInfo, {
    body: params
  });
  formatUserDataPromise(re, formatType); // 用户数据格式化

  return re;
} //导出推介费计划列表

export function commissionExportExcelApplication(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.commissionExportExcelApplication, {
    body: params
  }).then(function (blob) {
    util.download(blob, '推介费计划列表' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // 获取待分配计划
//TODO 列表 formatType格式化类型：0不格式化，1只格式化金额，2按用户保密设置设置

export function commissionGetWaitAllocation(params, formatType) {
  var re = request.post(func.commissionGetWaitAllocation, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // 查询合同预期推介费列表
//TODO 列表 formatType格式化类型：0不格式化，1只格式化金额，2按用户保密设置设置

export function commissionListExpectCommission(params, formatType) {
  var re = request.post(func.commissionListExpectCommission, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // 批量保存分配

export function commissionSaveAllocation(params) {
  return request.post(func.commissionSaveAllocation, {
    body: params
  });
} //查询导出

export function commissionExportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.commissionExportExcel, {
    body: params
  }).then(function (blob) {
    if (params.process === '3') {
      util.download(blob, '推介费计划列表' + date + '.xlsx');
    } else if (params.process === '1') {
      util.download(blob, '推介费分配列表' + date + '.xlsx');
    } else if (params.process === '4') {
      util.download(blob, '推介费审核列表' + date + '.xlsx');
    } else {
      util.download(blob, '推介费明细' + date + '.xlsx');
    }
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // 审核

export function commissionAudit(params) {
  return request.post(func.commissionAudit, {
    body: params
  });
} // 删除计划和申请

export function commissionRemovePlanAndApply(params) {
  return request.post(func.commissionRemovePlanAndApply, {
    body: params
  });
} // 批量资金审核

export function commissionAuditMoneyBatch(params) {
  return request.post(func.commissionAuditMoneyBatch, {
    body: params
  });
} // 导入导出
// 导出待分配人员推介费Excel

export function exportExcelWaitAllocation(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelWaitAllocation, {
    body: params
  }).then(function (blob) {
    util.download(blob, '分配人员推介费模版' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // 导出待分配人员推介费导入结果

export function exportExcelWaitAllocationResult(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelWaitAllocationResult, {
    body: params
  }).then(function (blob) {
    util.download(blob, '分配人员推介费导入结果' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 导入Excel流水

export function importExcelWaitAllocation(params) {
  return request.post(func.importExcelWaitAllocation, params);
} // 导出分区人员汇总报表

export function exportExcelSumOrgPerson(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelSumOrgPerson, {
    body: params
  }).then(function (blob) {
    util.download(blob, '人员汇总报表' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // 导出分区月份汇总报表

export function exportExcelSumOrgMonth(params) {
  var date = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  // let date = formatDate(new Date());
  return blobRequest.post(func.exportExcelSumOrgMonth, {
    body: params
  }).then(function (blob) {
    util.download(blob, '直营推介费合计报表' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 分区人员列表

export function listSumOrgPerson(params, formatType) {
  var re = request.post(func.listSumOrgPerson, {
    body: params
  });
  formatUserDataPromise(re, formatType); // 用户数据格式化

  return re;
} // TODO 分区月份列表

export function listSumOrgMonth(params, formatType) {
  var re = request.post(func.listSumOrgMonth, {
    body: params
  });
  formatUserDataPromise(re, formatType); // 用户数据格式化

  return re;
}