



































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/bill/gift/index.vue"
}
