import "core-js/modules/es.object.to-string.js";
// 纸合同归档
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import util from '@/libs/util';
import { formatDate, formatUserDataPromise } from '@/libs/crm/format';
import func from '@/maps/url/crm/paper/box'; // TODO 保存

export function save(params) {
  var re = request.post(func.save, {
    body: params
  });
  return re;
} // TODO 删除柜子

export function removeBox(params) {
  var re = request.post(func.removeBox, {
    body: params
  });
  return re;
} // TODO 合同柜列表

export function listBox(params) {
  var re = request.post(func.listBox, {
    body: params
  });
  return re;
} // TODO 未归档合同列表

export function listNotArchive(params, formatType) {
  var re = request.post(func.listNotArchive, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 合同归档

export function archive(params) {
  var re = request.post(func.archive, {
    body: params
  });
  return re;
} // TODO 删除归档合同

export function removeArchive(params) {
  var re = request.post(func.removeArchive, {
    body: params
  });
  return re;
} // TODO 归档合同列表

export function listArchive(params, formatType) {
  var re = request.post(func.listArchive, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 合同归档导出excel

export function exportExcelArchive(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelArchive, {
    body: params
  }).then(function (blob) {
    util.download(blob, '归档合同' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
}