import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
var PUBLIC_CONFIG = {
  labelWidth: 80
};
export var TOP_FORM = {
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '广告名称',
    props: {
      placeholder: '请输入标题'
    }
  }),
  addressLocationId: {
    labelWidth: 100,
    type: 'select',
    label: '广告位名称',
    props: {
      placeholder: '请选择广告位名称'
    },
    options: []
  },
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '状态',
    props: {
      placeholder: '请选择状态'
    },
    options: [{
      label: '启用',
      value: 1
    }, {
      label: '禁用',
      value: 0
    }]
  })
};
export var TABLE_LABEL = [{
  label: '广告位名称',
  prop: 'addressLocationName'
}, {
  label: '广告名称',
  prop: 'name'
}, {
  label: '描述',
  prop: 'describe'
}, {
  label: '广告内容',
  prop: 'pictureUrl',
  type: 'img',
  formatter: function formatter(row, column, cellValue) {
    return "".concat(process.env.VUE_APP_CLOUD_UPLOAD).concat(row.pictureUrl);
  }
}, {
  label: '优先级',
  prop: 'sort',
  // type: 'inputNumber',
  width: 200,
  props: {
    max: 999
  }
}, {
  label: '点击次数',
  prop: 'numberHits'
}, {
  label: '状态',
  prop: 'status',
  width: 150,
  formatter: function formatter(row) {
    var res = '';

    switch (row.status) {
      case 0:
        res = '禁用';
        break;

      case 1:
        res = '启用';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  type: 'operation',
  label: '操作',
  width: 200,
  formatter: function formatter() {
    var arr = [{
      type: 'edit',
      label: '修改',
      permission: 'advertising-edit'
    }, {
      type: 'delete',
      label: '删除',
      permission: 'advertising-delete'
    }];
    return arr;
  }
}];
export var TABLE_CONFIG = {
  actions: [{
    label: '新增广告',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'advertising-edit'
  }]
};