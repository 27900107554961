//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DialogRemind from "./components/dialog-remind";
import { listPayment } from "@api/crm/remind/remind";
import api from '@/api-new';
import util from '@/libs/util.js';
export default {
  name: "header-remind",
  components: {
    DialogRemind: DialogRemind
  },
  data: function data() {
    return {
      showRemindDialog: false,
      tableData: [],
      sumData: 0,
      //消息提醒
      timer: null,
      //计时器，用户消息提醒
      timer2: null //计时器，用户需付款紧急消息提醒

    };
  },
  computed: {},
  created: function created() {
    //拼接html片段中的onClick事件生效    
    var _this = this;

    window.routerChange = _this.routerChange;
    this.remindUser(); //立即调用一次更新消息

    /*
    this.remindUser();//立即调用一次更新消息
    this.timer = setInterval(this.remindUser, 3 * 60000); //设置之后每3分钟更新消息数    
    this.remindPayment();
    this.timer2 = setInterval(this.remindPayment, 30 * 60000); //每30分钟更新重要提醒消息数
    */
  },
  methods: {
    clickShowRemind: function clickShowRemind() {
      this.remindUser();
      this.showRemindDialog = true;
    },
    //消息提醒
    remindUser: function remindUser() {
      var _this2 = this;

      this.loading = true;
      api.USER_REMIND().then(function (res) {
        _this2.tableData = res;
        _this2.sumData = 0;

        for (var i = 0; i < _this2.tableData.length; i++) {
          var n = _this2.tableData[i];
          _this2.sumData += n.doToCount;
          _this2.sumData += n.leastCount;
          _this2.sumData += n.overdueCount;
        }

        var needShowTips = util.cookies.get('needShowTips') || false;

        if (needShowTips === 'true') {
          _this2.clickShowRemind();

          util.cookies.set('needShowTips', false);
        }
      }).catch(function (e) {
        clearInterval(_this2.timer);
      });
    },
    //未来几天需付款紧急消息提醒
    //10:00 ~ 11:00，14:00~15:00 每30分钟提醒

    /*
    remindPayment() {
      let curDate = new Date();
      let hour = curDate.getHours();
      if ((hour >= 9 && hour <= 10) || (hour >= 14 && hour <= 15)) {
        listPayment()
          .then((res) => {
            let message = "";
            res.data.forEach((n) => {
              if (n.count > 0) {
                message += "<div onClick='routerChange(\"" + n.menuUrl + "\")'>" + n.menuName + ": " + n.count + "</div>";
              }
            });
            if (message != "") {
              this.$notify({
                title: "您有以下待处理消息:",
                dangerouslyUseHTMLString: true,
                position: "bottom-right",
                message: message,
                duration: 0, //手动关闭
              });
            }
          })
          .catch((e) => {
            clearInterval(this.timer2);
          });
      }
    },
    */
    //跳转路由
    routerChange: function routerChange(url) {
      this.$router.push(url);
    }
  }
};