































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/integralGift/integralRule/index.vue"
}
