var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c("j-page", {
        ref: "jPage",
        attrs: {
          filters: _vm.topForm,
          "filters-data": _vm.filtersData,
          filtersFormatter: _vm.filtersFormatter,
          table: _vm.tableConfig,
          "table-label": _vm.tableLabel,
          "http-config": _vm.httpConfig
        },
        on: {
          "update:filtersData": function($event) {
            _vm.filtersData = $event
          },
          "update:filters-data": function($event) {
            _vm.filtersData = $event
          },
          "table-click": _vm.tableClick,
          "table-row-click": _vm.tableRowClick
        },
        scopedSlots: _vm._u([
          {
            key: "status",
            fn: function(scope) {
              return [
                scope.row.status != 2
                  ? _c("span", [
                      _vm._v(_vm._s(_vm.auditStateTest(scope.row.status)))
                    ])
                  : _vm._e(),
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "right-start",
                      title: "审核失败原因",
                      trigger: "hover",
                      content: scope.row.failureReason
                    }
                  },
                  [
                    scope.row.status === 2
                      ? _c(
                          "span",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [
                            _vm._v(
                              _vm._s(_vm.auditStateTest(scope.row.status)) + " "
                            ),
                            _c("i", { staticClass: "el-icon-chat-dot-round" })
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            }
          }
        ])
      }),
      _c(
        "j-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
              modifiers: { fullscreen: true, lock: true }
            }
          ],
          attrs: {
            visible: _vm.addVisible,
            title: _vm.addTitle,
            width: "35%",
            height: "700px"
          },
          on: {
            "update:visible": function($event) {
              _vm.addVisible = $event
            },
            confirm: _vm.addDialogConfirm,
            close: _vm.close
          }
        },
        [
          _c("j-form", {
            ref: "form",
            attrs: {
              "form-config": _vm.addFormConfig,
              "form-data": _vm.addFormData,
              "foot-active": false
            },
            on: {
              "update:formData": function($event) {
                _vm.addFormData = $event
              },
              "update:form-data": function($event) {
                _vm.addFormData = $event
              },
              change: _vm.formChange,
              submit: function($event) {
                return _vm.FormSubmit($event)
              }
            }
          })
        ],
        1
      ),
      _c(
        "j-dialog",
        {
          attrs: {
            visible: _vm.paymentRecordDialog,
            title: "付款记录",
            width: "70%",
            height: "700px"
          },
          on: {
            "update:visible": function($event) {
              _vm.paymentRecordDialog = $event
            },
            close: _vm.paymentRecordClose
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "button" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.paymentRecordClose }
                        },
                        [_vm._v("确定")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "orderNumber" }, [
            _c("div", [
              _vm._v(" 订单号:"),
              _c("span", [
                _vm._v(" " + _vm._s(_vm.paymentRecordTableData.orderNumber))
              ])
            ]),
            _c("div", [
              _vm._v(" 订单状态:"),
              _c("span", [
                _vm._v(" " + _vm._s(_vm.paymentRecordTableData.statusValue))
              ])
            ])
          ]),
          _c("div", { staticClass: "money" }, [
            _c("div", [_vm._v("金额信息")]),
            _c("div", [
              _c("span", [
                _vm._v(
                  "应付金额： " +
                    _vm._s(_vm.paymentRecordTableData.transactionAmount) +
                    "元"
                )
              ]),
              _c("span", [
                _vm._v(
                  "已付金额： " +
                    _vm._s(
                      _vm.paymentRecordTableData.paidAmount
                        ? _vm.paymentRecordTableData.paidAmount
                        : 0
                    ) +
                    "元"
                )
              ]),
              _c("span", [
                _vm._v(
                  "未付金额： " +
                    _vm._s(
                      _vm.paymentRecordTableData.unpaidAmount
                        ? _vm.paymentRecordTableData.unpaidAmount
                        : 0
                    )
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "paymentRecordInfo" }, [_vm._v("付款记录")]),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.paymentRecordTableDataList }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间", width: "150" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "walletName",
                  label: "用户关联钱包",
                  width: "150"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "channelNumber",
                  label: "渠道单号",
                  width: "160"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "transactionAmount",
                  label: "交易金额（元）",
                  width: "120"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "transactionNumber",
                  label: "支付单号",
                  width: "170"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "gatewayTransactionNumber",
                  label: "网关支付单号",
                  width: "170"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "middlewareTransactionNumber",
                  label: "支付中台单号",
                  width: "170"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.stateTest(scope.row.status)) + " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }