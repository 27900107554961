import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import AUDIT from "@/maps/enum/status/audit";
import { formatAmountToNumber, formatAmountToString } from "@/libs/crm/format";
import { auditFinanceManager } from "@api/crm/apply/apply";
export default {
  name: "settle-batch",
  props: {
    propsModel: {
      type: Array,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      showLogDialog: false,
      tableData: [],
      formModel: {},
      //表单
      sum: {},
      //统计
      rules: {}
    };
  },
  created: function created() {},
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.formModel = {};
      this.sum = {};
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    clickAuditFail: function clickAuditFail() {
      //审核不通过
      if (this.formModel.advice == null || this.formModel.advice == "") {
        this.$message("请填写审核意见");
      } else {
        this.audit(AUDIT.NO.value);
      }
    },
    clickAudit: function clickAudit() {
      //审核通过
      this.audit(AUDIT.YES.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this.$confirm("本次操作合同 " + _this.tableData.length + " 笔, 提取金额合计 " + _this.sum.sumApplyBackCash, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(function () {
            var arrayApplyId = [];

            _this.tableData.forEach(function (n) {
              arrayApplyId.push(n.applyId);
            });

            var param = {
              arrayApplyId: arrayApplyId,
              audit: result,
              advice: _this.formModel.advice,
              applyBackPayMethod: null,
              paymentAmount: 0
            };
            _this.loading = true;
            auditFinanceManager(param).then(function (res) {
              _this.loading = false;
              _this.showDialog = false;

              _this.$emit("refresh");
            }).catch(function (e) {
              _this.loading = false;
            });
          }).catch(function () {});
        } else {
          return false;
        }
      });
    },
    loadData: function loadData() {
      var sumApplyBackCash = 0; //提取金额汇总

      this.propsModel.forEach(function (n) {
        n.holdDay = null;
        n.actualEarnings = null;
        sumApplyBackCash += formatAmountToNumber(n.applyBackCash);
      });
      this.sum.sumApplyBackCash = formatAmountToString(sumApplyBackCash);
      this.tableData = Object.assign([], this.propsModel); //复制新对象
    },
    //设置表格统计行
    setSummaryRow: function setSummaryRow() {
      var sumRow = [];
      sumRow[0] = "合计";
      sumRow[1] = "共" + this.tableData.length + "笔";
      sumRow[11] = this.sum.sumApplyBackCash;
      return sumRow;
    }
  }
};