import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import ENUM from "@/maps/enum/enum";
import { getEnumList } from "@api/common/common";
import { saveBirthdayBonus } from "@api/crm/customer/customer";
export default {
  name: "dialog-edit-gift",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      collapseActive: ["1"],
      //信息折叠默认展开
      model: {},
      // 表单值      
      formModel: {},
      selectData: {
        expressCompany: [],
        //快递公司
        expressStatus: [],
        //快递状态
        reviewState: [] //回访状态

      },
      rules: {}
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    //快递公司
    getEnumList({
      enumCd: ENUM.ENUM_CD_0221
    }).then(function (res) {
      _this.selectData.expressCompany = res;
    }); //快递状态

    getEnumList({
      enumCd: ENUM.ENUM_CD_0234
    }).then(function (res) {
      _this.selectData.expressStatus = res;
    }); //回访状态

    getEnumList({
      enumCd: ENUM.ENUM_CD_0225
    }).then(function (res) {
      _this.selectData.reviewState = res;
    });
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.getInfo();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 获取详情
    getInfo: function getInfo() {
      this.formModel = Object.assign({}, this.propsModel); //克隆
    },
    // TODO 确定
    confirm: function confirm() {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = _objectSpread({}, _this2.formModel);

          _this2.loading = true;
          saveBirthdayBonus({
            object: param
          }).then(function (res) {
            _this2.loading = false;
            _this2.showDialog = false;

            _this2.$emit("refresh");
          }).catch(function (e) {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};