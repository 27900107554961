



















































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/achievement/conjbf/index.vue"
}
