import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 80
};
export var CONFIG = {
  // 表单提交按钮配置
  submitInfo: {
    icon: '',
    name: '确定'
  },
  // 表单重置按钮配置
  resetInfo: {
    icon: '',
    name: '取消'
  },
  classData: [],

  /* 弹窗 */
  dialogData: {
    // 弹窗状态
    dialogVisible: false,
    // 弹窗宽度
    width: '600px',
    // title
    title: '提示',
    // 弹窗名字
    name: 'dialogData',
    height: '800px',
    // 点击确定按钮，后返回回来的唯一参数
    type: '',
    id: '',
    // 是否显示底部按钮
    footActive: true
  },

  /* 添加分类 */
  formConfig: {
    parentId: {
      type: 'select',
      label: '上级分类',
      props: {
        placeholder: '请输入上级分类',
        disabled: false
      },
      options: []
    },
    classifyName: {
      type: 'input',
      label: '分类名称',
      props: {
        placeholder: '请填写分类'
      },
      rules: [{
        required: true,
        message: '分类名称不能为空',
        trigger: 'blur'
      }]
    },
    coverPicture: {
      type: 'uploadImage',
      label: '分类封面',
      props: {
        placeholder: '最多可传1张，图片大小不超过200KB，支GIF,JPEG,JPG,PNG,BMP格式'
      }
    },
    backgroundPicture: {
      type: 'uploadImage',
      label: '封面底图',
      props: {
        placeholder: '最多可传1张，图片大小不超过200KB，支GIF,JPEG,JPG,PNG,BMP格式'
      }
    },
    description: {
      type: 'input',
      label: '分类描述',
      props: {
        type: 'textarea',
        rows: 4,
        maxlength: 600,
        showWordLimit: true
      }
    }
  }
};
export var TOP_FORM = {
  classifyId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '分类名称',
    props: {
      placeholder: '请输入分类名称'
    },
    options: []
  }),
  title: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '标题',
    props: {
      placeholder: '请输入标题'
    }
  })
};