




























































































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/components/business/contract/businessAccountingIndex.vue"
}
