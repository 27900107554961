import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import attachmentMap from "@/maps/url/crm/attachment/attachment";
import util from "@/libs/util";
export default {
  props: {
    uploadContractId: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      loading: false,
      name: "attachment-upload",
      uploadUrl: process.env.VUE_APP_API + attachmentMap.upload,
      uploadHeader: {
        "X-Token": util.cookies.get("token")
      },
      fileList: [],
      uploadFileName: "file",
      //服务器接收文件名参数
      uploadParams: {},
      //服务器额外参数
      uploadFileCount: 0,
      //上传文件总数
      accept: ".jpg,.jpeg,.png,.gif,.bmp,.wav,.m4a,.mp3,.pdf,.doc,.docx,.xls,.xlsx",
      //上传文件类型,
      formModel: {
        uploadType: null,
        //上传类型
        uploadExtId: null //扩展ID

      },
      selectData: {
        attachmentType: [{
          value: '1',
          text: '认购-合同资料'
        }, {
          value: '2',
          text: '认购-回单凭证'
        }, {
          value: '3',
          text: '赎回-申请资料'
        }]
      },
      rules: {
        attachmentType: [{
          required: true,
          message: '请选择附件类型',
          trigger: 'input'
        }]
      }
    };
  },
  mixins: [dialogMixin],
  showDialog: {
    handler: function handler(val) {},
    immediate: true
  },
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.fileList = [];
      this.uploadFileCount = 0;
    },
    dialogClose: function dialogClose() {},
    submitUpload: function submitUpload(params) {
      this.$refs.upload.submit(); //原element上传多个文件拆分成多次请求
    },
    handleRemove: function handleRemove(file, fileList) {},
    handlePreview: function handlePreview(file) {},
    handleSuccess: function handleSuccess(response, file, fileList) {
      if (response.code == "1") {
        this.uploadFileCount++;

        if (this.uploadFileCount >= fileList.length) {
          //全部上传完成
          this.$message({
            message: "文件已上传",
            type: "success"
          });
          this.$emit("refresh-upload");
          this.$emit("check", fileList); //检查文件

          this.showDialog = false;
          this.uploadFileCount = 0;
        }
      } else {
        this.init();
        this.$message.error(response.message);
      }
    },
    handleError: function handleError(err, file, fileList) {
      this.init();
      this.$message.error("上传文件出错");
    },
    handleBeforeUpload: function handleBeforeUpload(file) {
      var fileExtName = file.name.substring(file.name.lastIndexOf(".")); //文件扩展名

      fileExtName = fileExtName.toLowerCase();

      if (this.accept.indexOf(fileExtName) < 0) {
        this.$message(file.name + " 文件格式不支持");
        return false;
      }

      return true;
    },
    handleChange: function handleChange(file, fileList) {},
    //选择附件类型
    changeIdAttachmentType: function changeIdAttachmentType() {
      //上传参数
      this.uploadParams = {
        contractId: this.uploadContractId,
        extId: null,
        type: this.formModel.attachmentType
      };
    }
  }
};