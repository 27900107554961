var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        top: "10vh",
        width: "500px",
        title: "【" + _vm.propsModel.name + "】菜单权限配置",
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _vm.menuList && _vm.menuList.length
        ? _c(
            "div",
            [
              _c("el-tree", {
                ref: "menuTree",
                attrs: {
                  "default-checked-keys": _vm.defaultCheckedKeys,
                  data: _vm.menuList,
                  props: _vm.props,
                  "node-key": "id",
                  "show-checkbox": "",
                  "check-strictly": ""
                },
                on: { check: _vm.handleCheck }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "mini" },
              on: { click: _vm.dialogClose }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                plain: "",
                size: "mini",
                loading: _vm.loading
              },
              on: { click: _vm.confirm }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }