import { computed } from '@vue/composition-api';
import { useStore } from '@/store'; // import { TCloudInfo } from '@/store/modules/d2admin/modules/cloud';

export default function useCloud() {
  var store = useStore();
  /** 华为云数据 */

  var cloudInfo = computed(function () {
    return store.getters['d2admin/cloud/info'];
  });
  /** 华为云地址 */

  var cloudUrl = computed(function () {
    return cloudInfo.value.upload || null;
  });
  return {
    /** 华为云数据 */
    cloudInfo: cloudInfo,

    /** 华为云地址 */
    cloudUrl: cloudUrl
  };
}