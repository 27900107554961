export default (function (_ref) {
  var request = _ref.request,
      url = _ref.url;
  return {
    /** 获取系统角色列表 */
    GET_PERMISSION_CODE_LIST: function GET_PERMISSION_CODE_LIST() {
      return request({
        url: url.getUserPermissionCode
      });
    },

    /** 转移用户组织 */
    TRANSFERORG: function TRANSFERORG(data) {
      return request({
        url: url.transferOrg,
        data: data
      });
    },

    /** 重新入职 */
    RE_ENTRY_RY: function RE_ENTRY_RY(data) {
      return request({
        url: url.reEntry,
        data: data
      });
    }
  };
});