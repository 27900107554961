var VAL_WIDTH = '200px'; // 表头

export default [{
  label: '参数名',
  prop: 'paramName',
  width: '500px'
}, {
  label: '参数值',
  prop: 'paramValue',
  width: '100px'
}, {
  label: '是否启用',
  prop: 'enabled',
  width: '100px'
}, {
  label: '参数描述',
  prop: 'paramDesc',
  width: '500px'
}];