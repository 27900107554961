var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        top: "20vh",
        width: "30%",
        title: "信息",
        visible: _vm.showDialog
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-row",
        { staticClass: "block-col-2" },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("对方户名:")]),
            _vm._v(" " + _vm._s(_vm.model.accountName) + " ")
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("对方账号:")]),
            _vm._v(" " + _vm._s(_vm.model.bankAccount) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "block-col-2" },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("交易日期:")]),
            _vm._v(" " + _vm._s(_vm.model.tallyDate) + " ")
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("交易时间:")]),
            _vm._v(" " + _vm._s(_vm.model.tallyTime) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "block-col-2" },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("交易行名:")]),
            _vm._v(" " + _vm._s(_vm.model.bankName) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "block-col-2" },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("收入金额:")]),
            _vm._v(" " + _vm._s(_vm.model.debtor) + " ")
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("支出金额:")]),
            _vm._v(" " + _vm._s(_vm.model.amount) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "block-col-2" },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("支付金额:")]),
            _vm._v(" " + _vm._s(_vm.model.pay) + " ")
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("手续费:")]),
            _vm._v(" " + _vm._s(_vm.model.poundage) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "block-col-2" },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("交易类型:")]),
            _vm._v(" " + _vm._s(_vm.model.tradeTypeText) + " ")
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("凭单类别:")]),
            _vm._v(" " + _vm._s(_vm.model.voucherKind) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "block-col-2" },
        [
          _c(
            "el-row",
            { staticClass: "block-col-3" },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", { staticClass: "d2-fwb" }, [_vm._v("流水号:")]),
                _vm._v(" " + _vm._s(_vm.model.waterNum) + " ")
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", { staticClass: "d2-fwb" }, [_vm._v("凭单号:")]),
                _vm._v(" " + _vm._s(_vm.model.voucherNumber) + " ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "block-col-2" },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("摘要:")]),
            _vm._v(" " + _vm._s(_vm.model.info) + " ")
          ])
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("span", { staticClass: "d2-fwb" }, [_vm._v("备注:")]),
            _vm._v(" " + _vm._s(_vm.model.remark) + " ")
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("关闭")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }