























































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/memberPoints/annualRule/index.vue"
}
