//审核
export default {
  SAVE: {
    value: '1',
    text: '待发件'
  },
  CONFIRM_SENT: {
    value: '2',
    text: '已发货'
  },
  CONFIRM_SIGN: {
    value: '3',
    text: '已收件'
  },
  LOST: {
    value: '4',
    text: '包裹丢失'
  }
};