





















































































































































































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/interest/dialog-info.vue"
}
