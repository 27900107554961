//帐户对帐
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

var baseRoute = 'crm';
export default [// 银行流水
{
  path: "".concat(baseRoute, "/bank/accountStatement/search"),
  name: 'accountStatement-search',
  meta: {
    title: '银行流水导入查询',
    auth: true,
    cache: false
  },
  component: _import('crm/bank/accountStatement/search')
}, {
  path: "".concat(baseRoute, "/bank/accountStatement/messageSearch"),
  name: 'accountStatement-messageSearch',
  meta: {
    title: '银行流水导入查询',
    auth: true,
    cache: true
  },
  component: _import('crm/bank/accountStatement/messageSearch')
}];