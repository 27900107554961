import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import AUDIT from '@/maps/enum/status/audit';
import { formatAmountToNumber, formatAmountToString } from '@/libs/crm/format';
import { auditFinance } from '@api/crm/interest/interest';
export default {
  name: 'settle-batch',
  props: {
    propsModel: {
      type: Array,
      default: function _default() {
        return {};
      }
    },
    LastTime: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      showLogDialog: false,
      tableData: [],
      formModel: {},
      //表单
      sum: {},
      //统计
      rules: {}
    };
  },
  created: function created() {},
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.formModel = {};
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    clickAuditFail: function clickAuditFail() {
      //审核不通过
      if (this.formModel.advice == null || this.formModel.advice == '') {
        this.$message('请填写审核意见');
      } else {
        this.audit(AUDIT.NO.value);
      }
    },
    clickAudit: function clickAudit() {
      //审核通过
      this.audit(AUDIT.YES.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this.$confirm("\u672C\u6B21\u64CD\u4F5C\u5408\u540C ".concat(_this.tableData.length, " \u7B14\uFF0C\u4ED8\u606F\u91D1\u989D\u5408\u8BA1 ").concat(_this.sum.sumInterest, " \u5143 ").concat(_this.LastTime ? '，续约租金金额合计 ' + _this.sum.sumGoOnEarnings + ' 元' : ''), '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            var arrayIpdId = [];
            var isSubmit = true;

            for (var i = 0; i < _this.tableData.length; i++) {
              var n = _this.tableData[i];
              arrayIpdId[i] = n.ipdId;
            }

            if (isSubmit) {
              var param = {
                arrayIpdId: arrayIpdId,
                audit: result,
                advice: _this.formModel.advice
              };
              _this.loading = true;
              auditFinance(param).then(function (res) {
                _this.loading = false;
                _this.showDialog = false;

                _this.$emit('refresh');
              }).catch(function (e) {
                _this.loading = false;
              });
            }
          }).catch(function () {}); // 深度选择器无效 获取实例添加弹出宽度


          var el = document.querySelectorAll('.el-message-box');

          if (el) {
            el.forEach(function (item) {
              item.style.cssText = 'width:600px';
            });
          }
        } else {
          return false;
        }
      });
    },
    loadData: function loadData() {
      var sumInterest = 0.0;
      var sumGoOnEarnings = 0.0;
      this.propsModel.forEach(function (n) {
        n.holdDay = null;
        n.actualEarnings = null;
      });
      this.tableData = Object.assign([], this.propsModel); // 复制新对象

      this.tableData.forEach(function (n) {
        n.interest = formatAmountToNumber(n.interest); // 金额转回数字

        sumInterest += n.interest;
        sumGoOnEarnings += formatAmountToNumber(n.goOnEarnings);
      });
      this.sum.sumInterest = formatAmountToString(sumInterest);
      this.sum.sumGoOnEarnings = formatAmountToString(sumGoOnEarnings);
    },
    //设置表格统计行
    setSummaryRow: function setSummaryRow() {
      var sumRow = [];
      sumRow[0] = '合计';
      sumRow[1] = '共' + this.tableData.length + '笔';
      this.LastTime ? sumRow[9] = this.sum.sumInterest : sumRow[6] = this.sum.sumInterest;

      if (this.LastTime) {
        sumRow[8] = this.sum.sumGoOnEarnings;
      } else {
        sumRow[8] = '';
      }

      return sumRow;
    }
  }
};