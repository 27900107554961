import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import AUDIT from "@/maps/enum/status/audit";
import { formatAmountToNumber, formatAmountToString } from "@/libs/crm/format";
import { auditCount } from "@api/crm/interest/interest";
export default {
  name: "settle-batch",
  props: {
    propsModel: {
      type: Array,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      showLogDialog: false,
      tableData: [],
      formModel: {},
      //表单
      sum: {},
      //统计
      rules: {}
    };
  },
  created: function created() {},
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.formModel = {};
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    clickAuditFail: function clickAuditFail() {
      //审核不通过
      if (this.formModel.advice == null || this.formModel.advice == "") {
        this.$message("请填写审核意见");
      } else {
        this.audit(AUDIT.NO.value);
      }
    },
    clickAudit: function clickAudit() {
      //审核通过
      this.audit(AUDIT.YES.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this.$confirm("本次操作合同 " + _this.tableData.length + " 笔, 付息金额合计 " + _this.sum.sumInterest, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(function () {
            var arrayIpdId = [];
            var isSubmit = true;

            for (var i = 0; i < _this.tableData.length; i++) {
              var n = _this.tableData[i];
              arrayIpdId[i] = n.ipdId;
            }

            if (isSubmit) {
              var param = {
                arrayIpdId: arrayIpdId,
                audit: result,
                advice: _this.formModel.advice
              };
              _this.loading = true;
              auditCount(param).then(function (res) {
                _this.loading = false;
                _this.showDialog = false;

                _this.$emit("refresh");
              }).catch(function (e) {
                _this.loading = false;
              });
            }
          }).catch(function () {});
        } else {
          return false;
        }
      });
    },
    loadData: function loadData() {
      var sumInterest = 0.00;
      this.propsModel.forEach(function (n) {
        n.holdDay = null;
        n.actualEarnings = null;
      });
      this.tableData = Object.assign([], this.propsModel); //复制新对象

      this.tableData.forEach(function (n) {
        n.interest = formatAmountToNumber(n.interest); //金额转回数字

        sumInterest += n.interest;
      });
      this.sum.sumInterest = formatAmountToString(sumInterest);
    },
    //设置表格统计行
    setSummaryRow: function setSummaryRow() {
      var sumRow = [];
      sumRow[0] = "合计";
      sumRow[1] = "共" + this.tableData.length + "笔";
      sumRow[9] = this.sum.sumInterest;
      return sumRow;
    }
  }
};