//查询条件
import { listCacheOrg, listCacheProduct } from "@api/common/cache";
import { formatDate } from "@/libs/crm/format";
var VAL_WIDTH = '150px';
export var FILTER_PROPS = {
  labelWidth: 'auto',
  inline: true
};
export var FILTER_FORMS = [{
  label: '合同编号',
  valueKey: 'contractCd',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '客户',
  valueKey: 'customerName',
  value: '',
  type: 'input',
  props: {
    type: 'text',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '产品',
  valueKey: 'arrayProductId',
  value: [],
  type: 'select',
  method: listCacheProduct,
  params: {},
  options: [],
  props: {
    clearable: true,
    style: {
      width: VAL_WIDTH
    },
    multiple: true
  }
}, {
  label: '机构名称',
  valueKey: 'orgCd',
  value: '',
  type: 'cascader',
  method: listCacheOrg,
  params: {},
  options: [],
  props: {
    //expandTrigger: 'hover',
    checkStrictly: true,
    emitPath: false,
    showAllLevels: false,
    value: 'orgCd',
    label: 'orgName',
    children: 'children'
  }
}, {
  label: '开始日期',
  valueKey: 'startDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'startDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '结束日期',
  valueKey: 'endDateBegin',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'endDateEnd',
  value: '',
  type: 'date',
  props: {
    type: 'date',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '推介费月份',
  valueKey: 'planDateBegin',
  value: initPlanDateBegin(),
  type: 'date',
  props: {
    type: 'month',
    style: {
      width: VAL_WIDTH
    }
  }
}, {
  label: '~',
  labelWidth: '15px',
  valueKey: 'planDateEnd',
  value: initPlanDateEnd(),
  type: 'date',
  props: {
    type: 'month',
    style: {
      width: VAL_WIDTH
    }
  }
}]; //默认提成月份规则：如当日是本月上旬，则默认提成月份为上月下旬；如当日是本月下旬，则默认提成月份是本月上旬

/**
 * 默认提成月份开始
 * @returns 
 */

function initPlanDateBegin() {
  var date = new Date();
  var day = date.getDate();

  if (day <= 15) {
    //本月上旬
    date.setMonth(date.getMonth() - 1);
    date.setDate(16);
  } else {
    //本月下旬
    date.setDate(1);
  }

  var rs = formatDate(date);
  return rs;
}

;
/**
 * 默认提成月份结束
 * @returns 
 */

function initPlanDateEnd() {
  var date = new Date();
  var day = date.getDate();

  if (day <= 15) {
    //本月上旬       
    date.setDate(0); //取上月下旬
  } else {
    //本月下旬
    date.setDate(15); //取本月上旬
  }

  var rs = formatDate(date);
  return rs;
}

;