import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
// 查询条件
var PUBLIC_CONFIG = {
  labelWidth: 100
};
var PUBLIC_CONFIGS = {
  labelWidth: 120
}; // 表格列宽

var PUBLIC_TABLE_LABEL_CONFIG = {
  width: 150
};
export var TOP_FORM = {
  merchantName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '商户名称',
    props: {
      placeholder: '请输入商户名称'
    }
  }),
  merchantNumber: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '商户号',
    props: {
      placeholder: '请输入商户号'
    }
  }),
  channel: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '支付渠道',
    col: 6,
    props: {
      placeholder: '请选择支付渠道'
    },
    options: []
  }),
  transactionMemberCode: {
    labelWidth: 120,
    type: 'input',
    label: '交易会员代码',
    props: {
      placeholder: '请输入交易会员代码'
    }
  },
  createTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '创建时间',
    col: 6,
    propGroup: ['beginCreateTime', 'endCreateTime'],
    props: {
      type: 'daterange'
    }
  })
};
export var TABLE_LABEL = [{
  label: '商户名称',
  prop: 'merchantName'
}, {
  label: '平台商户',
  prop: 'platformMerchantValue'
}, {
  label: '渠道',
  prop: 'channelValue'
}, {
  label: '商户号',
  prop: 'merchantNumber'
}, {
  label: '商户编号',
  prop: 'merchantId'
}, {
  label: '交易会员代码',
  prop: 'transactionMemberCode'
}, {
  label: '开户手机号',
  prop: 'accountPhoneNumber'
}, {
  label: '创建时间',
  prop: 'createTime',
  sortable: 'merchantManagement',
  sortProp: 'createTime'
}, _objectSpread(_objectSpread({
  type: 'operation',
  label: '操作',
  fixed: 'right'
}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  formatter: function formatter(row) {
    var arr = [];
    arr.push({
      type: 'compile',
      label: '修改',
      permission: 'merchant-update'
    }, {
      type: 'delete',
      label: '删除',
      permission: 'merchant-delete'
    });
    return arr;
  }
})];
export var TABLE_CONFIG = {
  isSelect: true,
  rowKey: 'id',
  actions: [{
    label: '新增商户',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'merchant-add'
  }]
}; // 新增 商户配置

export var FORM_CONFIG = [_objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '商户名称',
  prop: 'merchantName',
  rules: [{
    required: true,
    message: '请输入',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '商户号',
  prop: 'merchantNumber',
  rules: [{
    required: true,
    message: '请输入',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '商户编号',
  prop: 'merchantId',
  rules: [{
    required: true,
    message: '请输入',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'select',
  label: '平台商户',
  prop: 'platformMerchant',
  value: 2,
  options: [{
    label: '自取',
    value: 1
  }, {
    label: '直邮',
    value: 2
  }],
  rules: [{
    required: true,
    message: '请选择',
    trigger: 'change'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'select',
  label: '支付渠道',
  prop: 'channel',
  value: 2,
  options: [],
  rules: [{
    required: true,
    message: '请选择',
    trigger: 'change'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '开户手机号',
  prop: 'accountPhoneNumber',
  hidden: false,
  rules: [{
    required: true,
    message: '请输入开户手机号',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '交易会员代码',
  prop: 'transactionMemberCode',
  hidden: false
})];