

































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/activity/audit/reviewedFail/index.vue"
}
