import _objectSpread from "/data/jenkins/home/workspace/jsyz-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
// 核算打印
import { getLodop } from '@/plugin/c-lodop/LodopFuncs';
import { readPublicFile, replacePublicFileKeyFromObject } from '@/libs/crm/file';
import { formatAmountToNumber, formatAmountToChinese, formatObjectAmountToString } from '@/libs/crm/format';
/**
 * 核算对日赎回现金打印
 * @param {*} model
 */

export function printCountSameDateApplyBackCash(model) {
  var printModel = _objectSpread({}, model);

  var printFile = '/file/print/count_cash_samedate.html'; // 打印静态文件
  // 设置合同信息的字段

  setCountModelContract(printModel); // 结算日期拆分

  var arrayCountDate = printModel.countDate.split('-');
  printModel.countDateYear = arrayCountDate[0];
  printModel.countDateMonth = arrayCountDate[1];
  printModel.countDateDay = arrayCountDate[2]; // 提取金额转中文大写

  if (printModel.applyBackCash != null && printModel.applyBackCash > 0) {
    var applyBackCash = formatAmountToNumber(printModel.applyBackCash);
    var applyBackCashChinese = formatAmountToChinese(applyBackCash, 8, '/'); // 将金额格式化为中文大写，且整数8位，前面补#，小数2位.ⓧ 无法打印显示, 改用 ∕

    var arrApplyBackCashChinese = applyBackCashChinese.split('.');
    var arrApplyBackCashChineseInt = arrApplyBackCashChinese[0].split(''); // 整数

    var arrApplyBackCashChineseFloat = arrApplyBackCashChinese[1].split(''); // 小数

    for (var i = 0; i < arrApplyBackCashChineseInt.length; i++) {
      // 整数
      var label = 'applyBackCash_i' + (arrApplyBackCashChineseInt.length - i); // 金额位数    

      printModel[label] = arrApplyBackCashChineseInt[i];
    }

    for (var _i = 0; _i < arrApplyBackCashChineseFloat.length; _i++) {
      // 小数
      var _label = 'applyBackCash_f' + (_i + 1); // 金额位数    


      printModel[_label] = arrApplyBackCashChineseFloat[_i];
    }
  } else {
    // 可能此时还没有生成提取金额
    for (var _i2 = 0; _i2 < 8; _i2++) {
      var _label2 = 'applyBackCash_i' + (_i2 + 1);

      printModel[_label2] = '/';
    }

    for (var _i3 = 0; _i3 < 2; _i3++) {
      var _label3 = 'applyBackCash_f' + (_i3 + 1);

      printModel[_label3] = '/';
    }
  } //金额格式转千分位


  formatObjectAmountToString(printModel); // 加载申请文件打印

  readPublicFile(printFile).then(function (re) {
    var htmlString = re; // 读入文件内容字符串

    htmlString = replacePublicFileKeyFromObject(htmlString, printModel); // 代换掉其中的key        
    // 打印

    var LODOP = getLodop();
    if (!LODOP) return;
    LODOP.PRINT_INIT(printModel.customerName + '核算提取现金申请表');
    LODOP.ADD_PRINT_HTM(30, 0, '100%', '100%', htmlString);
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 5);
    LODOP.SET_PRINT_MODE("AUTO_CLOSE_PREWINDOW", 1); //打印完关闭预览窗口

    LODOP.PREVIEW();
  });
}
/**
 * 核算对日申请赎回黄金
 * @param {*} model 
 */

export function printCountSameDateApplyBackGold(model) {
  var printModel = _objectSpread({}, model);

  var printFile = '/file/print/count_jewelry_samedate.html'; // 打印静态文件
  // 设置合同信息的字段

  setCountModelContract(printModel); // 结算日期拆分

  var arrayCountDate = printModel.countDate.split('-');
  printModel.countDateYear = arrayCountDate[0];
  printModel.countDateMonth = arrayCountDate[1];
  printModel.countDateDay = arrayCountDate[2]; // 提取现货总费用

  printModel.feeDeductTotal = printModel.goldFee; //提货费用

  if (printModel.feeDeductTotal > 0) {
    var applyFeeDeductTotalChinese = formatAmountToChinese(printModel.feeDeductTotal, 8, '/'); // 将金额格式化为中文大写，且整数8位，前面补#，小数2位.ⓧ 无法打印显示, 改用 ∕

    var arrFeeDeductTotalChinese = applyFeeDeductTotalChinese.split('.');
    var arrFeeDeductTotalChineseInt = arrFeeDeductTotalChinese[0].split(''); // 整数

    var arrFeeDeductTotalChineseFloat = arrFeeDeductTotalChinese[1].split(''); // 小数

    for (var i = 0; i < arrFeeDeductTotalChineseInt.length; i++) {
      // 整数
      var label = 'feeDeductTotal_i' + (arrFeeDeductTotalChineseInt.length - i); // 金额位数    

      printModel[label] = arrFeeDeductTotalChineseInt[i];
    }

    for (var _i4 = 0; _i4 < arrFeeDeductTotalChineseFloat.length; _i4++) {
      // 小数
      var _label4 = 'feeDeductTotal_f' + (_i4 + 1); // 金额位数    


      printModel[_label4] = arrFeeDeductTotalChineseFloat[_i4];
    }
  } else {
    for (var _i5 = 0; _i5 < 8; _i5++) {
      var _label5 = 'feeDeductTotal_i' + (_i5 + 1);

      printModel[_label5] = '/';
    }

    for (var _i6 = 0; _i6 < 2; _i6++) {
      var _label6 = 'feeDeductTotal_f' + (_i6 + 1);

      printModel[_label6] = '/';
    }
  } // 提取金额转中文大写


  printModel.applyBackCash = printModel.originalinterest; // 提取金额=利息

  if (printModel.applyBackCash != null && printModel.applyBackCash > 0) {
    var applyBackCash = formatAmountToNumber(printModel.applyBackCash);
    var applyBackCashChinese = formatAmountToChinese(applyBackCash, 8, '/'); // 将金额格式化为中文大写，且整数8位，前面补#，小数2位.ⓧ 无法打印显示, 改用 ∕

    var arrApplyBackCashChinese = applyBackCashChinese.split('.');
    var arrApplyBackCashChineseInt = arrApplyBackCashChinese[0].split(''); // 整数

    var arrApplyBackCashChineseFloat = arrApplyBackCashChinese[1].split(''); // 小数

    for (var _i7 = 0; _i7 < arrApplyBackCashChineseInt.length; _i7++) {
      // 整数
      var _label7 = 'applyBackCash_i' + (arrApplyBackCashChineseInt.length - _i7); // 金额位数    


      printModel[_label7] = arrApplyBackCashChineseInt[_i7];
    }

    for (var _i8 = 0; _i8 < arrApplyBackCashChineseFloat.length; _i8++) {
      // 小数
      var _label8 = 'applyBackCash_f' + (_i8 + 1); // 金额位数    


      printModel[_label8] = arrApplyBackCashChineseFloat[_i8];
    }
  } else {
    // 可能此时还没有生成提取金额
    for (var _i9 = 0; _i9 < 8; _i9++) {
      var _label9 = 'applyBackCash_i' + (_i9 + 1);

      printModel[_label9] = '/';
    }

    for (var _i10 = 0; _i10 < 2; _i10++) {
      var _label10 = 'applyBackCash_f' + (_i10 + 1);

      printModel[_label10] = '/';
    }
  } //金额格式转千分位


  formatObjectAmountToString(printModel); // 加载申请文件打印

  readPublicFile(printFile).then(function (re) {
    var htmlString = re; // 读入文件内容字符串

    htmlString = replacePublicFileKeyFromObject(htmlString, printModel); // 代换掉其中的key
    // 打印

    var LODOP = getLodop();
    if (!LODOP) return;
    LODOP.PRINT_INIT(printModel.customerName + '核算提取现货申请表');
    LODOP.ADD_PRINT_HTM(30, 0, '100%', '100%', htmlString);
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 5);
    LODOP.SET_PRINT_MODE("AUTO_CLOSE_PREWINDOW", 1); //打印完关闭预览窗口

    LODOP.PREVIEW();
  });
}
/**
 * 核算固定日申请赎回黄金
 * @param {*} model 
 */

export function printCountFixedDateApplyBackGold(model) {
  var printModel = _objectSpread({}, model);

  var printFile = '/file/print/count_jewelry_fixeddate.html'; // 打印静态文件
  // 设置合同信息的字段

  setCountModelContract(printModel); // 结算日期拆分

  var arrayCountDate = printModel.countDate.split('-');
  printModel.countDateYear = arrayCountDate[0];
  printModel.countDateMonth = arrayCountDate[1];
  printModel.countDateDay = arrayCountDate[2]; // 提取现货总费用

  printModel.feeDeductTotal = printModel.goldFee; //提货费用

  if (printModel.feeDeductTotal > 0) {
    var applyFeeDeductTotalChinese = formatAmountToChinese(printModel.feeDeductTotal, 8, '/'); // 将金额格式化为中文大写，且整数8位，前面补#，小数2位.ⓧ 无法打印显示, 改用 ∕

    var arrFeeDeductTotalChinese = applyFeeDeductTotalChinese.split('.');
    var arrFeeDeductTotalChineseInt = arrFeeDeductTotalChinese[0].split(''); // 整数

    var arrFeeDeductTotalChineseFloat = arrFeeDeductTotalChinese[1].split(''); // 小数

    for (var i = 0; i < arrFeeDeductTotalChineseInt.length; i++) {
      // 整数
      var label = 'feeDeductTotal_i' + (arrFeeDeductTotalChineseInt.length - i); // 金额位数    

      printModel[label] = arrFeeDeductTotalChineseInt[i];
    }

    for (var _i11 = 0; _i11 < arrFeeDeductTotalChineseFloat.length; _i11++) {
      // 小数
      var _label11 = 'feeDeductTotal_f' + (_i11 + 1); // 金额位数    


      printModel[_label11] = arrFeeDeductTotalChineseFloat[_i11];
    }
  } else {
    for (var _i12 = 0; _i12 < 8; _i12++) {
      var _label12 = 'feeDeductTotal_i' + (_i12 + 1);

      printModel[_label12] = '/';
    }

    for (var _i13 = 0; _i13 < 2; _i13++) {
      var _label13 = 'feeDeductTotal_f' + (_i13 + 1);

      printModel[_label13] = '/';
    }
  } // 提取金额转中文大写


  if (printModel.applyBackCash != null && printModel.applyBackCash > 0) {
    var applyBackCash = formatAmountToNumber(printModel.applyBackCash);
    var applyBackCashChinese = formatAmountToChinese(applyBackCash, 8, '/'); // 将金额格式化为中文大写，且整数8位，前面补#，小数2位.ⓧ 无法打印显示, 改用 ∕

    var arrApplyBackCashChinese = applyBackCashChinese.split('.');
    var arrApplyBackCashChineseInt = arrApplyBackCashChinese[0].split(''); // 整数

    var arrApplyBackCashChineseFloat = arrApplyBackCashChinese[1].split(''); // 小数

    for (var _i14 = 0; _i14 < arrApplyBackCashChineseInt.length; _i14++) {
      // 整数
      var _label14 = 'applyBackCash_i' + (arrApplyBackCashChineseInt.length - _i14); // 金额位数    


      printModel[_label14] = arrApplyBackCashChineseInt[_i14];
    }

    for (var _i15 = 0; _i15 < arrApplyBackCashChineseFloat.length; _i15++) {
      // 小数
      var _label15 = 'applyBackCash_f' + (_i15 + 1); // 金额位数    


      printModel[_label15] = arrApplyBackCashChineseFloat[_i15];
    }
  } else {
    // 可能此时还没有生成提取金额
    for (var _i16 = 0; _i16 < 8; _i16++) {
      var _label16 = 'applyBackCash_i' + (_i16 + 1);

      printModel[_label16] = '/';
    }

    for (var _i17 = 0; _i17 < 2; _i17++) {
      var _label17 = 'applyBackCash_f' + (_i17 + 1);

      printModel[_label17] = '/';
    }
  } //金额格式转千分位


  formatObjectAmountToString(printModel); //加载申请文件打印

  readPublicFile(printFile).then(function (re) {
    var htmlString = re; // 读入文件内容字符串

    htmlString = replacePublicFileKeyFromObject(htmlString, printModel); // 代换掉其中的key
    // 打印

    var LODOP = getLodop();
    if (!LODOP) return;
    LODOP.PRINT_INIT(printModel.customerName + '核算提取现货申请表');
    LODOP.ADD_PRINT_HTM(30, 0, '100%', '100%', htmlString);
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 5);
    LODOP.SET_PRINT_MODE("AUTO_CLOSE_PREWINDOW", 1); //打印完关闭预览窗口

    LODOP.PREVIEW();
  });
}
/**
 * 设置合同信息部分的字段
 * @param {*} model 
 */

function setCountModelContract(printModel) {
  // 合同开始日期拆分
  var arrayStartDate = printModel.startDate.split('-');
  printModel.startDateYear = arrayStartDate[0];
  printModel.startDateMonth = arrayStartDate[1];
  printModel.startDateDay = arrayStartDate[2]; // 合同到期日期拆分

  var arrayEndDate = printModel.endDate.split('-');
  printModel.endDateYear = arrayEndDate[0];
  printModel.endDateMonth = arrayEndDate[1];
  printModel.endDateDay = arrayEndDate[2]; // 合同金额转中文大写

  var amount = formatAmountToNumber(printModel.amount);
  var amountChinese = formatAmountToChinese(amount, 8, '/'); // 将金额格式化为中文大写，且整数8位，前面补#，小数2位.ⓧ 无法打印显示, 改用 ∕

  var arrAmountChinese = amountChinese.split('.');
  var arrAmountChineseInt = arrAmountChinese[0].split(''); // 整数

  var arrAmountChineseFloat = arrAmountChinese[1].split(''); // 小数

  for (var i = 0; i < arrAmountChineseInt.length; i++) {
    // 整数
    var label = 'amount_i' + (arrAmountChineseInt.length - i); // 金额位数    

    printModel[label] = arrAmountChineseInt[i];
  }

  for (var _i18 = 0; _i18 < arrAmountChineseFloat.length; _i18++) {
    // 小数
    var _label18 = 'amount_f' + (_i18 + 1); // 金额位数    


    printModel[_label18] = arrAmountChineseFloat[_i18];
  } // 收益金额转中文大写


  var expectEarnings = formatAmountToNumber(printModel.expectEarnings);
  var expectEarningsChinese = formatAmountToChinese(expectEarnings, 8, '/'); // 将金额格式化为中文大写，且整数8位，前面补#，小数2位.ⓧ 无法打印显示, 改用 ∕

  var arrExpectEarningsChinese = expectEarningsChinese.split('.');
  var arrExpectEarningsInt = arrExpectEarningsChinese[0].split(''); // 整数

  var arrExpectEarningsFloat = arrExpectEarningsChinese[1].split(''); // 小数

  for (var _i19 = 0; _i19 < arrExpectEarningsInt.length; _i19++) {
    // 整数
    var _label19 = 'expectEarnings_i' + (arrExpectEarningsInt.length - _i19); // 金额位数    


    printModel[_label19] = arrExpectEarningsInt[_i19];
  }

  for (var _i20 = 0; _i20 < arrExpectEarningsFloat.length; _i20++) {
    // 小数
    var _label20 = 'expectEarnings_f' + (_i20 + 1); // 金额位数    


    printModel[_label20] = arrExpectEarningsFloat[_i20];
  }
}