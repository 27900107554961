


























































































































export default function (Component) {
  Component.options.__source = "src/views/crm/commissionAgency/search/components/dialog-batch-list.vue"
}
