import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import ENUM from "@/maps/enum/enum";
import { getEnumList } from "@api/common/common";
import { formatDate } from "@/libs/crm/format";
import { delivery } from "@api/crm/gift/apply";
export default {
  name: "gift-apply-delivery-batch",
  props: {
    propsModel: {
      type: Array,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      showLogDialog: false,
      tableData: [],
      selectData: {
        provideWay: [],
        //发放方式
        express: [] //快递公司

      },
      formModel: {},
      //表单
      rules: {
        provideWay: [{
          required: true,
          message: "请选择发放方式",
          trigger: "input"
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    //发放方式
    getEnumList({
      enumCd: ENUM.ENUM_CD_0242
    }).then(function (res) {
      _this.selectData.provideWay = res;
    }); //快递公司

    getEnumList({
      enumCd: ENUM.ENUM_CD_0221
    }).then(function (res) {
      _this.selectData.express = res;
    });
  },
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
          this.$set(this.formModel, "provideWay", "1");
          this.$set(this.formModel, "express", "sfkd");
          this.$set(this.formModel, "provideDate", formatDate(new Date()));
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    // TODO 确定
    clickDelivery: function clickDelivery(result) {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = {
            arrayObject: []
          };

          _this2.tableData.forEach(function (n) {
            param.arrayObject.push({
              applyGiftId: n.applyGiftId,
              giftNo: _this2.formModel.giftNo,
              giftGuarantee: _this2.formModel.giftGuarantee,
              provideWay: _this2.formModel.provideWay,
              provideDate: _this2.formModel.provideDate,
              express: _this2.formModel.express,
              expressNo: _this2.formModel.expressNo,
              receiver: _this2.formModel.receiver,
              recipients: _this2.formModel.recipients,
              deliverGoodsRemark: _this2.formModel.deliverGoodsRemark,
              deliverRemark: _this2.formModel.deliverRemark
            });
          });

          _this2.loading = true;
          delivery(param).then(function (res) {
            _this2.loading = false;
            _this2.showDialog = false;

            _this2.$emit("refresh");
          }).catch(function (e) {
            _this2.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    loadData: function loadData() {
      this.tableData = Object.assign([], this.propsModel); //复制新对象      
    }
  }
};