var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "j-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { visible: _vm.dialogVisible, title: "新增客户", width: "60%" },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        confirm: _vm.dialogConfirm,
        close: _vm.close
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            inline: true,
            model: _vm.formData,
            size: "mini",
            "label-position": "left",
            "label-width": "80px",
            rules: _vm.rules
          }
        },
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("基本信息")
          ]),
          _c(
            "div",
            { staticClass: "formItem-box" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户", prop: "customerName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.customerName,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "customerName", $$v)
                      },
                      expression: "formData.customerName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "证件类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      on: { change: _vm.changeIdentityCard },
                      model: {
                        value: _vm.formData.idType,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "idType", $$v)
                        },
                        expression: "formData.idType"
                      }
                    },
                    _vm._l(_vm.docTypeList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "证件编码", prop: "identification" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    on: { change: _vm.getinfo },
                    model: {
                      value: _vm.formData.identification,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "identification", $$v)
                      },
                      expression: "formData.identification"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客户电话", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "mobile", $$v)
                      },
                      expression: "formData.mobile"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "性别", prop: "sex" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.formData.sex,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "sex", $$v)
                        },
                        expression: "formData.sex"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "男", value: "1" } }),
                      _c("el-option", { attrs: { label: "女", value: "0" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "年龄", prop: "age" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.age,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "age", $$v)
                      },
                      expression: "formData.age"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "民族" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.formData.nation,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "nation", $$v)
                        },
                        expression: "formData.nation"
                      }
                    },
                    _vm._l(_vm.nationList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "出生地" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.formData.birthplace,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "birthplace", $$v)
                        },
                        expression: "formData.birthplace"
                      }
                    },
                    _vm._l(_vm.provinceList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "微信", prop: "weChat" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.weChat,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "weChat", $$v)
                      },
                      expression: "formData.weChat"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "生日" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "178px" },
                    attrs: {
                      type: "date",
                      placeholder: "请选择",
                      "value-format": "yyyy-MM-dd"
                    },
                    model: {
                      value: _vm.formData.birthday,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "birthday", $$v)
                      },
                      expression: "formData.birthday"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "家庭住址" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.address,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "address", $$v)
                      },
                      expression: "formData.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.email,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "email", $$v)
                      },
                      expression: "formData.email"
                    }
                  })
                ],
                1
              ),
              _vm.isHasPermission("customer-category")
                ? _c(
                    "el-form-item",
                    { attrs: { label: "客户类别" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "" },
                          on: { change: _vm.customerCategory },
                          model: {
                            value: _vm.formData.customerCategory,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "customerCategory", $$v)
                            },
                            expression: "formData.customerCategory"
                          }
                        },
                        _vm._l(_vm.customerCategoryList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "客户来源" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.formData.customerSource,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "customerSource", $$v)
                        },
                        expression: "formData.customerSource"
                      }
                    },
                    _vm._l(_vm.selectData.customerSourceList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "认识时间" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.formData.lengthOfAcquaintance,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "lengthOfAcquaintance", $$v)
                        },
                        expression: "formData.lengthOfAcquaintance"
                      }
                    },
                    _vm._l(_vm.selectData.lengthOfAcquaintanceList, function(
                      item
                    ) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "年见面频次", "label-width": "100px" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.annualMeetingFrequency,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formData,
                          "annualMeetingFrequency",
                          _vm._n($$v)
                        )
                      },
                      expression: "formData.annualMeetingFrequency"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "新老客户" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.formData.newOrOld,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "newOrOld", $$v)
                        },
                        expression: "formData.newOrOld"
                      }
                    },
                    _vm._l(_vm.selectData.newOrOldList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "投资我司产品金额", "label-width": "130px" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.formData.investedCompanyProductAmount,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formData,
                            "investedCompanyProductAmount",
                            $$v
                          )
                        },
                        expression: "formData.investedCompanyProductAmount"
                      }
                    },
                    _vm._l(
                      _vm.selectData.investedCompanyProductAmountList,
                      function(item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        })
                      }
                    ),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "婚姻现状" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.formData.maritalStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "maritalStatus", $$v)
                        },
                        expression: "formData.maritalStatus"
                      }
                    },
                    _vm._l(_vm.selectData.maritalStatusList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "子女" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.children,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "children", $$v)
                      },
                      expression: "formData.children"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "社会关系网络", "label-width": "100px" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        clearable: "",
                        multiple: ""
                      },
                      model: {
                        value: _vm.formData.socialNetwork,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "socialNetwork", $$v)
                        },
                        expression: "formData.socialNetwork"
                      }
                    },
                    _vm._l(_vm.selectData.socialNetworkList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "座驾" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        clearable: "",
                        multiple: ""
                      },
                      model: {
                        value: _vm.formData.vehicle,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "vehicle", $$v)
                        },
                        expression: "formData.vehicle"
                      }
                    },
                    _vm._l(_vm.selectData.vehicleList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预估身价" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.formData.estimatedNetWorth,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "estimatedNetWorth", $$v)
                        },
                        expression: "formData.estimatedNetWorth"
                      }
                    },
                    _vm._l(_vm.selectData.estimatedNetWorthList, function(
                      item
                    ) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "预估年收入", "label-width": "100px" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.formData.estimatedAnnualIncome,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "estimatedAnnualIncome", $$v)
                        },
                        expression: "formData.estimatedAnnualIncome"
                      }
                    },
                    _vm._l(_vm.selectData.estimatedAnnualIncomeList, function(
                      item
                    ) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "资产类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        clearable: "",
                        multiple: ""
                      },
                      model: {
                        value: _vm.formData.assetType,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "assetType", $$v)
                        },
                        expression: "formData.assetType"
                      }
                    },
                    _vm._l(_vm.selectData.assetTypeList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { visibility: "hidden" },
                  attrs: { label: "家庭住址" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.address,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "address", $$v)
                      },
                      expression: "formData.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { visibility: "hidden" },
                  attrs: { label: "家庭住址" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.address,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "address", $$v)
                      },
                      expression: "formData.address"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("个人标签")
          ]),
          _c(
            "div",
            { staticClass: "formItem-box" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "工作单位" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.company,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "company", $$v)
                      },
                      expression: "formData.company"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "行业" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.formData.industry,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "industry", $$v)
                        },
                        expression: "formData.industry"
                      }
                    },
                    _vm._l(_vm.industryList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "身份" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.formData.identity,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "identity", $$v)
                        },
                        expression: "formData.identity"
                      }
                    },
                    _vm._l(_vm.identityList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "性格" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", clearable: "" },
                      scopedSlots: _vm._u(
                        [
                          _vm.formData.character
                            ? {
                                key: "prefix",
                                fn: function() {
                                  return [
                                    _c("div", {
                                      staticClass: "character-select",
                                      style:
                                        "background: " +
                                        _vm.formData.character +
                                        ";"
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            : null
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.formData.character,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "character", $$v)
                        },
                        expression: "formData.character"
                      }
                    },
                    _vm._l(_vm.characterList, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.value,
                          attrs: { label: item.label, value: item.value }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "character-select-option" },
                            [
                              _c("div", {
                                staticClass: "character-select-option-item",
                                style: "background:" + item.value + ";"
                              }),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "10px" } },
                                [_vm._v(_vm._s(item.label))]
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "学历" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formData.education,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "education", $$v)
                        },
                        expression: "formData.education"
                      }
                    },
                    _vm._l(_vm.educationList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "毕业院校" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.school,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "school", $$v)
                      },
                      expression: "formData.school"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "爱好" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", multiple: "" },
                      model: {
                        value: _vm.formData.hobby,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "hobby", $$v)
                        },
                        expression: "formData.hobby"
                      }
                    },
                    _vm._l(_vm.selectData.hobbyList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "忌讳内容" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.taboo,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "taboo", $$v)
                      },
                      expression: "formData.taboo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "投资偏好" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formData.icHobby,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "icHobby", $$v)
                        },
                        expression: "formData.icHobby"
                      }
                    },
                    _vm._l(_vm.InvestmentPreferList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "投资经验" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formData.icExperience,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "icExperience", $$v)
                        },
                        expression: "formData.icExperience"
                      }
                    },
                    _vm._l(_vm.experienceList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "纪念日" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "178px" },
                    attrs: {
                      type: "date",
                      placeholder: "请选择",
                      "value-format": "yyyy-MM-dd"
                    },
                    model: {
                      value: _vm.formData.memorialDay,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "memorialDay", $$v)
                      },
                      expression: "formData.memorialDay"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { visibility: "hidden" },
                  attrs: { label: "家庭住址" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.address,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "address", $$v)
                      },
                      expression: "formData.address"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("业务信息")
          ]),
          _c(
            "div",
            { staticClass: "formItem-box bottom" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "理顾", prop: "newAgentUserId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", disabled: "" },
                      model: {
                        value: _vm.formData.newAgentUserId,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "newAgentUserId", $$v)
                        },
                        expression: "formData.newAgentUserId"
                      }
                    },
                    _vm._l(_vm.userTableData, function(item) {
                      return _c("el-option", {
                        key: item.userId,
                        attrs: { label: item.userName, value: item.userId }
                      })
                    }),
                    1
                  ),
                  _c("el-button", {
                    attrs: { icon: "el-icon-plus", disabled: _vm.isUserid },
                    on: { click: _vm.clickAddUserId }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { visibility: "hidden" },
                  attrs: { label: "家庭住址" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.address,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "address", $$v)
                      },
                      expression: "formData.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { visibility: "hidden" },
                  attrs: { label: "家庭住址" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.address,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "address", $$v)
                      },
                      expression: "formData.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { visibility: "hidden" },
                  attrs: { label: "家庭住址" }
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.address,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "address", $$v)
                      },
                      expression: "formData.address"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }